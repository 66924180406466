import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { resolveAny } from 'dns';
 
import { MatDialog } from '@angular/material';
 
import * as FileSaver from 'file-saver';
import { Localst } from '../../../_services/localst.service';
import { RegistryApp2Code } from '../../../_models/appsetting/RegistryApp2Code';
import { RegistryService } from '../../../_services/appsetting/Registry.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { AlertifyService } from '../../../_services/alertify.service';

@Component({
  selector: 'appsetting-registry-app2Code',
  templateUrl: './registry-app2Code.component.html',
  styleUrls: ['./registry-app2Code.component.css']
})

export class registryapp2CodeComponent implements OnInit {

  

  model: RegistryApp2Code ;
  loadCounter: number = 0;

  constructor(
    
    public dialog: MatDialog,
    private localst: Localst,
    private alertify: AlertifyService,
    private registryService: RegistryService,
    private router: Router) {

    this.model = new RegistryApp2Code();
 
  }

  ngOnInit() {

    this.LoadModel();




  }

   
  LoadModel() {

    this.loadCounter++;
    this.registryService.getRegistryApp2Code(this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadCounter--;


        this.model = res.data;
      }, error => {
        this.loadCounter--;
        this.alertify.error(error);
      });
 

  }


  onSaveEdit() {



    //console.log(JSON.stringify(this.myModel));
    this.loadCounter++;
    this.registryService.updateRegistryApp2Code(this.model).subscribe(res => {
      this.loadCounter--

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }
      this.alertify.success(res.mes);
      
    }, error => {
      this.loadCounter--
      this.alertify.error(error);
    
    });

  }
}
