import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Sherkat } from '../../../../_models/app1/paye/Sherkat';
import { SherkatService } from '../../../../_services/app1/paye/Sherkat.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-Sherkat-list',
  templateUrl: './Sherkat-list.component.html',
  styleUrls: ['./Sherkat-list.component.css']
})
 

export class SherkatListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;
 

  mymodle: Sherkat[];
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
  header: any;

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrow: any;

  loaddata: loadingCl;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
  constructor(private userService: UserService, private alertify: AlertifyService, private SherkatService: SherkatService,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private router: Router,
    private excelService: ExcelService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,5);
    this.header = this.SherkatService.getTafsiliHeader();
    this.openeditId = -1;
  }
  ngAfterViewChecked() {
   // console.log("! changement de la date du composant !");
   
  }
  ngOnInit() {
    this.resetFilters();
    this.isdelete = "no";;
    this.loadSherkat();
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

  }
  onClicksearch() {

    this.loadSherkat();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadSherkat();
    console.log('m:searching... all');
  }
  loadSherkat() {

    this.loadServ.showloading(this.loaddata,0);
    this.SherkatService.getallnopage()
      .subscribe((res: Sherkat[]) => {
        this.loadServ.hideloading(this.loaddata,0);
        this.mymodle = res;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
          console.log("myerror:" + error);
        this.alertify.error(error);
      });

    //this.SherkatService.getSherkats_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.shakhsParams)
    //  .subscribe((res: PaginatedResult<Sherkat[]>) => {
    //    this.mymodle = res.result;
    //    this.pagination = res.pagination;
    //  }, error => {
    //    this.alertify.error(error);
    //  });

  }
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';
 
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadSherkat();
  }


  reloadrows(): void {
    this.loadSherkat();
    console.log("m:page refleshe done");
    this.loadSherkat();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

    console.log(JSON.stringify(this.selectedrow));

  }

  onClickDelete() {

    if (this.loaddata.showed)
      return;

  //  console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }
  onRowDblClick(e) {
    //fgdhgt6565
    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

  dodelete() {

    this.loadServ.showloading(this.loaddata,2);
    this.SherkatService.deleteSherkat(this.selectedrow.id).subscribe((data) => {

      let dd = data;
      // 0  وجود ندارد
      // 1  پاک شد
      // 2  اجازه پاک شدن ندارد
      if (dd == 1)
        this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

      if (dd == 2)
        this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

      if (dd == 0)
        this.alertify.warning('این کد در سرور یافت نشد');


    
      this.loadServ.hideloading(this.loaddata,2);
      this.loadSherkat();

    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }






  onClickEdit() {

    if (this.loaddata.showed)
      return;

      if (this.selectedrow.id == null) {



        return;
      }

   

        this.openeditId = this.selectedrow.id;
        this.openedit = true;
 


  }
  onClickNew() {
    if (this.loaddata.showed)
      return;

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;
 

  }


  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;
    
    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;
      
    }
    this.loadSherkat();

  }
  onClickExportexcel() {


    // console.log(JSON.stringify(this.modlelist[0]));


    // var p=  this.shakhzService.convertToShakhzForPrint(this.modlelist);


    //this.excelService.exporttableRowbyRow(p , this.header, 'لیست اشخاص');

    this.excelService.exportAsExcelFile(this.mymodle, 'لیست شرکت ها');
  }
}


