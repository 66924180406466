import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnAmel, AnAmelBox, DoAmel } from '../../../_models/app2/glob/AnAmel';
 
 
let EmptyAnAmel: AnAmel = {

  id: 0,
  kind: null,
  fieldId: null,
  fieldC: null,
  fieldN: null,
  hesabId: null,
  hesabC: null,
  hesabN: null,
  taf5Id: null,
  taf5C: null,
  taf5N: null,
  taf6Id: null,
  taf6C: null,
  taf6N: null
};
let EmptyAnAmelBox: AnAmelBox = {

  kind1: [],
  kind2: [],
  kind3: []
};
 
let EmptyDoAmel: DoAmel = {

  tableN:null,
  kind: null,
  sanadIds:[]
};

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnAmelService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnAmel/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
 
  getAnAmelEmpty(): AnAmel {

    return JSON.parse(JSON.stringify(EmptyAnAmel));

  }
  getAnAmelBoxEmpty(): AnAmelBox {

    return JSON.parse(JSON.stringify(EmptyAnAmelBox));

  }
  getDoAnAmelEmpty(): DoAmel {


    return JSON.parse(JSON.stringify(EmptyDoAmel));

  }
  update(item: AnAmelBox) {
    let apiadress = '/api/App2/Glob/AnAmel/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  doAmel(item: DoAmel): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnAmel/doAmel';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  doAmelOne(item: any): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnAmel/doAmelOne';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

}
