import { Sabt } from "./sabt";

 

export interface Sanad {
      
  id: number ,
      cShomare : number,
 cRozane: number,
 cAtf: number,
 cFare: number,
date: number,
kindId: number,
  kindN: string,
  kind2Id: number,
  kind2N: string,
 dis: string,
dateTime: Date, 
  userNLog: string,
  userFLog: string,
  uSaE: string,
  uSaEF: string,
  dateELog: Date,
  years: number,
  costSBed: number,
  costSBes: number,
  costSEkh: number,
    sabts?: Sabt[];
  date_String: string,
  vaziat: number,

  vaziatN: string,
 
  isSys: string,
  _isSysN: string,
  
}

export class sabtExel {
  moien: string;
  tafsili4: string;
  tafsili5: string;
  tafsili6: string;
  sharh: string;
  bedehkar: number;
  bestankar: number;

   constructor(s: Sabt) {
     this.moien = s.hesabCode;
    
     this.tafsili4 = s.tafsili4Code;
     this.tafsili5 = s.tafsili5Code;
     this.tafsili6 = s.tafsili6Code;
     this.sharh = s.dis;
     this.bedehkar = s.bed;
     this.bestankar = s.bes;

  }

}


