import { Component, OnInit } from '@angular/core';

 
import { MatDialog } from '@angular/material';


import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Localst } from '../../_services/localst.service';
import { LoadingService } from '../../_services/loading.service';
import { SuperDbsReport } from '../../_models/SuperAdmin/SuperDbsReport';
import { SuperAdminService } from '../../_services/superAdmin/SuperAdmin.service';
import { ApiRet } from '../../_models/General/ApiRet';
 
@Component({
  selector: 'SuperAdmin-SuperReport-list',
  templateUrl: './SuperReport-list.component.html',
  styleUrls: ['./SuperReport-list.component.css']
})
 
export class SuperReportListComponent implements OnInit {
 
  model: SuperDbsReport;
  loaddata: loadingCl;
  constructor(
    private userService: UserService,
    private alertify: AlertifyService,
    private loadServ: LoadingService,
    private superAdminService: SuperAdminService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 20);

    this.model = superAdminService.getEmptySuperDbsReport();
  } 
  

  ngOnInit() {
   


  }

  onClickReflesh() {

    this.loadServ.showloading(this.loaddata, 0);
    this.superAdminService.getDbsReport().subscribe((res: ApiRet) => {

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.model = res.data;
      }

      this.loadServ.hideloading(this.loaddata, 0);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
    });


  }

}


