import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdBank } from '../../../_models/app4/paye/PdBank';
 
 
let EmptyPdBank: PdBank = {
  id:-1,
  kind: null,
  code:null,
  name: null,
  tell: null,
  shobeN: null,
  shobeC: null,
  noe: null,
  shHesab: null,
  shSheba: null,
  avalPrc: 0,
  dateA: 0,
  dateA_S: null,
  hesabAId: null ,
  hesabAC: null,
  hesabAN: null,
  taf4Id: null ,
  taf4C: null,
  taf4N: null,
  hesabBId: null ,
  hesabBC: null,
  hesabBN: null,
  hesabCId: null ,
  hesabCC: null,
  hesabCN: null,
  hesabDId: null,
  hesabDC: null,
  hesabDN: null,
  dis: null,
  uOwnerCu: null,
  uOwner: null,
  uOwnerF: null,
  pdPoss:[]

};

const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdBankService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(kind: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/getall?kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  getallPoss(): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/getallPoss';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  getallTiny(kind: number, dellOtherOwner : boolean | null): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/getallTiny?kind=' + kind.toString() + '&dellOtherOwner=' + dellOtherOwner;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



  getbyid(id): Observable<PdBank> {
    let apiadress = '/api/App4/Paye/PdBank/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<PdBank>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  add(item: PdBank): Observable<ApiRet>{
    let apiadress = '/api/App4/Paye/PdBank/add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getPdBankEmpty(): PdBank {


    return JSON.parse(JSON.stringify(EmptyPdBank));

  }
 

  update(item: PdBank): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(PdBankId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/delete?id=' + PdBankId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App4/Paye/PdBank/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }



  GetAllCkBank_Static(): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdBank/GetAllCkBank_Static';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}

