import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
 
import { Pagination } from '../../../../_models/pagination';
 
import { environment } from '../../../../../environments/environment';

 

import { LoadingService } from '../../../../_services/loading.service';
import { PdMoveGroup } from '../../../../_models/app4/opr/PdMoveGroup';
import { PdMoveService } from '../../../../_services/app4/opr/PdMove.service';
 
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { PdPayrecRow, PdPayrecRowForChek } from '../../../../_models/app4/opr/PdPayrec';

import { PdPayrecService } from '../../../../_services/app4/opr/PdPayrec.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';

import { PdBankService } from '../../../../_services/app4/paye/PdBank.service';
import { PdBank, PdBankTiny } from '../../../../_models/app4/paye/PdBank';
import { Localst } from '../../../../_services/localst.service';
import { PdHesService } from '../../../../_services/app4/paye/PdHes.service';
import { PdHes } from '../../../../_models/app4/paye/PdHes';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Key } from 'protractor';
 
@Component({
  selector: 'app4-PdMoveGroup-edit',
  templateUrl: './PdMoveGroup-edit.component.html',
  styleUrls: ['./PdMoveGroup-edit.component.css']
})
export class PdMoveGroupEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();


  @ViewChild("gridCklist", { static: false }) gridCklist: DxDataGridComponent;
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  pagination: Pagination;
  myModel: PdMoveGroup;
  myModel_old: PdMoveGroup;
 
  pdPayRecRowList: PdPayrecRow[] =[] ;
  pdPayRecRowListFiltered: PdPayrecRow[] =[] ;

  @Input() kindpage: number;
 
 
 
  kindMovelist: any[] = [];
  pdBankTinylist: PdBankTiny[];
 
 

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
 
    private pdMoveService: PdMoveService,
    private tafsiliService: TafsiliService,
    private pdPayrecService: PdPayrecService,
    private pdBankService: PdBankService,
    private pdHesService: PdHesService,
    private hesabService: HesabService,
    private localst: Localst,
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,20);
    this.validationCallback = this.validationCallback.bind(this);
 
   
    this.myModel = this.pdMoveService.getPdMoveGroupEmpty();
 
  
     
 
  }

  ngOnInit() {
 
    this.pdPayRecRowList = [];
 


    this.loadServ.showloading(this.loaddata, 11);
    this.pdBankService.getallTiny(-1,true).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.pdBankTinylist = res.data;


    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });





 

    //this.loadServ.showloading(this.loaddata, 4);
    //this.pdMoveService.GetKindMove(this.kindpage).subscribe(res => {
    //  this.loadServ.hideloading(this.loaddata, 4);
    //  //ytu568658
    //  if (res.errors.length > 0) {
    //    this.alertify.showListString(res.errors);
    //  }
    //  else {
    //    this.kindMovelist = res.data;
    //  }




    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 4);
    //  this.alertify.error(error);

    //});
 
 

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.pdMoveService.getPdMoveGroupEmpty();
 
   

      this.myModel.years = this.localst.yearnow_get();

      this.myModel.dateO_S = this.localst.dateEmruz.dateString;

      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;


      //فرم وصول
      if (this.kindpage == 3) {
        this.myModel.kindMove = 104;
      }
      //فرم وصول
      if (this.kindpage == 4) {
        this.myModel.kindMove = 203;
        this.loadChek(this.myModel.kindMove , null);
      }

      this.isnew = true;
     

      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.pdMoveService.getGroupbyid(this.editId).subscribe(
        res => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = res.data;
          //yghfyti444
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
         // this.loadOldMove(this.myModel.pdPayrecRowId);
          let u = this.localst.usernow_get();
          this.myModel.uSaE = u.username;
          this.myModel.uSaEF = u.name;

          if (this.kindpage == 4) {
           
            this.loadChek(this.myModel.kindMove, null);
          }
        
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
    



 
  

    console.log(this.editId.toString());
  
  }

  
  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    this.myModel.kindPage = this.kindpage;




    if (this.kindpage == 4) {
      this.myModel.pdBankId = null;
    }
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.pdMoveService.addGroup(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
        //ytu568658
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.OnMySaveEvent.emit(true);
        }
       
     
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
 
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.pdMoveService.updateGroup(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.OnMySaveEvent.emit(true);

        }

 
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
   
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  
 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
  pdbankchanged(e) {




    if (e.value == null)
      return;
    var h = e.component.option('selectedItem') as PdBankTiny;
    if (h == null)
      return;

    this.myModel.pdBankId = h.id;
    this.myModel.pdBankC = h.code;
    this.myModel.pdBankN = h.name;
      //fty45754
    if (e.previousValue != null) {

      this.myModel.pdPayrecRows = [];

    }


    this.loadChek(this.myModel.kindMove ,this.myModel.pdBankId);


  }

  loadChek( kindMove: number, pdBankId: number ) {


    if (pdBankId == null) {
      pdBankId = -1;
    }

    this.loadServ.showloading(this.loaddata, 3);
    this.pdPayrecService.getPayrecRowForPdMoveGroup(this.editId, kindMove,pdBankId).subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 3);
      this.pdPayRecRowList = res.data;
      // var k = this.myModel.pdPayrecRows.map(u => u.id);

      this.filterUsed();
    }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 3);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }
    );


  }



  validateNumber(e) {

    

  }

  //gridBox_displayExpr(item) {
  //  return item && item.codeSum + '-' + item.name;
  //}
 
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }
 
 
 
  cksopen: boolean = false;
  onClickAdd() {


    this.cksopen = !this.cksopen;


  }

  importCkclick() {

    this.cksopen = !this.cksopen;

   
   var keys= this.gridCklist.selectedRowKeys;
   //fghfgjt44646n

   
    for (var i = 0; i < this.pdPayRecRowList.length; i++) {

      if (keys.filter(x => x == this.pdPayRecRowList[i].id).length == 1) {

        if (this.myModel.pdPayrecRows.filter(x => x.id == this.pdPayRecRowList[i].id).length == 0) {

          this.myModel.pdPayrecRows.push(this.pdPayRecRowList[i]);
        }
  
      }
 
    }

    this.filterUsed();
  }

  filterUsed() {

    this.pdPayRecRowListFiltered = this.pdPayRecRowList.filter(x => !this.myModel.pdPayrecRows.map(u => u.id).includes(x.id));


  }
  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }

  onClickDeleterow() {

    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
    // console.log(JSON.stringify(rowkersselected));
    //try4446rfhdtrf
    if (rowkersselected == null)
      rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);

    let afterindex = -1;



    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {


        for (var j = 0; j < this.myModel.pdPayrecRows.length; j++) {

          if (rowkersselected[i] == this.myModel.pdPayrecRows[j].id) {

            this.myModel.pdPayrecRows.splice(j, 1);
            afterindex = j - 1;
            break;
          }
        }


      }

    this.filterUsed();

  }
  generateDis( pattern : string): string {
    
    
    if (pattern == null)
      return null;
    //tyyu75665
    return pattern
      .replace('*1*', this.myModel.tafN)
      .replace('*2*', this.myModel.pdBankN)
      //   .replace('*3*', this.myModel.dateA_S)
      .replace('*4*', this.myModel.mos);
    //  .replace('*5*', this.myModel.CkBank)
    //  .replace('*6*', this.myModel.ckCode)
   //   .replace('*7*', this.myModel.ckDate_S)
   //   .replace('*8*', this.myModel.mahalN);


  }
}
