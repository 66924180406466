import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Morur, MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';
import { LoadingService } from '../../../../_services/loading.service';





@Component({
  selector: 'app-Sanad-repht',
  templateUrl: './Sanad-repht.component.html',
  styleUrls: ['./Sanad-repht.component.css']
})


export class SanadRepHtComponent implements OnInit {


  @Input() inputdata: any;
  @Input() inputoption: any;

  orgheader: any;
  orgrows: any[];
 pVeiw: any = { atf: true}
  nowdate: MyTimeClass;

  loaddata: loadingCl;
  // printpage: printpage;
  printpage: any;
  constructor(private userService: UserService, private alertify: AlertifyService, private sanadService: SanadService,
    private localst: Localst,
    private loadServ: LoadingService,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);
    this.orgrows = [];
    this.printpage = [];




  }

  ngOnInit() {


    // console.log('kkkkk');
    //  console.log(JSON.stringify(this.inputoption));
    this.loadServ.showloading(this.loaddata, 0);
    this.sanadService.reportHt_SanadsPrint(this.inputoption).subscribe((res: any[]) => {
      this.orgrows = res;
      this.loadServ.hideloading(this.loaddata, 0);
      this.createAllobject();
    }, error => {
      this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
    });


    //if (this.inputdata == null)
    //  return;



    this.loadServ.showloading(this.loaddata, 1);
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;


      this.loadServ.hideloading(this.loaddata, 1);



    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();




  }
  createAllobject() {

    //this.createOneobject(this.orgrows[0]);
    //return;
    // console.log(JSON.stringify(this.orgrows));

    // console.log(this.orgrows.length);
    for (var i = 0; i < this.orgrows.length; i++) {





      // console.log(this.orgrows[i].sanadId);


      this.printpage.push(this.createOneobject(this.orgrows[i]));
    }


  }
  sumall: any;
  obtemp: any;
  createOneobject(onepdata: any): any {

    this.obtemp = null;



    this.obtemp = {
      pages: [],
      header: null,
      sumall: null
    };

    this.obtemp.header = {
      cShomare: onepdata.cShomare,
      dateSanad: onepdata.dateSanad,
      cAtf: onepdata.cAtf,
      vaziat: onepdata.vaziat,
      dateNow: onepdata.dateNow


    };
    // console.log(JSON.stringify(onepdata));
    //console.log(onepdata.sanadId);

    let indexreaded = 0;
    let pageindex = 0;
    let datalegh = onepdata.rows.length;


    // console.log(datalegh);

    if (datalegh == 0)
      return;

    this.obtemp.sumall = { "ls": "del-b", "code": "جمع", "text": "", "kBed": 0, "kBes": 0, "legText": 0 }


    while (indexreaded < datalegh) {


      this.obtemp.pages.push(new pagep(pageindex));


      let counterpage = 0;


      while (indexreaded < datalegh) {


        //جمع کل
        this.obtemp.sumall.kBes += onepdata.rows[indexreaded].kBes;
        this.obtemp.sumall.kBed += onepdata.rows[indexreaded].kBed;

        //درج ردیف در صفحه
        this.obtemp.pages[pageindex].rowsp.push(onepdata.rows[indexreaded]);


        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        //fgdredtg547
        ctx.font = '10pt B Nazanin';
        
        const metrics = ctx.measureText(onepdata.rows[indexreaded].text).width;

        let skiprow = Math.floor(metrics / 280);
         //skiprow = 0;

       // let skiprow = Math.floor(onepdata.rows[indexreaded].legpx / 265);
 

        //if (Number.isNaN(skiprow))
        //  skiprow = 0;




        counterpage = counterpage + skiprow;

        indexreaded++;
        counterpage++;
        if (counterpage > 39)
          break;






 
      }





      pageindex++;



    }



   // console.log(JSON.stringify(this.obtemp));

    let leghpage = this.obtemp.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.obtemp.pages[i].ppage = i + 1;
      this.obtemp.pages[i].ppages = leghpage;



    }

    if (leghpage > 0)
      this.obtemp.pages[leghpage - 1].islastpage = true;

    return JSON.parse(JSON.stringify(this.obtemp));

  }




  resetFilters() {


  }




  onFocusedRowChanged(e) {





  }
  onClickedickNew() {





  }


  onClickDelete() {



  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        // this.dodelete();

      }

    });
  }


  createheader() {

    return;
    this.printpage.HMahdude = ' از تاریخ ' + this.orgheader.dateS + ' الی ' + this.orgheader.dateE + '  از شماره سند           ' + this.orgheader.CShomareS + ' الی ' + this.orgheader.CShomareE;


    let level = this.orgheader.nowlevel;
    if (level == 1)
      this.printpage.HHesab = ' (گروه) ';
    if (level == 2)
      this.printpage.HHesab = ' (کل) ';
    if (level == 3)
      this.printpage.HHesab = ' (معین) ';
    if (level == 4)
      this.printpage.HHesab = ' (سطح چهارم) ';
    if (level == 5)
      this.printpage.HHesab = ' (سطح پنجم) ';
    if (level == 6)
      this.printpage.HHesab = ' (سطح ششم) ';

    if (this.orgheader.dir != null)
      for (var i = 0; i < this.orgheader.dir.length; i++) {


        this.printpage.HHesab += this.orgheader.dir[i].htidName;
      }



  }



  onClickEdit() {



  }
  onClickNew() {




  }
  OnMyCancelEvent(e) {


  }

  OnMySaveEvent(e) {



  }

  onClicklevel(level: number) {


  }


  onDBClicklevel(level: number) {




  }

  onClickprint() {


    var d = document.getElementById("d");
    console.log(d.outerHTML);


    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


    //  let popupWinindow
    // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
    //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
    
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "B Nazanin";
  font-weight: 400;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  height: 292mm;
  width: 205mm;
  padding: 0mm;
  margin: 0mm auto;

  background: white;

}



 .t-table {
      width: 188mm;
      margin: 0mm 5mm 0mm 12mm;
      font: 10pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;
    }

       .t-table td {
        font: 10pt "B Nazanin" !important;
        font-weight: 400;
        border: 1px solid black;
              border-top: 1px solid #b0b0b0;
        border-bottom: 1px solid #c2c2c2;
        padding: 0px 5px 0px 5px;
        height: 18px;
      }

      .t-table th {
        font: 10pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
      }

    .t-header {
      width: 188mm;
      margin: 5mm 5mm 0mm 12mm;
      direction: rtl;
      font: 10pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 10pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
      }


    .grayrow {
    }

    .del-t {
      border-top: none !important;
    }

    .del-b {
      border-bottom: none !important;
    }
 


@page {
  size: A4;
 
}
@media print {
  html, body {
    width: 210mm;
    height: 292mm;
  }
  /* ... the rest of the rules ... */
   
body {
  width: 100%;
  height: 100%;
  padding: 0mm;
  margin: 0mm auto;
  background-color: white;
  font: 12pt "B Nazanin";
}


 .page {

display:block; page-break-before:always;
  height: 292mm;
  width: 205mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;

  border-radius: 0px;
  background: white;

}



  .t-table {
        width: 188mm;
        margin: 0mm 5mm 0mm 12mm;
        font: 10pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: 'B Nazanin';
        direction: rtl;
        text-align: right;
      }

        .t-table td {
          font: 10pt "B Nazanin";
          font-weight: 400;
               border-top: 1px solid #b0b0b0;
        border-bottom: 1px solid #c2c2c2;
          padding: 0px 5px 0px 5px;
          height: 18px;
        }

        .t-table th {
          font: 10pt "B Nazanin";
          font-weight: 400;
          border: 1px solid black;
          text-align: center;
          height: 20px;
          background-color: lightgrey;
        }

      .t-header {
        width: 188mm;
        margin: 5mm 5mm 0mm 12mm;
        direction: rtl;
        font: 10pt "B Nazanin";
        font-weight: 400;
        text-align: right;
        height: 90px;
      }

        .t-header td {
          font: 10pt "B Nazanin";
          font-weight: 400;
          padding: 5px 5px 5px 5px;
          height: 18px;
        }


      .grayrow {
      }

      .del-t {
        border-top: none !important;
      }

      .del-b {
        border-bottom: none !important;
      }


}

  
 
          </style>
        </head>
    <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}
