import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { SaleMali } from '../../_models/General/saleMali';
import { environment } from '../../../environments/environment';
import { Menurow } from '../../_models/General/menurow';

export class Menrow {
  id: string;
  tabType: number;
  name: string;
  component: string;
  ismen: boolean;
  level: number;
  op_moruroption: any;
  op_data: any;
  fchanged: boolean;
  icon?: string;
  expanded?: boolean;
  disabled: boolean;
  items?: Menrow[];

}

@Injectable({
  providedIn: 'root'
})
export class MenurowService {

  //تب های حلقه 
  tablooplist = [{
    id: 1,
    tabType: 0,
    name: 'عمومی',
    compo: 'blankl',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }, {
    id: 2,
    tabType: 1,
    name: 'اشخاص',
    component: 'Shakhzlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }, {
    id: 3,
    tabType: 1,
    name: 'شرکت ها',
    component: 'Sherkatlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 4,
    tabType: 1,
    name: 'تعریف حساب',
    component: 'Hesablist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 5,
    tabType: 1,
    name: 'گروه تفصیلی',
    component: 'TafDastelist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 5,
    tabType: 1,
    name: ' تفصیلی',
    component: 'Tafsililist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 6,
    tabType: 1,
    name: 'سند',
    component: 'Sanadlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 7,
    tabType: 1,
    name: 'گزارش مرور حساب ها',
    component: 'Morurlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 8,
    tabType: 1,
    name: 'ریز گردش حساب ها',
    component: 'Rizgardesh',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 9,
    tabType: 1,
    name: 'سال مالی',
    component: 'SaleMali',
    ismen: false,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 9,
    tabType: 1,
    name: 'مرور حساب پرینت',
    component: 'MorurHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 9,
    tabType: 1,
    name: 'مرور حساب پرینت',
    component: 'Morur2Ht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 10,
    tabType: 1,
    name: 'شرح استاندارد',
    component: 'GSharh',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 11,
    tabType: 1,
    name: 'گزارش تراز',
    component: 'Tarazlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 12,
    tabType: 1,
    name: 'پرینت تراز',
    component: 'TarazHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 13,
    tabType: 1,
    name: 'پرینت سند',
    component: 'SanadRepHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 14,
    tabType: 1,
    name: 'پرینت ریز گردش حساب',
    component: 'RizgardeshHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 14,
    tabType: 1,
    name: 'پرینت ریز گردش حساب',
    component: 'Rizgardesh2Ht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 15,
    tabType: 1,
    name: 'تعدیل ماهیت اخر دوره',
    component: 'Tadilmahilatlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 16,
    tabType: 1,
    name: 'گردش تفصیلی',
    component: 'ghardeshTafsililist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 16,
    tabType: 1,
    name: 'پرینت گردش تفصیلی',
    component: 'ghardeshTafsiliht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 16,
    tabType: 1,
    name: 'پرینت گردش تفصیلی',
    component: 'ghardeshTafsili2ht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 17,
    tabType: 1,
    name: 'پرینت  دفاتر',
    component: 'daftarHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 18,
    tabType: 1,
    name: 'پرینت  تعدیل ماهیت اخر دوره',
    component: 'Tadilmahilatht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 19,
    tabType: 1,
    name: 'جستجو در اقلام سند',
    component: 'sanadsearch',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 20,
    tabType: 1,
    name: 'پرینت کدینگ حساب',
    component: 'hesabHtComponent',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 21,
    tabType: 1,
    name: 'بستن حساب های موقت',
    component: 'Bastanlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 22,
    tabType: 1,
    name: 'بستن حساب های دائم',
    component: 'Bastandaem',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 22,
    tabType: 1,
    name: 'سند افتتاحیه',
    component: 'starteftetah',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 23,
    tabType: 1,
    name: 'تعریف کالا',
    component: 'KalaList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 24,
    tabType: 1,
    name: ' انبار',
    component: 'AnbarList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 25,
    tabType: 1,
    name: ' واحد سنجش',
    component: 'VahedList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 26,
    tabType: 1,
    name: 'الگوی کدینگ کالا',
    component: 'GrKalaList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 27,
    tabType: 1,
    name: 'طبقه کالا',
    component: 'TabagheList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 34,
    tabType: 1,
    name: 'گزارش کاردکس ریالی',
    component: 'KardexList_Rial',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: { showRial: true },
    fchanged: false,
  },
  {
    id: 34,
    tabType: 1,
    name: 'گزارش کاردکس مقداری',
    component: 'KardexList_Tedad',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: { showRial: false },
    fchanged: false,
  },
  {
    id: 34,
    tabType: 1,
    name: 'صدور سند کل',
    component: 'DafKolList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 35,
    tabType: 1,
    name: 'پرینت سند کل',
    component: 'DafKolHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 36,
    tabType: 1,
    name: 'پرینت دفتر کل',
    component: 'DafKolAllHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,


  {
    id: 30,
    tabType: 1,
    name: 'موجودی اول دوره ',
    component: 'AnDeedList150',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 29,
    tabType: 1,
    name: 'رسید موقت',
    component: 'AnMovaList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 30,
    tabType: 1,
    name: 'رسید خرید',
    component: 'AnDeedList101',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'برگشت از مصرف',
    component: 'AnDeedList102',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'برگشت امانی ما نزد دیگران',
    component: 'AnDeedList103',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'دریافت امانی دیگران نزد ما',
    component: 'AnDeedList104',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'دریافت از تولید',
    component: 'AnDeedList105',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'برگشت از تحویل فروش',
    component: 'AnDeedList106',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'رسید انتقال بین انبار',
    component: 'AnDeedList107',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'برگشت از حواله تحویل دارایی های ثابت',
    component: 'AnDeedList108',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'رسید ضایعات',
    component: 'AnDeedList109',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'برگشت از فروش ضایعات',
    component: 'AnDeedList110',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 31,
    tabType: 1,
    name: 'اضافات انبار گردانی',
    component: 'AnDeedList111',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 32,
    tabType: 1,
    name: 'موجودی پایان دوره',
    component: 'AnDeedList-50',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 0,
    tabType: 1,
    name: 'درخواست کالا',
    component: 'DarKhastList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },

  {
    id: 32,
    tabType: 1,
    name: 'برگشت از رسید موقت',
    component: 'AnDeedList-21',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 32,
    tabType: 1,
    name: 'برگشت از خرید',
    component: 'AnDeedList-101',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 33,
    tabType: 1,
    name: 'حواله مصرف',
    component: 'AnDeedList-102',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'ارسال امانی ما نزد دیگران',
    component: 'AnDeedList-103',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'برگشت از دریافت امانی دیگران نزد ما',
    component: 'AnDeedList-104',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'مرجوعی به تولید',
    component: 'AnDeedList-105',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'تحویل فروش ',
    component: 'AnDeedList-106',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'حواله انتقال بین انبار ها',
    component: 'AnDeedList-107',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'حواله تحویل دارایی های ثابت',
    component: 'AnDeedList-108',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'برگشت از رسید ضایعات',
    component: 'AnDeedList-109',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'فروش ضایعات',
    component: 'AnDeedList-110',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'کسورات انبار گردانی',
    component: 'AnDeedList-111',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'فاکتور فروش',
    component: 'AnSellList-706',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
    },
    {
      id: 33,
      tabType: 1,
      name: 'برگشت از فروش',
      component: 'AnSellList706',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 33,
      tabType: 1,
      name: 'ابطال فروش',
      component: 'AnSellList707',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 33,
      tabType: 1,
      name: 'ابطال دستی فاکتور های تکراری ',
      component: 'AnSellList708',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 33,
      tabType: 1,
      name: 'پیش فاکتور',
      component: 'AnSellList-701',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
  {
    id: 33,
    tabType: 1,
    name: 'رسید خرید',
    component: 'AnDeedBuyList201',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },

  {
    id: 33,
    tabType: 1,
    name: 'برگشت رسید خرید',
    component: 'AnDeedBuyList-201',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'تنظیمات انبار',
    component: 'RegistryAnbar',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'پرینت فاکتور',
    component: 'AnSellRepHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 33,
    tabType: 1,
    name: 'گزارش جامع فروش',
    component: 'AnSelljameRep',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }

    ,
  {
    id: 33,
    tabType: 1,
    name: 'بستن تعدادی اسناد',
    component: 'AnBastanlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 34,
    tabType: 1,
    name: 'قیمت گذاری اسناد صادره',
    component: 'AnPrcBaslist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 35,
    tabType: 1,
    name: 'عامل گذاری اتوماتیک',
    component: 'AnAmellist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 36,
    tabType: 1,
    name: 'گزارش رتبه بندی',
    component: 'RankingList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 37,
    tabType: 1,
    name: ' رتبه بندی امتیاز تفصیلی ',
    component: 'Rankinght',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 37,
    tabType: 1,
    name: 'گزارش جزء امتیاز نفصیلی',
    component: 'Rankingrizht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 37,
    tabType: 1,
    name: 'تعیین حساب های درآمدی',
    component: 'AnSellHes1',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 37,
    tabType: 1,
    name: 'تعیین حساب های مرتبط با فروش',
    component: 'AnSellHes2',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 37,
    tabType: 1,
    name: 'گزارش جامع انبار',
    component: 'AnDeedJameRep',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'گزارش آنالیز خرید',
    component: 'AnDeedJameRep201',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'دریافت پس انداز',
    component: 'VmDarList1',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'دریافت اقساط',
    component: 'VmDarList2',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'دریافت رفاهی',
      component: 'VmDarList3',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
  {
    id: 37,
    tabType: 1,
    name: 'گروه اشخاص',
    component: 'VmGrsList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'پرداخت وام',
    component: 'VmVamList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'گزارش دریافتی',
    component: 'VmDarReplist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'عملیات تسویه',
      component: 'VmTasList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
  {
    id: 37,
    tabType: 1,
    name: 'گزارش اقساط',
    component: 'VmReplist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'برگشت از ارسال به تولید',
    component: 'AnDeedList112',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'ارسال به تولید',
    component: 'AnDeedList-112',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'گزارش تجمیعی',
    component: 'VmTajReplist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'گزارش نوبت وام',
      component: 'VmNobatReplist',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
  {
    id: 37,
    tabType: 1,
    name: 'حسابهای مرتبط',
    component: 'VmsHesEdit',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'گردش کالا',
    component: 'AnGardeshlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 37,
    tabType: 1,
    name: 'گردش کالا',
    component: 'AnGardeshlist2',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 33,
    tabType: 1,
    name: 'پرینت انبار',
    component: 'AnDeedRepHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'گزارش موجودی ریالی کالا',
    component: 'AnMojudilist_Rial',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'گزارش موجودی تعدادی کالا',
    component: 'AnMojudilist_Tedad',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
  {
    id: 33,
    tabType: 1,
    name: 'پرینت کاردکس',
    component: 'KardexHt',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 33,
    tabType: 1,
    name: 'پرینت کاردکس',
    component: 'Kardex2Ht',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }
    ,
  {
    id: 34,
    tabType: 1,
    name: 'فروش مجزا ',
    component: 'DirSellList',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  }

    ,
    {
      id: 34,
      tabType: 1,
      name: 'بانک ها',
      component: 'PdBankList1',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }
    ,
    {
      id: 34,
      tabType: 1,
      name: 'صندوق',
      component: 'PdBankList2',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }
    ,
    {
      id: 34,
      tabType: 1,
      name: 'عملیات حسابداری',
      component: 'PdHesList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }
    ,
    {
      id: 34,
      tabType: 1,
      name: 'دریافت',
      component: 'PdPayrecList10',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'پرداخت',
      component: 'PdPayrecList-10',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'دسته چک',
      component: 'PdMyCkList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'انتقال',
      component: 'PdMoveList1',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'عملیات چک های صادره',
      component: 'PdMoveList2',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'ابطال چک و سفته',
      component: 'PdMoveList5',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'وصول',
      component: 'PdMoveGroupList3',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'برداشت',
      component: 'PdMoveGroupList4',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    { 
      id: 34,
      tabType: 1,
      name: 'صدور سند حسابداری',
    component: 'PdMoveSanadList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'گزارش چک',
      component: 'PdMoveRepList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'آنالیز',
      component: 'PdLinkList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'گزارش چک های صادره',
      component: 'CheckSadereRep',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }
    ,
  
    {
      id: 34,
      tabType: 1,
      name: 'پرینت',
      component: 'vmTajRepHt',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },

    {
      id: 34,
      tabType: 1,
      name: 'پرینت',
      component: 'vmDarRepHt',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 34,
      tabType: 1,
      name: 'پرینت',
      component: 'vmRepVamHt',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'گزارش مدیریتی',
      component: 'VmMahReplist',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    
    {
      id: 37,
      tabType: 1,
      name: 'پرینت',
      component: 'vmMahRepHt',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },

    {
      id: 37,
      tabType: 1,
      name: 'معرفی دوره انبارگردانی',
      component: 'AnGarList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'شروع انبارگردانی',
      component: 'AnCountList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'بستن سند اختتامیه انبار',
      component: 'AnEndList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'سند افتتاحیه انبار',
      component: 'AnStartList',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'پرینت انبار گردانی',
      component: 'AnCount2Ht',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'گزارش چک دریافتی',
      component: 'PdPayrecCheckRep1',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'گزارش چک پرداختی',
      component: 'PdPayrecCheckRep2',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'گزارش اسناد تضمینی',
      component: 'PdPayrecTazminiRep',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 37,
      tabType: 1,
      name: 'رسید پرداخت',
      component: 'PdPayrecResidPrintHt',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }
  ];

 

  allmenu: Menurow[];

  constructor(private http: HttpClient) { }


  public GetMenowAsRow(): any {



    return this.tablooplist;

  }
  public GetMenowAsTree(): Menrow[] {



   // return this.menutree;
    return null;

  }

}
