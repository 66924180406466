import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { LoadingService } from '../../../_services/loading.service';
import { Localst } from '../../../_services/localst.service';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify.service';
import { SaleMaliService } from '../../../_services/General/SaleMali.service';
import { HesabService } from '../../../_services/app1/paye/Hesab.service';
import { Hesab } from '../../../_models/app1/paye/hesab';
import { AnSellHes, AnSellHesTab } from '../../../_models/app3/paye/AnSellHes';
import { AnSellHesService } from '../../../_services/app3/paye/AnSellHes.service';
 
 
 
 

@Component({
  selector: 'app3-AnSell-Hes',
  templateUrl: './AnSell-Hes.component.html',
  styleUrls: ['./AnSell-Hes.component.css'] 
})
  

export class AnSellHesComponent implements OnInit {
 
 
  hesablist: Hesab[] = [];
  myModelHes: AnSellHes;
  myModelTab: AnSellHesTab[] =[];
  srow: AnSellHesTab;
  @Input() tabh: number;


  loaddata: loadingCl;
  constructor( private alertify: AlertifyService,
    private route: ActivatedRoute,
    private localst: Localst,
    private loadServ: LoadingService,
    private hesabService: HesabService,
    private AnSellHesService: AnSellHesService,
 
    public dialog: MatDialog) {

    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 10);
    this.myModelHes = AnSellHesService.getAnSellHesEmpty();
    this.srow = AnSellHesService.getAnSellHesTabEmpty();

  
  }
 
  ngOnInit() {




    this.loadServ.showloading(this.loaddata, 0);
    this.hesabService.getHesabs_treenum(3).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.hesablist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.loadServ.showloading(this.loaddata, 2);
    this.AnSellHesService.getFirst_AnSellHes().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);
       
      this.myModelHes = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.loadServ.showloading(this.loaddata, 3);
    this.AnSellHesService.getFirstGroup_AnSellHesTab().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);

      this.myModelTab = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    
  }
  onClickUpdate() {

    //حساب درامدی
    if (this.tabh == 1) {

   
      this.loadServ.showloading(this.loaddata, 5);
      this.AnSellHesService.addorUpdate_AnSellHesTab(this.myModelTab).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length == 0) {
          this.alertify.success(res.mes);
        }
      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });


    }
    //حساب های مرتبط با فروش
   else if (this.tabh == 2) {

      this.loadServ.showloading(this.loaddata, 4);
      this.AnSellHesService.addorUpdate_AnSellHes(this.myModelHes).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 4);

        if (res.errors.length == 0) {
          this.alertify.success(res.mes);
        }
      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
     

    }


   
  }





  temp_moienchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hesabId = selected.id;
      cellInfo.data.hesabC = selected.codeSum;
      cellInfo.data.hesabN = selected.name;
    }
    else {

      cellInfo.data.hesabId = null;
      cellInfo.data.hesabC = null;
      cellInfo.data.hesabN = null;
    }
  }

  temp_hb201Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb201Id = selected.id;
      cellInfo.data.hb201C = selected.codeSum;
      cellInfo.data.hb201N = selected.name;
    }
    else {

      cellInfo.data.hb201Id = null;
      cellInfo.data.hb201C = null;
      cellInfo.data.hb201N = null;
    }
  }

  temp_hb202Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb202Id = selected.id;
      cellInfo.data.hb202C = selected.codeSum;
      cellInfo.data.hb202N = selected.name;
    }
    else {

      cellInfo.data.hb202Id = null;
      cellInfo.data.hb202C = null;
      cellInfo.data.hb202N = null;
    }
  }

  temp_hb204Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb204Id = selected.id;
      cellInfo.data.hb204C = selected.codeSum;
      cellInfo.data.hb204N = selected.name;
    }
    else {

      cellInfo.data.hb204Id = null;
      cellInfo.data.hb204C = null;
      cellInfo.data.hb204N = null;
    }
  }
  temp_hb205Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb205Id = selected.id;
      cellInfo.data.hb205C = selected.codeSum;
      cellInfo.data.hb205N = selected.name;
    }
    else {

      cellInfo.data.hb205Id = null;
      cellInfo.data.hb205C = null;
      cellInfo.data.hb205N = null;
    }
  }


  temp_hesab706changed(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hesab706Id = selected.id;
      cellInfo.data.hesab706C = selected.codeSum;
      cellInfo.data.hesab706N = selected.name;
    }
    else {

      cellInfo.data.hesab706Id = null;
      cellInfo.data.hesab706C = null;
      cellInfo.data.hesab706N = null;
    }
  }

  temp_hesab707changed(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hesab707Id = selected.id;
      cellInfo.data.hesab707C = selected.codeSum;
      cellInfo.data.hesab707N = selected.name;
    }
    else {

      cellInfo.data.hesab707Id = null;
      cellInfo.data.hesab707C = null;
      cellInfo.data.hesab707N = null;
    }
  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);

  }
  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
      

  onFocusedRowChanged(e) {

    if (e.row == null) {

      this.srow = this.AnSellHesService.getAnSellHesTabEmpty();
    }

    this.srow = e.row.data;

  }

}


