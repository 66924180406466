import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';

import { AlertifyService } from '../../../../_services/alertify.service';



import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MyTimeService } from '../../../../_services/myTime.service';
import { LoadingService } from '../../../../_services/loading.service';
import { ExcelService } from '../../../../_services/excel.service';
 
import { AnEndService } from '../../../../_services/app2/end/AnEnd.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { AnEnd } from '../../../../_models/app2/end/AnEnd';

 

 
@Component({
  selector: 'app2-AnEnd-Show',
  templateUrl: './AnEnd-Show.component.html',
  styleUrls: ['./AnEnd-Show.component.css']

})
export class AnEndShowComponent implements OnInit {
  
  width: number | null = null;

  onResize(width: number) { 
    this.width = width;
  }
  lastrowidtemplate: number;
  baseUrl = environment.apiUrl;
 

  @Output() OnMyPrintEvent = new EventEmitter<any>();


  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Input() editId: number;
  
 
 
  myModel: AnEnd;
  loaddata: loadingCl;

  constructor(
    private alertify: AlertifyService,
    private loadServ: LoadingService,
    private anEndService: AnEndService,

    private localst: Localst,
    public dialog: MatDialog,
 
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);

    
 
    this.myModel = this.anEndService.getAnEndEmpty();
 
  }
 
 
  ngOnInit() {



    this.loadServ.showloading(this.loaddata, 1);
    this.anEndService.getbyid(this.editId).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.myModel = res.data;
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }


  cancelthis(e) {
    this.OnMyCancelEvent.emit(true);
  }
   
}
