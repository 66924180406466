import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import { BankM, ChekM, DarM, DarMRow, HItem, KindHaz, PerM } from '../../../../_models/app99/app99model';
import { Localst } from '../../../../_services/localst.service';
 
let comp = 'DarMEdit';

@Component({
  selector: 'app99-DarM-edit',
  templateUrl: './DarM-edit.component.html',
  styleUrls: ['./DarM-edit.component.css']
})
export class DarMEditComponent implements OnInit {

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: DarM;
  myModel_old: DarM;
  focusedRowKey: number;
  cssgrid: string='';
 
   perMlist: any[]=[];
 
  KindHazlist: KindHaz[]=[];
  ParKindlist: any[]=[];
  BankMlist: BankM[]=[];
  perMForhaveGhars: any[]=[];
 

  sGhartinylist: any[] = [];
  babkilist: any[];

  selectedrow: DarMRow;
  chekm: ChekM;
 DarMcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  selectedtab: number;
  gridcost: number;
  visitorMlist: any[] = [];

 
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
 
    private localst: Localst,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
    this.selectedtab = 0;
    this.focusedRowKey = -1;
    this.myModel = this.app99Service.DarM_getDarMEmpty();
    this.myModel.chekm  = this.app99Service.ChekM_getChekMEmpty();
    this.myModel.chekmId = -1;

    this.DarMcodelist = [];
    this.gridcost = 0;
    this.ParKindlist = this.app99Service.DarMRow_getParKindList();
    this.selectedrow = this.app99Service.DarMRow_getDarMRowEmpty();
    this.babkilist = this.app99Service.DarMRow_getBabKindList();
  }

  ngOnInit() {
 
    this.loadother();
    




    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.DarM_getallCods().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.DarMcodelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.loadServ.showloading(this.loaddata, 6);
    this.app99Service.PerM_getallForList(1).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.visitorMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 6);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


    this.isnew = true;
 
    if (this.editId == -1) {
 
  
      try {
        this.myModel.dateA_S = this.localst.dateEmruz.dateString;
        this.myModel.chekm.dateSabt_S = this.localst.dateEmruz.dateString;
    
  

      }
      catch {

      }
   
 

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.DarM_getbyid(this.editId).subscribe(
        (data : DarM) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          if (this.myModel.rows.length>0)
            this.focusedRowKey = this.myModel.rows[0].id;

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));

          this.calcsum();

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }

 
  addthis = e => {
 
    
  }

  loadother() {

    this.loadServ.showloading(this.loaddata, 4);
    this.app99Service.PerM_getallForList(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.perMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


 

    this.loadServ.showloading(this.loaddata, 6);
    this.app99Service.KindHaz_getall().subscribe(
      (data: KindHaz[]) => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.KindHazlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 6);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );

    this.loadServ.showloading(this.loaddata, 7);
    this.app99Service.BankM_getall(comp).subscribe(
      (res) => {
        this.loadServ.hideloading(this.loaddata, 7);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        this.BankMlist = res.data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 7);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );



    this.loadServ.showloading(this.loaddata, 8);
    this.app99Service.PerM_getallForhaveGhars(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.perMForhaveGhars = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 8);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


  }
  onValueChangedper(e) {

    const previousValue = e.previousValue;
    const newValue = e.value;
    if (previousValue != null) {
      this.sGhartinylist = [];
      this.myModel.rows = [];
    }


    if (e.value == null) {
      return;
    }

    this.loadghar(e.value);

  }

  loadghar(value) {
    this.loadServ.showloading(this.loaddata, 9);
    this.app99Service.Ghar_GetAllAsGhartiny(-1, value).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 9);
        this.sGhartinylist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 9);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );

  }

  onValueChangedrad(e) {

    if (e.value == 2) {
      this.cssgrid = 'mydisble'
      this.sGhartinylist = [];

      this.myModel.rows = [];
    }
    else {

      this.cssgrid = '';

      if (this.myModel.permId != null) {
         
        this.loadghar(this.myModel.permId);

      }
     
    }
     






  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }


    console.log(this.myModel);
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.DarM_add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
   
     
 
        //ای دی  ذخیره شده را میگیریم
        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.success[0]);
          this.OnMySaveEvent.emit(true);
      
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.DarM_update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);

        //ای دی  ذخیره شده را میگیریم
        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.success[0]);
          this.OnMySaveEvent.emit(true);

        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    //if (this.editId != -1)
    //if (this.myModel_old.cMelli != null)
    //  if (this.myModel_old.cMelli == v)
    //    return true;

    for (var val of this.DarMcodelist) {
      if (v == val) return false;
    }

    return true


  }
 
 

  validateNumber(e) {

    

  }

  onSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  } 
  ghar2tempchanged(e, cellInfo) {


    cellInfo.setValue(e.value);
    for (var r of this.sGhartinylist) {
      if (r.id == e.value) {

      
     
        cellInfo.data.gharDis = r.dis;

        break;
      }


    }
 
  }
 
 

  costchanged(e, cellInfo) {


    var newValue = e.value;

    cellInfo.setValue(newValue);

   
 
 
    this.calcsum();
  }
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    for (var _i = 0; _i < this.myModel.rows.length; _i++) {


      if (this.myModel.rows[_i].id == f.id) {

 
        this.selectedrow = this.myModel.rows[_i];
      }



    }




    //console.log('selectedrow : ' + this.selectedrow.id);
    //this.focusedRowKey = this.selectedrow.id;
    //console.log(e.rowIndex);


    //this.pushnewrow(true);

    //console.log('now index' + e.row.rowIndex);
    //console.log('now index hesabid' + e.row.data.hesabId);
    //if (e.row.data.hesabId == null) {
    //  this.dataGrid.instance.editRow(e.row.rowIndex);
 

    //}
    //// else this.dataGrid.instance.cancelEditData();

    //this.tafg_reflesh(this.selectedrow.hesabId);

  }
  ononInitNewRow(e) {



  }
  calcsum( ) {

 
    this.dataGrid.instance.saveEditData();

   // var rd = this.dataGrid.instance.getKeyByRowIndex(this.dataGrid.focusedRowIndex);

    this.gridcost = 0;
    for (var r of this.myModel.rows) {
     
      this.gridcost += r.cost;
    }
    return this.gridcost;


  }
  addrow() {

    let nid = 0;
    for (var d of this.myModel.rows) {
      if (d.id < nid)
        nid = d.id
    }
    nid--;
    let ne = this.app99Service.DarMRow_getDarMRowEmpty();
    ne.id = nid;
 
    ne.cost = 0;
 
 
    this.myModel.rows.push(ne);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
    
      {
        location: 'right',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: this.addrow.bind(this)
        }
      });


  }
  selectedtabchang(e) {
    this.selectedtab = +e;
    
 
  }
  parKindchanged(e) {

    if(e.value !=2)
    this.myModel.mos = '';


  }

  onClickReflesh() {

    this.loadother();


  }
}
