import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { TafDaste, TafDasteSort } from '../../../_models/app1/paye/TafDaste';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { CryptoService } from '../../crypto.service';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyTafDaste: TafDaste = {
  id: -1,
  codeStartint: null,

  codeStart: null,


  name: null,

  nameE: null,

  pedarId: null,

  pedarName: null,
  fatherChek: false,
  tol: null,
  dis: null,
  radif: null,
  show: null,
  tafsiliList: null,
  dropsee: null,
  ischecked: false
   
  
 

};
let HeaderTafDaste: any = {
 
  id: 'id',
 
  codeStartint: 'کد شروع',
 
  codeStart: 'کد شروع',


  name: 'نام',

  nameE: null,

  pedarId: null,

  pedarName: null,
  fatherChek: null,
  tol: null,
  dis: null,
  radif: 'ردیف',
  show: null,
  tafsiliList: null,
  dropsee: null,

};

@Injectable({
  providedIn: 'root'
})
export class TafDasteService {


  baseUrl = environment.apiUrl;
  origin = location.origin;
 

  constructor(private http: HttpClient, private crypto: CryptoService, private localstService: Localst) {

  }

  getTafDastes(): Observable<TafDaste[]> {



  
    let apiadress = '/api/App1/Paye/TafDaste/getallnopage?inctafsili=false';
    var headers = this.localstService.header_get(apiadress,null);

    

    return this.http.get<TafDaste[]>(this.origin + apiadress, { 'headers': headers });
  }




  getTafDastesDynamic(inctafsili: boolean): Observable<TafDaste[]> {

    let apiadress = '/api/App1/Paye/TafDaste/getallnopageDynamic?inctafsili=' + inctafsili;
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<TafDaste[]>(this.origin + apiadress, { 'headers': headers });
     

  }
 
  getTafDaste(id): Observable<TafDaste> {

    let apiadress = '/api/App1/Paye/TafDaste/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<TafDaste>(this.origin + apiadress, { 'headers': headers }).pipe(
     
    );
  
    return v;
    // return this.http.get<User>(this.origin + 'users/' + id, httpOptions);
  }

  addTafDaste(item: TafDaste) {
    let apiadress = '/api/App1/Paye/TafDaste/addTafDaste';
    var headers = this.localstService.header_get(apiadress,null);

    

    return this.http.post(this.origin + apiadress, item, { 'headers': headers } );
  }



  getTafDasteEmpty(): TafDaste {

 

    return  JSON.parse(JSON.stringify(EmptyTafDaste));

  }

  getTafDasteHeader(): TafDaste {
  

    return HeaderTafDaste;


  }
  updateTafDaste(item: TafDaste) {

    let apiadress = '/api/App1/Paye/TafDaste/updateTafDaste';
    var headers = this.localstService.header_get(apiadress,null);

    

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  deleteTafDaste(TafDasteId: number) {
    let apiadress = '/api/App1/Paye/TafDaste/deletebyid?id=' + TafDasteId.toString();
    var headers = this.localstService.header_get(apiadress,null);

  
    return this.http.delete<number>(this.origin + apiadress, { 'headers': headers });
  }
 
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/TafDaste/countTafDastecode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);


  

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  TafDasteListCode(): Observable<number[]> {

    let apiadress = '/api/App1/Paye/TafDaste/TafDastecodelist';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<number[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
  SortCodes(item: TafDasteSort): Observable<ApiRet> {

    let apiadress = '/api/App1/Paye/TafDaste/SortCodes';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });


  }
}
