import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { SMoad } from '../../../_models/app2/paye/SMoad';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptySMoad: SMoad = {

  id: -1,
  smId: null,
  sn: null,
  tp: null,
  taxId: null,
  tax: null,
  vat: null,
  kind:null

};

let smoadKinds: any[] = [
  {
    id: 1,
    name: 'شناسه عمومی تولید داخل(کالا)'
  },
  {
    id: 2,
    name: 'شناسه اختصاصی تولید داخل(کالا)'
  },
  {
    id: 3,
    name: 'شناسه عمومی وارداتی(کالا)'
  },
  {
    id: 4,
    name: 'شناسه اختصاصی وارداتی(کالا)'
  },
  {
    id: 5,
    name: 'شناسه عمومی(خدمت)'
  },
  {
    id: 6,
    name: 'شناسه اختصاصی(خدمت)'
  }
];
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class SMoadService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<SMoad[]> {
    let apiadress = '/api/App2/Paye/SMoad/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<SMoad[]>(this.origin + apiadress, { 'headers': headers });
  }

  getallSearch(searchtext: string, kinds: number[]): Observable<SMoad[]> {

    if (kinds == null || kinds == undefined) {
      kinds = [0];
    }
    if (kinds.length == 0 ) {
      kinds = [0];
    }
    let kindstring = '';
    for (var i = 0; i < kinds.length; i++) {
      kindstring += '&kinds=' + kinds[i].toString();

    }
    
    

    let apiadress = '/api/App2/Paye/SMoad/getallSearch?searchtext=' + searchtext + kindstring ;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<SMoad[]>(this.origin + apiadress, { 'headers': headers });
  }


 
  getbyid(id): Observable<SMoad> {
    let apiadress = '/api/App2/Paye/SMoad/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<SMoad>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: SMoad) {
    let apiadress = '/api/App2/Paye/SMoad/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getSMoadEmpty(): SMoad {

 
    return JSON.parse(JSON.stringify(EmptySMoad));

  }
 
  update(item: SMoad) {
    let apiadress = '/api/App2/Paye/SMoad/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  delete(SMoadId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/SMoad/delete?id=' + SMoadId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getsmoadKinds(): any[] {

    return smoadKinds;

  }
}
