import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
 
 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { PdPayrecCheckRep } from '../../../_models/app4/rep/PdPayrecCheckRep';
import { ApiRet } from '../../../_models/General/ApiRet';
import { ExcelService } from '../../../_services/excel.service';
import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { Localst } from '../../../_services/localst.service';
import { PdPayrecRepService } from '../../../_services/app4/rep/PdPayrecRep.service';
 
 

@Component({
  selector: 'app4-PdPayrecCheck-Rep',
  templateUrl: './PdPayrecCheck-Rep.component.html',
  styleUrls: ['./PdPayrecCheck-Rep.component.css']
})
 

export class PdPayrecCheckRepComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @Input() PdPayrecCheckOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();
  ///نوع چک داخلی یا خارجی
  @Input() PaykindIn: number;


  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;
 

  focusedSabtId: number;
  
 
 
  selectedrow: any;
 

  model: PdPayrecCheckRep;
 
 
 
 
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
 
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private pdPayrecRepService: PdPayrecRepService,
 
 
 
 
 
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    this.openedit= false;
    this.openeditId = -1;
    this.focusedSabtId = -1;
 
    this.loadServ.create(this.loaddata,8);



    this.model = new PdPayrecCheckRep();
 
   


  }
 
  ngOnInit() {


    this.loadServ.showloading(this.loaddata, 4);
    this.pdPayrecRepService.DateRangPdPayrec(this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 4);

        this.model.option.dateAS_S = res.data.dateA_S;
        this.model.option.dateAE_S = res.data.dateB_S;

        this.model.option.dateBS_S = res.data.dateA_S;
        this.model.option.dateBE_S = res.data.dateB_S;

        this.allDataLoaded(4);
      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });


    //this.loadServ.showloading(this.loaddata, 3);
    //this.pdPayrecRepService.getRep()
    //  .subscribe((res: ApiRet) => {
    //    this.loadServ.hideloading(this.loaddata, 3);

       
 

    //    this.allDataLoaded(3);

    //  }, error => {
    //    this.loadServ.hideloading(this.loaddata, 3);
    //    this.alertify.error(error);
    //  });


       
  
  }

 
 
  allDataLoaded(num : number) {
 


  }



  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,' کاردکس کالا ');

   // this.excelService.exportAsExcelFile(this.modelsearch, 'sample');

  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }

  onClickReflesh() {

    
 


    //انبار های سلکت شده را به مدل میدیم

    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم
    //if (this.dxliststr!= undefined) {
    //  var keys = this.dxliststr.selectedItemKeys;
    //  this.model.option.anbarIds = [];
    //  if (keys != null)
    //    for (var j = 0; j < keys.length; j++) {

    //      this.model.option.anbarIds.push(keys[j].id);
    //    }
    //}
    this.model.option.paykindIn = this.PaykindIn;

    this.loadServ.showloading(this.loaddata, 5);
    this.pdPayrecRepService.GetPdPayrecCheckRep(this.model.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        //tyytu676767
        this.model = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


  adjustWidth(e) {

    e.component._popup.option('width', 1000);

  }
 
  OnMyCancelEvent(e) {

    this.openedit = false;
  }
  OnMySaveEvent(e) {

    this.openedit = false;

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

 

  firstclreate = true;
 

  dropopened(e) {


    e.component._popup.option('width', 500);
    
    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

      //if (this.model.option.anbarIds.length > 0) {

      //  for (var r of this.model.option.anbarIds) {
      //    this.dxliststr.instance.selectItem(r);
      //  }
      //}
      //else {
      //  this.dxliststr.instance.selectAll();
      //}
       

      this.firstclreate = false;

    }
  }


 
  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    if (e.column.dataField == "prcMan") {

      if (e.data.anDeedC == 0) {
        if (e.data.prcMan > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.amtMan < 0) {
          e.cellElement.style.backgroundColor = '#ca0000';

        }
 
      }
    }
 
    if (e.column.dataField == "prcOut") {

      if (e.data.anDeedC == 0) {
        

        if (e.data.prcOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.prcOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }

      }
    }


    if (e.column.dataField == "fiOut") {

      if (e.data.anDeedC == 0) {



        if (e.data.fiOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.fiOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }
      }
    }


 

   



  }
  onRowPrepared(e) {

    // console.log(e.data);
    if (e.data == undefined)
      return;
     
    //hjg5565655
    if (e.data.kind == 9  ) {
 
      e.rowElement.style.color = '#19bf00';
    //  e.rowElement.style.border = '1px solid';

     
    }
   else if (e.data.kind == -9) {

      e.rowElement.style.color = '#ca0000';
    //  e.rowElement.style.border = '1px solid';

    }

     if (e.data.anDeedC == 0) {


      e.rowElement.style.border = '1px solid';

    }





  }


  onClickgoSanad() {

   
  }


  calculateSelectedRow(options) {
    //if (options.name === 'amtManSummary') {

    //  if (options.summaryProcess === 'start') {
    //    options.totalValue = 0;
    //  }
    //  else if (options.summaryProcess === 'calculate') {
    //  //  if (options.component.isRowSelected(options.value.ID)) {
    //      options.totalValue = options.value.amtMan;
    //  //  }
    //  }
    //}
    if (options.name === 'amtManSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
        //آخرین ردیف همراه با اصلاحش را با هم جمع میکنیم
        if (options.value.anDeedC == 0 ) {
          //اگر ردیف اصلاح بود از را جایگزین نمیکنیم و با موجودی قبل جمع میکنیم
          options.totalValue += options.value.amtMan;
        }
        else {
          //اگر به ردیف جدید رسیدیم اخرین ردیف را جایگزین میکنیم
          options.totalValue = options.value.amtMan;
        }

        //}
      }
    }
    //fdgh7yhg
    if (options.name === 'prcManRowsSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
       //آخرین ردیف همراه با اصلاحش را با هم جمع میکنیم
        if (options.value.anDeedC == 0) {
          //اگر ردیف اصلاح بود از را جایگزین نمیکنیم و با موجودی قبل جمع میکنیم
          options.totalValue += options.value.prcMan;
        }
        else {
          //اگر به ردیف جدید رسیدیم اخرین ردیف را جایگزین میکنیم
          options.totalValue = options.value.prcMan;
        }
       
        //  }
      }
    }
  }

  saveuioption() {

    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2013 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw2013.length; i++) {
      this.localst.optioncli.gw2013[i] *= e;
    }

  }
  defwidth() {

    this.localst.optioncli.gw2013 = this.localst.optionClient_readDefault('gw2013');

  }


  onClickprint(kind) {


 


    var mdata = { model: JSON.parse(JSON.stringify(this.model)), printKind: kind }

    this.onPrint.emit(mdata);

  }
 
}


