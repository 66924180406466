import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import {  MatTab, MatTabGroup } from '@angular/material';
import { Menurow } from '../_models/General/menurow';
import { MenurowService } from '../_services/General/menurow.service';

@Component({
  selector: 'app-Menurow',
  templateUrl: './Menurow.component.html',
  styleUrls: ['./Menurow.component.css']
})
export class MenurowComponent implements OnInit {

   
  @Input() rowdata: Menurow;

 
 
  constructor(public authService: MenurowService, private alertify: AlertifyService, 
    private router: Router) {

 
  }

  ngOnInit() {



  }
 
}
