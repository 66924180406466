import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { Morur, MorurOption } from '../../../_models/app1/rep/Morur';
import { IdName } from '../../../_models/General/IdName';
import { Taraz } from '../../../_models/app1/rep/Taraz';
import { Localst } from '../../localst.service';
import { tarazHesabsFilter } from '../../../_models/app1/rep/tarazHesabsFilter';
import { DaftarPack } from '../../../_models/app1/rep/DaftarPack';
import { DafKol, DafKolRow, DafKolSanId } from '../../../_models/app1/rep/DafKol';
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
 
@Injectable({
  providedIn: 'root'
})
export class DafKolService {
  
  origin = location.origin;
  
  EmptyDafKol : DafKol = {
    id: 0,
    code: 0,
    dateA: 0,
    dateA_S: null,
    dateB: 0,
    dateB_S: null,
    dateS: 0,
    dateS_S: null,
    cShomareA: null,
    cShomareB: null,
    rows: [],
    sanIds: [],
    year: null,
    bed: 0,
    bes: 0
}

EmptyDafKolRow: DafKolRow = {
  id:0,
  dafKolId: null,
  hesabId: null,
  hesabC: null,
  hesabN: null,
  bed: 0,
  bes: 0,
  radif: 0,
}

  EmptyDafKolSanId: DafKolSanId= {
    id: 0,
    dafKolId: null,
    sanId: null,
}

 

 

  constructor(private http: HttpClient, private localstService: Localst) { }

  add(data: DafKol): Observable<ApiRet> {
 
    let apiadress = '/api/App1/Rep/DafKol/add';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }
  getall(year: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/DafKol/getall?year=' + year;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });



  }
  getbyid(id: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/DafKol/getbyid?id=' + id;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });



  }
  mandeh(year: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/DafKol/mandeh?year=' + year;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  delete(id: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/DafKol/delete?id=' + id;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers });



  }


  getDafKolEmpty(): DafKol {

    var a = JSON.parse(JSON.stringify(this.EmptyDafKol)) as DafKol;
    a.rows = [];
    a.sanIds = [];
    return a;
  }

  getDafKolRowEmpty(): DafKolRow {

    return JSON.parse(JSON.stringify(this.EmptyDafKolRow)) as DafKolRow;
 
  }


  DafKolAll(year: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/DafKol/DafKolAll?year=' + year;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
