import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
 
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app2-Anbar-list',
  templateUrl: './Anbar-list.component.html',
  styleUrls: ['./Anbar-list.component.css']
})


export class AnbarListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;

 

  modlelist: Anbar[];
 
 
 
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private Anbar_Service: AnbarService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
    this.modlelist = [];
 
  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();

  }
 
 
  
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.Anbar_Service.getall()
      .subscribe((res: Anbar[]) => {
        this.modlelist = [];
        this.modlelist = res;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
 
 
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

   // console.log(JSON.stringify(this.selectedrow));

  }
 


  onClickDelete() {

    if (this.loaddata.showed)
      return;

    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
     
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {


    this.loadServ.showloading(this.loaddata, 1);
    this.Anbar_Service.delete(this.selectedrow.id).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
      else { 
        this.alertify.success(res.mes);
        this.load();

      }
   

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }







  onClickEdit() {

    if (this.loaddata.showed)
      return;
    if (this.selectedrow.id == null) {



      return;
    }



    this.openeditId = this.selectedrow.id;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.load();

  }
  onClickExportexcel() {

 

    this.excelService.exportAsExcelFile(this.modlelist, 'لیست اشخاص');
  }

  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }
}
