import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnBastan } from '../../../_models/app2/glob/AnBastan';
 
 
let EmptyAnBastan: AnBastan = {

 
    id: 0,
  kind: null,
  years: null,
  anbarId: null,
  anbarN: null,
  dateA: null,
  dateA_S:null,
  _butVaz:0,
  _dbDate: null,
   datelast:null,

};
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnBastanService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getallByKind(kind: number , years : number): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnBastan/getallByKind?kind=' + kind.toString() + '&years=' + years.toString() ;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
  getAnBastanEmpty(): AnBastan {

 
    return JSON.parse(JSON.stringify(EmptyAnBastan));

  }


  update(item: AnBastan) {
    let apiadress = '/api/App2/Glob/AnBastan/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
 
}
