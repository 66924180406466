import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User, UserForAcc } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../_models/pagination';

import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OstanService } from '../../../_services/app1/paye/Ostan.service';
import { mySF } from '../../../../../my_modules/mySF';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../dialog/deldialog.component';
import { GeneralService } from '../../../_services/General/General.service';
 
import { Localst } from '../../../_services/localst.service';
import {  AccForEdit } from '../../../_models/General/Acc';
 
import { LoadingService } from '../../../_services/loading.service';
import { AccService } from '../../../_services/General/Acc.service';

@Component({
  selector: 'app-Acc-edit',
  templateUrl: './Acc-edit.component.html',
  styleUrls: ['./Acc-edit.component.css']
})
export class AccEditComponent implements OnInit {

 
 
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() cancelRegister = new EventEmitter();
 
  myModel: AccForEdit[];
  loaddata: loadingCl;

  shkhscodelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
   
  id: number;
  
  test: string;
    n: number;
  mytitle: string;
 
  users: any[] = [];

  selectvalue: string;
  selectuser: UserForAcc;
  KindList: IdName[];
  editclass: string;

  userType: number = 0;

  constructor(private userService: UserService, private alertify: AlertifyService,

    private generalfoService: GeneralService,
    private accService: AccService,
  
    private localst: Localst,
    private loadServ: LoadingService,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private sf: mySF,
  ) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 10);
 


    this.validationCallback = this.validationCallback.bind(this);  


    this.selectuser = this.userService.getUserForAccEmpty();
 
    this.editclass = 'mydisble';
  //  this.myModel = generalfoService.em();
  }

  ngOnInit() {
   

 
    
    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else{
        this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
 
    this.isnew = true;



    this.reloadlist();
  }







  onFocusedRowChanged(e) {



  }
  addthis = e => {


  }
  cancelthis = e => {


  }

  reloadlist() {

 


  }


  onFormSubmit(e) {
 
    console.log("m:submitform");
 


 

 
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

  onValueChangeduser(e) {

    //hu546u56

    for (var r of this.users) {
      if (r.id == e.value) {
        this.selectuser = r;
        this.loaduserAcc(this.selectuser.customerName, this.selectuser.username );
        break;

      }
    }
 
  }

  loaduserAcc(cu: string, un: string) {


    if (cu == null || un == null)
      return;

    this.loadServ.showloading(this.loaddata, 2);
    this.accService.getAccOnUcKey(cu, un, this.isAllowMe).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 2);

      this.myModel = data.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
  }



  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
  

  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    return true

    let v = e.value;


    for (var val of this.shkhscodelist) {

      if (v == val) return false;
    }
    return true



}


  validateNumber(e) {

    

  }

  numberBox_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

 
   
    
     
  }
  
  datestart_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log(newValue);

    let t =  this.sf.calcOtherThing(2);

 
  
   console.log(t);
 
  }

 

  
 
  onSaveEdit() {


   

    this.loadServ.showloading(this.loaddata, 3);
    this.accService.UpdateAllow(this.myModel).subscribe( res  => {
      this.loadServ.hideloading(this.loaddata, 3);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        if (this.isAllowMe) {
          const u = this.localst.usernow_get();
          this.loaduserAcc(u.customerName, u.username);
        } else {

          this.loaduserAcc(this.selectuser.customerName, this.selectuser.username);
        }
       
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }

  clickAccRow(data, allow) {

    //data.allow = allow;
    for (var i = 0; i < this.myModel.length ; i++) {
      if (this.myModel[i].id == data.id) {

        //yrty4574577u
        this.myModel[i].allow = allow;

 
        break;
      }

    }

   
  }
  cssColor: string = '';
  isAllowMe: boolean = false;
  onGoisAllowMe() {

    this.myModel = [];
    this.isAllowMe = !this.isAllowMe;


    if (this.isAllowMe) {
      this.cssColor = 'csscolor';
      const u = this.localst.usernow_get();
      this.loaduserAcc(u.customerName, u.username);
    }
    else {
      this.selectuser = this.userService.getUserForAccEmpty();
       
      this.cssColor = '';
    }


  }

}

class MyStaticFunction2 {
  static pi = 3.14;

  static ChekExistDate(radius: string) {
    return true;
  }


  static calculateArea(radius: number) {
    return this.pi * radius * radius;
  }

  calculateCircumference(radius: number): number {
    return 2;
  }




}
