import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { Localstclass } from '../_models/localstclass';
import { SaleMaliService } from './General/SaleMali.service';
import { SaleMali } from '../_models/General/saleMali';
import { sabtCopyTemp } from '../_models/app1/opr/sabtCopyTemp';
import { Customer } from '../_models/customer';
import { StorageService } from './storage.service';
import { CryptoService } from './crypto.service';
import { SherkatInfo } from '../_models/General/sherkatInfo';
import { optionClient } from '../_models/optionClient';
import { MyTimeClass } from '../_models/General/myTimeClass';
import { MyClipboard } from '../_models/General/MyClipboard';

import { Registry } from '../_models/General/Registry';
import { VmDarEditComponent } from '../app98/opr/vmDar/vmDar-edit/VmDar-edit.component';
import { Router } from '@angular/router';






@Injectable({
  providedIn: 'root'
})
export class Localst {

  OnMySalshChanged = new EventEmitter<boolean>();

  OnMyNavloaded = new EventEmitter<boolean>();
  dateEmruz: MyTimeClass;

  cheatlib: number;

  customer: Customer;
  salemali: SaleMali;



 


/*  regs: Registry[] = [];*/


  sherkatInfo: SherkatInfo;
  optioncli: optionClient;

  lll: any =15;
 // baseUrl = environment.apiUrl;
  baseUrl = location.origin + '/api/';
  lcclass: Localstclass;
  
 //public logined: boolean = false;

   
  sabtCopyList: sabtCopyTemp[];
  clipboardList: MyClipboard[];

  constructor(private http: HttpClient,
 
    private storageService: StorageService,
    private crypto: CryptoService,
    private router: Router,

  ) {
    this.clipboardList = [];

    //this.lll = 15;
    if (this.sabtCopyList == null) {

      this.sabtCopyList = [];
    }

    this.sherkatInfo = null;
    this.optionClient_load();
    if (this.cheatlib == undefined)
      this.cheatlib = 0;
    ////
    //console.log('ooooooooohgoooooo');
    //console.log(this.optionClient_load());
    //if (this.optioncli == undefined) {

    //  if (this.optionClient_load() == null) {
    //    this.optioncli = JSON.parse(JSON.stringify(this.optionClientDefault));
    //    console.log(this.optioncli);
    //  }
        

    //}
    //console.log('ooooooooooooooo');
    //console.log(this.optioncli);

  }


 allStorage() {

  var archive = [],
    keys = Object.keys(localStorage),
    i = 0, key;

  for (; key = keys[i]; i++) {
    archive.push(key + '=' + localStorage.getItem(key));
  }

  return archive;
}

  sherkatInfo_set(sh: SherkatInfo) {

    this.sherkatInfo = sh;
    localStorage.setItem('sherkatInfo', JSON.stringify(this.sherkatInfo));
  }

  sherkatInfo_get(): SherkatInfo {

    if (this.sherkatInfo == null || this.sherkatInfo == undefined) {

      var retrievedObject = localStorage.getItem('sherkatInfo');
      this.sherkatInfo =JSON.parse(retrievedObject);
    }
    return this.sherkatInfo;
  }
  lastSelectedSalDB_set(dbname: string ,Sal:number) {



    let stg = JSON.parse((localStorage.getItem("lastdbSal")))  as any[];
    if (stg == null) {
      stg = []
    }

    let exist = false;
    for (var r of stg) {

      if (r.db == dbname) {
        r.sal = Sal;
        exist = true;
      }


    }
    if (!exist) {
      stg.push({ db:dbname, sal:Sal});
    }

    localStorage.setItem('lastdbSal', JSON.stringify(stg));

  }
  
  lastSelectedSalDB_get(dbname: string): number {

    let stg = JSON.parse((localStorage.getItem("lastdbSal"))) as any[];
    if (stg == null) {
      stg = []
    }



    for (var r of stg) {

      if (r.db == dbname) {
       return r.sal;
      }
    }


    return -1;
  }



  yearnow_set(y: SaleMali): number {

    this.salemali = JSON.parse(JSON.stringify(y));
    localStorage.setItem('yearnow', y.codeSal.toString());

    localStorage.setItem('yearsnowClass', JSON.stringify(y));

   
    return y.codeSal;
  }
 
  yearnow_get(): number {

    let techStack = localStorage.getItem("yearnow");
    if (techStack == null) return null;
    return parseInt(techStack);
  }



  yearnow_Class_get(): SaleMali {
    return this.salemali;
    this.salemali = JSON.parse( JSON.stringify( localStorage.getItem('yearsnowClass')));
    return this.salemali;

  }


  set_nowtimeEmruz(emruz: MyTimeClass) {


    this.dateEmruz = emruz;
   // this.datenowMonth = nowpersianstring.substring(4,2);

  }
 

  usernow_set(user: User): User {

   
    localStorage.setItem('usernow', JSON.stringify(user));
 

    return user;
  }
  usernow_get(): User {

    var retrievedObject = localStorage.getItem('usernow');

   
    
    return JSON.parse(retrievedObject);
  }
  clipboard_set(id: string, data: any): boolean {

    let temp = new MyClipboard(id);
    temp.data = data;

    this.clipboardList.forEach((item, index) => {
      if (item.id === id) this.clipboardList.splice(index, 1);
    });

    this.clipboardList.push(temp);


    return true;
  }
  clipboard_get(id: string): any {


    for (var r = 0; r < this.clipboardList.length; r++) {

      if (this.clipboardList[r].id == id) {
        return JSON.parse(JSON.stringify(this.clipboardList[r].data));

      }

    }

    return null;

  }


  sabtcopytemplate_set(sabtCt: sabtCopyTemp): sabtCopyTemp {

    this.sabtCopyList.forEach((item, index) => {
      if (item.idName === sabtCt.idName) this.sabtCopyList.splice(index, 1);
    });

    this.sabtCopyList.push(sabtCt);

   // localStorage.setItem('ct' +sabtCt.idName, JSON.stringify(sabtCt));


    return sabtCt;
  }
  sabtcopytemplate_get(sabtCtidname: string): sabtCopyTemp {

 //   var retrievedObject = localStorage.getItem('ct' + sabtCtidname);

    for (var r = 0; r < this.sabtCopyList.length; r++) {

      if (this.sabtCopyList[r].idName == sabtCtidname) {

     //   var valu = this.sabtCopyList[r];
        return JSON.parse(JSON.stringify(this.sabtCopyList[r]));

      }

    }

    return new sabtCopyTemp(sabtCtidname);

  }

  sherkatsList_set(sabtCt: Customer[]): Customer[] {

    this.storageService.secureStorage.setItem('Customerlist', JSON.stringify(sabtCt));
    return sabtCt;
  }
  sherkatsList_get(): Customer[] {


    return JSON.parse(this.storageService.secureStorage.getItem('Customerlist'))

  }

  sherkatsList_selected_set(sabtCt: Customer): Customer {
    this.customer = JSON.parse( JSON.stringify(sabtCt));
    this.storageService.secureStorage.setItem('Customerselected', JSON.stringify(sabtCt));
    return this.customer;
  }
   
  sherkatsList_selected_get(): Customer {

    var c = this.storageService.secureStorage.getItem('Customerselected');

    //اگر بار اول بود و شرکتی انتخاب نبود
    if (c == null) {

      var Customers = JSON.parse(this.storageService.secureStorage.getItem('Customerlist'));
      this.storageService.secureStorage.setItem('Customerselected', JSON.stringify(Customers[0]));
      this.customer = JSON.parse(this.storageService.secureStorage.getItem('Customerselected'));
      return this.customer;
    }
    this.customer = JSON.parse(this.storageService.secureStorage.getItem('Customerselected'));
    return this.customer;

 

  }

  //const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
  header_get(apiadress: string , comp:string): HttpHeaders {

    var sabtCt = JSON.parse(this.storageService.secureStorage.getItem('Customerselected'))
    if (sabtCt == null)
      return null;
     
    if (comp == null)
      comp = 'null';

    let dbinfo = sabtCt.cusName + '/' + sabtCt.userName + '/' + sabtCt.dbaseName + '/' + comp;
   
    if (dbinfo == null) {
      console.log('sherkatsList_selected_forAPI_get is null');
      return;
    } 
    var datac = this.crypto.encryptData(apiadress, dbinfo);
   // console.time('test time');
    //let tok = localStorage.getItem('token');

    //if (tok == null || tok == undefined) {
    //  this.router.navigate(['/home']);
    //}


   
    var u= new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
     // .set('accept-encoding', 'gzip')
      .set('mbase', dbinfo)
      .set('mcpd', datac);
   // console.timeEnd('test time');

    return u;
  }
  get_dbName(): string {

    var sabtCt = JSON.parse(this.storageService.secureStorage.getItem('Customerselected'))
    if (sabtCt == null)
      return null;
       
   

    return sabtCt.dbaseName;
 
   
  }
  optionClient_readDefault(any) : any[] {


    var defl = this.readdefault();

    return JSON.parse(JSON.stringify(defl[any]));


    //if (any == 'gw1001')
    //  return JSON.parse(JSON.stringify(defl.gw1001));


    //if (any == 'gw2001')
    //  return JSON.parse(JSON.stringify(defl.gw2001));
    //if (any == 'gw2002')
    //  return JSON.parse(JSON.stringify(defl.gw2002));
    ////if (any == 'gw3001')
    ////  return JSON.parse(JSON.stringify(defl.gw3001));
    //if (any == 'gw3002')
    //  return JSON.parse(JSON.stringify(defl.gw3002));
    //if (any == 'gw2008')
    //  return JSON.parse(JSON.stringify(defl.gw2008));
    //if (any == 'gw2009')
    //  return JSON.parse(JSON.stringify(defl.gw2009));
    //if (any == 'gw2010')
    //  return JSON.parse(JSON.stringify(defl.gw2010));
    //if (any == 'gw2011')
    //  return JSON.parse(JSON.stringify(defl.gw2011));
    //if (any == 'gw2012')
    //  return JSON.parse(JSON.stringify(defl.gw2012));
    //if (any == 'gw2013')
    //  return JSON.parse(JSON.stringify(defl.gw2013));

    //if (any == 'gw3001')
    //  return JSON.parse(JSON.stringify(defl.gw3001));
    //if (any == 'gw3002')
    //  return JSON.parse(JSON.stringify(defl.gw3002));
    //if (any == 'gw3003')
    //  return JSON.parse(JSON.stringify(defl.gw3003));


 
    //if (any == 'gw3003')
    //  return JSON.parse(JSON.stringify(defl['gw3003']));

    //return [];
  }

  optionClient_save() {
    console.log('optionClient_save');
    localStorage.setItem('optionClient', JSON.stringify(this.optioncli));
  }
  optionClient_load() {

    console.log('optionClient_load');
    //???????????????
  //ghfgfh66766
    var store =  JSON.parse(  localStorage.getItem('optionClient'));



    if (store== null) {
      this.optioncli = JSON.parse(JSON.stringify(this.readdefault()));
    
      console.log('store ui option is null');
    }
    else {
      this.optioncli = this.repairoptionClient(store);
      console.log('store ui option is null');
    }
    localStorage.setItem('optionClient', JSON.stringify(this.optioncli));

   // console.log( JSON.stringify( this.optioncli));


  }





  readdefault(): optionClient {


   let  l: optionClient = {

      settingname: 'default',
      ver: '1',
     gw1001: [50, 110, 110, 110, 110, 100, 150, 150, 100, 100],
     ui_sanadlist_grid_width: ['auto','70','70','70','70','110','80','160','160','160','600', '110', '200'],
     ui_gardeshtafrep_grid_width: ['50','110','110', '110','110' ],
     ui_morurrep_grid_width: ['50', '200', '200', '200', '200', '200', '200'],
     ui_tadilrep_grid_width: ['50', '110', '110', '110', '110'],
     ui_rizghardesh_grid_width: ['150', '100', '100', '100', '300', '150', '150', '150', '150'],






     test: ['50', '110', '110', '110', '110'],
     //Sanad-search.component
     gw1008: [100, 100, 100, 100, 100, 100, 100, 100, 420, 140, 140, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1009: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1010: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1011: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1012: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1013: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1014: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1015: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1016: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw1017: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     

     //AnDeed-edit.component
     gw2001: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
    
     //AnDeed- list.component
     gw2002: [100, 120, 160, 180, 180, 150, 130, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //Kardex-list.component
     gw2003: [90, 100, 150, 140, 90, 100, 100, 120, 160, 100, 120, 160, 100, 160, 160, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //AnMova-list.component
     gw2004: [100, 150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //AnMova- edit.component
     gw2005: [50, 200, 220, 100, 100, 100, 100, 50, 100, 100, 100, 100, 100, 100],
     //DarKhast-list.component
     gw2006: [100, 100, 300, 300, 100, 100, 100, 100, 100, 100],
    // DarKhast- edit.component
     gw2007: [50, 200, 250, 100, 100, 200, 150, 100, 100, 100, 100, 100],
     //AnDeedBuy-edit.component
     gw2008: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //andeedJame
     gw2009: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //andeedJame B kind
     gw2010: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //anGardesh-list
     gw2011: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //anMojudi-list
     gw2012: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //Kardex-list
     gw2013: [50, 200, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],



     //AnSellEdit
    // gw3001: ['100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '0', '0']
     gw3001: [30, 160, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 100],
     //AnSell-list.component
     gw3002: [100, 110, 160, 300, 100, 120, 120, 120, 120, 120, 120, 120, 250, 250, 250, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     //AnSellJame
     gw3003: [100, 200, 160, 300, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],


     //  vm
       gw9801: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9802: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9803: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9804: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9805: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9806: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9807: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9808: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9809: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
       gw9810: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],



     gw4001: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4002: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4003: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4004: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4005: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4006: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4007: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
     gw4008: [100, 100, 100, 100, 100, 120, 120, 120, 120, 120, 120, 120, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],


    }
    return l;
  }

  repairoptionClient(val: any): optionClient {

    let defu = this.readdefault();
    //gthgh555587
    const pnames = Object.getOwnPropertyNames(val);
    for (var name of pnames) {
      if (val[name] != undefined)
        defu[name] = val[name];
    }

    return JSON.parse(JSON.stringify(defu));








    //if (val.gw1001 != undefined)
    //  defu.gw1001 = val.gw1001;

    //if (val.ui_sanadlist_grid_width != undefined)
    //  defu.ui_sanadlist_grid_width = val.ui_sanadlist_grid_width;

    //if (val.ui_gardeshtafrep_grid_width != undefined)
    //  defu.ui_gardeshtafrep_grid_width = val.ui_gardeshtafrep_grid_width;

    //if (val.ui_morurrep_grid_width != undefined)
    //  defu.ui_morurrep_grid_width = val.ui_morurrep_grid_width;

    //if (val.ui_tadilrep_grid_width != undefined)
    //  defu.ui_tadilrep_grid_width = val.ui_tadilrep_grid_width;

    //if (val.ui_rizghardesh_grid_width != undefined)
    //  defu.ui_rizghardesh_grid_width = val.ui_rizghardesh_grid_width;


  

    //if (val.gw1008 != undefined)
    //  defu.gw1008 = val.gw1008;

    //if (val.gw1009 != undefined)
    //  defu.gw1009 = val.gw1009;

    //if (val.gw1010 != undefined)
    //  defu.gw1010 = val.gw1010;

    //if (val.gw1011 != undefined)
    //  defu.gw1011 = val.gw1011;

    //if (val.gw1012 != undefined)
    //  defu.gw1012 = val.gw1012;

    //if (val.gw1013 != undefined)
    //  defu.gw1013 = val.gw1013;

    //if (val.gw1014 != undefined)
    //  defu.gw1014 = val.gw1014;

    //if (val.gw1015 != undefined)
    //  defu.gw1015 = val.gw1015;

    //if (val.gw1016 != undefined)
    //  defu.gw1016 = val.gw1016;




    //if (val.gw2001 != undefined)
    //  defu.gw2001 = val.gw2001;

    //if (val.gw2002 != undefined)
    //  defu.gw2002 = val.gw2002;

    //if (val.gw2003 != undefined)
    //  defu.gw2003 = val.gw2003;

    //if (val.gw2004 != undefined)
    //  defu.gw2004 = val.gw2004;

    //if (val.gw2005 != undefined)
    //  defu.gw2005 = val.gw2005;


    //if (val.gw2006 != undefined)
    //  defu.gw2006 = val.gw2006;

    //if (val.gw2007 != undefined)
    //  defu.gw2007 = val.gw2007;

    //if (val.gw2008 != undefined)
    //  defu.gw2008 = val.gw2008;

    //if (val.gw2009 != undefined)
    //  defu.gw2009 = val.gw2009;

    //if (val.gw2010 != undefined)
    //  defu.gw2010 = val.gw2010;

    //if (val.gw2011 != undefined)
    //  defu.gw2011 = val.gw2011;

    //if (val.gw2012 != undefined)
    //  defu.gw2012 = val.gw2012;

    //if (val.gw2013 != undefined)
    //  defu.gw2013 = val.gw2013;

    //if (val.gw3001 != undefined)
    //  defu.gw3001 = val.gw3001;

    //if (val.gw3002 != undefined)
    //  defu.gw3002 = val.gw3002;

    //if (val.gw3003 != undefined)
    //  defu.gw3003 = val.gw3003;

    //if (val.test != undefined)
    //  defu.test = val.test;


    
    //return JSON.parse(JSON.stringify(defu));
  }

  getMySalshChangedEvent() {

    return this.OnMySalshChanged;
  }

  emitOnMyNavloaded(value) {
    this.OnMyNavloaded.emit(value);
  }
  getOnMyNavloadedEmitter() {
    return this.OnMyNavloaded;
  }


  regsBinded: boolean = false;
  regs: Registry[] = [];
  //'App3-AnSell-[Direct]'
  bindRegistry(regs: Registry[]) {
    this.regsBinded = true;
    this.regs = regs;

  }

  GetRegistryByPath(path ) : Registry{
 
    for (var a of this.regs) {

      if (a.path == path)
        return a;

    }
    return null;
  }

  clear() {



    this.regsBinded= false;
  
    this.regs = [];
    this.dateEmruz = null;

    this.cheatlib = 0;

    this.customer = null;
    this.salemali = null;


  }

  version: string = '5.6.22';

  getVersion() {

    return this.version;


  }
}
 
