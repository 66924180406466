 


export enum TableName {
  None,
  PdMove,
  AnDeed,
  AnSell,
}

export class PdLink {
  id: number;
  tableNameA: TableName;
  tableAId: number;
  tableNameB: TableName;
  tableBId: number;
  prc: number;
  changePrc: number;
  tableAGenericId: string;
  tableBGenericId: string;
}



export class PdLinkBox {
  op: PdLinkBoxOp;
  isLoadLinks: boolean;
  pdLinks: PdLink[];
  rows: PdLinkBoxRow[];
  loadedIds: PdLinkBoxLoadedIds;

  public constructor() {
    this.op = new PdLinkBoxOp();
    this.pdLinks = [];

  } 

}

export class PdLinkBoxOp {
  tafId: number;
  dateA: number;
  dateA_S: string;
  dateB: number;
  dateB_S: string;
  public constructor() {

  }
}

export class PdLinkBoxRow {
  id: string;
  tafId: number;
  tableName: TableName;
  tableId: number;
  kindN: string;
  kindId: number;
  typeSplit: number;
 
  date: number;
  date_S: string;
  prc: number;
  sumLinkPrc: number;
  freePrc: number;
  isLink: boolean=false;
  pIds: PdLinkBoxRowLinkPrc[];
  public constructor() {

  //  this.pIds = [];
  }
}

export class PdLinkBoxRowLinkPrc {
  id: string;
  linkPrc: number;



}

export class PdLinkBoxLoadedIds {
  pdMoveIds: number[];
  anSellIds: number[];
}



 
export class PdLinkPost {
  right: PdLinkBoxRow;
  left: PdLinkBoxRow;
  prc: number;
  public constructor() {

    this.right= null;
    this.left = null;
    this.prc = 0;

  }

  setRight(data: PdLinkBoxRow) {

    this.right = data;

    if (this.left == null || this.left.prc >= this.right.freePrc) {
      this.prc = this.right.freePrc;
    }
    else {
      this.prc = this.left.freePrc;

    }



    if (this.left) {
      var setedPrc = this.right.pIds.find(x => x.id == this.left.id);

      if (setedPrc) {
        this.prc = setedPrc.linkPrc;
      }
    }

  }
  setLeft(data: PdLinkBoxRow) {

    this.left = data;


    if (this.right == null || this.right.prc >= this.left.freePrc) {
      this.prc = this.left.freePrc;
    }
    else {
      this.prc = this.right.freePrc;

    }

    if (this.right) {
      var setedPrc = this.left.pIds.find(x => x.id == this.right.id);

      if (setedPrc) {
        this.prc = setedPrc.linkPrc;
      }
    }

  }
}

///بابت سند خرید و فروش و اتصال ان به دریافت پرداخت
export class AutoPdLink {
  tableName: TableName;
  tableId: number;
  tafId: number;
  data_S: string;
}
