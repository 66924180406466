import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';

 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
 
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';

import DataSource from "devextreme/data/data_source";

import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';
import { DoAmel } from '../../../../_models/app2/glob/AnAmel';
 
import { SanadJoinerVmsService } from '../../../../_services/app1/opr/SanadJoinerVms.service';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { Sanad } from '../../../../_models/app1/opr/sanad';
import { Sabt } from '../../../../_models/app1/opr/sabt';
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';


@Component({
  selector: 'app98-Vms-sanad',
  templateUrl: './Vms-sanad.component.html',
  styleUrls: ['./Vms-sanad.component.css']
})
 

export class VmsSanadComponent implements OnInit {
 
 
 
  @Input() ftabindex: number;
  @Input() hyperLinkOption: any;
 
  @Input() kindpage: number;
  @Input() Vmsids: number[] = [];
 
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;
     //kind نوع صفحه
        //VmsId ای دی سند
        //VmsRowId ردیف فکوس
  @Input() inputdata: any;

 
  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() OnChangeEvent = new EventEmitter<any>();

  usernow: User;
 
  ids: [] =[];
  mymodle: Sanad;
  headT: string;
  isdetail: boolean = false;
  selectedrow: Sabt;
 
 
 
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  loaddata: loadingCl;
 
  constructor(private userService: UserService,
 
    private cd: ChangeDetectorRef,
    private AnAmelService: AnAmelService,
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anRepService: AnRepService,
    private sanadService: SanadService,
    private sabtService: SabtService,
    private SanadJoinerVmsService: SanadJoinerVmsService,
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {
 

    this.mymodle = sanadService.getSanadEmpty();

    this.usernow = this.localst.usernow_get();
    this.loaddata = loadServ.getEmpty();

    this.loadServ.create(this.loaddata,10);
   this.selectedrow = this.sabtService.getSabtEmpty();
    
  } 
   
  ngOnInit() {
 

    if (this.kindpage == 101)
      this.headT = ' صدور سند حسابداری  تسهیلات';

   else if (this.kindpage == 201)
      this.headT = ' صدور سند حسابداری دریافت پس انداز';

    else if (this.kindpage == 202)
      this.headT = ' صدور سند حسابداری دریافت اقساط';

    this.readSanad();
  }

  chValueChanged(e) {



    this.readSanad();

  }

  readSanad() {
 
    //gtgy45te6

    if (this.kindpage == 101) {

      let opbox = { VmVamIds: this.Vmsids, kind: this.kindpage, isdetail: this.isdetail, userFLog: this.usernow.name, userNLog: this.usernow.username, sanad: null };

      this.loadServ.showloading(this.loaddata, 1);
      this.SanadJoinerVmsService.CreateSanadByVmVamIds(opbox).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 1);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);

        }
        else {
          this.mymodle = res.data;
        }
      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });


    }
    else if (this.kindpage == 201 || this.kindpage == 202) {

      let opbox = { VmDarIds: this.Vmsids, kind: this.kindpage, isdetail: this.isdetail, userFLog: this.usernow.name, userNLog: this.usernow.username, sanad: null };

      this.loadServ.showloading(this.loaddata, 1);
      this.SanadJoinerVmsService.CreateSanadByVmDarIds(opbox).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 1);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);

        }
        else {
          this.mymodle = res.data;
        }
      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });



    }



  }

  closeclick() {

    this.OnMyCancelEvent.emit(true);

  }

  addClick() {


    if (this.kindpage == 101) {
      let addi = { VmVamIds: this.Vmsids, kind: this.kindpage, groupKind: this.isdetail, userFLog: this.usernow.name, userNLog: this.usernow.username, sanad: this.mymodle };

      this.loadServ.showloading(this.loaddata, 2);
      this.SanadJoinerVmsService.AddSanadOnVmVam(addi).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 2);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);

        }
        else {

          this.OnChangeEvent.emit(true);
          this.readSanad();
          this.alertify.success(res.mes);

        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });
    }
    else if (this.kindpage == 201 || this.kindpage == 202) {

      let addi = { VmDarIds: this.Vmsids, kind: this.kindpage, groupKind: this.isdetail, userFLog: this.usernow.name, userNLog: this.usernow.username, sanad: this.mymodle };

      this.loadServ.showloading(this.loaddata, 2);
      this.SanadJoinerVmsService.AddSanadOnVmDar(addi).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 2);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);

        }
        else {

          this.OnChangeEvent.emit(true);
          this.readSanad();
          this.alertify.success(res.mes);

        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });



    }

  }
 
  onFocusedRowChanged(e) {

    if (e.row.data != null)

    this.selectedrow = e.row.data;


  }

  deleteClick(e) {

    let key = this.dataGrid.focusedRowKey;

    let sanadId = 0;
  
    for (var r of this.mymodle.sabts) {
      if (r.id == key) {
        sanadId = r.sanadId;
    
      }
    }

    if (sanadId == null) {
      this.alertify.warning('هیچ ردیفی انتخاب نشده');
      return;
    }



    if (this.kindpage == 101) {

      this.loadServ.showloading(this.loaddata, 3);
      this.SanadJoinerVmsService.DeleteSanadBySanadIdVmVam(sanadId).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 3);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.alertify.success(res.mes);
          this.OnChangeEvent.emit(true);
          this.readSanad();
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });
    }
    else if (this.kindpage == 201 || this.kindpage == 202) {

      this.loadServ.showloading(this.loaddata, 3);
      this.SanadJoinerVmsService.DeleteSanadBySanadIdVmDar(sanadId).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 3);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.alertify.success(res.mes);
          this.OnChangeEvent.emit(true);
          this.readSanad();
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });

    }




  }

  onRowPrepared(e) {

    // console.log(e.data);
    if (e.data == undefined)
      return;

    //hjg5565655
    if (e.data.cAtf > 0) {

      e.rowElement.style.color = '#00b456';
      //  e.rowElement.style.border = '1px solid';


    }
 


  }

  showSanadClick() {

    let key = this.dataGrid.focusedRowKey;

    if (key == null) {
      this.alertify.warning('هیچ ردیفی انتخاب نشده');
      return;
    }


  

    var data = this.mymodle.sabts.find(x => x.id === key);

    if (data) {
      var option = { "HPSanadId": data.sanadId, "HPSabtId": data.id, "HPOpenOnInit": true };

      if (option.HPSanadId != -1)
        this.OnMyLoadSanadEvent.emit(option);

    }
  
      


  

  }


  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }

  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' سند   ');


  }
}


