import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import { PerM } from '../../../../_models/app99/app99model';
 
  

@Component({
  selector: 'app99-Perm-edit',
  templateUrl: './Perm-edit.component.html',
  styleUrls: ['./Perm-edit.component.css']
})
export class PermEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PerM;
  myModel_old: PerM;
 
 
 

 Permcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
 
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.app99Service.PerM_getPerMEmpty();
    this.Permcodelist = [];
 
  }

  ngOnInit() {
 
 
    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.PerM_getallCods().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.Permcodelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.app99Service.PerM_getPerMEmpty();
 

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.PerM_getbyid(this.editId).subscribe(
        (data : PerM) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          

 

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }


  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    

   // console.log(JSON.stringify(this.myModel));
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.PerM_add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.PerM_update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    if (this.editId != -1)
    if (this.myModel_old.cMelli != null)
      if (this.myModel_old.cMelli == v)
        return true;

    for (var val of this.Permcodelist) {
      if (v == val) return false;
    }

    return true


  }
 
 

  validateNumber(e) {

    

  }
}
