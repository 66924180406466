import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';

 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../../_services/loading.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';

import { ApiRet } from '../../../../_models/General/ApiRet';

import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { VmMahRep } from '../../../../_models/app98/rep/VmMahRep';
import { VmMahRepService } from '../../../../_services/app98/rep/VmMahRep.service';
import { VmDarRepOp } from '../../../../_models/app98/rep/VmDarRep';
import { VmDarRepService } from '../../../../_services/app98/rep/VmDarRep.service';
import { VmGrsService } from '../../../../_services/app98/paye/VmGrs.service';
import { VmGrs } from '../../../../_models/app98/paye/VmGrs';
 

@Component({
  selector: 'app98-VmMahRep-list',
  templateUrl: './VmMahRep-list.component.html',
  styleUrls: ['./VmMahRep-list.component.css']
})
 

export class VmMahReplistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @Output() OnVmDarRep = new EventEmitter<VmDarRepOp>();
  @Output() OnMyprint = new EventEmitter<any>();

  @Input() kind: number;
  @Input() tabindex: number;
  openeprint: boolean;
 

  model: VmMahRep;
  vmGrsList: VmGrs;
 
 
 
 
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];

  mahList: any[];
 
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
    private cd: ChangeDetectorRef,
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private vmMahRepService: VmMahRepService,
    private vmGrsService: VmGrsService,
    private vmDarRepService: VmDarRepService,
    private tafsiliService: TafsiliService,
    private excelService: ExcelService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    
 
    this.loadServ.create(this.loaddata,8);



    this.model = vmMahRepService.getVmMahRepEmpty();


  }
  mytabindex: number = -1;
  ngOnInit() {

    this.mytabindex = this.tabindex;

    this.mahList = this.vmMahRepService.getMahList();

    this.model.op.year = this.localst.yearnow_get();

    this.model.op.mah = this.localst.dateEmruz.month;
    this.setmah(this.model.op.year, this.model.op.mah);

    this.loadServ.showloading(this.loaddata, 4);
    this.vmGrsService.getallOnRep(true)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 4);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.vmGrsList = res.data;
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });
  
  }
  ngOnChanges(changes: any) {

    if (this.dataGrid != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.dataGrid.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.dataGrid.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.dataGrid.instance.getCellElement(index, 0);
          var y = this.dataGrid.instance.getScrollable();
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.dataGrid.instance.refresh();
          //y.scrollTo(0,2);


          //y.sc(0);
        }

      }
      else {


      }
  }

  ngAfterViewInit() {

   
  }
  
  onClickVmDarRep() {

    let key = this.dataGrid.focusedRowKey;
    if (key == null) {
      this.alertify.warning('ابتدا ردیفی را انتخاب کنید');
      return;
    }

    var op = this.vmDarRepService.getVmDarRepOpEmpty();
    op.tafId = key;
    op.dateS = this.model.op.dateS;
    op.dateS_S = this.model.op.dateS_S;
    op.dateE = this.model.op.dateE;
    op.dateE_S = this.model.op.dateE_S;
    op.kind = -1;


    this.OnVmDarRep.emit(op);



  }

 


  reloadrows(): void {
 
  }

 

  onClickReflesh() {



    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم
    if (this.dxliststr != undefined) {
      var keys = this.dxliststr.selectedItemKeys;
      this.model.op.vmGrss = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {
          this.model.op.vmGrss.push({ id: keys[j].id, name: keys[j].name });
        }
    }


    this.loadServ.showloading(this.loaddata, 5);
    this.vmMahRepService.getRep(this.model.op)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.model = res.data;
        }


       

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


 
  onValueChangedMah(e) {

 
   
    this.setmah(this.model.op.year ,e.value);


  }

  setmah(year ,mah) {

    //hyguty6866656
    if (mah == null) {
      this.model.op.dateS_S = this.model.op.year.toString() + '/01/01';
      this.model.op.dateE_S = this.model.op.year.toString() + '/12/30';
      this.model.op.mahN = '';
    }
    else {

      var dr = this.vmMahRepService.getDateRange(year, mah);
      this.model.op.dateS_S = dr.dateA;
      this.model.op.dateE_S = dr.dateB;

      for (var r of this.mahList) {
        if (r.id == mah) {
          this.model.op.mahN = r.name;

        }


      }
  
    }



  }





  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,' گزارش تجمیعی ');

 

  }

  onExporting(e) {

  }

 
 
  adjustWidth(e , w) {

    e.component._popup.option('width', w);

  }
  firstclreate = true;

  dropopened(e) {


    e.component._popup.option('width', 500);

    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

      this.firstclreate = false;

    }
  }

  onSelectionChanged(e) {

 
  }

  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    if (e.column.dataField == "prcMan") {

      if (e.data.anDeedC == 0) {
        if (e.data.prcMan > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.amtMan < 0) {
          e.cellElement.style.backgroundColor = '#ca0000';

        }
 
      }
    }
 
    if (e.column.dataField == "prcOut") {

      if (e.data.anDeedC == 0) {
        

        if (e.data.prcOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.prcOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }

      }
    }


    if (e.column.dataField == "fiOut") {

      if (e.data.anDeedC == 0) {



        if (e.data.fiOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.fiOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }
      }
    }


 

   
 
  }
 

  


  gwname = 'gw9810';

  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli[this.gwname] = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }

  addwidth(e) {
    for (var i = 0; i < this.localst.optioncli[this.gwname].length; i++) {
      this.localst.optioncli[this.gwname][i] *= e;
    }
  }


  defwidth() {
    this.localst.optioncli[this.gwname] = this.localst.optionClient_readDefault(this.gwname);
  }

  modelTemp: VmMahRep;
  onClickprint(printKind : number) {

    this.model.op.dateS_S;




    if (printKind == 1) {

      this.modelTemp = JSON.parse(JSON.stringify(this.model));

    }
    else if (printKind == 11) {

      let filterExpr = this.dataGrid.instance.getCombinedFilter(true);
      const dataSource = this.dataGrid.instance.getDataSource();
      const loadOptions = dataSource.loadOptions();

      this.modelTemp = JSON.parse(JSON.stringify(this.model));

      dataSource
        .store()
        .load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group })
        .then((result: any) => {
          // your code...
          this.modelTemp.rows = result;
        });


      this.modelTemp.rowSum.radif = this.dataGrid.instance.getTotalSummaryValue('radif');
      this.modelTemp.rowSum.prcDar3 = this.dataGrid.instance.getTotalSummaryValue('prcDar3');
      this.modelTemp.rowSum.prcDar1 = this.dataGrid.instance.getTotalSummaryValue('prcDar1');
      this.modelTemp.rowSum.prcDar1All = this.dataGrid.instance.getTotalSummaryValue('prcDar1All');
      this.modelTemp.rowSum.prcDarSum31 = this.dataGrid.instance.getTotalSummaryValue('prcDarSum31');
      this.modelTemp.rowSum.prcVamMandehAll = this.dataGrid.instance.getTotalSummaryValue('prcVamMandehAll');
      this.modelTemp.rowSum.prcDar2 = this.dataGrid.instance.getTotalSummaryValue('prcDar2');
      this.modelTemp.rowSum.prcVamMandeh = this.dataGrid.instance.getTotalSummaryValue('prcVamMandeh');
      this.modelTemp.rowSum.rsMandeh = this.dataGrid.instance.getTotalSummaryValue('rsMandeh');
  



    }
    else {
      this.alertify.error('الگو یافت نشد');
    }

    if (this.modelTemp == undefined || this.modelTemp == null) {
      this.alertify.error('مدل یافت نشد');
    }


    var modelData = JSON.parse(JSON.stringify(this.modelTemp));

    //756756755gf
    var mdata = { modelData: modelData, printKind: printKind, compKind: this.kind }

    this.OnMyprint.emit(mdata);

  }
 
}


