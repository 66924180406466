import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Sanad } from '../../../../_models/app1/opr/Sanad';
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
 
import { tabeventobj } from '../../../../_models/tabeventobj';
import { GeneralService } from '../../../../_services/General/General.service';
import DataSource from "devextreme/data/data_source";
import { Column } from 'devextreme/ui/data_grid';
 

@Component({
  selector: 'app-Sanad-list',
  templateUrl: './Sanad-list.component.html',
  styleUrls: ['./Sanad-list.component.css']
})
 

export class SanadListComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;
  @Input() tabindex: number;
  @Input() hyperLinkOption: any;
 
  items: any;
  openeprint: boolean;
  openeDuplcate : boolean;
  openeMerge : boolean;
  openedit: boolean;
  openeditId: number;


  monthRang: number[];


  focusedSabtId: number;
 
  



  valdata: DialogData;
  selectedrow: any;
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
   };

 // modlelist: Sanad[];
  modlelist: any = {};
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
  header: any;

  //animal: string;
  //name: string;
  isdelete: string;
 
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;


  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  mergeOption: any;

  isdadghos: boolean = false;

  constructor(private userService: UserService,
 
    private cd: ChangeDetectorRef,
  
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private SanadService: SanadService,
    private generalService: GeneralService,
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {

   this.modlelist = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: null
      }

   });

    this.isdadghos = false;
    if (localst.sherkatsList_selected_get().dbaseName == 'tzcu2dadghos') {
      this.isdadghos = true;

    }

    this.loaddata = loadServ.getEmpty();
  //  this.loadServ = new loadingClass(8);
   // let sam: loadingCl = new loadingClass(5);
  //  let myTask: Task = new MyTask('someTask');
   // myTask.run("test");
    this.loadServ.create(this.loaddata,10);
   // this.loadServ2.showloading(7);
   // this.loadServ2.showed = true;

   // this.loadServ2.showloading(0);
   // this.monthRang = [localst.dateEmruz.month -3, localst.dateEmruz.month]
    this.monthRang = [1, 12]
 
    if (this.monthRang[0] < 1)
      this.monthRang[0] = 1;

    this.pagination = this.defaultpagation;
    this.header = this.SanadService.getSanadHeader();
    this.openeprint = false;
    this.openedit = false;
    this.openeDuplcate = false;
    this.openeMerge = false;
    this.openeditId = -1;
 
    this.focusedSabtId = -1;
    this.duplcateOption = {
      copyCSh : null,
      copyId: null,
      paseCSh: null,
      paseId: null,
      date : '1000/00/00'

    }
    this.mergeOption = {

      years: 0,
      byKind: 'inList',

      inCShomareS: 1,
      inCShomareE: 1,

      inDaterangS: null,
      inDaterangE: null,

      inDateOneS: null,
      inDateOneE: null,

      inList: [],
    }

    this.items = [
    { text: 'کپی سند'  , id:'copy'},
    { text: '        '  , id:'null'},
      { text: 'ویرایش'  , id:'edit'},
    { text: 'حذف'  , id:'delete'},
    { text: 'قطعی'  , id:'vaz4'},
    { text: ' لغو بررسی'  , id:'vaz2'},
    { text: 'بررسی'  , id:'vaz3'}
    //  items: [
    //    { text: 'Facebook' },
    //    { text: 'Twitter' }],
    //},
 
    ];
  } 
   
  itemClick(e) {

    //راست کلیک روی سند باز شده نباید باز شود
    if (this.openedit)
      return;

    if (!e.itemData.items) {

      let a = e.itemData.id;

      if (a == 'copy')
        this.onClickDublctaeSanad();
      else if (a == 'delete')
        this.onClickDelete();
      else if (a == 'edit')
        this.onClickEdit();
      else if (a == 'vaz4')
        this.onClickbarasi(4);
      else if (a == 'vaz2')
        this.onClickbarasi(2);
      else if (a == 'vaz3')
        this.onClickbarasi(3);

    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode);

 //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeSanad();
    //}
    



  }
  mytabindex: number = -1;
  userType: number = 0;
  ngOnInit() {

    this.localst.usernow_get
    this.mytabindex = this.tabindex;
    this.resetFilters();
    this.isdelete = "no";;
    this.loadSanad();



    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
        // this.alertify.showListString(res.errors);
      }
      else {
        // this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

   // console.log('+=+=+=+ : ' + JSON.stringify(this.hyperLinkOption));

    
    //e شامل اپشن زیر استe
       // temp.op_moruroption = { "HPSanadId": e.HPSanadId, "HPSabtId": e.HPSanadId, "HPOpenOnInit": e.HPOpenOnInit };


    //اگر هیپرلینک ورودی داشت
    if (this.hyperLinkOption != null) {
      if (this.hyperLinkOption.HPSanadId != null)
        this.openeditId = this.hyperLinkOption.HPSanadId;

      if (this.hyperLinkOption.HPSabtId != null)
        this.focusedSabtId = this.hyperLinkOption.HPSabtId;


      if (this.hyperLinkOption.HPSanadId != null)
        this.openedit = this.hyperLinkOption.HPOpenOnInit;





    }
 

  }
  ngOnChanges(changes: any) {

    if (this.gridContainer != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.gridContainer.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.gridContainer.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.gridContainer.instance.getCellElement(index, 0);
          var y = this.gridContainer.instance.getScrollable();
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.gridContainer.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }
  }
 change;
  fchang(e) {

    // this.fchanged = true;
    this.change = new tabeventobj(this.ftabindex);
    this.change.value = e;


    this.onfchanged.emit(this.change);
    console.log(this.ftabindex);

  }
  ngAfterViewInit() {

    this.loaddata.showed = true;
    this.cd.detectChanges();

  }

  onClicksearch() {

    this.loadSanad();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadSanad();
    console.log('m:searching... all');
  }
  loadSanad() {

   
    //console.log(this.monthRang);

    let y = this.localst.yearnow_get().toString();
    let dA = this.monthRang[0].toString();
    let dB = this.monthRang[1].toString();

    if (dA.length < 2)
      dA = dA = '0' + dA;
    if (dB.length < 2)
      dB = dB = '0' + dB;

    dA = y + dA + '00';
    dB = y + dB + '99';
    this.loadServ.showloading(this.loaddata, 0);
    this.SanadService.getSanads(this.localst.yearnow_get(), '13000101', '19999999')
      .subscribe((res: Sanad[]) => {
       
        this.loadServ.hideloading(this.loaddata, 0);
      
        this.modlelist = new DataSource({
          store: {
            type: "array",
            key: "id",
            data: res,
            
          }
 
        });
      
       // this.modlelist = res;

        this.gridContainer.scrolling.scrollByContent = true;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

  }

 
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';
 
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadSanad();
  }


  reloadrows(): void {
   // this.loadSanad();
  //  console.log("m:page refleshe done");
   // this.loadSanad();
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
   // console.log(JSON.stringify(this.selectedrow));

  }


  onClickDelete() {

    
    this.openDialog();

  }

  openDialog(): void {

   
    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });
   // dialogRef.componentInstance.message = 'jjj';
   
    dialogRef.afterClosed().subscribe(result => {
     
 
      let isdelete = result;
    
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }


  

  dodelete() {


    var keyss = this.gridContainer.selectedRowKeys;

    if (keyss == null)
      return;

    if (keyss.length == 0)
      return;

     

    //for (var i = 0; i < keyss.length; i++) {


    //  for (var j = 0; j < this.modlelist._items.length; j++) {

    //    if (keyss[i] == this.modlelist._items[j].id) {

    //      if (this.modlelist._items[j].vaziat <= 2) {

    //        this.selectedrowkeys.push(this.modlelist._items[j].id);
    //      }
    //      else {
    //        this.selectedrowkeysIgnored.push(this.modlelist._items[j].id);
            
    //      }

    //        break;
    //    }

    //  }

    //}

    if (keyss.length == 0) {

      this.alertify.error('ردیف قابل حذفی انتخاب نشده است ');
      return;

    }

 
    this.loadServ.showloading(this.loaddata,2);
    this.SanadService.DeleteSanads(keyss).subscribe((res) => {
      this.loadServ.hideloading(this.loaddata, 2);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
      }

      this.loadSanad();
     // this.refleshSanadShomare();

    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
            this.alertify.error(error);
           
          }); 
  

  
     
  }



  OnMyCancelEvent(e) {
    console.log('cancel form');
   // console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }

  }
  onClickNew() {

    console.log('newopen');


    this.openedit = true;


  }


  onClickEdit() {


    if (this.selectedrow == null) return;


    this.openeditId = this.selectedrow.id;

    this.openedit = true;

  }
  OnMySaveEvent(e) {


 //   this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: false });

   

    if (e.refleshShomare) {

    //  this.refleshSanadShomare();

    }

    if (e.reloadlist) {

      this.loadSanad();

    }



    if (e.closepage) {

      this.openedit = false;
      this.openeditId = -1;

    }


 

  }


  refleshSanadShomare() {
    
    this.loadServ.showloading(this.loaddata,3);
    this.SanadService.refleshSanad(this.localst.yearnow_get()).subscribe(data => {

      this.loadServ.hideloading(this.loaddata,3);
      console.log('sanads reflesh count: ' + data.toString());

      this.loadSanad();

    }, error => {
        this.loadServ.hideloading(this.loaddata,3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }

  //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  //{ id: 2, name: 'موقت' },
  //{ id: 3, name: 'بررسی شده' },
  //{ id: 4, name: 'قطعی شده' }

  //]


  onClickbarasi(vaziatnumber : number) {


    if (this.gridContainer.selectedRowKeys == null)
      return;

    var issuperadmin = false;
    if (vaziatnumber == 22) {
      vaziatnumber = 2;
      issuperadmin = true;
    }
    //console.log(JSON.stringify(this.gridContainer.selectedRowKeys));

    let vaziat = this.SanadService.getVaziat(vaziatnumber);

    if (vaziat == null)
      return;


 


    this.loadServ.showloading(this.loaddata,4);
    this.SanadService.updateVaziat(this.gridContainer.selectedRowKeys, vaziat.id, vaziat.name, issuperadmin).subscribe((res: number[]) => {

      
      this.loadServ.hideloading(this.loaddata,4);
      this.alertify.success( ' تعداد ' + res.length +  ' سند به وضعیت ' + vaziat.name + 'تغییر یافت');
      for (var i = 0; i < res.length; i++) {


        for (var j = 0; j < this.modlelist._items.length; j++) {

          if (this.modlelist._items[j].id == res[i]  ) {

            this.modlelist._items[j].vaziat = vaziat.id;
            this.modlelist._items[j].vaziatN = vaziat.name;
           // this.gridContainer.instance.getDataSource().reload();
            break;
          }

        }



      }
     /// this.loadSanad();
    }, error => {
        this.loadServ.hideloading(this.loaddata,4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  

  }


  onClickghati(v: number) {

      let mess = 'از قطعی شدن اسناد اطمینان دارید؟';
    this.valdata = { title: mess, rows: [' در صورت قطعی شدن امکان برگشت وجود ندارد'], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
     

    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let dialogdata = result;

      if (dialogdata != null) {


        console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.onClickbarasi(4);

        }
      }
    });

     

  }
  onRowDblClick(e) {

    //if (e.key == null)
    //  return;


    //console.log(e.key);

    if (this.selectedrow == null) return;


    this.openeditId = this.selectedrow.id;

    this.openedit = true;

  }

  onCellPrepared(e) {


    
    if (e.rowType === "data" && e.column.dataField === "vaziat") {
    //  if (e.data.Amount==2) 
    //  e.cellElement.style.backgroundColor  =  "green" ;
      // Tracks the `Amount` data field
      //e.watch(function () {
      //  return e.data.Amount;
      //}, function () {
      //  e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      //})
    }



  }
  //فرمت
  //[calculateCellValue] = "calculateCellDateValue"
  //calculateCellDateValue(data) {
  //  let ddd = data.date.toString();
  //  ddd = ddd.substring(1, 2)

  //  return ddd;
  //}

  onClickReflesh() {

    
    this.loadServ.showloading(this.loaddata, 8);
    this.SanadService.refleshSanad(this.localst.yearnow_get()).subscribe(data => {

     
      this.loadSanad();
      this.loadServ.hideloading(this.loaddata, 8);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });






  }
  data: any = [{
    eid: 'e101',
    ename: 'ravi',
    esal: 1000
  },
  {
    eid: 'e102',
    ename: 'ram',
    esal: 2000
  },
  {
    "سلام": 'e103',
    "سسس": 'rajesh',
    "بیبی": 3000
  }];
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance,' لیست اسناد ');

   // this.excelService.exportAsExcelFile(this.modlelist, 'sample');

  }

  onExporting(e) {

  }
  onClickPrint() {

    

    this.openeprint = true;

    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }
    //اگر پرینت از لیست سند صدا شود
  onCloseprint(e) {
 
    //console.log('click2');
    this.openeprint = false;

  }
  onPrintPrint(e) {

    this.onPrint.emit(e);
    this.openeprint = false;

  }
  //اگر پرینت از صفحه ویرایش سند صدا شود
  OnEditSanadClickPrintEvent(e) {

    if (e == null)
      return;


    this.printnum = e;
   // console.log(e);
    this.openeprint = true;

    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }
  test8() {
   // this.gridContainer.instance.sc
    console.log("test8");
  //  this.gridContainer.instance.columnOption(5, "width", 900);
  }

  onClickDublctaeSanad() {

   let id =  this.gridContainer.focusedRowKey;

    if (id == null)
      return;

    for (var r = 0; r < this.modlelist._items.length; r++) {

      if (this.modlelist._items[r].id == id) {


        this.duplcateOption.copyId = this.modlelist._items[r].id;
        this.duplcateOption.copyCSh = this.modlelist._items[r].cShomare;
        this.duplcateOption.paseId = null;
        this.duplcateOption.paseCSh = null;
        this.duplcateOption.date = null;
      //  this.duplcateOption.date = this.modlelist[r].date_String;

        if (this.modlelist._items[r].vaziat != 2) {
          this.alertify.alert('سند باید در وضعیت موقت قرار داشته باشد');
          return;

        }
        //if (this.modlelist._items[r].is != 2) {
        //  this.alertify.alert('سند باید در وضعیت موقت قرار داشته باشد');
        //  return;

        //}
        break;
      }

    }



    if (this.duplcateOption.copyId == null)
      return;

    this.openeDuplcate = true;
    if (this.openeprint)
      this.openeprint = false;

  }



  onClickMergeSanad() {

    var keyss = this.gridContainer.selectedRowKeys;

   

   var selected = [];
   var ignored = [];

    if (keyss != null && keyss.length > 0)
    for (var i = 0; i < keyss.length; i++) {


      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (keyss[i] == this.modlelist._items[j].id) {
          if (this.modlelist._items[j].vaziat == 2) {
            selected.push(this.modlelist._items[j].id);
          }
          else {
            ignored.push(this.modlelist._items[j].id);

          }

          break;
        }

      }

    }

    if (ignored.length > 0) {

      this.alertify.message(ignored.length.toString() +' ردیف نادیده گرفته شد ' + ' همه ی اسناد باید در وضعیت موقت قرار داشته باشند ');
     

    }

    if (selected.length > 0) {
      this.mergeOption.inList = selected;
      this.mergeOption.byKind = 'inList';

    }  


    this.openeMerge = true;
    if (this.openeprint)
      this.openeprint = false;
    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }

  onCloseSSS(e) {

    this.openeDuplcate = false;

  }
  onClosemerge(e) {

    this.openeMerge = false;

  }
  onDuplcateDateChanged(e) {


    var newcshomare = -1;
    for (var r = 0; r < this.modlelist._items.length; r++) {

      newcshomare = this.modlelist._items[r].cShomare;

      if (this.modlelist._items[r].date <= e)
        break;
      

    }

    newcshomare++;

    this.duplcateOption.paseCSh = newcshomare;


  }



  onDuplcateSanad(e) {
 

    let sanadid = e.copyId;
    this.openeDuplcate = false;

    let date: string = e.date.replace(/[^\d.-]/g, '');

    if (sanadid == null || date == null)
      return;

    if (date.length < 8) {
      console.log('date not vali length');
      return;

    }
    this.loadServ.showloading(this.loaddata, 5);
    this.SanadService.duplcateSanad(sanadid ,date ).subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 5);


      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.openeDuplcate = false;
        this.loadSanad();
      }

     

    }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }

  onMergeSanad(e) {


    //console.log(JSON.stringify(this.mergeOption));
    this.openeMerge = false;
    this.loadServ.showloading(this.loaddata, 7);
    this.SanadService.mergeSanad(this.mergeOption).subscribe(data => {

      this.loadServ.hideloading(this.loaddata, 7);
      console.log(data + ' merged ');
      this.openeMerge = false;
      this.loadSanad();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }
  saveuioption() {

   // console.log(this.gridContainer.instance.getVisibleColumns()[1].width);

    var v = this.gridContainer.instance.getVisibleColumns();


    var res = [];
    for (var i = 0; i < v.length; i++) {
      res.push(v[i].width);

    }
    this.localst.optioncli.ui_sanadlist_grid_width = res;

    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }

  reftadarokat() {



    this.loadServ.showloading(this.loaddata, 6);
    this.generalService.Paziresh_reflesh(this.localst.yearnow_Class_get().codeSal).subscribe(data => {

      this.loadServ.hideloading(this.loaddata, 6);

      this.openeDuplcate = false;
      this.loadSanad();


    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  onValueChangedrang(e) {

    this.loadSanad();
  }

  //istzcu2dadghos
  dadghoslimit:any ;
  openlimit = false;
  limitdadghos() {

    this.openlimit = !this.openlimit;

    if (this.openlimit) {
      this.limitdadghosGet();
    }
  }

  onClicklimitdadghos() {

   this.loadServ.showloading(this.loaddata, 7);
    this.generalService.limitdadghostari_Set(this.dadghoslimit).subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.success('محدودیت ویرایش اعمال شد');
      this.openlimit = false;
      this.loadSanad();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
  
    });

  }


  limitdadghosGet() {

    this.loadServ.showloading(this.loaddata, 9);
    this.generalService.limitdadghostari_Get().subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 9);
      this.dadghoslimit = res.data;
    
    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.alertify.error(error);

    });

  }


  readExcel(event) {
    //گرفتن دیتاا
    this.excelService.importExcelFile(event).then(data => {

      if (data.length < 2)
        return;

      this.loadServ.showloading(this.loaddata, 11);
      this.SanadService.solimaniReadedExcelSanad(data).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 11);

        //console.log(data.vgf);

        // console.log(JSON.stringify(data));

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);

        }

        else {
          this.alertify.success(res.mes);
          this.loadSanad();
        }
 
      }, error => {
        this.loadServ.hideloading(this.loaddata, 11);
        this.alertify.error(error);
         
      });
      this.myInputexcel.nativeElement.value = "";

    });;



  }
  calculateFilterExpression(this: Column, filterValue, selectedFilterOperation, target) {
    // Override implementation for the "between" filter operation
    //if (filterValue == 'null') {
    //  const filterExpression = [
    //    [this.dataField, "=", ''],
    //    "or", 
    //    [this.dataField, "=", ' '],
    //  ];
    //  return filterExpression;
    //}

    
  }
  //onContentReady(e) {
  //  //var filterExpr = e.component.getCombinedFilter();
  //  //if (filterExpr.filterValue === "null") {
  //  //  return [this.dataField, "=", null];
  //  //} 
  //  ////t6u8765ui56
  //  //console.log('hjk');
  //}

}


