import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Sherkat } from '../../../../_models/app1/paye/Sherkat';
import { SherkatService } from '../../../../_services/app1/paye/Sherkat.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';

import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Localst } from '../../../../_services/localst.service';
import { TaxpayerInfo } from '../../../../_models/General/tax/TaxpayerInfo';
import { TaxGovService } from '../../../../_services/General/tax/taxGov.service';
 

@Component({
  selector: 'app-Sherkat-edit',
  templateUrl: './Sherkat-edit.component.html',
  styleUrls: ['./Sherkat-edit.component.css']
})
export class SherkatEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: Sherkat;
  readOnlyCode: boolean = true;
  valdata: DialogData;

  codeEghlist: string[];
  shenaseelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  noeshekatlist: IdName[] = [
    { id: 1, name: "سهامی عام" },
    { id: 2, name: "سهامی خاص" },
    { id: 3, name: "مسئولیت محدود" },
    { id: 4, name: "تضامنی" },
    { id: 5, name: "مختلط غیرسهامی" },
    { id: 6, name: "مختلط سهامی" },
    { id: 7, name: "نسبی" },
    { id: 8, name: "تعاونی" },
  ];
  toblist: IdName[];
  private sub: any;
  test: string;
    n: number;
 

  OstanKollist: OstanKol[];
  Ostanlist: Ostan[];
  loaddata: loadingCl;
  constructor(
    private alertify: AlertifyService,
    private loadServ: LoadingService,

    private SherkatService: SherkatService,
    private tafsiliService: TafsiliService,
    private taxGovService: TaxGovService,
    private localst: Localst,
    public dialog: MatDialog,
 
    private ostanService: OstanService) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
 
    
    this.myModel = this.SherkatService.getSherkatEmpty();
    this.codeEghlist = [];
    this.shenaseelist = [];
    this.rules = { "X": /[02-9]/ };
  }

  ngOnInit() {
    //this.sub = this.route.params.subscribe(params => {
    //  this.editId = +params['id'];
    //  this.n = 1;
    //  console.log(this.editId);
    //});

    this.toblist = this.SherkatService.gettoblist();

    this.loadServ.showloading(this.loaddata,0);
    this.ostanService.getOstanKols().subscribe(data => {
      this.loadServ.hideloading(this.loaddata,0);
      this.OstanKollist = data;
      //console.log(data);
    }, error => {
        this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.loadServ.showloading(this.loaddata,1);
    this.SherkatService.codeEghAndShenase().subscribe(data => {
      

      let brektonew = false;

      for (var i = 0; i < data.length; i++)
      {

        if (data[i] == '+++')
          brektonew = true;

        if (brektonew == false)
          this.codeEghlist.push(data[i]);


        if (brektonew == true)
          this.shenaseelist.push(data[i]);

      }



      this.loadServ.hideloading(this.loaddata,1);
    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.isnew = true;
    //this.myModel.code = '123';
    if (this.editId == -1) {
   
      console.log('in new mode');
      this.myModel = this.SherkatService.getSherkatEmpty();
      this.myModel.id = this.editId;

 

      this.isnew = true;

      this.loadServ.showloading(this.loaddata,2);
      this.tafsiliService.getNextCode(-1002).subscribe(data => {
        this.loadServ.hideloading(this.loaddata,2);
       // console.log('list is  ' + data.toString());
        this.myModel.code = data.toString();

        //tarazravannew فقط برای سلیمانی
        if (this.localst.get_dbName() == 'tarazravannew') {
        //  this.myModel.code = '';
          this.readOnlyCode = false;
        }

   


      }, error => {
          this.loadServ.hideloading(this.loaddata,2);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
      
    }
    else {
    
      console.log('in edit mode');
      this.isnew = false;

      this.loadServ.showloading(this.loaddata,3);
      this.SherkatService.getSherkat(this.editId).subscribe(
        (data: Sherkat) => {

          this.loadServ.hideloading(this.loaddata, 3);
          this.myModel = data;

 

          //   this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          //  console.log(JSON.stringify(this.myModel));
          //tarazravannew فقط برای سلیمانی
          if (this.localst.get_dbName() == 'tarazravannew') {
            this.readOnlyCode = false;
          }
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
   // this.myModel.Code = "0001";
  // this.isnew = true;
   // if (this.isnew)
  
    console.log(this.editId.toString());

    this.test = "j";
    this.rules = { "X": /[02-9]/ };
  }


  addthis = e => {
    // const buttonText = e.component.option("text");
    //notify("The button save was clicked");
    console.log("The button save was clicked");
    //let result = e.validationGroup.validate();
    //console.log(result.isValid);
    //if (result.isValid) {
    //  console.log("Thedf");
    //}

  }
  cancelthis(e) {
    console.log('cancel');
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
    //notify({
    //  message: "You have submitted the form",
    //  position: {
    //    my: "center top",
    //    at: "center top"
    //  }
    //}, "success", 3000);

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }


 


    if (this.myModel.ostanCode != null) {
      for (var val of this.OstanKollist) {
        //  console.log(this.myModel.ostanCode);
        if (val.codeKol == this.myModel.ostanCode) {
          // console.log('jjjjj    '+val.nameOstan);
          this.myModel.ostanName = val.nameOstan;
        }
         

      }
    }


    if (this.myModel.shahrCode != null) {
      for (var val2 of this.Ostanlist) {
        //  console.log(this.myModel.shahrCode);
        if (val2.codeShahr == this.myModel.shahrCode) {
          // console.log('jjjjj    ' + val.nameOstan);
          this.myModel.shahrName = val2.nameShahr;
        }

      }

    }

    if (this.myModel.kindShekatId != null) {
      for (var val3 of this.noeshekatlist) {
        //  console.log(this.myModel.shahrCode);
        if (val3.id == this.myModel.kindShekatId) {
          // console.log('jjjjj    ' + val.nameOstan);
          this.myModel.kindShekatN = val3.name;
        }

      }
    }
    else {
      this.myModel.kindShekatId = -1;
      this.myModel.kindShekatN = null;

    }

    if (this.myModel.adress != null) {
      let al = this.myModel.adress.length;
      if (al != 0 && al < 10) {
        this.alertify.warning('آدرس باید بین 10 تا 80 کاراکتر باشد');
        return;
      }
    }
    //hgiyt897677767
    if (this.myModel.shenase != null) {
      let m = this.myModel.shenase.length;
      if (m > 0 && m != 11) {

        this.alertify.warning('شناسه ملی باید 11 رقم باشد');
        return;

      }
    }

    if (this.myModel.codeEghtesadi != null) {
      let t = this.myModel.codeEghtesadi.length;
      if (t > 0 && t != 12) {

        this.alertify.warning('کد اقتصادی باید 12 رقم باشد');
        return;

      }
    }

    if (this.myModel.shEghtesadi != null) {
      let t = this.myModel.shEghtesadi.length;
      if (t > 0 && t != 11) {

        this.alertify.warning('شماره اقتصادی باید 11 رقم باشد');
        return;

      }
    }

    if (this.myModel.codePost != null) {
      let m = this.myModel.codePost.length;
      if (m > 0 && m != 10) {

        this.alertify.warning('کد پستی باید 10 رقم باشد');
        return;
      }

    }
    //tygfgyhfu6556543
   // console.log(JSON.stringify(this.myModel));
  //  console.log(val); // prints values: 10, 20, 30, 40
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.SherkatService.addSherkat(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
        if (res.value == 'shs') {
          this.es_conform(res);
        }
        else if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }


      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
      //this.myModel.tafsilisId.name = this.myModel.nameFarsi + ' ' + this.myModel.familyFarsi;
      //this.myModel.tafsilisId.nameE = this.myModel.nameEnglish + ' ' + this.myModel.familyEnglish;
      this.loadServ.showloading(this.loaddata,5);
      this.SherkatService.updateSherkat(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        if (res.value == 'shs') {
          this.es_conform(res);
        }
        else if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

      

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 
  es_conform(res:ApiRet) {
    //if (this.myShakhz._ignore_sh == true)
    //  return;

    let mess = 'هشدار !';

    this.valdata = { title: mess, rows: res.errors, valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {


      let dialogdata = result;

      if (dialogdata != null) {


        // console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.myModel._ignore_sh = true;
          this.onFormSubmit(null);
          // this.dodghativaziat(4);

        }
      }
    });




  }


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }


  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;
    this.loadServ.showloading(this.loaddata,6);
    this.ostanService.getOstans(ostankolcode).subscribe(data => {
      this.loadServ.hideloading(this.loaddata,6);
      this.Ostanlist = data;
      //console.log(data);
    }, error => {
        this.loadServ.hideloading(this.loaddata,6);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);

 
    //  this.SherkatService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");
       
    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });
  
    //console.log('sec ' + ccode);


  }
 // public  validationCallback(e: any): boolean {
  //  this.myModelCEgh_old = this.myModel.codeEghtesadi;
//this.myModelShenase_old = this.myModel.shenase;
 
  //validationCeghCallback(e) {

  //  let v = e.value;

  //  console.log('vaaa');

  //  if (this.myModelCEgh_old != null && this.editId != -1)
  //    if (this.myModelCEgh_old == v)
  //      return true;

  //  for (var val of this.codeEghlist) {
  //    if (v == val) return false;
  //  }

  //  return true


  //}

  //validationshenaseCallback(e) {

  //  let v = e.value;

  //  console.log('vaaa');

  //  if (this.myModelShenase_old != null && this.editId != -1)
  //    if (this.myModelShenase_old == v)
  //      return true;

  //  for (var val of this.shenaseelist) {
  //    if (v == val) return false;
  //  }

  //  return true


  //}

  validateNumber(e) {

    

  }
  onTobChanged(e) {

    this.myModel.tob = e.value
  }

  showPayer: boolean = false;
  paymodel: TaxpayerInfo = null;


  onGetTaxpayer() {

    //window.open("https://tax.gov.ir/action/do/InquiryNationalID", "_blank");
    //return;

    this.paymodel = new TaxpayerInfo();

    if (this.localst.sherkatInfo_get().taxVer != 2) {
      this.alertify.warning("استعلام تنها از طریق سرویس دو سامانه مودیان امکان پذیر است");
      return;
    }


    this.loadServ.showloading(this.loaddata, 2);
    this.taxGovService.GetTaxpayer(this.myModel.shEghtesadi).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);


      //e674578568
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.showPayer = true;
        this.paymodel = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  onGoLink() {

    window.open("https://tax.gov.ir/action/do/InquiryNationalID", "_blank");


  }
}
