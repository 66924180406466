import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';

import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';

import { Localst } from '../../../_services/localst.service';

import { ApiRet } from '../../../_models/General/ApiRet';

import { AnAmel, AnAmelBox, DoAmel } from '../../../_models/app2/glob/AnAmel';
import { AnAmelService } from '../../../_services/app2/glob/AnAmel.service';
import { Hesab } from '../../../_models/app1/paye/hesab';
import { HesabService } from '../../../_services/app1/paye/Hesab.service';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../_models/app1/paye/tafsiliForGroupRead';
import { TafsiliGbydaste } from '../../../_models/app1/paye/tafsiliGbydaste';
import DataSource from "devextreme/data/data_source";

@Component({
  selector: 'app-AnAmel-list',
  templateUrl: './AnAmel-list.component.html',
  styleUrls: ['./AnAmel-list.component.css']
})
 

export class AnAmellistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
 
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("taf5tempedit", { static: false }) taf5tempedit: DxSelectBoxComponent;
  @ViewChild("taf6tempedit", { static: false }) taf6tempedit: DxSelectBoxComponent;
  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();
  TafG: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];
  tafjustdaste: TafsiliGbydaste[] = [];
  TafGListallCount = 0;
  hesablist: Hesab[] = [];
  selectedrow: any;
  model: AnAmelBox;

  mastext: '-';
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
  devDataTaf5: any = {};
  devDataTaf6: any = {};
 
  gridsource: any[]=[];
  loaddata: loadingCl;
  raddata: any;
  constructor(

    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private hesabService: HesabService,
    private AnAmelService: AnAmelService,
    private route: ActivatedRoute,
    private TafsiliService: TafsiliService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 8);
    this.model = AnAmelService.getAnAmelBoxEmpty();


    this.raddata = [
      { id: 1, name: ' نوع سند' },
      { id: 2, name: 'طرف مقابل' },
      { id: 3, name: 'کد کالا' }
    ];

 
    //for (var i = 0; i < 200; i++) {
    //  this.gridsource.push({ id: i });
    //}



  }

  ngOnInit() {
    this.loadServ.hideloading(this.loaddata, 6);
    this.hesabService.getHesabs_treenum(3).subscribe((res: Hesab[]) => {

      this.loadServ.hideloading(this.loaddata, 6);
      this.hesablist = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
    });


 
    this.loadServ.hideloading(this.loaddata, 7);
    this.TafsiliService.getTafsiliJustGrupedByDaste(false, -1, 'none').subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 7);
      this.tafjustdaste = res.data;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
    });



 
    this.loadServ.hideloading(this.loaddata, 8);
    this.TafsiliService.getTafsilisGroupList().subscribe((res: any[]) => {

      this.loadServ.hideloading(this.loaddata, 8);
      this.TafGListall = res;
      this.TafGListallCount = this.TafGListall.length;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);
    });

   // this.tafg_reflesh();

   


  }
  ngAfterViewInit() {

   
  }
  onInitialized() {
 
    this.loadgrid();

  }

  loadgrid() {
   
    this.loadServ.showloading(this.loaddata, 2);
    this.AnAmelService.getall()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 2);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
  
        this.model = res.data;
       // this.gridsource = this.model.kind1;
     ////  this.gridContainer.r
        //  this.model[0]._butVaz = -1;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


  }
 


  addthis() {

  



    this.loadServ.showloading(this.loaddata, 3);
    this.AnAmelService.update(this.model)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 3);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.mes);
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });


  }
   
  onCellPrepared(e) {

    if (e.data == undefined)
      return;


   



  }
  temp_dateAchanged(e, cellInfo) {

    if (e.value == undefined || e.value == null || e.value == '') {

      //فقط اگر در دیتا بیس ذخیره بود دکمه نمایش داده شود
      if (cellInfo.data.id>0)
      cellInfo.data._butVaz = -1;
 
      return;
    }
    

 ///tgyutyu444

    let sd = e.value;
    sd = sd.replace('/', '').replace('/', '');

    if (sd.length < 8)
      return;

 
    cellInfo.data.dateA_S = sd.substring(0, 4) + '/' + sd.substring(4, 6) + '/' + sd.substring(6, 8);
    cellInfo.data.dateA = +sd;

    if (cellInfo.data.dateA > cellInfo.data._dbDate)
      cellInfo.data._butVaz = 1
    else if (cellInfo.data.dateA < cellInfo.data._dbDate)
      cellInfo.data._butVaz = -1
    else
      cellInfo.data._butVaz = 0;
    



  }
 
  RadChanged(e) {

    if (e.value == 1) 
      this.gridsource = this.model.kind1;
   else if (e.value == 2)
      this.gridsource = this.model.kind2;
   else if (e.value == 3)
      this.gridsource = this.model.kind3;



  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
   
  }


  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    //  console.log(e);

  // if null => set null
      this.tafg_reflesh(f.hesabId);
 
 


 

  }

  temp_moienintilized(e, cellInfo) {
    
  }
  onFocustaf5dit(e) {
    //if (this.taf5tempedit.minSearchLength == 0)
    //  this.taf5tempedit.minSearchLength = 3;

    this.taf5tempedit.instance.open();
 
  }
  onFocustaf6dit(e) {
    //if (this.taf6tempedit.minSearchLength == 0)
    //  this.taf6tempedit.minSearchLength = 3;

    this.taf6tempedit.instance.open();
    //    e.instance.open();
  }


  pv: any;
  temp_moienchanged(e, cellInfo) {

   // this.isfchangcall();


    this.pv = -1;
    // return;
    //  if (this.moieninchang)
    //   return;
    var selected;
    // this.moieninchang = true;
   // console.log('temp_moienchanged');

    var previousValue = e.previousValue;
    var newValue = e.value;
 

    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        break;
      
      }

    }
    if (selected != null) {



      ////////////////////


      cellInfo.data.hesabId = selected.id;
      cellInfo.data.hesabC = selected.codeSum;
      cellInfo.data.hesabN = selected.name;
     
     
    

    }
    else {

      cellInfo.data.hesabId = null;
      cellInfo.data.hesabC = null;
      cellInfo.data.hesabN = null;

    }

 

    cellInfo.data.taf5Id = null;
    cellInfo.data.taf5C = null;
    cellInfo.data.taf5N = null;

    cellInfo.data.taf6Id = null;
    cellInfo.data.taf6C = null;
    cellInfo.data.taf6N = null;


    if (this.dataGrid.focusedRowIndex == 0) {

    }

    if (selected != null)
      this.tafg_reflesh(selected.id);
    else
      this.tafg_reflesh(null);

 
    this.dataGrid.instance.focus();
   

  }


  tafg_reflesh(hesabid: number) {
   // console.log('hes : ' + hesabid);
    if (hesabid == null) {
      //hhjhjg776g

      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
 
      this.devDataTaf5 = [];
      this.devDataTaf6 = [];
    
      return;


    }
 

    this.TafG = null;



    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListallCount; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];



        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب

        for (var j = 0; j < this.tafjustdaste.length; j++) {
 
          //خواندن تفصیلی   5
          // if (this.TafGListall[_i].taf5List.length == 0)
          for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

            if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی 6
          // if (this.TafGListall[_i].taf6List.length == 0)
          for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

            if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
            }

          }


        }

         

        //dfrtee4445435
        this.TafG = this.TafGListall[_i];
        //this.devDataTaf4 = new DataSource({
        //  store: this.TafG.taf4List,
        //  paginate: true,
        //  pageSize: 50
        //});
        this.devDataTaf5 = new DataSource({
          store: this.TafG.taf5List,
          paginate: true,
          pageSize: 50
        });
        this.devDataTaf6 = new DataSource({
          store: this.TafG.taf6List,
          paginate: true,
          pageSize: 50
        });

        //if (!this.TafG.have4)
        //  this.selectedrow.taf4C = "-----";
        //if (!this.TafG.have5)
        //  this.selectedrow.taf5C = "-----";
        //if (!this.TafG.have6)
        //  this.selectedrow.taf6C = "-----";

       // console.log('$TafG:' + this.TafG.hesabId.toString() + ' l4:' + this.TafG.have4 + ' l5:' + this.TafG.have5 + ' l6:' + this.TafG.have6);


        return;
      }

    }









  }

  temp_taf5changed(e, cellInfo) {
 
    var previousValue = e.previousValue;
    var newValue = e.value;

    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //    this.searchedtaf5id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == newValue) {
        selected = this.TafG.taf5List[i];
        break;
      }

    }

    if (selected != null) {
      cellInfo.data.taf5Id = selected.id;
      cellInfo.data.taf5C = selected.code;
      cellInfo.data.taf5N = selected.name;
 
 

    }
   else {
      cellInfo.data.taf5Id = null;
      cellInfo.data.taf5C = null;
      cellInfo.data.taf5N = null;



    }
   

  }


  temp_taf6changed(e, cellInfo) {
 
    var previousValue = e.previousValue;
    var newValue = e.value;
    // console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;
    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //   this.searchedtaf6id = newValue;
    //treert56654y654


    var selected = null;
    for (let i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == newValue) {
        selected = this.TafG.taf6List[i];
        break;
      }

    }

    if (selected != null) {
      cellInfo.data.taf6Id = selected.id;
      cellInfo.data.taf6C = selected.code;
      cellInfo.data.taf6N = selected.name;

    }
    else {
      cellInfo.data.taf6Id = null;
      cellInfo.data.taf6C = null;
      cellInfo.data.taf6N = null;
    }


    
 

  }
}


