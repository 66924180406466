import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmVam, VmVamForCreateRowDto, VmVamRow } from '../../../_models/app98/opr/VmVam';
 
 
 
let EmptyVmVam: VmVam = {

  id: -1,
  code:null,
  cnt: 0,
  tafId: null,
  tafC: null,
  tafN: null,
  tafZId: null,
  tafZC: null,
  tafZN: null,
  dateA: 0,
  dateA_S: null,
  dateAsT: null,
  rs: 12,
  prcRow: 0,
  tasKind: 1,
  rsDone: 0,
  rsSpan: 1,
  rDateS: 0,
  rDateS_S: null,
  rDateE: 0,
  rDateE_S: null,
  prc: 0,
  karDarsad:0,
  prcKar: 0,
  prcSum: 0,
  prcRound: 0,
  prcDone: 0,
  karOnRow: 1,
  roundOnRow: 1,
  lastOnRow: 0,
  rows: [],
  vaz: 0,
  vazN: null,
  dis: null,
  uSa: null,
  uSaF: null,
  dateLog: null,
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  years: null,
  karOnVam: true,
  sanadId: null,
  radif: 0,
  prcOldVam: 0,
  isPrcOldVam: false
};

let EmptyVmVamRow: VmVamRow = {
  id: 0,
  code:null,
  vmVamId: 0,
  tafId: null,
  tafC: null,
  tafN: null,
  rNum: null,
  rNumAll: null,
  prc: 0,
  prcKar: 0,
  prcSum: 0,
  vaz: 0,
  vazN: null,
  dateA: 0,
  dateA_S: null
};

let EmptyVmVamForCreateRow: VmVamForCreateRowDto = {

  rs: 0,
  prcRow: 0,
  tasKind: 1,
  rsDone: 0,
  rsSpan: 0,
  rDateS: 0,
  rDateS_S: null,
  rDateE: 0,
  rDateE_S: null,
  prc: 0,
  karDarsad: 0,
  prcKar: 0,
  prcSum: 0,
  prcRound: 0,
  prcDone: 0,
  karOnRow:1,
  roundOnRow: 1,
  lastOnRow: 0,
  karOnVam:null
}



const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmVamService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(addnone:boolean): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/getall?addnone=' + addnone;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: VmVam): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getVmVamEmpty(): VmVam {

    let l = JSON.parse(JSON.stringify(EmptyVmVam)) as VmVam;
    const d: Date = new Date(); 
    l.dateAsT = d;
    l.dateELog = d;
    l.dateLog = d;
    return l;

  }
  getVmVamRowEmpty(): VmVamRow {


    return JSON.parse(JSON.stringify(EmptyVmVamRow));

  }
  getVmVamForCreateRow(data: VmVam): VmVamForCreateRowDto {

    let res = JSON.parse(JSON.stringify(EmptyVmVamForCreateRow)) as VmVamForCreateRowDto;
 
    res.rs = data.rs;
    res.tasKind = data.tasKind;
    res.prcRow = data.prcRow;

    res.rsDone = data.rsDone;
    res.rsSpan = data.rsSpan;
    res.rDateS = data.rDateS;
    res.rDateS_S = data.rDateS_S;
    res.rDateE = data.rDateE;
    res.rDateE_S = data.rDateE_S;
    res.prc = data.prc;
    res.karDarsad = data.karDarsad;
    res.prcKar = data.prcKar;
    res.prcSum = data.prcSum;
    res.prcRound = data.prcRound;
    res.prcDone = data.prcDone;

    res.karOnRow = data.karOnRow;
    res.roundOnRow = data.roundOnRow;
    res.lastOnRow = data.lastOnRow;
    res.karOnVam = data.karOnVam;

 
    return res;

  }
  update(item: VmVam): Observable<ApiRet>{
    let apiadress = '/api/App98/Opr/VmVam/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(VmVamId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/delete?id=' + VmVamId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }

  deletes(VmVamId: number[]): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/deletes'
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, VmVamId, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  createRows(model: VmVamForCreateRowDto): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/createRows';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress,  model, { 'headers': headers });
  }


  getTasKind(): any[] {

    var data: any[] = [];
   
    data.push({ id: 2, name: 'مبلغ هر قسط' });
    data.push({ id: 1, name: 'تعداد اقساط' });


    return data;
  }


  getMandehAghsat(tafid , vamId : number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmVam/getMandehAghsat?tafId=' + tafid +'&vamId=' + vamId.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
