import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 

import { LoadingService } from '../../../../_services/loading.service';
import { PdMyCk } from '../../../../_models/app4/paye/PdMyCk';
import { PdMyCkService } from '../../../../_services/app4/paye/PdMyCk.service';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { PdBankService } from '../../../../_services/app4/paye/PdBank.service';
import { PdBank } from '../../../../_models/app4/paye/PdBank';
import { Localst } from '../../../../_services/localst.service';
 
@Component({
  selector: 'app4-PdMyCk-edit',
  templateUrl: './PdMyCk-edit.component.html',
  styleUrls: ['./PdMyCk-edit.component.css']
})
export class PdMyCkEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PdMyCk;
  myModel_old: PdMyCk;
 
 
 
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
  n: number;

  pdBanklist: PdBank[] = [];

 

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
 
    private pdMyCkService: PdMyCkService,
    private pdBankService: PdBankService,
    private localst: Localst,
 

  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.pdMyCkService.getPdMyCkEmpty();
 
 
  }

  ngOnInit() {
 
 
   

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.pdMyCkService.getPdMyCkEmpty();
 
      this.myModel.years = this.localst.yearnow_get();

      this.myModel.dateA_S = this.localst.dateEmruz.dateString;

      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;
      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.pdMyCkService.getbyid(this.editId).subscribe(
        (res) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = res.data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          

 

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
     this.pdBankService.getall(1).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);
   
       this.pdBanklist = res.data;
     


    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
     });




 
  

    console.log(this.editId.toString());
  
  }


  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {
      this.myModel.id =-1
    }

    if (this.myModel.rows.length == 0) {
      this.alertify.showListString(['هیچ ردیفی ثبت نشده']);
      return;
    }
 
 

   
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.pdMyCkService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);

        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }
     
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.pdMyCkService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);

        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

 
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
 

  validateNumber(e) {

    

  }
 
  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }


 
  t4changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    if (h == null)
      return;

    //this.myModel.taf4C = h.code;
    //this.myModel.taf4N = h.name;
 
  }




  mochangedB(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    //this.myModel.hesabBC = h.codeSum;
    //this.myModel.hesabBN = h.name;


  }



  mochangedC(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;
 
    if (h == null)
      return;

    //this.myModel.hesabCC = h.codeSum;
    //this.myModel.hesabCN = h.name;

 

  }
  onClickCreate() {

    this.creatRow();
  }
  creatRow() {

    if (this.myModel.paperAll == null || this.myModel.paperS == null)
      return;

    for (var j of this.myModel.rows) {
      if (j.vaz == 2) {
        this.alertify.warning('از این چک استفاده شده');
        return;
      }
    }

     
    this.myModel.rows = [];
    let start = this.myModel.paperS;
    for (var i = 0; i < this.myModel.paperAll; i++) {

      let a = this.pdMyCkService.getPdMyCkRowEmpty();

      a.id = start * -1;
      a.paperC = start;
      a.seri = this.myModel.seri;
      a.vaz = 1;
      a.vazN = 'سفید';
  
      start++;
      this.myModel.rows.push(a);
       
    }



 


  }

  onValueChangeSayadi(e, cellInfo) {

  

    if (e.value == null || e.value == undefined)
      return;
    

    if (cellInfo.data.vaz != 1)
      return;

 
    if (e.value.length == 16) {
      cellInfo.data.sayadi = e.value;
    }




  }
}
