import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
 
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
import { DirSellService } from '../../../../_services/app3/opr/DirSell.service';
 
import { tabeventobj } from '../../../../_models/tabeventobj';
import { GeneralService } from '../../../../_services/General/General.service';
import DataSource from "devextreme/data/data_source";
import { DoAmel } from '../../../../_models/app2/glob/AnAmel';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TaxGovService } from '../../../../_services/General/tax/taxGov.service';

 
//const express = require('express-session');
//const fs = require('fs');
//const bodyparser = require('body-parser');
//const https = require('https')

//const { Fido2Lib } = require("fido2-lib");

@Component({
  selector: 'app3-DirSell-list',
  templateUrl: './DirSell-list.component.html',
  styleUrls: ['./DirSell-list.component.css']
})
 

export class DirSellListComponent implements OnInit {
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;;
  @Input() hyperLinkOption: any;

  @Input() kindpage: number;

     //kind نوع صفحه
        //DirSellId ای دی سند
        //DirSellRowId ردیف فکوس
  @Input() inputdata: any;
  openImport: boolean;
 
  openeprint: boolean;
  openeDuplcate : boolean;
  openeMerge : boolean;
  openedit: boolean;
  openeditId: number;
  sanadopen: boolean = false;
  sanadkind: number = 0;

  monthRang: number[];


  focusedDirSellRowId: number;
 
 


 
  modlelist: any = {};
 
    
  
 
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  mergeOption: any;
  constructor(private userService: UserService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private AnAmelService: AnAmelService,
    private loadServ: LoadingService,
    private taxGovService: TaxGovService,
    private alertify: AlertifyService,
    private DirSellService: DirSellService,
    private generalService: GeneralService,
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {

   this.modlelist = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: null
      }

    });
    this.loaddata = loadServ.getEmpty();
  //  this.loadServ = new loadingClass(8);
   // let sam: loadingCl = new loadingClass(5);
  //  let myTask: Task = new MyTask('someTask');
   // myTask.run("test");
    this.loadServ.create(this.loaddata,10);
   // this.loadServ2.showloading(7);
   // this.loadServ2.showed = true;

   // this.loadServ2.showloading(0);
   // this.monthRang = [localst.dateEmruz.month -3, localst.dateEmruz.month]
    this.monthRang = [1, 12]
 
    if (this.monthRang[0] < 1)
      this.monthRang[0] = 1;
 
  
    this.openImport = false;
    this.openeprint = false;
    this.openedit = false;
    this.openeDuplcate = false;
    this.openeMerge = false;
    this.openeditId = -1;
    this.selectedrowkeys = [];
    this.selectedrowkeysIgnored = [];
    this.focusedDirSellRowId = -1;
    this.duplcateOption = {
      copyCSh : null,
      copyId: null,
      paseCSh: null,
      paseId: null,
      date : '1000/00/00'

    }
    this.mergeOption = {

      years: 0,
      byKind: 'inList',

      inCShomareS: 1,
      inCShomareE: 1,

      inDaterangS: null,
      inDaterangE: null,

      inDateOneS: null,
      inDateOneE: null,

      inList: [],
    }

 
  } 
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);

 //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeDirSell();
    //}
    



  }
  ngOnInit() {
 

    
 

  }
 
  
 
  
  load() {

 
    this.loadServ.showloading(this.loaddata, 0);
    this.DirSellService.getall(this.localst.yearnow_get(),this.kindpage)
      .subscribe((res) => {
       
        this.loadServ.hideloading(this.loaddata, 0);
      
        this.modlelist = new DataSource({
          store: {
            type: "array",
            key: "id",
            data: res.data,
            
          }
 
        });
      
       // this.modlelist = res;

        this.gridContainer.scrolling.scrollByContent = true;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

  }

  reloadrows(): void {
   // this.load();
  //  console.log("m:page refleshe done");
   // this.load();
  }

  onFocusedRowChanged(e) {
 

  }


  onClickDelete() {

 
    this.openDialog();


  }

  openDialog(): void {

   
    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });
   // dialogRef.componentInstance.message = 'jjj';
   
    dialogRef.afterClosed().subscribe(result => {
     
   
      let isdelete = result;
 
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }


  

  dodelete() {


    var key = this.gridContainer.focusedRowKey;
 
 //657657658

    this.loadServ.showloading(this.loaddata,2);
    this.DirSellService.delete(key).subscribe((res) => {
      this.loadServ.hideloading(this.loaddata,2);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);


      }
      else {
        this.alertify.success(res.mes);
        this.load();
      }
     
 

    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 
  

  
     
  }



  OnMyCancelEvent(e) {
  
    if (e) {
      this.openedit = false;
      this.openeditId = -1;
    }

  }


  OnMyCancelxlsEvent(e) {

    if (e) {
      this.openImport = false;
      this.openeditId = -1;
    }

  }


  onClickNew() {
 
    this.openedit = true;


  }


  onClickEdit() {

   let key= this.gridContainer.focusedRowKey;

    this.openeditId = key;
    this.openedit = true;

  }
  OnMySaveEvent(e) {

 
     
    this.openImport = false;
      this.load();
 



 

  }


  




  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

  onCellPrepared(e) {



  }
 

  onClickReflesh() {

    
    this.load();




  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance,' لیست اسناد ');

 
  }

  onExporting(e) {

  }
  onClickPrint() {


  

    let indx = this.gridContainer.focusedRowIndex;

    if (indx == -1) {
      if (this.modlelist != undefined)
        if (this.modlelist._items.length > 0)
          indx = 0;
    }

    if (indx != -1) {
      var data = this.gridContainer.instance.getCellElement(indx, 'code');
      this.printnum = +data.textContent;
    }

    this.openeprint = true;

  



    //if (this.openeDuplcate)
    //  this.openeDuplcate = false;

  }
    //اگر پرینت از لیست سند صدا شود
  onCloseprint(e) {
 
    //console.log('click2');
    this.openeprint = false;

  }
  onPrintPrint(e) {

    this.onPrint.emit(e);
    this.openeprint = false;

  }
  //اگر پرینت از صفحه ویرایش سند صدا شود
  OnEditPrintEvent(e) {

    if (e == null)
      return;

    this.onPrint.emit(e);
    this.openeprint = false;

    

  }
  test8() {
   // this.gridContainer.instance.sc
    console.log("test8");
  //  this.gridContainer.instance.columnOption(5, "width", 900);
  }

  onClickDublctaeDirSell() {

   let id =  this.gridContainer.focusedRowKey;

    if (id == null)
      return;

    for (var r = 0; r < this.modlelist._items.length; r++) {

      if (this.modlelist._items[r].id == id) {


        this.duplcateOption.copyId = this.modlelist._items[r].id;
        this.duplcateOption.copyCSh = this.modlelist._items[r].cShomare;
        this.duplcateOption.paseId = null;
        this.duplcateOption.paseCSh = null;
        this.duplcateOption.date = null;
      //  this.duplcateOption.date = this.modlelist[r].date_String;

        if (this.modlelist._items[r].vaziat != 200) {
          this.alertify.alert('سند باید در وضعیت موقت قرار داشته باشد');
          return;

        }

        break;
      }

    }



    if (this.duplcateOption.copyId == null)
      return;

    this.openeDuplcate = true;
    if (this.openeprint)
      this.openeprint = false;

  }



  onClickMergeDirSell() {

    var keyss = this.gridContainer.selectedRowKeys;

   

   var selected = [];
   var ignored = [];

    if (keyss != null && keyss.length > 0)
    for (var i = 0; i < keyss.length; i++) {


      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (keyss[i] == this.modlelist._items[j].id) {
          if (this.modlelist._items[j].vaziat == 200) {
            selected.push(this.modlelist._items[j].id);
          }
          else {
            ignored.push(this.modlelist._items[j].id);

          }

          break;
        }

      }

    }

    if (ignored.length > 0) {

      this.alertify.message(ignored.length.toString() +' ردیف نادیده گرفته شد ' + ' همه ی اسناد باید در وضعیت موقت قرار داشته باشند ');
     

    }

    if (selected.length > 0) {
      this.mergeOption.inList = selected;
      this.mergeOption.byKind = 'inList';

    }  


    this.openeMerge = true;
    if (this.openeprint)
      this.openeprint = false;
    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }

  onCloseSSS(e) {

    this.openeDuplcate = false;

  }
  onClosemerge(e) {

    this.openeMerge = false;

  }
  onDuplcateDateChanged(e) {


    var newcshomare = -1;
    for (var r = 0; r < this.modlelist._items.length; r++) {

      newcshomare = this.modlelist._items[r].cShomare;

      if (this.modlelist._items[r].date <= e)
        break;
      

    }

    newcshomare++;

    this.duplcateOption.paseCSh = newcshomare;


  }


 
  onValueChangedrang(e) {

    this.load();
  }



  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.gridContainer.instance.columnCount(); i++) {
      var w = this.gridContainer.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw3002 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw3002.length; i++) {
      this.localst.optioncli.gw3002[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw3002 = this.localst.optionClient_readDefault('gw3002');
  }






  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }


  Sanadids: number[];
  onClicksanadjoiner(e) {

    this.Sanadids = this.gridContainer.selectedRowKeys;



    if (this.Sanadids.length < 1) {
      this.alertify.warning('هیچ سندی انتخاب نشده است');
      return;
    }

    this.sanadopen = !this.sanadopen;
    this.sanadkind = e;




  }


  closesanad() {
    this.sanadopen = false;
    this.sanadkind = 0;
  }

  doAmel: DoAmel;
  onClickAmel(e) {

    this.doAmel = this.AnAmelService.getDoAnAmelEmpty();
    this.doAmel.kind = e;
    this.doAmel.tableN = 'DirSell';
    this.doAmel.sanadIds = [];
    let keys = this.gridContainer.selectedRowKeys as number[];
    if (keys.length == 0) {
      this.alertify.warning('ابتدا ردیفی را انتخاب کنید');
      return;
    }
    //frdty4356
    this.doAmel.sanadIds = keys;

    this.loadServ.showloading(this.loaddata, 13);
    this.AnAmelService.doAmel(this.doAmel).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.message(res.mes);
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });
  }

  firstper: boolean = true;
  onPrepared() {

    if (this.firstper) {
      this.firstper = false;
      this.load();
    }
    

  }

  onClickMoad() {

   // this.alertify.showListString(['توکن امضا الکترونیک شما فعال نیست']);


    var keys = this.gridContainer.selectedRowKeys;

    if (keys == null)
      return;


    this.loadServ.showloading(this.loaddata, 3);
    this.taxGovService.sendDirSell(keys).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.frameless(res.mes);
        this.load();
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
    });


  }
  onClickChek() {


    this.loadServ.showloading(this.loaddata, 3);
    this.taxGovService.checkProcessDirSell(this.localst.yearnow_get()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        this.load();
      }
      else {
        this.alertify.message(res.mes);
        this.load();
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
    });


  }

  onClickEr() {

    var keys = this.gridContainer.selectedRowKeys;

    let a = [];
    //tgu65856856
   
    for (var r of this.modlelist._items) {


        for (var k of keys) {


          if (r.id == k) 
            a.push( r.taxErD);

        }
      

      }
    
    this.alertify.showListString(a);

  }
  onClicktest() {
   // const signer = require('pkcs11-smartcard-sign');
  //  var pkcs11 = new pkcscls.PKCS11();
 
    //const f2l = new Fido2Lib({
    //  timeout: 42,
    //  rpId: "example.com",
    //  rpName: "ACME",
    //  rpIcon: "https://example.com/logo.png",
    //  challengeSize: 128,
    //  attestation: "none",
    //  cryptoParams: [-7, -257],
    //  authenticatorAttachment: "platform",
    //  authenticatorRequireResidentKey: false,
    //  authenticatorUserVerification: "required"
    //});





    //console.log(fs);
    ////console.log(express);
    //console.log(JSON.stringify(fs));
    //console.log(bodyparser);
   // var bodyparse = require('express');

    //var express = require('express');
   // const fs = require('fs')
   // const https = require('https')
  //  const X509Security = require('azure-iot-security-x509').X509Security;


   // greet();
   // CryptoUtils.g1();
  }

   



  onClickimport() {


    this.openImport = true;

  }





}


