import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { AnSellHes, AnSellHesTab } from '../../../_models/app3/paye/AnSellHes';
 
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
let EmptyAnSellHes: AnSellHes = {

  id: null,
  hb206Id: null,
  hb206C: null,
  hb206N: null,
  hb207Id: null,
  hb207C: null,
  hb207N: null,
  hb208Id: null,
  hb208C: null,
  hb208N: null,
  hb209Id: null,
  hb209C: null,
  hb209N: null,
  hesab706Id:  null,
  hesab706C: null,
  hesab706N: null,
  hesab707Id: null,
  hesab707C: null,
  hesab707N: null

};
let EmptyAnSellHesTab: AnSellHesTab = {

  id: null,
  tabagheId: null,
  tabagheC: null,
  tabagheN: null,
  hesabId: null ,
  hesabC: null,
  hesabN: null,
  hb201Id: null,
  hb201C: null,
  hb201N: null,
  hb202Id: null ,
  hb202C: null,
  hb202N: null,
  hb204Id: null,
  hb204C: null,
  hb204N: null,
  hb205Id: null,
  hb205C: null,
  hb205N: null,
   hesab706Id: null,
  hesab706C: null,
  hesab706N: null,
  hesab707Id: null,
  hesab707C: null,
  hesab707N: null

};
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnSellHesService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getFirst_AnSellHes(): Observable<ApiRet> {
    let apiadress = '/api/App3/Paye/AnSellHes/getFirst_AnSellHes';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
   
 
  addorUpdate_AnSellHes(item: AnSellHes): Observable<ApiRet> {
    let apiadress = '/api/App3/Paye/AnSellHes/addorUpdate_AnSellHes';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  getAnSellHesEmpty(): AnSellHes {
    return JSON.parse(JSON.stringify(EmptyAnSellHes));
  }


  getFirstGroup_AnSellHesTab(): Observable<ApiRet>{
    let apiadress = '/api/App3/Paye/AnSellHes/getFirstGroup_AnSellHesTab';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



  addorUpdate_AnSellHesTab(item: AnSellHesTab[]): Observable<ApiRet> {
    let apiadress = '/api/App3/Paye/AnSellHes/addorUpdate_AnSellHesTab';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  getAnSellHesTabEmpty(): AnSellHesTab {
    return JSON.parse(JSON.stringify(EmptyAnSellHesTab));
  }

  
}
