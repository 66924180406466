import { Photo } from "../photo";

 

export interface SherkatInfo {
      



   id :number,

  customer: string,
  name: string,

  shEghtesadi: string,

  shSabt: string,

  shenaseMelli: string,

  email: string,

  fax: string,

  post: string,

  tell: string,

  adressA: string,

  adressB: string,
  adressC: string,


ostanAId: number,
 shahrAId: number,

  ostanAN: string,

  shahrAN: string,


 ostanBId : number,
shahrBId : number,
 
    ostanBN: string,

 shahrBN: string,
  taxVer:number,

  editCnt: number,
  demoApp1: number,
  demoApp2: number,
  demoApp3: number


  


 
    
}

export class SherkatInfoKeys {
  priKey: string;
  priKeySt: string;
  clientId: string;
  taxUrl: string;
  taxVer: number;
  cer2Path: string;
  pri2Path: string;
  tax2Url: string;
  client2Id: string;
  fileCer: FileToUpload;
  filePri: FileToUpload;
  constructor(){
    this.taxUrl = 'https://sandboxrc.tax.gov.ir/req/api';
    this.tax2Url = 'https://sandboxrc.tax.gov.ir/requestsmanager';
    this.taxVer = 1;
 

  }

}


export class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  lastModifiedTime: number = 0;
  lastModifiedDate: Date = null;
  fileAsBase64: string = "";
}

