import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnSell, AnSellAf, AnSellBar, AnSellRow, AnSellRowForMabna } from '../../../_models/app3/opr/AnSell';
import { LastPrc } from '../../../_models/app3/opr/LastPrcDto';
 

 
@Injectable({
  providedIn: 'root'
})


export class TaxGovService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
 
  constructor(private http: HttpClient, private localstService: Localst) {

    
  }

 
  checkConnection(inti: number, version: number): Observable<ApiRet> {
    let v = version.toString();
    if (version == 1)
      v = '';

    let apiadress = '/api/General/Tax/TaxGov' + v +'/checkConnection?inti=' +inti.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



  sendAnSell(ansellIds: number[], version: number ): Observable<ApiRet> {

    let v = version.toString();
    if (version == 1)
      v = '';


    let apiadress = '/api/General/Tax/TaxGov' + v + '/sendAnSell';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, ansellIds, { 'headers': headers });

  }

  EditAsEbtalAnSell(ansellIds: number[]): Observable<ApiRet> {

    let apiadress = '/api/General/Tax/TaxGov/EditAsEbtalAnSell';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, ansellIds, { 'headers': headers });

  }
  EditAsModifyAnSell(ansellIds: number[]): Observable<ApiRet> {

    let apiadress = '/api/General/Tax/TaxGov/EditAsModifyAnSell';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, ansellIds, { 'headers': headers });

  }
  checkProcess(year, version: number): Observable<ApiRet> {

    let v = version.toString();
    if (version == 1)
      v = '';


    let apiadress = '/api/General/Tax/TaxGov' + v + '/checkProcess?year=' + year;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }

  sendDirSell(dirsellIds: number[]): Observable<ApiRet> {

    let apiadress = '/api/General/Tax/TaxGov/sendDirSell';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, dirsellIds, { 'headers': headers });

  }


  checkProcessDirSell(year): Observable<ApiRet> {

    let apiadress = '/api/General/Tax/TaxGov/checkProcessDirSell?year=' + year;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  GetFiscalInformation(): Observable<ApiRet> {



    let apiadress = '/api/General/Tax/TaxGov2/GetFiscalInformation';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  GetTaxpayer(economicId:string): Observable<ApiRet> {



    let apiadress = '/api/General/Tax/TaxGov2/GetTaxpayer?economicId=' + economicId;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
}
