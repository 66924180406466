import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
 
 
 
import { Localst } from '../localst.service';
import { Note } from '../../_models/General/Note';
import { ApiRet } from '../../_models/General/ApiRet';
 
 
 

 
let EmptyNote: Note = {

 
  id: 0,
  tit: null,
  txt: null,
  dateLog: new Date(),
  dateP: '',
  uNLog: 'none',
  uFLog: 'none',
  uVazNLog: 0,
  vazId: 0,
  vazN: 'none',
  color: 'none',
  sort: 0,
  years: 0,
  cheked: false,
 

};
 
@Injectable({
  providedIn: 'root'
})
export class NoteService {
  baseUrl = environment.apiUrl;
  origin = location.origin;
  datalist: Note[];
 
 
  constructor(private http: HttpClient, private localstService: Localst) { }

 
  getyyyyyList(): Note[] {
    this.datalist = [];
 
 
    return this.datalist;
  }

 

  getNotes(cheked: boolean , year): Observable<ApiRet> {
    let apiadress = '/api/General/Note/getall?cheked=' + cheked+'&year='+year;
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  

 
  getNotebyid(id): Observable<Note> {
    let apiadress = '/api/General/Note/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);
 
    let v = this.http.get<Note>(this.origin + apiadress, { 'headers': headers }).pipe(
     
    );
  
    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  getNoteBycodesal(id): Observable<Note> {
    let apiadress = '/api/General/Note/getbyCodeSal?CodeSal=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Note>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  addNote(item: Note) : Observable<ApiRet> {
    let apiadress = '/api/General/Note/add';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.post<ApiRet>(this.origin + apiadress,item, { 'headers': headers });
  }



  getNoteEmpty(): Note {

    return JSON.parse(JSON.stringify(EmptyNote)); 


  }

  updateNote(item: Note): Observable<ApiRet> {

    let apiadress = '/api/General/Note/update';
    var headers = this.localstService.header_get(apiadress,null);
  

    return this.http.post<ApiRet>(this.origin + apiadress,item, { 'headers': headers });
  }

 
  deleteNote(NoteId: number) {
    let apiadress = '/api/General/Note/delete?id=' + NoteId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
 
  }
 
  countCode(item: string) {
    let apiadress = '/api/Note/countNotecode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  NoteListCode(): Observable<string[]> {
    let apiadress = '/api/General/Note/Notecodelist';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
}
