import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
 MatStepperModule
} from '@angular/material';

@NgModule({
  imports: [MatButtonModule,
    MatIconModule,
    MatStepperModule




  ],
  exports: [MatButtonModule,
    MatIconModule,
    MatStepperModule


  ]

})

export class MaterialModule {}
