import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { Router } from '@angular/router';
import { MatTab, MatTabGroup } from '@angular/material';
import { QueryList } from '@angular/core';
import { ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Menrow, MenurowService } from './_services/General/menurow.service';
import { Menurow } from './_models/General/menurow';
import { MorurOption } from './_models/app1/rep/Morur';
import { SaleMaliService } from './_services/General/SaleMali.service';
import { Localst } from './_services/localst.service';
import { SaleMali } from './_models/General/saleMali';
import { Customer } from './_models/customer';
import { AlertifyService } from './_services/alertify.service';
import { DxSelectBoxComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ViewChild } from '@angular/core';
import { GeneralService } from './_services/General/General.service';
import { LoadingService } from './_services/loading.service';
import { tabeventobj } from './_models/tabeventobj';
import { SherkatInfo } from './_models/General/sherkatInfo';
import { MyTimeService } from './_services/myTime.service';
import { Yaddasht } from './_models/General/yaddasht';
import { YaddashtService } from './_services/General/Yaddasht.service';
import { VmDarRepOp } from './_models/app98/rep/VmDarRep';
import { AccService } from './_services/General/Acc.service';
import dxTreeView from 'devextreme/ui/tree_view';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
 
@Component({
  selector: 'tabp-root',
  templateUrl: './tabp.component.html',
  styleUrls: ['./tabp.component.css']
})
export class tabpComponent implements OnInit {

 

  @Input() compname: string;

 
  @Input() options;
  @Input() menus: any[];

  yaddashtlist: Yaddasht[];
  dddd: boolean;

  public tabGroup: MatTabGroup;
  //برای وقتی که مرورگر کلا میبندیم
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {

    //if (location.hostname === "localhost") {
    //  alert("It's a local server!");
    //}
    if (this.tabs.length > 0 && location.hostname != "localhost") {
      $event.returnValue = true;
   

    }
  }
 
  @ViewChildren('allTheseThings') things: QueryList<any>;

  ngAfterViewInit() {
    if (this.tabs.length < 1)
      return; 

    this.selected.setValue(this.tabs.length - 1);
    this.things.changes.subscribe(t => {
      this.ngForRendred();
    })

  }
  ngForRendred() {
 //   console.log('NgFor is Rendered');

   // this.selected.setValue(this.tabs.length - 1);
  }

  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab>;
  public closedTabs = [];


  selected = new FormControl(0);
  MemberListComponent: boolean;
  menusourslist: any[];
 
  visiblenenu: boolean;
  navVaziat: number;
  @Input() tabs = [];

  tabsarchive: any;
  tabsarchive2: any; 
  gg: number;
  navclassw: string;
  rightSide: string;
  leftSide: string;
  lodermessage: string;
  loaddata: loadingCl;
  timeourerror: boolean;
  hero: any;
  constructor(private authService: AuthService, private router: Router,  
    private menurowService: MenurowService, private saleMaliService: SaleMaliService, private LocalstService: Localst, private alertify: AlertifyService,
    private loadServ: LoadingService,
    private YaddashtService: YaddashtService,
    private generalService: GeneralService,
    private myTimeService: MyTimeService,
    private accService: AccService,

  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 10);
    this.lodermessage = 'درحال بارگذاری تنظیمات';

    this.refleshnavvaziat(31);
 


    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        alert("One more page already open");
        window.location.replace('home');
      }
    }, false);







    this.timeourerror = false;
    //در یافت رویداد جهانی
    saleMaliService.salemaliChangEmitter$.subscribe(data => {
      console.log("eventrecive" + data);
      this.tabs = [];


    }, error => {

    });
    this.LocalstService.sherkatInfo = this.generalService.getSherkatInfo_null();
     
    this.menusourslist = this.menurowService.GetMenowAsRow();
 

  }
 
  ngOnInit() {

    var i = localStorage.getItem('token');
    if (i == null || i == undefined) {
      this.router.navigate(['/']);
    }

    this.bindGlobals();

    this.LocalstService.cheatlib = 0;
    this.dddd = false;



     this.LocalstService.getMySalshChangedEvent()
       .subscribe(item => this.onshalshchanged(item));



       ///567457457gfhj56utyjh56gh


   this.yaddashtlist =  this.YaddashtService.getyyyyyList();
    this.intilizeAcc();

    //this.CompaireVersion();

  }
  //showUpd: boolean = false;
  //textUpd: string = '';
  //newversion: string = '';
  //CompaireVersion() {



  //  this.loadServ.showloading(this.loaddata, 5);
  //  this.generalService.getTarazVersion().subscribe(res => {
  //    this.loadServ.hideloading(this.loaddata, 5);
  //    this.textUpd = res.data;
  //    this.newversion = res.value;
  //    if (this.newversion != this.LocalstService.version) {
  //      this.showUpd = true;
  //    }
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 5);
  //  });


  //}
  onClickreflesh() {

    location.reload();
  }
  menutemp : any=[];
  intilizeAcc() {

    this.accService.menuRows = [];
    let u=  this.LocalstService.usernow_get();

    this.loadServ.showloading(this.loaddata, 3);
    this.accService.initialize(u.customerName,u.username).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);

      }
      else {
        this.accService.menuRows = res.data;
        this.menutemp = JSON.parse(JSON.stringify(this.accService.menuRows));
        //hfthufr
      }
    }, error => {

      //this.timeourerror = true;
      this.loadServ.hideloading(this.loaddata, 3);
      this.lodermessage = 'خطا در پیکربندی سطوح دسترسی';
      this.alertify.error(this.lodermessage);

    });



  }

  onshalshchanged(e: boolean) {
    this.tabs=[];
   // console.log('all tabs closed')
  }
  bindGlobals() {



 
 

    this.tabsarchive = this.menurowService.GetMenowAsRow();

  //  this.tabsarchive2 = this.menurowService.GetMenowAsTree();
    //this.tabsarchive2 = this.accService.menuRows;
    //?????????? 
    let un = this.LocalstService.usernow_get().username;

    //this.tabsarchive2.splice(1, 2);

    //if (un == 'mali1')
    //   {
    
    //  this.tabsarchive2.splice(1, 2);
    //  }
    //else {

    //  this.tabsarchive2.splice(3, 1);
    //}


    //if (un == 'koohkan' || un == 'parsa' || un == 'ashkan')
    //for (var r of this.tabsarchive2) {
    //  //if (r.disabled != undefined)
    //  //  r.disabled = true;
    //  this.updatetree_temp(r);

    //}

     
    //if (un == 'moradi' || un == 'khodam')
    //  for (var r of this.tabsarchive2) {
    //    //if (r.disabled != undefined)
    //    //  r.disabled = true;
    //    this.updatetree_temp2(r);
    //  }


  }
  //موقت
  updatetree_temp(d: Menrow) {
  
    d.disabled = false;


    if (
     
      d.id == '1_2' 
   
   )
    d.disabled = true;
 

    if (d.items != undefined)
    for (var u of d.items) {

      this.updatetree_temp(u);

    }

  }




  updatetree_temp2(d: Menrow) {

    d.disabled = false;


    if (

      d.id == '1_1' ||
      d.id == '1_2' ||
      d.id == '2' ||
      d.id == '4' 

    )
      d.disabled = true;


    if (d.items != undefined)
      for (var u of d.items) {

        this.updatetree_temp2(u);

      }

  }




  clickmenubutton() {

    if (this.navVaziat == 31) {
      this.refleshnavvaziat(10);
    }
    else if (this.navVaziat == 10) {
      this.refleshnavvaziat(31);
    }
  }
  refleshnavvaziat(vaz : number) {

    this.navVaziat = vaz;

    //کامل باز و چسبیده
    if (vaz == 31) {

      this.visiblenenu = true;
      this.rightSide = 'oneppp';
      this.leftSide = 'twoppp';
    }

    //کامل بسته
  else if (vaz == 10) {
      this.visiblenenu = false;
      this.rightSide = 'oneppp onepppclose';
      this.leftSide = 'twoppp twopppclose';

    }
      

  }
  clickmenu(e) {


   // console.log(e);
    this.AddTab(e);

  }
  test() {

    
    console.log('test');

  }


  tabindex: number = -1;
 

  AddTab(comp: string) {
    event.stopPropagation();

    if (!this.authService.loggedIn()) {
      this.router.navigate(['/home']);
      this.alertify.message('کلید منقضی شده است. دوباره وارد شوید');
    }


    for (var _i = 0; _i < this.tabsarchive.length; _i++) { 
      var num = this.tabsarchive[_i];
     // console.log(num);
     
     //gju658756
      for (var _j = 0; _j < this.tabs.length; _j++) {
        if (this.tabs[_j].component == comp) {
          this.selected.setValue(_j);
         // this.selectidex = _j;
          this.tabindex = _j;
      
          return;
        }  
       
      }

      if (num.component == comp) {
        //this.tabindex = _i;
        //this.selectidex = _i;
        if (num.component == 'Sanadlist') {
          num.op_moruroption = { "HPSanadId": -1, "HPSabtId": -1, "HPOpenOnInit": false };
        }
        num.fchanged = false;
        this.tabs.push(num);

        let index = this.tabs.length - 1;
        this.tabindex = index;
     
        this.selected.setValue(index);
        return;
      }


    


    }
   // this.selectidex = this.tabs.length - 1;
  //  this.selected.setValue(this.tabs.length - 1);
  //  this.tabGroup.selectedIndex = this.tabs.length - 1;
  }
  onTabClick(e) {
    ///56745h456uy546
    this.tabindex = e.index;

    //var t = this.tabs[0];
    //this.tabs[0] = this.tabs[2];
    // this.tabs[2] = t;

   // console.log(e);
  }

  closeTab(index: number) {

   // this.alertify.message(this.tabs[index].fchanged);
   // console.log(JSON.stringify(this.tabs[index]));
   // console.log(this.dddd);

   // console.log('ddd' + this.tabs[index].fchanged);
 
    if (this.tabs[index].fchanged == true) {

      this.alertify.confirmClosetab( () => {
        this.tabs.splice(index, 1);
      });

 
    }
    else {
      this.tabs.splice(index, 1);
    }

  //  event.stopPropagation();
  //  this.closedTabs.push(index);
  //  this.tabGroup.selectedIndex = this.tabNodes.length - 1;
  }
 
  onfchanged(obj: tabeventobj) {

  
    //console.log('jjjjj' + this.tabs[index].fchanged);
    if (obj != null)
      this.tabs[obj.index].fchanged = obj.value;

   // console.log('jjjjj2' + this.tabs[index].fchanged);
   // console.log('tabindex' + index.toString() +' changed to true');


  }
  OnRizGhardesh(option: MorurOption) {

  
   // console.log(JSON.stringify(option));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'Rizgardesh') {
   
        let temp = JSON.parse(JSON.stringify(this.tabsarchive[i]));
        temp.op_moruroption =  option;
    
        this.tabs.push(temp);
        let index = this.tabs.length - 1;
        this.tabindex = index;
      }
    }
 
    this.selected.setValue(this.tabs.length -1);

  }
  testt() {



    var t = this.tabs[0];
    this.tabs[0] = this.tabs[2];
     this.tabs[2] = t;


    //let y = this.tabs.length - 1;
    //console.log(this.tabs.length);
    //this.selected.setValue(y);


  }
  //dropped(event: CdkDragDrop<any>, t: MatTabGroup): void {
  //  const arr = t._tabs.toArray();
  //  moveItemInArray(arr, event.previousIndex, event.currentIndex);
  //  t._tabs.reset(arr);
  //}
  OnVmDarRep(e: VmDarRepOp) {


    // console.log(JSON.stringify(option));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'VmDarReplist') {

        let temp = JSON.parse(JSON.stringify(this.tabsarchive[i]));
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);

  }
  


  OnMyLoadSanad(e: any) {

  //  console.log(sanadId + 'eeeeeeeeeeeeeeeee');


    //sanadId: number, sabtId: number

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'Sanadlist') {

        let temp = this.tabsarchive[i];
        //e شامل اپشن زیر استe
      //  temp.op_moruroption = { "HPSanadId": e.HPSanadId, "HPSabtId": e.HPSanadId, "HPOpenOnInit": e.HPOpenOnInit };
        temp.op_moruroption = e;

        this.tabs.push(temp);

      }
    }
   
    this.selected.setValue(this.tabs.length - 1);
  }


  onShowPdlinkList(e: any) {
 //yhtru7568568 
    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'PdLinkList') {

        let temp = this.tabsarchive[i];
      
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }



  OnPrintMorurlist(data: any) {

   // console.log(JSON.stringify(data));

    let com = '';
    if (data.printKind == 1)
      com = 'MorurHt';
    else
      com = 'Morur2Ht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }

  OnPrintKardexlist(data: any) {

    // console.log(JSON.stringify(data));

    let com = '';
    if (data.printKind == 1)
      com = 'KardexHt';
    else
      com = 'Kardex2Ht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }
  OnPrintAnCountList(data: any) {

    // console.log(JSON.stringify(data));

    let com = '';
    if (data.printKind == 1)
      com = 'AnCountHt';
    else
      com = 'AnCount2Ht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }
  OnPrintTarazlist(data: any) {

    

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'TarazHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }

  OnPrintTarazlistDaftar(data: any) {



    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'daftarHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }



  onValueChangedmenue(e) {

    const val = e.value;
    if (val == null)
      return;

    for (var i = 0; i < this.menusourslist.length; i++) {

      if (this.menusourslist[i].id == val) {
        this.AddTab(this.menusourslist[i].component);

      }
    }

    

   

  }













  //salemalichang(e) {

  //  if (e.value == null)
  //    return;

  //  if (this.LocalstService.yearnow_get() != e.value) {

  //    this.saleMaliService.OnGlobalSalemaliChang(e.value);
  //    console.log('trig');

  //  }
 
  //  this.loadServ.showloading(this.loaddata, 2);
  //  this.saleMaliService.getSaleMaliBycodesal(e.value).subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 2);
  //    var salemali = data;
  //    this.LocalstService.yearnow_set(salemali);

  //  }, error => {
  //    this.timeourerror = true;
  //    console.log("my error: " + error)
  //  });
  //  this.tabs = [];

  //}



  onSanadprint(e) {

    //console.log('ghfghgf');
   // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'SanadRepHt') {

        let temp = this.tabsarchive[i];
        temp.op_moruroption = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);

  }
  onAnSellprint(e) {

    //console.log('ghfghgf');
    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'AnSellRepHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }
    //665gh45
    this.selected.setValue(this.tabs.length - 1);
 

  }
  onAnDeedprint(e) {

    //console.log('ghfghgf');
    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'AnDeedRepHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }
    //665gh45
    this.selected.setValue(this.tabs.length - 1);


  }
  OnMyprintRizghardesh(data) {

    let com = '';
    if (data.printKind == 1)
      com = 'RizgardeshHt';
    else
      com = 'Rizgardesh2Ht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);



  //  console.log("print rizghgardesh");
  }
 

  OnPrintRiuzghardesh(data) {

    let com = '';
    if (data.printKind == 1)
      com = 'ghardeshTafsiliht';
    else
      com = 'ghardeshTafsili2ht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data.model;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);



    //  console.log("print rizghgardesh");
  }


  OnPrintRankinglist(data) {

    // console.log(JSON.stringify(e));
    let co = 'Rankinght';
    if (data.isRiz)
      co = 'Rankingrizht';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == co) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }




  OnPrintDatdilmahiyatlist(data) {

    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'Tadilmahilatht') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);



    //  console.log("print rizghgardesh");
  }
  OnPrinthesablist(data) {

    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'hesabHtComponent') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);

 
  }
  OnPrintDafKollist(data) {

    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'DafKolHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);


  }
  OnPrintDafKolAlllist(data) {

    // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'DafKolAllHt') {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);


  }
  onReloadpage() {


    window.location.reload();

  }

  Paziresh_just(years :number) {

    console.log('paziresh refresh going');
    this.loadServ.showloading(this.loaddata, 6);
    this.generalService.Paziresh_reflesh(years).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.message( "اسناد پذیرش باموفقیت بروزرسانی شد");
      console.log('paziresh refresh done:');
      console.log(data);



    }, error => {
      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 6);
    });


  }
  onOpenSanadListKardex(e) {

           //kind نوع صفحه
        //anDeedId ای دی سند
        //AnDeedRowId ردیف فکوس
    //hyiytui76ytyy
    console.log(e);
    let comp = 'AnDeedList' + e.kind.toString();

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == comp) {

        let temp = JSON.parse( JSON.stringify( this.tabsarchive[i]));
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);

  }

  treeViewonItemClick(e) {
    var n = e.itemData;
    //56547fgu6
   // n.op_data = null;
    if (n.ismen) {
      this.clickmenu(n.component);
    }
  }



  onOpenKardex(e) {

    //dfrtg45ytrh457
    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'KardexList_Rial') {

        let temp = JSON.parse(JSON.stringify(this.tabsarchive[i]));
        temp.op_data = e;

        this.tabs.push(temp);
      }
    }

    this.selected.setValue(this.tabs.length - 1);


  }

  OnPrintPdPayrec(data: any) {

   
    let com = 'PdPayrecResidPrintHt';
 

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }
  OnPrintvmTajReplist(data: any) {

    // console.log(JSON.stringify(data));

    let com = 'vmTajRepHt';
    //if (data.printKind == 1)
    //  com = 'vmTajRepHt1';
    //else
    //  com = 'vmTajRepHt2';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }
  OnPrintvmDarReplist(data: any) {

    // console.log(JSON.stringify(data));

    let com = 'vmDarRepHt';
    //if (data.printKind == 1)
    //  com = 'vmTajRepHt1';
    //else
    //  com = 'vmTajRepHt2';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }
  OnPrintVmReplist(data: any) {

    // console.log(JSON.stringify(data));

    let com = 'vmRepVamHt';
    //if (data.printKind == 1)
    //  com = 'vmTajRepHt1';
    //else
    //  com = 'vmTajRepHt2';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }

  OnPrintVmMahReplist(data: any) {

    // console.log(JSON.stringify(data));

    let com = 'vmMahRepHt';
    //if (data.printKind == 1)
    //  com = 'vmTajRepHt1';
    //else
    //  com = 'vmTajRepHt2';

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == com) {

        let temp = this.tabsarchive[i];
        temp.op_data = data;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);
  }

  handleOptionChanged(e) {
    if (e.fullName === "searchValue") {
      var t = (e.component as dxTreeView);

      if ((e.previousValue === "" || e.value === null) && e.value !== "") {
      //make note of expanded keys before beginning search
        this.menutemp = JSON.parse(JSON.stringify(this.accService.menuRows));
       }
      if ((e.value === "" || e.value === null)) {

      
       
        this.accService.menuRows = JSON.parse(JSON.stringify(this.menutemp));   

      }
     
    }
   
  }
 // expandedRowKeys = [];





//handleOptionChanged (e) {
//  if (e.fullName === "searchPanel.text") {
//    if (e.previousValue === "" && e.value !== "") {
//      //make note of expanded keys before beginning search
//      this.expandedRowKeys = e.component.option('expandedRowKeys');
//    }
//    if (e.previousValue !== "" && e.value === "") {
//      //when search is cleared signal the restore
//      e.component.__searchRestoreRequested = true;

//      e.component.option('expandedRowKeys', this.expandedRowKeys);

//    }
//  }
//}

//handleOnContentReady(e) {
//  if (e.component.__searchRestoreRequested) {
//    e.component.__searchRestoreRequested = false;
//    e.component.option('expandedRowKeys', this.expandedRowKeys);
//  }
//}



}

