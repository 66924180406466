import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnPrcBas, AnPrcBasOp } from '../../../_models/app2/glob/AnPrcBas';
 
 
let EmptyAnPrcBas: AnPrcBas = {

 
  id: 0,
  kalaId: null,
  years: null,
  isError: null,
  

  errorT: null,
  dateDone: 0,
  dateDone_S: null,
  dateBas: 0,
  dateBas_S: null,
  _Code: null,
  _Name: null,
  _VahedId: null,
  _VahedN: null,
  _TghId: null,
  _TghN: null,
  subs: [],

};

let EmptyAnPrcBasOp: AnPrcBasOp = {
  years: 0,
  dateBas: 0,
  dateBas_S: null,
  index: 0,
  kalaId: 0

};

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnPrcBasService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getallByKind(kind: number , years : number): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnPrcBas/getall?years=' + years.toString() ;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
  getAnPrcBasEmpty(): AnPrcBas {

 
    return JSON.parse(JSON.stringify(EmptyAnPrcBas));

  }
  getAnPrcBasOpEmpty(): AnPrcBasOp {


    return JSON.parse(JSON.stringify(EmptyAnPrcBasOp));

  }

  calcPrice(item: AnPrcBasOp) {
    let apiadress = '/api/App2/Glob/AnPrcBas/calcPrice';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

  validDate(item: any) {
    let apiadress = '/api/App2/Glob/AnPrcBas/validDate';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

  AnPrcBasAsExcel(years: number): Observable<Blob> {
 
    let apiadress = '/api/App2/Glob/AnPrcBas/AnPrcBasAsExcel?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<Blob>(this.origin + apiadress, { 'headers': headers, responseType: 'blob' as 'json' });

  }

  getlockedkalaId(years: number, dateA): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnPrcBas/getlockedkalaId?years=' + years.toString() + '&dateA=' + dateA;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  
  lastValidPrcDate(years: number, anbarId : number): Observable<ApiRet> {
    let apiadress = '/api/App2/Glob/AnPrcBas/lastValidPrcDate?years=' + years.toString() + '&anbarId=' + anbarId.toString;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
}
