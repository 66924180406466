import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
 
import { FormGroup, FormBuilder, Validator } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxComponent, DxTextBoxModule, DxDataGridComponent, DxTextAreaComponent, DxNumberBoxComponent, DxListComponent, DxLookupComponent, DxValidatorComponent} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
 
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
 

  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
 
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
 
import { MyTimeService } from '../../../../_services/myTime.service';
 
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { SaleMali } from '../../../../_models/General/saleMali';
import { GSharh } from '../../../../_models/General/GSharh';
import { GSharhService } from '../../../../_services/General/GSharh.service';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { LoadingService } from '../../../../_services/loading.service';
import { TafsiliGbydaste } from '../../../../_models/app1/paye/tafsiliGbydaste';
 
import { ExcelService } from '../../../../_services/excel.service';
 import DataSource from "devextreme/data/data_source";
 
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import dxValidator from 'devextreme/ui/validator';
import { KalaForCombo } from '../../../../_models/app2/paye/Kala';
import { AnDeed, AnDeedRow } from '../../../../_models/app2/opr/AnDeed';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
 
const sendRequest = function (value) {
  const validEmail = 'test@dx-email.com';
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value === validEmail);
    }, 1000);
  });
};

 
@Component({
  selector: 'app2-DarKhast-edit',
  templateUrl: './DarKhast-edit.component.html',
  styleUrls: ['./DarKhast-edit.component.css']

})
export class DarKhastEditComponent implements OnInit {
  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) { 
    this.width = width;
  }
  lastrowidtemplate: number;
  @Input() fchanged: boolean;
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() focusedDarKhRowId: number; 
  debounceTime = 500;
  focusedRowKey: number;
 
  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMySaveEvent = new EventEmitter<any>();
  @Output() OnMyRefleshEvent = new EventEmitter<boolean>();
  @Output() onfchanged: EventEmitter<any> = new EventEmitter<any>();
  isfchanged: boolean;
  //اگر -1 بود حالت دستی
  nextCode: number=0;
  isAutoCode: boolean=true;
  reloadMainList: boolean=false;
  kindpage = -11;

  @Input() viewfreze: boolean;
  //
 // printoption: DarKhastRepOption;

  @Output() OnMyPrintEvent = new EventEmitter<number>();

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("editColumnkalaC", { static: false }) editColumnkalaC: DxSelectBoxComponent;
  @ViewChild("editColumnTaraf", { static: false }) editColumnTaraf: DxSelectBoxComponent;
  @ViewChild("editColumnvahed", { static: false }) editColumnvahed: DxSelectBoxComponent;
 

  @ViewChild("distempedit", { static: false }) distempedit: DxTextAreaComponent;
  @ViewChild("distempedit2", { static: false }) distempedit2: DxLookupComponent;


  @ViewChild("discolumeditedit", { static: false }) discolumeditedit: DxiColumnComponent;

  @ViewChild("listsharh", { static: false }) listsharh: DxListComponent;
  @ViewChild("ttt", { static: false }) ttt: DxTextBoxComponent;

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;
 
  @ViewChild("valllA", { static: false }) valllA: DxValidatorComponent;
  @ViewChild("valllB", { static: false }) valllB: DxValidatorComponent;
  @ViewChild("valllC", { static: false }) valllC: DxValidatorComponent;
  @ViewChild('testForm', { static: true }) testFormElement;
  @ViewChild("numcodevalid", { static: false }) numcodevalid: DxValidatorComponent;

  bedhaveadad: boolean;
  focusedColumnIndex: number;
 
 
  ontopselector: string = 'ontopselector';


  mymodle: AnDeed;
  mymodle_old: AnDeed;
 
 
  sho: false;
  somest: string;
  someint: number;
  onvandata: IdName[] = [];

 
  test: string;
  n: number;
  selectedrow: AnDeedRow;
  selectedrowOld: AnDeedRow;
  firstShitedRowid: number;

  usernow: User;
  yearnow: number;
  gsharhlist: GSharh[] = [];
  gsharhSelected: GSharh ;

  valdata: DialogData;
  selecteddisindext: number;
  messagetemp: DialogData;
  
 
 
 
 
  tafjustdaste: TafsiliGbydaste[] = [];
  candatechang: boolean;
  disableghat: boolean;
 
 
  SaleMaliselected: SaleMali;
  ColDisSelector: number ;
 
 
  loaddata: loadingCl;
  isreadonly = true;
  //yadashtandnew: boolean;
  moieninchang: boolean;

  firsttimeopen: boolean;
  // رنگ وضعیت 
  vazcolor: string;

  kindlist: any[]= [];
  tafDarlist: any = {};
  MoAlistData: any = [];

   MarkazAndProjData: any = [];
  shazhsSherkatData: any = [];

  tafMolist: any = {};
  kalaCombolist: any = {};

  constructor(private userService: UserService, private alertify: AlertifyService,
    private router: Router,
    private anDeedService: AnDeedService,
 
    private kalaService: KalaService,
 
    private hesabService: HesabService,
    private gSharhService: GSharhService,
    private myTimeService: MyTimeService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private loadServ: LoadingService,
    private localst: Localst,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private TafsiliService: TafsiliService,
    private httpClient: HttpClient) {
    this.candatechang = false;
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);

   
   
  
    this.vazcolor = 'vaz-none';
    this.disableghat = true;
 

    this.isreadonly = false;

 
    this.ColDisSelector = 1;
    this.SaleMaliselected = localst.yearnow_Class_get();
    this.gsharhSelected = gSharhService.getGSharhEmpty();
 
    this.moieninchang = false;
    this.selectedrowOld = null;
    this.firstShitedRowid = null;
    this.firsttimeopen = true;
 
    //localst.optioncli.ui_DarKhast_taf6_width;
   // this.yadashtandnew = false;
    this.kindlist = this.anDeedService.getkindList().filter(x => x.atfKindid ==-11);
    this.asyncValidation = this.asyncValidation.bind(this);

  }
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);
    if (event.altKey==true) {
      if (event.keyCode == 83) {

        //if (event.shiftKey == true)
        //  this.addthis('DarKhast');
        //else
        //  this.yaddasht('yaddasht');
      }
      //n
     if (event.keyCode == 78) {
        this.ctrNthis();
        //this.ttt.instance.focus();
      }
    }

  }

 
  Testing() {

    var r = $("#disdiv");
    console.log(r);
    r.find('.dx-texteditor-input').focus();
    console.log(r.find('dx-texteditor-input'));


  }
  myloclset() {

 //   this.localst.yearnow_set(0);

  }


  mychek() {

    console.log(this.mymodle);

  }

  testb() {


    console.log('testb');

    // this.mymodle.rows[1].vaziatN = 'hhh';
    //var newrowDarKhRow = this.darKhRowService.getDarKhRowEmpty();



    //// Well don't push to nonexistent array maybe? You can explicitly check if it's not null and create one if needed:
    //this.mymodle.rows = this.mymodle.rows || [];
    //  newrowDarKhRow.vaziatN = 'mnbmbnnbm';
    //this.mymodle.rows.push(newrowDarKhRow);


  }
  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;

  }
  ngOnInit() {

   

    this.reloadMainList = false;
    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.myoninit();
    this.refleshhesabvatafsili();


    this.isAutoCode = this.localst.GetRegistryByPath('App2-AnDeed-[Auto' + this.kindpage.toString() + ']').vBool;

    if (this.viewfreze) {


    //  this.dataGrid.editing.allowUpdating = false;
    //  this.dataGrid.editing.allowDeleting = false;
   //   this.dataGrid.editing.allowAdding = false;
      this.isreadonly = true;
    }

   // this.dataGrid.focusedRowIndex = 0;
   // this.dataGrid.selectedRowKeys = this.dataGrid.dataSource[0].id;

   // this.selectedrow = this.dataGrid.dataSource[0];
  
 
  }

  refleshhesabvatafsili() {

    this.loadServ.showloading(this.loaddata ,0);
    this.gSharhService.getGSharhs().subscribe(data => {
      this.loadServ.hideloading(this.loaddata,0);
      this.gsharhlist = data;
      // this.listsharh.selectedItemKeys = [6];

    }, error => {
        this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  
    this.loadServ.showloading(this.loaddata, 9);
    this.TafsiliService.GetTafsiliForSmall().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 9);
     
      this.tafDarlist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });
    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    //this.loadServ.showloading(this.loaddata, 10);
    //this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId, 'andeed').subscribe(data => {
    //  this.loadServ.hideloading(this.loaddata, 10);

    //  this.shazhsSherkatData = data;
    //  this.selectkind(this.mymodle.opA);
   
    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 10);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error);
    //});

    this.loadServ.showloading(this.loaddata, 10);
    this.TafsiliService.GetTafsiliForSmallByAnFormCode(this.kindpage, true, this.editId, 'andeed').subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 10);

     // this.shazhsSherkatData = data;

      this.tafMolist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });


     // this.selectkind(this.mymodle.opA);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




    this.loadServ.showloading(this.loaddata, 12);
    this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1003, -1004, true, this.editId, 'andeed').subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 12);

      this.MarkazAndProjData = data;

     // this.selectkind(this.mymodle.opA);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 12);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  


    
    this.loadServ.showloading(this.loaddata,7);
    this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'andeed').subscribe(res => {
      this.loadServ.hideloading(this.loaddata,7);
      this.tafjustdaste = res.data;

      console.log('down' + this.tafjustdaste.length);
    }, error => {
        this.loadServ.hideloading(this.loaddata,7);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

   
  }

  //در حالت ویرایش بعد از لود لیست کالا و اقلام ورود خروج . لیست واحد های هر ردیف را باید بارگذاری کنیم
  //تا اگر روی لیست کالا های هر ردیف کلیک کردیم لیست خالی نباشد
  filvahedlis() {

    if (this.isnew)
      return;

    if (this.kalaCombolist._store == undefined)
      return;

    if (this.kalaCombolist._store._array.length == 0 ||
      this.mymodle.rows.length == 0)
      return;


    for (var r of this.mymodle.rows) {

      for (var j of this.kalaCombolist._store._array) {


        if (r.kalaId == j.id) {


          r.vs = j.vs;

          break;
        }

      }
    }


  }


  myoninit() {

    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.candatechang = true;
    //////////////////////////////constractor///////////////////////////
    this.validationCallback = this.validationCallback.bind(this);
    this.mymodle = this.anDeedService.getAnDeedEmpty(-11);
    this.mymodle.rows = [];
    
 
    this.enc = 0;


    this.focusedRowKey = -1;


    this.lastrowidtemplate = -2000100000;
    this.lastrowidtemplate--;
    this.selectedrow = this.anDeedService.getAnDeedRowEmpty();
    this.usernow = this.localst.usernow_get();
    this.yearnow = this.localst.yearnow_get();

  
     

    this.bedhaveadad = false;
    this.focusedColumnIndex = 2;

 
    if (this.editId == -1) {
      console.log('new mode');
      this.mymodle = this.anDeedService.getAnDeedEmpty(-11);
   
      this.mymodle.dateA_S = this.localst.dateEmruz.dateString;
      this.mymodle.dateD_S = this.localst.dateEmruz.dateString;
      this.mymodle.dateZ_S = this.localst.dateEmruz.dateString;
      this.mymodle.uSa = this.localst.usernow_get().username;
      this.mymodle.uSaF = this.localst.usernow_get().name;
      this.refleshDarKhastcods(true);
      this.pushnewrow(true);
      this.isnew = true;
      this.setCode();
 
    }
    else {
      this.isnew = false;
      this.loadServ.showloading(this.loaddata,3);
      this.anDeedService.getbyid(this.editId).subscribe(
   
        (data: AnDeed) => {
          this.loadServ.hideloading(this.loaddata,3);
          
       
           
          this.mymodle = data;
          this.mymodle.uSaE = this.localst.usernow_get().username;
          this.mymodle.uSaEF = this.localst.usernow_get().name;

          this.mymodle_old = JSON.parse(JSON.stringify(this.mymodle));
     

          for (var _i = 0; _i < this.mymodle.rows.length; _i++) {

 

            //رنگ وضعیت
            this.refreshvazColor(this.mymodle.vaz);

           
            
          }
          this.setCode();

           
         // this.inmyintilized = false;
          this.pushnewrow(true);

          if (this.focusedDarKhRowId != null && this.focusedDarKhRowId != -1) {

            this.focusedRowKey = this.focusedDarKhRowId;

          }

        
      

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }


    this.refreshvazColor(this.mymodle.vaz);


  }

  //گرفتن نوع اتو بودن کد و اگر بود کد بعدی
  setCode() {


    this.loadServ.showloading(this.loaddata, 13);
    this.anDeedService.nextCode(this.localst.yearnow_get(),this.kindpage).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.nextCode = res.value;

   
        if(this.isnew)
        this.mymodle.code = this.nextCode;
      
        
    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });




  }



  refleshDarKhastcods(isnew: boolean) {

   //// return;
   // console.log('reflesh');
   // var g: DarKhast = JSON.parse(JSON.stringify(this.mymodle));
   // g.rows = null;
   // if (g.dateD_S != null) {
   //   this.loadServ.showloading(this.loaddata,4);
   //   this.anDeedService.getDarKhastEmpty().subscribe(data => {
   //     this.loadServ.hideloading(this.loaddata,4);
   //     var r = data as DarKhast;

   //     //    this.mymodle.cAtf = r.cAtf;
   //     //    this.mymodle.cFare = r.cFare;
   //     //   this.mymodle.cRozane = r.cRozane;
   //     //  this.mymodle.cShomare = r.cShomare;

   //     this.mymodle.cAtf = 0;
   //     this.mymodle.cFare = 0;
   //     this.mymodle.cRozane = 0;
   //     this.mymodle.cShomare = 0;

   //     this.mymodle.date = r.date;


   //     this.mymodle.date_String = r.date_String;

   //   //  this.ttt.instance.focus(); 
   //  //   console.log(JSON.stringify(this.mymodle));
   //   }, error => {
   //       this.loadServ.hideloading(this.loaddata,4);
   //     this.alertify.error(error);
   //     console.log("myerror:" + error)
   //   });

   //  }

  }
  calculateCellValue(data) {
    return [data.kalaId, data.hesabName].join(" ");
  }
  calledsubmitvaziat;

  
 
  addthis(condition: string) {
 
  
    if (this.loaddata.showed)
      return;
    this.calledsubmitvaziat = this.anDeedService.getvaz(200);

    this.onFormSubmit(condition);

  }
  
  cancelthis(e) {

 
    this.onfchanged.emit(false);
    this.OnMyCancelEvent.emit(true);
    this.OnMySaveEvent.emit({ reloadlist: this.reloadMainList , closepage: false });

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
   
        this.cancelthis(e);
      });
      return;
    }
 
 

  }
  //onFormSubmit = function (e) {

  onFormSubmit(condition : string) {

  

  //  this.testFormElement.nativeElement.submit();
  ////gfg66867867
   // this.testFormElement.nativeElement.submit();
    //برای رفلش مانده
    this.pushnewrow(false);

 
    this.repairlostKalaId();

    


    if (this.myvalidationchek(condition) == false) return;
   


    this.refreshvazColor(this.calledsubmitvaziat.id);
 
 
    if (this.mymodle.id == -1) {
      this.mymodle.years = this.localst.yearnow_get();
      this.mymodle.anbarId = -1;
        this.loadServ.showloading(this.loaddata,6);
        this.anDeedService.addDarkhast(this.mymodle).subscribe(data => {
        this.loadServ.hideloading(this.loaddata,6);
   
        this.alertify.success('مشخصات با موفقت ذخیره شد');

        //ای دی  ذخیره شده را میگیریم
          if (data.errors.length > 0) {

            this.alertify.showListString(data.errors);
            return;
          }
          else {
            this.alertify.success(data.success[0]);
          }

          this.reloadMainList = true;

          this.editId = data.data;
           this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });

        
       
          if (condition == 'addAndNew') {
            this.ctrNthis(); 
             
          } 
          else { 
             
            this.myoninit(); 
          } 
        
           
           

      }, error => {
          this.loadServ.hideloading(this.loaddata,6);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,8);
      this.anDeedService.updateDarkhast(this.mymodle,true).subscribe(next => {
      
        this.loadServ.hideloading(this.loaddata,8);
  
        if (next.errors.length > 0) {

          this.alertify.showListString(next.errors);
          return;
        }
        else {
          this.alertify.success(next.success[0]);
        }

        this.reloadMainList = true;
        //  ؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟
        this.OnMyRefleshEvent.emit(true);
        this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });

  
        if (condition == 'DarKhastAndNew') {
          this.ctrNthis(); 
      
        }
        if (condition == 'yaddashtAndNew') {
          this.ctrNthis();
    
        }
      }, error => {
          this.loadServ.hideloading(this.loaddata,8);
        this.alertify.error(error);
          console.log("myerror:" + error);
      });

    }

    this.isfchanged = false;
    this.onfchanged.emit(false);
    //  e.preventDefault();
  }








  onValueChanged(e) {
    //hgfhgffghtdtfr6
   // console.log(e);
    //وقتی نال اولین بارز که صفحه باز شده چون در حالت ویراش 
    if (e.previousValue != null) {

      this.mymodle.taf4Id = null;
      this.mymodle.taf4N = null;
    }
    this.selectkind(e.value);
  
    this.loadServ.showloading(this.loaddata, 11);
  //  this.kalaService.getallForCombo(-1, this.kindpage).subscribe(data => {
    this.kalaService.getallForCombo(-1, e.value, 'andeed!' + this.editId.toString()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.kalaCombolist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });
      this.filvahedlis();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  } 
  selectkind(kind) {

//    id: -102,
//      name: 'مصرف انبار',
//}, {
//  id: -103,
//    name: 'ارسال امانی ما نزد دیگران',
//}, {
//  id: -107,
//    name: 'تحویل فروش ',
//}, {
//  id: -109,
//    name: 'حواله تحویل دارایی های ثابت',
//  }]

    //if (kind == -102 || kind == -109) {

    //  this.tafMolist = new DataSource({
    //    store: this.MarkazAndProjData,
    //    paginate: true,
    //    pageSize: 50
    //  });
    //}
    //else if (kind == -103 || kind == -107) {

    //  this.tafMolist = new DataSource({
    //    store: this.shazhsSherkatData,
    //    paginate: true,
    //    pageSize: 50
    //  });

    //}
  }
  onValueChangedtarafmo(e) {

    //hgkghkhjhhh566
    e.data;
     
    for (var k of this.tafMolist._store._array) {

      if (k.id == e.value) {

        this.mymodle.taf4N = k.name;
        break;

      }

    }


 
    for (var r of this.mymodle.rows) {

      if (r.kalaId != null && r.taf4Id == null) {

       
        r.taf4Id = this.mymodle.taf4Id;
        r.taf4N = this.mymodle.taf4N;

      }

    }

  }

  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;


  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {

    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);


    //  this.anDeedService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");

    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });

    //console.log('sec ' + ccode);


  }
  // public  validationCallback(e: any): boolean {
  ctrNthis() {

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.ctrNthis();
      });
      return;
    }
    //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم
    
    if (this.loaddata.showed)
      return;

    this.editId = -1; 
    this.myoninit();
    this.alertify.message('سند جدید');
    this.ttt.instance.focus();
  }

  validationCallback(e) {
    return true

   

  }


  validateNumber(e) {



  }
 
  dateDarKhRow(e) {
     
    if (this.candatechang)
      return;



   

    const previousValue = e.previousValue;
    const newValue = e.value;
 
 let valid=   this.myTimeService.isvalidday(newValue);






    //if(this.isnew)
    //this.refleshDarKhastcods(false);
     
 



  }
  datechanged() {


    this.numcodevalid.instance.validate();
  }
  onFocusIn(e) {

 

  }
  onFocusIn2(e) {
  
    e.element.querySelector("input.dx-texteditor-input").select();  
  //  e.select();
  //  e.instance.select();
    //reset hide
    this.candatechang = false;

  

  }
  onFocusOut(e) {

 

  }

  onInitialized(e) {
    //   e.component.focus();  
    //  this.inputName.instance.focus();
    //  $("#ttt").focus();  


    // console.log(e.element);
    // console.log(e.instance.focus());

  }

  focusMethod = function getFocus() {
    document.getElementById("myTextField").focus();
  }


  onFocusRow(e) {




    //for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
    //  this.mymodle.rows[_i]._rowStyle = 'csnone';
    //  if (this.mymodle.rows[_i].id == e) {
    //    this.mymodle.rows[_i]._rowStyle = 'csfocus';
    //  }


    //}


  }
  deleteDone(e) {

  }










  ///////////////////////////////////table function ----------------------

 
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    for (var _i = 0; _i < this.mymodle.rows.length; _i++) {


      if (this.mymodle.rows[_i].id == f.id) {
         
        //اگر اویل بار اولین ردیف را کلیک کردیم
        if (this.selectedrowOld == null)
          this.selectedrowOld = JSON.parse(JSON.stringify(this.mymodle.rows[_i]));
        else
          this.selectedrowOld = JSON.parse(JSON.stringify(this.selectedrow));

        this.selectedrow = this.mymodle.rows[_i];
      }
       


    }





    this.focusedRowKey = this.selectedrow.id;



    this.pushnewrow(true);


    if (e.row.data.kalaId == null) {
      this.dataGrid.instance.editRow(e.row.rowIndex);


    }
    // else this.dataGrid.instance.cancelEditData();

    this.tafg_reflesh(this.selectedrow.kalaId);

  }

  pushnewrow(focusnwerow: boolean) {

    this.mymodle.rows = this.mymodle.rows || [];

    if (this.mymodle.rows.length == 0) {
      let a = this.anDeedService.getAnDeedRowEmpty();
      //ای دی منفی میدهیم تا جدول قاطی نکنه
      //منفی نشاندهنده جدید بودن و نبود در دیتا بیس است
      a.id = this.lastrowidtemplate;
      this.mymodle.rows.push(a);
      this.refreshradifnumber();
 
      return;
    }


    let existnewrow = true;
    let radif = 1;



    if (this.mymodle.rows != null) {
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
        radif = radif + 1;

        if (this.mymodle.rows[_i].kalaId == null) {

          existnewrow = false;
        }


      }
    }




    if (existnewrow) {

      var r = this.anDeedService.getAnDeedRowEmpty();
      this.lastrowidtemplate--;
      r.id = this.lastrowidtemplate;
      r.radif = radif;
      this.mymodle.rows.push(r);
    }
 

  }

  onRowUpdating(e) {

 


  }


  //tmptae edit
  onss(e, cellInfo) {


 
    cellInfo.setValue('123435');
  }

 
  temp_moienchanged(e, cellInfo) {

    if (e.value == undefined)
      return;


    //let IsKalaDupl = false;
    //for (var r of this.anbarlist) {

    //  if (this.mymodle.anbarId == r.id) {

    //    IsKalaDupl = r.isKalaDupl;
    //  }

    //}

    //if (!IsKalaDupl)
    for (var t of this.mymodle.rows) {

      if (t.kalaId == e.value && t.id != cellInfo.data.id) {
        this.alertify.message('کد کالا تکراری است');
        cellInfo.data.kalaId = null;
        cellInfo.data.kalaC = null;
        cellInfo.data.kalaN = null;
        return;
      }
    }

    this.isfchangcall();

   // this.kalaCombolist;
    cellInfo.data.kalaId = e.value;
    for (var r of this.kalaCombolist._store._array){

      if (r.id == e.value) { 

        var temp = r as KalaForCombo;
      
        cellInfo.data.kalaC = temp.c;
        cellInfo.data.kalaN = temp.n;
        cellInfo.data.vahId = temp.vs[0].id;
        cellInfo.data.vahN = temp.vs[0].n;
        cellInfo.data.vahBId = temp.vs[0].id;
        cellInfo.data.vahBN = temp.vs[0].n;
        cellInfo.data.zMulti = temp.vs[0].z;
        cellInfo.data.vs = temp.vs;
       
        break;
      }

    }

 

    if (this.mymodle.taf4Id > 0) {
      for (var r of this.tafMolist._store._array) {

        if (r.id == this.mymodle.taf4Id) {


          cellInfo.data.taf4Id = r.id;
          cellInfo.data.taf4N = r.name;


          break;
        }

      }
    }
  

  }


  temp_Tarafchanged(e, cellInfo) {

    if (e.value == undefined)
      return;


  

    for (var r of this.tafMolist._store._array) {

      if (r.id == e.value) {


        cellInfo.data.taf4Id = r.id;
        cellInfo.data.taf4N = r.name;
     
     
        break;
      }

    }




  }

  temp_vahedchanged(e, cellInfo) {

    if (e.value == undefined)
      return;

   
    for (var r of cellInfo.data.vs) {

      if (r.id == e.value) {
        cellInfo.data.vahBId = r.id;
        cellInfo.data.vahBN = r.n;
        cellInfo.data.zMulti = r.z;
        break;
      }
    }
     
    //for (var r of this.tafMolist._store._array) {

    //  if (r.id == e.value) {


    //    cellInfo.data.taf4Id = r.id;
    //    cellInfo.data.tafMo = r.name;


    //    break;
    //  }

    //}




  }
  first_moienintilized : boolean;

  temp_moienintilized(e, cellInfo) {
    console.log(this.first_moienintilized);
    //console.log( JSON.stringify(e.value));
    if (this.first_moienintilized == undefined) {
      this.first_moienintilized = true; 
      console.log(this.first_moienintilized);
    }

  }

  temp_Tarafintilized(e, cellInfo) {
   
  }

  ///???????????
  tafg_reflesh(kalaId: number) {
 
    if (kalaId == null) {
       
  

    }
    //?????????????????????????????? چرا
    //if (this.TafG.kalaId == null) {
    //  return;
    //}
    //if (this.TafG.kalaId == kalaId) {
    //  return;
    //}
 

    

    ////خواندن تفصیلی ها
    //for (var _i = 0; _i < this.TafGListallCount; _i++) {

    //  //پیدا کردن حسابی که انتخاب کردیم
    //  if (this.TafGListall[_i].kalaId == kalaId) {
    //    this.TafGListall[_i].taf4List = [];
    //    this.TafGListall[_i].taf5List = [];
    //    this.TafGListall[_i].taf6List = [];

         

    //    //خواندن لیست تمام تفصیلی های موجود درسته ان حساب
   
    //      for (var j = 0; j < this.tafjustdaste.length; j++) {
    //      //خواندن تفصیلی 4
    //     // if (this.TafGListall[_i].taf4List.length == 0)
    //      for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

    //        if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
    //          this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
    //        }

    //      }
    //      //خواندن تفصیلی   5
    //     // if (this.TafGListall[_i].taf5List.length == 0)
    //      for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

    //        if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
    //          this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
    //        }

    //      }
    //     //خواندن تفصیلی 6
    //     // if (this.TafGListall[_i].taf6List.length == 0)
    //      for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

    //        if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
    //          this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
    //        }

    //      }

        
    //    }
     



    //    this.TafG = this.TafGListall[_i];
    //    this.devDataTaf4 = new DataSource({
    //      store: this.TafG.taf4List,
    //      paginate: true,
    //      pageSize: 50
    //    });
    //    this.devDataTaf5 = new DataSource({
    //      store: this.TafG.taf5List,
    //      paginate: true,
    //      pageSize: 50
    //    });
    //    this.devDataTaf6 = new DataSource({
    //      store: this.TafG.taf6List,
    //      paginate: true,
    //      pageSize: 50
    //    });

    //    if (!this.TafG.have4)
    //      this.selectedrow.tafsili4Code = "-----";
    //    if (!this.TafG.have5)
    //      this.selectedrow.tafsili5Code = "-----";
    //    if (!this.TafG.have6)
    //      this.selectedrow.tafsili6Code = "-----";

    //    console.log('$TafG:' + this.TafG.kalaId.toString() + ' l4:' + this.TafG.have4 + ' l5:' + this.TafG.have5 + ' l6:' + this.TafG.have6);


    //    return;
    //  }

    //}


   


  }


  //////////////////////////////////////////////////////////////>



  onEditCanceled(e) {


    console.log('canceled....................................................');

  }

  logEvent(eventName) {
    
  }

  onSavedrow(eventName) {
 
  }
  onFocusInrowend(e) {

    // this.dataGrid.instance.saveEditData();






  }


  onValueChangedbed(e, cellInfo) {
    this.isfchangcall();

    cellInfo.data.amtB = e.value;
    


  }
 
  onValueChangedbes(e, cellInfo) {
    this.isfchangcall();
   // console.log('selectedrow is : ' + this.selectedrow.hesabCode);



    var newval = e.value;

    //this.selectedrow.bes = newval;
    //cellInfo.setValue(newval);

    //if (newval != null) {
    //  {
    //    if (newval < 0)
    //      newval = 0;
         

    //    this.selectedrow.bes = newval;
    //    cellInfo.setValue(newval);
    //    if (newval > 0) {

    //      this.selectedrow.bed = 0;
    //    }


    //  }


    //}


    //if (this.selectedrow.bes>0) {

    //  this.selectedrow.bed = 0;
    //}

  }


  onInputbed(e, cellInfo) {

 
  

  }
  onInputbes(e, cellInfo) {

    var newval = e.value;



  }

  onKeyDownGrid(e) {

  //  console.log('hhh: '+ e.event.shiftKey);
    this.mypresskey(e.event);

  }

 

  //searchedKalaid : number;
  //onSearchChangeMo(searchValue: string): void {
  //  this.searchedKalaid = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  // // console.log(thenum);
  //    //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
  //  if (thenum.length >2)
  //    for (var r = 0; r < this.kalaCombolist._store._array.length; r++) {
  //      if (this.kalaCombolist._store._array[r].c == thenum) {

  //     // console.log(JSON.stringify(this.moientempedit.items[r]));
  //      //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //     // this.moientempedit.value = this.moientempedit.items[r].id;
  //        this.searchedKalaid = this.kalaCombolist._store._array[r].id;
  //      break;
  //    }

  //  }
  //}


  //searchedtaraf: number;
  //onSearchChangetaraf(searchValue: string): void {
  //  this.searchedtaraf = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  //  // console.log(thenum);
  //  //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
  //  if (thenum.length > 2) 
  //    for (var r = 0; r < this.tafDarlist._store._array.length; r++) {
  //      if (this.tafDarlist._store._array[r].codeSum == thenum) {

  //        // console.log(JSON.stringify(this.moientempedit.items[r]));
  //        //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //        // this.moientempedit.value = this.moientempedit.items[r].id;
  //        this.searchedtaraf = this.tafDarlist._store._array[r].id;
  //        break;
  //      }

  //    }
  //}

  
  tw;
  onKeyDownmoien(e) {
    
  }
 
  onKeyDownTaraf(e) {
    //return;
    if (e.event.key == 'Insert') {

      this.editColumnTaraf.minSearchLength = 0;
    }
    //this.mypressMovekey(e.event);

  }

 
  onFocusInDis() {

    this.selecteddisindext = this.dataGrid.focusedRowIndex;
  }

  gDisRow_insert: number =-1;
  gDisColumn_insert: number = -1;;
 
  onKeyDowndis2(e) {


   
  }

  temp_Dischanged(e, cellInfo) {
    this.isfchangcall();
    const previousValue = e.previousValue;
    const newValue = e.value;
     
  //  this.seleggggfhgtrthyrthyctedrow.dis = newValue;
    cellInfo.setValue(newValue);
    cellInfo.data.dis = e.value;



    //const rowindex = this.dataGrid.focusedRowIndex;
    //const columnindex = this.dataGrid.focusedColumnIndex;

    //var navtocell = this.dataGrid.instance.getCellElement(rowindex, columnindex);
    ////  var cellolv = this.dataGrid.instance.cellValue(rowindex, columnindex);
    //// this.dataGrid.instance.cellValue(rowindex, columnindex, cellolv);
    //this.dataGrid.instance.editCell(rowindex, columnindex);
    //this.dataGrid.instance.focus(navtocell);
 

  }


  onClosedis2() {

    if (this.ColDisSelector === 2) {
      
      this.ColDisSelector = 1;
      this.discolumeditedit.editCellTemplate = 'editColumnDis';
    }
  }


  temp_DisonSelectionChanged2(e, cellInfo) {


    if (e.selectedItem == null)
      return;

    const newValue = e.selectedItem;
    if (newValue.text == null)
      return;
    //console.log(JSON.stringify(newValue));

    if (this.selectedrow.dis == null)
      this.selectedrow.dis = newValue.text;
    else
      this.selectedrow.dis += newValue.text;

    this.ColDisSelector = 1;



    const rowindex = this.dataGrid.focusedRowIndex;
    const columnindex = this.dataGrid.focusedColumnIndex;

    var navtocell = this.dataGrid.instance.getCellElement(rowindex, columnindex);
    //  var cellolv = this.dataGrid.instance.cellValue(rowindex, columnindex);
    // this.dataGrid.instance.cellValue(rowindex, columnindex, cellolv);
    this.dataGrid.instance.editCell(rowindex, columnindex);
    this.dataGrid.instance.focus(navtocell);
    this.discolumeditedit.editCellTemplate = 'editColumnDis';
  }

  onKeyDownmove(e) {

    this.mypressMovekey(e.event);
  }

  mypressMovekey(event: KeyboardEvent) {

    this.oldkey = event.key;

    //console.log(event.key);

    if (event.key == 'ArrowDown') {

      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;

      row++;
      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      // console.log('ArrowDown');

    }
    if (event.key == 'ArrowUp') {



      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;
      row--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
    }

    if (event.key == 'ArrowLeft') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 6)
        return;

      col++;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }
    if (event.key == 'ArrowRight') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 7)
        return;

      col--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }



  }


  mypresskey(event: KeyboardEvent) {

  
    this.oldkey = event.key;





    if (event.key == 'Enter') {
      this.pushnewrow(false);

      // console.log('***mypresskey***');

      //  this.dataGrid.instance.focus(navtocell);
      // this.dataGrid.instance.editCell(indexrow, indexcolum);

      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;


      //var nnn = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      //this.dataGrid.instance.focus(nnn);
      //this.dataGrid.instance.editCell(indexrow, indexcolum);


      if (indexcolum == 0) {

        indexcolum++;
      }

      //kala
      else if (indexcolum == 1) {

        //if (this.searchedKalaid != -1) {
        //  //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
        //  this.editColumnkalaC.value = this.searchedKalaid;
        //  this.selectedrow.kalaId = this.searchedKalaid;
      
        //  // this.alertify.message(this.searchedmoid.toString());
        //  //ست کردن نام گروه و ....
        //  this.setrowinfoBymoien(this.searchedKalaid);
        //  this.searchedKalaid = -1;

        //}
    
        //2/برگشت
        if (event.shiftKey) {

          if (indexrow > 0) {
            indexrow--;
            indexcolum = 6;
          }

        } 
        //2/رفت
        else {
       
          indexcolum =   3;
        
        }

      }

      //نام کالا
      else if (indexcolum == 2) {

      

        //2/برگشت
        if (event.shiftKey) {

          indexcolum = 1;

        }
        //2/رفت
        else {
            indexcolum=3;
        
        }
      }
      //تعداد
      else if (indexcolum == 3) {
      
        //2/برگشت
        if (event.shiftKey) {



          indexcolum=1;


        }
        //2/رفت
        else {
        

          indexcolum=4;
          
        }

      }
      //واحد
      else if (indexcolum == 4) {
       
        //2/برگشت
        if (event.shiftKey) {



          indexcolum=3;


        }
        //2/رفت
        else {

          indexcolum=6;

        }

      }
      //طرف مقابل
      else if (indexcolum == 5) {

        //2/برگشت
        if (event.shiftKey) {

          
      
            indexcolum = 3;
        
        
        }
        //2/رفت
        else {
          indexcolum=6;
        }

      }
      //شرح
      else if (indexcolum == 6) {


        //2/برگشت
        if (event.shiftKey) {

          indexcolum=3;
        }
        //2/رفت
        else {

          this.repairlostKalaId();
         

            if (this.selectedrow.kalaId == null) {
              indexcolum = 1;
            }
            else {
              var navtocell = this.dataGrid.instance.getCellElement(indexrow, 3);
              this.dataGrid.instance.focus(navtocell);
              indexrow++;
              indexcolum = 1;

            }


       



        }

 
      }
     
     // this.dataGrid.instance.saveEditData();
    //  this.dataGrid.instance.closeEditCell();
     // this.repairShitRow();
     

      this.xx = indexcolum;
      this.yy = indexrow;
      var navtocell = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      this.enc++;



      this.dataGrid.instance.focus(navtocell);
      var cellolv = this.dataGrid.instance.cellValue(indexrow, indexcolum);
   
      this.dataGrid.instance.cellValue(indexrow, indexcolum, cellolv);
      this.dataGrid.instance.editCell(indexrow, indexcolum);
      this.dataGrid.instance.focus(navtocell);




      this.oldrowindex = indexrow;
      this.oldcolumindex = indexcolum;

     
      // this.dataGrid.onEditingStart
    }



  }





   setrowinfoBymoien(moid : number){

     var selected;
     //for (let i = 0; i < this.hesablist.length; i++) {
     //  if (this.hesablist[i].id == moid) {
     //    selected = this.hesablist[i];
     //    break;
     //    // console.log(this.hesablist[i].id)
     //  }

     //}
     if (selected != null) {



      //this.selectedrow.groupId = selected.groupId;
      // this.selectedrow.groupCode = selected.groupCode;
      // this.selectedrow.groupName = selected.groupName;


      // this.selectedrow.kolId = selected.kolId;
      // this.selectedrow.kolCode = selected.kolCode;
      // this.selectedrow.kolName = selected.kolName;


      // this.selectedrow.kalaId = selected.id;
      // this.selectedrow.hesabName = selected.name;
      // this.selectedrow.hesabCode = selected.codeSum;
    




     }
     else {

       //this.selectedrow.groupId = null;
       //this.selectedrow.groupCode = null;
       //this.selectedrow.groupName = null;


       //this.selectedrow.kolId = null;
       //this.selectedrow.kolCode = null;
       //this.selectedrow.kolName = null;

       //this.selectedrow.kalaId = null;
       //this.selectedrow.hesabName = null;
 

     }



}

 
  xx;
  yy;
  oldrowindex: number;
  oldkey: string;
  enc: number;
  oldcolumindex: number;
  testtt() {
    this.distempedit2.instance.open();

 

  }
  onFocusedCellChanged(e) {
   
   
  }
  onFocusedCellChanging(e) {

  
     
  }
  onEditingStart(e) {




  }
 

  adjustWidth(e) {

    e.component._popup.option('width', 400);
    if (this.firsttimeopen) {
 
   
         this.firsttimeopen = false;
   
    }
  }

  moisopen: number;
  onFocusmoienedit(e, item) {

 

    if (this.moisopen == null) this.moisopen = 0;

    this.editColumnkalaC.instance.open();
    this.moisopen++;
 
  }
 
  tarafopen: number;
  onFocusTarafedit(e, item) {


    if (this.editColumnTaraf.minSearchLength == 0)
      this.editColumnTaraf.minSearchLength = 2;

    if (this.moisopen == null) this.tarafopen = 0;

    this.editColumnTaraf.instance.open();
    this.tarafopen++;

  }
 
  onFocusVahededit(e, item) {

 
    this.editColumnvahed.instance.open();
 

  }

  onFocusDis2dit(e) {
  
    this.distempedit2.instance.open();
    //    e.instance.open();
  }

  testthis(e) {

    let dialogRef = this.dialog.open(DialogvalidComponent, {
      width: '250px',
      data: {}
    });

 
    dialogRef.afterClosed().subscribe(result => {

      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        console.log('dell');

      }

    });
 
  }

  onFocusInbes(e) {



  }


 
 




  onCellPrepared(e) {


 

  }




  myvalidationchek(condition: string): boolean {


   

    const verror = [];


    if (this.mymodle == null)
      verror.push('model is empty');
    else {

     

      if (!this.valllA.instance.validate().isValid) {
        verror.push('نوع در خواست مشخص نشده');
      
      }
    
      if (!this.valllB.instance.validate().isValid) {
        verror.push('طرف مقابل مشخص نشده');

      }
      if (!this.valllC.instance.validate().isValid) {
        verror.push('درخواست کننده خالی است');

      }
 

      if (this.mymodle.code == null)
        verror.push(' شماره درخواست خالی است');


      let datevalid = this.myTimeService.isvalidday(this.mymodle.dateA_S);

 
     
      if (datevalid != null) {
        
        verror.push(datevalid);
      }

      var years = this.localst.yearnow_Class_get();

 
      //var dajfouy = parseInt(this.mymodle.dateA_S.replace('/', '').replace('/', '').replace('/', '').toString()); 
 

      //if (dajfouy > years.dateE) {
      //  verror.push('تاریخ در محدوده سال مالی جاری نیست');
      //}

      //if (dajfouy < years.dateS) {
      //  verror.push('تاریخ در محدوده سال مالی جاری نیست');
      //}
 

      if (this.mymodle.vaz > 200) {


        verror.push('ابتدا سند را در وضعیت موقت قرار دهید');


      }



 
      let c = 0;
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {

        if (this.mymodle.rows[_i].kalaId == null || this.mymodle.rows[_i].kalaId == -1) {
          this.mymodle.rows.splice(_i, 1);
          continue;
        }
         


          c++;

 
       
     
      }


      if (c == 0) {

          verror.push('هیچ ردیفی ثبت نشده است');

      }






    }
   // verror.push('ارور');

    if (verror.length == 0)
      return true;

    



    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

  
 

    return false;;
  }
 



  onClickbarasi(vaziatnumber: number) {

    
    if (this.loaddata.showed)
      return;

    if (this.mymodle.id == -1)
      return;

    let allow = null;

    if (vaziatnumber == 3) {
      if (this.mymodle.vaz != 2 ) {

        allow = 'سند باید در وضعیت موقت قرار داشته باشد';
      

      }

    }
   else   if (vaziatnumber == 200) {


      if (this.mymodle.vaz !=300 ) {
        allow = 'سند باید در وضعیت تایید شده قرار داشته باشد';
     //   this.showmessage('سند باید در وضعیت بررسی شده قرار داشته باشد');

      }

    }

    if (allow != null) {

      this.showmessage(allow);
      return;

    }

    var n = [];

    n.push(this.mymodle.id);

    let vaziat = this.anDeedService.getvaz(vaziatnumber);

    if (vaziat == null)
      return;

  
    this.loadServ.showloading(this.loaddata,10);
    this.anDeedService.updateVaz(n,vaziat.id).subscribe((res:ApiRet) => {

      res;
      this.loadServ.hideloading(this.loaddata,10);
      
     

      this.refreshvazColor(this.mymodle.vaz);
      // this.loadDarKhast();

    }, error => {
        this.loadServ.hideloading(this.loaddata,10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
    //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  //{ id: 2, name: 'موقت' },
  //{ id: 3, name: 'بررسی شده' },
  //{ id: 4, name: 'قطعی شده' }
  //]

  onClickghati(vaziatnumber: number) {

 
    if (this.loaddata.showed)
      return;

    if (this.mymodle.id == -1)
      return;

    
    if (vaziatnumber == 500) {
      if (this.mymodle.vaz != 400) {

        this.showmessage('سند باید در وضعیت تایید شده قرار داشته باشد');
        return;
      } 

    }

    let mess = 'از قطعی شدن سند اطمینان دارید؟';

    this.valdata = { title: mess, rows: [' در صورت قطعی شدن امکان برگشت وجود ندارد'], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


   // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

 
    dialogRef.afterClosed().subscribe(result => {
    
      console.log('m:The dialog was closed');
      let dialogdata = result;

      if (dialogdata != null) {


       // console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.dodghativaziat(500);

        }
      }
    });




  }


  refreshvazColor(vaz: number) {



    //ثبت نشده
    if (vaz == -1) {

      this.vazcolor = 'vaz-none';
      //  this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;

    }

    //موقت
    else if (vaz == 200) {
      this.vazcolor = 'vaz-yellow';
      // this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;
    }
    //قیمت گذاری شده
    else if (vaz == 300) {
      this.vazcolor = 'vaz-orange';
      // this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;
    }
    //تایید
    else if (vaz == 400) {

      this.vazcolor = 'vaz-green';
      // this.mainwarperCSS = 'frzAnDeed opAnDeed';
      this.isreadonly = true;
    }
    //سند حسابداری
    else if (vaz == 500) {
      this.vazcolor = 'vaz-blue';
      // this.isreadonly = false;
    }


  }
 
  dodghativaziat(v : number) {

    var n = [];
    n.push(this.mymodle.id);
    let vaziat = this.anDeedService.getvaz(v);

    if (vaziat == null)
      return;

    this.loadServ.showloading(this.loaddata, 12);
    this.anDeedService.updateVaz(n, vaziat.id).subscribe((res: ApiRet) => {

       res;
       this.loadServ.hideloading(this.loaddata,12);
      this.alertify.success(' وضعیت سند به ' + vaziat.name + ' تغییر یافت ');
      this.mymodle.vaz = vaziat.id;
      this.mymodle._VazN = vaziat.name;
         //رنگ وضعیت
       this.refreshvazColor(this.mymodle.vaz);
      // this.loadDarKhast();

     }, error => {
         this.loadServ.hideloading(this.loaddata,12);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  showmessage(message: string) {


    this.messagetemp = { title: 'خطا', rows: [], valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
    this.messagetemp.rows = [message];
    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.messagetemp;
    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);
   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The message dialog was closed');
      return;
    });
    return;
 
  }

 
  onClickDeleterow() {
 
    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
   // console.log(JSON.stringify(rowkersselected));

    if (rowkersselected == null)
        rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);

    let afterindex = -1;
 

    if (rowkersselected != null)
    for (var i = 0; i < rowkersselected.length; i++) {

 
      for (var j = 0; j < this.mymodle.rows.length; j++) {

        if (rowkersselected[i] == this.mymodle.rows[j].id) {
          
          this.mymodle.rows.splice(j, 1);
          afterindex = j - 1;
          break;
        }
      }


    }

    if (afterindex < this.mymodle.rows.length && afterindex >= 0)
      this.focusedRowKey = this.mymodle.rows[afterindex].id;
    else if (this.mymodle.rows.length>0)
      this.focusedRowKey = this.mymodle.rows[0].id;

    this.refreshradifnumber();
    this.pushnewrow(false);

  }
  onClickinsertrow() {

    let DarKhRowempty = this.anDeedService.getAnDeedRowEmpty();
    this.lastrowidtemplate--;
    DarKhRowempty.id = this.lastrowidtemplate;
    
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {
    
        this.mymodle.rows.splice(i, 0, DarKhRowempty);
        break;
      }

    }

    this.refreshradifnumber();


  }


  onClickcopyrow() {

    let data =[];

   
    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.clipboard_set( 'DarKhRow' , data);
    this.alertify.warning('رونوشت صورت گرفت');
    this.dataGrid.instance.clearSelection();

  }
  onClickcutrow() {
    let data = [];


    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));
    this.localst.clipboard_set('DarKhRow', data);
    this.alertify.warning('برش صورت گرفت');
    this.onClickDeleterow();
    this.dataGrid.instance.clearSelection();

  }
  onClickpaserow() {
    var data = this.localst.clipboard_get('DarKhRow');
    if (data == null) {

      this.alertify.warning('رونوشتی یافت نشد');
      return;
    }
     

  
    let nextid = this.getnextrowid();

    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {

        //ردیف مورد نظر را پیدا میکنیم

        //ردیف های کپی شده را جایگزاری میکنیم

        for (var k = 0; k < data.length; k++) {
          this.lastrowidtemplate--;
          data[k].id = this.lastrowidtemplate;
          this.mymodle.rows.splice(i, 0, data[k]);


         }
        this.alertify.warning('ردیف جایگذاری شد');
        break;
      }

    }

    this.refreshradifnumber();
 

  }
  onClickexchangRow() {

    

  }
  getnextrowid() : number {

    let nextid = -2000000011;
    for (var j = 0; j < this.mymodle.rows.length; j++) {
      if (this.mymodle.rows[j].id > nextid) {
        nextid = this.mymodle.rows[j].id;
      }
    }
    nextid++;
    return nextid;
  }
  refreshradifnumber() {

   
    let c = 0;
    for (var k = 0; k < this.mymodle.rows.length; k++) {
      c++;
      this.mymodle.rows[k].radif = c;

    }

  }

  nextDarKhast() {
  

  }




  backDarKhast() {



  }
  onClickdeleteDarKhast() {

   

    if (this.loaddata.showed)
      return;

    if (this.editId == -1)
      return;


    if (this.mymodle.vaz > 200)
      return;


    this.openDialog();

  


  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

    

      }

    }); 
  }

  dodelete() {


 
 
    this.loadServ.showloading(this.loaddata,16);
    this.anDeedService.delete(this.editId, null).subscribe((data) => {

      this.loadServ.hideloading(this.loaddata,16);
     
      this.alertify.success('سند مورد نظر با موفقیت حذف شد');
       
               this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });

            this.ctrNthis(); 
    }, error => {
        this.loadServ.hideloading(this.loaddata,16);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 

  }








 

  onValueChangedGSharh(e) {

  }

  onKeyDownDate(e) {


    if (e.event.key == 'Enter') {


      var cellolv = this.dataGrid.instance.cellValue(0, 1);

 
      this.dataGrid.instance.cellValue(0, 1, cellolv);
      this.dataGrid.instance.editCell(0, 1);
      this.dataGrid.instance.focus(cellolv);

    }


  }
  onClickmoveRow(step: number) {


    //حرکت روبه بالا
    if (step > 0) {

      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر اولین سطر انتخاب شده بود هیچ کاری نمیکنیم
          if (i == 0)
            return


          var tempmove = this.mymodle.rows[i-1];

          this.mymodle.rows[i - 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
     
        }
      }


    }
     //حرکت روبه پایین
    else if (step < 0) {

      let stopdown = this.mymodle.rows.length - 2;
      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر ردیف اخر به بعد بود هیچ کاری نمیکنیم
          if (i > stopdown)
            return


          var tempmove = this.mymodle.rows[i + 1];

          this.mymodle.rows[i + 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
          
        }
      }

    }
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;

    }

  }

  onClicrepedrows() {


 

    var foc = this.dataGrid.focusedRowKey;


    if (foc == null)
      return;

 

   
       var foradd = null;
  

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == foc) {

          
            foradd = JSON.parse(JSON.stringify(this.mymodle.rows[j]));

            this.lastrowidtemplate--;
            foradd.id = this.lastrowidtemplate;
      
            break;
          }


        }
     


    if (foradd == null)
      return;

 


    if (this.mymodle.rows == null)
      this.mymodle.rows = [];



 




    let indexforinsert = 0;

    if (this.mymodle.rows.length > 0)
      indexforinsert = this.mymodle.rows.length - 1;


    this.mymodle.rows.splice(indexforinsert, 0, foradd);



    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;

    }



  }
  onClickPrint() {

   
    if (this.loaddata.showed)
      return;


    this.OnMyPrintEvent.emit(this.mymodle.id);
  

  }
  refreshbottum() {

  
    if (this.loaddata.showed)
      return;

    this.refleshhesabvatafsili();
  //  this.tafg_reflesh(this.selectedrow.kalaId);
   // this.selecttafg(this.selectedrow.kalaId);
  }


  repairShitRow() {

 
   //// return;


   // if (this.firstShitedRowid == null)
   //   return;

   // if (this.pv == -1)
   //   return;



   // console.log('<<<shit>>>' + this.firstShitedRowid);

   // for (var r = 0; r < this.mymodle.rows.length; r++) {
      
   //   if (this.mymodle.rows[r].id == this.firstShitedRowid) {


       

   //     let hitem = null;
   //     for (var h = 0; h < this.hesablist.length; h++) {

   //       if (this.hesablist[h].id == this.pv) {

   //         hitem = this.hesablist[h];
   //       }

   //     }

   //     if (hitem != null && hitem.codeSum != this.selectedrowOld.hesabCode) {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = hitem.codeSum;
   //       this.mymodle.rows[r].hesabName = hitem.name;


   //       this.mymodle.rows[r].groupId = null;
   //       this.mymodle.rows[r].groupCode = null;
   //       this.mymodle.rows[r].groupName = null;

   //       this.mymodle.rows[r].kolId = null;
   //       this.mymodle.rows[r].kolCode = null;
   //       this.mymodle.rows[r].kolName = null;


   //       this.mymodle.rows[r].tafsili4Id = null;
   //       this.mymodle.rows[r].tafsili4Code = null;
   //       this.mymodle.rows[r].tafsili4Name = null;

   //       this.mymodle.rows[r].tafsili5Id = null;
   //       this.mymodle.rows[r].tafsili5Code = null;
   //       this.mymodle.rows[r].tafsili5Name = null;

   //       this.mymodle.rows[r].tafsili6Id = null;
   //       this.mymodle.rows[r].tafsili6Code = null;
   //       this.mymodle.rows[r].tafsili6Name = null;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;
   //     }
   //     else {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = this.selectedrowOld.hesabCode;
   //       this.mymodle.rows[r].hesabName = this.selectedrowOld.hesabName;


   //       this.mymodle.rows[r].groupId = this.selectedrowOld.groupId;
   //       this.mymodle.rows[r].groupCode = this.selectedrowOld.groupCode;
   //       this.mymodle.rows[r].groupName = this.selectedrowOld.groupName;

   //       this.mymodle.rows[r].kolId = this.selectedrowOld.kolId;
   //       this.mymodle.rows[r].kolCode = this.selectedrowOld.kolCode;
   //       this.mymodle.rows[r].kolName = this.selectedrowOld.kolName;


   //       this.mymodle.rows[r].tafsili4Id = this.selectedrowOld.tafsili4Id;
   //       this.mymodle.rows[r].tafsili4Code = this.selectedrowOld.tafsili4Code;
   //       this.mymodle.rows[r].tafsili4Name = this.selectedrowOld.tafsili4Name;

   //       this.mymodle.rows[r].tafsili5Id = this.selectedrowOld.tafsili5Id;
   //       this.mymodle.rows[r].tafsili5Code = this.selectedrowOld.tafsili5Code;
   //       this.mymodle.rows[r].tafsili5Name = this.selectedrowOld.tafsili5Name;

   //       this.mymodle.rows[r].tafsili6Id = this.selectedrowOld.tafsili6Id;
   //       this.mymodle.rows[r].tafsili6Code = this.selectedrowOld.tafsili6Code;
   //       this.mymodle.rows[r].tafsili6Name = this.selectedrowOld.tafsili6Name;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;


   //     }

       

   //      }

   //   }
   

  }


  repairlostKalaId() {
    

    for (var k of this.mymodle.rows) {

      if (k.kalaC != null && k.kalaId == null) {

        for (var r of this.kalaCombolist._store._array) {

          if (r.c == k.kalaC) {

            k.kalaId = r.id;
            break;
          }

        }
      }
    }

  }

  lastindex = -1;
  
  

  isfchangcall() {
    if (!this.isfchanged) {
      this.isfchanged = true;
      this.onfchanged.emit(true);
    }
  }
  confirmfchang() {

  
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        return false;
      });
    }
    else return true
  }


 
  exportexcel() {

    //let excelmodle: DarKhRowExel[] =[] ;
   
    //for (var r = 0; r < this.mymodle.rows.length; r++) {

    //  let greeter = new DarKhRowExel(this.mymodle.rows[r]);


    //  excelmodle.push(greeter);



    //}


    //this.excelService.exportAsExcelFile(excelmodle, 'اسناد');


  }
  DarKhRowtemp: AnDeedRow;
  asyncValidation(params) {

    //return new Promise((resolve) => {
    //  setTimeout(() => {
    //    resolve(1 === 1);
    //  }, 1000);
    //});
   
    if (this.isAutoCode) {
       return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
    }

    return new Promise((resolve, reject) => {
      this.anDeedService.validationCode(params.value, this.editId, this.mymodle.dateA_S, this.kindpage, this.localst.yearnow_get())
        .then((res: ApiRet) => {

          if (res.errors.length > 0) {

            this.alertify.error(res.errors.join('\n'));
          }
       
         
          resolve(res.value);
        })
        .catch(error => {
          console.error("خطا ارتباط با سرور", error);
 
        });
    });

   
  }


  shiftsanad(s) {

    if (this.editId == -1) {

      this.alertify.warning('سندی وجود ندارد');
      return;
    }

    this.loadServ.showloading(this.loaddata, 14);
    this.anDeedService.getAnDeedbyshift(this.editId, s).subscribe((res: ApiRet) => {
      this.loadServ.hideloading(this.loaddata, 14);

      if (res.warnings.length > 0) {
        this.alertify.warning(res.warnings[0]);

      }

      if (res.value != null) {

        this.editId = res.value;
        //از نو بخونه بیخیال بار سرور
        this.myoninit();
      }


    }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 14);
        console.log(error);
        this.alertify.error(error);
      }


    );


  }


  saveuioption() {
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
    }
    this.localst.optioncli.gw2007 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }



}
