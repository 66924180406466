import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MyTimeService } from '../_services/myTime.service';
import { AlertifyService } from '../_services/alertify.service';

export class DialogData {

 title: string;
  rows: string[];
  valid: boolean;
  kind: string;
  selectbutton: string;
  datastring: string;
  datastringShow: boolean;
  styleMode: number;
 
}
 


@Component({
  selector: 'Dialogvalid',
  templateUrl: 'Dialogvalid.component.html',
  styleUrls: ['./Dialogvalid.component.css']
})
export class DialogvalidComponent {

  vdata: DialogData;

  styleMode: string='';

  constructor(
    public dialogRef: MatDialogRef<DialogvalidComponent>,
    private myTimeService: MyTimeService,
    private alertify: AlertifyService,
    @Inject(MAT_DIALOG_DATA) data: DialogData) {

    this.vdata = data;

 

  //  console.log(JSON.stringify(data));
    //if (this.data.rowss == null)
    //  this.data.rowss = [];


   // this.data.rowss.push('یک');
    this.vdata.selectbutton = 'none';

  }

  cancelClick(): void {
    this.dialogRef.close(this.vdata);
  }

  nocancel(): void {
    this.vdata.selectbutton = 'nocancel';
    this.dialogRef.close(this.vdata);
  }

  sanadcancel(): void {
    this.vdata.selectbutton = 'sanadcancel';
    this.dialogRef.close(this.vdata);
  }
  sanadAndNewcancel(): void {
    this.vdata.selectbutton = 'sanadAndNewcancel';
    this.dialogRef.close(this.vdata);
  }


  onYesClick(): void {


    //اگر تاریخ نمایش داده شده بود
    if (this.vdata.datastringShow) {

      let vald = this.myTimeService.isvalidday(this.vdata.datastring);
      if (vald != null) {
        this.alertify.error("تاریخ در محدوده سال مالی جاری نمیباشد");
        return;
      }
    }


    this.vdata.selectbutton = 'yesdone';
    this.dialogRef.close(this.vdata);
  

  }
  onyaddashtClick(): void {

    this.vdata.selectbutton = 'yaddasht';
    this.dialogRef.close(this.vdata);


  }
  onyaddashtAndNewClick(): void {

    this.vdata.selectbutton = 'yaddashtAndNew';
    this.dialogRef.close(this.vdata);


  }
}
