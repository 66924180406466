import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { LoadingService } from '../../../../_services/loading.service';
import { VmTas } from '../../../../_models/app98/opr/VmTas';
import { VmTasService } from '../../../../_services/app98/opr/VmTas.service';
import { VmGrs } from '../../../../_models/app98/paye/VmGrs';
import { VmGrsService } from '../../../../_services/app98/paye/VmGrs.service';
import DataSource from "devextreme/data/data_source";
import { Localst } from '../../../../_services/localst.service';
import * as FileSaver from 'file-saver';
import { ExcelService } from '../../../../_services/excel.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { VmTajRepService } from '../../../../_services/app98/rep/VmTajRep.service';
import { VmTajRepRow } from '../../../../_models/app98/rep/VmTajRep';

@Component({
  selector: 'app98-VmTas-edit',
  templateUrl: './VmTas-edit.component.html',
  styleUrls: ['./VmTas-edit.component.css']
})
export class VmTasEditComponent implements OnInit {



  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
 
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;
  myModel: VmTas;



  vmGrslist: VmGrs[] =[];
  tafMolist: any = {};
 

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private VmTasService: VmTasService,
    private vmGrsService: VmGrsService,
    private tafsiliService: TafsiliService,
    private excelService: ExcelService,
    private localst: Localst,
    private vmTajRepService : VmTajRepService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.VmTasService.getVmTasEmpty();
     
 
  }

  ngOnInit() {


    this.tafMolist = new DataSource({
      store: [],
      paginate: true,
      pageSize: 50
    });

    this.bindall();
    this.isnew = true;
 
    if (this.editId == -1) {
 
   
      this.myModel = this.VmTasService.getVmTasEmpty();

      this.myModel.dateA_S = this.localst.dateEmruz.dateString;
     

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.VmTasService.getbyid(this.editId).subscribe(
        (res) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = res.data;
   

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }
    bindall() {

      this.loadServ.showloading(this.loaddata, 0);
      this.tafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId, 'VmTas').subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.tafMolist = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

  

      this.loadServ.showloading(this.loaddata, 5);
      this.vmGrsService.getall(true).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.vmGrslist = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

    }
  refreshbottum() {

    this.bindall();

  }

  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

 
 
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.VmTasService.add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.VmTasService.update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

 
    return true


  }
 
 

  validateNumber(e) {

    

  }

  vmGrschanged(e) {
     


  }
  exportexcel() {


    let def = [{ TafsiliCode: 'text', CodeMelli: 'text', Name: '', Price: 0, Description:'' }];

    this.excelService.exportAsExcelFile(def , 'Daryafti');



     



  }
  onValueChangedPrc(e, cellInfo) {

    cellInfo.data.prc = e.value;

  }

  onTafchanged(e) {



    const taf =  e.value;

    if (taf == null) {
      return;
    }
 

    this.loadServ.showloading(this.loaddata, 1);
    this.vmTajRepService.getVmTajOneRep(taf).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      const r= res.data as VmTajRepRow;
      this.myModel.prcDar1 = r.prcDar1;
      this.myModel.prcDar2 = r.prcDar2;
      this.myModel.prcDar3 = r.prcDar3;
      this.myModel.prcDarSum31 = r.prcDarSum31;
      this.myModel.prcSumDar = r.prcSumDar;
      this.myModel.prcVam = r.prcVam;
      this.myModel.prcVamMandeh = r.prcVamMandeh;
     


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
}
