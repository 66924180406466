import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';

 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
 
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
 
import { tabeventobj } from '../../../../_models/tabeventobj';
import { GeneralService } from '../../../../_services/General/General.service';
import DataSource from "devextreme/data/data_source";
import { AnDeed, AnDeedReadOp } from '../../../../_models/app2/opr/AnDeed';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';
import { DoAmel } from '../../../../_models/app2/glob/AnAmel';
 

@Component({
  selector: 'app2-AnDeed-list',
  templateUrl: './AnDeed-list.component.html',
  styleUrls: ['./AnDeed-list.component.css']
})
 

export class AnDeedListComponent implements OnInit {
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;;
  @Input() hyperLinkOption: any;

  @Input() kindpage: number;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;
  @ViewChild("dxcolumlist", { static: false }) dxcolumlist: DxListComponent;
     //kind نوع صفحه
        //anDeedId ای دی سند
        //AnDeedRowId ردیف فکوس
  @Input() inputdata: any;
  @Input() tabindex: number;
  items: any;
  openeprint: boolean;
 
  openeMerge : boolean;
  openedit: boolean;
  openeditId: number;
  openeditKind: number;
  sanadopen: boolean = false;
  sanadkind: number = 0;

  monthRang: number[];


  focusedAnDeedRowId: number;
 
  
  kindList: any[] = [];
  anDeedReadOp: AnDeedReadOp ;


  valdata: DialogData;
  selectedrow: any;
 

  modletemp: AnDeed[];
  modlelist: any = {};
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};

 
  columH: any[] = [
    { index: 0, name: 'شماره', vis: true },
    { index: 1, name: 'نوع سند', vis: true },
    { index: 2, name: 'تاریخ', vis: true },
    { index: 3, name: 'انبار', vis: true },
    { index: 4, name: 'طرف مقابل', vis: true },
    { index: 5, name: 'وضعیت', vis: true },
    { index: 6, name: 'قیمت گذاری با اصلاحات', vis: true },
    { index: 7, name: 'سند حسابداری اصلاحات', vis: true },
    { index: 8, name: 'توضیحات', vis: true },
    { index: 9, name: 'صادره', vis: true },
    { index: 10, name: 'صادر کننده', vis: true },
    { index: 11, name: 'تغییر دهنده', vis: true },
   



  ];
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  loaddata: loadingCl;
  printnum: number ;
  duplcateOption: any;
  mergeOption: any;
  constructor(private userService: UserService,
 
    private cd: ChangeDetectorRef,
   
    private AnAmelService: AnAmelService,
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private AnDeedService: AnDeedService,
    private anRepService: AnRepService,
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {
    this.openeditKind = this.kindpage;
    this.printnum = 0;

    let b = this.AnDeedService.getAnDeedEmpty(0);
    this.modletemp = [];
    this.modletemp.push(b);
  
   this.modlelist = new DataSource({
      store: {
        type: "array",
        key: "id",
       data: this.modletemp
      }

   });
   // this.dataGrid.dataSource = this.modlelist;
    this.loaddata = loadServ.getEmpty();
  //  this.loadServ = new loadingClass(8);
   // let sam: loadingCl = new loadingClass(5);
  //  let myTask: Task = new MyTask('someTask');
   // myTask.run("test");
    this.loadServ.create(this.loaddata,20);
   // this.loadServ2.showloading(7);
   // this.loadServ2.showed = true;

   // this.loadServ2.showloading(0);
   // this.monthRang = [localst.dateEmruz.month -3, localst.dateEmruz.month]
    this.monthRang = [1, 12]
 
    if (this.monthRang[0] < 1)
      this.monthRang[0] = 1;
  
    this.openeprint = false;
    this.openedit = false;
 
    this.openeMerge = false;
    this.openeditId = -1;
    this.selectedrowkeys = [];
    this.selectedrowkeysIgnored = [];
    this.focusedAnDeedRowId = -1;
    this.duplcateOption = {
      copyCSh : null,
      copyId: null,
      paseCSh: null,
      paseId: null,
      date : '1000/00/00'

    }
    this.mergeOption = {

      years: 0,
      byKind: 'inList',

      inCShomareS: 1,
      inCShomareE: 1,

      inDaterangS: null,
      inDaterangE: null,

      inDateOneS: null,
      inDateOneE: null,

      inList: [],
    }

    this.items = [
    { text: 'کپی سند'  , id:'copy'},
    { text: '        '  , id:'null'},
      { text: 'ویرایش'  , id:'edit'},
    { text: 'حذف'  , id:'delete'},
    { text: 'قطعی'  , id:'vaz4'},
    { text: ' لغو بررسی'  , id:'vaz2'},
    { text: 'بررسی'  , id:'vaz3'}
    //  items: [
    //    { text: 'Facebook' },
    //    { text: 'Twitter' }],
    //},
 
    ];
  } 
   
  //itemClick(e) {

  //  //راست کلیک روی سند باز شده نباید باز شود
  //  if (this.openedit)
  //    return;

  //  if (!e.itemData.items) {

  //    let a = e.itemData.id;

  //    if (a == 'copy') { }
  //    else if (a == 'delete')
  //      this.onClickDelete();
  //    else if (a == 'edit')
  //      this.onClickEdit();


  //  }
  //}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);

 //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeAnDeed();
    //}
    



  }
  mytabindex: number = -1;
  userType: number = 0;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    //اگه سند از کاردکس یا دیگر گزارش ها فراخوانده شده بود
    //kind نوع صفحه
    //anDeedId ای دی سند
    //AnDeedRowId ردیف فکوس

    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
        // this.alertify.showListString(res.errors);
      }
      else {
        // this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.isdelete = "no";;



    this.kindList = this.AnDeedService.getkindList();
    this.anDeedReadOp = this.AnDeedService.getAnDeedReadOpEmpty();
    this.anDeedReadOp.years = this.localst.yearnow_get();
    //دیفالت صفحه
    this.anDeedReadOp.kinds.push(this.kindpage);
 


  //  this.load();
 
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

   // console.log('+=+=+=+ : ' + JSON.stringify(this.hyperLinkOption));

    
    //e شامل اپشن زیر استe
       // temp.op_moruroption = { "HPAnDeedId": e.HPAnDeedId, "HPSabtId": e.HPAnDeedId, "HPOpenOnInit": e.HPOpenOnInit };


    //اگر هیپرلینک ورودی داشت
    //if (this.hyperLinkOption != null) {
    //  if (this.hyperLinkOption.HPAnDeedId != null)
    //    this.openeditId = this.hyperLinkOption.HPAnDeedId;

    //  if (this.hyperLinkOption.HPSabtId != null)
    //    this.focusedAnDeedRowId = this.hyperLinkOption.HPSabtId;


    //  if (this.hyperLinkOption.HPAnDeedId != null)
    //    this.openedit = this.hyperLinkOption.HPOpenOnInit;





    //}
 

  }

  ngOnChanges(changes: any) {

    if (this.dataGrid != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.dataGrid.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.dataGrid.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.dataGrid.instance.getCellElement(index, 0);
          var y = this.dataGrid.instance.getScrollable();
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.dataGrid.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }
  }

 change;
  fchang(e) {

  
    this.change = new tabeventobj(this.ftabindex);
    this.change.value = e;


    this.onfchanged.emit(this.change);
    console.log(this.ftabindex);

  }
  ngAfterContentInit() {
     //546745hgfghf
    if (this.inputdata != null) {


      this.openeditKind =this.inputdata.kind;
      this.openeditId = this.inputdata.anDeedId;

      //this.openeditKind = 101;
      //this.openeditId = 5256;
      this.openedit = true;
    }

     
  }

  onClicksearch() {

    this.load();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
   
    this.load();
    console.log('m:searching... all');
  }
  load() {

   
    //console.log(this.monthRang);

    //let y = this.localst.yearnow_get().toString();
    //let dA = this.monthRang[0].toString();
    //let dB = this.monthRang[1].toString();

    //if (dA.length < 2)
    //  dA = dA = '0' + dA;
    //if (dB.length < 2)
    //  dB = dB = '0' + dB;

    //dA = y + dA + '00';
    //dB = y + dB + '99';

     

    this.loadServ.showloading(this.loaddata, 0);
    this.AnDeedService.getallByReadOp(this.anDeedReadOp)
      .subscribe((res) => {
       
        this.loadServ.hideloading(this.loaddata, 0);
      
        this.modlelist = new DataSource({
          store: {
            type: "array",
            key: "id",
            data: res.data,
            
          }
 
        });

        this.dataGrid.dataSource = this.modlelist;

        this.dataGrid.scrolling.scrollByContent = true;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

    //this.loadServ.showloading(this.loaddata, 0);
    //this.AnDeedService.getall(this.localst.yearnow_get(), this.kindpage)
    //  .subscribe((res) => {

    //    this.loadServ.hideloading(this.loaddata, 0);

    //    this.modlelist = new DataSource({
    //      store: {
    //        type: "array",
    //        key: "id",
    //        data: res.data,

    //      }

    //    });

    //    this.gridContainer.scrolling.scrollByContent = true;
    //  }, error => {
    //    this.loadServ.hideloading(this.loaddata, 0);
    //    this.alertify.error(error);
    //  });
  }

 

 
 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


 
  
   // console.log(JSON.stringify(this.selectedrow));

  }

  showDel: boolean = false;

  currentDelete: AnDeed = null;

  onClickDelete() {

    this.currentDelete = null;
    var key = this.dataGrid.focusedRowKey;

    if (key) {

      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (this.modlelist._items[j].id == key) {
          this.currentDelete = this.modlelist._items[j];
          this.showDel = true;

          break;
        }
      }

    }


  }

 
  dodelete() {


   
 
    if (this.currentDelete) {
    this.loadServ.showloading(this.loaddata,2);
      this.AnDeedService.delete(this.currentDelete.id,null).subscribe((res) => {
      this.loadServ.hideloading(this.loaddata,2);
        this.showDel = false;
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
    
      this.load();
      this.refleshAnDeedShomare();

    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 
  
    }
  
     
  }
  onClickCancelDelete() {

    this.showDel = false;
    this.currentDelete = null;

  }


  OnMyCancelEvent(e) {
    console.log('cancel form');
   // console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }

  }
  onClickNew() {

    console.log('newopen');

    this.openeditKind = this.kindpage;
    this.openedit = true;


  }

   
  onClickEdit() {


    if (this.selectedrow == null) return;

    //hhui5685
    this.openeditId = this.selectedrow.id;
    this.openeditKind = this.selectedrow.kind;

    this.openedit = true;

  }

  //onClickEdit2() {


  //  if (this.inputdata != null) {

  //    this.openeditKind = this.inputdata.kind;
  //    this.openeditId = this.inputdata.anDeedId;

  //    this.openedit = true;
  //  }

  //}


  OnMySaveEvent(e) {

 
    if (e.reloadlist) {

      this.load();

    }

    if (e.closepage) {

      this.openedit = false;
      this.openeditId = -1;

    }


 

  }


  refleshAnDeedShomare() {

  

  }

  //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  //{ id: 2, name: 'موقت' },
  //{ id: 3, name: 'بررسی شده' },
  //{ id: 4, name: 'قطعی شده' }

  //]
 


 onRowDblClick(e) {
//fgdhgt6565
    if (e.key == null) return;
    this.openeditId = e.key;
    this.openeditKind = e.data.kind;
    this.openedit = true;

  }

  onCellPrepared(e) {


    
    if (e.rowType === "data" && e.column.dataField === "vaziat") {
    //  if (e.data.Amount==2) 
    //  e.cellElement.style.backgroundColor  =  "green" ;
      // Tracks the `Amount` data field
      //e.watch(function () {
      //  return e.data.Amount;
      //}, function () {
      //  e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      //})
    }



  }
  //فرمت
  //[calculateCellValue] = "calculateCellDateValue"
  //calculateCellDateValue(data) {
  //  let ddd = data.date.toString();
  //  ddd = ddd.substring(1, 2)

  //  return ddd;
  //}

  onClickReflesh() {

 
 
    this.load();

  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,' لیست اسناد ');

 
  }
  onClickExportexceldetail() {


    // this.excelService.exportAsExcelFile(this.model.rows,' گزارش جامع فروش ');

    this.loadServ.showloading(this.loaddata, 3);
    this.anRepService.AnDeedJameRepAsExcel({ kind: this.kindpage, year: this.localst.yearnow_get() }).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  onExporting(e) {

  }
  onClickPrint() {


    let indx = this.dataGrid.focusedRowIndex;

    if (indx == -1) {
      if (this.modlelist != undefined)
        if (this.modlelist._items.length > 0)
          indx = 0;
    }

    if (indx != -1) {
      var data = this.dataGrid.instance.getCellElement(indx, 'code');
      this.printnum = +data.textContent;
    }

    this.openeprint = true;



  }
    //اگر پرینت از لیست سند صدا شود
  onCloseprint(e) {
 
    //console.log('click2');
    this.openeprint = false;

  }
  onPrintPrint(e) {


    e.kind = this.kindpage;
    this.onPrint.emit(e);
    this.openeprint = false;

  }
  //اگر پرینت از صفحه ویرایش سند صدا شود
  //اگر پرینت از صفحه ویرایش سند صدا شود
  OnEditPrintEvent(e) {

    if (e == null)
      return;

    this.onPrint.emit(e);
    this.openeprint = false;



  }
  test8() {
   // this.gridContainer.instance.sc
    console.log("test8");
  //  this.gridContainer.instance.columnOption(5, "width", 900);
  }

  

 
  onCloseSSS(e) {

  

  }
  onClosemerge(e) {

    this.openeMerge = false;

  }
  onDuplcateDateChanged(e) {


    var newcshomare = -1;
    for (var r = 0; r < this.modlelist._items.length; r++) {

      newcshomare = this.modlelist._items[r].cShomare;

      if (this.modlelist._items[r].date <= e)
        break;
      

    }

    newcshomare++;

    this.duplcateOption.paseCSh = newcshomare;


  }


 
  onValueChangedrang(e) {

    this.load();
  }
  firstclreate = true;
  onSelectionChanged(e) {


    if (this.firstclreate)
      return;

    var keys = this.dxliststr.selectedItemKeys;
    this.anDeedReadOp.kinds = [];
 
    if (keys != null)
      for (var j = 0; j < keys.length; j++) {


        this.anDeedReadOp.kinds.push(keys[j].id);
      }
  }
  
  saveuioption() {
    //ghh5uytbn7777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2002 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw2002.length; i++) {
      this.localst.optioncli.gw2002[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw2002 = this.localst.optionClient_readDefault('gw2002');
  }





  dropopened(e) {

   
    e.component._popup.option('width', 350);

    if (this.firstclreate) {

      
     //// this.dxliststr.selectedItemKeys=[this.kindpage];

      for (var r of this.kindList) {
        if (r.id == this.kindpage)
          this.dxliststr.instance.selectItem(r);
      }

      this.firstclreate = false;

    }
  }

  doAmel: DoAmel;
  onClickAmel(e) {

    this.doAmel = this.AnAmelService.getDoAnAmelEmpty();
    this.doAmel.kind = e;
    this.doAmel.tableN = 'andeed';
    this.doAmel.sanadIds = [];
    let keys = this.dataGrid.selectedRowKeys as number[];
    if (keys.length == 0) {
      this.alertify.warning('ابتدا ردیفی را انتخاب کنید');
      return;
    }
    //frdty4356
    this.doAmel.sanadIds= keys;

    this.loadServ.showloading(this.loaddata, 13);
    this.AnAmelService.doAmel(this.doAmel).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      if (res.errors.length > 0) { 
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.message(res.mes);
      }
       
    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });
  }



  Sanadids: number[];
  onClicksanadjoiner(e) {

    this.Sanadids = this.dataGrid.selectedRowKeys;



    if (this.Sanadids.length < 1) {
      this.alertify.warning('هیچ سندی انتخاب نشده است');
      return;
    }

    this.sanadopen = !this.sanadopen;
    this.sanadkind = e;
 



  }
  closesanad() {
    this.sanadopen = false;
    this.sanadkind = 0;
  }

  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }

  oncolumlistChanged(e) {

    if (this.firstpc)
      return;

    for (var i of e.addedItems) {
      this.columH[i.index].vis = true;
    }
    for (var i of e.removedItems) {
      this.columH[i.index].vis = false;
    }
   
  }


  firstpc:boolean= true;
  dropopenedColum(e) {


    e.component._popup.option('width', 250);

    if (this.firstpc) {


      this.dxcolumlist.instance.selectAll();
      this.firstpc = false;
  

    }
  }

  prepared = false;
  onPrepared(e) {
   // this.load();

    if (!this.prepared) {
      this.load();
      this.prepared = true;
    }
   
    //this.dataGrid.dataSource = this.modlelist;
  }

  showSort: boolean = false;
  numberSort: number = 10000;

  onClickSort() {

 

    //this.currentTafdaste = tafDasteSort;
    //this.popupVisible = true;

    this.showSort = true;


  }
  inuse: boolean = false;
  onClickSaveSort() {

    if (this.inuse) {
      return;
    }

    if (this.userType !=19) {
      return;
    }

    this.inuse = true;
    this.loadServ.showloading(this.loaddata, 2);
    this.AnDeedService.SortCodes(this.localst.yearnow_get(), this.kindpage, this.numberSort).subscribe(res => {
      this.inuse = false;

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.showSort = false;
      }
      this.load();
      this.loadServ.hideloading(this.loaddata, 2);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      this.inuse = false;
    });



  }
}


