import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
 
import { RepGhar, RepGharMain, RepGharOp } from '../../../../_models/app99/app99Repmodel';
import { App99RepService } from '../../../../_services/app99/App99Rep.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import * as FileSaver from 'file-saver';
 
 
 

@Component({
  selector: 'app99-RepGhar-list',
  templateUrl: './RepGhar-list.component.html',
  styleUrls: ['./RepGhar-list.component.css']
})


export class RepGharListComponent implements OnInit {

  openedit: boolean;
  openeditId: number =-1;
 
 


  model: RepGharMain;
  repGharOne: any;
  repGharOneRow: any[];
  vaziatlist: any[];
 
 
 
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  //@ViewChild("#treeContainer", { static: false }) #treeContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private RepGhar_Service: App99RepService, private app99_Service: App99Service,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.model = this.RepGhar_Service.RepGharMain_getEmpty();
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
 

    this.repGharOne= null;
    this.repGharOneRow= [];
    this.vaziatlist = this.app99_Service.Ghar_vaziatlist();

  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
 
    this.bindmah(0);
  }
 
 
  onClickSearch() {

    this.load();



  }
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.RepGhar_Service.RepGhar_Get(this.model.op)
      .subscribe((data: RepGharMain) => {
        this.model = data
  
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
  onValueChangedmah(e) {

    if (e.value == null)
      return;


    this.bindmah(e.value);

  }

  bindmah(mah: number) {


    this.loadServ.showloading(this.loaddata, 2);
    this.RepGhar_Service.gettimeOnmah(mah)
      .subscribe((data: any) => {

    
        this.model.op.dateA_S = data.dateA_S;
        this.model.op.dateB_S = data.dateB_S;
        this.load();

        this.loadServ.hideloading(this.loaddata, 2);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


  }



   
  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;



    this.repGharOneRow = [];
    if (this.selectedrow.id < 0)
      return;
 
    this.loadServ.showloading(this.loaddata, 8);
    this.RepGhar_Service.RepGharOne(this.selectedrow.id)
      .subscribe((res: any) => {
     
        this.repGharOne = res;
        this.repGharOneRow = res.doRow;
        this.loadServ.hideloading(this.loaddata, 8);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.alertify.error(error);
      });


  }
 

  onClickExportexcel() {


 

    this.loadServ.showloading(this.loaddata, 3);
    this.RepGhar_Service.AnDeedJameRepAsExcel(this.model.op).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

 
 

}
