import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { LoadingService } from '../../../_services/loading.service';
import { Localst } from '../../../_services/localst.service';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify.service';
import { SaleMaliService } from '../../../_services/General/SaleMali.service';
import { HesabService } from '../../../_services/app1/paye/Hesab.service';
import { Hesab } from '../../../_models/app1/paye/hesab';
import { VmsHes } from '../../../_models/app98/paye/VmsHes';
import { VmsHesService } from '../../../_services/app98/paye/VmsHes.service';
import { TafsiliForGroupRead } from '../../../_models/app1/paye/tafsiliForGroupRead';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';

 
 
 

@Component({
  selector: 'app98-VmsHes-Edit',
  templateUrl: './VmsHes-Edit.component.html',
  styleUrls: ['./VmsHes-Edit.component.css']
})
  

export class VmsHesEditComponent implements OnInit {
 
 
  hesablist: Hesab[] = [];
  myModel: VmsHes;
  @Input() tabh: number;

  tafg102: TafsiliForGroupRead;
  tafg201: TafsiliForGroupRead;
 

  loaddata: loadingCl;
  constructor( private alertify: AlertifyService,
    private route: ActivatedRoute,
    private localst: Localst,
    private loadServ: LoadingService,
    private hesabService: HesabService,
    private vmsHesService: VmsHesService,
    private tafsiliService: TafsiliService,
    public dialog: MatDialog) {

    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 10);
    this.myModel = vmsHesService.getVmsHesEmpty();
 

  
  }
 
  ngOnInit() {



    this.tafg102 = this.tafsiliService.getTafsiliGroupEmpty();
    this.tafg201 = this.tafsiliService.getTafsiliGroupEmpty();


    this.loadServ.showloading(this.loaddata, 0);
    this.hesabService.getHesabs_treenum(3).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.hesablist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
 

    this.loadServ.showloading(this.loaddata, 3);
    this.vmsHesService.getFirst().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);

      if (res.data != null) {
        this.myModel = res.data;
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

 

  }
  onClickUpdate() {

 
   
      this.loadServ.showloading(this.loaddata, 5);
      this.vmsHesService.UpdateAndReplace(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length == 0) {
          this.alertify.success(res.mes);
        }
      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

 


   
  }





  temp_moienchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hesabId = selected.id;
      cellInfo.data.hesabC = selected.codeSum;
      cellInfo.data.hesabN = selected.name;
    }
    else {

      cellInfo.data.hesabId = null;
      cellInfo.data.hesabC = null;
      cellInfo.data.hesabN = null;
    }
  }

  temp_hb201Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb201Id = selected.id;
      cellInfo.data.hb201C = selected.codeSum;
      cellInfo.data.hb201N = selected.name;
    }
    else {

      cellInfo.data.hb201Id = null;
      cellInfo.data.hb201C = null;
      cellInfo.data.hb201N = null;
    }
  }

  temp_hb202Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb202Id = selected.id;
      cellInfo.data.hb202C = selected.codeSum;
      cellInfo.data.hb202N = selected.name;
    }
    else {

      cellInfo.data.hb202Id = null;
      cellInfo.data.hb202C = null;
      cellInfo.data.hb202N = null;
    }
  }

  temp_hb204Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb204Id = selected.id;
      cellInfo.data.hb204C = selected.codeSum;
      cellInfo.data.hb204N = selected.name;
    }
    else {

      cellInfo.data.hb204Id = null;
      cellInfo.data.hb204C = null;
      cellInfo.data.hb204N = null;
    }
  }
  temp_hb205Idchanged(e, cellInfo) {
    var newValue = e.value;
    var selected = this.hesablist.find(e => {
      return e.id == newValue;
    });

    if (selected != null) {
      cellInfo.data.hb205Id = selected.id;
      cellInfo.data.hb205C = selected.codeSum;
      cellInfo.data.hb205N = selected.name;
    }
    else {

      cellInfo.data.hb205Id = null;
      cellInfo.data.hb205C = null;
      cellInfo.data.hb205N = null;
    }
  }


  adjustWidth(e, w) {

    e.component._popup.option('width', w);

  }
  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
      
  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }


  hb102changed(e) {

    if (e.value == null)
      return;


    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.getTafsilisGroup(e.value).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);
      //jtyui5686868
      this.tafg102 = res;
   
 

    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



  }
  t4changed(e) {

 

 
  }

  hb201changed(e) {

    if (e.value == null)
      return;


    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.getTafsilisGroup(e.value).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);
      //jtyui5686868
      this.tafg201 = res;



    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



  }
  t4changed201(e) {




  }
}


