import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { MatTab, MatTabGroup } from '@angular/material';
import { QueryList } from '@angular/core';
import { ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Menrow, MenurowService } from '../_services/General/menurow.service';
import { Menurow } from '../_models/General/menurow';
import { MorurOption } from '../_models/app1/rep/Morur';
import { SaleMaliService } from '../_services/General/SaleMali.service';
import { Localst } from '../_services/localst.service';
import { SaleMali } from '../_models/General/saleMali';
import { Customer } from '../_models/customer';
import { AlertifyService } from '../_services/alertify.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { ViewChild } from '@angular/core';
import { GeneralService } from '../_services/General/General.service';
import { LoadingService } from '../_services/loading.service';
import { tabeventobj } from '../_models/tabeventobj';
import { SherkatInfo } from '../_models/General/sherkatInfo';
import { MyTimeService } from '../_services/myTime.service';
import { Yaddasht } from '../_models/General/yaddasht';
import { YaddashtService } from '../_services/General/Yaddasht.service';
 
@Component({
  selector: 'tabapp99-root',
  templateUrl: './tabapp99.component.html',
  styleUrls: ['./tabapp99.component.css']
})
export class tabapp99Component implements OnInit {

 

  @Input() compname: string;

 
  @Input() options;
  @Input() menus: any[];

  yaddashtlist: Yaddasht[];
  dddd: boolean;

  public tabGroup: MatTabGroup;

  @ViewChildren('allTheseThings') things: QueryList<any>;

  ngAfterViewInit() {
    if (this.tabs.length < 1)
      return;

    this.selected.setValue(this.tabs.length - 1);
    this.things.changes.subscribe(t => {
      this.ngForRendred();
    })
  }
  ngForRendred() {
 //   console.log('NgFor is Rendered');

   // this.selected.setValue(this.tabs.length - 1);
  }

  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab>;
  public closedTabs = [];


  selected = new FormControl(0);
  MemberListComponent: boolean;
 

  tabsarchive2: Menrow[] = [{
    id: '1',
    tabType: 0,
    name: 'عملیات',
    component: '_app99',
    ismen: true,
    level: 0,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
    icon: "../assets/menup/base.png",
    expanded: true,
    disabled: false,
    items:
      [{
        id: '1_1',
        tabType: 0,
        name: 'اطلاعات پایه',
        component: '_app99_paye',
        ismen: true,
        level: 1,
        op_moruroption: null,
        op_data: null,
        fchanged: false,
        icon: "../assets/menup/base.png",
        expanded: true,
        disabled: false,
        items: [

          {

            id: '1_1_1',
            tabType: 1,
            name: 'اشخاص',
            component: 'Permlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled:false,
            items: []
          },
          {

            id: '1_1_2',
            tabType: 1,
            name: 'اپراتور مراسم',
            component: 'Oprtlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled: false,
            items: []
          },
          {

            id: '1_1_3',
            tabType: 1,
            name: 'ایتم هزینه ',
            component: 'HItemlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled: false,
            items: []
          },
          {

            id: '1_1_4',
            tabType: 1,
            name: 'پکیج ایتم هزینه ',
            component: 'HItemGlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled: false,
            items: []
          },
          {

            id: '1_1_5',
            tabType: 1,
            name: 'انواع هزینه ',
            component: 'KindHazlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled: false,
            items: []
          }
          ,
          {

            id: '1_1_6',
            tabType: 1,
            name: 'بانک ها ',
            component: 'BankMlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/peron966.png",
            expanded: false,
            disabled: false,
            items: []
          }
        
        ]

      }
        , {
        id: '1_2',
        tabType: 0,
        name: 'عملیات ',
          component: '_app99_opr',
        ismen: true,
        level: 1,
        op_moruroption: null,
        op_data: null,
        fchanged: false,
        icon: "../assets/menup/asnadgroups.png",
          expanded: true,
          disabled: false,
        items: [

          {
            id: '1_2_1',
            tabType: 1,
            name: 'قرارداد ها ',
            component: 'Gharlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }
          , {
            id: '1_2_2',
            tabType: 1,
            name: 'هزینه ',
            component: 'HazMList',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }, {
            id: '1_2_3',
            tabType: 1,
            name: 'درآمد ',
            component: 'DarMList',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }
          , {
            id: '1_2_4',
            tabType: 1,
            name: 'خزانه داری ',
            component: 'ChekMlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }, {
            id: '1_2_5',
            tabType: 1,
            name: 'تحویل',
            component: 'TahvilMlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }
          , {
            id: '1_2_6',
            tabType: 1,
            name: 'پرداختی',
            component: 'PayMlist',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/asnads.png",
            expanded: false,
            disabled: false,
            items: []
          }
        ]

      }

        ,
      {
        id: '1_3',
        tabType: 0,
        name: 'گزارش ها',
        component: '_app99_rep',
        ismen: true,
        level: 1,
        op_moruroption: null,
        op_data: null,
        fchanged: false,
        icon: "../assets/menup/reports.png",
        expanded: true,
        disabled: false,
        items: [

          {
            id: '1_3_1',
            tabType: 1,
            name: 'گزارش قرارداد',
            component: 'RepGharList',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/morur.png",
            expanded: false,
            disabled: false,
            items: []
          },
          {
            id: '1_3_2',
            tabType: 1,
            name: 'گزارش مشتری',
            component: 'RepPermList',
            ismen: true,
            level: 2,
            op_moruroption: null,
            op_data: null,
            fchanged: false,
            icon: "../assets/menup/morur.png",
            expanded: false,
            disabled: false,
            items: []
          }
        ]

      }

      ]



  },


  

  ]

  //تب های حلقه 
  tabsarchive = [{
    
    id: 1,
    tabType: 1,
    name: 'اشخاص',
    component: 'Permlist',
    ismen: true,
    level: 1,
    op_moruroption: null,
    op_data: null,
    fchanged: false,
  },
    {

      id: 11111,
      tabType: 1,
      name: 'اپراتور مراسم',
      component: 'Oprtlist',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },


   {
      id: 2,
      tabType: 1,
       name: 'ایتم هزینه ',
     component: 'HItemlist',
    
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {
      id: 2,
      tabType: 1,
      name: 'پکیج ایتم هزینه ',
      component: 'HItemGlist',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 3,
      tabType: 1,
      name: 'انواع هزینه ',
      component: 'KindHazlist',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 4,
      tabType: 1,
      name: 'بانک ها ',
      component: 'BankMlist',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 5,
      tabType: 1,
      name: 'قرارداد ها ',
      component: 'Gharlist',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },

    {

      id: 7,
      tabType: 1,
      name: 'هزینه ',
      component: 'HazMList',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 8,
      tabType: 1,
      name: 'درآمد ',
      component: 'DarMList',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 9,
      tabType: 1,
      name: 'گزارش قرارداد',
      component: 'RepGharList',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 10,
      tabType: 1,
      name: 'گزارش مشتری',
      component: 'RepPermList',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 11,
      tabType: 1,
      name: 'خزانه داری ',
      component: 'ChekMlist',

      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 12,
      tabType: 1,
      name: 'تحویل',
      component: 'TahvilMlist',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 13,
      tabType: 1,
      name: 'پرداختی',
      component: 'PayMlist',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    },
    {

      id: 14,
      tabType: 1,
      name: 'پرینت قرارداد',
      component: 'RepGharOneht',
      ismen: true,
      level: 1,
      op_moruroption: null,
      op_data: null,
      fchanged: false,
    }    ];



  visiblenenu: boolean;
  navVaziat: number;
  @Input() tabs = [];

   
 
  gg: number;
  navclassw: string;
  rightSide: string;
  leftSide: string;
  lodermessage: string;
  loaddata: loadingCl;
  timeourerror: boolean;
  hero: any;
  constructor(private authService: AuthService, private router: Router,  
    private menurowService: MenurowService, private saleMaliService: SaleMaliService, private LocalstService: Localst, private alertify: AlertifyService,
    private loadServ: LoadingService,
    private YaddashtService: YaddashtService,
    private generalService: GeneralService,
    private myTimeService: MyTimeService,

  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 10);
    this.lodermessage = 'درحال بارگذاری تنظیمات';

    this.refleshnavvaziat(31);
 
  
    this.timeourerror = false;
    //در یافت رویداد جهانی
    saleMaliService.salemaliChangEmitter$.subscribe(data => {
      console.log("eventrecive" + data);
      this.tabs = [];


    }, error => {

    });
    this.LocalstService.sherkatInfo = this.generalService.getSherkatInfo_null();

/*    this.menusourslist = this.menurowService.GetMenowAsRow();*/
 

  }
 
  ngOnInit() {

    var i = localStorage.getItem('token');
    if (i == null || i == undefined) {
      this.router.navigate(['/']);
    }

    this.bindGlobals();

    this.LocalstService.cheatlib = 0;
    this.dddd = false;



     this.LocalstService.getMySalshChangedEvent()
       .subscribe(item => this.onshalshchanged(item));



    

   this.yaddashtlist =  this.YaddashtService.getyyyyyList();

 
   
  
  } 



 
  onshalshchanged(e: boolean) {
    this.tabs=[];
   // console.log('all tabs closed')
  }
  bindGlobals() {











      

 


   // this.tabsarchive = this.menurowService.GetMenowAsRow();

  //  this.tabsarchive2 = this.menurowService.GetMenowAsTree();
 

  


  }


  clickmenubutton() {

    if (this.navVaziat == 31) {
      this.refleshnavvaziat(10);
    }
    else if (this.navVaziat == 10) {
      this.refleshnavvaziat(31);
    }
  }
  refleshnavvaziat(vaz : number) {

    this.navVaziat = vaz;

    //کامل باز و چسبیده
    if (vaz == 31) {

      this.visiblenenu = true;
      this.rightSide = 'oneppp';
      this.leftSide = 'twoppp';
    }

    //کامل بسته
  else if (vaz == 10) {
      this.visiblenenu = false;
      this.rightSide = 'oneppp onepppclose';
      this.leftSide = 'twoppp twopppclose';

    }
      

  }
  clickmenu(e) {


    console.log(e);
    this.AddTab(e);

  }
  test() {

    
    console.log('test');

  }

  AddTab(comp: string) {
    event.stopPropagation();

    for (var _i = 0; _i < this.tabsarchive.length; _i++) { 
      var num = this.tabsarchive[_i];
     // console.log(num);

      for (var _j = 0; _j < this.tabs.length; _j++) {
        if (this.tabs[_j].component == comp) {
          this.selected.setValue(_j);
          return;
        }
       
      }
      if (num.component == comp) {

        if (num.component == 'Sanadlist') {
          num.op_moruroption = { "HPSanadId": -1, "HPSabtId": -1, "HPOpenOnInit": false };
        }
        num.fchanged = false;
        this.tabs.push(num);
      }


    


    }

    this.selected.setValue(this.tabs.length - 1);
  //  this.tabGroup.selectedIndex = this.tabs.length - 1;
  }
 

  closeTab(index: number) {

   // this.alertify.message(this.tabs[index].fchanged);
   // console.log(JSON.stringify(this.tabs[index]));
   // console.log(this.dddd);

   // console.log('ddd' + this.tabs[index].fchanged);
 
    if (this.tabs[index].fchanged == true) {

      this.alertify.confirmClosetab( () => {
        this.tabs.splice(index, 1);
      });

 
    }
    else {
      this.tabs.splice(index, 1);
    }

  //  event.stopPropagation();
  //  this.closedTabs.push(index);
  //  this.tabGroup.selectedIndex = this.tabNodes.length - 1;
  }
 
  onfchanged(obj: tabeventobj) {

  
    //console.log('jjjjj' + this.tabs[index].fchanged);
    if (obj != null)
      this.tabs[obj.index].fchanged = obj.value;

   // console.log('jjjjj2' + this.tabs[index].fchanged);
   // console.log('tabindex' + index.toString() +' changed to true');


  }
 

 

 


  onValueChangedmenue(e) {

    const val = e.value;
    if (val == null)
      return;

    for (var i = 0; i < this.tabsarchive2.length; i++) {

      if (this.tabsarchive2[i].id == val) {
        this.AddTab(this.tabsarchive2[i].component);

      }
    }

    

   

  }










  onSanadprint(e) {

 
   // console.log(JSON.stringify(e));

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'SanadRepHt') {

        let temp = this.tabsarchive[i];
        temp.op_moruroption = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);

  }
 
  onReloadpage() {


    window.location.reload();

  }

  Paziresh_just(years :number) {

    console.log('paziresh refresh going');
    this.loadServ.showloading(this.loaddata, 6);
    this.generalService.Paziresh_reflesh(years).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.message( "اسناد پذیرش باموفقیت بروزرسانی شد");
      console.log('paziresh refresh done:');
      console.log(data);



    }, error => {
      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 6);
    });


  }

  treeViewonItemClick(e) {
    var n = e.itemData
    if (n.level > 0) {
      this.clickmenu(n.component);
    }
  }
  OnMyprintGharlist(e) {

    for (var i = 0; i < this.tabsarchive.length; i++) {

      if (this.tabsarchive[i].component == 'RepGharOneht') {

        let temp = this.tabsarchive[i];
        temp.op_data = e;

        this.tabs.push(temp);

      }
    }

    this.selected.setValue(this.tabs.length - 1);


  }
}

