import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
import { parse } from 'querystring';
import { GSharh } from '../../_models/General/GSharh';
import { IdName } from '../../_models/General/IdName';
import { Localst } from '../localst.service';
 
 

 
let EmptyGSharh: GSharh = {
  id: -1,
  code: 1,
  text: null,


};
 
@Injectable({
  providedIn: 'root'
})
export class GSharhService {
  baseUrl = environment.apiUrl;
  origin = location.origin;
  kindlist: IdName[] = [{ id: 1, name: 'حسایداری' },
  { id: 2, name: 'انبار' },
  ]
  constructor(private http: HttpClient, private localstService: Localst) { }


  getKindcodeList(): IdName[] {
    return this.kindlist;
  } 

  getGSharhs(): Observable<GSharh[]> {
    let apiadress = '/api/General/GSharh/getall';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<GSharh[]>(this.origin + apiadress, { 'headers': headers });
  }

  

 
  getGSharhbyid(id): Observable<GSharh> {
    let apiadress = '/api/General/GSharh/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);
 
    let v = this.http.get<GSharh>(this.origin + apiadress, { 'headers': headers }).pipe(
     
    );
  
    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  getGSharhBycodesal(id): Observable<GSharh> {
    let apiadress = '/api/General/GSharh/getbyCodeSal?CodeSal=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<GSharh>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  addGSharh(item: GSharh) {
    let apiadress = '/api/General/GSharh/add';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.post(this.origin + apiadress,item, { 'headers': headers });
  }



  getGSharhEmpty(): GSharh {

    return JSON.parse(JSON.stringify(EmptyGSharh)); 


  }
 
  updateGSharh(item: GSharh) {

    let apiadress = '/api/Paye/GSharh/update';
    var headers = this.localstService.header_get(apiadress,null);
  

    return this.http.post(this.origin + apiadress,item, { 'headers': headers });
  }

 
  deleteGSharh(GSharhId: number) {
    let apiadress = '/api/General/GSharh/delete?id=' + GSharhId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
 
  }
 
  countCode(item: string) {
    let apiadress = '/api/GSharh/countGSharhcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  GSharhListCode(): Observable<string[]> {
    let apiadress = '/api/General/GSharh/GSharhcodelist';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
}
