 
export class GhardeshTafsiliRep {
  option: GhardeshTafsiliRepOp;
  rows: GhardeshTafsiliRepRow[];

  constructor() {
    this.option = new GhardeshTafsiliRepOp();
    this.rows = [];

  }
}

export class GhardeshTafsiliRepOp {
  lv4: boolean;
  lv5: boolean;
  lv6: boolean;
  dateA: number;
  dateB: number;
  dateA_S: string;
  dateB_S: string;
  yearA: number;
  yearB: number;
  tafId: number;
  tafC: string;
  tafN: string;
  ishesabfilter: boolean;
  hesabIds: number[];
  rows: GhardeshTafsiliRepRow[];
  constructor() {

  }
}

export class GhardeshTafsiliRepRow {
  id: number;
  isSelect: boolean;
  text: string;
  sanadId: number;
  cShomare: number;
  cRozane: number;
  cAtf: number;
  cFare: number;
  date: number;
  date_S: string;
  years: number;
  hesabId: number;
  hesabName: string;
  hesabCode: string;
  tafsiliLevel: number;
  bed: number;
  bes: number;
  mande: number;
  mandeBed: number;
  mandeBes: number;
  mandeAll: number;
  mandeAllPipe: string;
  mahiyat: string;
  radif: number;
  subs: GhardeshTafsiliRepRow[];
}
 
