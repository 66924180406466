import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { resolveAny } from 'dns';
import { Backupc } from '../../_models/accounts/Backupc';
import { User, UserForEdit } from '../../_models/user';
import { BackupService } from '../../_services/accounts/Backupc.service';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { Localst } from '../../_services/localst.service';
import { UserService } from '../../_services/user.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../dialog/deldialog.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-backup-list',
  templateUrl: './backup-list.component.html',
  styleUrls: ['./backup-list.component.css']
})

export class backuplistComponent implements OnInit {

  

  model: Backupc ;
  modelList: Backupc[] =[];
  changres: any;
  //بک اپ های سیستمی
  kiselected: number = 1;
  ki: any[];

  constructor(
    private alertify: AlertifyService,
    private authService: AuthService,
    private userService: UserService,
    private backupService: BackupService,
    public dialog: MatDialog,
    private localst: Localst,
    private router: Router) {
    this.ki = [{ id: 1, name: 'پشتیبان کاربر' }, { id: 2, name: 'پشتبان سیستمی' }];
 
  }

  ngOnInit() {
    this.model = this.backupService.getBackupcEmpty();
 
    this.reloadgrid();
  }


  reloadgrid() {

    if (this.kiselected == 1) {
      this.backupService.getall().subscribe(res => {

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.modelList = res.data;
        }

      }, error => {
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
    }
   else if (this.kiselected == 2) {
      this.backupService.getallSys().subscribe(res => {

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.modelList = res.data;

        }
      }, error => {
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
    }





  }

  mestext: string;
  MyTimer() {
   
    console.log("plusplus");      // this works
    this.pvalue += 17;
    if (this.pvalue > 70) {

      this.pvalue = 100;
      clearInterval(this.myInterval);
      this.inprog = false;
      this.model = this.backupService.getBackupcEmpty();
      this.reloadgrid();
      this.mestext = 'فایل پشتیبان با موفقیت ایجاد شد';
     // this.alertify.success(res.mes);
    }
  }
  inprog = false;
   myInterval;
  pvalue: number = 0;
  takeBackup() {

   
    this.pvalue = 21;
 
    this.mestext = ' ';

    if (this.model.name == null || this.model.name.length < 1) {
      this.alertify.warning('عنوان فایل را مشخص نمایید');
      return;

    }
  
    this.inprog = true;
    this.mestext = 'درحال پردازش ... '
    this.backupService.takeBackup(this.model).subscribe(res => {

      if (res.errors.length > 0) {
        this.mestext = '';
        this.alertify.showListString(res.errors);
        return;
      }
      else {
     
      }

      this.myInterval = setInterval(() => { this.MyTimer() }, 1100);

    }, error => {
      this.mestext = 'خطا در پردازش ';
      this.inprog = false;
      this.pvalue = 0;
      this.alertify.error(error);
      console.log("myerror:" + error);
      clearInterval(this.myInterval);
    });


  }







  onSaveEdit() {

 
     
  }
  
 
onDeleterow(info) {



  this.openDialog(info.data.id);
}
  openDialog(id): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete(id);

      }

    });
  }

  dodelete(id: number) {

    this.backupService.deleteBackup(id).subscribe(res => {

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }
 
    
      this.alertify.success(res.mes);
 
      this.reloadgrid();

    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  //onClickExportexcel() {


  //  // this.excelService.exportAsExcelFile(this.model.rows,' گزارش جامع فروش ');

  //  this.loadServ.showloading(this.loaddata, 3);
  //  this.sellRepService.AnSellJameRepAsExcel(this.model.option).subscribe((buffer) => {
  //    this.loadServ.hideloading(this.loaddata, 3);

  //    const data: Blob = new Blob([buffer], {
  //      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
  //    });
  //    FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 3);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });


  //}

  dwname: string = '';
  onDownload(e) {

    //this.backupService.download().subscribe((response) => {
    // console.log( response['message']);
    //}, error => {

    //  this.alertify.error(error);

    //});




    this.dwname = 'db ' + e.data.name + ' ' +e.data.perdate+ 'backup' + '.tzs'

    if (!e.data.isSystem) {

      this.backupService.GetZipFile(e.data.id).subscribe((buffer) => {


        const data: Blob = new Blob([buffer], {
          type: 'application/zip;'
        });
        FileSaver.saveAs(data, this.dwname);

      }, error => {

        this.alertify.error(error);

      });
    }
    else {
  

      this.backupService.GetZipFileSys(e.data as Backupc).subscribe((buffer) => {
        const data: Blob = new Blob([buffer], {
          type: 'application/zip;'
        });
        FileSaver.saveAs(data, this.dwname);

      }, error => {

        this.alertify.error(error);

      });



    }


 
  }
  onRadValueChanged(e) {
    this.reloadgrid();
    //event.value
    
  }


}
