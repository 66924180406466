import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Sabt, SearchSanad } from '../../../_models/app1/opr/Sabt';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { of } from 'rxjs';
import { Localst } from '../../localst.service';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptySabt: Sabt = {

  id: -2000000010,
  sanad: null,
  sanadId: null,

  cShomare: -1,
  cRozane: -1,
  cAtf: -1,
  cFare: -1,
  date: null,
  kindId: -1,

  kindN: null,
 
  kind2Id: -1,

  kind2N: null,
  vaziat: -1,

  vaziatN: '',

  years: null,

  groupId: null,

  groupCode: null,


  groupName: null,

  kolId: null,

  kolCode: null,

  kolName: null,


  hesabId: null,

  hesabName: null,

  hesabCode: null,
  tafsili4Id: null,

  tafsili4Name: '',


  tafsili4Code: '',
  tafsili5Id: null,

  tafsili5Name: null,

  tafsili5Code: null,

  tafsili6Id: null,

  tafsili6Name: null,

  tafsili6Code: null,
  bed: 0,

  bes: 0,



  tartib: null,
  dateLog: null,
  userNLog: null,
  userFLog: null,
  _focus: false,
   radif:0,
  _rowStyle: 'csvalid',
  dis: null,
  _Dis2: null,
  _end:null

};
let header: any = {

  id: '',

  sanad: '',
  sanadId: '',

  cShomare: 'شماره سند',
  cRozane: 'شماره روزانه',
  cAtf: 'شماره عطف',
  cFare: '',
  date: '',
  kindId: '',
  kindN: 'نوع سند',

  whereIs: '',

  whereIsN: '',

  vaziat: '',

  vaziatN: 'وضعیت',

  years: '',

  hesabId: '',
  hesabName: 'معین',

  tafsili4Id: '',

  tafsili4Name: 'سطح چهارم',
  tafsili4Code: 'سطح چهارم',

  tafsili5Id: '',
  tafsili5Name: 'تفصیلی 5',
  tafsili5Code: 'سطح پنجم',

  tafsili6Id: '',
  tafsili6Name: 'تفصیلی 6',
  tafsili6Code: 'سطح ششم',
  bed: 'بدهکار',
  bes: 'بستانکار',
   _radif: 'ردیف'
};
let EmptySearchSanad: SearchSanad = {

 
  cShomareS: null,
  cShomareE: null,
  cAtfS: null,
  cAtfE: null,
  dateS: '1300/01/01',
  dateE: '1500/01/01',
  costS: null,
  costE: null,

  hesab: null,
  taf4: null,
  taf5: null,
  taf6: null,
  dis: null,
  costString: null,
  yearS:null,
  yearE:null,
  rows: []

};
@Injectable({
  providedIn: 'root'
})
export class SabtService {
  baseUrl = environment.apiUrl;
  origin = location.origin;
  constructor(private http: HttpClient, private localstService: Localst) { }


  getSabts(): Observable<Sabt[]> {

    let apiadress = '/api/App1/Paye/Sabt/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Sabt[]>(this.origin + apiadress, { 'headers': headers });

  }

  getSabts_P(): Observable<Sabt[]> {
    let apiadress = '/api/App1/Paye/Sabt/getall';
    var headers = this.localstService.header_get(apiadress,null);

    let params = new HttpParams();

     
    return this.http.get<Sabt[]>(this.origin + apiadress, { 'headers': headers })
   
  
   
 
  }
  getSabts_treenum(treenum : number): Observable<Sabt[]> {
    let apiadress = '/api/App1/Paye/Sabt/getallBytreenum/?treenum=' + treenum.toString();
    var headers = this.localstService.header_get(apiadress,null);

   
 
    return this.http.get<Sabt[]>(this.origin + apiadress, { 'headers': headers });

  }

 
  getSabt(id): Observable<Sabt> {
    let apiadress = '/api/App1/Paye/Sabt/' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

    if (id == -1) return of(this.getSabtEmpty());
    
 
    let v = this.http.get<Sabt>(this.origin + apiadress, { 'headers': headers }  ).pipe(
     
    );
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

 
  addSabt(item: Sabt) {
    let apiadress = origin + '/api/App1/Paye/Sabt/addSabt';
    var headers = this.localstService.header_get(apiadress,null);
 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getSabtEmpty(): Sabt {

 
    return JSON.parse(JSON.stringify(EmptySabt));

  }
  getHeader(): Sabt {

    return header;


  }
  getSearchSanadEmpty(): SearchSanad {


    return JSON.parse(JSON.stringify(EmptySearchSanad));

  }
  updateSabt(item: Sabt) {
    let apiadress = '/api/App1/Paye/Sabt/updateSabt';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  deleteSabt(SabtId: number) {
    let apiadress = '/api/App1/Paye/Sabt/';
    var headers = this.localstService.header_get(apiadress,null);

   
    return this.http.delete(this.origin + apiadress, { 'headers': headers } );
   // return this.http.delete(origin + 'users/' + userId + '/photos/' + id);
  }
  deleteSabtAndChild(SabtId: number) {
    let apiadress = '/api/App1/Paye/Sabt/deleteArchAndChild?id=' + SabtId.toString();
    var headers = this.localstService.header_get(apiadress,null);

  
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
    // return this.http.delete(origin + 'users/' + userId + '/photos/' + id);
  }
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Sabt/countSabtcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  SabtListCode(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Sabt/Sabtcodelist';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  SearchSabtssanad(empts: SearchSanad): Observable<SearchSanad> {
    let apiadress = '/api/App1/Opr/Sabt/SearchSanadRep';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.post<SearchSanad>(this.origin + apiadress, empts, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
}
