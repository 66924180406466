import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { Vahed } from '../../../_models/app2/paye/Vahed';
import { VahedFormul } from '../../../_models/app2/paye/VahedFormul';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyVahed: Vahed = {

  id: null,
  name: null,
  visib:true,
  taxGovC: null,
  taxGovN: null,

};
let EmptyVahedFormul: VahedFormul = {

  id: -1,

  vahedAId: null,

  vahedAN: null,


  vahedBId: null,

  vahedBN: null,

  zMulti: 1.0,

  zShow: 1.0,
  kalaId: -1,
  isInvert: false,
  _IsUsed:false

};
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VahedService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<Vahed[]> {
    let apiadress = '/api/App2/Paye/Vahed/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Vahed[]>(this.origin + apiadress, { 'headers': headers });
  }

 

 
  getbyid(id): Observable<Vahed> {
    let apiadress = '/api/App2/Paye/Vahed/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Vahed>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: Vahed) {
    let apiadress = '/api/App2/Paye/Vahed/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getVahedEmpty(): Vahed {

 
    return JSON.parse(JSON.stringify(EmptyVahed));

  }
  getVahedFormulEmpty(): VahedFormul {


    return JSON.parse(JSON.stringify(EmptyVahedFormul));

  }
  update(item: Vahed) {
    let apiadress = '/api/App2/Paye/Vahed/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  delete(VahedId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Vahed/delete?id=' + VahedId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallName(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/Vahed/getallName';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  getallTaxGov(): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Vahed/getallTaxGov';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
