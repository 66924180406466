import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
 
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
import { IdName } from '../../../../_models/General/IdName';
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { parse } from 'querystring';
import { LoadingService } from '../../../../_services/loading.service';
import { Sabt } from '../../../../_models/app1/opr/sabt';
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';
import { AnGar } from '../../../../_models/app2/end/AnGar';
import { AnGarService } from '../../../../_services/app2/end/AnGar.service';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
import { MyTimeService } from '../../../../_services/myTime.service';
import { retry } from 'rxjs/operators';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
 



@Component({
  selector: 'app2-AnGar-list',
  templateUrl: './AnGar-list.component.html',
  styleUrls: ['./AnGar-list.component.css']
})
  

export class AnGarListComponent implements OnInit {

  openprint: boolean;
  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;
  openedit: boolean;
  openeditId: number;
 
 
  @Output() OnMyprint = new EventEmitter<any>();
  @Input() inputdata: any;
 
  valdata: DialogData;
  myModel: AnGar[] = [];

  anbardateslast: any[] =[];
  anbarlist: Anbar[] =[];
  numlist: any[] = [{ id: 1, name: 'یک' },{ id: 2, name: 'دو' },{ id: 3, name: 'سه' }];
 


  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private anGarService: AnGarService,
 
    private AnbarService: AnbarService,
    private anDeedService: AnDeedService,
    private localst: Localst,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private myTimeService: MyTimeService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);

    this.myModel = [];
 
    this.openprint = false;
 
 

  }

  ngOnInit() {

    this.load();


    this.loadServ.showloading(this.loaddata, 1);
    this.AnbarService.getall().subscribe((data) => {

      this.loadServ.hideloading(this.loaddata, 1);
      this.anbarlist = data;
      this.pushNewRow();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }
 

  load() {

    this.loadServ.showloading(this.loaddata, 0);
    this.anGarService.getall(this.localst.yearnow_get()).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.myModel = res.data;
      this.loadalldate();
      this.pushNewRow();
      //  console.log(JSON.stringify(this.tadilop));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


   
  }


  loadalldate() {
    this.loadServ.showloading(this.loaddata, 0);
    this.anDeedService.getMaxDateOfDeeds(this.localst.yearnow_get()).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.anbardateslast = res.data;

      for (var t of this.myModel) {

        for (var uuu of this.anbardateslast) {
        //     public int anbarid { get; set; }
        //public int Year { get; set; }
        //public int Date { get; set; }
        //public string Date_S { get; set; }
          if (uuu.anbarid == t.anbarId) {

            t.datelast = uuu.date_S;
          }


        }



      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }

 

  pushNewRow() {

    //gfhjrtu7tyu6
    if (this.myModel.filter(x => x.id == 0).length == 0) {
      var nnn = this.anGarService.getAnGarEmpty()
      nnn = this.anGarService.getAnGarEmpty();

      let c = 0;
      for (var r of this.myModel) {
        if (r.radif > c)
          c = r.radif;

      }
      c++;
      nnn.radif = c;

      this.myModel.push(nnn);
    }
  }

  temp_anbarchanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Anbar;


    cellInfo.data.anbarId = h.id;
    cellInfo.data.anbarN = h.name;
    cellInfo.data.anbarC = h.code;


    cellInfo.data.datelast = this.getdatelast(h.id);




  }

  getdatelast(anbarid : number)  {
    //4544554454
    for (var uuu of this.anbardateslast) {
      //     public int anbarid { get; set; }
      //public int Year { get; set; }
      //public int Date { get; set; }
      //public string Date_S { get; set; }
      if (uuu.anbarid == anbarid) {

        return uuu.date_S;
      }

    

    }
    return null;
  

  }


  onValueChangeckDate(e, cellInfo) {

    var r = this.myTimeService.getValueAsDatestring(e);
    if (r == null)
      return;
    cellInfo.data.dateA_S = r;



  }
  onValueChangeName(e, cellInfo) {
    
    cellInfo.data.name = e.value;

  }
  onValueChangeMasul(e, cellInfo) {
    //ytui65865856
    cellInfo.data.masul = e.value;

  }
  onradioChanged(e, cellInfo) {
    if (e.value == undefined)
      return;

    if (e.value == null)
      return;
    //hgfgfh545555

  var h=  this.numlist.find(x => x.id == e.value);
  //  var h = e.component.option('selectedItem');

    if (h == null) {
      cellInfo.data.num = null;
      cellInfo.data.numN = null;
    }
    else {
      cellInfo.data.num = h.id;
      cellInfo.data.numN = h.name;
    }


   
  }

  onAdd(cellInfo) {
    //
    if (cellInfo.data == null)
      return;

    if (
      cellInfo.data.anbarId == null ||
      cellInfo.data.dateA_S == null ||
      cellInfo.data.name == null ||
      cellInfo.data.num == null ||
      cellInfo.data.masul == null
    ) {
      this.alertify.message('تمام فیلدها اجباری است');
      return;
    }

    var data = cellInfo.data as AnGar;

    data.years = this.localst.yearnow_get();
    



    this.loadServ.showloading(this.loaddata, 0);
    this.anGarService.add(data).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.load();
      }
      
  
      //  console.log(JSON.stringify(this.tadilop));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });





  }
  onCancel(cellInfo) {
     

    this.myModel = this.myModel.filter(x => x.id != 0);

    this.pushNewRow();

  }

  onStart(cellInfo) {


    if (cellInfo.data == null)
      return;

    if (cellInfo.data.id == 0)
      return;




    this.loadServ.showloading(this.loaddata, 0);
    this.anGarService.sabt(cellInfo.data.id).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.load();
      }


      //  console.log(JSON.stringify(this.tadilop));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  onDelete(cellInfo) {
    if (cellInfo.data == null)
      return;

    if (cellInfo.data.id == 0)
      return;




    this.loadServ.showloading(this.loaddata, 0);
    this.anGarService.delete(cellInfo.data.id).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.load();
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }
  onFocusedRowChanged(e) {
 
  }

  onRowDblClick(e) {

    if (e.key == null)
      return;
 
  

  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


   
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  

  }


 
  
 

  
  
  onFocusInDateS(e) {
  }

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
   
  }
} 


