import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { DirSell, DirSellExcelImportDto } from '../../../_models/app3/opr/DirSell';


 
let EmptyDirSellExcelImport: DirSellExcelImportDto = {
  exelN: null,
  exelText: null,
  exelDate: null,
  datatable: null
};
 
let EmptyDirSell: DirSell = {
  id: null,
  k_ExelC: null,
  k_ExelN: null,
  k_ExelText: null,
  k_ExelDate: null,
  k_code: null,
  taxid: null,
  indatim: null,
  factorDate: null,
  indati2m: null,
  inty: null,
  inno: null,
  irtaxid: null,
  inp: null,
  ins: null,
  tins: null,
  tob: null,
  bid: null,
  tinb: null,
  sbc: null,
  bpc: null,
  bbc: null,
  ft: null,
  bpn: null,
  scln: null,
  scc: null,
  cdcn: null,
  cdcd: null,
  crn: null,
  billid: null,
  tprdis: null,
  tdis: null,
  tadis: null,
  tvam: null,
  todam: null,
  tbill: null,
  tonw: null,
  torv: null,
  tocv: null,
  setm: null,
  cap: null,
  insp: null,
  tvop: null,
  tax17: null,
  rows: [],
  taxIdShow: null,
  taxNow: null,
  taxRdmSrl: null,
  taxVaz: null,
  taxVazN: null,
  taxRef: null,
  taxUId: null,
  taxErC: null,
  taxErD: null,
  taxDateP: null,

  vaz: null,
  _VazN:null,
  dateA: null,
  dateA_S: null,
  tob_S: null,
  inty_S: null,
  inp_S: null,
  ins_S: null,
  setm_S: null,

};

 


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class DirSellService {
  baseUrl = environment.apiUrl;
  origin = location.origin;


 
  constructor(private http: HttpClient, private localstService: Localst) {

 

  }

 
  getall(year,kind): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/DirSell/getall';

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getbyid(id): Observable<DirSell> {
    let apiadress = '/api/app3/Opr/DirSell/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<DirSell>(this.origin + apiadress, { 'headers': headers }).pipe();
    
  }

  delete(DirSellId: number): Observable<ApiRet> {

    let apiadress = '/api/app3/Opr/DirSell/delete?id=' + DirSellId.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

  }
 
  AddExcelImport( data : any): Observable<ApiRet> {
    let apiadress = '/api/App3/Opr/DirSell/addExcelImport';

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }


  getDirSellExcelImportEmpty(): DirSellExcelImportDto {
    return JSON.parse(JSON.stringify(EmptyDirSellExcelImport));

  }


  getDirSellEmpty(): DirSell {
    return JSON.parse(JSON.stringify(EmptyDirSell));

  }


}
