import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdMyCk, PdMyCkRow } from '../../../_models/app4/paye/PdMyCk';
 
 
let EmptyPdMyCk: PdMyCk = {
  id: -1,
  pdBankId: null,
  pdBank: null,
  seri: null,
  paperAll: 0,
  paperS: 0,
  years: null,
  dateA: 0,
  dateA_S: null,
  rows: [],
  uSa: null,
  uSaF: null,
  dateLog: new Date(),
  uSaE: null,
  uSaEF: null,
  dateELog: null,
 
};
let EmptyPdMyCkRow: PdMyCkRow = {
  id: 0,
  pdMyCkId: 0,
  pdMyCk: null,
  seri: null,
  paperC: 0,
  vaz: 1,
  vazN: 'سفید',
  sayadi:null
};
const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdMyCkService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdMyCk/getall';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdMyCk/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  add(item: PdMyCk) {
    let apiadress = '/api/App4/Paye/PdMyCk/add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getPdMyCkEmpty(): PdMyCk {


    return JSON.parse(JSON.stringify(EmptyPdMyCk));

  }
  getPdMyCkRowEmpty(): PdMyCkRow {


    return JSON.parse(JSON.stringify(EmptyPdMyCkRow));

  }

  update(item: PdMyCk) {
    let apiadress = '/api/App4/Paye/PdMyCk/update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(PdMyCkId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdMyCk/delete?id=' + PdMyCkId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }

  GetAllRowUnUsed(PdPayrecId : number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdMyCk/GetAllRowUnUsed?PdPayrecId=' +PdPayrecId.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}

