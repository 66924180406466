import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdMove } from '../../../_models/app4/opr/PdMove';
import { IdName } from '../../../_models/General/IdName';
import { PdMoveGroup } from '../../../_models/app4/opr/PdMoveGroup';
 
 
let EmptyPdMove: PdMove = {
  id: -1,
  radif:0,
  code:0,
  pdPayrecId: 0,
  pdPayrecRowId: 0,
  pdPayrecRow :null,
  kindPage: null,
  kindPageN: null,
  kindMove: null,
  kindMoveN: null,
  prc: 0,
  paykindIn: null,
  paykindInN: null,
  paykindRow: null,
  paykindRowN: null,
  cnt: 0,
  pdHesId : null,
  years: null,
  dateO: 0,
  dateO_S: null,
  mos: null,
  tafId: null,
  tafC: null,
  tafN: null,
  
  pdBankId: null,
  pdBankC: null,
  pdBankN: null,
  pdBank303Id: null,
  pdBank303C: null,
  pdBank303N: null,
  vaz: 0,
 
  uSa: null,
  uSaF: null,
  dateLog: new Date(),
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  sanadId: null,

  hesabId: null,
  hesabC: null,
  hesabN: null,
  dis: null,

  _haveSanad: null,
  sanadInfo: {}
};

let EmptyPdMoveGroup: PdMoveGroup = {
  id: -1,
  radif:0,
  code: 0,
  kindPage: null,
  kindPageN: null,
  kindMove: null,
  kindMoveN: null,
  prc: 0,
  paykindIn: 0,
  paykindInN: null,
  paykindRow: 0,
  paykindRowN: null,
  cnt: 0,
  pdHesId: null,
  years: null,
  dateO: 0,
  dateO_S: null,
  mos: null,
  tafId: null,
  tafC: null,
  tafN: null,

  pdBankId: null,
  pdBankC: null,
  pdBankN: null,
  vaz: 0,

  uSa: null,
  uSaF: null,
  dateLog: new Date(),
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  sanadId: null,

  hesabId: null,
  hesabC: null,
  hesabN: null,
  dis: null,
  rows: [],
    pdPayrecRows:[]
};

    /// عملیات دیگران
        /// 101 = دریافت
        /// 102 = انتقال داخلی
        /// 103 = واخواست
        /// 104 = وصول
        /// 105 = واگذاری
        /// 106 = مرجوع
        /// عملیت چک خودمان
        /// 201 =ایجاد دسته چک
        /// 202 = صدور
        /// 203 = انتقال داخلی
        /// 204 = برداشت*
        /// 205 = مرجوع
        /// 206 = ابطال*
        /// 207 = واگذاری مجدد

        //1=فرم انتقال
        //2= فرم ابطال
        //10 = فرم دریافت
        //-10= فرم پرداخت
        //-20 = دسته چک
//let  KindMoveList: any[] = [
//  { id: 101, name: 'دریافت', kindPage: 10, payKindIn: 1},
//  { id: 102, name: 'انتقال داخلی', kindPage: 1, payKindIn: 1 },
//  { id: 103, name: 'واخواست', kindPage: 1, payKindIn: 1 },
//  { id: 104, name: 'وصول', kindPage: 1, payKindIn: 1 },
//  { id: 105, name: 'واگذاری', kindPage: 1, payKindIn: 1 },
//  { id: 106, name: 'مرجوع', kindPage: 1, payKindIn: 1 },

//  { id: 200, name: 'ایجاد دسته چک', kindPage: -20, payKindIn: 2 },
//  { id: 201, name: 'صدور', kindPage: -10, payKindIn: 2 },
//  { id: 202, name: 'انتقال داخلی خود', kindPage: 1, payKindIn: 2 },
//  { id: 203, name: 'برداشت', kindPage: 2, payKindIn: 2 },
//  { id: 204, name: 'مرجوع خود', kindPage: 1, payKindIn: 2 },
//  { id: 205, name: 'ابطال', kindPage: 2, payKindIn: 2 },
//  { id: 206, name: 'واگذاری مجدد خود', kindPage: 1, payKindIn: 2 },

//];



const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdMoveService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }
  //1 = انتقال
  //2 = ایطال
  getall(kind: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/getall?kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getallPdMoveSanad(): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/getallPdMoveSanad';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getallByKindMove(kindMove: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/getallByKindMove?kindMove=' + kindMove.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getbyid(id): Observable<PdMove> {
    let apiadress = '/api/App4/Opr/PdMove/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<PdMove>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  add(item: PdMove): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getPdMoveEmpty(): PdMove {


    return JSON.parse(JSON.stringify(EmptyPdMove));

  }
  //getKindMoveList(kindPage: number, payKindIn: number): any[] {

    
  //    return JSON.parse(JSON.stringify(KindMoveList.filter(x => x.kindPage == kindPage && x.payKindIn == payKindIn)));

  //}
   
  
  update(item: PdMove): Observable<ApiRet>{
    let apiadress = '/api/App4/Opr/PdMove/update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(PdMoveId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/delete?id=' + PdMoveId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App4/Opr/PdMove/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
  getallByPayrecRowId(payrecRowId: number, reverse :boolean): Observable<ApiRet> {
   //ghjfgddftyiytgi
    let apiadress = '/api/App4/Opr/PdMove/getallByPayrecRowId?payrecRowId=' + payrecRowId.toString() +"&reverse=" + reverse;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
 
  GetAvailableKindMove(editId: number, kindPage: number,paykindRow : number ,pdPayrecRowId: number): Observable<ApiRet> {
    //ghjfgddftyiytgi
    let apiadress = '/api/App4/Opr/PdMove/GetAvailableKindMove?editId=' + editId.toString()+'&kindPage=' + kindPage.toString() + '&paykindRow=' + paykindRow.toString() + '&pdPayrecRowId=' + pdPayrecRowId.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  GetKindMove( kindPage: number): Observable<ApiRet> {
    //ghjfgddftyiytgi
    let apiadress = '/api/App4/Opr/PdMove/GetKindMove?kindPage=' + kindPage.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }

  ////////////////////////////////
  getPdMoveGroupEmpty(): PdMoveGroup {


    return JSON.parse(JSON.stringify(EmptyPdMoveGroup));

  }
  getallGroup(kind: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/getallGroup?kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  getGroupbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/getGroupbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

 
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  addGroup(item: PdMoveGroup): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/addGroup';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  updateGroup(item: PdMoveGroup): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/updateGroup';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  deleteGroup(PdMoveGroupId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdMove/deleteGroup?id=' + PdMoveGroupId.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
}

