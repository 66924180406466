import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';

import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../_models/General/IdName';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OstanService } from '../../../_services/app1/paye/Ostan.service';
import { mySF } from '../../../../../my_modules/mySF';
import { MatDialog } from '@angular/material';
import { GeneralService } from '../../../_services/General/General.service';
import {  FileToUpload, SherkatInfo, SherkatInfoKeys } from '../../../_models/General/sherkatInfo';
import { Localst } from '../../../_services/localst.service';
import { OstanKol } from '../../../_models/app1/paye/ostanKol';
import { Ostan } from '../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../_services/loading.service';
import { Photo } from '../../../_models/photo';
import { DomSanitizer } from '@angular/platform-browser';
import { TaxGovService } from '../../../_services/General/tax/taxGov.service';
 
@Component({
  selector: 'appsetting-SherkatInfo-taxgov',
  templateUrl: './sherkatInfo-taxgov.component.html',
  styleUrls: ['./sherkatInfo-taxgov.component.css']
})
export class sherkatInfoTaxGovComponent implements OnInit {


  messages: string[] = [];



  baseUrl = environment.apiUrl;
 
  @Output() cancelRegister = new EventEmitter();
 
 
 
  fileToUpload: File | null = null;
 
 
  SherkatInfoKeys: SherkatInfoKeys;
 
  versionRadio: any[] = [];
  loaddata: loadingCl;
    unsafeImageUrl: string;
  public imageUrl: any;
  constructor(private userService: UserService, private alertify: AlertifyService,
    private loadServ: LoadingService,
    private generalfoService: GeneralService,
    private taxGovService: TaxGovService,
    private localst: Localst,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private sf: mySF,
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 6);
 


    this.validationCallback = this.validationCallback.bind(this);  

    this.SherkatInfoKeys = new SherkatInfoKeys();
 
  
  }

  ngOnInit() {
    this.versionRadio = this.generalfoService.getVersionTax();
 

    this.reloadlist();
  }
 
  onFocusedRowChanged(e) {



  }
  addthis = e => {


  }
  cancelthis = e => {


  }


  havLogo: boolean = false;

 
  reloadlist() {

 




    this.loadServ.showloading(this.loaddata, 7);
    this.generalfoService.getSherkatInfoKeys().subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 7);
      this.SherkatInfoKeys = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
     
    });


  }


  onFormSubmit(e) {
 
    console.log("m:submitform");
 


 

 
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }



  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
  

  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    return true

   


}


  validateNumber(e) {

    

  }

  numberBox_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

 
   
    
     
  }
  
  datestart_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log(newValue);

    let t =  this.sf.calcOtherThing(2);

 
  
   console.log(t);
 
  }

 

 


  fileContent: string = '';
/*  cerKey: any;*/
  priKey: any;
 /* pubKey: any;*/
  //onChangePub(fileList: FileList) {
  //  let file = fileList[0];
  //  let fileReader: FileReader = new FileReader();
  //  let self = this;
  //  fileReader.onloadend = function (x) {
  //    self.SherkatInfoKeys.pubKey = fileReader.result.toString();
  //    console.log('ok');
  //  }
  //  fileReader.readAsText(file);
  //}


  onChangePri(fileList: FileList) {
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function (x) {
      self.SherkatInfoKeys.priKey = fileReader.result.toString();
      console.log('ok');
    }
    fileReader.readAsText(file);
  }

  //onChangeCer(fileList: FileList) {
  //  let file = fileList[0];
  //  let fileReader: FileReader = new FileReader();
  //  let self = this;
  //  fileReader.onloadend = function (x) {
  //    self.SherkatInfoKeys.cerKey = fileReader.result.toString();
  //    console.log('ok');
  //  }
  //  fileReader.readAsText(file);
  //}
  onSaveEdit(readCer: boolean, readPri: boolean) {

    //hftryutry87ty8
    if (this.SherkatInfoKeys.taxVer == 1) {
      if (this.SherkatInfoKeys.priKey.length == 0) {
        this.alertify.warning('فایل کلید بارگذاری نشده است.');
        return;
      }
    }
    else if (this.SherkatInfoKeys.taxVer == 2) {

      if (!this.theFileCer) {
        this.alertify.warning('گواهی امضا بارگذاری نشده');
      }
      if (!this.theFilePri) {
        this.alertify.warning(' کلید خصوصی بارگذاری نشده');
      }


      if (readCer == false) {

        this.readFileCer(this.theFileCer);
        return;
      }
      if (readPri == false) {

        this.readFilePri(this.theFilePri);
        return;
      }
    }
   


  


    this.loadServ.showloading(this.loaddata, 1);
    this.generalfoService.updateSherkatInfoKeys(this.SherkatInfoKeys).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.getsherkatinfi();
      }

 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




  }


  getsherkatinfi() {

    this.loadServ.showloading(this.loaddata, 1);
    this.generalfoService.getSherkatInfo().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.localst.sherkatInfo = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }



  
  onchekKeys(inti : number) {


    var s = this.localst.sherkatInfo_get();

    if (s.taxVer == null || s.taxVer == 0 || s.taxVer == undefined) {
      this.alertify.warning('نسخه کلایت شما بروز نیست ');
      this.alertify.warning('مرورگر را رفلش کنید ');
      return;
    }


    this.loadServ.showloading(this.loaddata, 2);
    this.taxGovService.checkConnection(inti,s.taxVer).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




  }
 
  theFileCer: any = null;
  onFileChangeCer(event) {
    const MAX_SIZE: number = 1048576;
    this.theFileCer = null;
  //  this.SherkatInfoKeys.fileCer = null;

    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        this.theFileCer = event.target.files[0];

       // this.SherkatInfoKeys.fileCer = event.target.files[0];
      }
      else {
        // Display error message
        this.alertify.error(' حجم فایل ' + event.target.files[0].name + ' بیشتر از یک مگابایت است ')
        this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
      }
    }
  }

  theFilePri: any = null;
  onFileChangePri(event) {
  const MAX_SIZE: number = 1048576;
    this.theFilePri = null;
  //  this.SherkatInfoKeys.fileCer = null;

  if (event.target.files && event.target.files.length > 0) {
    // Don't allow file sizes over 1MB
    if (event.target.files[0].size < MAX_SIZE) {
      // Set theFile property
      this.theFilePri = event.target.files[0];

      // this.SherkatInfoKeys.fileCer = event.target.files[0];
    }
    else {
      // Display error message
      this.alertify.error(' حجم فایل ' + event.target.files[0].name + ' بیشتر از یک مگابایت است ')
      this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
    }
  }
}

  //uploadCerFile(): void {
  //  this.readAndUploadFile(this.theFileCer);
  //}
  //private readAndUploadFile(theFile: any) {
  //  let file = new FileToUpload();

  //  // Set File Information
  //  file.fileName = theFile.name;
  //  file.fileSize = theFile.size;
  //  file.fileType = theFile.type;
  //  file.lastModifiedTime = theFile.lastModified;
  //  file.lastModifiedDate = theFile.lastModifiedDate;

  //  // Use FileReader() object to get file to upload
  //  // NOTE: FileReader only works with newer browsers
  //  let reader = new FileReader();

  //  // Setup onload event for reader
  //  reader.onload = () => {
  //    // Store base64 encoded representation of file
  //    file.fileAsBase64 = reader.result.toString();

  //    // POST to server
  //    this.generalfoService.uploadCerKeys(file).subscribe(resp => {

  //      this.alertify.success('فایل با موفقیت آپلود شد');
  //      this.messages.push("Upload complete");
  //    });
  //  }

  //  // Read the file
  //  reader.readAsDataURL(theFile);
  //}


  private readFileCer(theFile: any) {

   
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;
    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();
    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      this.SherkatInfoKeys.fileCer = file;
      this.onSaveEdit(true ,false);
    }
    // Read the file
    reader.readAsDataURL(theFile);
  }


  private readFilePri(theFile: any) {


    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;
    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();
    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      this.SherkatInfoKeys.filePri = file;
      this.onSaveEdit(true, true);
    }
    // Read the file
    reader.readAsDataURL(theFile);
  }

  defaulturl() {

    if (this.SherkatInfoKeys.taxVer == 1)
      this.SherkatInfoKeys.taxUrl = 'https://tp.tax.gov.ir/req/api';
    else if (this.SherkatInfoKeys.taxVer == 2)
      this.SherkatInfoKeys.tax2Url = 'https://tp.tax.gov.ir/requestsmanager';


  }
  onfin() {


    this.loadServ.showloading(this.loaddata, 2);
    this.taxGovService.GetFiscalInformation().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);
      //e674578568
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
}



class MyStaticFunction2 {
  static pi = 3.14;

  static ChekExistDate(radius: string) {
    return true;
  }


  static calculateArea(radius: number) {
    return this.pi * radius * radius;
  }

  calculateCircumference(radius: number): number {
    return 2;
  }







}
