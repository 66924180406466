import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Tafsili } from '../../../../_models/app1/paye/Tafsili';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ShakhzService } from '../../../../_services/app1/paye/Shakhz.service';
import { Shakhz } from '../../../../_models/app1/paye/Shakhz';
import { SherkatService } from '../../../../_services/app1/paye/Sherkat.service';
import { Sherkat } from '../../../../_models/app1/paye/sherkat';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ViewChild } from '@angular/core';
import { ExcelService } from '../../../../_services/excel.service';
import { LoadingService } from '../../../../_services/loading.service';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-Tafsili-list',
  templateUrl: './Tafsili-list.component.html',
  styleUrls: ['./Tafsili-list.component.css']
})
 

export class TafsiliListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;
  openDasteId: number;
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 5,
  totalItems: 0,
  totalPages: 0,
   };

  modlelist: Tafsili[];
  header: any;
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  searchParams: any = {};
  pagination: Pagination;


  //animal: string;
  //name: string;
  isdelete: string;
  selectedrow: any;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;

  constructor(private userService: UserService, private alertify: AlertifyService, private TafsiliService: TafsiliService,
    private ShakhzService: ShakhzService,
    private SherkatService: SherkatService,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {
    this.openDasteId = -1;
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 10);
    this.pagination = this.defaultpagation;
    this.header = this.TafsiliService.getTafsiliHeader();
    this.openedit = false;
    this.openeditId = -1;
  }

  ngOnInit() {
    this.resetFilters();
    this.isdelete = "no";;
    this.loadTafsili();
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

  }
  onClicksearch() {

    this.loadTafsili();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadTafsili();
    console.log('m:searching... all');
  }
  loadTafsili() {

    //this.TafsiliService.getTafsilis_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchParams)
    //  .subscribe((res: PaginatedResult<Tafsili[]>) => {
    //    this.modlelist = res.result;
    //    this.pagination = res.pagination;
    //  }, error => {
    //    this.alertify.error(error);
    //  });



    this.loadServ.showloading(this.loaddata, 0);
    this.TafsiliService.getTafsilis()
      .subscribe((res: Tafsili[]) => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.modlelist = res;
      }, error => {
          this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
      });

    //this.TafsiliService.getTafsilis_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.shakhsParams)
    //  .subscribe((res: PaginatedResult<Tafsili[]>) => {
    //    this.Tafsilis = res.result;
    //    this.pagination = res.pagination;
    //  }, error => {
    //    this.alertify.error(error);
    //  });
 
  }
  resetFilters() {
    this.searchParams.Name = '';
    this.searchParams.Code = '';
 
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadTafsili();
  }


  reloadrows(): void {
    this.loadTafsili();
    console.log("m:page refleshe done");
    this.loadTafsili();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

    console.log(JSON.stringify(this.selectedrow));

  }


  onClickDelete() {


    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }


  dodelete() {



    if (this.selectedrow.tafDasteId == -1001) {
      this.loadServ.showloading(this.loaddata, 1);
      this.ShakhzService.getShakhzByTafsiliid(this.selectedrow.id)
        .subscribe((res: Shakhz) => {
          this.loadServ.hideloading(this.loaddata, 1);
          let shakhs = res;
         


          console.log('shakhs.id');
          console.log(shakhs.id);

          this.loadServ.showloading(this.loaddata, 2);
          this.ShakhzService.deleteshakhz(shakhs.id).subscribe((data) => {
            this.loadServ.hideloading(this.loaddata, 2);
            let dd = data;
            // 0  وجود ندارد
            // 1  پاک شد
            // 2  اجازه پاک شدن ندارد
            if (dd == 1)
              this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

            else  if (dd == 2)
              this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

           else if (dd == 0)
              this.alertify.warning('این کد در سرور یافت نشد');

            console.log("m:deleted successful");
            this.loadTafsili();

          }, error => {
              this.loadServ.hideloading(this.loaddata, 2);
            this.alertify.error(error);
            console.log("myerror:" + error)
          });



        }, error => {
            this.loadServ.hideloading(this.loaddata, 1);
          this.alertify.error(error);
        });



    }
    else if (this.selectedrow.tafDasteId == -1002) {
      this.loadServ.showloading(this.loaddata, 3);
      this.SherkatService.getSherkatbytafsiliid(this.selectedrow.id)
        .subscribe((res: Sherkat) => {
          this.loadServ.hideloading(this.loaddata, 3);

          let shakhs = res;


          this.loadServ.showloading(this.loaddata, 4);
          this.SherkatService.deleteSherkat(shakhs.id).subscribe((data) => {
            this.loadServ.hideloading(this.loaddata, 4);
            let dd = data;
            // 0  وجود ندارد
            // 1  پاک شد
            // 2  اجازه پاک شدن ندارد
            if (dd == 1)
              this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

            else if (dd == 2)
              this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

            else if (dd == 0)
              this.alertify.warning('این کد در سرور یافت نشد');

            console.log("m:deleted successful");
            this.loadTafsili();
             
          }, error => {
              this.loadServ.hideloading(this.loaddata, 4);
            this.alertify.error(error);
              console.log("myerror:" + error);
          });

           

        }, error => {
            console.log("myerror:" + error);
            this.loadServ.hideloading(this.loaddata, 3);
          this.alertify.error(error);
        });
    }


    else {



      this.loadServ.showloading(this.loaddata, 5);
      this.TafsiliService.deleteTafsili(this.selectedrow.id).subscribe((data) => {
        this.loadServ.hideloading(this.loaddata, 5);
        let dd = data;
        // 0  وجود ندارد
        // 1  پاک شد
        // 2  اجازه پاک شدن ندارد
        if (dd == 1)
          this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

        else if (dd == 2)
          this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

        else if (dd == 0)
          this.alertify.warning('این کد در سرور یافت نشد');

        console.log("m:deleted successful");
        this.loadTafsili();

      }, error => {
          this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });
    }




  }






  onClickEdit() {

    if (this.loaddata.showed)
      return;

    if (this.selectedrow.id == null)
      return;

  

    if (this.selectedrow.tafDasteId == -1001) {

      this.openDasteId = this.selectedrow.tafDasteId;


      this.loadServ.showloading(this.loaddata, 6);
      this.ShakhzService.getShakhzByTafsiliid(this.selectedrow.id)
        .subscribe((res: Shakhz) => {

          this.loadServ.hideloading(this.loaddata, 6);
          if (res != null) {
            this.openeditId = res.id;
            this.openedit = true;
          }

        }, error => {
          this.loadServ.hideloading(this.loaddata, 6);
          this.alertify.error(error);
        });


    }
    else if (this.selectedrow.tafDasteId == -1002) {

      this.openDasteId = this.selectedrow.tafDasteId;


      this.loadServ.showloading(this.loaddata, 7);
      this.SherkatService.getSherkatbytafsiliid(this.selectedrow.id)
        .subscribe((res: Sherkat) => {
          this.loadServ.hideloading(this.loaddata, 7);
          if (res != null) {
            this.openeditId = res.id;
            this.openedit = true;
          }

        }, error => {
          this.loadServ.hideloading(this.loaddata, 7);
          this.alertify.error(error);
        });


    }
    else {
      this.openDasteId = -1;
      this.openeditId = this.selectedrow.id;
      this.openedit = true;
    }
      

     
 
 


  }

  OnMyCancelEvent(e) {
 
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
      this.openDasteId = -1;
    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
      this.openDasteId = -1;
    }

    this.loadTafsili();
  }
  onClickNew() {

    if (this.loaddata.showed)
      return;

    console.log('newopen');


    this.openedit = true;
    this.openeditId = -1;

  }
  onClickExportexcel() {


    // console.log(JSON.stringify(this.modlelist[0]));


    // var p=  this.shakhzService.convertToShakhzForPrint(this.modlelist);


    //this.excelService.exporttableRowbyRow(p , this.header, 'لیست اشخاص');

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance, ' لیست تفصیلی ');
  }

  OnOpenOderFormEvent(e) {

    if (e == -1001 || e == -1002) {

      this.openDasteId = e;
    }
    else
      this.openDasteId = -1;
  }
}


