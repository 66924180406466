import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
 
import { RepPerm, RepPermOp } from '../../../../_models/app99/app99Repmodel';
import { App99RepService } from '../../../../_services/app99/App99Rep.service';
import { App99Service } from '../../../../_services/app99/App99.service';
 
 
 
 

@Component({
  selector: 'app99-RepPerm-list',
  templateUrl: './RepPerm-list.component.html',
  styleUrls: ['./RepPerm-list.component.css']
})


export class RepPermListComponent implements OnInit {

  openedit: boolean;
  openeditId: number =-1;
 
 
  perMlist: any[] = [];
  modlelist: RepPerm[];
  modlelop: RepPermOp;
  RepPermOne: any;
  RepPermOneRow: any[];
 
 
 
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService
    , private App99Rep_Service: App99RepService,
    private app99Service: App99Service,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.modlelop = this.App99Rep_Service.RepPermOp_getEmpty();
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
    this.modlelist = [];

    this.RepPermOne= null;
    this.RepPermOneRow= [];
  
  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();
    this.loadServ.showloading(this.loaddata, 4);
    this.app99Service.PerM_getallForList(-1).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.perMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );
  }
 
 
  onClickSearch() {

    this.load();



  }
  load() {


    if (this.modlelop.permId == null)
      return;

    this.loadServ.showloading(this.loaddata,0);
    this.App99Rep_Service.RepPerm_Get(this.modlelop)
      .subscribe((res: RepPerm[]) => {
        this.modlelist = [];
        this.modlelist = res;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
 
 
   
  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

    this.RepPermOneRow = [];
 

    //this.loadServ.showloading(this.loaddata, 8);
    //this.RepPerm_Service.RepPerm_Get(this.selectedrow.id)
    //  .subscribe((res: any) => {
     
    //    this.RepPermOne = res;
    //    this.RepPermOneRow = res.doRow;
    //    this.loadServ.hideloading(this.loaddata, 8);

    //  }, error => {
    //    this.loadServ.hideloading(this.loaddata, 8);
    //    this.alertify.error(error);
    //  });


  }
 


  adjustWidth(e) {

    e.component._popup.option('width', 400);
   
  }
 
 

}
