import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent, DxTreeListComponent, DxTreeViewComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
 
 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ExcelService } from '../../../_services/excel.service';
import { Localst } from '../../../_services/localst.service';
 
import { AnRepService } from '../../../_services/app2/rep/AnRep.service';
import { KalaService } from '../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { KalaForList } from '../../../_models/app2/paye/Kala';
import { Vahed } from '../../../_models/app2/paye/Vahed';
import { Anbar } from '../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../_services/app2/paye/Anbar.service';
 
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../_models/app1/paye/tafsiliForSmall';
import * as FileSaver from 'file-saver';
import { AnDeedJameRep } from '../../../_models/app2/rep/AnDeedJameRep';
import { AnDeedJameRepService } from '../../../_services/app2/rep/AnDeedJameRep.service';
import { TabagheService } from '../../../_services/app2/paye/Tabaghe.service';
import { AnDeedService } from '../../../_services/app2/opr/AnDeed.service';
import { HesabService } from '../../../_services/app1/paye/Hesab.service';
import { hesabsmall } from '../../../_models/app1/paye/hesabsmall';

@Component({
  selector: 'app-AnDeedJame-Rep',
  templateUrl: './AnDeedJame-Rep.component.html',
  styleUrls: ['./AnDeedJame-Rep.component.css']
})
 

export class AnDeedJameRepComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @Input() isKharid: boolean;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;
  @ViewChild("dxliststrtab", { static: false }) dxliststrtab: DxListComponent;
  @ViewChild("dxliststrkind", { static: false }) dxliststrkind: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxTreeListComponent;
  @ViewChild("dataGridB", { static: false }) dataGridB: DxTreeListComponent;

  @ViewChild("hesabc", { static: false }) hesabc: DxSelectBoxComponent;
  @ViewChild("taf4c", { static: false }) taf4c: DxSelectBoxComponent;
  @ViewChild("taf5c", { static: false }) taf5c: DxSelectBoxComponent;
  @ViewChild("taf6c", { static: false }) taf6c: DxSelectBoxComponent;
  @ViewChild("kalac", { static: false }) kalac: DxSelectBoxComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  @Input() showRial: boolean;
  panelOpenState = false;

  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;
 

  focusedSabtId: number;
  
 //لیست کالا ها
  devDataKalas: any = {};
  //لیست کالا ها
  devDataTaf: any = {};
  vahedList: Vahed[] = [];
  //لیست انبارها 
  anbarList: Anbar[] = [];
  hesabList: hesabsmall[] = [];
  vazList: any[] = [];
  kindList: any[] = [];
  tabagList: any[] = [];

  
 
  selectedrow: any;
 

  model: AnDeedJameRep;
 
 
 
  dataSource: any;
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
 
  @ViewChild("UnSelectlist", { static: false }) UnSelectlist: DxListComponent;
  @ViewChild("Selectlist", { static: false }) Selectlist: DxListComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anDeedJameRepService: AnDeedJameRepService,
    private anDeedService: AnDeedService,
    private kalaService: KalaService,
    private tafsiliService: TafsiliService,
    private anbarService: AnbarService,
    private tabagheService: TabagheService,
    private hesabService: HesabService,
 
 
 
 
 
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    this.openedit= false;
    this.openeditId = -1;

 
    this.loadServ.create(this.loaddata,8);

   
    this.model = anDeedJameRepService.getAnDeedJameRepEmpty();
    this.model.option.year = localst.yearnow_get();

  //  $("dataGrid").find(".dx-icon").width(32).height(32);
   // this.dataGrid.instance.option('.dx-icon').width(32).height(32);;
   
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    
 
    //c=67
  
     //   this.onClickReflesh();
      
   
 



  }
  ngOnInit() {
    this.vazList = this.anDeedService.getVazList();
    this.kindList = this.anDeedService.getkindList();

    if (this.isKharid)
      this.kindList = this.kindList.filter(x => x.id == 201 || x.id == -201);


    this.loadServ.showloading(this.loaddata, 11);
    this.hesabService.gethesabsmall(3)
      .subscribe((res: hesabsmall[]) => {
        this.loadServ.hideloading(this.loaddata, 11);

        this.hesabList = res;


      }, error => {
        this.loadServ.hideloading(this.loaddata, 11);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 10);
    this.tabagheService.getall_tiny()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 10);

        this.tabagList = res.data;
       

      }, error => {
        this.loadServ.hideloading(this.loaddata, 10);
        this.alertify.error(error);
      });




    this.loadServ.showloading(this.loaddata, 2);
    this.kalaService.getallForlist()
      .subscribe((res: KalaForList[]) => {
        this.loadServ.hideloading(this.loaddata, 2);
     

        this.devDataKalas = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.GetTafsiliForSmall()
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 4);


        this.devDataTaf = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 3);
    this.anbarService.getalltiny()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 3);

        this.anbarList = res.data;

        ////در بار اول انبار ها را در مدل سلکت میکنیم
        for (var j = 0; j < this.anbarList.length; j++) {

          this.model.option.anbars.push( { id: this.anbarList[j].id, name: this.anbarList[j].name });

        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });



    this.loadServ.showloading(this.loaddata, 7);
    this.anDeedJameRepService.DateRangAnDeed(this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 7);

    
        this.model.option.dateS_S = res.data.dateA_S;

      
        this.model.option.dateE_S = res.data.dateB_S;
     

        //تا اولین شماره سند این تاریخ هم از سرور خوانده شود
        this.focusfilter = 'dateS';
        this.dateSchang({ value: res.data.dateA_S });

        //تا اخرین شماره سند این تاریخ هم از سرور خوانده شود
        this.focusfilter = 'dateE';
        this.dateEchang({ value: res.data.dateB_S });

        this.focusfilter = null;
      }, error => {
        this.loadServ.hideloading(this.loaddata, 7);
        this.alertify.error(error);
      });


  
  }
  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {


   // this.excelService.exportAsExcelFile(this.model.rows,' گزارش جامع فروش ');
    this.model.option.isKharid = this.isKharid;

    this.loadServ.showloading(this.loaddata, 3);
    this.anDeedJameRepService.AnDeedJameRepAsExcel(this.model.option).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }
  onClickReflesh() {

    //if (this.model.option.kalaId == null) {
    //  this.alertify.message('کالا را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.dateA_S == null) {
    //  this.alertify.message('تاریخ شروع را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.dateA_S == null) {
    //  this.alertify.message('تاریخ پایان را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.vahedId == null) {
    //  this.alertify.message('واحد را انتخاب کنید');
    //  return;
    //}

    //انبار های سلکت شده را به مدل میدیم

    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم

    if (this.dxliststr != undefined) {
      var keys = this.dxliststr.selectedItemKeys;
      this.model.option.anbars = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {

          this.model.option.anbars.push({ id: keys[j].id, name: keys[j].name});
        }
    }
    if (this.dxliststrtab != undefined) {
      var keys = this.dxliststrtab.selectedItemKeys;
      this.model.option.tabages = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {

          this.model.option.tabages.push({ id: keys[j].id, name: keys[j].name });
        }
    }
  

 
      if (this.dxliststrkind != undefined) {
      var keys = this.dxliststrkind.selectedItemKeys;
      this.model.option.kind = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {
          this.model.option.kind.push({ id: keys[j].id, name: keys[j].name });
        }
      }

    //همواره پر شود
    if (this.model.option.kind.length == 0) {
      this.model.option.kind = [];
      ////for (var j = 0; j < this.kindList.length; j++) {
      ////  this.model.option.kind.push({ id: this.kindList[j].id, name: this.kindList[j].name });
      ////}
    }


    this.model.option.isKharid = this.isKharid;

    //yytu85685858
    this.loadServ.showloading(this.loaddata, 5);
    this.anDeedJameRepService.AnDeedJameRep(this.model.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);


        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.model = res.data;
        }
      
       

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


  adjustWidth(e) {

    e.component._popup.option('width', 500);

  }

 


  OnMyCancelEvent(e) {

    this.openedit = false;
  }
  OnMySaveEvent(e) {

    this.openedit = false;

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

  onkalaachanged(e) {

  //fghfgh665

    if (e.value == null)
      return;

    var it = this.devDataKalas._store._array.find(obj => { return obj.id === e.value });

    //if (this.model.option.kalaIds.find(obj => { return obj.id === -1 })) {
    //  this.model.option.kalaIds = [];
    //}

    this.model.option.kalaIds.push({ id: it.id, name: it.code + ' ' + it.code });

    this.kalac.value = null;
    
  }

  onhesabchanged(e) {
    var it = this.hesabList.find(obj => { return obj.id === e.value });

    this.model.option.hesabs.push({ id: it.id, name: it.codeSum + ' ' + it.name });
    this.hesabc.value = null;
  }
  ontafchanged(e ,level:number) {

    if (e.value == null)
      return;

    var it = this.devDataTaf._store._array.find(obj => { return obj.id === e.value });


   
    if (level == 4) {
      this.model.option.taf4s.push({ id: it.id, name: it.code + ' ' + it.name });
      this.taf4c.value = null;
    }
    else if (level == 5) {
      this.model.option.taf5s.push({ id: it.id, name: it.code + ' ' +it.name });
      this.taf5c.value = null;
    }
    else if (level == 6) {
      this.model.option.taf6s.push({ id: it.id, name: it.code + ' ' +it.name });
      this.taf6c.value = null;
    }
  }
  delkala(e) {

    this.model.option.kalaIds = this.model.option.kalaIds.filter(({ id }) => id !== e.id);
  }
  delhesab(e) {

    this.model.option.hesabs = this.model.option.hesabs.filter(({ id }) => id !== e.id);
  }
  deltaf(e, level: number) {

    if (level==4)
      this.model.option.taf4s = this.model.option.taf4s.filter(({ id }) => id !== e.id);
    if (level == 5)
      this.model.option.taf5s = this.model.option.taf5s.filter(({ id }) => id !== e.id);
      if (level == 6)
        this.model.option.taf6s = this.model.option.taf6s.filter(({ id }) => id !== e.id);
  }
   
  toupgroup(e) {
    for (var i = 0; i < this.model.option.gSelect.length;i++) {
      if (this.model.option.gSelect[i].id == e.id) {

        if (i == 0)
          break;

        var temp = this.model.option.gSelect[i - 1];

        this.model.option.gSelect[i - 1] = this.model.option.gSelect[i]
        this.model.option.gSelect[i] = temp;
        break;
      }

    }

  }


  firstclreate = true;
  dropopened(e) {

    e.component._popup.option('width', 500);
    if (this.firstclreate) {
      this.dxliststr.instance.selectAll();
      this.firstclreate = false;

    }
  }


  f2 = true;
  dropopenedTabag(e) {

    e.component._popup.option('width', 500);
    if (this.f2) {
      this.dxliststrtab.instance.selectAll();
      this.f2 = false;

    }
  }

  f3 = true;
  dropopenedKind(e) {

    e.component._popup.option('width', 500);
    if (this.f3) {
      this.dxliststrkind.instance.selectAll();
      this.f3 = false;

    }
  }
  onSelectionChanged(e) {


    //if (this.firstclreate)
    //  return;

    //var keys = this.dxliststr.selectedItemKeys;
    //this.model.option.anbarIds = [];
    ////console.log('oooooooooooop');
    ////console.log(JSON.stringify(keys));
    //if (keys != null)
    //  for (var j = 0; j < keys.length; j++) {

         
    //    this.model.option.anbarIds.push(keys[j]);
    //  }
  }

  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    //if (e.column.dataField == "amtMan"  ) {
    //  if (e.data.amtMan > 0) {
    //   // e.cellElement.style.backgroundColor = 'rgb(31 233 0 / 0.1)';
    //    e.cellElement.style.color = 'darkred';
     
    //  }
    //  else if (e.data.amtMan < 0) {
    //    e.cellElement.style.backgroundColor = '#efbdba';

    //  }
    //}
    //if (e.column.dataField == "amtIn" || e.column.dataField == "fiIn" || e.column.dataField == "prcIn") {
    //  if (e.data.amtIn == 0) {
    //    // e.cellElement.style.backgroundColor = 'rgb(31 233 0 / 0.1)';
    //    e.cellElement.style.backgroundColor = '#B0B0B0';

    //  }
      
    //}
    //console.log(e.rowType);
   // e.cellElement.style.textalign = "center!important";
     // e.cellElement.css("text-align", "center!important");
    
 

   



  }



  onClickgoSanad() {

    //yttyyt566767
    //var key = this.dataGrid.focusedRowKey;
    //if (key == null)
    //  return;

 
    //for (var r of this.model.rows) {
    //  if (r.id == key) {
    //    //kind نوع صفحه
    //    //anDeedId ای دی سند
    //    //AnDeedRowId ردیف فکوس
    //    this.onOpenSanadList.emit({ kind: r.kind, anDeedId: r.anDeedId, AnDeedRowId: r.id });
    //    break;
    //  }

    //}
  }
  selectgroup() {

    let se = this.UnSelectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gUnSelect) {
      if (r.id == se[0]) {
        this.model.option.gSelect.push(r);
        break;
      }
  
    }
    this.model.option.gUnSelect = this.model.option.gUnSelect.filter(({ id }) => id !== se[0]);
 
    
  }

  unselectgroup() {
    let se = this.Selectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gSelect) {
      if (r.id == se[0]) {
        this.model.option.gUnSelect.push(r);

        break;
      }

    }
    this.model.option.gSelect = this.model.option.gSelect.filter(({ id }) => id !== se[0]);
 

  }

   
  calculateSelectedRow(options) {
 
  }
 



  focusfilter: string;
 
onFocusIn(e) {

    this.focusfilter = e;
}
  dateSchang(e) {
    if (this.focusfilter != 'dateS')
      return;

   // this.model.option.dateS = e.value.toString();
    this.loadServ.showloading(this.loaddata, 9);
    this.anDeedJameRepService.getfirstCodeAnDeed(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 9);
      if (res != null)
        this.model.option.codeS = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
    });

  }
  dateEchang(e) {
    if (this.focusfilter != 'dateE')
      return;


    this.loadServ.showloading(this.loaddata, 8);
    this.anDeedJameRepService.getlastCodeAnDeed(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 8);
      if (res != null)
        this.model.option.codeE = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
    });
  }
  codeSchang(e) {
    if (this.focusfilter != 'codeS')
      return;

    this.loadServ.showloading(this.loaddata, 6);
    this.anDeedJameRepService.getfirstDateAnDeed(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);
      if (res != null)
        this.model.option.dateS_S = res.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
    });
  }

  codeEchang(e) {
    if (this.focusfilter != 'codeE')
      return;

    this.loadServ.showloading(this.loaddata, 1);
    this.anDeedJameRepService.getlastDateAnDeed(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (res != null)
        this.model.option.dateE_S = res.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
    });
  }


  saveuioption() {
    //ghh555566667777
    if (!this.isKharid) {
      var res = [];
      for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
        var w = this.dataGrid.instance.columnOption(i).width;
        if (w != undefined)
          res.push(w);
        else res.push(0);
      }
      this.localst.optioncli.gw2009 = res;
      this.localst.optionClient_save();
      this.alertify.message('تغییرات نما ذخیره شد');
    }
    else {

      var res = [];
      for (var i = 0; i < this.dataGridB.instance.columnCount(); i++) {
        var w = this.dataGridB.instance.columnOption(i).width;
        if (w != undefined)
          res.push(w);
        else res.push(0);
      }
      this.localst.optioncli.gw2010 = res;
      this.localst.optionClient_save();
      this.alertify.message('تغییرات نما ذخیره شد');


    }
  }


  addwidth(e) {
    if (!this.isKharid) {
      for (var i = 0; i < this.localst.optioncli.gw2009.length; i++) {
        this.localst.optioncli.gw2009[i] *= e;
      }
    }
    else {
      for (var i = 0; i < this.localst.optioncli.gw2010.length; i++) {
        this.localst.optioncli.gw2010[i] *= e;
      }

    }
  }
  defwidth() {
    if (!this.isKharid) {
      this.localst.optioncli.gw2009 = this.localst.optionClient_readDefault('gw2009');
    }
    else {

      this.localst.optioncli.gw2010 = this.localst.optionClient_readDefault('gw2010');
    }
  }

 

  RowPrepared(e) {

    if (e.key < 0) {
    /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }
     
    //e.rowElement.css('background', 'green');
  }
  onClickPrint() {

 

  }

  pstylee: any = 'fixheghLong';

  expandopened(e) {
    this.panelOpenState = true;
   // this.pstylee = 'fixheghShort';
  }
  expandclosed(e) {
    this.panelOpenState = false;

   // this.pstylee = 'fixheghLong';
  }
 
}


