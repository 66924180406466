import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnMojudiRep, AnMojudiRepOp } from '../../../_models/app2/rep/AnMojudi';
 
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class AnMojudiRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
  constructor(private http: HttpClient, private localstService: Localst) {

 

  }
   


  EmptyAnMojudiRep: AnMojudiRep = {
    option: null,
    rows: null
  }

  EmptyAnMojudiRepOp: AnMojudiRepOp = {
    codeE: 0,
    dateE_S: null,
    dateE: 0,
    year: 0,
    anbars: [],
    kalaIds: [],
    tabages: []
  }


 

  getEmptyAnMojudiRep(): AnMojudiRep {

    let l = JSON.parse(JSON.stringify(this.EmptyAnMojudiRep)) as AnMojudiRep;
    l.option = JSON.parse(JSON.stringify(this.EmptyAnMojudiRepOp));
    return l;


  }

  getEmptyAnMojudiRepOp(): AnMojudiRepOp {


    return JSON.parse(JSON.stringify(this.EmptyAnMojudiRepOp));


  }

  AnMojudiRep(data: AnMojudiRepOp): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnMojudiRep/AnMojudiRep';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }
}
