import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { AlertifyService } from '../../../../_services/alertify.service';
 
import { environment } from '../../../../../environments/environment';
 
 
import { LoadingService } from '../../../../_services/loading.service';
import { AnCount, AnCountRow } from '../../../../_models/app2/end/AnCount';
import { AnCountService } from '../../../../_services/app2/end/AnCount.service';
import { AnGar } from '../../../../_models/app2/end/AnGar';
import { AnGarService } from '../../../../_services/app2/end/AnGar.service';
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from "devextreme/data/data_source";
import { ExcelService } from '../../../../_services/excel.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
  

@Component({
  selector: 'app2-AnCount-edit',
  templateUrl: './AnCount-edit.component.html',
  styleUrls: ['./AnCount-edit.component.css']
})
export class AnCountEditComponent implements OnInit {

  
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
   
  myModel: AnCount;
  myModel_old: AnCount;
 
  anGarList: AnGar[] =[];
 
  gridDataSource: AnCountRow[]=[] ;

 AnCountcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  opensanad: boolean = false;
 
  private sub: any;
  test: string;
    n: number;

  showsefr: boolean = false;
 
  loaddata: loadingCl;
  constructor(
    private alertify: AlertifyService,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private anCountService: AnCountService,
    private anGarService: AnGarService,
    public dialog: MatDialog,
    private localst: Localst,
 ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
 
    
    this.myModel = this.anCountService.getAnCountEmpty();
    this.AnCountcodelist = [];
    
  }

  ngOnInit() {
 
  
    this.loadServ.showloading(this.loaddata, 3);
    this.anGarService.getall(this.localst.yearnow_get()).subscribe(
      (res) => {

        this.loadServ.hideloading(this.loaddata, 3);
        this.anGarList = res.data;

      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 3);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );

   
    this.load();
  
  }
  load() {
    this.isnew = true;

    if (this.editId == -1) {


      this.myModel = this.anCountService.getAnCountEmpty();
      this.refleshSanadCSS();

      this.isnew = true;

      if (this.numselected == 0)
        this.numselected = 1;

      this.refleshDiisable(0);

    }
    else {


      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.anCountService.getbyid(this.editId).subscribe(
        (data: AnCount) => {

          this.loadServ.hideloading(this.loaddata, 3);
          this.myModel = data;
          //  this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
     

          this.refleshDiisable(this.myModel.anGar.num);
          this.refleshSanadCSS();

          if (this.numselected == 0)
            this.numselected = 1;

          this.onNumbutton(this.numselected);
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }


  }

  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e, close: boolean, inOutKey: number) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    

     
 
    if (this.myModel.id == -1) {


      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;


      this.loadServ.showloading(this.loaddata,4);
      this.anCountService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 4);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          if (inOutKey == 1 || inOutKey == -1) {
            this.openDeedSanad(inOutKey);
          }
          this.OnMySaveEvent.emit(close);
        }
      

 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.anCountService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          if (inOutKey == 1 || inOutKey == -1) {
            //fdh878678
          //  this.load();
            this.openDeedSanad(inOutKey);
          }
          this.OnMySaveEvent.emit(close);
        }

 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


  //  e.preventDefault();
  }

  firstload: boolean = true;
  anGarchanged(e) {
    //87987o87ukolui

    if (this.editId>0 &&   e.previousValue == null && this.firstload) {
      this.firstload = false;
      return;
    }

    this.myModel.anCountRows = [];
    this.myModel.anGarId = null;
    for (var r of this.anGarList) {
      if (r.id == e.value) {

        this.myModel.anGarId = r.id;
        this.myModel.anGar = r;
      }
    }

    if (this.myModel.anGarId == null)
      return;

    this.refleshDiisable(this.myModel.anGar.num);

    this.loadServ.showloading(this.loaddata, 2);
    this.anCountService.CreateRow(this.myModel.anGarId).subscribe(
      (res) => {
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.myModel.anCountRows = res.data;

         // this.gridDataSource = this.myModel.AnCountRows;

          //this.gridDataSource = new DataSource({
          //  store: this.myModel.AnCountRows,


          //});
          if (this.numselected == 0)
            this.numselected = 1;


          this.onNumbutton(this.numselected);
          //this.gridDataSource.filter([
          //  ["amtMojudi", ">", 0]
       
          //]);
        }
        this.loadServ.hideloading(this.loaddata, 2);
     
      //  this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 2);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


  }
  lockCSS1: string = '';
  lockCSS2: string = '';
  lockCSS3: string = '';
  refleshDiisable(num :number) {
    this.lockCSS1 = 'mydisble';
    this.lockCSS2 = 'mydisble';
    this.lockCSS3 = 'mydisble';
    if (num == 1) {
      this.lockCSS1 = '';
    }
     
    if (num == 2) {
      this.lockCSS1 = '';
      this.lockCSS2 = '';
    }
    if (num == 3) {
      this.lockCSS1 = '';
      this.lockCSS2 = '';
      this.lockCSS3 = '';
    }
  }
  lockSanadCSS: string = '';

  refleshSanadCSS() {
    this.lockSanadCSS = 'mydisble';

    if (this.myModel.anGarId >0 )
    if (this.myModel.numDone == this.myModel.anGar.num) {
      this.lockSanadCSS = '';
    }
  
   
  }
  loadRows(e) {




  }
  checkBoxToggled(e) {
    this.onNumbutton(this.numselected);
  }
  numselected = 0;
  activeCSS1 = '';
  activeCSS2 = '';
  activeCSS3 = '';
  onNumbutton(e) {

    this.numselected = e;

    this.activeCSS1 = '';
    this.activeCSS2 = '';
    this.activeCSS3 = '';
   // let y = this.myModel.AnCountRows;
    ///gyhui678679679

    
    if (this.numselected == 1) {
      this.activeCSS1 = ' active';
      if (this.showsefr)
        this.gridDataSource = this.myModel.anCountRows;
      else
        this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi > 0);
     // this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi >0);
    }
    else if (this.numselected == 2) {
      this.activeCSS2 = ' active';
      if (this.showsefr)
        this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtCount1 != x.amtMojudi);
      else
      this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi > 0  && x.amtCount1 != x.amtMojudi);
     // this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi > 0 && x.amtCount1 != x.amtMojudi);
    }
    else if (this.numselected == 3) {
      this.activeCSS3 = ' active';
      if (this.showsefr)
        this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtCount2 != x.amtMojudi);
      else
      this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi > 0 && x.amtCount2 != x.amtMojudi );
     // this.gridDataSource = this.myModel.anCountRows.filter(x => x.amtMojudi > 0 && x.amtCount1 != x.amtMojudi && x.amtCount2 != x.amtCount1);
    }

    //  new DataSource({
    //  store: this.myModel.AnCountRows,
    //  key:'id'

    //});
 
    //if (this.numselected == 1) {
    //  this.gridDataSource.filter([
    //    ["amtMojudi", ">", 0]

    //  ]);

    //}
    //if (this.numselected == 2) {
    //  this.gridDataSource.filter([
    //    ["amtMojudi", ">", 0],
    //    "and",
    //    ["amtCount1", ">", "amtMojudi"]
    //  ]);

    //}

  }
  onDonebutton(num:number , vaz:boolean) {

    if (this.myModel.anDeedInId > 0 || this.myModel.anDeedOutId >0) {
      this.alertify.warning('سند تعدادی صادر شده');
      this.alertify.warning('امکان تغییر مقادیر وجود ندارد');
      return;
    }


    if (vaz) {
      if (this.myModel.numDone != (num - 1)) {
        this.alertify.message('مرحله قبل تایید نشده');
        return;
      }
    }
    else {
      if (this.myModel.numDone >= (num + 1)) {
        this.alertify.message('مرحله بعد لغو تایید نشده');
        return;
      }
    }

    if(vaz)
      this.myModel.numDone = num;
    else
      this.myModel.numDone =( num -1);


    this.refleshSanadCSS();
  }
  onFillbutton() {

    if (this.myModel.vaz == 2) {
      this.alertify.warning('شمارش به اتمام رسیده');
      this.alertify.warning('امکان تغییر مقادیر وجود ندارد');
      return;
    }

 

    if (this.numselected == 1)
    for (var r of this.myModel.anCountRows) {
      r.amtCount1 = r.amtMojudi;
    }
    if (this.numselected == 2)
      for (var r of this.myModel.anCountRows) {
        r.amtCount2 = r.amtMojudi;
      }
    if (this.numselected == 3)
      for (var r of this.myModel.anCountRows) {
        r.amtCount3 = r.amtMojudi;
      }
   

  }
  onClearbutton() {

    if (this.myModel.vaz == 2) {
      this.alertify.warning('شمارش به اتمام رسیده');
      this.alertify.warning('امکان تغییر مقادیر وجود ندارد');
      return;
    }



    this.openClearDialog();



  

  }

  openClearDialog(): void {

    

    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {

      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.doClear();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  doClear() {


    if (this.numselected == 1)
      for (var r of this.myModel.anCountRows) {
        r.amtCount1 = 0;
      }
    if (this.numselected == 2)
      for (var r of this.myModel.anCountRows) {
        r.amtCount2 = 0;
      }
    if (this.numselected == 3)
      for (var r of this.myModel.anCountRows) {
        r.amtCount3 = 0;
      }

  }





  onValueChangedAmt1(e, cellInfo) {
    cellInfo.data.amtCount1 = e.value; 
  }
  onValueChangedAmt2(e, cellInfo) {
    cellInfo.data.amtCount2 = e.value;
  }
  onValueChangedAmt3(e, cellInfo) {
    cellInfo.data.amtCount3 = e.value;
  }




  onCloseDeed() {
    this.opensanad = false;
  }
  inOut: number ;
  onDeedbutton(value: number) {

    //ytry657yy567
    this.onFormSubmit(null, false, value);
 


  }
  openDeedSanad(inOutKey : number) {

    if (this.myModel.vaz != 2) {
      this.alertify.warning('تمام مراحل شمارش تایید نشده');
      return;
    }

    this.inOut = inOutKey;
    this.opensanad = true;
  }



  onSaveDeed(e) {

    //if (e) {

      this.load();
      this.OnMySaveEvent.emit(false);
  //  }

  }





  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
 
  onClickExportexcel() {
    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' شمارش ' + this.numselected.toString() +' ' + this.myModel.anGar.name);
 
  }

  onClickprint(kind) {

    this.myModel.anGar.anbarN
    var mdata = { model: JSON.parse(JSON.stringify(this.myModel)), printKind: kind, numselected : this.numselected}
    mdata.model.anCountRows = JSON.parse(JSON.stringify(this.gridDataSource));
    this.onPrint.emit(mdata);

  }
}
