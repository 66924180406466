import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../_models/pagination';

import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { SaleMaliService } from '../../../_services/General/SaleMali.service';
import { OstanKol } from '../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../_models/app1/paye/Ostan';
import { SaleMali } from '../../../_models/General/saleMali';
import { mySF } from '../../../../../my_modules/mySF';
import { DialogvalidComponent } from '../../../dialogvalid/dialogvalid.component';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../dialog/deldialog.component';
 
 
 

@Component({
  selector: 'app-SaleMali-edit',
  templateUrl: './SaleMali-edit.component.html',
  styleUrls: ['./SaleMali-edit.component.css']
})
export class SaleMaliEditComponent implements OnInit {

 
 
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() cancelRegister = new EventEmitter();
 
 myModel: SaleMali;

  shkhscodelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
   
  id: number;
  private sub: any;
  test: string;
    n: number;
  mytitle: string;
  modlelist: SaleMali[];
  OstanKollist: OstanKol[];
  Ostanlist: Ostan[];
  editclass: string;

  constructor(private userService: UserService, private alertify: AlertifyService,
    private router: Router,
    private SaleMaliService: SaleMaliService,
    private tafsiliService: TafsiliService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private sf: mySF,
  ) {

    this.modlelist = [];
    this.validationCallback = this.validationCallback.bind(this);  
    this.myModel = this.SaleMaliService.getSaleMaliEmpty();
    this.editclass = 'mydisble';
    this.rules = { "X": /[02-9]/ };
  }

  ngOnInit() {
   
 

 
 
    this.isnew = true;
  


    this.test = "j";
    this.rules = { "X": /[02-9]/ };




    this.reloadlist();
  }

  onFocusedRowChanged(e) {



  }
  addthis = e => {


  }
  cancelthis = e => {


  }

  reloadlist() {


    this.SaleMaliService.getSaleMalis()
      .subscribe((res: SaleMali[]) => {
        this.modlelist = res;
      }, error => {
        this.alertify.error(error);
      });


  }


  onFormSubmit = function (e) {
 
    console.log("m:submitform");

 
    console.log(JSON.stringify(this.myModel));
  //  console.log(val); // prints values: 10, 20, 30, 40
    if (this.myModel.id == -1) {
      this.SaleMaliService.addSaleMali(this.myModel).subscribe(next => {
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");

        this.router.navigate(['/app1/paye/SaleMali']);

      }, error => {
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
      //this.myModel.tafsilisId.name = this.myModel.nameFarsi + ' ' + this.myModel.familyFarsi;
      //this.myModel.tafsilisId.nameE = this.myModel.nameEnglish + ' ' + this.myModel.familyEnglish;

      this.SaleMaliService.updateSaleMali(this.myModel).subscribe(next => {
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");

        this.router.navigate(['/app1/paye/SaleMali']);

      }, error => {
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }


  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;
    this.ostanService.getOstans(ostankolcode).subscribe(data => {

      this.Ostanlist = data;
      //console.log(data);
    });

  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
  

  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    return true

    let v = e.value;


    for (var val of this.shkhscodelist) {

      if (v == val) return false;
    }
    return true



}


  validateNumber(e) {

    

  }

  numberBox_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

   // if (this.myModel.text == null || this.myModel.text == '')
    this.myModel.text = 'سال مالی ' + ' ' + newValue.toString();

   // this.myModel.dateS = parseInt(newValue.toString() + '0101');
   // this.myModel.dateE = parseInt(newValue.toString()+'1229');
    this.myModel.dateS_s = newValue.toString() + '/01/01';
    this.myModel.dateE_s = newValue.toString() + '/12/29';
    
     
  }
  
  datestart_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log(newValue);

    let t =  this.sf.calcOtherThing(2);

 
  
   console.log(t);
 
  }

  onCancel() {


    this.myModel = this.SaleMaliService.getSaleMaliEmpty();
    this.editclass = 'mydisble';


  }


  

  onClickNew() {

    this.isnew = true;
    this.myModel = this.SaleMaliService.getSaleMaliEmpty();
    this.editclass = 'myenable';


    let max = 1000; 
    for (var i = 0; i < this.modlelist.length; i++) {

      console.log(this.modlelist[i].codeSal);
      if (this.modlelist[i].codeSal > max) {

        max = this.modlelist[i].codeSal;

      }

    }
    max = max + 1;
    this.myModel.codeSal = max;

  }
  onSaveEdit() {



    this.SaleMaliService.addSaleMali(this.myModel).subscribe(data => {

      this.myModel = this.SaleMaliService.getSaleMaliEmpty();
      this.editclass = 'mydisble';

      this.reloadlist();
    });



  }

  onEditrow(info) {


    this.myModel = info.data;
    this.isnew = false;
    this.editclass = 'myenable';
 
  }
  onDeleterow(info) {


 
    this.openDialog(info.data.id);
  }

  openDialog(id): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete(id);

      }

    });
  }

  dodelete(id: number) {

    this.SaleMaliService.deleteSaleMali(id).subscribe(data => {
      let dd = data;
      // 0  وجود ندارد
      // 1  پاک شد
      // 2  اجازه پاک شدن ندارد
      if (dd == 1)
        this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

      if (dd == 2)
        this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

      if (dd == 0)
        this.alertify.warning('این کد در سرور یافت نشد');
      console.log("m:deleted successful");
      this.reloadlist();

    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }

}

class MyStaticFunction2 {
  static pi = 3.14;

  static ChekExistDate(radius: string) {
    return true;
  }


  static calculateArea(radius: number) {
    return this.pi * radius * radius;
  }

  calculateCircumference(radius: number): number {
    return 2;
  }





}
