import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { resolveAny } from 'dns';
import { User, UserForEdit } from '../../_models/user';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { Localst } from '../../_services/localst.service';

 


@Component({
  selector: 'appsetting-changpass',
  templateUrl: './changpass.component.html',
  styleUrls: ['./changpass.component.css']
})

export class ChangpassComponent implements OnInit {

   

  nowuser: User;
  usernewchang: UserForEdit;

  model: any = {};
  changres: any;


  constructor(
    private alertify: AlertifyService,
    private authService: AuthService,
    private localst: Localst,
    private router: Router) {
    this.nowuser = this.localst.usernow_get();
    this.usernewchang = Object.assign(this.nowuser);
    this.usernewchang.oldpass = null;
    this.usernewchang.newpass1 = null;
    this.usernewchang.newpass2 = null;
    console.log('hhh');
    console.log(JSON.stringify(this.usernewchang));
  }

  ngOnInit() {



  }

  onSaveEdit() {

 

  }
  onFormSubmit() {


    console.log('saveeee');
    console.log(JSON.stringify(this.usernewchang));

    this.authService.changpass(this.usernewchang).subscribe(next => {
      this.changres = next;
      this.alertify.success(this.changres.value);
      this.usernewchang.oldpass = null;
      this.usernewchang.newpass1 = null;
      this.usernewchang.newpass2 = null;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  passwordComparison = () => {
    return this.usernewchang.newpass1;
  };
}
