import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Sanad } from '../../../_models/app1/opr/Sanad';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { of } from 'rxjs';
import { IdName } from '../../../_models/General/IdName';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptySanad: Sanad = {

  id: -1,
  cShomare: -1,
  cRozane: -1,
  cAtf: -1,
  cFare: -1,
  date: 0,
  kindId: -1,
  kindN: null,
  kind2Id: -1,
  kind2N: null,
  dis: '',
  dateTime: null,
  userNLog: null,
  userFLog: null,
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  years: null,
  costSBed: 0,
  costSBes: 0,
  costSEkh: 0,
  sabts: null,
  date_String: '13990101',
  vaziat: -1,
  isSys: null,
  _isSysN: null,

  vaziatN: 'جدید',

};
let HeaderSanad: any = {

  id: '',
  cShomare: 'شماره سند',
  cRozane: 'شماره روزانه',
  cAtf: 'شماره عطف',
  cFare: 'شماره فرعی',
  date: 'تاریخ',

  kindId: 'نوع',

  kindN: 'نوع',


  dis: 'توضیحات',


  dateTime: null,
  userId: '',
  usernNameLog: 'کاربر',
  nameLog: null,
  years: -1,
  sabts: null

};

 
 
 
 
@Injectable({
  providedIn: 'root'
})
export class SanadService {
  origin = location.origin;
 
  kindlist: IdName[] = [{ id: 100, name: 'عمومی' },
  { id: 200, name: 'تعدیل ماهیت اخر دوره' },
    { id: 300, name: 'بستن حسابهای موقت' },
    { id: 400, name: 'اختتامیه' },
    { id: 500, name: 'افتتاحیه' },
    { id: 600, name: 'تعدیل ماهیت اول دوره' }

  ]


  vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  { id: 2, name: 'موقت' },
  { id: 3, name: 'بررسی شده' },
  { id: 4, name: 'قطعی شده' }

  ]

  constructor(private http: HttpClient, private localst: Localst, private localstService: Localst) {



  }
 
  getKind(id : number): IdName {

    for (var _i = 0; _i < this.kindlist.length; _i++) {
      if (this.kindlist[_i].id == id) {
        return this.kindlist[_i];
      }

   
    }
    return null;
  }
  getKinds(): IdName[] {

    return JSON.parse(JSON.stringify(this.kindlist));

  }



  getVaziat(id: number): IdName {

    for (var _i = 0; _i < this.vaziat.length; _i++) {
      if (this.vaziat[_i].id == id) {
        return this.vaziat[_i];
      }
    }
    return null;
  }
  getVaziats(): IdName[] {

    return JSON.parse(JSON.stringify(this.vaziat));

  }


  getSanads(year: number, dA: string, dB: string): Observable<Sanad[]> {

    let apiadress = '/api/App1/Opr/Sanad/getallnopage?year=' + year.toString() + '&DateA=' + dA + '&DateB=' + dB;
    var headers = this.localstService.header_get(apiadress,null);

   // console.log(apiadress);
    return this.http.get<Sanad[]>(this.origin + apiadress, { 'headers': headers });
  }
 

  getSanad_P(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Sanad[]>> {

    let apiadress = '/api/App1/Opr/Sanad/getall';
    var headers = this.localstService.header_get(apiadress,null);


    const paginatedResult: PaginatedResult<Sanad[]> = new PaginatedResult<Sanad[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (userParams != null) {
      params = params.append('Name', userParams.Name);
      params = params.append('Code', userParams.Code); 

    }
 
    return this.http.get<Sanad[]>(this.origin + apiadress, { 'headers': headers , observe: 'response', params })
      .pipe(
        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
      );

    //for (var val of getlist) {

    //  console.log(val); // prints values: 10, 20, 30, 40
    //}
    // console.log(getlist[0].Onvan);


  }

  
 

 
  getSanad( id : number): Observable<Sanad> {

    let apiadress = '/api/App1/Opr/Sanad/getSanad?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    if (id == -1) return of(this.getSanadEmpty());


    let v = this.http.get<Sanad>(this.origin + apiadress, { 'headers': headers }).pipe(); 
    return v;
  }


  getSanadshift(id: number, year: number, shift: number): Observable<Sanad> {

    let apiadress = '/api/App1/Opr/Sanad/getSanadbyshift?year=' + year + '&shift=' + shift + '&id=' + id;
    var headers = this.localstService.header_get(apiadress,null);
    //if (id == -1) return of(this.getSanadEmpty());
     
 
    let v = this.http.get<Sanad>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }

  addSanad(item: Sanad): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/Sanad/addone?un=' + this.localst.usernow_get().username;
    var headers = this.localstService.header_get(apiadress,null);
 

    return this.http.post<ApiRet>(this.origin + apiadress,item, { 'headers': headers });
  }


  DeleteSanads(item: number[]): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/Sanad/deleteSanads?un=' + this.localst.usernow_get().username;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item,{ 'headers': headers });
  }
 refleshSanad(year: number) {
   let apiadress = '/api/App1/Opr/Sanad/refleshsanadcode?year=' + year.toString();
   var headers = this.localstService.header_get(apiadress,null);

 

   return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  getNewSanadEmpty(empts: Sanad, takenowdate : boolean)  {
    let apiadress = '/api/App1/Opr/Sanad/newsanad?takenowdate=' + takenowdate;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.post(this.origin + apiadress, empts, { 'headers': headers } );
  }

  getSanadEmpty(): Sanad {

    
    return JSON.parse(JSON.stringify(EmptySanad));


  }
  getSanadHeader(): Sanad {

    return HeaderSanad;


  }
  updateSanad(item: Sanad) :Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/Sanad/updateSanad?un=' + this.localst.usernow_get().username;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.post<ApiRet>(this.origin + apiadress,item,{ 'headers': headers });
  }
  updateVaziat(ids: number[], vc: number, vn: string, isSuperAdmin: boolean) {
    let apiadress = '/api/App1/Opr/Sanad/setvaziat?vc=' + vc.toString() + '&vn=' + vn.toString() + '&isSuperAdmin=' + isSuperAdmin.toString() ;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, ids, { 'headers': headers });
  }

 

  deleteSanadAndChild(SanadId: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/Sanad/deleteSanad?id=' + SanadId.toString();
    var headers = this.localstService.header_get(apiadress,null);

  
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Sanad/countSanadcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  SanadListCode(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Sanad/Sanadcodelist';
    var headers = this.localstService.header_get(apiadress,null);

   
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }



  getFirstsanad(year: number, date: string): Observable<number>  {

    let apiadress = '/api/App1/Opr/Sanad/getfirstCodeSanad?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress,null);
 

    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getFirstDate(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App1/Opr/Sanad/getfirstDateSanad?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getLastsanad(year: number, date: string): Observable<number> {

    let apiadress = '/api/App1/Opr/Sanad/getlastCodeSanad?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }

  getLastDate(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App1/Opr/Sanad/getlastDateSanad?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }
  reportHt_SanadsPrint(item: any) {
    let apiadress = '/api/App1/Opr/Sanad/report_SanadsPrint';
    var headers = this.localstService.header_get(apiadress,null);

   // console.log( item);
    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  duplcateSanad(id: number , date : string) :Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/Sanad/AutoSanadDuplcate?id=' + id + '&date=' + date;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, null, { 'headers': headers });
  }

  mergeSanad(option: any) {
    let apiadress = '/api/App1/Opr/Sanad/AutoSanadMerge';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, option, { 'headers': headers });
  }

  checkReadedExcelSanad(data:any) {
    let apiadress = '/api/App1/Opr/Sanad/checkReadedExcelSanad';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, data, { 'headers': headers });
  }
  solimaniReadedExcelSanad(data: any) : Observable <ApiRet>{
    let apiadress = '/api/App1/Opr/Sanad/solimaniReadedExcelSanad';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }
  countSanadOnKind(year: number, kind: number): Observable<number>  {
    let apiadress = '/api/App1/Opr/Sanad/CountSanadOnKind?year=' + year.toString() + '&kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
}
