import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MatSidenav, MatTab, MatTabGroup } from '@angular/material';
import { SaleMaliService } from '../_services/General/SaleMali.service';
import { SaleMali } from '../_models/General/saleMali';
import { Localst } from '../_services/localst.service';
import { MenurowService } from '../_services/General/menurow.service';
import { Customer } from '../_models/customer';
import { GeneralService } from '../_services/General/General.service';
import { MyTimeService } from '../_services/myTime.service';
import { LoadingService } from '../_services/loading.service';
import { DxLookupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { LogcliService } from '../_services/Logcli.service';

import { AccService } from '../_services/General/Acc.service';
import { RegistryService } from '../_services/appsetting/Registry.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']

})
export class NavComponent implements OnInit {
  @ViewChild("dataso", { static: false }) dataso: DxLookupComponent;
   
  //@Output() OnSaleChang = new EventEmitter<number>();
  @ViewChild('menush', { static: false }) menu: ElementRef;
 
  //@ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  appShakhzedit = false;
  
  visiblenenu: boolean;
 
  lodermessage: string;
  menutree: any;
 
  islogined: boolean = false;
  salemalilist: SaleMali[];
  salemalinow: number;
  customerList: Customer[];
  dbname: string;
  loaddata: loadingCl;
  model: any = {};
  timeourerror: boolean;
  manulist: any;
  photoUrl: string;
  datepersian: string;
  loginename: string;
  isNoteOpen: boolean = false;
  cu: string;
  un: string;
  constructor(public authService: AuthService, private alertify: AlertifyService,
    private localst: Localst, 
    private logcliServic: LogcliService,
    private generalService: GeneralService,
    private menurowService: MenurowService,
    private saleMaliService: SaleMaliService,
    private registryService: RegistryService,
    private accService: AccService,
 
    private myTimeService: MyTimeService,
    private loadServ: LoadingService,
    private router: Router) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 10);
    this.cu = "";
    this.un = "";
    router.events.forEach((event) => {
        //موقع تغییر روتر صدا زده میشه
      if (event instanceof NavigationStart) {
        console.log("NavigationStart");

        console.log(JSON.stringify(event));

        

        if (event.url == '/home' || event.url == '/') {


          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.authService.decodedToken = null;
          this.authService.currentUser = null;

          //  this.islogined = this.authService.loggedIn();


        }
        else if (event.url == '/maintab' && this.localst.usernow_get().customerName == 'tashrifat') {

          this.router.navigate(['/sectab']);


        }
        else if (false){

         // this.router.navigate(['/home']);


        }



        if (event.url == '/maintab') {


          //وقتی لوگین شد نام یوزر و.. گرفته شده و میتوان لیست شرکت ها را گرفت
          this.loggedIn();

       

        }
        this.islogined = authService.loggedIn();

        if (!this.islogined) {
          this.isOpenMenush = false;
       //   this.router.navigate(['/home']);
        }
      
        //if (this.islogined == false) {

        //  this.logout();
        //}


       


      }
      if (event instanceof NavigationEnd) {
        console.log("NavigationEnd");
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        console.log(event.error);
      }
    });

    this.loginename = "";
    this.datepersian = '0000/00/00';

    this.lodermessage = 'درحال بارگذاری تنظیمات';
    this.customerList = [];
    this.salemalinow = 0;

    this.appShakhzedit = false;
    this.salemalinow = 0;
    this.localst.sherkatInfo_get();
  }


  fdfdh() {


  }



  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.ctrlKey == true && event.altKey == true)
      if (event.key == 'T' || event.key == 't')
        window.open(location.origin + '/plugin/tetris',"_blank");

  }




  showUpd: boolean = false;
  textUpd: string = '';
  newversion: string = '';


  ngOnInit() {

  
  

  





    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
    //this.loadServ.showloading(this.loaddata, 9);
    setInterval(() => {
      this.logcliServic.TestCon(this.cu,this.un).subscribe(res => {
          console.clear();
        console.log('connection ok');




        this.textUpd = res.data;
        this.newversion = res.value;
        if (this.newversion != this.localst.version) {
          this.showUpd = true;
        }




     //   this.loadServ.hideloading(this.loaddata, 9);
      }, error => {
      //  this.loadServ.hideloading(this.loaddata, 9);
        console.log('connection error');
      });
      // console.log('ooo');
  }, 100000);
    if (this.loggedIn()) {
      //if (this.localst.dateEmruz == null)
      //  this.getsherkats();

    }
    else {
      //نوار بالا که نمیاد بره بیرون
  
        this.router.navigate(['/home']);
   
    }


  
  }
  onClickreflesh() {

    location.reload();
  }
  customerchang(e) {

    if (e.value === null) {
      this.dataso.value = e.previousValue;
      return;
    }
    if (e.value == null)
      return;

    for (var i = 0; i < this.customerList.length; i++) {

      if (this.customerList[i].dbaseName == e.value) {

        this.dbname = this.customerList[i].dbaseName;
        this.localst.sherkatsList_selected_set(this.customerList[i]);

      
  


        this.loadServ.showloading(this.loaddata, 3);
        this.generalService.getSherkatInfo().subscribe(data => {
          this.loadServ.hideloading(this.loaddata, 3);
          this.localst.sherkatInfo_set(data);
         
         // console.log(this.localst.sherkatInfo_get());
         //tyfu686555
          this.salmalll(e.value);
      
          if (this.router.url != '/maintab') {
            this.router.navigate(['/maintab']);
          }
         
        }, error => {
          var m = this.generalService.getSherkatInfo_null();
          this.localst.sherkatInfo_set(m);
          //this.timeourerror = true;
          this.loadServ.hideloading(this.loaddata, 3);
          this.lodermessage = 'ارتباط با دیتابیس بر قرار نمیباشد';
          this.alertify.error(this.lodermessage);

        });




      }

    }

  }

  //serverVersion = 



  salmalll(selectedSherkat: string) {

    let sallast = this.localst.lastSelectedSalDB_get(selectedSherkat);



    this.loadServ.showloading(this.loaddata, 4);
    this.saleMaliService.getSaleMalis().subscribe(data => {

      this.loadServ.hideloading(this.loaddata, 4);
      this.salemalinow = this.localst.yearnow_set(this.saleMaliService.getSaleMaliEmpty());
      this.salemalilist = data;

     

      if (this.salemalilist != null)
        if (this.salemalilist.length > 0) {

          let donee = false;
          for (var r of this.salemalilist) {
            if (r.codeSal == sallast) {
              donee = true;
              this.salemalinow = this.localst.yearnow_set(r);
            }


          }
          //اگه در هیستوری سال ها چیزی نبود اولی را انتخاب میکنه
          if (!donee) {
            this.salemalinow = this.localst.yearnow_set(this.salemalilist[this.salemalilist.length - 1]);
            this.localst.lastSelectedSalDB_set(selectedSherkat, this.salemalinow);
          }

        }


      


      this.salemalinow = this.localst.yearnow_get();

      console.log(this.salemalinow);



    }, error => {

      //this.timeourerror = true;
      this.loadServ.hideloading(this.loaddata, 4);
      this.lodermessage = 'سال مالی';
      this.alertify.error(this.lodermessage);

    });



    this.localst.OnMySalshChanged.emit(true);
  }

  loggedIn() {
    //tyuru8568568
    //const token = localStorage.getItem('token');
    //  return !!token;
    let a = this.authService.loggedIn();
    if (a == true) {
      //???????????????
      this.getsherkats();
   
      this.loginename = this.localst.usernow_get().name;
      this.cu = this.localst.usernow_get().customerName;
      this.un = this.localst.usernow_get().username;
    }

    return a;
  }

  getsherkats() {
    var user = this.localst.usernow_get();
    this.loadServ.showloading(this.loaddata, 5);
    this.authService.GetSherkatlist(user.username, user.customerName).subscribe(data => {
      console.log('sherkat list loaded in successfully');
      this.loadServ.hideloading(this.loaddata, 5);
      var l = data as Customer[];
      this.localst.sherkatsList_set(l);
      this.load2();
      this.readacses();

    }, error => {
      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 5);
    }, () => {


    });

 
  }


  readacses() {
 
 
  }

  loadRegistry() {

    this.loadServ.showloading(this.loaddata, 12);
    this.registryService.getall(this.localst.yearnow_get()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 12);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      } 
      else {
        this.localst.bindRegistry(res.data);
      }

    }, error => {
      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 12);
    }, () => {


    });


  }

  load2() {

    //this.loadServ.showloading(this.loaddata, 8);
    //this.myTimeService.GetPersianNow().subscribe(data => {
    //  this.loadServ.hideloading(this.loaddata, 8);
   
    //  this.localst.dateEmruz = data;
     
    //}, error => {
    //  this.alertify.error(this.lodermessage);
    //  this.loadServ.hideloading(this.loaddata, 8);
    //});


    this.localst.set_nowtimeEmruz(this.myTimeService.GetEmptyPersianNow());
    this.loadServ.showloading(this.loaddata, 7);
    //خواندن تاریخ روز
    this.myTimeService.GetPersianNow().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 7);


      this.localst.set_nowtimeEmruz(data);
      this.datepersian = this.localst.dateEmruz.dateString;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(this.lodermessage);

    });

    //گرفتن لیست شرکت ها
    this.customerList = this.localst.sherkatsList_get();


    let dbn = this.localst.sherkatsList_selected_get().dbaseName;
    let usn = this.localst.sherkatsList_selected_get().userName;
    this.dbname = null;
     
    for (var i = 0; i < this.customerList.length; i++) {
      //??????????????????
      if (this.customerList[i].dbaseName == dbn && this.customerList[i].userName == usn) {
        //چک میکنیم چون شاید یوزر عوض بشه و به دیتابیس درترسی نداشته باشه

        this.dbname = dbn;
       // this.localst.sherkatInfo_get(gh);
      }

    }
    if (this.dbname == null) {

      if (this.customerList.length > 0) {
        this.dbname = this.customerList[0].dbaseName;
        this.localst.sherkatsList_selected_set(this.customerList[0]);
        this.loadServ.hideloading(this.loaddata, 0);
      }
      else
        this.localst.sherkatsList_selected_set(null);

    }


    this.timeourerror = false;
    this.loadServ.showloading(this.loaddata, 0);
    this.generalService.getSherkatInfo().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.localst.sherkatInfo_set(data);
      console.log(this.localst.sherkatInfo_get());

    }, error => {

    var m=  this.generalService.getSherkatInfo_null();
      this.localst.sherkatInfo_set(m);
      //this.timeourerror = true;
      this.loadServ.hideloading(this.loaddata, 0);
      this.lodermessage = 'ارتباط با دیتابیس بر قرار نمیباشد';
      this.alertify.error(this.lodermessage);

    });

    this.loadServ.showloading(this.loaddata, 1);
    //خواندن سال مالی
    this.saleMaliService.getSaleMalis().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.salemalilist = data;

    

      this.salemalinow = this.localst.yearnow_get();

      console.log(this.dbname);

      let exist = false;
      for (var i = 0; i < this.salemalilist.length; i++) {

        if (this.salemalilist[i].codeSal == this.salemalinow) {
          this.localst.yearnow_set(this.salemalilist[i]);
          exist = true;
        }
          
      }
      if (!exist) {

        if (this.salemalilist.length > 0)
          this.localst.yearnow_set(this.salemalilist[this.salemalilist.length -1]);

      }

   

      if (this.dbname == 'tzcu2dadghos')
        this.Paziresh_just(this.salemalinow);

      console.log(this.salemalinow);

      if (this.salemalinow == null) {
        if (this.salemalilist.length > 0)
          this.salemalinow = this.salemalilist[0].codeSal;
      }
      //this.salmalll();
    

      this.loadRegistry();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(this.lodermessage);

    });



    
  }



  logout() {
    //با رفلش صفحه از بین میرود
   // this.islogined = this.localst.setinlogin(false);


    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
      this.alertify.message('logged out');
    this.router.navigate(['/home']);

    this.islogined  = this.authService.loggedIn();
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  test2() {
    this.islogined = this.authService.loggedIn();
  }



  test() {

    console.log('test');
    if (this.appShakhzedit == true) {
      this.appShakhzedit = false;
      return;
    }
    


    if (this.appShakhzedit == false)
      this.appShakhzedit = true;


    //this.router.navigate(['/app1/paye/Sherkat', { outlets: { 'aaa': ['none'] } }]);
  }

  ///??????????  رویداد جهانی
  salemalichang(e) {

    if (e.value == null)
      return;



    if (this.localst.yearnow_get() != e.value) {

      this.saleMaliService.OnGlobalSalemaliChang(e.value);
      console.log('trig');
    
     // window.location.reload();
    }

    //اخرینم سال مالی انتخاب شده هر دیتا بیس را ذخیره میکند
    this.localst.lastSelectedSalDB_set(this.dbname ,e.value);

    this.saleMaliService.getSaleMaliBycodesal(e.value).subscribe(data => {

      var saleMali = data;

      this.localst.yearnow_set(saleMali);
      this.loadRegistry();

    }, error => {

      console.log("my error: " + error)
    });
 

    console.log( 'onsale chang by ' +  this.localst.yearnow_get());
   // this.OnSaleChang.emit(this.localst.yearnow_get());

    this.localst.OnMySalshChanged.emit(true);
  }
  gotoaccount() {


    this.router.navigate(['/Accounts']);

  }
   TarazSimin() {
     this.router.navigate(['/maintab']);
   }



  Paziresh_just(years: number) {

    console.log('paziresh refresh going');
   this.loadServ.showloading(this.loaddata, 6);
    this.generalService.Paziresh_reflesh(years).subscribe(data => {
     this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.message("اسناد پذیرش باموفقیت بروزرسانی شد");
      console.log('paziresh refresh done:');
      console.log(data);



    }, error => {
      this.alertify.error(error);
     this.loadServ.hideloading(this.loaddata, 6);
    });


  }
  isOpenMenush = false;
  mouseEnter() {
    if (!this.isOpenMenush)
      this.isOpenMenush = true;
 
  }

  closemenush() {

    this.isOpenMenush = false;;

  }


  notes() {

    this.isNoteOpen = !this.isNoteOpen;


  }
  closenote() {

    this.isNoteOpen = false;
  }
}
