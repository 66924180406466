import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { GrKala, GrmaskOption } from '../../../_models/app2/paye/GrKala';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyGrKala: GrKala = {


  id: null,

  treeId: null,

  pTreeId: null,

  codeA: '',
  code: '',
  codeC: '',
  
 

  name: null,

  isLast: true,
  level: 0,
  _sumName:null
 

};
let EmptyGrmaskOption: GrmaskOption = {

  levels: [],
  levelNum: 0,
  pattern: '',
  cntStart: 0,
  cntMax: 0,
  cntleg:0,
  cntPattern: '0',

  leg: 0,
  freeleg: false,
  kind: 0,
  kind2: 1


};
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class GrKalaService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<GrKala[]> {
    let apiadress = '/api/App2/Paye/GrKala/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<GrKala[]>(this.origin + apiadress, { 'headers': headers });
  }

 

 
  getbyid(id): Observable<GrKala> {
    let apiadress = '/api/App2/Paye/GrKala/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<GrKala>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: GrKala) {
    let apiadress = '/api/App2/Paye/GrKala/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<GrKala>(this.origin + apiadress, item, { 'headers': headers });
  }



  getGrKalaEmpty(): GrKala {

 
    return JSON.parse(JSON.stringify(EmptyGrKala));

  }
  getGrmaskOptionEmpty(): GrmaskOption {


    return JSON.parse(JSON.stringify(EmptyGrmaskOption));

  }
  update(item: GrKala): Observable<ApiRet>  {
    let apiadress = '/api/App2/Paye/GrKala/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

 
  delete(GrKalaId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/GrKala/delete?id=' + GrKalaId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallName(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/GrKala/getallName';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
  updatePattern(item: GrmaskOption) {
    let apiadress = '/api/App2/Paye/GrKala/updatePattern';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<any>(this.origin + apiadress, item, { 'headers': headers });
  }
  readPatternString(): Observable<any> {
    let apiadress = '/api/App2/Paye/GrKala/readPatternString';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }
  readPattern(): Observable<GrmaskOption> {
    let apiadress = '/api/App2/Paye/GrKala/readPattern';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<GrmaskOption>(this.origin + apiadress, { 'headers': headers });
  }

  nextcode(grId: number): Observable<string> {

  

      let apiadress = '/api/App2/Paye/GrKala/readNextCode?grId=' + grId.toString();
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<string>(this.origin + apiadress, { 'headers': headers });
  }

  getallCode(): Observable<string[]> {

    let apiadress = '/api/App2/Paye/GrKala/getallCode';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


}
