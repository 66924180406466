import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { Sanad } from '../../../../_models/app1/opr/sanad';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
 
import { Localst } from '../../../../_services/localst.service';
import { DxTextBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-Sanad-dublcate',
  templateUrl: './Sanad-dublcate.component.html',
  styleUrls: ['./Sanad-dublcate.component.css']
})
export class SanaddublcateComponent implements OnInit {
  
  @ViewChild("txbox", { static: false }) txbox: DxTextBoxComponent;

  @Input() option: any;

  

  @Output() onDuplcate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDateChanged: EventEmitter<number> = new EventEmitter<number>();

  buttcop: boolean;
  buttsave: boolean;

  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private localst: Localst,
  ) {

    let y = localst.yearnow_get();

 //   this.option = { year: y, levelDetail: 6, cShS: 1, cShE: 1 };

    this.buttcop = true;
    this.buttsave = false;
  

  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   
      if (event.keyCode == 13) {

        if (this.buttcop) {
          this.buttcopclick();
        }
        else if (this.buttsave) {
          this.creteduplcate();
        }
      }
    
 

  }
  ngOnInit() {

    //var val = this.option.date.replace(/[^\d.-]/g, '');
    //if (val == null)
    //  return;

    //let date = Number(val);
    //this.onDateChanged.emit(date);
  
  }
  ngAfterViewInit() {
    this.txbox.instance.focus();
  }
 
  onClickClose() {
    console.log('click');
    this.onClose.emit(true);

  }
  onValueChangedop(e) {

   


  }

 
  datechanged() {

    var val = this.option.date.replace(/[^\d.-]/g, '');
    if (val == null)
      return;
    if (val.length < 8)
      return;


    let date = Number(val);


    this.onDateChanged.emit(date);
  

  }

  creteduplcate() {

    this.onDuplcate.emit(this.option);

  }
  buttcopclick() {

    var val : string = this.option.date.replace(/[^\d.-]/g, '');
    if (val == null)
      return;
    if (val.length <8)
      return;

    this.buttcop = false;
    this.buttsave = true;

  }

  buttcancel() {
    this.buttcop = true;
    this.buttsave = false;

  }
}



