import { PdPayrecRow } from "../opr/PdPayrec";
import { PdMove } from "../opr/PdMove";

 
export class PdPayrecCheckRep
{
    option: PdPayrecCheckRepOps;
  rows: PdPayrecCheckRepRow[];

    constructor (){

      this.option = new PdPayrecCheckRepOps();
      this.rows = [];
   };
}

export class PdPayrecCheckRepOps {
  dateAS: number;
  dateAS_S: string;
  dateAE: number;
  dateAE_S: string;
  dateBS: number;
  dateBS_S: string;
  dateBE: number;
  dateBE_S: string;
  paykindIn: number;
  showBardashtP1: boolean;
  ShowBatelP1: boolean;
  showDakhelP1: boolean;
  showTazP12: boolean;
  showVosulP2: boolean;
  constructor() {

    this.dateAS= 0;
    this.dateAS_S=null;
    this.dateAE = 0;
    this.dateAE_S = null;
    this.dateBS = 0;
    this.dateBS_S = null;
    this.dateBE = 0;
    this.dateBE_S = null;
    this.paykindIn = 0;
    this.showBardashtP1 = false;
    this.ShowBatelP1 = false;

    this.showDakhelP1 = false;
    this.showTazP12 = false;
    this.showVosulP2 = false;

  }
}
 
export class PdPayrecCheckRepRow {
  id: number;
  radif: number;
  pdPayrecRow: PdPayrecRow;
  lastPdMove: PdMove;
  ckDateDaySpan: number;
  lastTaf: string;
  lastPdBank: string;
  dateVosulP1: string;
}
