import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import {  VmMahRep, VmMahRepOp, VmMahRepRow } from '../../../_models/app98/rep/VmMahRep';
 
let EmptyVmMahRep: VmMahRep = {
  op: null,
  rows: [],
  rowSum:null,
};

let EmptyVmMahRepOp: VmMahRepOp = {
  year :1300,
  mah :null,
  mahN :null,
  dateS: 0,
  dateS_S: null,
  dateE: 0,
  dateE_S: null,
  vmGrss: [],
  showTas: false
};

let EmptyVmMahRepRow: VmMahRepRow = {
 
  id: null,
  tafId: null,
  tafC: null,
  tafN: null,
  tafFName: null,
  tafLName: null,
  tafMelli: null,
  prcDar1All: null,
  prcDar1: null,
  prcDar3: null,
  prcDarSum31: null,
  prcVamMandehAll: null,
  prcDar2: null,
  prcVamMandeh: null,
  rsMandeh: null,
  radif: null,
  vmGrsNs: null

};

let MahsList: any[] = [
  { id: 1, name: 'فروردین' },
  { id: 2, name: 'اردیبهشت' },
  { id: 3, name: 'خرداد' },
  { id: 4, name: 'تیر' },
  { id: 5, name: 'مرداد' },
  { id: 6, name: 'شهریور' },
  { id: 7, name: 'مهر' },
  { id: 8, name: 'آبان' },
  { id: 9, name: 'آذر' },
  { id: 10, name: 'دی' },
  { id: 11, name: 'بهمن' },
  { id: 12, name: 'اسفند' }
];

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmMahRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

 
 
  getRep(op: VmMahRepOp): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getVmMahRep';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, op,{ 'headers': headers }).pipe();

  }
  getVmMahOneRep(tafId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getVmMahOneRep?tafId=' + tafId;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

  }
  getVmMahRepEmpty(): VmMahRep {
    let l = JSON.parse(JSON.stringify(EmptyVmMahRep)) as VmMahRep;
    l.op = JSON.parse(JSON.stringify(EmptyVmMahRepOp));
    return l;

  }
  getVmMahRepOpEmpty(): VmMahRepOp {

    return JSON.parse(JSON.stringify(EmptyVmMahRepOp));

  }
 

  getMahList(): any[] {

    return MahsList;

  }
  getDateRange(year: number,  mahId :number): any {

    let m = mahId.toString();
    if (mahId < 10)
      m = "0" + m;

    if (mahId > 0 && mahId < 7)
      return { dateA: year.toString() + '/' + m + '/01' ,dateB: year.toString() + '/' + m + '/31' }
    else if (mahId == 12)
      return { dateA: year.toString() + '/' + m + '/01', dateB: year.toString() + '/' + m + '/29' }
    else
      return { dateA: year.toString() + '/' + m + '/01', dateB: year.toString() + '/' + m + '/30' }
  }



}
