import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
 
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
 
 
import { MatDialog } from '@angular/material';
 
 
import { Localst } from '../../../../_services/localst.service';
import { AnSellRepOp } from '../../../../_models/app3/rep/AnSellRep';

@Component({
  selector: 'app3-AnSell-rep',
  templateUrl: './AnSell-rep.component.html',
  styleUrls: ['./AnSell-rep.component.css']
})

export class AnSellrepComponent implements OnInit {
  
 
  @Input() cshomareOne: number = -1;

 

  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();



  @Input() set printnum(valu: number) {
    if (valu) {
      this.option.codeS = valu;
      this.option.codeE = valu;
    }
 
  }

 

  option: AnSellRepOp;

  lv3: boolean;
  lv4: boolean;
  lv5: boolean;
  lv6: boolean;

  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private localst: Localst,
  ) {

    let y = localst.yearnow_get();

    this.option = { year: y, codeS: 6, codeE: 1  , dateS :0 , dateE:0, dateE_S:null , dateS_S:null , kind:-706};

   
  

  }
 
  ngOnInit() {

  }

 
  onClickClose() {
 
    this.onClose.emit(true);

  }
 
  print() {

 
    this.option.year = this.localst.yearnow_get();
    this.onPrint.emit(this.option);

    

  }
}



