import { Component, OnInit, Output, Input } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
 
import { Localst } from '../../../../_services/localst.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { PdLinkBox, PdLinkBoxRow, PdLinkPost, PdLink, PdLinkBoxRowLinkPrc, PdLinkBoxOp } from '../../../../_models/app4/opr/PdLink';
import { PdLinkService } from '../../../../_services/app4/opr/PdLink.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { retry } from 'rxjs/operators';
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app4-PdLink-list',
  templateUrl: './PdLink-list.component.html',
  styleUrls: ['./PdLink-list.component.css']
})


export class PdLinkListComponent implements OnInit {

 
 
  model: PdLinkBox;
  rowsRight: PdLinkBoxRow[];
  rowsLeft: PdLinkBoxRow[];

  focusedRowKeyRight: string;
  focusedRowKeyLeft: string;

  modelPost: PdLinkPost;
 
  TafsiliForSmalllist: TafsiliForSmall[];
  @Input() inputdata: any;
  @ViewChild("dataGridRight", { static: false }) dataGridRight: DxDataGridComponent;
  @ViewChild("dataGridLeft", { static: false }) dataGridLeft: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private pdLinkService: PdLinkService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private tafsiliService: TafsiliService,
     private localst: Localst,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 
    this.loadServ.create(this.loaddata, 11);

    this.model = new PdLinkBox();

    this.model.op.dateA_S = this.localst.yearnow_Class_get().dateS_s;
    this.model.op.dateB_S = this.localst.yearnow_Class_get().dateE_s;
    this.model.op.tafId = null;

    this.modelPost = new PdLinkPost();
    this.rowsRight=[];
    this.rowsLeft=[];
  }


 


  ngOnInit() {


    if (this.inputdata) {

      let o = this.inputdata as PdLinkBoxOp;

      this.model.op = o;


    }



    this.loadServ.showloading(this.loaddata, 3);
    this.tafsiliService.GetTafsiliForSmall().subscribe((data) => {

      this.loadServ.hideloading(this.loaddata, 3);
      this.TafsiliForSmalllist = data;

      //  console.log(JSON.stringify(this.model));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    if (this.model.op.tafId > 0) {
      this.load();
    }
   

  }
 
 
  
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.pdLinkService.getPdLinkModel(this.model.op)
      .subscribe((res: ApiRet) => {
    
        this.loadServ.hideloading(this.loaddata, 0);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        this.model = res.data;
        this.rowsLeft = this.model.rows.filter(x => x.typeSplit == 1);
        this.rowsRight = this.model.rows.filter(x => x.typeSplit == 2);

        this.onFocusedRowChangedRight(null);
        this.onFocusedRowChangedLeft(null);
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
  onFocusedRowChangedRight(e) {
    this.modelPost.right = null;
    

    var r = this.model.rows.find(x => x.id == this.focusedRowKeyRight);
    if (r) {
      this.modelPost.setRight(r);
    }
    else {
      return;
    }
   
      for (var i = 0; i < this.rowsLeft.length; i++) {

        this.rowsLeft[i].isLink = false;
        for (var fo of r.pIds) {

          if (this.rowsLeft[i].id == fo.id) {
            this.rowsLeft[i].isLink = true;
          }

        }

      }
    

  }
  onFocusedRowChangedLeft(e) {
    //54y4u654ui
    this.modelPost.left = null;
    
    var l = this.model.rows.find(x => x.id == this.focusedRowKeyLeft);
    if (l) {
      this.modelPost.setLeft(l);
    }
    else {
      return;
    }

    for (var i = 0; i < this.rowsRight.length; i++) {

      this.rowsRight[i].isLink = false;
      for (var fo of l.pIds) {

        if (this.rowsRight[i].id == fo.id) {
          this.rowsRight[i].isLink = true;
        }

      }

    }
   

  }



  onClickLink() {


    if (this.modelPost.right == undefined || this.modelPost.right == null) {
      this.alertify.warning('ستون سمت راست اتخاب نشده');
      return;
    }
    if (this.modelPost.left == undefined || this.modelPost.left == null) {
      this.alertify.warning('ستون سمت چپ اتخاب نشده');
      return;
    }


    

    this.loadServ.showloading(this.loaddata, 0);
    this.pdLinkService.AddOrUpdateNoSide(this.modelPost)
      .subscribe((res: ApiRet) => {

        this.loadServ.hideloading(this.loaddata, 0);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        this.load();
      
       //let p = res.data as PdLink;


       // for (var r of this.model.rows) {

       //   if (r.id == p.tableAGenericId && )

       //   const index = myArray.indexOf(key, 0);
       //   if (index > -1) {
       //     myArray.splice(index, 1);
       //   }

       // }


       // for (var r of this.model.rows) {

       //   if (r.id == p.tableAGenericId) {

       //     r.sumLinkPrc += p.changePrc;
       //     r.freePrc = r.prc - r.sumLinkPrc;


       //     let temp = new PdLinkBoxRowLinkPrc();
       //     temp.id = p.tableBGenericId;
       //     temp.linkPrc = p.prc;
       //     r.pIds.push(temp);
       //   }
       //   if (r.id == p.tableBGenericId) {

       //     r.sumLinkPrc += p.changePrc;
       //     r.freePrc = r.prc - r.sumLinkPrc;

       //     let temp = new PdLinkBoxRowLinkPrc();
       //     temp.id = p.tableAGenericId;
       //     temp.linkPrc = p.prc;
       //     r.pIds.push(temp);
       //   }

       // }


      }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
      });



   
  }



  onRowClick(e) {

    //for (var i = 0; i < this.model.rows.length; i++) {

    //  this.model.rows[i].isLink = false;
    //  for (var fo of e.data.pIds) {

    //    if (this.model.rows[i].id == fo.id) {
    //      this.model.rows[i].isLink = true;
    //    }

    //  }

    //}

  }
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

  onFocusedRowChanged(e) {
   // this.selectedrow = e.row.data;
 
  }
 

  onClickReflesh() {

    if (this.model.op.tafId == null) {
      this.alertify.warning('عنوان تفصیلی انتخاب نشده');
      return;
    }
    this.load();





  }


   
 
   
}
