import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { Sabt } from '../../../../_models/app1/opr/Sabt';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { Tafsili } from '../../../../_models/app1/paye/tafsili';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { DxSelectBoxComponent } from 'devextreme-angular';
 

@Component({
  selector: 'app-Sabt-row',
  templateUrl: './Sabt-row.component.html',
  styleUrls: ['./Sabt-row.component.css']
})
export class SabtrowComponent implements OnInit {
  @Input() myvs: Sabt = this.SabtService.getSabtEmpty()
  //@Output() deleteDone: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onFocusRow: EventEmitter<number> = new EventEmitter<number>();
  @Input() isheader: boolean = false;
  @Input() hesablist: Hesab[] = [];
  TafG: TafsiliForGroupRead;
  @ViewChild("moienChild", { static: false }) moienChild: DxSelectBoxComponent;
  @ViewChild("taf4Child", { static: false }) taf4Child: DxSelectBoxComponent;
  @ViewChild("taf5Child", { static: false }) taf5Child: DxSelectBoxComponent;
  @ViewChild("taf6Child", { static: false }) taf6Child: DxSelectBoxComponent;
  @HostListener('document:keypress', ['$event'])


 
// va: string;
  handleKeyboardEvent(event: KeyboardEvent) {





  //  console.log(event);
     
 
  }


  focus(vvv: boolean) {
    if (vvv) {

   //   this.va = 'csfocus';
      this.myvs._focus = true;
    }
  else {
    //  this.va = 'csvalid';
      this.myvs._focus = false;
    }
  }
 
  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private router: Router,
    private httpClient: HttpClient,
    private SabtService: SabtService,
    private TafsiliService: TafsiliService,
    private route: ActivatedRoute) {
    
  //  this.myvs = this.SabtService.getSabtEmpty()
    console.log('ooooo:' + JSON.stringify(this.myvs));
    if (this.myvs._focus) {
     // this.va = 'csfocus';
    }
   
  }
  OnInput(value) {
   // this.myvs = value;
    //this.myvs.vaziatN = 'hjj';
    //console.log(this.myvs.vaziatN)
  }
  ngOnInit() {

    this.TafG = this.TafsiliService.getTafsiliGroupEmpty();

  //  console.log("  this.TafG" + JSON.stringify(this.TafG));
 
   // console.log(" id" + this.myvs.id);
    
  }


  loadUsers() {
  
   
  }


  deletethis(id: number) {

    this.myvs.id = id;
    console.log("The delete clicked "+ this.myvs.id.toString());
    this.openDialog();
  //  this.dodelete();
   
  }
 
  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
     let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();
     
      }
      
    });
  }

 
  dodelete() {

    this.SabtService.deleteSabt(this.myvs.id).subscribe((data) => {
      this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');
      console.log("m:deleted successful");
      //this.deleteDone.emit(true);


    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }



  editthis(editsha: Sabt) {


    console.log("The edit clicked");
    let vvv = this.SabtService.getSabt(this.myvs.id);
    if (vvv == null) {

      console.log(this.myvs.id.toString() + 'not exist in database!!!');
    }
    else {
      this.router.navigate(['/app1/paye/Sabt/edit', this.myvs.id]);
    }

 

  }

  editthis2(editsha: Sabt) {


    console.log("The ببببب clicked");
    



  }


  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log('neww --' +newValue);


    this.TafsiliService.getTafsilisGroup(newValue).subscribe(data => {
      //   this.alertify.success('مشخصات با بروز رسانی شد');

      this.TafG = data;


      //console.log(JSON.stringify(this.TafG.taf4List));
      console.log(JSON.stringify(this.TafG));
      //'⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞ ⁞';

      this.myvs.tafsili4Code = null;
      this.myvs.tafsili5Code = null;
      this.myvs.tafsili6Code = null;
      if (!this.TafG.have4)
        this.myvs.tafsili4Code = '-------------------------';
      if (!this.TafG.have5)
        this.myvs.tafsili5Code = '-------------------------';
      if (!this.TafG.have6)
        this.myvs.tafsili6Code = '-------------------------';
   
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    for (var _i = 0; _i < this.hesablist.length; _i++) {
      if (this.hesablist[_i].id == newValue) {
        this.myvs.hesabCode = this.hesablist[_i].code;
        this.myvs.hesabId = this.hesablist[_i].id;
        this.myvs.hesabName = this.hesablist[_i].name;
      }


    }


    
  }

  updateEmployeeInfo() {
  
  }

  ////////////////////////////moien/////////////////////////////
  mo_focus: boolean = false;
  moieninputonFocus(event: any) {
    this.focus(true);
    this.mo_focus = true;
    this.moienChild.instance.focus();

   // this.moienChild.instance.focus()
 //   console.log(this.moienChild.onFocusIn.emit);
  
   // this.moienChild.inputAttr.focus();
    //var r = $("#moiendiv");
    //console.log(r);
    //r.find('.dx-texteditor-input').focus();
    //console.log(r.find('.dx-texteditor-input'));

    console.log('focusinput moien');

  }
  moieninputonFocusout(event: any) {
    this.moienChild.instance.focus();
    console.log('focusOutinput moien');

  }


  moienComboonFocusIn(event: any) {


  }
 
  moienComboFocusOut(event: any) {
    this.mo_focus = false;
    console.log('focusOutCombo moien');
   
  }


  ////////////////////////////taf4/////////////////////////////
  taf4_focus: boolean = false;
 taf4inputonFocus(event: any) {

   this.taf4_focus = true;
    this.moienChild.instance.focus();

  }
 taf4inputonFocusout(event: any) {
   this.taf4Child.instance.focus();

  }


 taf4ComboonFocusIn(event: any) {

  }

 taf4ComboFocusOut(event: any) {
   this.taf4_focus = false;

 }


  taf4onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;


    for (var _i = 0; _i < this.TafG.taf4List.length; _i++) {
      console.log(JSON.stringify(this.TafG.taf4List[_i]) );
      if (this.TafG.taf4List[_i].id == newValue) {


        this.myvs.tafsili4Code = this.TafG.taf4List[_i].code;
        this.myvs.tafsili4Id = this.TafG.taf4List[_i].id;
        this.myvs.tafsili4Name = this.TafG.taf4List[_i].name;
      }


    }



  }

  ////////////////////////////taf5/////////////////////////////
  taf5_focus: boolean = false;
  taf5inputonFocus(event: any) {

    this.taf5_focus = true;
    this.moienChild.instance.focus();

  }
  taf5inputonFocusout(event: any) {
    this.taf5Child.instance.focus();

  }


  taf5ComboonFocusIn(event: any) {

  }

  taf5ComboFocusOut(event: any) {
    this.taf5_focus = false;

  }

  taf5onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;


    for (var _i = 0; _i < this.TafG.taf5List.length; _i++) {
      console.log(JSON.stringify(this.TafG.taf5List[_i]));
      if (this.TafG.taf5List[_i].id == newValue) {


        this.myvs.tafsili5Code = this.TafG.taf5List[_i].code;
        this.myvs.tafsili5Id = this.TafG.taf5List[_i].id;
        this.myvs.tafsili5Name = this.TafG.taf5List[_i].name;
      }


    }



  }

  ////////////////////////////taf6/////////////////////////////
  taf6_focus: boolean = false;
  taf6inputonFocus(event: any) {

    this.taf6_focus = true;
    this.myvs._focus = true;
    this.moienChild.instance.focus();

  }
  taf6inputonFocusout(event: any) {
    this.taf6Child.instance.focus();

  }


  taf6ComboonFocusIn(event: any) {

  }

  taf6ComboFocusOut(event: any) {
    this.taf6_focus = false;
   

  }

  taf6onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;


    for (var _i = 0; _i < this.TafG.taf6List.length; _i++) {
      console.log(JSON.stringify(this.TafG.taf6List[_i]));
      if (this.TafG.taf6List[_i].id == newValue) {


        this.myvs.tafsili6Code = this.TafG.taf6List[_i].code;
        this.myvs.tafsili6Id = this.TafG.taf6List[_i].id;
        this.myvs.tafsili6Name = this.TafG.taf6List[_i].name;
      }


    }



  }



  ////////////////////////////bed/////////////////////////////
  bed_focus: boolean = false;
  bedinputonFocus(event: any) {

    this.bed_focus = true;
    this.myvs._focus = true;
    this.moienChild.instance.focus();

  }
  bedinputonFocusout(event: any) {
   // this.bedChild.instance.focus();

  }


  bedComboonFocusIn(event: any) {

  }

  bedComboFocusOut(event: any) {
    this.bed_focus = false;


  }

  bedonValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;


    //for (var _i = 0; _i < this.TafG.bedList.length; _i++) {
    //  console.log(JSON.stringify(this.TafG.bedList[_i]));
    //  if (this.TafG.bedList[_i].id == newValue) {


    //    this.myvs.tafsili6Code = this.TafG.bedList[_i].code;
    //    this.myvs.tafsili6Id = this.TafG.bedList[_i].id;
    //    this.myvs.tafsili6Name = this.TafG.bedList[_i].name;
    //  }


    //}



  }

  changedItem() {
   // console.log('click');
    this.onFocusRow.emit(this.myvs.id);
  }

  ///////////////////////////
  myFunction() {
     console.log('fffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

  }
}



