import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { MorurOption } from '../../../../_models/app1/rep/Morur';
 
import { Localst } from '../../../../_services/localst.service';
import { App99RepService } from '../../../../_services/app99/App99Rep.service';
import { RepGharPrint, RepGharPrintOp } from '../../../../_models/app99/app99Repmodel';
import { LoadingService } from '../../../../_services/loading.service';



 
@Component({
  selector: 'app99-RepGharOne-ht',
  templateUrl: './RepGharOne-ht.component.html',
  styleUrls: ['./RepGharOne-ht.component.css']
})
 

export class RepGharOneHtComponent implements OnInit {

 
  @Input() inputdata: any;

  orgheader: any;
  orgrows: any[];
  orgrows2: any[];

  apidata: any;
  loadcomplete: boolean = false;
 
 
 
  loaddata: loadingCl;
  printpage: printpage;

  lastpage: pagep;

  constructor(private userService: UserService, private alertify: AlertifyService, private app99RepService: App99RepService,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private router: Router,
    private localst: Localst,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 4);


   
  }

  ngOnInit() {



    let id = this.inputdata.value;

    this.loadServ.showloading(this.loaddata, 1);
    this.app99RepService.RepGharOneHt(id).subscribe(data => {


      this.loadServ.hideloading(this.loaddata, 1);
      this.apidata = data;
      this.initprint();
      this.loadcomplete = true;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);

    });




  
  }
 
  ngAfterViewInit() {

 
  }
 

 
 


  initprint() {

    this.apidata;

    //ggfgfhhgfgfhgfh65
   



    this.orgrows = this.apidata.rowsHitem;
    this.orgrows2 = this.apidata.rowsDarM;



    if (this.inputdata == null)
      return;
    //گرفتن تاریخ از سرور

   

    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
      HNoemandeh: null
    };

    if (this.inputdata == null)
      return;
    //گرفتن تاریخ از سرور

    this.printpage.HDatepring = this.localst.dateEmruz.dateString;


    this.createheader();

    let indexreaded = 0;
    let pageindex = 0;

    let datalegh = this.orgrows.length;

    let radiftable1temp = 0;

    while (indexreaded < datalegh) {

      this.printpage.pages.push(new pagep(pageindex));
      this.lastpage = new pagep(pageindex);
 

      let counterpage = 0;
      while (indexreaded < datalegh) {
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].havet1 = true;
        this.printpage.pages[pageindex].rowsp.push(this.orgrows[indexreaded]);
        let skiprow = Math.floor(this.orgrows[indexreaded].legpx / 464);
        counterpage = counterpage + skiprow;


        indexreaded++;
        counterpage++;
        radiftable1temp = counterpage;
        if (counterpage > 8)
          break;

      }
      pageindex++;



    }
     
     indexreaded = 0;
    pageindex = 0;
     datalegh = this.orgrows2.length;


     while (indexreaded < datalegh) {

       if ((this.printpage.pages.length -1) < pageindex) {
         this.printpage.pages.push(new pagep(pageindex));
       }

      let counterpage = 0;
       while (indexreaded < datalegh) {
         //اگر از جدول یک در این صفحه بود طول جدول را اضافه میکنییم
         if (this.printpage.pages[pageindex].havet1) {

           counterpage = radiftable1temp + 2;
           radiftable1temp = 0;
         }

        //درج ردیف در صفحه2
        this.printpage.pages[pageindex].havet2 = true;
        this.printpage.pages[pageindex].rowsp2.push(this.orgrows2[indexreaded]);
        let skiprow = Math.floor(this.orgrows2[indexreaded].legpx / 464);
        counterpage = counterpage + skiprow;


        indexreaded++;
        counterpage++;
        if (counterpage > 8)
          break;

      }
      pageindex++;



 
    }










    let leghpage = this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      //یکی اضافه میکنیم برای شماره صفحه اخر
      this.printpage.pages[i].ppages = leghpage + 1;

    
      this.lastpage.ppage = leghpage + 1;
      this.lastpage.ppages = leghpage + 1;
    }


  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

 

   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
 
    console.log(d.outerHTML);

    var ttt = document.getElementById("tbtb");
    console.log(ttt.offsetHeight);

 
    

    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


  //  let popupWinindow
   // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
  //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 12pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: #E2E2E2;
}


 

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

     .t-table {
          width: 277mm;
          margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
            font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
           font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
  font: 12pt "B Nazanin" ;
  font-weight:400;
          width: 277mm;
         margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
          font-family: 'B Nazanin';
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }

@media print {

* {
  margin: 0;
  padding: 0;
}
 .page-break  { display:block; page-break-before:always; }
   @page {
     size: A4 landscape;
   margin: 0;
 }
 
  html, body {
 
  width: 297mm;
  height: 210mm;
  }

 .page {

display:block; page-break-before:always;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;
 
  border-radius: 0px;
  background: white;
 
}

 

 


        .t-table {
          width: 277mm;
           margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
            font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
          width: 277mm;
        margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );




    popupWin.document.close();



  }

}


//popupWin.document.write(`
//      <html>
//        <head>
//          <title>Print tab</title>
//          <style>
//          //........Customized style.......

// body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//  background-color: #FAFAFA;
//  font: 12pt "Tahoma";
//}

//* {
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}

//.page {
    
//  width: 297mm;
//  min-height: 210mm;
//  padding: 5mm;
//  margin: 10mm auto;
//  border: 1px #D3D3D3 solid;
//  border-radius: 5px;
//  background: white;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

 
 


// -webkit-transform: rotate(-90deg) ; 
//     -moz-transform:rotate(-90deg) ;

//     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

//}

//.subpage {
//  padding: 5mm;
//  border: 5px rgb(128, 128, 128) solid;
//  height: 200mm;
//  outline: 5mm #D3D3D3 solid;
//}



 
 


//@media print {

//   @page {size: A4  ;

// margin: -10;


//}
 
//  html, body {
 
    
//  }

//  .page {
//    margin: 0;
    
//    border: initial;
//    border-radius: initial;
//    width: initial;
//    min-height: initial;
//    box-shadow: initial;
//    background: initial;
//    page-break-after: always;
//  }
//  #section-to-print, #section-to-print * {
//    visibility: visible;
//  }

//  #section-to-print {
//    position: absolute;
//    left: 0;
//    top: 0;
//  }

//}



//          </style>
//        </head>
//    <body onload="window.print();window.close()">${d.innerHTML}</body>
//      </html>`
//);
