import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
 
 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../../_services/loading.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
import { KardexOps, KardexRep } from '../../../../_models/app2/rep/KardexRep';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { KalaForList } from '../../../../_models/app2/paye/Kala';
import { Vahed } from '../../../../_models/app2/paye/Vahed';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
 
 

@Component({
  selector: 'app-Kardex-list',
  templateUrl: './Kardex-list.component.html',
  styleUrls: ['./Kardex-list.component.css']
})
 

export class KardexlistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @Input() KardexOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  @Input() showRial: boolean;


  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;
 

  focusedSabtId: number;
  
 //لیست کالا ها
  devDataKalas: any = {};
 
  vahedList: Vahed[] = [];
  //لیست انبارها 
  anbarList: Anbar[] = [];

  
 
  selectedrow: any;
 

  model: KardexRep;
 
 
 
 
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
 
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anRepService: AnRepService,
    private kalaService: KalaService,
    private anbarService: AnbarService,
 
 
 
 
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    this.openedit= false;
    this.openeditId = -1;
    this.focusedSabtId = -1;
 
    this.loadServ.create(this.loaddata,8);



    this.model = anRepService.getKardexRepEmpty();
    this.model.option.year = localst.yearnow_get();



  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    
 
    //c=67

    //this.onClickReflesh();
      
   
 



  }
  ngOnInit() {


  

    this.loadServ.showloading(this.loaddata, 2);
    this.kalaService.getallForlist()
      .subscribe((res: KalaForList[]) => {
        this.loadServ.hideloading(this.loaddata, 2);
     

        this.devDataKalas = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });


       
        this.allDataLoaded(2);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 3);
    this.anbarService.getalltiny()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 3);

        this.anbarList = res.data;

        //در بار اول انبار ها را در مدل سلکت میکنیم
        for (var j = 0; j < this.anbarList.length; j++) {

          this.model.option.anbarIds.push(this.anbarList[j].id);

        }


        this.allDataLoaded(3);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });



    this.loadServ.showloading(this.loaddata, 4);
    this.anRepService.DateRangAnDeed(this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 4);

        this.model.option.dateA_S = res.data.dateA_S;
        this.model.option.dateB_S = res.data.dateB_S;


        this.allDataLoaded(4);
      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });


  
  }

  num2= false;
  num3= false;
  num4= false;
 
  allDataLoaded(num : number) {

    if (num == 2)
      this.num2 = true;
    if (num == 3)
      this.num3 = true;
    if (num == 4)
      this.num4 = true;
  
   // if (this.loaddata.showed) {
    if (this.num2 && this.num3 && this.num4 ) {


      //////yh456u5687568
      if (this.KardexOption != undefined) {

        this.model.option.kalaId = this.KardexOption.kalaId;

        this.onClickReflesh();

      }



    }
   


  }



  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,' کاردکس کالا ');

   // this.excelService.exportAsExcelFile(this.modelsearch, 'sample');

  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }

  onClickReflesh() {

    if (this.model.option.kalaId == null) {
      this.alertify.message('کالا را انتخاب کنید');
      return;
    }
    if (this.model.option.dateA_S == null) {
      this.alertify.message('تاریخ شروع را انتخاب کنید');
      return;
    }
    if (this.model.option.dateB_S == null) {
      this.alertify.message('تاریخ پایان را انتخاب کنید');
      return;
    }
    if (this.model.option.vahedId == null) {
      this.alertify.message('واحد را انتخاب کنید');
      return;
    }

    this.model.option.showRial = this.showRial;



    //انبار های سلکت شده را به مدل میدیم

    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم
    if (this.dxliststr!= undefined) {
      var keys = this.dxliststr.selectedItemKeys;
      this.model.option.anbarIds = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {

          this.model.option.anbarIds.push(keys[j].id);
        }
    }
    this.loadServ.showloading(this.loaddata, 5);
    this.anRepService.Kardex(this.model.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        //tyytu676767
        this.model = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


  adjustWidth(e) {

    e.component._popup.option('width', 1000);

  }
 
  OnMyCancelEvent(e) {

    this.openedit = false;
  }
  OnMySaveEvent(e) {

    this.openedit = false;

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

  onkalaachanged(e) {

  
    this.vahedList = [];

    if (e.value == null) {
      this.model.option.vahedId = null;
      this.model.option.vahedN = null;
      return;
    }
    


    this.kalaService.getSortedVaheds(e.value).subscribe((res: ApiRet) => {
      this.vahedList = res.data;
      //اولین واحد از لیست را انتخاب میکنیم که همان واحد اصلی است
      if (this.vahedList.length > 0) {
        this.model.option.vahedId = this.vahedList[0].id;
        this.model.option.vahedN = this.vahedList[0].name;
     
      }

      ////////yh456u5687568
      //if (this.KardexOption != undefined) {

      //  this.model.option.kalaId = this.KardexOption.kalaId;

      //  this.onClickReflesh();

      //}
    

    }, error => {
      this.alertify.error(error);
    });


    this.model.option.kalaC = '';
    this.model.option.kalaN = '';
    this.model.option.tabghN = '';
    this.model.option.tabghRaveshN = '';


    this.kalaService.getTabagh(e.value).subscribe((res: ApiRet) => {
  
      this.model.option.kalaC = res.data.code;
      this.model.option.kalaN = res.data.name;
      //طبقه کالا
      this.model.option.tabghId = res.data._Tabgh.id;
      this.model.option.tabghN = res.data._Tabgh.name;
      //طبقه کالا - روش قیمت گذاری
      this.model.option.tabghRaveshId = res.data._Tabgh.raveshId;
      this.model.option.tabghRaveshN = res.data._Tabgh.raveshN;



    }, error => {
      this.alertify.error(error);
    });




    //for (var r = 0; r < this.devDataKalas._store._array.length; r++) {
    //  if (this.devDataKalas._store._array[r].id == e.value) {

    //    // console.log(JSON.stringify(this.moientempedit.items[r]));
    //    //this.moientempedit.selectedItem = this.moientempedit.items[r];
    //    // this.moientempedit.value = this.moientempedit.items[r].id;
    //    this.model.option.kalaC = this.devDataKalas._store._array[r].code;
    //    this.model.option.kalaN  = this.devDataKalas._store._array[r].name;
    //    break;
    //  }
    //}
  }
 

  firstclreate = true;
 

  dropopened(e) {


    e.component._popup.option('width', 500);
    
    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

      //if (this.model.option.anbarIds.length > 0) {

      //  for (var r of this.model.option.anbarIds) {
      //    this.dxliststr.instance.selectItem(r);
      //  }
      //}
      //else {
      //  this.dxliststr.instance.selectAll();
      //}
       

      this.firstclreate = false;

    }
  }


  onSelectionChanged(e) {


    if (this.firstclreate)
      return;

    var keys = this.dxliststr.selectedItemKeys;
    this.model.option.anbarIds = [];
    //console.log('oooooooooooop');
    //console.log(JSON.stringify(keys));
    if (keys != null)
      for (var j = 0; j < keys.length; j++) {

         
        this.model.option.anbarIds.push(keys[j]);
      }
  }

  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    if (e.column.dataField == "prcMan") {

      if (e.data.anDeedC == 0) {
        if (e.data.prcMan > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.amtMan < 0) {
          e.cellElement.style.backgroundColor = '#ca0000';

        }
 
      }
    }
 
    if (e.column.dataField == "prcOut") {

      if (e.data.anDeedC == 0) {
        

        if (e.data.prcOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.prcOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }

      }
    }


    if (e.column.dataField == "fiOut") {

      if (e.data.anDeedC == 0) {



        if (e.data.fiOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.fiOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }
      }
    }


 

   



  }
  onRowPrepared(e) {

    // console.log(e.data);
    if (e.data == undefined)
      return;
     
    //hjg5565655
    if (e.data.kind == 9  ) {
 
      e.rowElement.style.color = '#19bf00';
    //  e.rowElement.style.border = '1px solid';

     
    }
   else if (e.data.kind == -9) {

      e.rowElement.style.color = '#ca0000';
    //  e.rowElement.style.border = '1px solid';

    }

     if (e.data.anDeedC == 0) {


      e.rowElement.style.border = '1px solid';

    }





  }


  onClickgoSanad() {

    //yttyyt566767
    var key = this.dataGrid.focusedRowKey;
    if (key == null)
      return;

 
    for (var r of this.model.rows) {
      if (r.id == key) {
        //kind نوع صفحه
        //anDeedId ای دی سند
        //AnDeedRowId ردیف فکوس
        this.onOpenSanadList.emit({ kind: r.kind, anDeedId: r.anDeedId, AnDeedRowId: r.id });
        break;
      }

    }
  }


  calculateSelectedRow(options) {
    //if (options.name === 'amtManSummary') {

    //  if (options.summaryProcess === 'start') {
    //    options.totalValue = 0;
    //  }
    //  else if (options.summaryProcess === 'calculate') {
    //  //  if (options.component.isRowSelected(options.value.ID)) {
    //      options.totalValue = options.value.amtMan;
    //  //  }
    //  }
    //}
    if (options.name === 'amtManSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
        //آخرین ردیف همراه با اصلاحش را با هم جمع میکنیم
        if (options.value.anDeedC == 0 ) {
          //اگر ردیف اصلاح بود از را جایگزین نمیکنیم و با موجودی قبل جمع میکنیم
          options.totalValue += options.value.amtMan;
        }
        else {
          //اگر به ردیف جدید رسیدیم اخرین ردیف را جایگزین میکنیم
          options.totalValue = options.value.amtMan;
        }

        //}
      }
    }
    //fdgh7yhg
    if (options.name === 'prcManRowsSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
       //آخرین ردیف همراه با اصلاحش را با هم جمع میکنیم
        if (options.value.anDeedC == 0) {
          //اگر ردیف اصلاح بود از را جایگزین نمیکنیم و با موجودی قبل جمع میکنیم
          options.totalValue += options.value.prcMan;
        }
        else {
          //اگر به ردیف جدید رسیدیم اخرین ردیف را جایگزین میکنیم
          options.totalValue = options.value.prcMan;
        }
       
        //  }
      }
    }
  }

  saveuioption() {

    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2013 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw2013.length; i++) {
      this.localst.optioncli.gw2013[i] *= e;
    }

  }
  defwidth() {

    this.localst.optioncli.gw2013 = this.localst.optionClient_readDefault('gw2013');

  }


  onClickprint(kind) {


 


    var mdata = { model: JSON.parse(JSON.stringify(this.model)), printKind: kind, showRial: this.showRial }

    this.onPrint.emit(mdata);

  }
 
}


