import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { Registry } from '../../../../_models/General/Registry';

import { ApiRet } from '../../../../_models/General/ApiRet';
import { Localst } from '../../../../_services/localst.service';
import { AnFormTafDasteService } from '../../../../_services/app2/paye/AnFormTafDaste.service';
import { AnFormTafDasteGroup } from '../../../../_models/app2/paye/AnFormTafDaste';
import { TafDaste } from '../../../../_models/app1/paye/TafDaste';
import { TafDasteService } from '../../../../_services/app1/paye/TafDaste.service';
import { DxListComponent } from 'devextreme-angular';
import { RegistryService } from '../../../../_services/appsetting/Registry.service';
 
 
 
export interface DialogData {
  animal: string;
  name: string; 
}

@Component({
  selector: 'app2-Registry-Anbar',
  templateUrl: './Registry-Anbar.component.html',
  styleUrls: ['./Registry-Anbar.component.css'],

})


export class RegistryAnbarComponent implements OnInit {

 
  radDr: any[] = [{ id: 0, name: 'مجزا از فاکتور فروش' }, { id: 1, name: 'بر اساس فاکتور فروش' }];
 

  model: any;
  model2: AnFormTafDasteGroup[];


  tafDasteList: TafDaste[];
  modelLoaded: Boolean = false;
 
 
 
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;


  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService,
    private alertify: AlertifyService,
    private Registry_Service: RegistryService,
 
    private route: ActivatedRoute,
    private localst: Localst,
    private anFormTafDasteService: AnFormTafDasteService,
    private tafDasteService: TafDasteService,
  
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
 
    this.loadServ.create(this.loaddata,8);
    this.model = null;
    this.modelLoaded = false;
 
  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();

  }
 
 
  
  load() {

    this.modelLoaded = false;
    this.loadServ.showloading(this.loaddata,0);
    this.Registry_Service.getRegistryAnbar(this.localst.yearnow_get()).subscribe((res: ApiRet) => {
        this.model = res.data;
     
        this.loadServ.hideloading(this.loaddata,0);
      this.modelLoaded = true;
    }, error => {

          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });



    this.loadAnForm();


    this.loadServ.showloading(this.loaddata, 0);
    this.tafDasteService.getTafDastes().subscribe((data: TafDaste[]) => {
      this.tafDasteList = data;

      this.loadServ.hideloading(this.loaddata, 0);

    }, error => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
    });



  }

  loadAnForm() {

    this.loadServ.showloading(this.loaddata, 0);
    this.anFormTafDasteService.getAllAnFormTafDasteGroup().subscribe((res: ApiRet) => {
      this.model2 = res.data as AnFormTafDasteGroup[];

      this.loadServ.hideloading(this.loaddata, 0);

    }, error => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
    });

  }
 
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

 

 





  onClickUpdate() {
 
  
    
    this.loadServ.showloading(this.loaddata, 1);
    this.Registry_Service.updateRegistryAnbar(this.model).subscribe((res: ApiRet) => {

      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.success('تنظیمات ذخیره شد');
      this.loadRegistry();
      this.loadAnForm();
    }, error => {

      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
    });



  }

  onClickTafformUpdate() {



    this.loadServ.showloading(this.loaddata, 1);
    this.anFormTafDasteService.updateAllAnFormTafDasteGroup(this.model2).subscribe((res: ApiRet) => {

      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.success('تنظیمات ذخیره شد');
      this.loadRegistry();
    }, error => {

      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
    });



  }
 
  radChanged(e) {

  }
  

  loadRegistry() {

    this.loadServ.showloading(this.loaddata, 2);
    this.Registry_Service.getall(this.localst.yearnow_get()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.localst.bindRegistry(res.data);
      }

    }, error => {
      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 2);
    }, () => {


    });


  }
 
  onSelectionChanged(e, data){
    //utyuyt45456
    if (this.block)
      return;



    this.selectedrow.tafDastes = [];
    this.selectedrow.tafDasteNameS = '';
   var uu=  this.dxliststr.selectedItems;
    for (var k of uu) {
      for (var r of this.tafDasteList) {

        if (r.id == k.id) {
          this.selectedrow.tafDastes.push(r);
        
         
          this.selectedrow.tafDasteNameS += r.name + " / ";
        
        }

      }

    }
   // this.selectedrow.setTafDasteNameString();

  }
  selectedrow: AnFormTafDasteGroup;
  block: boolean = false;
  onFocusedRowChanged(e) {
    this.block = true;
    this.dxliststr.instance.unselectAll();

    this.selectedrow = e.row.data as AnFormTafDasteGroup; 
    //yghu658678967
    for (var r of this.selectedrow.tafDastes) {

      let index = this.tafDasteList.findIndex(x => x.id == r.id);

      this.dxliststr.instance.selectItem(index);

    }
    this.block = false;

  }
}
