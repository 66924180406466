import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxTreeListComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';

import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';

import { Localst } from '../../../_services/localst.service';

import { ApiRet } from '../../../_models/General/ApiRet';

import { AnPrcBas, AnPrcBasOp } from '../../../_models/app2/glob/AnPrcBas';
import { AnPrcBasService } from '../../../_services/app2/glob/AnPrcBas.service';
import * as FileSaver from 'file-saver';
import { ExcelService } from '../../../_services/excel.service';
 

@Component({
  selector: 'app-AnPrcBas-list',
  templateUrl: './AnPrcBas-list.component.html',
  styleUrls: ['./AnPrcBas-list.component.css']
})
 

export class AnPrcBaslistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
 
  @ViewChild("dataGrid", { static: false }) dataGrid: DxTreeListComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  selectedrow: any;
  model: AnPrcBas[];
  selectedKalaIds: number[] =[];
  allCnt: number =100;
  nowCnt: number =0;
  option: AnPrcBasOp;
  mastext: '-';
 
  priorities: any[];
  valll: number =1;

  loaddata: loadingCl;

  constructor(
  
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private excelService: ExcelService,
    private AnPrcBasService: AnPrcBasService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata,8);
    this.model = [];
    this.priorities = [
      { id: 1, name: 'قیمت گذاری' },
      { id: 2, name: 'حذف قیمت گذاری' }
    ];
  }

  ngOnInit() {

    this.option = this.AnPrcBasService.getAnPrcBasOpEmpty();
    this.option.years = this.localst.yearnow_get();
    this.option.dateBas_S = this.localst.dateEmruz.dateString;
   
    this.loadgrid();


  }
  ngAfterViewInit() {

   
  }


  loadgrid() {

    this.loadServ.showloading(this.loaddata, 2);
    this.AnPrcBasService.getallByKind(300, this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 2);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }

        this.model = res.data;
        this.bindReport();
        //  this.model[0]._butVaz = -1;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


  }

  mmAll: number = 0;
  mmDone: number = 0;
  mmError: number = 0;

  bindReport() {

    this.mmAll=0;
    this.mmError = 0;
    this.mmDone = 0;

    for (var r of this.model) {

      for (var k of r.subs) {
        this.mmAll++;

        if (k.isError)
          this.mmError++;
        else
          this.mmDone++;

      }

   
    }

  }

 

  refreshbottum() {

    this.loadgrid();

  }

   
  
  temp_dateAchanged(e, cellInfo) {

    if (e.value == undefined || e.value == null || e.value == '') {

      //فقط اگر در دیتا بیس ذخیره بود دکمه نمایش داده شود
      if (cellInfo.data.id>0)
      cellInfo.data._butVaz = -1;
 
      return;
    }
    

 ///tgyutyu444

    let sd = e.value;
    sd = sd.replace('/', '').replace('/', '');

    if (sd.length < 8)
      return;

 
    cellInfo.data.dateA_S = sd.substring(0, 4) + '/' + sd.substring(4, 6) + '/' + sd.substring(6, 8);
    cellInfo.data.dateA = +sd;

    if (cellInfo.data.dateA > cellInfo.data._dbDate)
      cellInfo.data._butVaz = 1
    else if (cellInfo.data.dateA < cellInfo.data._dbDate)
      cellInfo.data._butVaz = -1
    else
      cellInfo.data._butVaz = 0;
    



  }
 
  RowPrepared(e) {

    if (e.key < 0) {
      /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }

    
  }

  SelectionChanged(e) {



   // this.selectedKalaIds = this.dataGrid.instance.getSelectedRowKeys().concat(this.dataGrid.instance.getSelectedRowKeys(true));
    this.selectedKalaIds = this.dataGrid.instance.getSelectedRowKeys(true);

    this.selectedKalaIds = this.selectedKalaIds.filter(x => x > 0);


    this.allCnt = this.selectedKalaIds.length;

    
  }
  rchanged: boolean = false;
  RadChanged(e) {

    if (e.previousValue != e.value) {
      this.rchanged = true;
    }

  }
  updatePrice(indel) {


    if (this.selectedKalaIds.length == 0) {
      this.alertify.warning('ابتدا کالایی را انتخاب کنید')

      return;
    }

        //    public int years { get; set; }
        //public List < int > KalaIds { get; set; }
        //public int DateA { get; set; }
        //public string DateA_S { get; set; }

    if (indel) {
      this.option.dateBas_S = "";
    }

    var vData = { KalaIds: this.selectedKalaIds, years: this.option.years, DateA: 0, DateA_S: this.option.dateBas_S, valll: this.valll };

 


    this.loadServ.showloading(this.loaddata, 3);
    this.AnPrcBasService.validDate(vData)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 3);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
         // this.alertify.success(res.mes);

        
          this.progStatus = 'pricing';
          this.nowCnt = 0;
          this.savedindex = 0;
          this.startpricing(0);
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });

  }
  //اخرین ایندکسی که قیمتگذاری شد
  savedindex: number = 0;
  startpricing(index: number) {
    this.rchanged = false;
    if (this.progStatus != 'pricing') {
      return;
    }

      //اگر کالا ها تمام شد متوقف میشویم
    if (index >= this.allCnt) {
      this.progStatus = 'complete';
      this.loadgrid();
      return;
    }
   

    this.option.dateBas_S;
   
    this.option.index = index;
    this.option.years = this.localst.yearnow_get();
    this.option.kalaId = this.selectedKalaIds[index];

    this.AnPrcBasService.calcPrice(this.option)
      .subscribe((res: ApiRet) => {
        this.savedindex = res.value;
        this.nowCnt++;
        this.startpricing(this.savedindex +1);
      
      }, error => {
        this.progStatus = 'pused';
        this.alertify.error(error);
      });

  }

  progStatus: string = 'no';
  pusePrice() {

    this.progStatus = 'pused';
  }
  continuePrice (){

    this.progStatus = 'pricing';
    this.startpricing(this.savedindex + 1);

  }

  cancelPrice() {
 
     this.savedindex = 0;
    this.nowCnt = 0;
    this.progStatus = 'no';
    this.loadgrid();
}


  onClickExportexcel() {


    // this.excelService.exportAsExcelFile(this.model.rows,' گزارش جامع فروش ');

    this.loadServ.showloading(this.loaddata, 3);
    this.AnPrcBasService.AnPrcBasAsExcel(this.option.years).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

 
}


