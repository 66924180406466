import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { MorurOption } from '../../../../_models/app1/rep/Morur';
import { ReportApp1Service } from '../../../../_services/app1/rep/ReportApp1.service';
 
import { Localst } from '../../../../_services/localst.service';
import { hesabRep, hesabRepRow } from '../../../../_models/app1/paye/hesabRep';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { LoadingService } from '../../../../_services/loading.service';
 


 
 
@Component({
  selector: 'app-hesab-ht',
  templateUrl: './hesab-ht.component.html',
  styleUrls: ['./hesab-ht.component.css']
})
 

export class hesabHtComponent implements OnInit {

 
  @Input() inputdata: hesabRep;



  nowdate: MyTimeClass;
 
  loaddata: loadingCl;
  printpage: printpage;
  constructor(private userService: UserService, private alertify: AlertifyService, private hesabService: HesabService,
    private route: ActivatedRoute,
    private router: Router,
    private loadServ: LoadingService,
    private localst: Localst,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 3);


   
  }

  ngOnInit() {


    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
      HNoemandeh: null
    };

 

    this.loadServ.showloading(this.loaddata, 0);
    this.hesabService.getHesabRep().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
    
      this.inputdata = data;

     // console.log(JSON.stringify(this.inputdata));

      this.fillrow();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      //  this.alertify.error(error);
      console.log("myerror:" + error)
    });
 
   

   

 

  }
  fillrow() {

  

    if (this.inputdata == null)
      return;
 

    this.createheader();


    //fghfgdtgeyujj

    let datalegh = this.inputdata.rows.length;



    if (datalegh == 0)
      return;
 


 
    let indexreaded = 0;
    let pageindex = 0;

 
    datalegh = this.inputdata.rows.length;

    while (indexreaded < datalegh) {


      this.printpage.pages.push(new pagep(pageindex));
      let counterpage = 0;

      let breakenullkol = true;
      while (indexreaded < datalegh) {


        //اگر رفتیم صفحه بعد و کل هنوز ادامه داشت تا رسیدن به کل بعدی یک سلول نال اینسرت میکنیم
        if (this.inputdata.rows[indexreaded].rowspam == 0 && breakenullkol)
          this.inputdata.rows[indexreaded].nulkol = true;
        else breakenullkol = false;
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.inputdata.rows[indexreaded]);

        let skiprow = Math.floor(this.inputdata.rows[indexreaded].legpx / 240);
       //  skiprow += Math.floor(this.inputdata.rows[indexreaded].legpxkol / 85);

        if (Number.isNaN(skiprow))
          skiprow = 0;

        skiprow = skiprow + (this.inputdata.rows[indexreaded].legmaxdaste - skiprow);


        counterpage = counterpage + skiprow;

        indexreaded++;
        counterpage++;

        if (counterpage > 22)
          break;

     


      }


      pageindex++;



    }
  //  console.log(JSON.stringify(this.printpage));
    let leghpage = this.printpage.pages.length;


    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }




  }
  ngAfterViewInit() {

 

  }
  resetFilters() {
   
 
  }
 

 

  onFocusedRowChanged(e) {


 

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
   // this.printpage.HMahdude = ' از تاریخ ' + this.inputdata.dateS + ' الی ' + this.inputdata.dateE     ;

 

 


   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
 
    console.log(d.outerHTML);

    var ttt = document.getElementById("tbtb");
    console.log(ttt.offsetHeight);

 
    

    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


  //  let popupWinindow
   // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
  //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 9pt "B Nazanin";
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
 
}

.subpage {
 
}



.navprintbar {
 
}

 
 


@media print {

   @page {size: A4 ;}
 
  html, body {
 
    
  }

  .page {
    margin: 0;
    
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }


.t-table {
      width: 195mm;
      margin: 1mm 6mm 0mm 6mm;
      font: 11pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;

    }

      .t-table td {
        font: 11pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        padding: 0px 5px 0px 5px;
        height: 18px;
      }

      .t-table th {
        font: 11pt "B Nazanin";
        font-weight: 400;
        border: 2px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
      }

    .t-header {
      width: 195mm;
      margin-right: 6mm;
      margin-top: 5mm;
      direction: rtl;
      font: 12pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 12pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
      }


    .grayrow {
    }
}

   
 
          </style>
        </head>
    <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}




//popupWin.document.write(`
//      <html>
//        <head>
//          <title>Print tab</title>
//          <style>
//          //........Customized style.......

// body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//  background-color: #FAFAFA;
//  font: 12pt "Tahoma";
//}

//* {
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}

//.page {
    
//  width: 297mm;
//  min-height: 210mm;
//  padding: 5mm;
//  margin: 10mm auto;
//  border: 1px #D3D3D3 solid;
//  border-radius: 5px;
//  background: white;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

 
 


// -webkit-transform: rotate(-90deg) ; 
//     -moz-transform:rotate(-90deg) ;

//     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

//}

//.subpage {
//  padding: 5mm;
//  border: 5px rgb(128, 128, 128) solid;
//  height: 200mm;
//  outline: 5mm #D3D3D3 solid;
//}



 
 


//@media print {

//   @page {size: A4  ;

// margin: -10;


//}
 
//  html, body {
 
    
//  }

//  .page {
//    margin: 0;
    
//    border: initial;
//    border-radius: initial;
//    width: initial;
//    min-height: initial;
//    box-shadow: initial;
//    background: initial;
//    page-break-after: always;
//  }
//  #section-to-print, #section-to-print * {
//    visibility: visible;
//  }

//  #section-to-print {
//    position: absolute;
//    left: 0;
//    top: 0;
//  }

//}



//          </style>
//        </head>
//    <body onload="window.print();window.close()">${d.innerHTML}</body>
//      </html>`
//);
