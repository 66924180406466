import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { JwtModule } from "@auth0/angular-jwt";
import { FileUploadModule } from 'ng2-file-upload';
import { TimeAgoPipe } from 'time-ago-pipe';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { MemberListComponent } from './members/member-list/member-list.component';
import { ListsComponent } from './lists/lists.component';
import { MessagesComponent } from './messages/messages.component';
import { appRoutes } from './routes';
import { MemberCardComponent } from './members/member-card/member-card.component';

import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { MemberDetailResolver } from './_resolvers/member-detail.resolver';
import { MemberListResolver } from './_resolvers/member-list.resolver';

import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { PhotoEditorComponent } from './members/photo-editor/photo-editor.component';
import { ArchListComponent } from './arch/arch-list/arch-list.component';
import { DevExtremeModule, DxHtmlEditorModule, DxLookupModule, DxTextBoxModule, DxTreeListModule , DxPopupModule, DxLoadPanelModule } from 'devextreme-angular'; 
import { ArchEditComponent } from './arch/arch-edit/arch-edit.component';
import { ArchPhotoEditorComponent } from './arch/archphoto-editor/archphoto-editor.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MaterialModule } from '../app/material.module';
import { WindowRefService } from './_services/window.service';
import { MatInputModule, MatAutocompleteModule, MatDialogModule, MatTabsModule, MatSidenavModule, MatListModule, MatCheckboxModule, MatTreeModule, MatTooltipModule, MatTableModule, MatSortModule, MatSnackBarModule, MatSlideToggleModule, MatSliderModule, MatSelectModule, MatRippleModule, MatProgressSpinnerModule, MatRadioModule, MatProgressBarModule, MatPaginatorModule, MatNativeDateModule, MatMenuModule, MatIconModule, MatGridListModule, MatExpansionModule, MatDividerModule, MatDatepickerModule, MatChipsModule, MatCardModule, MatButtonToggleModule, MatButtonModule, MatBottomSheetModule, MatBadgeModule } from '@angular/material';
import { ShakhzListComponent } from './app1/paye/Shakhz/Shakhz-list/Shakhz-list.component';
 
import { ShakhzEditComponent } from './app1/paye/Shakhz/Shakhz-edit/Shakhz-edit.component';
import { DeldialogComponent } from './dialog/deldialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ArchRowComponent } from './arch/arch-row/arch-row.component';
import { ArchphotoshowComponent } from './arch/Arch-photoShow/Arch-photoShow.component';
import { HesabListComponent } from './app1/paye/Hesab/Hesab-list/Hesab-list.component';
import { HesabEditComponent } from './app1/paye/Hesab/Hesab-edit/Hesab-edit.component';
import { TafsiliListComponent } from './app1/paye/Tafsili/Tafsili-list/Tafsili-list.component';
 
import { TafsiliEditComponent } from './app1/paye/Tafsili/Tafsili-edit/Tafsili-edit.component';
import { TafDasteEditComponent } from './app1/paye/TafDaste/TafDaste-edit/TafDaste-edit.component';
 
import { TafDasteListComponent } from './app1/paye/TafDaste/TafDaste-list/TafDaste-list.component';
import { SherkatListComponent } from './app1/paye/Sherkat/Sherkat-list/Sherkat-list.component';
import { SherkatEditComponent } from './app1/paye/Sherkat/Sherkat-edit/Sherkat-edit.component';
 
 
import { SaleMaliEditComponent } from './appgeneral/SaleMali/SaleMali-edit/SaleMali-edit.component';
 
import { SanadListComponent } from './app1/opr/Sanad/Sanad-list/Sanad-list.component';
import { SanadEditComponent } from './app1/opr/Sanad/Sanad-edit/Sanad-edit.component';
 
import { SabtrowComponent } from './app1/opr/Sanad/Sabt-row/Sabt-row.component';
import { HighlightDirective } from './directive';
import { ResizableDirective } from './resizable.directive';

import { CommonModule } from '@angular/common';
import { tabpComponent } from './tabp.component';
import { DialogvalidComponent } from './dialogvalid/dialogvalid.component';
import { MenurowComponent } from './menurow/Menurow.component';
import { MorurListComponent } from './app1/rep/morur/morur-list/Morur-list.component';
import { MorurHtComponent } from './app1/rep/morur/morur-ht/Morur-ht.component';
import { RizgardeshListComponent } from './app1/rep/rizgardesh/rizgardesh-list/rizgardesh-list.component';
import { RepFilterComponent } from './app1/rep/filter/RepFilter-box.component';
import { GSharhEditComponent } from './appgeneral/GSharh/GSharh-edit/GSharh-edit.component';
import { TarazListComponent } from './app1/rep/taraz/taraz-list/Taraz-list.component';
import { TarazHtComponent } from './app1/rep/taraz/taraz-ht/Taraz-ht.component';
 
import { SanadrepComponent } from './app1/opr/Sanad/Sanad-rep/Sanad-rep.component';
import { SanadRepHtComponent } from './app1/opr/Sanad/Sanad-repht/Sanad-repht.component';
import { RizgardeshHtComponent } from './app1/rep/rizgardesh/rizgardesh-ht/rizgardesh-ht.component';
import { TadilmahilatListComponent } from './app1/rep/Tadilmahilat-list/Tadilmahilat-list/Tadilmahilat-list.component';
import { SanaddublcateComponent } from './app1/opr/Sanad/Sanad-dublcate/Sanad-dublcate.component';
import { ghardeshTafsiliListComponent } from './app1/rep/ghardeshTafsili/ghardeshTafsili-list/ghardeshTafsili-list.component';
 
import { DragDropModule } from '@angular/cdk/drag-drop';
import { daftarHtComponent } from './app1/rep/taraz/daftar-ht/daftar-ht.component';
import { TadilmahilatHtComponent } from './app1/rep/Tadilmahilat-list/Tadilmahilat-ht/Tadilmahilat-ht.component';
import { sherkatInfoEditComponent } from './appsetting/SherkatInfo/SherkatInfo-edit/sherkatInfo-edit.component';
import { SanadsearchComponent } from './app1/opr/Sanad/Sanad-search/Sanad-search.component';
import { hesabHtComponent } from './app1/paye/Hesab/hesab-ht/hesab-ht.component';
import { BastanListComponent } from './app1/opr/AutoSanad/Bastan/Bastan-list/Bastan-list.component';
 
import { SanadmergeComponent } from './app1/opr/Sanad/Sanad-merge/Sanad-merge.component';
import { TetrismainComponent } from './app1/plugin/tetris/tetris-main/tetris-main.component';
import { BastanDaemComponent } from './app1/opr/AutoSanad/Bastan/Bastan-daem/Bastan-daem.component';
import { KalaListComponent } from './app2/paye/kala/kala-list/Kala-list.component';
import { AnbarListComponent } from './app2/paye/anbar/anbar-list/Anbar-list.component';
import { AnbarEditComponent } from './app2/paye/anbar/anbar-edit/anbar-edit.component';
import { VahedEditComponent } from './app2/paye/vahed/vahed-edit/Vahed-edit.component';
import { VahedListComponent } from './app2/paye/vahed/vahed-list/Vahed-list.component';
import { GrKalaListComponent } from './app2/paye/grKala/grKala-list/GrKala-list.component';
import { KalaEditComponent } from './app2/paye/kala/kala-edit/Kala-edit.component';
import { TabagheEditComponent } from './app2/paye/tabaghe/tabaghe-edit/Tabaghe-edit.component';
import { TabagheListComponent } from './app2/paye/tabaghe/tabaghe-list/Tabaghe-list.component';
import { tabapp99Component } from './tabApp99/tabapp99.component';
import { PermListComponent } from './app99/paye/perm/perm-list/Perm-list.component';
import { PermEditComponent } from './app99/paye/perm/perm-edit/Perm-edit.component';
import { HItemListComponent } from './app99/paye/HItem/HItem-list/HItem-list.component';
import { HItemEditComponent } from './app99/paye/HItem/HItem-edit/HItem-edit.component';
import { KindHazEditComponent } from './app99/paye/KindHaz/KindHaz-edit/KindHaz-edit.component';
import { KindHazListComponent } from './app99/paye/KindHaz/KindHaz-list/KindHaz-list.component';
import { BankMListComponent } from './app99/paye/BankM/BankM-list/BankM-list.component';
import { BankMEditComponent } from './app99/paye/BankM/BankM-edit/BankM-edit.component';
import { GharEditComponent } from './app99/opr/Ghar/Ghar-edit/Ghar-edit.component';
import { GharListComponent } from './app99/opr/Ghar/Ghar-list/Ghar-list.component';
import { HazMEditComponent } from './app99/opr/HazineM/HazineM-edit/HazM-edit.component';
import { HazMListComponent } from './app99/opr/HazineM/HazineM-list/HazM-list.component';
import { DarMEditComponent } from './app99/opr/DarM/DarM-edit/DarM-edit.component';
import { DarMListComponent } from './app99/opr/DarM/DarM-list/DarM-list.component';
import { RepGharListComponent } from './app99/rep/RepGhar/RepGhar-list/RepGhar-list.component';
import { RepPermListComponent } from './app99/rep/RepPerm/RepPerm-list/RepPerm-list.component';
import { ChekMListComponent } from './app99/opr/ChekM/ChekM-list/ChekM-list.component';
 
import { PayMEditComponent } from './app99/opr/PayM/PayM-edit/PayM-edit.component';
import { PayMListComponent } from './app99/opr/PayM/PayM-list/PayM-list.component';
import { TahvilMListComponent } from './app99/opr/TahvilM/TahvilM-list/TahvilM-list.component';
import { TahvilMEditComponent } from './app99/opr/TahvilM/TahvilM-edit/TahvilM-edit.component';
import { RepGharOneHtComponent } from './app99/rep/RepGhar/RepGharOne-ht/RepGharOne-ht.component';
import { DarKhastListComponent } from './app2/opr/DarKhast/DarKhast-list/DarKhast-list.component';
import { DarKhastEditComponent } from './app2/opr/DarKhast/DarKhast-edit/DarKhast-edit.component';
 
import { AnDeedListComponent } from './app2/opr/AnDeed/AnDeed-list/AnDeed-list.component';
import { AnDeedEditComponent } from './app2/opr/AnDeed/AnDeed-edit/AnDeed-edit.component';
import { AnMovaEditComponent } from './app2/opr/AnMova/AnMova-edit/AnMova-edit.component';
import { AnMovaListComponent } from './app2/opr/AnMova/AnMova-list/AnMova-list.component';
import { NoteEditComponent } from './appgeneral/Note/Note-list/Note-edit.component';
 
import { DafKolListComponent } from './app1/rep/dafKol/dafKol-list/dafKol-list.component';
import { DafKolHtComponent } from './app1/rep/dafKol/dafKol-ht/DafKol-ht.component';
import { DafKolAllHtComponent } from './app1/rep/dafKol/dafKolAll-ht/DafKolAll-ht.component';
import { AnSellEditComponent } from './app3/opr/AnSell/AnSell-edit/AnSell-edit.component';
import { AnSellListComponent } from './app3/opr/AnSell/AnSell-list/AnSell-list.component';
import { RegistryAnbarComponent } from './app2/paye/AnReg/AnReg-list/Registry-Anbar.component';
import { AnSellRepHtComponent } from './app3/opr/AnSell/AnSell-repht/AnSell-repht.component';
import { AnSellrepComponent } from './app3/opr/AnSell/AnSell-rep/AnSell-rep.component';
import { AnSellJamelistComponent } from './app3/rep/AnSelljame/AnSelljame-list.component';
import { AnBastanlistComponent } from './app2/glob/Bastan/AnBastan-list.component';
import { AnPrcBaslistComponent } from './app2/glob/AnPrcBas/AnPrcBas-list.component';
import { Morur2HtComponent } from './app1/rep/morur/morur2-ht/Morur2-ht.component';
import { Rizgardesh2HtComponent } from './app1/rep/rizgardesh/rizgardesh2-ht/Rizgardesh2-ht.component';
import { AnAmellistComponent } from './app2/glob/AnAmel/AnAmel-list.component';
import { AnDeedSanadComponent } from './app2/opr/AnDeed/AnDeed-sanad/AnDeed-sanad.component';
import { RankingListComponent } from './app1/rep/ranking/ranking-list/ranking-list.component';
import { RankingHtComponent } from './app1/rep/ranking/ranking-ht/Ranking-ht.component';
import { RankingrizHtComponent } from './app1/rep/ranking/rankingriz-ht/rankingriz-ht.component';
import { AnDeedBuyListComponent } from './app2/opr/AnDeed/AnDeedBuy-list/AnDeedBuy-list.component';
import { AnDeedBuyEditComponent } from './app2/opr/AnDeed/AnDeedbuy-edit/AnDeedBuy-edit.component';
 
import { ghardeshTafsiliHtComponent } from './app1/rep/ghardeshTafsili/ghardeshTafsili-ht/ghardeshTafsili-ht.component';
import { ghardeshTafsili2HtComponent } from './app1/rep/ghardeshTafsili/ghardeshTafsili2-ht/ghardeshTafsili2-ht.component';
import { AnSellSanadComponent } from './app3/opr/AnSell/AnSell-sanad/AnSell-sanad.component';
import { AnSellHesComponent } from './app3/paye/AnSell-Hes/AnSell-Hes.component';
import { AnDeedJameRepComponent } from './app2/rep/AnDeedjame/AnDeedJame-Rep.component';
import { VmDarEditComponent } from './app98/opr/vmDar/vmDar-edit/VmDar-edit.component';
import { VmDarListComponent } from './app98/opr/vmDar/vmDar-list/VmDar-list.component';
import { VmGrsListComponent } from './app98/paye/vmGrs/vmGrs-list/VmGrs-list.component';
import { VmGrsEditComponent } from './app98/paye/vmGrs/vmGrs-edit/VmGrs-edit.component';
import { VmVamListComponent } from './app98/opr/vmVam/vmVam-list/VmVam-list.component';
import { VmVamEditComponent } from './app98/opr/vmVam/vmVam-edit/VmVam-edit.component';
 
import { HItemGListComponent } from './app99/paye/HItemG/HItemG-list/HItemG-list.component';
import { HItemGEditComponent } from './app99/paye/HItemG/HItemG-edit/HItemG-edit.component';
import { VmTajRepService } from './_services/app98/rep/VmTajRep.service';
 
import { VmsSanadComponent } from './app98/opr/sanad/vms-sanad/Vms-sanad.component';
import { AnGardeshlistComponent } from './app2/rep/anGardesh/AnGardesh-list.component';
import { VmsHesEditComponent } from './app98/paye/VmsHes/VmsHes-Edit.component';
 
import { OprtListComponent } from './app99/paye/Oprt/Oprt-list/Oprt-list.component';
import { OprtEditComponent } from './app99/paye/Oprt/Oprt-edit/Oprt-edit.component';
import { AnDeedrepComponent } from './app2/opr/AnDeed/AnDeed-rep/AnDeed-rep.component';
import { AnDeedRepHtComponent } from './app2/opr/AnDeed/AnSell-repht/AnDeed-repht.component';
import { AnMojudilistComponent } from './app2/rep/anMojudi/AnMojudi-list.component';
import { KardexlistComponent } from './app2/rep/kardex/Kardex-list/Kardex-list.component';
import { KardexHtComponent } from './app2/rep/kardex/Kardex-ht/Kardex-ht.component';
import { Kardex2HtComponent } from './app2/rep/kardex/Kardex2-ht/Kardex2-ht.component';
import { DirSellListComponent } from './app3/opr/DirSell/DirSell-list/DirSell-list.component';
import { DirSellXlsComponent } from './app3/opr/DirSell/DirSell-xls/DirSell-xls.component';
import { DirSellEditComponent } from './app3/opr/DirSell/DirSell-edit/DirSell-edit.component';
 
import { VmTasListComponent } from './app98/opr/vmTas/vmTas-list/VmTas-list.component';
import { VmTasEditComponent } from './app98/opr/vmTas/vmTas-edit/VmTas-edit.component';
import { PdBankListComponent } from './app4/paye/PdBank/PdBank-list/PdBank-list.component';
import { PdBankEditComponent } from './app4/paye/PdBank/PdBank-edit/PdBank-edit.component';
import { PdHesListComponent } from './app4/paye/PdHes/PdBank-list/PdHes-list.component';
import { PdHesEditComponent } from './app4/paye/PdHes/PdBank-edit/PdHes-edit.component';
import { PdPayrecListComponent } from './app4/opr/PdPayrec/PdPayrec-list/PdPayrec-list.component';
import { PdPayrecEditComponent } from './app4/opr/PdPayrec/PdPayrec-edit/PdPayrec-edit.component';
import { PdMyCkListComponent } from './app4/paye/PdMyCk/PdMyCk-list/PdMyCk-list.component';
import { PdMyCkEditComponent } from './app4/paye/PdMyCk/PdMyCk-edit/PdMyCk-edit.component';
import { PdMoveListComponent } from './app4/opr/PdMove/PdMove-list/PdMove-list.component';
import { PdMoveEditComponent } from './app4/opr/PdMove/PdMove-edit/PdMove-edit.component';
 
import { VmTajReplistComponent } from './app98/rep/vmTaj/vmTajRep-list/VmTajRep-list.component';
import { vmTajRepHtComponent } from './app98/rep/vmTaj/vmTajRep-ht/vmTajRep-ht.component';
import { VmReplistComponent } from './app98/rep/vmRepVam/vmRepVam-list/VmRep-list.component';
import { VmDarReplistComponent } from './app98/rep/vmDarRep/vmDarRep-list/VmDarRep-list.component';
import { vmDarRepHtComponent } from './app98/rep/vmDarRep/vmDarRep-ht/vmDarRep-ht.component';
import { vmRepVamHtComponent } from './app98/rep/vmRepVam/vmRepVam-ht/vmRepVam-ht.component';
import { VmMahReplistComponent } from './app98/rep/vmMahRep/vmMahRep-list/vmMahRep-list.component';
import { vmMahRepHtComponent } from './app98/rep/vmMahRep/vmMahRep-ht/vmMahRep-ht.component';
import { PdsSanadComponent } from './app4/opr/sanad/Pd-sanad/Pds-sanad.component';
import { SuperReportListComponent } from './SuperAdmin/SuperReport/SuperReport-list.component';
import { PdMoveSanadListComponent } from './app4/opr/PdMove/PdMoveSanad-list/PdMoveSanad-list.component';
import { AnGarListComponent } from './app2/end/AnGar/AnGar-list/AnGar-list.component';
import { AnCountListComponent } from './app2/end/AnCount/AnCount-list/AnCount-list.component';
import { AnCountEditComponent } from './app2/end/AnCount/AnCount-edit/AnCount-edit.component';
import { AnCountDeedComponent } from './app2/end/AnCount/AnCount-Deed/AnCount-Deed.component';
import { AnCount2HtComponent } from './app2/end/AnCount/AnCount2-ht/AnCount2-ht.component';
import { AnEndListComponent } from './app2/end/AnEnd/AnEnd-list/AnEnd-list.component';
import { AnEndShowComponent } from './app2/end/AnEnd/AnEnd-show/AnEnd-show.component';
import { PdMoveGroupEditComponent } from './app4/opr/PdMove/PdMoveGroup-edit/PdMoveGroup-edit.component';
import { PdMoveGroupListComponent } from './app4/opr/PdMove/PdMoveGroup-list/PdMoveGroup-list.component';
import { AnStartListComponent } from './app2/end/AnEnd/AnStart-list/AnStart-list.component';
import { KalamergeComponent } from './app2/paye/kala/kala-merge/kala-merge.component';
import { PdMoveSimpleEditComponent } from './app4/opr/PdMove/PdMoveSimple-edit/PdMoveSimple-edit.component';
import { PdMoveReplistComponent } from './app4/rep/pdMoveRep-list/pdMoveRep-list.component';
import { CheckSadereRepComponent } from './app4/rep/CheckSadere-Rep/CheckSadere-Rep.component';
import { PdPayrecRowHtComponent } from './app4/opr/PdPayrec/PdPayrecPdMyCk-ht/PdPayrecRow-ht.component';
import { PdPayrecCheckRepComponent } from './app4/rep/PdPayrecCheck-Rep/PdPayrecCheck-Rep.component';
import { AccEditComponent } from './appsetting/Acc/Acc-edit/Acc-edit.component';
import { backuplistComponent } from './appsetting/backup-list/backup-list.component';
import { ChangpassComponent } from './appsetting/changpassw/changpass.component';
import { SettingListComponent } from './appsetting/setting-list.component';
import { userlistComponent } from './appsetting/user-list/user-list.component';
import { sherkatInfoTaxGovComponent } from './appsetting/SherkatInfo/SherkatInfo-taxgov/SherkatInfo-taxgov.component';
import { registryapp2CodeComponent } from './appsetting/registry/app2Code/registry-app2Code.component';
import { PdPayrecTazminiRepComponent } from './app4/rep/PdPayrecTazmini-Rep/PdPayrecTazmini-Rep.component';
import { registryApp3CodeComponent } from './appsetting/registry/app3Code/registry-app3Code.component';
import { PdLinkListComponent } from './app4/opr/PdLink/PdLink-list/PdLink-list.component';
import { PdPayrecResidPrintHtComponent } from './app4/opr/PdPayrec/PdPayrecResidPrint-ht/PdPayrecResidPrint-ht.component';
//import { CdkLazyDrag, CdkLazyDropList } from './lazy-drag-drop';

export function tokenGetter() {
    return localStorage.getItem('token');
}
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false }
    };
}
@NgModule({
  declarations: [
    //CdkLazyDrag,
    //CdkLazyDropList,
    PdPayrecResidPrintHtComponent,
    PdPayrecRowHtComponent,
    CheckSadereRepComponent,
    PdMoveSimpleEditComponent,
    KalamergeComponent,
     AnStartListComponent,
    PdMoveGroupEditComponent,
    PdMoveGroupListComponent,
    AnEndShowComponent,
    AnEndListComponent,
    AnCount2HtComponent,
    AnCountDeedComponent,
    AnCountEditComponent,
    AnCountListComponent,
    AnGarListComponent,
    SuperReportListComponent,
    PdsSanadComponent,
    vmMahRepHtComponent,
    VmMahReplistComponent,
    vmRepVamHtComponent,
    vmDarRepHtComponent,
    vmTajRepHtComponent,
    PdMoveReplistComponent,
    PdMoveEditComponent,
    PdMoveListComponent,
    PdMoveSanadListComponent,
    PdMyCkEditComponent,
    PdMyCkListComponent,
    PdPayrecEditComponent,
    PdPayrecListComponent,
    PdHesEditComponent,
    PdHesListComponent,
    PdBankEditComponent,
    PdBankListComponent,
    PdLinkListComponent,
    DirSellEditComponent,
    DirSellXlsComponent,
    DirSellListComponent,
    AnMojudilistComponent,
    AnDeedRepHtComponent,
    AnDeedrepComponent,

    VmTasListComponent,
    VmTasEditComponent,

    VmsHesEditComponent,
    AnGardeshlistComponent,
    VmsSanadComponent,
    VmTajReplistComponent,
    VmDarReplistComponent,
    VmReplistComponent,
    VmVamEditComponent,
    VmVamListComponent,
    VmGrsEditComponent,
    VmGrsListComponent,
    VmDarEditComponent,
    VmDarListComponent,
    AnDeedJameRepComponent,
    AnSellHesComponent,
    AnSellSanadComponent,
    backuplistComponent,
    AnDeedBuyListComponent,
    AnDeedBuyEditComponent,
    RankingrizHtComponent,
    RankingHtComponent,
    RankingListComponent,
    AnDeedSanadComponent,
    AnAmellistComponent,
    AnPrcBaslistComponent,
    AnBastanlistComponent,
    AnSellJamelistComponent,
    AnSellrepComponent,
    AnSellRepHtComponent,
    RegistryAnbarComponent,
    AnSellEditComponent,
    AnSellListComponent,
    DafKolAllHtComponent,
    DafKolHtComponent,
    DafKolListComponent,
    KardexHtComponent,
    Kardex2HtComponent,
    KardexlistComponent,
    NoteEditComponent,
    AnMovaEditComponent,
    AnMovaListComponent,
    AnDeedEditComponent,
    AnDeedListComponent,
    AccEditComponent,
    DarKhastEditComponent,
    DarKhastListComponent,
    RepGharOneHtComponent,
    PayMEditComponent,
    PayMListComponent,
    TahvilMEditComponent,
    TahvilMListComponent,
    ChekMListComponent,
    RepPermListComponent,
    RepGharListComponent,
    DarMListComponent,
    DarMEditComponent,
    HazMEditComponent,
    HazMListComponent,
    GharEditComponent,
    GharListComponent,
    BankMListComponent,
    BankMEditComponent,
    KindHazEditComponent,
    KindHazListComponent,
    HItemListComponent,
    HItemEditComponent,
    HItemGListComponent,
    HItemGEditComponent,

    PermEditComponent,
    PermListComponent,

    OprtListComponent,
    OprtEditComponent,
    PdPayrecTazminiRepComponent,
    tabapp99Component,
    TabagheListComponent,
    TabagheEditComponent,
    KalaEditComponent,
    GrKalaListComponent,
    VahedEditComponent,
    VahedListComponent,
    AnbarEditComponent,
    AnbarListComponent,
    KalaListComponent,
    BastanDaemComponent,
    TetrismainComponent,
    userlistComponent,
    BastanListComponent,
    hesabHtComponent,
    SanadsearchComponent,
    sherkatInfoEditComponent,
    TadilmahilatHtComponent,
    daftarHtComponent,
    ghardeshTafsiliHtComponent,
    ghardeshTafsili2HtComponent,
    ghardeshTafsiliListComponent,
    SanaddublcateComponent,
    SanadmergeComponent,
    TadilmahilatListComponent,
    RizgardeshHtComponent,
    Rizgardesh2HtComponent,
    SanadRepHtComponent,
    SanadrepComponent,
    ChangpassComponent,
    SettingListComponent,
    TarazHtComponent,
    TarazListComponent,
    RepFilterComponent,
    RizgardeshListComponent,
    MorurHtComponent,
    Morur2HtComponent,
    MorurListComponent,
    MenurowComponent,
    tabpComponent,
    DialogvalidComponent,
    ResizableDirective,
    HighlightDirective,
    SabtrowComponent,
    SanadListComponent,
     SanadEditComponent,
    sherkatInfoTaxGovComponent,
    PdPayrecCheckRepComponent,
    SaleMaliEditComponent,
    GSharhEditComponent,
 registryapp2CodeComponent,
 registryApp3CodeComponent,
    TafDasteListComponent,
    TafDasteEditComponent,
 
    TafsiliEditComponent,
 
    TafsiliListComponent,
    HesabEditComponent,
    HesabListComponent,
    ArchphotoshowComponent,
    ArchRowComponent,
    DeldialogComponent,
      AppComponent,
      NavComponent,
      HomeComponent,
        RegisterComponent,
    MemberListComponent,
    ArchPhotoEditorComponent,
    ArchListComponent,
    ArchEditComponent,
    ArchPhotoEditorComponent,
    ListsComponent,

        MessagesComponent,
        MemberCardComponent,
        MemberDetailComponent,
    MemberEditComponent,
    PhotoEditorComponent,
    TimeAgoPipe,
    ShakhzListComponent,
 
    ShakhzEditComponent,

    SherkatListComponent,
 
  SherkatEditComponent
   ],
  imports: [
  
    TooltipModule.forRoot(),
    DxHtmlEditorModule,
    DragDropModule,
    CommonModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    DxTreeListModule,
    DxPopupModule,
   DxLoadPanelModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
  
    DxLookupModule,
    DxTextBoxModule,
    MatAutocompleteModule,
    MatInputModule,
    MaterialModule,
    MatStepperModule,
    DevExtremeModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),

    RouterModule.forRoot(appRoutes),
    FileUploadModule,
    JwtModule.forRoot({
           config: {
               tokenGetter: tokenGetter,
         //  whitelistedDomains: ['https://localhost:44367'],
          // blacklistedRoutes: ['https://localhost:44367/api/auth']
                   whitelistedDomains: ['localhost:44367'],
           blacklistedRoutes: ['localhost:44367/api/auth']
           }
       })
  ],
  entryComponents: [DialogvalidComponent],
  providers: [ 
     WindowRefService,
       AuthService,
       AlertifyService,
       ErrorInterceptorProvider,
       MemberDetailResolver,
       MemberListResolver,
       MemberEditResolver,
      PreventUnsavedChanges,
       { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
  
   ],
  bootstrap: [AppComponent]

})
export class AppModule { }
