import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../../_models/pagination';
 
import { Localst } from '../localst.service';
import { AllM, BankM, ChekM, DarM, DarMRow, Ghar, GharRow, HazM, HazMRow, HItem, KindHaz, PerM } from '../../_models/app99/app99model';
import { RepGhar, RepGharMain, RepGharOp, RepGharPrint, RepGharPrintOp, RepPerm, RepPermOp } from '../../_models/app99/app99Repmodel';
 
 
let EmptyRepGharMain: RepGharMain = {
  sumGhar: 0,
  sumDarGhar: 0,
  sumDarMote: 0,
  sumHazGhar: 0,
  sumHazMote: 0,
  sumSud: 0,
  sumMandehGhar: 0,
  rows: [],
  op: null,
}

 

 
let EmptyBankM: RepGharOp ={

  permId: -1,
  vaziat: -1,
  dateA: 0,
  dateA_S: '',
  dateB: 99999999,
  dateB_S: '',
  mandeh: -1,
  mahShift:0
}

let EmptyRepPermOp: RepPermOp= {
  permId: null
}

let EmptyRepGharPrintOp: RepGharPrintOp ={
  gharId: -1

}
let EmptyRepGharPrint: RepGharPrint= {

  option: null,
  name: null,
  perm: null,
  visitormN: null,
  vaziat: null,
  vaziatN: null,
  dis: null,
  costAll: null,
  costTakh: null,
  rows:  [],
  unLog: null,
  dtLog: null,
  dateA_S: null,
  dateB_S: null,
  dateC_S: null,

}
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class App99RepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }



  //////////////   RepGhar
 
  RepGhar_Get(item: RepGharOp): Observable<RepGharMain> {
    let apiadress = '/api/App99Rep/RepGhar';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<RepGharMain>(this.origin + apiadress, item, { 'headers': headers });
  }

  AnDeedJameRepAsExcel(item: RepGharOp): Observable<Blob> {

    let apiadress = '/api/App99Rep/RepGharAsExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<Blob>(this.origin + apiadress, item, { 'headers': headers, responseType: 'blob' as 'json' });

  }


  RepGharMain_getEmpty(): RepGharMain {

    let l = JSON.parse(JSON.stringify(EmptyRepGharMain));
    l.rows = [];
    l.op = JSON.parse(JSON.stringify(EmptyBankM));
    return l;

  }
  RepGharOp_getEmpty(): RepGharOp {


    return JSON.parse(JSON.stringify(EmptyBankM));

  }

  RepGharOne(gharid): Observable<any> {
    let apiadress = '/api/App99Rep/RepGharOne?gharId=' + gharid;
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }

  RepPerm_Get(item: RepPermOp): Observable<RepPerm[]> {
    let apiadress = '/api/App99Rep/RepPerm';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<RepPerm[]>(this.origin + apiadress, item, { 'headers': headers });
  }

  RepPermOp_getEmpty(): RepGharOp {


    return JSON.parse(JSON.stringify(EmptyRepPermOp));

  }



  RepGharOneHt(gharId: number): Observable<any> {
    let apiadress = '/api/App99Rep/RepGharOneHt?gharId=' + gharId.toString();
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });

  }

  gettimeOnmah(mahshift: number): Observable<any> {
    let apiadress = '/api/App99Rep/gettimeOnmah?mahshift=' + mahshift.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });

  }
  

  getemptyEmptyRepGharPrint(): RepGharPrint {
    return JSON.parse(JSON.stringify(EmptyRepGharPrint));
  }

  getprint(data: RepGharPrint): string {

    let row =
      `
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
`

    //if (data.rowsAsli != null) {


    //  for (var _i = 0; _i < data.rowsAsli.length; _i++) {

    //    row = row + '<tr><td>' + moror[_i].id + '</td>' +
    //      '<td>' + moror[_i].name + '</td>' +
    //      '<td>' + moror[_i].code + '</td></tr>';



    //  }

    //}


    return `

<style>

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 20mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 1cm;
        border: 5px red solid;
        height: 257mm;
        outline: 2cm #FFEAEA solid;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }




</style>



  <div> 
<div class="book">
  <div class="page">
    <div class="subpage"><table style="width:100%">
  <tr>
    <th>لببلا</th>
    <th>Lastname</th>
    <th>Age</th>
  </tr>
  `+

      row + row + row + row


      + `
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table></div>
  </div>
  <div class="page">
    <div class="subpage">Page 2/2</div>
  </div>
</div>



</div>




`;






  }


}
