import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tadilmahilat, TadilmahilatOption } from '../../../../_models/app1/rep/Tadilmahilat';
import { TadilmahilatService } from '../../../../_services/app1/rep/Tadilmahilat.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';



 
 
@Component({
  selector: 'app-Tadilmahilat-ht',
  templateUrl: './Tadilmahilat-ht.component.html',
  styleUrls: ['./Tadilmahilat-ht.component.css']
})
 

export class TadilmahilatHtComponent implements OnInit {

 
  @Input() inputdata: any;

  orgheader: TadilmahilatOption; 
  

  nowdate: MyTimeClass;
  rowpagesumall: Tadilmahilat;
  noehesabN: string;
  mahiyat: string;

  printpage: printpage;
  constructor(private userService: UserService, private alertify: AlertifyService, private TadilmahilatService: TadilmahilatService,
    private route: ActivatedRoute,
    private router: Router,
    private localst: Localst,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {



   
  }

  ngOnInit() {

    if (this.inputdata == null)
      return;

 
    //this.orgheader = [];
    this.orgheader = this.inputdata;

   

    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
        HNoemandeh: null
    };

    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();

    let indexreaded= 0;


    let pageindex = 0;


    this.rowpagesumall = this.TadilmahilatService.getemptyTadilmahilat();
    this.rowpagesumall.hesabN = 'جمع کل'
    //برای صفحه بندی درست نیاز است جمع اخرین صفحه هم جزو ردیف ها بیاریم
   // var rowpagesummandeh = { name: 'مانده', bed: null, bes: null, mandehBed: 0, mandehBes: 0 };
    this.orgheader.rows.push(this.rowpagesumall);
   // this.orgrows.push(rowpagesummandeh);

    let datalegh = this.orgheader.rows.length;
    let MandehIndes = datalegh-1;


 

    if (datalegh == 0)
      return;

    //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
   

    while (indexreaded < datalegh) {
 

      this.printpage.pages.push(new pagep(pageindex));

      for (var i = 0; i < this.orgheader.rows.length; i++) {

        this.orgheader.rows[i].id = i + 1;
      }

      let counterpage = 0;
      var sumpage = 0;
    
      this.orgheader.rows[MandehIndes].id = null;

      while (indexreaded < datalegh) {


        ///چون جمع ها است دو ردیف اخر را نادیده میگیریم
        if (indexreaded < datalegh - 1) {
          //شماره رذدیف
         // this.orgheader.rows[indexreaded].radif = indexreaded + 1;
 
          //جمع کل
 
          this.orgheader.rows[MandehIndes].mandehBed += this.orgheader.rows[MandehIndes].mandehBed;
          this.orgheader.rows[MandehIndes].mandehBes += this.orgheader.rows[MandehIndes].mandehBes;


   

         // this.orgrows[MandehIndes].bed = 8;
          //مانده اخر
          var mandeh = this.orgheader.rows[MandehIndes].mandehBed - this.orgheader.rows[MandehIndes].mandehBes;

          if (mandeh > 0) {
            this.orgheader.rows[MandehIndes].mandehBed = mandeh;
            this.orgheader.rows[MandehIndes].mandehBes = 0;
          }

          else {
            this.orgheader.rows[MandehIndes].mandehBed = 0;
            this.orgheader.rows[MandehIndes].mandehBes = mandeh * -1;
          }
          
        }
  
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.orgheader.rows[indexreaded]);


        let skiprow = Math.floor(this.orgheader.rows[indexreaded].legpx / 450);

        if (Number.isNaN(skiprow))
          skiprow = 0;

        counterpage = counterpage + skiprow;

        indexreaded++;
        counterpage++;
        if (counterpage > 20)
          break;

      }
 



   

      pageindex++;



    }


    let leghpage =  this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }


   // this.printpage.pages[0].rowsp[0].bed =  this.printpage.pages[0].rowsp[0].bed.toString();





  }
 
  
  resetFilters() {
   
 
  }
 

 

  onFocusedRowChanged(e) {


 

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

  

    this.printpage.HMahdude = ' سال ' + this.orgheader.year + ' تا تاریخ ' + this.myTimeService.addslashtostring(this.orgheader.date);

    this.noehesabN = '';

    if (this.orgheader.noeHesab == -1)
      this.noehesabN = '(همه)';
  else  if (this.orgheader.noeHesab == 1)
      this.noehesabN = 'دائمی';
    else if (this.orgheader.noeHesab == 2)
      this.noehesabN = 'موقت';
    else if (this.orgheader.noeHesab == 3)
      this.noehesabN = 'انتظامی';


    if (this.orgheader.mahiat == -1)
      this.mahiyat = '(همه)';
   else if (this.orgheader.mahiat == 1)
      this.mahiyat = 'بدهکار';
    else if (this.orgheader.mahiat == 2)
      this.mahiyat = 'بستانکار';
    else if (this.orgheader.mahiat == 3)
      this.mahiyat = 'بدهکار بستانکار';
 
     
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
    console.log(d.outerHTML);


    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


  //  let popupWinindow
   // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
  //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 12pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: #E2E2E2;
}


 

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

     .t-table {
          width: 277mm;
          margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
            font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
           font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
  font: 12pt "B Nazanin" ;
  font-weight:400;
          width: 277mm;
         margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
          font-family: 'B Nazanin';
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }

@media print {

* {
  margin: 0;
  padding: 0;
}
 .page-break  { display:block; page-break-before:always; }
   @page {
      size: A4 landscape;
   margin: 0; 

   }
 
  html, body {
 
  width: 297mm;
  height: 210mm;
  }

 .page {

display:block; page-break-before:always;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;
 
  border-radius: 0px;
  background: white;
 
}

 

 


        .t-table {
          width: 277mm;
           margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
            font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
          width: 277mm;
        margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}

