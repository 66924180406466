import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnSell, AnSellBar, AnSellRow, AnSellRowForMabna } from '../../../_models/app3/opr/AnSell';
import { AnSellRep } from '../../../_models/app3/rep/AnSellRep';
import { AnSellJameRep, AnSellJameRepOp } from '../../../_models/app3/rep/AnSellJameRep';
 
 
 
 
let EmptyAnSellRep: AnSellRep= {
  option: null,
  ones: []
}

let EmptyAnSellJameRep: AnSellJameRep = {
  option: null,
  rows: []
}

let EmptyAnSellJameRepOp: AnSellJameRepOp= {
  codeS: 0,
  codeE: 0,
  dateS_S: null,
  dateS: 0,
  dateE_S: null,
  dateE: 0,
  year: 0,
  kind: [],
  anbars: [],
  kalaIds: [],
  tabages:[],
  hesabs: [],
  taf4s: [],
  taf5s: [],
  taf6s: [],
  gUnSelect: [
    //{ id: 1, name: 'نوع سند', colum: 'KindId' },
    //{ id: 2, name: 'کالا', colum: 'kalaC' },
    //{ id: 3, name: 'طرف مقابل', colum: 'taf4C' },
    //{ id: 4, name: 'انبار', colum: 'anbarId' },
    //{ id: 5, name: 'طبقه کالا', colum: 'TabaghId' }
    { id: 1, name: 'نوع سند', colum: 'KindId' },
    { id: 2, name: 'کالا', colum: 'kalaC' },
    { id: 3, name: 'انبار', colum: 'anbarId' },
    { id: 4, name: 'طبقه کالا', colum: 'TabaghId' },
    { id: 5, name: 'حساب معین', colum: 'HesabC' },
    { id: 6, name: 'طرف مقابل', colum: 'taf4C' },
    { id: 7, name: 'تفصیلی پنج', colum: 'taf5C' },
    { id: 8, name: 'تفصیلی شش', colum: 'taf6C' },
    { id: 9, name: 'شماره سند', colum: 'anSellCode' }

  ],
  //مدل گروه بندی انتخاب شده
  gSelect: [],
  //ایا گروهبندی از این بابت صورت گرفته؟
  //برای حذف ستون ها استفاده میشود
  isKindId: false,
  isDateA: false,
  isAnbarId: false,
  isTaf4C: false,
  isTaf5C: false,
  isTaf6C: false,
  isKalaC: false,
  isTabaghId: false,
  isHesabC:false,
  isAnSellCode:false,
  isExitNeg: true
}

//export interface AnSellJameRepRow {
//  id: number;
//  kind: number;
//  opA: number | null;
//  anSellId: number;
//  anSellCode: number;
//  anSellRowId: number | null;
//  anbarId: number;
//  dateA: number;
//  years: number;
//  vaz: number;
//  vazR: number;
//  kalaId: number;
//  kalaN: string;
//  kalaC: string;
//  amt: number;
//  amtB: number;
//  amtDone: number;
//  amtDoneB: number;
//  vahBId: number;
//  vahBN: string;
//  vahId: number;
//  vahN: string;
//  zMulti: number;
//  fi: number;
//  prc: number;
//  prcTkh: number;
//  prcHaz: number;
//  prc9: number;
//  prc10: number;
//  prc11: number;
//  darsadM: number;
//  hesabId: number | null;
//  hesabC: string;
//  hesabN: string;
//  taf4Id: number | null;
//  taf4C: string;
//  taf4N: string;
//  taf5Id: number | null;
//  taf5C: string;
//  taf5N: string;
//  taf6Id: number | null;
//  taf6C: string;
//  taf6N: string;
//  dis: string;
//  disB: string;
//  radif: number;
//  ctn: number;
//  anDeedRowId: number | null;
//  anDeedCode: number | null;
//}
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class SellRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
  constructor(private http: HttpClient, private localstService: Localst) {

 

  }
   



  getAnSellRowEmpty(): AnSellRep {


    return JSON.parse(JSON.stringify(EmptyAnSellRep));

  }

  reportHt_AnSellsPrint(item: any) :Observable<ApiRet> {
    let apiadress = '/api/app3/Rep/SellRep/report_AnSellsPrint';
    var headers = this.localstService.header_get(apiadress, null);

    // console.log( item);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getAnSellJameRepEmpty(): AnSellJameRep {

    let a = JSON.parse(JSON.stringify(EmptyAnSellJameRep));
    a.option = JSON.parse(JSON.stringify(EmptyAnSellJameRepOp));

    return a;

  }

  AnSellJameRep(item: any): Observable<ApiRet> {
    let apiadress = '/api/app3/Rep/SellRep/AnSellJameRep';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  DateRangAnSell(year): Observable<ApiRet> {
    let apiadress = '/api/App3/Rep/SellRep/DateRangAnSell?year=' + year.toString();

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }




  getfirstCodeAnSell(year: number, date: string): Observable<number> {
    
    let apiadress = '/api/App3/Rep/SellRep/getfirstCodeAnSell?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getfirstDateAnSell(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App3/Rep/SellRep/getfirstDateAnSell?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getlastCodeAnSell(year: number, date: string): Observable<number> {

    let apiadress = '/api/App3/Rep/SellRep/getlastCodeAnSell?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }

  getlastDateAnSell(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App1/Rep/SellRep/getlastDateAnSell?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }
  AnSellJameRepAsExcel(item: any): Observable<Blob> {

    let apiadress = '/api/App3/Rep/SellRep/AnSellJameRepAsExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<Blob>(this.origin + apiadress, item, { 'headers': headers, responseType: 'blob' as 'json' });

  }

  TejaratExcel(item: any): Observable<Blob> {

    let apiadress = '/api/App3/Rep/SellRep/TejaratExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<Blob>(this.origin + apiadress, item, { 'headers': headers, responseType: 'blob' as 'json' });

  }
}
