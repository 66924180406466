import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import {  VmDarRep, VmDarRepOp, VmDarRepRow } from '../../../_models/app98/rep/VmDarRep';
 
let EmptyVmDarRep: VmDarRep = {
  op: null,
  rows: [],
  rowSum: null,
};

let EmptyVmDarRepOp: VmDarRepOp = {
  tafId: null,
  tafC: null,
  tafN: null,
  kind: -1,
  isDateFilter: false,
  dateS: 0,
  dateS_S: null,
  dateE: 0,
  dateE_S: null,
  vmGrss: [],
  showTas: false
};

let EmptyVmDarRepRow: VmDarRepRow = {
  id: null,
  radif: 0,
  kind: 0,
  kindN: null,
  pId: null,
  vmGrsId: null,
  vmGrsN: null,
  dateA: null,
  dateA_S: null,
  shHesab: null,
  prc: null,
  tafId: null,
  tafC: null,
  tafN: null,
  tafFName: null,
  tafLName: null,
  mos: null,
  cRah: null,
  dis: null,
  moed: null
};
let kindList: any[] = [
  { id: -1, name: 'همه' },
  { id: 1, name: 'دریافت پس انداز' },
  { id: 2, name: 'دریافتی اقساط' },

];
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmDarRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

 
 
  getRep(op: VmDarRepOp): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getDarRep';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, op,{ 'headers': headers }).pipe();

  }
  getVmDarRepEmpty(): VmDarRep {
    let l = JSON.parse(JSON.stringify(EmptyVmDarRep)) as VmDarRep;
    l.op = JSON.parse(JSON.stringify(EmptyVmDarRepOp));
    return l;

  }
  getVmDarRepOpEmpty(): VmDarRepOp {

    return JSON.parse(JSON.stringify(EmptyVmDarRepOp));

  }
  getkindList(): any[] {

    return kindList;

  }

}
