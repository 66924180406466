import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { Kala, KalaForCombo, KalaForList } from '../../../_models/app2/paye/Kala';
import { STejarat } from '../../../_models/app2/paye/STejarat';
import { SIranCode } from '../../../_models/app2/paye/SIranCode';
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyKala: Kala = {
  radif: 0,
  id:null,
  code:null,
  name:null,
  nameE:null,
  vahedId:null,
  vahedN: null,
  vahedFormuls:[],
  tabghId:null,
  tabgh:null,
  kindId:null,
  disFani:null,
  dis:null,
  anbarKalas:[],
  sefaresh:0,
 
  sTej:null,
 
  sIranC:null,
  visib: true,
  grKalaId :null,
  grKala: null,
  _IsUsed: false,
  
  fiLst: false,
  fiDf: 0,

  selectmode :2,
  smId: null,
  sn: null,
  tp: null,

  taxId: 0,
  tax: null,
  vat: -1,
};
let EmptySTejarat: STejarat = {

  id: -1,
  shenase: null,
  vahedId: null,
  vahedN: null,
  megh: null,
 
  zarib: 0.0,
  kala: null
};
let EmptySIranCode: SIranCode = {
  id: -1,
  codeKala: null,
  vahedId: null,
  vahedN: null,
  megh: null,
 
  zarib: 0.0,
  iSIC: null,
  kala: null

};
let selectmodeList: any[] = [
  {
    id: 1,
    name: 'انتخاب از لیست'
  },
  {
    id: 2,
    name: 'دستی'
  }
]
let taxKindList: any[] = [
  {
    id: 1,
    name: 'مشمول'
  },
  {
    id: 2,
    name: 'معاف'
  }
]

let Kinds: any[] = [
  {
  id: 1,
  name: 'مواد اولیه'
},
  {
    id: 2,
    name: 'نیمه ساخته'
  },
  {
    id: 3,
    name: 'محصول'
  },
  {
    id: 4,
    name: 'دارایی ثابت'
  },
  {
    id: 5,
    name: 'سایر'
  }
];

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class KalaService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<Kala[]> {
    let apiadress = '/api/App2/Paye/Kala/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Kala[]>(this.origin + apiadress, { 'headers': headers });
  }
  getallForlist(): Observable<KalaForList[]> {
    let apiadress = '/api/App2/Paye/Kala/getallForlist';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<KalaForList[]>(this.origin + apiadress, { 'headers': headers });
  }
  getallForCombo(anbarId:number , kindP : number , formid : string): Observable<KalaForCombo[]> {
    let apiadress = '/api/App2/Paye/Kala/getallForCombo?anbarId=' + anbarId.toString() + '&kindP=' + kindP.toString() + '&formid=' + formid;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<KalaForCombo[]>(this.origin + apiadress, { 'headers': headers });
  }

 
  getbyid(id): Observable<Kala> {
    let apiadress = '/api/App2/Paye/Kala/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Kala>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: Kala): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Kala/add';
    var headers = this.localstService.header_get(apiadress,null);


 
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getKalaEmpty(): Kala {

    EmptyKala.sTej = EmptySTejarat;
    EmptyKala.sIranC = EmptySIranCode;
 
    return JSON.parse(JSON.stringify(EmptyKala));

  }

  getselectmodeList(): any[] {

    return selectmodeList;

  }
  gettaxKind(): any[] {

    return taxKindList;

  }
  getKinds(): any[] {


    return Kinds;

  }
 
  update(item: Kala) : Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Kala/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

 
  delete(KalaId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Kala/delete?id=' + KalaId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallName(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/Kala/getallName';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/Kala/getallCode';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
  getSortedVaheds(id): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Kala/getSortedVaheds?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  //کالا همراه با طبقه
  getTabagh(id): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Kala/getTabagh?kalaId=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getAsExcel(): Observable<Blob> {

    let apiadress = '/api/App2/Paye/Kala/GeAsExcel';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<Blob>(this.origin + apiadress, null, { 'headers': headers, responseType: 'blob' as 'json' });
   
  }

  MergeKala(item: any): Observable<ApiRet> {

    let apiadress = '/api/App2/Paye/Kala/MergeKala';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

}
