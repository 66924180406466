import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { ExcelService } from '../../../../_services/excel.service';
import { RizgardeshService } from '../../../../_services/app1/rep/Rizgardesh.service';
import { Rizgardesh } from '../../../../_models/app1/rep/rizgardesh';
import { LoadingService } from '../../../../_services/loading.service';
import { Localst } from '../../../../_services/localst.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-Rizgardesh-list',
  templateUrl: './Rizgardesh-list.component.html',
  styleUrls: ['./Rizgardesh-list.component.css']
})
 

export class RizgardeshListComponent implements OnInit {

  openprint: boolean;
  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;
  openedit: boolean;
  openeditId: number;
  @Input() moruroption: MorurOption;
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() OnMyprint = new EventEmitter<any>();

  @Input() ftabindex: number;
  @Input() tabindex: number;

  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
   };
 
  modlelist: Rizgardesh[];
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};
  focusedRowKey: number;
  bls1: string;
  bls2: string;
  bls3: string;
  bls4: string;
  bls5: string;
  bls6: string;
 
  selectedlevelold: number;
  selectedlevelnew: number;


  mandehbedSum: number;
  mandehbesSum: number;

  mandehbedSumAll: number;
  mandehbesSumAll: number;

  selectedlevelDBClicknew: number;
  //animal: string;
  //name: string;
  isdelete: string;
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private rizgardeshServ: RizgardeshService, private MorurService: MorurService,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private localst: Localst,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 4);
    this.openprint = false;
    this.bls1 = 'btn btn-light btndef';
    this.bls2 = 'btn btn-light btndef';
    this.bls3 = 'btn btn-light btndef';
    this.bls4 = 'btn btn-light btndef';
    this.bls5 = 'btn btn-light btndef';
    this.bls6 = 'btn btn-light btndef';
    this.selectedlevelold = -2;
    this.selectedlevelnew = -1;
    this.selectedlevelDBClicknew = -1;
    this.focusedRowKey = -1;
    if (this.moruroption == null)
    {

      this.moruroption = MorurService.getemptyoption();

     }

    this.mandehbedSum = 0;
    this.mandehbesSum = 0;

    this.mandehbedSumAll = 0;
    this.mandehbesSumAll = 0;
  }
  mytabindex: number = -1;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    this.resetFilters();
    this.isdelete = "no";
    this.loadrizgardesh();
  
  }
  

  ngOnChanges(changes: any) {
    //75685689ddd
    if (this.dxgrid != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.dxgrid.focusedRowIndex;
        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.dxgrid.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.dxgrid.instance.getCellElement(index, 0);
          var y = this.dxgrid.instance.getScrollable();

          if (yyy != undefined)
            y.scrollToElement(yyy);
          this.dxgrid.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }

  }
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = ''; 
    this.shakhsParams.Codmelli = '';
 
  }
 

 

  onFocusedRowChanged(e) {

   
//    this.selectedrow = e.row.data.id;


   // console.log(this.focusedRowKey)
    //for (var _i = 0; _i < this.modlelist.length; _i++) {
    //  var num = numbers[_i];
    //  console.log(num);
    //}

  //  console.log(JSON.stringify(this.selectedrow));

 
  }
  onRowDblClick(e) {

    if (e.key == null)
      return;

    let sabtid = e.key;
    console.log('jjj: ' +sabtid);
    if (this.modlelist == null)
      return;

    this.gotoghardesh(sabtid);

  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {



    //  return;
    //}



    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    this.bls1 = this.bls1.replace(' btnbk', '');
    this.bls2 = this.bls2.replace(' btnbk', '');
    this.bls3 = this.bls3.replace(' btnbk', '');
    this.bls4 = this.bls4.replace(' btnbk', '');
    this.bls5 = this.bls5.replace(' btnbk', '');
    this.bls6 = this.bls6.replace(' btnbk', '');


  
    if (level == 1)
      this.bls1 += ' btnbk';
   else  if (level == 2)
      this.bls2 += ' btnbk';
    else  if (level == 3)
      this.bls3 += ' btnbk';
    else if (level == 4)
      this.bls4 += ' btnbk';
    else if (level == 5)
      this.bls5 += ' btnbk';
    else if (level == 6)
      this.bls6 += ' btnbk';


 
    //this.loadrizgardeshSub(

    //  this.selectedrow.hesOrTafId,


    //);

    if (level != this.selectedlevelnew) {
      this.selectedlevelold = this.selectedlevelnew;
    }
    this.selectedlevelnew = level;
    console.log('old ' + this.selectedlevelold + 'new' + this.selectedlevelnew);



    if (this.getselectedrow() == null) {

      this.modlelist = null;

    }
    else  {
      //this.loadrizgardeshSub(
      //  this.selectedlevelold,
      //  this.getselectedrow().hesOrTafId,
      //  this.selectedlevelnew
      //);
    }

  }


 
  loadrizgardesh() {

    if (this.moruroption == null)
      return;


    this.loadServ.showloading(this.loaddata, 0);
    this.rizgardeshServ.getRizghardesh(this.moruroption).subscribe((data) => {
      //Rizgardesh class
      this.loadServ.hideloading(this.loaddata, 0);
      this.modlelist = data;

      if (this.modlelist != null) {
        if (this.modlelist.length > 0)
          this.focusedRowKey = this.modlelist[0].id;

        this.mandehbedSum = 0;
        this.mandehbesSum = 0;

        for (var i = 0; i < this.modlelist.length; i++) {


         this.mandehbedSum +=  this.modlelist[i].bedMan;
         this.mandehbesSum +=  this.modlelist[i].besMan;


        }
      
        this.mandehbedSumAll = this.mandehbedSum - this.mandehbesSum;
        this.mandehbesSumAll = 0;

        if (this.mandehbedSumAll < 0) {
          this.mandehbesSumAll = this.mandehbedSumAll * -1;
          this.mandehbedSumAll = 0;
        }
         

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  } 
  calculateSelectedRow(options) {


    if (options.name === "SelectedRowsSummary") {
      if (options.summaryProcess === "start") {
        options.totalValue = 0;
      } else if (options.summaryProcess === "calculate") {
          options.totalValue = 889;
      }
      options.totalValue = 889;
    }




   // options.totalValue = 889;
    //if (options.name === "SelectedRowsSummary") {
    //  if (options.summaryProcess === "start") {
    //    options.totalValue = 0;
    //  } else if (options.summaryProcess === "calculate") {
    //    if (options.component.isRowSelected(options.value.ID)) {
    //      options.totalValue = 889;
    //    }
    //  }
    //}

   // options.totalValue = this.mandehbedSumAll = 0;

  }
   
  mtemp: Rizgardesh;
  getselectedrow() : Rizgardesh {
 

    var r = this.dxgrid.focusedRowKey;
    if (r == null) return null;
    if (this.modlelist == null) return null;

   // console.log(this.focusedRowKey)
    for (var _i = 0; _i < this.modlelist.length; _i++) {

      //if (this.focusedRowKey == this.modlelist[_i].id) {
         

      //  return this.modlelist[_i];
      //}
 
    }

    return null;


  }
  test() {

    var para = document.createElement('p');
    var title = document.createElement('title');

    // define some window attributes
    var features = 'width=1200, height=900, status=1, menubar=1, location=0, left=100, top=100';
    var winName = 'print';

    // populate the html elements
    para.textContent = '<p>fghjfgjghkjgh</p>';
    title.textContent = 'New Window Title';
     


    var winRef = window.open('', winName, features);


  
   // winRef.document.write(this.rizgardeshService.getprint(this.modlelist));








  }

  onClickghardesh() {

    var item = this.dxgrid.selectedRowKeys;

    if (item == null)
      return;



    for (var i = 0; i < item.length; i++) {

      this.gotoghardesh(i);
    }


 


  }

  gotoghardesh(rowidkey : number) {


    var option = { "HPSanadId": -1, "HPSabtId": -1, "HPOpenOnInit": false };
    for (var i = 0; i < this.modlelist.length; i++) {

      if (this.modlelist[i].id == rowidkey) {

        //console.log(JSON.stringify(this.modlelist[i]));
        var option = { "HPSanadId": this.modlelist[i].sanadId, "HPSabtId": this.modlelist[i].id, "HPOpenOnInit": true };
        //sanadid = this.modlelist[i].sanadId;

        break;
      }

    }


    if (option.HPSanadId != -1)
      this.OnMyLoadSanadEvent.emit(option);



  }
  onClickReflesh() {

    this.loadrizgardesh();
  }

  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' ریز گردش ');

  }
 
  onClickprint(kind) {


    var t = [];
    for (var i = 0; i < this.modlelist.length; i++) {

      t.push(this.modlelist[i]);
    }


    var mdata = { orgrows: t, orgheader: this.moruroption, printKind: kind }

    this.OnMyprint.emit(mdata);

  }
  saveuioption() {

    // console.log(this.gridContainer.instance.getVisibleColumns()[1].width);

    var v = this.dxgrid.instance.getVisibleColumns();


    var res = [];
    for (var i = 0; i < v.length; i++) {
      res.push(v[i].width);

    }

    this.localst.optioncli.ui_rizghardesh_grid_width = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      //  {
      //  location: 'before',
      //  widget: 'dxButton',
      //  options: {
      //    width: 136,
      //    text: 'Collapse All',
      //    onClick: this.collapseAllClick.bind(this)
      //  }
      //},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'arrowdown',
          onClick: this.toolbardown.bind(this)
        }
      });


  }

  toolbardown(e) {
     
    console.log('j');
  }
}


