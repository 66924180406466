import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Shakhz } from '../../../_models/app1/paye/Shakhz';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { shakhzForPrint } from '../../../_models/app1/paye/shakhzForPrint';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { IdName } from '../../../_models/General/IdName';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyShakhz: Shakhz = {



  id: null,

  code: null,

  onvan: null,

  onvanName: null,


  nameFarsi: null,

  familyFarsi: null,

  nameEnglish: null,

  familyEnglish: null,
  nameMostar: null,
  shenasnameCode: null,
  sexId: null,

  sex: null,



  goruh: null,

  meliyat: null,


  dinId: null,

  din: null,
  mazhabId: null,

  mazhab: null,

  tahsilatId: null,

  tahsilat: null,



  marryId: null,

  marry: null,
  dateMarrry: null,
  dateMarrryS: null,

  shomareShenasname: null,

  seryalShenasname: null,
   
  namePedar: null,

  mahalTavalodId: null,

  mahalTavalod: null,

  dateTavalod: null,
  dateTavalodS: null,
  dateSodur: null,
  dateSodurS: null,


  mahalSodurId: null,

  mahalSodur: null,



  tafsilis: null,
  tafsilisId: null,

  radif: -1,

  ostanCode: null,
  shahrCode: null,

  ostanName: null,

  shahrName: null,

  adress: null,

  codePost: null,

  mobile: null,

  tell: null,

  email: null,

  codeMelli: null,
  cnaghsh: null,

  shEghtesadi:null,
  _ignore_sh: false,
  visib: true,
    dis:null,
      tob: null,
  tobN: null,
      shobe:null
};
let HeaderShakhz: any = {

  id: "Id",
  code: "کد",
  onvan: "عنوان",
  onvanName: "عنوان",
  nameFarsi: "نام",
  familyFarsi: "نام خانوادگی",
  nameEnglish: 'نام لاتین',

  familyEnglish: 'نام خانوادگی لاتین',
  nameMostar: 'نام خانوادگی لاتین',
  shenasnameCode: 'نام خانوادگی لاتین',
  sexId: '',
  sex: 'جنسیت',
  goruh: 'گروه',
  meliyat: 'ملیت',
  dinId: '',
  din: 'دین',
  mazhabId: '',
  mazhab: 'مذهب',
  tahsilatId: '',

  tahsilat: 'تحصیلات',

  marryId: '',

  marry: 'وضعید تجرد',
  dateMarrry: 'تاریخ ازدواج',
  shomareShenasname: 'شماره شناسنامه',
  seryalShenasname: 'سریال شناسنامه',
  namePedar: 'نام پدر',

  mahalTavalodId: '',
  mahalTavalod: 'محا تولد',

  dateTavalod: 'تاریخ تولد',
  dateSodur: 'تاریخ صدور',
  mahalSodurId: '',
  mahalSodur: 'محل صدور',
  tafsilisId: '',

  radif: -1,
  ostanCode: 'کد استان',
  shahrCode: 'کد شهر',
  ostanName: 'نام استان',
  shahrName: 'نام شهر',
  adress: 'آدرس',
  codePost: 'کدپستی',
  mobile: 'موبایل',
  tell: 'تلفن',
  email: 'ایمیل',
  codeMelli: 'کد ملی',


};

let toblist : IdName[] =[
  { id: 1, name: "حقیقی" },
  //{ id: 2, name: "حقوقی" },
  { id: 3, name: "مشاركت مدنی" },
  { id: 4, name: "اتباع غیر ایرانی" },
  { id: 5, name: "مصرف کننده نهایی" },

];


//????????
//this.baseUrl

@Injectable({
  providedIn: 'root'
})
export class ShakhzService {
 // baseUrl = environment.apiUrl;
  origin = location.origin;
  

  constructor(private http: HttpClient, private localstService: Localst) { }

  getShakhzs(): Observable<Shakhz[]> {

    let apiadress = '/api/App1/Paye/Shakhz/getall';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<Shakhz[]>(this.origin + apiadress, { 'headers': headers });
  }
  getShakhzsnopage(): Observable<Shakhz[]> {

    let apiadress = '/api/App1/Paye/Shakhz/getallnopage';
    var headers = this.localstService.header_get(apiadress,null);
     
 
    return this.http.get<Shakhz[]>(this.origin + apiadress, { 'headers': headers });
  }
  getShakhzs_P(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Shakhz[]>> {
    let apiadress = '/api/App1/Paye/Shakhz/getall';
    var headers = this.localstService.header_get(apiadress,null);


    const paginatedResult: PaginatedResult<Shakhz[]> = new PaginatedResult<Shakhz[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (userParams != null) {
      params = params.append('NameFarsi', userParams.NameFarsi);
      params = params.append('FamilyFarsi', userParams.FamilyFarsi );
      params = params.append('Code', userParams.Code );
 
    }
 
    return this.http.get<Shakhz[]>(this.origin + apiadress, { observe: 'response', 'headers': headers ,params })
      .pipe(
        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
    );
   
    //for (var val of getlist) {

    //  console.log(val); // prints values: 10, 20, 30, 40
    //}
   // console.log(getlist[0].Onvan);
 

  }


 
  getShakhz(id): Observable<Shakhz> {
    let apiadress = '/api/App1/Paye/Shakhz/getone?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


  
    let v = this.http.get<Shakhz>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  getShakhzByTafsiliid(tafsiliid): Observable<Shakhz> {
    let apiadress = '/api/App1/Paye/Shakhz/getonebytafsili?tafsiliid=' + tafsiliid.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
   
    let v = this.http.get<Shakhz>(this.origin + apiadress, { 'headers': headers }).pipe( );

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  addshakhz(item: Shakhz): Observable<ApiRet> {
    let apiadress = '/api/App1/Paye/Shakhz/addShakhz';
    var headers = this.localstService.header_get(apiadress,null);

   
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getShakhzEmpty(): Shakhz {

 
    return JSON.parse(JSON.stringify(EmptyShakhz));

  }
  getTafsiliHeader(): any {

    return HeaderShakhz;


  }
  updateshakhz(item: Shakhz) :Observable<ApiRet> {
    let apiadress = '/api/App1/Paye/Shakhz/updateShakhz';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  deleteshakhz(ShakhzId): Observable<number> {
    let apiadress = '/api/App1/Paye/Shakhz/deletebyidh?id=' + ShakhzId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<number>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

  }
 
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Shakhz/countshakhzcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });


  }
  shakhzListCode(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Shakhz/shakhzcodelist';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }

  codeMellilist(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Shakhz/listCodeMelli';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });

  }

  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }

  gettoblist(): any {

    return toblist;


  }

  shts: shakhzForPrint[];
  sh: shakhzForPrint;
  convertToShakhzForPrint(items: Shakhz[]): shakhzForPrint[] {

    this.shts = [];


    for (var i = 0; i < items.length; i++) {

      this.sh.adress = 'h';
      this.sh.code = items[i].code;
      this.sh.codeMelli = items[i].codeMelli;
      this.sh.codePost = items[i].codePost;
      this.sh.dateMarrryS = items[i].dateMarrryS;
      this.sh.dateSodurS = items[i].dateSodurS;
      this.sh.dateTavalodS = items[i].dateTavalodS;
      this.sh.din = items[i].din;
      this.sh.email = items[i].email;
      this.sh.familyEnglish = items[i].familyEnglish;
      this.sh.familyFarsi = items[i].familyFarsi;
      this.sh.goruh = items[i].goruh;
      this.sh.mahalSodur = items[i].mahalSodur;
      this.sh.marry = items[i].marry;
      this.sh.mazhab = items[i].mazhab;
      this.sh.meliyat = items[i].meliyat;
      this.sh.mobile = items[i].mobile;
      this.sh.nameEnglish = items[i].nameEnglish;
      this.sh.nameFarsi = items[i].nameFarsi;
      this.sh.nameMostar = items[i].nameMostar;
      this.sh.namePedar = items[i].namePedar;
      this.sh.onvanName = items[i].onvanName;
      this.sh.ostanCode = items[i].ostanCode;
      this.sh.ostanName = items[i].ostanName;
      this.sh.seryalShenasname = items[i].seryalShenasname;
      this.sh.sex = items[i].sex;
      this.sh.shahrCode = items[i].shahrCode;
      this.sh.shahrName = items[i].shahrName;
      this.sh.shenasnameCode = items[i].shenasnameCode;
      this.sh.tahsilat = items[i].tahsilat;
      this.sh.tell = items[i].tell;
     


    }






    return null;
  }
}
