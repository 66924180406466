import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule, DxValidationGroupComponent} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { VahedService } from '../../../../_services/app2/paye/Vahed.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { Vahed } from '../../../../_models/app2/paye/Vahed';
  

@Component({
  selector: 'app2-Vahed-edit',
  templateUrl: './Vahed-edit.component.html',
  styleUrls: ['./Vahed-edit.component.css']
})
export class VahedEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: Vahed;
  myModel_old: Vahed;
 
 
  @ViewChild('aatttaa', { static: false }) aatttaa: DxValidationGroupComponent;

 Vahednamelist: string[];
 vtax: any[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private VahedService: VahedService,
 
    private tafsiliService: TafsiliService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.VahedService.getVahedEmpty();
    this.Vahednamelist = [];
 
  }

  ngOnInit() {
 
 
    this.loadServ.showloading(this.loaddata, 1);
    this.VahedService.getallName().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.Vahednamelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



    this.loadServ.showloading(this.loaddata, 2);
    this.VahedService.getallTaxGov().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.vtax = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.VahedService.getVahedEmpty();
 

      this.isnew = true;
 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.VahedService.getbyid(this.editId).subscribe(
        (data : Vahed) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          

 

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }


  addthis = e => {


    var r = this.aatttaa.instance.validate();

    if (r.isValid)
      this.onFormSubmit(null);
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    

    console.log(JSON.stringify(this.myModel));
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.VahedService.add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.VahedService.update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    if (this.editId != -1)
    if (this.myModel_old.name != null)
      if (this.myModel_old.name == v)
        return true;

    for (var val of this.Vahednamelist) {
      if (v == val) return false;
    }

    return true


  }
 
  vtaxchanged(e) {
    var h = e.component.option('selectedItem');
    if (h == null)
      return;




    this.myModel.taxGovC = h.taxGovC;
    this.myModel.taxGovN = h.taxGovN;

  }

  validateNumber(e) {

    

  }
}
