import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import { TahvilM, TahvilMRow, HItem, PerM } from '../../../../_models/app99/app99model';
import { Localst } from '../../../../_services/localst.service';
 
  

@Component({
  selector: 'app99-TahvilM-edit',
  templateUrl: './TahvilM-edit.component.html',
  styleUrls: ['./TahvilM-edit.component.css']
})
export class TahvilMEditComponent implements OnInit {

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: TahvilM;
  myModel_old: TahvilM;
  focusedRowKey: number;
 
   perMlist: any[]=[];
   HItemlist: HItem[]=[];
  gharlist: any[]=[];
 

  selectedrow: TahvilMRow;
 TahvilMcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  selectedtab: number;


 
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
 
    private localst: Localst,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
    this.selectedtab = 0;
    this.focusedRowKey = -1;
    this.myModel = this.app99Service.TahvilM_getTahvilMEmpty();
    this.TahvilMcodelist = [];
  

    this.selectedrow = this.app99Service.EmptyTahvilMRow_getEmpty();
  }

  ngOnInit() {
 
    this.loadother();
    




    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.TahvilM_getallCods().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.TahvilMcodelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.app99Service.TahvilM_getTahvilMEmpty();
      try {
        this.myModel.dateA_S = this.localst.dateEmruz.dateString;
    

      }
      catch {

      }
   
 

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.TahvilM_getbyid(this.editId).subscribe(
        (data : TahvilM) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
        

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
         

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }


 
  addthis = e => {
    
    
  }

  loadother() {

    this.loadServ.showloading(this.loaddata, 4);
    this.app99Service.PerM_getallForList(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.perMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


    this.loadServ.showloading(this.loaddata, 5);
    this.app99Service.HItem_getall().subscribe(
      (data: HItem[]) => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.HItemlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 5);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


   
  }

  
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

    let m = [];
    if (this.myModel.rows.length == 0) {
      m.push('هیچ رکوردی وارد نشده است');
      this.alertify.showListString(m);

      return;

    }
 
 
    

   // console.log(JSON.stringify(this.myModel));

    console.log(this.myModel);
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.TahvilM_add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.TahvilM_update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    //if (this.editId != -1)
    //if (this.myModel_old.cMelli != null)
    //  if (this.myModel_old.cMelli == v)
    //    return true;

    for (var val of this.TahvilMcodelist) {
      if (v == val) return false;
    }

    return true


  }
 
 

  validateNumber(e) {

    

  }

  onSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }

  hItemchanged(e, cellInfo) {

    var indexrow = this.dataGrid.focusedRowIndex;
    var indexcolum = this.dataGrid.focusedColumnIndex;
      var previousValue = e.previousValue;
    var newValue = e.value;
    let s = null;
    for (var r of this.HItemlist) {
      if (r.id == newValue) {
        s = r;
        break;
      }
     
    }
    cellInfo.setValue(e.value)
    this.dataGrid.instance.cellValue(indexrow, 1, 1);
    this.dataGrid.instance.cellValue(indexrow, 2, s.costFi);
    this.dataGrid.instance.cellValue(indexrow, 3, s.costFi);
    this.dataGrid.instance.cellValue(indexrow, 4, s.costFi);
    this.dataGrid.instance.cellValue(indexrow, 5, s.costFi);


    this.calcsum();
  }
  cntchanged(e, cellInfo) {

  
    let dr = cellInfo.row.data;
    cellInfo.setValue(e.value);

    if (e.value > dr.cntman) {

      cellInfo.setValue(dr.cntman);
    }
    else {
      cellInfo.setValue(e.value);
    }
    this.calcsum();
  }
  costchanged(e, cellInfo) {

    var indexrow = this.dataGrid.focusedRowIndex;
    var indexcolum = this.dataGrid.focusedColumnIndex;
    var newValue = e.value;

    cellInfo.setValue(newValue);

    let cnt = this.dataGrid.instance.cellValue(indexrow, 1);


    this.dataGrid.instance.cellValue(indexrow, 3, cnt * newValue);

    this.calcsum();
  }
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    for (var _i = 0; _i < this.myModel.rows.length; _i++) {


      if (this.myModel.rows[_i].id == f.id) {

 
        this.selectedrow = this.myModel.rows[_i];
      }



    }




    //console.log('selectedrow : ' + this.selectedrow.id);
    //this.focusedRowKey = this.selectedrow.id;
    //console.log(e.rowIndex);


    //this.pushnewrow(true);

    //console.log('now index' + e.row.rowIndex);
    //console.log('now index hesabid' + e.row.data.hesabId);
    //if (e.row.data.hesabId == null) {
    //  this.dataGrid.instance.editRow(e.row.rowIndex);
 

    //}
    //// else this.dataGrid.instance.cancelEditData();

    //this.tafg_reflesh(this.selectedrow.hesabId);

  }
  ononInitNewRow(e) {



  }
  calcsum( ) {
  }
  onpermchanged(e) {

    if (e.value == null)
      return;



    this.loadServ.showloading(this.loaddata, 6);
    this.app99Service.Ghar_GetAllAsGhartiny(-1, e.value).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.gharlist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }
  ongharchanged(e) {


    if (e.value == null)
      return;

    this.loadServ.showloading(this.loaddata, 6);
    this.app99Service.TahvilM_loaddataRow(this.editId, e.value).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.myModel.rows = data; 

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }
 
  onClickReflesh() {

    this.loadother();


  }
  
}
