import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
import { parse } from 'querystring';
import { GSharh } from '../../_models/General/GSharh';
import { IdName } from '../../_models/General/IdName';
import { Localst } from '../localst.service';
import { Yaddasht } from '../../_models/General/yaddasht';
 
 

 
let EmptyYaddasht: Yaddasht = {

  id: null,


  un: null,
  dbn: null,
  title: null,
  text: null,
  sort: null,

};
 
@Injectable({
  providedIn: 'root'
})
export class YaddashtService {
  baseUrl = environment.apiUrl;
  origin = location.origin;
  datalist: Yaddasht[];
 
 
  constructor(private http: HttpClient, private localstService: Localst) { }

  getyyyyyList(): Yaddasht[] {
    this.datalist = [];
    this.datalist.push(JSON.parse(JSON.stringify(EmptyYaddasht)));
    this.datalist.push(JSON.parse(JSON.stringify(EmptyYaddasht)));
    this.datalist[0].title = 'tit1';
    this.datalist[1].title = 'tit2';
    return this.datalist;
  }

 

  getGSharhs(): Observable<GSharh[]> {
    let apiadress = '/api/General/GSharh/getall';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<GSharh[]>(this.origin + apiadress, { 'headers': headers });
  }

  

 
  getGSharhbyid(id): Observable<GSharh> {
    let apiadress = '/api/General/GSharh/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);
 
    let v = this.http.get<GSharh>(this.origin + apiadress, { 'headers': headers }).pipe(
     
    );
  
    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  getGSharhBycodesal(id): Observable<GSharh> {
    let apiadress = '/api/General/GSharh/getbyCodeSal?CodeSal=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<GSharh>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  addGSharh(item: GSharh) {
    let apiadress = '/api/General/GSharh/add';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.post(this.origin + apiadress,item, { 'headers': headers });
  }



  getGSharhEmpty(): Yaddasht {

    return JSON.parse(JSON.stringify(EmptyYaddasht)); 


  }
 
  updateGSharh(item: GSharh) {

    let apiadress = '/api/Paye/GSharh/update';
    var headers = this.localstService.header_get(apiadress,null);
  

    return this.http.post(this.origin + apiadress,item, { 'headers': headers });
  }

 
  deleteGSharh(GSharhId: number) {
    let apiadress = '/api/General/GSharh/delete?id=' + GSharhId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
 
  }
 
  countCode(item: string) {
    let apiadress = '/api/GSharh/countGSharhcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  GSharhListCode(): Observable<string[]> {
    let apiadress = '/api/General/GSharh/GSharhcodelist';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
}
