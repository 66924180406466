import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';
import { LoadingService } from '../../../../_services/loading.service';
 
import { ApiRet } from '../../../../_models/General/ApiRet';
import { GeneralService } from '../../../../_services/General/General.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PdPayrecResidPrint } from '../../../../_models/app4/opr/PdPayrec';
import { PdPayrecService } from '../../../../_services/app4/opr/PdPayrec.service';






@Component({
  selector: 'app4-PdPayrecResidPrint-ht',
  templateUrl: './PdPayrecResidPrint-ht.component.html',
  styleUrls: ['./PdPayrecResidPrint-ht.component.css']
})


export class PdPayrecResidPrintHtComponent implements OnInit {


  @Input() inputdata: any;
 
  unsafeImageUrl: string;
  public imageUrl: any;
  nowdate: MyTimeClass;

  loaddata: loadingCl;
  pVeiw: any = { myinfo: true }
  asSmalltemp: boolean=false;


  model: PdPayrecResidPrint[];
  printpage: any;
  constructor(private userService: UserService, private alertify: AlertifyService,
 
    private pdPayrecService: PdPayrecService,
 
    private generalfoService: GeneralService,
    private sanitizer: DomSanitizer,
    private localst: Localst,
    private loadServ: LoadingService,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);
  
 
  let temp=  this.localst.sherkatsList_selected_get();

    if (temp.dbaseName == 'tzcu1tivan')
      this.asSmalltemp = true;


  }
  havLogo: boolean = true;
  title: string = 'بدون عنوان';
  ngOnInit() {


     
 //sfdert546456g564564
    this.loadServ.showloading(this.loaddata, 0);
    this.pdPayrecService.getPdPayrecResidPrint(this.inputdata).subscribe((res: ApiRet) => {
      this.model = res.data;

      this.loadServ.hideloading(this.loaddata, 0);
      this.createAllobject();
    }, error => {
      this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
    });

 
     

    this.loadServ.showloading(this.loaddata, 1);
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;

      this.loadServ.hideloading(this.loaddata, 1);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
     
    });

 

   
    this.generalfoService.getSherkatInfoLogo().subscribe(data => {

      if (data.size == 14) {
        this.havLogo = false;
    
      }
      else {
        this.unsafeImageUrl = URL.createObjectURL(data);
        // ftruy56r856865
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
        this.havLogo = true;
      }
  
    }, error => {
      console.log(error);
    });;


  }
  createAllobject() {

    this.printpage = [];
    for (var i = 0; i < this.model.length; i++) {


      this.printpage.push(this.createOneobject(this.model[i]));
    }


  }
  sumall: any;
  obtemp: any;
  createOneobject(hedtemp: PdPayrecResidPrint): any {

    this.obtemp = null;

    this.obtemp = {
      pages: [],
      header: null,
      sumall: null
    };
 

    this.obtemp.header = hedtemp;
  


    let indexreaded = 0;
    let pageindex = 0;
    let datalegh = hedtemp.rows.length;


    // console.log(datalegh);

    if (datalegh == 0)
      return;

 

    while (indexreaded < datalegh) {


      this.obtemp.pages.push(new pagep(pageindex));


      let counterpage = 0;


      while (indexreaded < datalegh) {

 

        //درج ردیف در صفحه
        this.obtemp.pages[pageindex].rowsp.push(hedtemp.rows[indexreaded]);



        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        //fgdredtg547بلی
        ctx.font = '10pt B Nazanin';
         
     


        const metrics = ctx.measureText(hedtemp.rows[indexreaded].dis).width;

        let skiprow = Math.floor(metrics / 200);


        //if (Number.isNaN(skiprow))
        //  skiprow = 0;




        counterpage = counterpage + skiprow;


        //صفحه اخر یهخ ردیف کمتر باشه
        if (datalegh  > indexreaded + 1)
          if (hedtemp.rows[indexreaded + 1].kind == -1)
          counterpage++;

        indexreaded++;
        counterpage++;
        if (counterpage > 7)
          break;






 
      }





      pageindex++;



    }



   // console.log(JSON.stringify(this.obtemp));

    let leghpage = this.obtemp.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.obtemp.pages[i].ppage = i + 1;
      this.obtemp.pages[i].ppages = leghpage;



    }

    if (leghpage > 0)
      this.obtemp.pages[leghpage - 1].islastpage = true;

    return JSON.parse(JSON.stringify(this.obtemp));

  }




  resetFilters() {


  }




  onFocusedRowChanged(e) {





  }
  onClickedickNew() {





  }


  onClickDelete() {



  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        // this.dodelete();

      }

    });
  }


 

  onClickEdit() {



  }
  onClickNew() {




  }
  OnMyCancelEvent(e) {


  }

  OnMySaveEvent(e) {



  }

  onClicklevel(level: number) {


  }


  onDBClicklevel(level: number) {




  }

  onClickprint() {


    var d = document.getElementById("d");
    console.log(d.outerHTML);


    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


    //  let popupWinindow
    // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
    //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
    
body{
  font: 8pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: white;
}


 

.page {
  size: A4;
  font: 8pt "B Nazanin" ;
  font-weight:400;


  height: 292mm;
  width: 205mm;

  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}
 .t-table {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
      font: 8pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;
    }

      .t-table td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        padding: 0px 2px 0px 2px;
        height: 18px;
        word-break: break-all;
      
      }

      .t-table th {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 2px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
    
      }

    .t-header {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
      direction: rtl;
      font: 8pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
        /*border: 1px solid black;*/
      }

    .t-headerB {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
 
      direction: rtl;
      font: 9pt "B Nazanin";
      font-weight: 800;
      text-align: right;
    }

      .t-headerB td {
        padding: 5px 5px 5px 5px;
        /*border: 1px solid black;*/
      }


    .grayrow {
    }
   


@page {
  size: A4;
   margin: 0;
}
@media print {

  html, body {

    width: 210mm;
    height: 292mm;
  }
    div.centerOnPrintedPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }
  /* ... the rest of the rules ... */

body {
  width: 100%;
  height: 100%;
  padding: 0mm;
  margin: 0mm auto;

  font: 8pt "B Nazanin";
}


 .page {

display:block; page-break-before:always;
 height: 292mm;
  width: 205mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;

  border-radius: 0px;


}

  .t-table {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
      font: 8pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;
    }

      .t-table td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        padding: 0px 2px 0px 2px;
        height: 18px;
        word-break: break-all;
      
      }

      .t-table th {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 2px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
    
      }

    .t-header {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
      direction: rtl;
      font: 8pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
        /*border: 1px solid black;*/
      }

    .t-headerB {
      width: 190mm;
      margin: 0mm 7mm 0mm 7mm;
 
      direction: rtl;
      font: 9pt "B Nazanin";
      font-weight: 800;
      text-align: right;
    }

      .t-headerB td {
        padding: 5px 5px 5px 5px;
        /*border: 1px solid black;*/
      }


    .grayrow {
    }

   

}

  
 
          </style>
        </head>
    <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}
