import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { ArchPerson } from '../_models/archperson';
 
 
@Injectable({
  providedIn: 'root'
})
export class ArchPersonService {
  baseUrl = environment.apiUrl;
 
  constructor(private http: HttpClient) {}

  getArchPersons(): Observable<ArchPerson[]> {

   
    console.log('get : ' + this.baseUrl + 'ArchPerson/getall');
    return this.http.get<ArchPerson[]>(this.baseUrl + 'ArchPerson/getall');
  }
 
  

 
}
