import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
 
 
import { parse } from 'querystring';
import { AlertifyService } from '../../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Localst } from '../../../../../_services/localst.service';
import { LoadingService } from '../../../../../_services/loading.service';
import { ExcelService } from '../../../../../_services/excel.service';
import { DeldialogComponent } from '../../../../../dialog/deldialog.component';
import { TadilformulService } from '../../../../../_services/app1/petty/Tadilformul.service';
import { tadilformul, tadilformulName } from '../../../../../_models/app1/petty/tadilformul';
import { Hesab } from '../../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../../_services/app1/paye/Hesab.service';
import { TafsiliForGroupRead } from '../../../../../_models/app1/paye/tafsiliForGroupRead';
import { TafsiliService } from '../../../../../_services/app1/paye/Tafsili.service';
import { TafsiliGbydaste } from '../../../../../_models/app1/paye/tafsiliGbydaste';
import { bastanOp } from '../../../../../_models/app1/opr/autoSanad/bastanOp';
import { AutoSanadService } from '../../../../../_services/app1/opr/AutoSanad.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MyTimeService } from '../../../../../_services/myTime.service';
import { SaleMaliService } from '../../../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../../../_models/General/saleMali';
import { SanadService } from '../../../../../_services/app1/opr/Sanad.service';
 

 

@Component({
  selector: 'app-Bastan-daem',
  templateUrl: './Bastan-daem.component.html',
  styleUrls: ['./Bastan-daem.component.css']
})
  

export class BastanDaemComponent implements OnInit {
  butebastandaemclass: string;
  buteftetahclass: string;
  buttadilclass: string;
 



  shoecnf: boolean;
  shoeftetah: boolean;
  shotadil: boolean;
  bastandaemDate :string;
  starteftetahDate :string;
  starttadilDate: string;
 
 
  messtyleA: string;
  messtyleB: string;
  messtyleC: string;
  messA: string;
  messB: string;
  messC: string;
  @Input() kindpage: string;
  salA: SaleMali;
  salB: SaleMali;


 bastaop: bastanOp;
  loaddata: loadingCl;
  constructor( private alertify: AlertifyService,
    private route: ActivatedRoute,
    private localst: Localst,
    private loadServ: LoadingService,
    private autoSanadService: AutoSanadService,
    private sanadService: SanadService,
    private myTimeService: MyTimeService,
    private saleMaliService: SaleMaliService,
 
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 10);
    this.shoecnf = false;
    this.shoeftetah = false;
    this.shotadil = false;
    this.bastandaemDate = this.localst.salemali.dateE_s;
    this.starteftetahDate = this.localst.salemali.dateS_s;
    this.starttadilDate = this.localst.salemali.dateS_s;

  
    this.salA = saleMaliService.getSaleMaliEmpty();
    this.salB = saleMaliService.getSaleMaliEmpty();

    this.buteftetahclass = 'mydisble';
    this.buttadilclass = 'mydisble';
    this.butebastandaemclass = 'mydisble';
  }
 // bastandaem
  //starteftetah
  //starttadil
  ngOnInit() {
    this.messtyleA = 'float:right;';
    this.messtyleB = 'float:right;';
    this.messtyleC = 'float:right;';

    let codA = -1;
    let codB = -1;
    //uuuuuuuuuuuru
    if (this.kindpage == 'bastandaem') {
      codA = this.localst.salemali.codeSal;
      codB = codA + 1;
    }
    if (this.kindpage == 'starteftetah' || this.kindpage == 'starttadil' ) {
      codB = this.localst.salemali.codeSal;
      codA = codB - 1;
    }

    this.loadServ.hideloading(this.loaddata, 1);
    this.saleMaliService.getSaleMaliBycodesal(codA).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (data != null)
      this.salA = data;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.loadServ.hideloading(this.loaddata, 2);
    this.saleMaliService.getSaleMaliBycodesal(codB).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 2);
      if (data != null)
      this.salB = data;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


   //  400 اختتامیه
    this.loadServ.hideloading(this.loaddata, 8);
    this.sanadService.countSanadOnKind(codA, 400).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 8);
      if (data != null) {
        if (data == 0) {
          this.butebastandaemclass = 'myenable';
        }
        else {
          this.messA = 'سند صادر شده است';
        }
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



          //افتتاحیه500
    this.loadServ.hideloading(this.loaddata, 3);
    this.sanadService.countSanadOnKind(codB,500).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (data != null) {
        if (data == 0) {
          this.buteftetahclass = 'myenable';
        }
        else {
          this.messB = 'سند صادر شده است';
        }


        if (data > 0) {
          //تعدیل اول دوره 600
          this.loadServ.hideloading(this.loaddata, 4);
          this.sanadService.countSanadOnKind(codB, 600).subscribe(data2 => {
            this.loadServ.hideloading(this.loaddata, 4);
            if (data2 != null) {
              if (data2 == 0) {
                this.buttadilclass = 'myenable';
              }
              else {
                this.messC = 'سند صادر شده است';
              }
            }

          }, error => {
            this.loadServ.hideloading(this.loaddata, 4);
            this.alertify.error(error);
            console.log("myerror:" + error)
          });







        }
      }
       
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    
    
  }
 


  onClickDobastandaem() {
    let dint = this.myTimeService.getDateAsInt32(this.bastandaemDate);

    if (dint > this.salA.dateE) {
      this.messtyleA = 'mesred';
      this.messA = ' بزرگترین تاریخ مجاز سدور سند ' + this.salA.dateE_s + ' میباشد ';
      return;
    }
    if (dint < this.salA.dateS) {
      this.messtyleA = 'mesred';
      this.messA = ' کوچکترین تاریخ مجاز سدور سند ' + this.salA.dateE_s + ' میباشد ';
      return;
    }
    this.shoecnf = true;
  }

  onClickDoeftetah() {
    let dint = this.myTimeService.getDateAsInt32(this.starteftetahDate);

    if (dint > this.salB.dateE) {
      this.messtyleB = 'mesred';
      this.messB = ' بزرگترین تاریخ مجاز سدور سند ' + this.salB.dateE_s + ' میباشد ';
      return;
    }
    if (dint < this.salB.dateS) {
      this.messtyleB = 'mesred';
      this.messB = ' کوچکترین تاریخ مجاز سدور سند ' + this.salB.dateE_s + ' میباشد ';
      return;
    }
    this.shoeftetah = true;
  }

  onClickDotadil() {
    let dint = this.myTimeService.getDateAsInt32(this.starttadilDate);

    if (dint > this.salB.dateE) {
      this.messtyleC = 'mesred';
      this.messC = ' بزرگترین تاریخ مجاز سدور سند ' + this.salB.dateE_s + ' میباشد ';
      return;
    }
    if (dint < this.salB.dateS) {
      this.messtyleC = 'mesred';
      this.messC = ' کوچکترین تاریخ مجاز سدور سند ' + this.salB.dateE_s + ' میباشد ';
      return;
    }
    this.shotadil = true;
  }



  onClickNo() {
    this.shoecnf = false;
  }
  onClickYes() {
  

    this.loadServ.showloading(this.loaddata, 5);
    this.autoSanadService.createbastandaermi(this.salA.codeSal, this.bastandaemDate).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 5);
      let sanadid = data;
      if ( sanadid == -2) {
        this.messtyleA = 'mesred';
        this.messA = 'خطا:  اسناد در وضعیت یادداشت قرار دارند';
        this.alertify.error(this.messA);
          
      }
      else if (sanadid == -3) {
        this.messtyleA = 'mesred';
        this.messA = 'خطا:  هیچ سندی یافت نشد';
        this.alertify.error(this.messA);

      }
      else if (sanadid == -5) {
        this.messtyleA = 'mesred';
        this.messA = 'خطا:  ماهیت بعضی از حساب ها اشتباه است';
        this.alertify.error(this.messA);

      }
      else if ( sanadid > 0) {
        this.messtyleA = 'mesgreen';
        this.messA = 'سند با موفقیت ایجاد شد';
        this.butebastandaemclass = 'myenable';
        this.alertify.success(this.messA);
   

      }
      this.shoecnf = false;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }



  /////////////



  onClickNoeftetah() {
    this.shoeftetah = false;
  }
  onClickYeseftetah() {



    this.loadServ.showloading(this.loaddata, 6);
    this.autoSanadService.createstartefteteh(this.salB.codeSal, this.starteftetahDate).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      let sanadid = data;
      if ( sanadid == -2) {
        this.messtyleB = 'mesred';
        this.messB = 'خطا: اسناد در وضعیت یادداشت قرار دارند';
        this.alertify.error(this.messB);

      }
     else if (sanadid == -3) {
        this.messtyleB = 'mesred';
        this.messB = 'خطا:  هیچ سندی یافت نشد';
        this.alertify.error(this.messA);
         
      }
      else if ( sanadid > 0) {
        this.messtyleB = 'mesgreen';
        this.messB = 'سند با موفقیت ایجاد شد';
        this.buteftetahclass = 'mydisble';
        this.buttadilclass = 'myenable';
        this.alertify.success(this.messB);
      }
      this.shoeftetah = false;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }


  /////////////



  onClickNotadil() {
    this.shotadil = false;
  }
  onClickYetadil() {

    this.loadServ.showloading(this.loaddata, 7);
    this.autoSanadService.createstarttadil(this.salB.codeSal, this.starttadilDate).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 7);
      let sanadid = data;
      if (sanadid == -2) {
        this.messtyleC = 'mesred';
        this.messC = 'خطا:  اسناد در وضعیت یادداشت قرار دارند';
        this.alertify.error(this.messC);

      }
      else if (sanadid == -3) {
        this.messtyleC = 'mesred';
        this.messC = 'خطا:  هیچ سندی یافت نشد';
        this.alertify.error(this.messA);

      }
      else if (sanadid > 0) {
        this.messtyleC = 'mesgreen';
        this.messC = 'سند با موفقیت ایجاد شد';
        this.alertify.success(this.messC);

      }
      this.shotadil = false; 
    
    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
}


