import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { DxTreeViewComponent } from 'devextreme-angular';
import { finalize } from 'rxjs/operators';
import { HesabEditComponent } from '../Hesab-edit/Hesab-edit.component';
import { parse } from 'querystring';
import { LoadingService } from '../../../../_services/loading.service';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-Hesab-list',
  templateUrl: './Hesab-list.component.html',
  styleUrls: ['./Hesab-list.component.css']
})


export class HesabListComponent implements OnInit {
  @ViewChild('treeView', { static: false }) treeView: DxTreeViewComponent;
 
  @ViewChild(HesabEditComponent, { static: false }) private myChild: HesabEditComponent;

  @Output() OnMyprint = new EventEmitter<any>();
listcodsum: string[];
  hesabs: any[];
  hesabslist: Hesab[];
  hesabslistold: any[];
 
  hesabsselected: Hesab;
  records2: any;
  user: User = JSON.parse(localStorage.getItem('user'));
  hhh: any;
  isnew: boolean;
  isenable: boolean;

  loaddata: loadingCl;

  constructor(private userService: UserService, private alertify: AlertifyService,
    private route: ActivatedRoute,
    private hesabService: HesabService,
    private loadServ: LoadingService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata,5);
    this.cloneIconClick = this.cloneIconClick.bind(this);
    this.cloneIconeditClick = this.cloneIconeditClick.bind(this);
    this.cloneIconDeleteClick = this.cloneIconDeleteClick.bind(this);
    this.hesabsselected = this.hesabService.getHesabEmpty();
    this.hesabsselected.id = -2;
    this.isnew = false;
    this.hesabslist = [];
    this.hesabslistold = [];

    console.log('m:onst');
    this.listcodsum = [];
  }

  ngOnInit() {
    this.refleshhesabs();
    console.log('m:init');
   

   // this.myTree.selectItem(document.getElementById('home'));
   }
  onClicksearch() {


    console.log('m:searching...filter');
  }
  onClicksearchall() {


    console.log('m:searching... all');
  }





  reloadrows(): void {

  }
  treeViewSelectionChanged(e) {
    this.syncSelection(e.component);
  }
  treeViewContentReady(e) {
    this.syncSelection(e.component);
  }

  syncSelection(treeView) {


  }
  treeViewIntilized(e) {
    // this.treeView = e.component;

    // console.log('m:'+this.treeView.parentIdExpr);
  }

  treeViewonRowClick(e) {

 
    if (e.data != null)
    this.hesabsselected = e.data as Hesab;	 // FILL THE ARRAY WITH DATA.
    // this.hesabsselected.code = 'uuu';
    //  this.hesabsselected = this.hesabService.getHesabEmpty2();

    // this.syncSelection(e.component);

    // console.log(JSON.stringify(this.hesabsselected));


    // Convert String obect to JSON
    //this.stringJson = JSON.stringify(this.courseList);
    //console.log("String json object :", this.stringJson);
    //console.log("Type :", typeof this.stringJson);

    // ConvertjSON to an object
    //this.stringObject = JSON.parse(this.stringJson);
    //console.log("JSON object -", this.stringObject);
  }
  test2() {

   // this.treeView.items.


    //this.isenable = true;

  }
  cloneIconClick(e) {

    if (this.loaddata.showed)
      return;

    this.isenable = true;
    this.isnew = true;
  //  let a = e.row.data;
    let a = this.hesabsselected;
    var hesabforadd = this.hesabService.getHesabEmpty();

    if (a.treeNum >= 3) {

      return;
    }

    hesabforadd.name = '';
    hesabforadd.id = -1;
    hesabforadd.codeP = a.codeSum;
    hesabforadd.treeNum = a.treeNum + 1;
    hesabforadd.treeHesdId = a.treeId;
    hesabforadd.treeId = -1;
    hesabforadd.code = '';
    hesabforadd.kindId = a.kindId;
    hesabforadd.kindN = a.kindN;
    hesabforadd.mahiyatId = null;
    hesabforadd.codeSum = hesabforadd.codeP + '';
 


    //اگر گروه بود و کل ایجاد کردیم
    if (a.treeNum == 1) {

      //این حساب کل است
      hesabforadd.groupId = a.id;
      hesabforadd.groupCode = a.codeSum;
      hesabforadd.groupName = a.name;

    }
    //اگر کل بود و معین ایجاد کردیم
    if (a.treeNum == 2) {


      
      hesabforadd.groupCode = a.groupCode;
      hesabforadd.groupId = a.groupId;
      hesabforadd.groupName = a.groupName;
       //این حساب معین است
      hesabforadd.kolId = a.id;
      hesabforadd.kolCode = a.codeSum;
      hesabforadd.kolName = a.name;

    }

 

 

   this.hesabsselected = hesabforadd;
    console.log(hesabforadd.codeP);
    console.log(JSON.stringify(a));
  }


  evHesabChanged() {
    
    this.isenable = false;
    console.log("savedevent");
    this.refleshhesabs();
  }
  test() {
    console.log("test");
    this.refleshhesabs();
  }

  refleshhesabs() {

    this.listcodsum = [];
    this.loadServ.showloading(this.loaddata,0);
    this.hesabService.getHesabsBySumName()
      .subscribe((res: Hesab[]) => {
        this.loadServ.hideloading(this.loaddata,0);
        //مشخص میکنیم کدام حساب قبل از ابدیت باز شده تا بعدا جایگذاری کنیم
        if (this.hesabslist != null) {
          this.hesabslistold = [];
          for (var t = 0; t < this.hesabslist.length; t++) {
            this.hesabslistold.push({id: this.hesabslist[t].id, expanded: this.hesabslist[t].expanded,});

          }
 
        }
       

        this.hesabslist = res;

        if (this.hesabslist != null)
          for (var i = 0; i < this.hesabslist.length; i++) {

            if (this.hesabslistold.length == 0 && this.hesabslist[i].treeNum==0)
            this.hesabslist[i].expanded = true;


            for (var j = 0; j < this.hesabslistold.length; j++) {

              if (this.hesabslist[i].id == this.hesabslistold[j].id) {

                if (this.hesabslistold[j].expanded)
                  this.hesabslist[i].expanded = true;
                else
                  this.hesabslist[i].expanded = false;
                
              }

            }


            this.listcodsum.push(this.hesabslist[i].codeSum);
          }

 

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      }).add(() => {

      });;

   
   
 


  }
 

  onSelectionChanged(e) {

    this.isenable = false;

    if (e.itemData == null)
      return;

    this.hesabsselected = e.itemData;


   // console.log(JSON.stringify(e.itemData));

  }

  cloneIconDeleteClick(e) {

    if (this.loaddata.showed)
      return;

    if (this.hesabsselected == null)
      return; 

    console.log(this.hesabsselected.id);
 
  //   let a = e.row.data;
   // console.log(a.id);

    this.deleteD(this.hesabsselected.id);
  }

  cloneIconeditClick(e) {

    if (this.loaddata.showed)
      return;
  //  this.myChild.ngOnChanges(null);

    if (this.hesabsselected == null)
      return;

    this.isenable = true;
   
    console.log(this.hesabsselected.id);
 
  }
  deleteD(deleteid: number) {

      console.log(deleteid);
    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodeleteHesab(deleteid);

      }

    }, error => {
 
      this.alertify.error(error);
    });
  }
 
  dodeleteHesab(deleteid: number) {

    
    this.loadServ.showloading(this.loaddata,2);
    this.hesabService.deletebyid(deleteid).subscribe(data => {
      this.loadServ.hideloading(this.loaddata,2);
      let dd = data;
      // 0  وجود ندارد
      // 1  پاک شد
      // 2  اجازه پاک شدن ندارد
      // 3  اجازه پاک شدن ندارد
      if (dd == 1)
        this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

      if (dd == 2)
        this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

      if (dd == 3)
        this.alertify.warning('این کد مورد دارای زیر مجموعه میباشد و قابلیت حذف ندارد');

       if (dd == 0)
        this.alertify.warning('این کد در سرور یافت نشد');

       if (dd == -2)
        this.alertify.warning('این رکورد مورد استفاده قرار گرفته و قابلیت حذف ندارد');

      console.log("m:deleted successful");
      this.refleshhesabs();


    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
    //  this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
 



  data: any[];
  // prepare the data
  source;

  // create data adapter & perform Data Binding.
  dataAdapter;
  // get the tree items. The first parameter is the item's id. The second parameter is the parent item's id. The 'items' parameter represents 
  // the sub items collection name. Each jqxTree item has a 'label' property, but in the JSON data, we have a 'text' field. The last parameter 
  // specifies the mapping between the 'text' and 'label' fields.  
  records: any;




  onClickprint() {


   

    this.OnMyprint.emit(true);

  }
}


