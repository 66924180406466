import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { environment } from '../../../environments/environment';
import { Localst } from '../localst.service';
import { ApiRet } from '../../_models/General/ApiRet';
import { Menrow } from './menurow.service';
import { User } from '../../_models/user';
 
 
 
 
 
@Injectable({
  providedIn: 'root'
})
export class AccService {
  baseUrl = environment.apiUrl;
  origin = location.origin;


  menuRows: any[];
  isSuperAdmin: boolean =false;

  constructor(private http: HttpClient, private localstService: Localst)
  {

    this.menuRows = [];
  }
  
  initialize(cu :string, un:string): Observable<ApiRet> {
    let apiadress = '/api/General/Acc/initialize?cu=' + cu + '&un=' + un ;
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
 
  }
  getAccOnUcKey(cu: string, un: string, isAllowMe:boolean): Observable<ApiRet> {
    let apiadress = '/api/General/Acc/getAccOnUcKey?cu=' + cu + '&un=' + un + '&isAllowMe=' + isAllowMe;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  UpdateAllow(data: any[]): Observable<ApiRet> {
    let apiadress = '/api/General/Acc/UpdateAllow';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress,data, { 'headers': headers });

  }
}
