import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Shakhz } from '../../../_models/app1/paye/Shakhz';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { shakhzForPrint } from '../../../_models/app1/paye/shakhzForPrint';
import { Localst } from '../../localst.service';
import { tadilformul, tadilformulName } from '../../../_models/app1/petty/tadilformul';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyTadilformulNames: tadilformulName = {

  sid: null,
  text: null,
    years:null
};
let EmptyTadilformuls: tadilformul = {

  id: null,
  sid: null,
  years: null,
  text: null,
  darsad: 0.0,
  groupId: null,
  groupCode: null,
  groupName: null,
  kolId: null,
  kolCode: null,
  kolName: null,
  hesabId: null,
  hesabName: null,
  hesabCode: null,
  tafsili4Id: null,
  tafsili4Name: null,
  tafsili4Code: null,
  tafsili5Id: null,
  tafsili5Name: null,
  tafsili5Code: null,
  tafsili6Id: null,
  tafsili6Name: null,
  tafsili6Code: null
};


@Injectable({
  providedIn: 'root'
})
export class TadilformulService {
 // baseUrl = environment.apiUrl;
  origin = location.origin;
  

  constructor(private http: HttpClient, private localstService: Localst) { }

  getTadilformulNames(years : any): Observable<tadilformulName[]> {

    let apiadress = '/api/App1/Petty/Tadilformul/getTadilformulNames?years=' + years;
    var headers = this.localstService.header_get(apiadress,null);

    console.log(apiadress);
    return this.http.get<tadilformulName[]>(this.origin + apiadress, { 'headers': headers });
  }

  getTadilformulbySid(sid: any): Observable<tadilformul[]> {

    let apiadress = '/api/App1/Petty/Tadilformul/getTadilformul?sid=' + sid;
    var headers = this.localstService.header_get(apiadress,null);

    console.log(apiadress);
    return this.http.get<tadilformul[]>(this.origin + apiadress, { 'headers': headers });
  }
 
  getTadilformulEmplty(): tadilformul {

    return JSON.parse(JSON.stringify(EmptyTadilformuls))
  }
  getTadilformulNameEmplty(): tadilformulName {

    return JSON.parse(JSON.stringify(EmptyTadilformulNames))
  }


  addTadilformul(data: tadilformul[]): Observable<tadilformulName> {

    let apiadress = '/api/App1/Petty/Tadilformul/addTadilformul' ;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<tadilformulName>(this.origin + apiadress, data, { 'headers': headers });
  }
  updateTadilformul(data: tadilformul[]): Observable<tadilformulName> {

    let apiadress = '/api/App1/Petty/Tadilformul/updateTadilformuls';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<tadilformulName>(this.origin + apiadress, data, { 'headers': headers });
  }
  deleteTadilformul(sid: any): Observable<any> {

    let apiadress = '/api/App1/Petty/Tadilformul/deleteTadilformul?sid=' + sid;
    var headers = this.localstService.header_get(apiadress,null);

    console.log(apiadress);
    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
    //return this.http.post<tadilformulName[]>(this.origin + apiadress, { 'headers': headers });
  }
}
