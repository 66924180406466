import { Injectable } from '@angular/core';
import SecureStorage from 'secure-web-storage';
const SECRET_KEY = 'secret_key';
import CryptoJS from 'crypto-js';



@Injectable({
  providedIn: 'root'
})
export class CryptoService {
 
  footerkey: string = 'o860L8u4K';

  constructor() { }

  encryptData(msg, encryptSecretKey : string) {
  
    encryptSecretKey = encryptSecretKey.replace('/', '').replace('/', '') + this.footerkey;
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
      keySize: keySize / 32,
      iterations: 100
    });

    var iv = CryptoJS.lib.WordArray.random(128 / 8);

    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));

    return result;
  }
}
