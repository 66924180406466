import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import {  Taraz } from '../../../../_models/app1/rep/Taraz';

import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../../_models/General/saleMali';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Diri, MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { Dir } from '@angular/cdk/bidi';
import { tarazHesabsFilter } from '../../../../_models/app1/rep/tarazHesabsFilter';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LoadingService } from '../../../../_services/loading.service';
import { DaftarPack } from '../../../../_models/app1/rep/DaftarPack';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import * as FileSaver from 'file-saver';

export interface DialogData {
  animal: string;
  name: string;
}


//let ListdoingTasks: Diri[] = [
//  { level: 1, levelName: 'گروه', sort: -1, htidName: '', htid: -1, htidCode: '' },
//  { level: 2, levelName: 'کل', sort: -1, htidName: '', htid: -1, htidCode: '' },
//  { level: 3, levelName: 'معین', sort: -1, htidName: '', htid: -1, htidCode: '' },


//];

//let ListplannedTasks: Diri[] = [


//  { level: 4, levelName: 'سطح چهار', sort: -1, htidName: '', htid: -1, htidCode: '' },
//  { level: 5, levelName: 'سطح پنج', sort: -1, htidName: '', htid: -1, htidCode: '' },
//  { level: 6, levelName: 'سطح شش', sort: -1, htidName: '', htid: -1, htidCode: '' },

//];

let ListallTasks: Diri[] = [

  { level: 1, levelName: 'گروه', sort: -1, htidName: '', htid: -1, htidCode: '' , selectedId:0 },
  { level: 2, levelName: 'کل', sort: -1, htidName: '', htid: -1, htidCode: '', selectedId: 0 },
  { level: 3, levelName: 'معین', sort: -1, htidName: '', htid: -1, htidCode: '', selectedId: 0 },
  { level: 4, levelName: 'سطح چهار', sort: -1, htidName: '', htid: -1, htidCode: '', selectedId: 0 },
  { level: 5, levelName: 'سطح پنج', sort: -1, htidName: '', htid: -1, htidCode: '', selectedId: 0 },
  { level: 6, levelName: 'سطح شش', sort: -1, htidName: '', htid: -1, htidCode: '', selectedId: 0 },

];


@Component({
  selector: 'app-Taraz-list',
  templateUrl: './Taraz-list.component.html',
  styleUrls: ['./Taraz-list.component.css']
})


export class TarazListComponent implements OnInit {
 
  openprint: boolean;
  @Output() OnRizGhardesh = new EventEmitter<MorurOption>();
  @Output() OnMyprint = new EventEmitter<any>();
  @Output() OnMyprintDaftar = new EventEmitter<any>();

  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;

  openedit: boolean;
  openeditId: number;

  diableexcel: string;

  morurOption: MorurOption;
  tarazHesabslistorginal: tarazHesabsFilter[];
  tarazHesabslist: tarazHesabsFilter[];
 

  doingTasks: Diri[];
  plannedTasks: Diri[];

  modlelist: Taraz[];
  modlelDaftar: DaftarPack;
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};
  focusedRowKey: 1;
  bls1: string;
  bls2: string;
  bls3: string;
  bls4: string;
  bls5: string;
  bls6: string;
 
  selectedlevelold: number;
  selectedlevelnew: number;
  selectedlevelDBClicknew: number;
  //animal: string;
  //name: string;
  isdelete: string;


  loaddata: loadingCl;

  constructor(private userService: UserService, private alertify: AlertifyService,
    private loadServ: LoadingService,
    private route: ActivatedRoute,
    private MorurService: MorurService,
    private hesabService: HesabService,
    private sanadService: SanadService,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 6);
    this.openprint = false;
    this.bls1 = 'btn btn-light btndef disbal';
    this.bls2 = 'btn btn-light btndef disbal';
    this.bls3 = 'btn btn-light btndef disbal';
    this.bls4 = 'btn btn-light btndef disbal';
    this.bls5 = 'btn btn-light btndef disbal';
    this.bls6 = 'btn btn-light btndef disbal';
    this.selectedlevelold=-2;
    this.selectedlevelnew = -1;
    this.selectedlevelDBClicknew = -1;
    this.tarazHesabslist = [];
    this.tarazHesabslistorginal = [];
  //  var o = MorurService.getemptytarazHesabsFilter();
  //  o.id = 1;
  //  o.name = 'jhhj';
  //  this.tarazHesabslist.push(o);
  //  var e = MorurService.getemptytarazHesabsFilter();
 //   e.id = 2;
  //  this.tarazHesabslist.push(e);
    this.diableexcel = "mydisble";
  }

  getdirilevel(level : number) : Diri {

   
    let dirtemp = new Diri();
    if (level == 1) {
     
      dirtemp.level = 1;
      dirtemp.levelName = 'گروه';
      dirtemp.sort = -1;
 

    }
    if (level == 2) {
 
      dirtemp.level = 2;
      dirtemp.levelName = 'کل';
      dirtemp.sort = -1;
   
    }
    if (level == 3) {
      
      dirtemp.level = 3;
      dirtemp.levelName = 'معین';
      dirtemp.sort = -1;
 
    }
    if (level == 4) {
     
      dirtemp.level = 4;
      dirtemp.levelName = 'سطح چهار';
      dirtemp.sort = -1;
     
    }
    if (level == 5) {
 
      dirtemp.level = 5;
      dirtemp.levelName = 'سطح پنج';
      dirtemp.sort = -1;
   
    }
    if (level == 6) {
     
      dirtemp.level = 6;
      dirtemp.levelName = 'سطح شش';
      dirtemp.sort = -1;
    
    }

    return JSON.parse( JSON.stringify( dirtemp));

  }


  ngOnInit() {

    //this.doingTasks = ListdoingTasks;

    //this.plannedTasks = ListplannedTasks;
    this.isdelete = "no";

    
    this.morurOption = this.MorurService.getemptyoption();
    this.morurOption.kindReport = null;
    //تمام نوع سند را در وضعیت فعال قرار میدهیم
    this.morurOption.KindSanad = this.sanadService.getKinds();
    this.morurOption.hesabKind = this.hesabService.gethesabKinds();

    // this.morurOption = this.MorurService.getemptyoption();

    this.loadServ.showloading(this.loaddata, 2);
    
    this.MorurService.GettarazHesabsFilterRowsData().subscribe((data) => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.tarazHesabslist = data;
   
      this.tarazHesabslist[0].selected = [];
      this.tarazHesabslist[1].selected = [];
      this.tarazHesabslist[2].selected = [];
      this.tarazHesabslist[3].selected = [];
      this.tarazHesabslist[4].selected = [];
      this.tarazHesabslist[5].selected = [];

     // this.tarazHesabslist[0].selected.push(4005);
      this.tarazHesabslistorginal = JSON.parse(JSON.stringify(this.tarazHesabslist))
    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      console.log("myerror:" + error)
    }); 

  }
  onDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onAdd(e) {
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  onRemove(e) {
    e.fromData.splice(e.fromIndex, 1);
  }   
 
  onFocusedRowChanged(e) {


   // console.log(JSON.stringify(this.tarazHesabslist[0].selected));
  //  console.log(JSON.stringify(this.tarazHesabslist[1].selected));

   // this.selectedrow = e.row.data;

   // console.log(this.focusedRowKey)
    //for (var _i = 0; _i < this.modlelist.length; _i++) {
    //  var num = numbers[_i];
    //  console.log(num);
    //}

  //  console.log(JSON.stringify(this.selectedrow));

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {
    //  return;
    //}
    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }


 
 

 

  loadTarazsub( oprate: string) {

    if (this.done.length == 0)
      return;

    if (this.morurOption.kindReport == null) {

      this.alertify.message('نوع گزارش مشخص نشده');
    }

  
    this.morurOption.tarazOlaviat = [];
    //for (var i = 0; i < this.doingTasks.length; i++ ) {

    //  this.doingTasks[i].sort = i+1;
    //  this.morurOption.tarazOlaviat.push( JSON.parse( JSON.stringify( this.doingTasks[i])));
    //}


    for (var i = 0; i < this.done.length; i++) {

      for (var y = 0; y < ListallTasks.length; y++) {

        if (ListallTasks[y].levelName == this.done[i]) {

          this.morurOption.tarazOlaviat.push(JSON.parse(JSON.stringify(ListallTasks[y])));


        }

      }
    }
    if (this.morurOption.tarazOlaviat.length == 0) {
      console.log('done selected list is 0 ');
      return;
    }

    if (this.tarazHesabslist.length) {

      //فیلتر حساب ها
      if (this.tarazHesabslist[0].selected != null)
        this.morurOption.l1 = this.tarazHesabslist[0].selected;

      if (this.tarazHesabslist[1].selected != null)
        this.morurOption.l2 = this.tarazHesabslist[1].selected;

      if (this.tarazHesabslist[2].selected != null)
        this.morurOption.l3 = this.tarazHesabslist[2].selected;

      if (this.tarazHesabslist[3].selected != null)
        this.morurOption.l4 = this.tarazHesabslist[3].selected;


      if (this.tarazHesabslist[4].selected != null)
        this.morurOption.l5 = this.tarazHesabslist[4].selected;

      if (this.tarazHesabslist[5].selected != null)
        this.morurOption.l6 = this.tarazHesabslist[5].selected;

    }

   // console.log(JSON.stringify(this.morurOption));


    if (this.morurOption.kindReport == 'taraz') {
      if (oprate == 'print') {

        this.loadServ.showloading(this.loaddata, 0);
        this.MorurService.getTarazs(this.morurOption).subscribe((data) => {
          this.loadServ.hideloading(this.loaddata, 0);
          this.modlelist = data;

          var mdata = { orgrows: this.modlelist, orgheader: this.morurOption }

          this.OnMyprint.emit(mdata);


        }, error => {
          this.loadServ.hideloading(this.loaddata, 0);
          this.alertify.error(error);
          console.log("myerror:" + error)
        });
      }

    }

    else if (this.morurOption.kindReport == 'daftar') {



      if (oprate == 'excel') {



        this.loadServ.showloading(this.loaddata, 3);
        this.MorurService.getDaftarAsExcel(this.morurOption).subscribe((buffer) => {
          this.loadServ.hideloading(this.loaddata, 3);

          //let file = new Blob([buffer.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          //let fileName = buffer.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
          //FileSaver.saveAs(file, fileName);


          const data: Blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
          });
          FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

          // FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'hdffdhfdjgj' + '.xlsx');

        }, error => {
          this.loadServ.hideloading(this.loaddata, 3);
          this.alertify.error(error);
          console.log("myerror:" + error)
        });



      }
        if (oprate == 'print') {

          this.loadServ.showloading(this.loaddata, 1);
          this.MorurService.getDaftar(this.morurOption).subscribe((data) => {
            this.loadServ.hideloading(this.loaddata, 1);
            this.modlelDaftar = data;

            //  var mdata = { orgrows: this.modlelist, orgheader: this.morurOption }

            this.OnMyprintDaftar.emit(this.modlelDaftar);


          }, error => {
            this.loadServ.hideloading(this.loaddata, 1);
            this.alertify.error(error);
            console.log("myerror:" + error)
          });
        }

    }
  }
  onClickbackdir(diri: Diri) {


    if (diri == null)
      return;


    console.log(diri.level);
    if (this.morurOption.dir == null) {
      return;
    }

   // this.morurOption.dir.push(sam);
    var temp = this.morurOption.dir;
    this.morurOption.dir = []
      ;
    for (var i=0; i < temp.length; i++) {
      if (temp[i].sort < diri.sort) {
       //فقط ما قبل بماند
        this.morurOption.dir.push(temp[i]);
      }
      else {

      }
        
    }

    this.disableDirbutton(15,true);
    for (var j = 0; j < this.morurOption.dir.length; j++) {


      this.disableDirbutton(this.morurOption.dir[j].level,false);

    }
    this.morurOption.nowlevel = diri.level;

    this.loadTarazsub('print');



  }
 

  disableDirbutton(level: number , reset : boolean) {


    if (reset) {
      this.bls1 = this.bls1.replace('disbal', '');
      this.bls2 = this.bls1.replace('disbal', '');
      this.bls3 = this.bls1.replace('disbal', '');
      this.bls4 = this.bls1.replace('disbal', '');
      this.bls5 = this.bls1.replace('disbal', '');
      this.bls6 = this.bls1.replace('disbal', '');
    }

    if (level == 1) {

      this.bls1 = this.bls1 +' disbal';
      this.morurOption.lvset1 = true;
    }
   else if (level == 2) {

      this.bls2 = this.bls2 + ' disbal';
      this.morurOption.lvset2 = true;
    }
    else if (level == 3) {

      this.bls3 = this.bls3 + ' disbal';
      this.morurOption.lvset3 = true;
    }
    else if (level == 4) {

      this.bls4 = this.bls4 + ' disbal';
      this.morurOption.lvset4 = true;
    }
    else if (level == 5) {

      this.bls5 = this.bls5 + ' disbal';
      this.morurOption.lvset5 = true;
    }
    else if (level == 6) {

      this.bls6 = this.bls6 + ' disbal';
      this.morurOption.lvset6 = true;
    }




  }

  enableDirbutton(level: number) {


    //if (reset) {
    //  this.bls1 = this.bls1.replace('disbal', '');
    //  this.bls2 = this.bls1.replace('disbal', '');
    //  this.bls3 = this.bls1.replace('disbal', '');
    //  this.bls4 = this.bls1.replace('disbal', '');
    //  this.bls5 = this.bls1.replace('disbal', '');
    //  this.bls6 = this.bls1.replace('disbal', '');
    //}

    if (level == 1) {

      this.bls1 = this.bls1.replace('disbal', '');
      this.morurOption.lvset1 = false;
    }
    else if (level == 2) {

      this.bls2 = this.bls2.replace('disbal', '');
      this.morurOption.lvset2 = false;
    }
    else if (level == 3) {

      this.bls3 = this.bls3.replace('disbal', '');
      this.morurOption.lvset3 = false;
    }
    else if (level == 4) {

      this.bls4 = this.bls4.replace('disbal', '');
      this.morurOption.lvset4 = false;
    }
    else if (level == 5) {

      this.bls5 = this.bls5.replace('disbal', '');
      this.morurOption.lvset5 = false;
    }
    else if (level == 6) {

      this.bls6 = this.bls6.replace('disbal', '');
      this.morurOption.lvset6 = false;
    }




  }



 

  mtemp: Taraz;
  getselectedrow() : Taraz {
 

    var r = this.dxgrid.focusedRowKey;
    if (r == null) return null;
    if (this.modlelist == null) return null;

   // console.log(this.focusedRowKey)
    for (var _i = 0; _i < this.modlelist.length; _i++) {

      if (this.focusedRowKey == this.modlelist[_i].id) {


        return this.modlelist[_i];
      }
 
    }

    return null;


  }
  onRowDblClick(e) {
    this.onClickRizghardesh();
  }
  onClickRizghardesh() {


    let selectedrow = this.getselectedrow();
    if (selectedrow == null)
      return;

    if (this.morurOption.dir == null)
      this.morurOption.dir = [];

    //تنظیمات را کپی کرده و یک سطح بهش اضافه میکنم که همان سطح و ای دی ریز گردش است
    let Tarazforrizoption = JSON.parse(JSON.stringify(this.morurOption)) as MorurOption;


    let maxsort = 0;
    for (var i = 0; i < Tarazforrizoption.dir.length; i++) {

      if (Tarazforrizoption.dir[i].sort > maxsort)
        maxsort = Tarazforrizoption.dir[i].sort;

    }
    maxsort++;
    let sam = new Diri();
   
    sam.level = Tarazforrizoption.nowlevel;
    sam.sort = maxsort;
    sam.htid = selectedrow.hesOrTafId;
    sam.htidCode = selectedrow.code;
    sam.htidName = selectedrow.name;

    Tarazforrizoption.dir.push(sam);


    this.OnRizGhardesh.emit(Tarazforrizoption);

  }

  test() {
 
    var para = document.createElement('p');
    var title = document.createElement('title');

    // define some window attributes
    var features = 'width=1200, height=900, status=1, menubar=1, location=0, left=100, top=100';
    var winName = 'print';

    // populate the html elements
    para.textContent = '<p>fghjfgjghkjgh</p>';
    title.textContent = 'New Window Title';
     
    // define a reference to the new window
    // and open it with defined attributes 
  //  var winRef = window.open('', winName, features);



  
   // winRef.document.write(this.MorurService.getprint(this.modlelist));




  }
  Ondofilterclicked(e) {


  //  console.log(JSON.stringify(this.morurOption));
    this.loadTarazsub('print');
  }
  onClickReflesh() {





    this.loadTarazsub('print');
  }

  onClickExportexcel() {
    if (this.done.length == 0)
      return;

   //console.log(this.morurOption.kindReport);
    if (this.morurOption.kindReport != 'daftar') {
     
      this.alertify.message('گزارش دفتر را انتخاب نمایید');
      return;
    }
    this.loadTarazsub('excel');


  }


 


  listforprint: Taraz[];
  onClickprint() {

   
    this.loadTarazsub('print');

     

  }

  newrowlevel() {
    if (this.morurOption.tarazOlaviat == null)
      this.morurOption.tarazOlaviat = [];

    if (this.morurOption.tarazOlaviat.length > 5)
      return;

    let maxlevel =0;

    for (var i = 0; i < this.morurOption.tarazOlaviat.length; i++) {

      if (this.morurOption.tarazOlaviat[i].level > maxlevel)
        maxlevel = this.morurOption.tarazOlaviat[i].level;

    }

    if (maxlevel<7)
    maxlevel++;

    var it = this.getdirilevel(maxlevel);
 
    it.sort = this.morurOption.tarazOlaviat.length +1;

    this.morurOption.tarazOlaviat.push(it);


  }

  shiftlevel(item : any,sh : number) {

    //dirtemp.level = 1;
    //dirtemp.levelName = 'گروه';
    //dirtemp.sort = -1;
     


    let lnew = item.level + sh;

    if (lnew > 6 || lnew < 1)
      return;

    var v = this.getdirilevel(lnew);


  //  item.level = v.level;
   // item.levelName = v.levelName;

    var index = item.sort;
    index--;
   // console.log(index);

 
    this.morurOption.tarazOlaviat[index].level = v.level;
    this.morurOption.tarazOlaviat[index].levelName = v.levelName;
  }
  removelevel(item: any) {

    let sortkey = item.sort;

    for (var i = 0; i < this.morurOption.tarazOlaviat.length; i++) {

      if (this.morurOption.tarazOlaviat[i].sort == sortkey)
       this.morurOption.tarazOlaviat.splice(i,1);

    }

    for (var i = 0; i < this.morurOption.tarazOlaviat.length; i++) {

      this.morurOption.tarazOlaviat[i].level = i + 1;
       

    }

  }
  todo = [
    'گروه',
    'سطح پنج',
    'سطح شش',
  ];

  done = [
    'کل',
    'معین',
    'سطح چهار',
  ];

  all = [
    'گروه',
    'کل',
    'معین',
    'سطح چهار',
    'سطح پنج',
    'سطح شش',
  ];
 
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    var temp = [];
    var exi = false;
    for (var i = 0; i < this.all.length; i++) {

      exi = false;
      for (var y = 0; y < this.todo.length; y++) {
        if (this.todo[y] == this.all[i])
          exi = true;
      }

      if (exi)
      temp.push(this.all[i]);
    }
    this.todo = temp;

  }

  onSelectionChanged(e, level) {

  
    if (level == 1) {

      //کل را خالی میکنیم و از نو میچینیم
      this.tarazHesabslist[1].rows = [];

      if (this.tarazHesabslist[0].selected.length > 0)
      for (var r = 0; r < this.tarazHesabslist[0].selected.length; r++) {


        for (var i = 0; i < this.tarazHesabslistorginal[1].rows.length; i++) {

          if (this.tarazHesabslistorginal[1].rows[i].g == this.tarazHesabslist[0].selected[r]) {
            this.tarazHesabslist[1].rows.push(this.tarazHesabslistorginal[1].rows[i]);

          }
        }

        }
      else
        this.tarazHesabslist[1].rows = JSON.parse(JSON.stringify(this.tarazHesabslistorginal[1].rows))

    }

 
    if (level == 2) {

      //معین را خالی میکنیم و از نو میچینیم
      this.tarazHesabslist[2].rows = [];

      if (this.tarazHesabslist[1].selected.length > 0)
        for (var r = 0; r < this.tarazHesabslist[1].selected.length; r++) {


          for (var i = 0; i < this.tarazHesabslistorginal[2].rows.length; i++) {

            if (this.tarazHesabslistorginal[2].rows[i].k == this.tarazHesabslist[1].selected[r]) {
              this.tarazHesabslist[2].rows.push(this.tarazHesabslistorginal[2].rows[i]);

            }
          }

        }
      else
        this.tarazHesabslist[2].rows = JSON.parse(JSON.stringify(this.tarazHesabslistorginal[2].rows));

    }



  }
  OnKindsetingChanged(e) {

   // console.log(e);
    if (e == 'daftar')
      this.diableexcel = "";
    else
      this.diableexcel = "mydisble";
  }
}


