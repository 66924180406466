import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { Morur, MorurOption } from '../../../_models/app1/rep/Morur';
import { IdName } from '../../../_models/General/IdName';
import { Taraz } from '../../../_models/app1/rep/Taraz';
import { Localst } from '../../localst.service';
import { tarazHesabsFilter } from '../../../_models/app1/rep/tarazHesabsFilter';
import { DaftarPack } from '../../../_models/app1/rep/DaftarPack';
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
 
@Injectable({
  providedIn: 'root'
})
export class MorurService {
  
  origin = location.origin;
  
emptymorooption: MorurOption = {

  mainlevel: -1,

  nowlevel: -1,
  oldlevel: -1,


  ismother: true,
  dir: [],

  lvset1: false,
  lvset2: false,
  lvset3: false,
  lvset4: false,
  lvset5: false,
  lvset6: false,


  KindSanad: [],
  hesabKind: [],

  allkindselected:false,


    yearS: null,
  yearE: null,
  dateS: null,
  dateE: null,

  useCShomare: true,
  cShomareS: null,
  cShomareE: null,

  bamandeh: false,
  sakhtar: 6,
  kindMandeh:3,
  tarazOlaviat: [],
  l1: [],
  l2: [],
  l3: [],
  l4: [],
  l5: [],
  l6: [],
  kindReport:'taraz'
 





};

  EmptyMorur: Morur = {
    radif:null,
    id: null,
    level: null,
    hesOrTafId: null,
    code: null,
    name: '',
    bed: null,
    bes: null,
    mandehBed: null,
    mandehBes: null,
    MorurRep: 0,
    legpx:0
  };


  sakhtarlist: IdName[] = [{
    id: 2,
    name: 'دو ستونی'
  }, {
    id: 4,
    name: 'چهار ستونی'
  }, {
    id: 6,
    name: 'شش ستونی'
  }

  ];

  kindmandehlist: IdName[] = [{
    id: 1,
    name: 'بدهکار'
  }, {
    id: 2,
    name: 'بستانکار'
  }, {
    id: 3,
    name: 'بدهکار بستانکار'
    },
    { 
      id: 4,
      name: 'بدهکار بستانکار با مانده غیر صفر'
    }
    ,
    {
      id: 5,
      name: 'مانده صفر'
    }
  ];


  EmptytarazHesabsFilter: tarazHesabsFilter = {
    id: null,
    name: null,
    level: null,
    rows: [],
    selected:[4005,4006],
  };

  constructor(private http: HttpClient, private localstService: Localst) { }

  getMorurs(level: number): Observable<Morur[]> {
    let apiadress = '/api/App1/Rep/Morur/getbylevel?level=' + level;
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<Morur[]>(this.origin + apiadress, { 'headers': headers });
  }


  getMorursSub(mop: MorurOption): Observable<Morur[]> {
    let apiadress = '/api/App1/Rep/Morur/GetMorurSub';
    var headers = this.localstService.header_get(apiadress,null);
  

 



    return this.http.post<Morur[]>(this.origin + apiadress,mop, { 'headers': headers });

  }

  getTarazs(mop: MorurOption): Observable<Taraz[]> {

    let apiadress = '/api/App1/Rep/Morur/GetTaraz';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<Taraz[]>(this.origin + apiadress,mop, { 'headers': headers });

  }
  getDaftar(mop: MorurOption): Observable<DaftarPack> {

    let apiadress = '/api/App1/Rep/Morur/GetDaftar';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<DaftarPack>(this.origin + apiadress, mop, { 'headers': headers });

  }
  getDaftarAsExcel(mop: MorurOption): Observable<Blob> {

    let apiadress = '/api/App1/Rep/Morur/GetDaftarAsExcel';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<Blob>(this.origin + apiadress, mop, { 'headers': headers, responseType: 'blob' as 'json'  });

  }
  GettarazHesabsFilterRowsData(): Observable<tarazHesabsFilter[]> {

    let apiadress = '/api/App1/Rep/Morur/GettarazHesabsFilterRowsData';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<tarazHesabsFilter[]>(this.origin + apiadress, { 'headers': headers });

  }


  getSakhtar(): IdName[] {


    return JSON.parse(JSON.stringify(this.sakhtarlist));
  }
  getSakhtarbyid(id : number): IdName {

    for (var i = 0; i < this.sakhtarlist.length; i++) {

      if (this.sakhtarlist[i].id == id)
        return JSON.parse(JSON.stringify(this.sakhtarlist[i]));

    }

    return null;
  }


  getkindMandehlist(): IdName[] {


    return JSON.parse(JSON.stringify(this.kindmandehlist));
  }

  getkindMandehbyid(id: number): IdName {

    for (var i = 0; i < this.kindmandehlist.length; i++) {

      if (this.kindmandehlist[i].id == id)
        return JSON.parse(JSON.stringify(this.kindmandehlist[i]));

    }

    return null;
  }

  getemptyoption(): MorurOption {


    return JSON.parse(JSON.stringify(this.emptymorooption)); 


  }

  getemptytarazHesabsFilter(): tarazHesabsFilter {


    return JSON.parse(JSON.stringify(this.EmptytarazHesabsFilter));


  }

  getprint(moror: Morur[]): string {

    let row =
      `
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
`
      
    if (moror != null) {


      for (var _i = 0; _i < moror.length; _i++) {

        row = row + '<tr><td>' + moror[_i].id + '</td>' +
          '<td>' + moror[_i].name + '</td>' +
          '<td>' + moror[_i].code + '</td></tr>';

   
    
      }

    }
  

    return `

<style>

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 20mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 0.5cm;
        border: 2px black solid;
        height: 257mm;
        outline: 0.7cm #FFEAEA solid;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }




</style>



  <div> 
<div class="book">
  <div class="page">
    <div class="subpage"><table style="width:100%">
  <tr>
    <th>لببلا</th>
    <th>Lastname</th>
    <th>Age</th>
  </tr>
  `+

      row + row + row + row


      +`
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table></div>
  </div>
  <div class="page">
    <div class="subpage">Page 2/2</div>
  </div>
</div>



</div>




`;






  }






}
