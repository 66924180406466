export class TaxpayerInfo
{
  nameTrade: string;
    taxpayerStatus: string;
    taxpayerType: string;
    postalcodeTaxpayer: string;
    addressTaxpayer: string;
    nationalId: string;
  constructor() {

  }
}
