import { Component, OnInit } from '@angular/core';
import { User } from '../../_models/user';
 
import { ArchService } from '../../_services/arch.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../_models/pagination';
import { Arch } from '../../_models/arch';



@Component({
  selector: 'app-arch-list',
  templateUrl: './arch-list.component.html',
  styleUrls: ['./arch-list.component.css']
})
export class ArchListComponent implements OnInit {
  defaultpagation: Pagination = {
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 5,
    totalPages: 26,
  };
  archParams: any = {};
  pagination: Pagination;
  archs: Arch[];
  user: User = JSON.parse(localStorage.getItem('user'));
  focusedRowIndex = 0;
  selectedrowId: number;
  autoNavigateToFocusedRow = true;
  constructor(private archService: ArchService, private alertify: AlertifyService,
    private route: ActivatedRoute) {
    this.pagination = this.defaultpagation;
  }

  ngOnInit() {
  
    this.loadArch();
    console.log(this.archs);
    };
  loadArch() {


    this.archService.getArchs_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.archParams)
      .subscribe((res: PaginatedResult<Arch[]>) => {
        this.archs = res.result;
        this.pagination = res.pagination;
      }, error => {
        this.alertify.error(error);
      });

   
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadArch();
  }
  delete() {
    console.log('the delete '+this.selectedrowId);

    this.archService.deleteArch(this.selectedrowId).subscribe((res: Arch[]) => {
      this.loadArch();
      this.focusedRowIndex = 0;
    }, error => {
      console.log('myerror: ');
      this.alertify.error(error);
    });


  }
  onFocusedRowChanging(e) {
   
  }

  onFocusedRowChanged(e) {
    var rowData = e.row && e.row.data;
    if (rowData) {
      var temprow = rowData.id;
      if (this.selectedrowId != temprow)
        this.selectedrowId = temprow;

     // console.log(this.selectedrowId);
    }
  }
}



