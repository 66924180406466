import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent, DxTreeListComponent, DxTreeViewComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
 
 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ExcelService } from '../../../_services/excel.service';
import { Localst } from '../../../_services/localst.service';
import { AnSellJameRep } from '../../../_models/app3/rep/AnSellJameRep';
import { AnRepService } from '../../../_services/app2/rep/AnRep.service';
import { KalaService } from '../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { KalaForList } from '../../../_models/app2/paye/Kala';
import { Vahed } from '../../../_models/app2/paye/Vahed';
import { Anbar } from '../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../_services/app2/paye/Anbar.service';
import { SellRepService } from '../../../_services/app3/rep/SellRep.service';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../_models/app1/paye/tafsiliForSmall';
import * as FileSaver from 'file-saver';
import { AnSellService } from '../../../_services/app3/opr/AnSell.service';
import { TabagheService } from '../../../_services/app2/paye/Tabaghe.service';

@Component({
  selector: 'app-AnSellJame-list',
  templateUrl: './AnSellJame-list.component.html',
  styleUrls: ['./AnSellJame-list.component.css']
})
 

export class AnSellJamelistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() ftabindex: number;
  @Input() hyperLinkOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxTreeListComponent;
  @ViewChild("dxliststrtab", { static: false }) dxliststrtab: DxListComponent;
  @ViewChild("taf6c", { static: false }) taf6c: DxSelectBoxComponent;
  @ViewChild("kalac", { static: false }) kalac: DxSelectBoxComponent;
  @ViewChild("dxliststrkind", { static: false }) dxliststrkind: DxListComponent;
  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  @Input() showRial: boolean;


  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;
 

  focusedSabtId: number;
  
 //لیست کالا ها
  devDataKalas: any = {};
  //لیست کالا ها
  devDataTaf: any = {};
  vahedList: Vahed[] = [];
  //لیست انبارها 
  anbarList: Anbar[] = [];

  kindList: any[] = [];
  tabagList: any[] = [];

  selectedrow: any;
 

  model: AnSellJameRep;
 
 
 
  dataSource: any;
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
 
  @ViewChild("UnSelectlist", { static: false }) UnSelectlist: DxListComponent;
  @ViewChild("Selectlist", { static: false }) Selectlist: DxListComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private sellRepService: SellRepService,
    private kalaService: KalaService,
    private tafsiliService: TafsiliService,
    private anbarService: AnbarService,
    private anSellService: AnSellService,
 
 
    private tabagheService: TabagheService,
 
 
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    this.openedit= false;
    this.openeditId = -1;

 
    this.loadServ.create(this.loaddata,8);

   
    this.model = sellRepService.getAnSellJameRepEmpty();
    this.model.option.year = localst.yearnow_get();

  //  $("dataGrid").find(".dx-icon").width(32).height(32);
   // this.dataGrid.instance.option('.dx-icon').width(32).height(32);;
   
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    
 
    //c=67
  
     //   this.onClickReflesh();
      
   
 



  }
  tabindex: number = -1;
  ngOnChanges(changes: SimpleChanges) {

    const changIndex = changes.ftabindex.currentValue;

    if (this.tabindex == -1) {
      this.tabindex = changIndex;
    }
    else if (this.tabindex == changIndex) {
      this.tabClicked(true);
    }
    else {
      this.tabClicked(false);
    }
  }

  tabClicked(active: boolean) {

    if (active) {

      this.dataGrid.instance.refresh();
    //  this.alertify.success('rep true');
    }

    else {

    //  this.alertify.success('rep false');
    }


  }

  ngOnInit() {


    this.kindList = this.anSellService.getkindList();

    this.loadServ.showloading(this.loaddata, 2);
    this.kalaService.getallForlist()
      .subscribe((res: KalaForList[]) => {
        this.loadServ.hideloading(this.loaddata, 2);
     

        this.devDataKalas = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });

    this.loadServ.showloading(this.loaddata, 10);
    this.tabagheService.getall_tiny()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 10);

        this.tabagList = res.data;


      }, error => {
        this.loadServ.hideloading(this.loaddata, 10);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, false, -1, 'none')
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 4);


        this.devDataTaf = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 3);
    this.anbarService.getalltiny()
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 3);

        this.anbarList = res.data;

        ////در بار اول انبار ها را در مدل سلکت میکنیم
        for (var j = 0; j < this.anbarList.length; j++) {

          this.model.option.anbars.push( { id: this.anbarList[j].id, name: this.anbarList[j].name });

        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });



    this.loadServ.showloading(this.loaddata, 7);
    this.sellRepService.DateRangAnSell(this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 7);

    
        this.model.option.dateS_S = res.data.dateA_S;

      
        this.model.option.dateE_S = res.data.dateB_S;
     

        //تا اولین شماره سند این تاریخ هم از سرور خوانده شود
        this.focusfilter = 'dateS';
        this.dateSchang({ value: res.data.dateA_S });

        //تا اخرین شماره سند این تاریخ هم از سرور خوانده شود
        this.focusfilter = 'dateE';
        this.dateEchang({ value: res.data.dateB_S });

        this.focusfilter = null;
      }, error => {
        this.loadServ.hideloading(this.loaddata, 7);
        this.alertify.error(error);
      });


  
  }
  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {


   // this.excelService.exportAsExcelFile(this.model.rows,' گزارش جامع فروش ');

    this.loadServ.showloading(this.loaddata, 3);
    this.sellRepService.AnSellJameRepAsExcel(this.model.option).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  onClickTEjaratExcel() {

    this.loadServ.showloading(this.loaddata, 3);
    this.sellRepService.TejaratExcel(this.model.option).subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }
  onClickReflesh() {

    //if (this.model.option.kalaId == null) {
    //  this.alertify.message('کالا را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.dateA_S == null) {
    //  this.alertify.message('تاریخ شروع را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.dateA_S == null) {
    //  this.alertify.message('تاریخ پایان را انتخاب کنید');
    //  return;
    //}
    //if (this.model.option.vahedId == null) {
    //  this.alertify.message('واحد را انتخاب کنید');
    //  return;
    //}

    //انبار های سلکت شده را به مدل میدیم

    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم
    if (this.dxliststr!= undefined) {
      var keys = this.dxliststr.selectedItemKeys;
      this.model.option.anbars = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {

          this.model.option.anbars.push({ id: keys[j].id, name: keys[j].name});
        }
    }

    if (this.dxliststrtab != undefined) {
      var keys = this.dxliststrtab.selectedItemKeys;
      this.model.option.tabages = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {

          this.model.option.tabages.push({ id: keys[j].id, name: keys[j].name });
        }
    }


    //ytguiytui66
    if (this.dxliststrkind != undefined) {
      var keys = this.dxliststrkind.selectedItemKeys;
      this.model.option.kind = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {
          this.model.option.kind.push({ id: keys[j].id, name: keys[j].name });
        }
    }

    //همواره پر شود
    if (this.model.option.kind.length == 0) {
      this.model.option.kind = [];
    }



    //yytu85685858
    this.loadServ.showloading(this.loaddata, 5);
    this.sellRepService.AnSellJameRep(this.model.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          this.model.rows = [];
        }
        else {
          //tyytu676767
          this.model = res.data;
        }
      
       

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


  adjustWidth(e) {

    e.component._popup.option('width', 500);

  }

 


  OnMyCancelEvent(e) {

    this.openedit = false;
  }
  OnMySaveEvent(e) {

    this.openedit = false;

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

  onkalaachanged(e) {

  //fghfgh665

    if (e.value == null)
      return;

    var it = this.devDataKalas._store._array.find(obj => { return obj.id === e.value });

    //if (this.model.option.kalaIds.find(obj => { return obj.id === -1 })) {
    //  this.model.option.kalaIds = [];
    //}

    this.model.option.kalaIds.push({ id: it.id, name: it.code });

    this.kalac.value = null;
    
  }
  ontaf4changed(e) {

    if (e.value == null)
      return;

    var it = this.devDataTaf._store._array.find(obj => { return obj.id === e.value });

    //if (this.model.option.taf4s.find(obj => { return obj.id === -1 })){
    //  this.model.option.taf4s = [];
    //}
    this.model.option.taf4s.push({ id: it.id, name: it.name });

    this.taf6c.value = null;

  }
  delkala(e) {

    this.model.option.kalaIds = this.model.option.kalaIds.filter(({ id }) => id !== e.id);
  }
  deltaf4(e) {

    this.model.option.taf4s = this.model.option.taf4s.filter(({ id }) => id !== e.id);
  }

  toupgroup(e) {
    for (var i = 0; i < this.model.option.gSelect.length;i++) {
      if (this.model.option.gSelect[i].id == e.id) {

        if (i == 0)
          break;

        var temp = this.model.option.gSelect[i - 1];

        this.model.option.gSelect[i - 1] = this.model.option.gSelect[i]
        this.model.option.gSelect[i] = temp;
        break;
      }

    }

  }


  firstclreate = true;
 

  dropopened(e) {


    e.component._popup.option('width', 500);
    
    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

      this.firstclreate = false;

    }
  }


  onSelectionChanged(e) {


    //if (this.firstclreate)
    //  return;

    //var keys = this.dxliststr.selectedItemKeys;
    //this.model.option.anbarIds = [];
    ////console.log('oooooooooooop');
    ////console.log(JSON.stringify(keys));
    //if (keys != null)
    //  for (var j = 0; j < keys.length; j++) {

         
    //    this.model.option.anbarIds.push(keys[j]);
    //  }
  }

  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    //if (e.column.dataField == "amtMan"  ) {
    //  if (e.data.amtMan > 0) {
    //   // e.cellElement.style.backgroundColor = 'rgb(31 233 0 / 0.1)';
    //    e.cellElement.style.color = 'darkred';
     
    //  }
    //  else if (e.data.amtMan < 0) {
    //    e.cellElement.style.backgroundColor = '#efbdba';

    //  }
    //}
    //if (e.column.dataField == "amtIn" || e.column.dataField == "fiIn" || e.column.dataField == "prcIn") {
    //  if (e.data.amtIn == 0) {
    //    // e.cellElement.style.backgroundColor = 'rgb(31 233 0 / 0.1)';
    //    e.cellElement.style.backgroundColor = '#B0B0B0';

    //  }
      
    //}
    //console.log(e.rowType);
   // e.cellElement.style.textalign = "center!important";
     // e.cellElement.css("text-align", "center!important");
    
 

   



  }



  onClickgoSanad() {

    //yttyyt566767
    //var key = this.dataGrid.focusedRowKey;
    //if (key == null)
    //  return;

 
    //for (var r of this.model.rows) {
    //  if (r.id == key) {
    //    //kind نوع صفحه
    //    //anDeedId ای دی سند
    //    //AnDeedRowId ردیف فکوس
    //    this.onOpenSanadList.emit({ kind: r.kind, anDeedId: r.anDeedId, AnDeedRowId: r.id });
    //    break;
    //  }

    //}
  }
  selectgroup() {

    let se = this.UnSelectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gUnSelect) {
      if (r.id == se[0]) {
        this.model.option.gSelect.push(r);
        break;
      }
  
    }
    this.model.option.gUnSelect = this.model.option.gUnSelect.filter(({ id }) => id !== se[0]);
 
    
  }

  unselectgroup() {
    let se = this.Selectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gSelect) {
      if (r.id == se[0]) {
        this.model.option.gUnSelect.push(r);

        break;
      }

    }
    this.model.option.gSelect = this.model.option.gSelect.filter(({ id }) => id !== se[0]);
 

  }

   
  calculateSelectedRow(options) {
    if (options.name === 'amtManSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
      //  if (options.component.isRowSelected(options.value.ID)) {
          options.totalValue = options.value.amtMan;
      //  }
      }
    }

    if (options.name === 'prcManRowsSummary') {

      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      else if (options.summaryProcess === 'calculate') {
        //  if (options.component.isRowSelected(options.value.ID)) {
        options.totalValue = options.value.prcMan;
        //  }
      }
    }
  }
 



  focusfilter: string;
 
onFocusIn(e) {

    this.focusfilter = e;
}
  dateSchang(e) {
    if (this.focusfilter != 'dateS')
      return;

   // this.model.option.dateS = e.value.toString();
    this.loadServ.showloading(this.loaddata, 9);
    this.sellRepService.getfirstCodeAnSell(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 9);
      if (res != null)
        this.model.option.codeS = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
    });

  }
  dateEchang(e) {
    if (this.focusfilter != 'dateE')
      return;


    this.loadServ.showloading(this.loaddata, 8);
    this.sellRepService.getlastCodeAnSell(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 8);
      if (res != null)
        this.model.option.codeE = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
    });
  }
  codeSchang(e) {
    if (this.focusfilter != 'codeS')
      return;

    this.loadServ.showloading(this.loaddata, 6);
    this.sellRepService.getfirstDateAnSell(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);
      if (res != null)
        this.model.option.dateS_S = res.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
    });
  }

  codeEchang(e) {
    if (this.focusfilter != 'codeE')
      return;

    this.loadServ.showloading(this.loaddata, 1);
    this.sellRepService.getlastDateAnSell(this.model.option.year, e.value.toString()).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (res != null)
        this.model.option.dateE_S = res.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
    });
  }


  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw3003 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw3003.length; i++) {
      this.localst.optioncli.gw3003[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw3003 = this.localst.optionClient_readDefault('gw3003');
  }

 

  RowPrepared(e) {

    if (e.key < 0) {
    /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }
     
    //e.rowElement.css('background', 'green');
  }
  onClickPrint() {

 

  }

  f3 = true;
  dropopenedKind(e) {

    e.component._popup.option('width', 500);
    if (this.f3) {
      this.dxliststrkind.instance.selectAll();
      this.f3 = false;

    }
  }
  f2 = true;
  dropopenedTabag(e) {

    e.component._popup.option('width', 500);
    if (this.f2) {
      this.dxliststrtab.instance.selectAll();
      this.f2 = false;

    }
  }
 
}


