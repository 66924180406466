export class RegistryApp2Code {
  years: number;
  rowCode: RegistryApp2CodeRow[];

 public constructor() {
   this.years = 0;
   this.rowCode = [];
  }

}

export interface RegistryApp2CodeRow {
  typeId: number;
  sanadN: string;
  codeStart: number;
  isAuto: boolean;
  isDateCh: boolean;
}
