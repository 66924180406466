import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../_models/user';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../_models/pagination';
import { Shakhz } from '../../_models/app1/paye/Shakhz';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { ShakhzService } from '../../_services/app1/paye/Shakhz.service';
import { DeldialogComponent } from '../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { ArchPhotoService } from '../../_services/archphoto.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-Arch-photoShow',
  templateUrl: './Arch-photoShow.component.html',
  styleUrls: ['./Arch-photoShow.component.css']
})
export class ArchphotoshowComponent implements OnInit {
  @Input() myvs: Shakhz = this.shakhzService.getShakhzEmpty()
  @Output() deleteDone: EventEmitter<boolean> = new EventEmitter<boolean>();
  public unsafeImageUrl: string;
  public imageUrl: any;

  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private router: Router,
    private httpClient: HttpClient,
    private shakhzService: ShakhzService,
    private archPhotoService: ArchPhotoService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute) { }
  OnInput(value) {
    this.myvs = value;
  }
  ngOnInit() {
    
    console.log('m:image loading');
    var r = this.archPhotoService.GetArchPhotosStream3().subscribe(data => {
      this.unsafeImageUrl = URL.createObjectURL(data);
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
    }, error => {
      console.log(error);
    });;
    console.log(" id" + this.myvs.id);
      //this.shakhz.nameFarsi
  }


  loadUsers() {
  
   
  }

  let
  deletethis(id: number) {

    this.myvs.id = id;
    console.log("The delete clicked "+ this.myvs.id.toString());
    this.openDialog();
  //  this.dodelete();
   
  }
 
  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
     let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yess') {

        this.dodelete();
     
      }
      
    });
  }

 
  dodelete(): void {

    this.shakhzService.deleteshakhz(this.myvs.id).subscribe((data) => {
      this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');
      console.log("m:deleted successful");
      this.deleteDone.emit(true);


    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }



  editthis(editsha: Shakhz) {


    console.log("The edit clicked ");
   // console.log("The edit clicked " + editsha.Id.toString());
    this.router.navigate(['/app1/paye/shakhz/edit', 36]);
  
   // this.dodelete();

  }
}



