import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {

  isdelete: string;
}
@Component({
  selector: 'Deldialog',
  templateUrl: 'Deldialog.component.html',
  styleUrls: ['./Deldialog.component.css']
})
export class DeldialogComponent {
 
  @Input() message = 'آیا از حذف این مورد اطمینان دارید؟';
  constructor(
    public dialogRef: MatDialogRef<DeldialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.data.isdelete = 'yes';

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
