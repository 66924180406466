import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { Sanad } from '../../../../_models/app1/opr/sanad';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
 
import { Localst } from '../../../../_services/localst.service';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';

@Component({
  selector: 'app-Sanad-merge',
  templateUrl: './Sanad-merge.component.html',
  styleUrls: ['./Sanad-merge.component.css']
})
export class SanadmergeComponent implements OnInit {
  
 
  @Input() option: any;

 

  @Output() onMerge: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
 

  buttcop: boolean;
  buttsave: boolean;
  selectedkind: string;
  countselected: number;


  classinCShomare: string;
  classinDaterang: string;
  classinDateOne: string;
  classinList: string;
  myTimeClass: MyTimeClass;

 
  constructor(private userService: UserService, private alertify: AlertifyService,
    public myTimeService: MyTimeService,
    public dialog: MatDialog,
    private localst: Localst,
  ) {
    this.selectedkind = 'none';
    let y = localst.yearnow_get();

 //   this.option = { year: y, levelDetail: 6, cShS: 1, cShE: 1 };

    this.buttcop = true;
    this.buttsave = false;
    this.countselected = 0;
    this.classinCShomare = 'mydisble';
    this.classinDaterang = 'mydisble';
    this.classinDateOne = 'mydisble';
    this.classinList = 'mydisble';
  }

 
  ngOnInit() {

    //ddfseat4
    this.myTimeClass = this.myTimeService.GetEmptyPersianNow();
    this.myTimeService.GetPersianNow().subscribe((res: MyTimeClass) => {
 
      this.myTimeClass = res;
      if (this.option.inDaterangS == null)
        this.option.inDaterangS = this.myTimeClass.dateString;
      if (this.option.inDaterangE == null)
        this.option.inDaterangE = this.myTimeClass.dateString;
      if (this.option.inDateOneS == null)
        this.option.inDateOneS = this.myTimeClass.dateString;
      if (this.option.inDateOneE == null)
        this.option.inDateOneE = this.myTimeClass.dateString;

    }, error => {
 
      this.alertify.error(error);
    });
    //var val = this.option.date.replace(/[^\d.-]/g, '');
    //if (val == null)
    //  return;

    //let date = Number(val);
    //this.onDateChanged.emit(date);
    this.checkValue();

  }

 
  onClickClose() {
    console.log('click');
    this.onClose.emit(true);

  }
  onValueChangedop(e) {

   


  }

 
  datechanged() {


  }

  cretemerge() {

    this.onMerge.emit(this.option);
    this.buttcop = true;
    this.buttsave = false;
  }

  buttcopclick() {


    if (this.option.years <1000) {

      this.option.years = this.localst.yearnow_get();

    }


    if (this.option.byKind == 'inCShomare') {

      if (this.option.inCShomareS == null || this.option.inCShomareE == null)
        return;

      if (this.option.inCShomareS > this.option.inCShomareE) {
        this.alertify.message('شماره سند پایانی کوچکتر از شماره سند شروع است');
        return;
      }
      
    }

        if(this.option.byKind == 'inDaterang') {

          if (!this.validdatedate(this.option.inDaterangS))
            return;
          if (!this.validdatedate(this.option.inDaterangE))
            return;
          }
    if (this.option.byKind == 'inDateOne') {

      if (!this.validdatedate(this.option.inDateOneS))
        return;
      if (!this.validdatedate(this.option.inDateOneE))
        return;

    }
      else if (this.option.byKind == 'inList') {

      if (this.option.inList.length < 1) {
        this.alertify.message('هیچ سندی انتخاب نشده');
        return;
      }
    


       }
  

    this.buttcop = false;
    this.buttsave = true;

  }
  validdatedate(date: string) : boolean {
    var val: string = date.replace(/[^\d.-]/g, '');
    if (val == null)
      return false;
    if (val.length < 8)
      return false;

    return true
}

  buttcancel() {
    this.buttcop = true;
    this.buttsave = false;

  }
  checkValue() {

    console.log(this.option.byKind);

    this.classinCShomare = 'mydisble';
    this.classinDaterang = 'mydisble';
    this.classinDateOne = 'mydisble';
    this.classinList = 'mydisble';
    if (this.option.byKind == 'inCShomare') {
      this.classinCShomare = 'myenable';
    }
    else if (this.option.byKind == 'inDaterang') {
      this.classinDaterang = 'myenable';
    }
    else if (this.option.byKind == 'inDateOne') {
      this.classinDateOne = 'myenable';
    }
    else if (this.option.byKind == 'inList') {
      this.classinList = 'myenable';
    }
  }
}



