import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmDar, VmDarForForm } from '../../../_models/app98/opr/VmDar';
 



let EmptyVmDarForForm: VmDarForForm = {

 //تکی
 vmGrsId: -1,
  vmGrsN: 'none',
  taki : null,
  rows  :[],


}
let EmptyVmDar: VmDar = {
   
  id: -1,
  kind:0,
  pId: 0,
  vmGrsId: -1, 
  vmGrsN: 'none',
  dateA: 0,
  dateA_S : null,
  dateAsT: null,
  shHesab: null,
  prc: 0,
  tafId: null,
  tafC: null,
  tafN: null,
  mos: null,
  cRah: null,
  dis: null,
  years: null,
  uSa: null,
  uSaF: null,
  dateLog: null,
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  sanadId: null,
  radif: 0
};
 
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmDarService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(kind:number): Observable<VmDar[]> {
    let apiadress = '/api/App98/Opr/VmDar/getall?kind=' + kind;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<VmDar[]>(this.origin + apiadress, { 'headers': headers });
  }

 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmDar/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: VmDarForForm) {
    let apiadress = '/api/App98/Opr/VmDar/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getVmDarEmpty(kind: number): VmDar {

    let l = JSON.parse(JSON.stringify(EmptyVmDar)) as VmDar;
    l.kind = kind;
    return l;

  }
 
  getVmDarForFormEmpty(kind:number): VmDarForForm {


    let l = JSON.parse(JSON.stringify(EmptyVmDarForForm)) as VmDarForForm ;
    l.taki = JSON.parse(JSON.stringify(EmptyVmDar)) as VmDar;
    l.taki.kind = kind;
      
    return l;
 
  } 

  update(item: VmDarForForm) {
    let apiadress = '/api/App98/Opr/VmDar/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(VmDarId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmDar/delete?id=' + VmDarId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  deletes(ids: any[]): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmDar/deletes';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, ids,{ 'headers': headers }).pipe();
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App98/Opr/VmDar/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  getRowOnVmGrs(vmGrsId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmDar/getRowOnVmGrs?vmGrsId=' + vmGrsId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }


  checkReadedExcelGrs(data: any): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmDar/checkReadedExcel';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }

  getDefaultXls(): Observable<Blob> {

    let apiadress = '/api/App98/Opr/VmDar/getDefaultXls';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<Blob>(this.origin + apiadress, { 'headers': headers, responseType: 'blob' as 'json' });

  }



}
