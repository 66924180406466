import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { resolveAny } from 'dns';
import { User, UserForEdit, UserforAdd } from '../../_models/user';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { Localst } from '../../_services/localst.service';
import { UserService } from '../../_services/user.service';

 


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class userlistComponent implements OnInit {

   

  nowuser: User;
  userselect: UserforAdd;

  mynewuser: UserforAdd;

  userList: any[];

  model: any = {};
  changres: any;
  isb: boolean = true;


  constructor(
    private alertify: AlertifyService,
    private authService: AuthService,
    private userService: UserService,
    private localst: Localst,
    private router: Router) {
  
    this.userselect = authService.getemptyuserforadd();
 

    this.userList = [];
  }

  ngOnInit() {
    //gfhtryurt656
    this.loadUsers();
  
  }

  loadUsers() {

    this.nowuser = this.localst.usernow_get();

    this.userService.GetUsersListEdit().subscribe(next => {
      //y456745y5y4
      this.userList = next.data;

    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



  }



  onSaveEdit() {

 

  }
  
  passwordComparison = () => {
    return this.userselect.password;
  };
 
  onCellPrepared(e) {
    if (e.data == undefined)
      return;
    if (e.rowType === "data" && e.column.dataField === "isActive") {

      e.cellElement.style.color = "green";
      //  if (e.data.Amount==2)
      //  e.cellElement.style.backgroundColor  =  "green" ;
      // Tracks the `Amount` data field
      //e.watch(function () {
      //  return e.data.Amount;
      //}, function () {
      //  e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      //})


    }





  }

  shonew: boolean = false;
  onNew(e) {

    this.mynewuser = this.userService.getUserForAddEmpty();

    this.shonew = true;

   


    //id: number,
    //  customerName: string,
    //    username: string,
    //      knownAs: string,
    //        password: string,
    //          confirmPassword: string,
    //            isAdmin: boolean,
    //              isSuperAdmin: boolean,
  }
  onCancel(e) {

    this.shonew = false;
  }

  onSave(e) {


   
  }
  onFormSubmit() {

    console.log('submited');


    this.mynewuser.customerName = this.nowuser.customerName;

    this.authService.registerbyadmin(this.mynewuser).subscribe(res => {

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.userList.push(res.data);
        this.shonew = false;
      }



    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



  }


  onEditrow(data, isActive : boolean) {
 

    this.userService.UpdateIsActive(data.id, isActive ).subscribe(res => {

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.loadUsers();
      }



    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }
}
