import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmRep, VmRepOp, VmRepRow } from '../../../_models/app98/rep/VmRep';
 
let EmptyVmRep: VmRep = {
  op: null,
  rows: [],
  rowSum: null,
};

let EmptyVmRepOp: VmRepOp = {
  tafId: null,
  tafC: null,
  tafN: null,
  isVaz0: true,
  isVaz1: true,
  isVaz2: true,
  isDateFilter: false,
  dateS: 0,
  dateS_S: null,
  dateE: 0,
  dateE_S: null,
  moed: 1,
  vmGrss: [],
  showTas: false
};

let EmptyVmRepRow: VmRepRow = {
  id: 0,
  cnt:0,
  vmVamId: 0,
  tafId: 0,
  tafC: null,
  tafN: null,
  tafFName: null,
  tafLName: null,
  rNum: 0,
  rNumAll: 0,
  rNumAs:null,
  prc: 0,
  prcKar: 0,
  prcSum:0,
  prcDone: 0,
  prcMan:0,
  vaz: 0,
  vazN: null,
  dateA: 0,
  dateA_S: null,
  moed: 0,
    vmGrsNs: null,
  radif: 0
};

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

 
 
  getRep(op: VmRepOp): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getRep';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, op,{ 'headers': headers }).pipe();

  }
  getVmRepEmpty(): VmRep {
    let l = JSON.parse(JSON.stringify(EmptyVmRep)) as VmRep;
    l.op = JSON.parse(JSON.stringify(EmptyVmRepOp));
    return l;

  }
  getVmRepOpEmpty(): VmRepOp {

    return JSON.parse(JSON.stringify(EmptyVmRepOp));

  }


}
