import { Tafsili } from "./tafsili";

 

 

export interface TafDaste {

        id: number,
       codeStartint : number,
       codeStart: string,
  name:  string,   
  nameE:  string,
        pedarId : number,
  pedarName: string,
    fatherChek: boolean,
       tol : number,
    dis: string,
        radif : number,
  show: boolean,
  dropsee: string,
  tafsiliList: Tafsili[];

  ischecked: boolean; 
}


   export class TafDasteSort {



     tafDasteId: number;
     tafDasteN: number;

    startCode: number;

     constructor() {

       tafDasteId: 0;

       startCode: 0;
     }


    }
