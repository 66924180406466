import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
 
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
 
 
import { tabeventobj } from '../../../../_models/tabeventobj';
import { GeneralService } from '../../../../_services/General/General.service';
import DataSource from "devextreme/data/data_source";
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { AnDeed } from '../../../../_models/app2/opr/AnDeed';
 
 

@Component({
  selector: 'app2-DarKhast-list',
  templateUrl: './DarKhast-list.component.html',
  styleUrls: ['./DarKhast-list.component.css']
})
 

export class DarKhastListComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;;
  @Input() hyperLinkOption: any;
  @Input() tabindex: number;
  items: any;
  openeprint: boolean;
  openeDuplcate : boolean;
  openeMerge : boolean;
  openedit: boolean;
  openeditId: number;


  monthRang: number[];


  focusedDarKhRowId: number;
 
  



  valdata: DialogData;
  selectedrow: any;
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
   };

 // modlelist: DarKhast[];
  modlelist: any = {};
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  mergeOption: any;
  constructor(private userService: UserService,
 
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anDeedService: AnDeedService,
    private generalService: GeneralService,
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {

   this.modlelist = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: null
      }

    });
    this.loaddata = loadServ.getEmpty();
  //  this.loadServ = new loadingClass(8);
   // let sam: loadingCl = new loadingClass(5);
  //  let myTask: Task = new MyTask('someTask');
   // myTask.run("test");
    this.loadServ.create(this.loaddata,10);
   // this.loadServ2.showloading(7);
   // this.loadServ2.showed = true;

   // this.loadServ2.showloading(0);
   // this.monthRang = [localst.dateEmruz.month -3, localst.dateEmruz.month]
    this.monthRang = [1, 12]
 
    if (this.monthRang[0] < 1)
      this.monthRang[0] = 1;

    this.pagination = this.defaultpagation;
  
    this.openeprint = false;
    this.openedit = false;
    this.openeDuplcate = false;
    this.openeMerge = false;
    this.openeditId = -1;
    this.selectedrowkeys = [];
    this.selectedrowkeysIgnored = [];
    this.focusedDarKhRowId = -1;
    this.duplcateOption = {
      copyCSh : null,
      copyId: null,
      paseCSh: null,
      paseId: null,
      date : '1000/00/00'

    }
    this.mergeOption = {

      years: 0,
      byKind: 'inList',

      inCShomareS: 1,
      inCShomareE: 1,

      inDaterangS: null,
      inDaterangE: null,

      inDateOneS: null,
      inDateOneE: null,

      inList: [],
    }

    this.items = [
    { text: 'کپی سند'  , id:'copy'},
    { text: '        '  , id:'null'},
      { text: 'ویرایش'  , id:'edit'},
    { text: 'حذف'  , id:'delete'},
    { text: 'قطعی'  , id:'vaz4'},
    { text: ' لغو بررسی'  , id:'vaz2'},
    { text: 'بررسی'  , id:'vaz3'}
    //  items: [
    //    { text: 'Facebook' },
    //    { text: 'Twitter' }],
    //},
 
    ];
  } 
   
  //itemClick(e) {

  //  //راست کلیک روی سند باز شده نباید باز شود
  //  if (this.openedit)
  //    return;


  //  if (!e.itemData.items) {

  //    let a = e.itemData.id;

  //    if (a == 'copy')
  //      this.onClickDublctaeDarKhast();
  //    else if (a == 'delete')
  //      this.onClickDelete();
  //    else if (a == 'edit')
  //      this.onClickEdit();
  //    else if (a == 'vaz4')
  //      this.onClickbarasi(4);
  //    else if (a == 'vaz2')
  //      this.onClickbarasi(2);
  //    else if (a == 'vaz3')
  //      this.onClickbarasi(3);

  //  }
  //}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);

 //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeDarKhast();
    //}
    



  }
  mytabindex: number = -1;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    this.resetFilters();
    this.isdelete = "no";;
    this.load();

    
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

   // console.log('+=+=+=+ : ' + JSON.stringify(this.hyperLinkOption));

    
    //e شامل اپشن زیر استe
       // temp.op_moruroption = { "HPDarKhastId": e.HPDarKhastId, "HPSabtId": e.HPDarKhastId, "HPOpenOnInit": e.HPOpenOnInit };


    //اگر هیپرلینک ورودی داشت
    if (this.hyperLinkOption != null) {
      if (this.hyperLinkOption.HPDarKhastId != null)
        this.openeditId = this.hyperLinkOption.HPDarKhastId;

      if (this.hyperLinkOption.HPSabtId != null)
        this.focusedDarKhRowId = this.hyperLinkOption.HPSabtId;


      if (this.hyperLinkOption.HPDarKhastId != null)
        this.openedit = this.hyperLinkOption.HPOpenOnInit;





    }
 

  }
  ngOnChanges(changes: any) {

    if (this.gridContainer != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.gridContainer.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.gridContainer.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.gridContainer.instance.getCellElement(index, 0);
          var y = this.gridContainer.instance.getScrollable();
       
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.gridContainer.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }
  }
 change;
  fchang(e) {

    // this.fchanged = true;
    this.change = new tabeventobj(this.ftabindex);
    this.change.value = e;


    this.onfchanged.emit(this.change);
    console.log(this.ftabindex);

  }
  ngAfterViewInit() {

    this.loaddata.showed = true;
    this.cd.detectChanges();

  }

  onClicksearch() {

    this.load();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.load();
    console.log('m:searching... all');
  }
  load() {

   
    //console.log(this.monthRang);

    let y = this.localst.yearnow_get().toString();
    let dA = this.monthRang[0].toString();
    let dB = this.monthRang[1].toString();

    if (dA.length < 2)
      dA = dA = '0' + dA;
    if (dB.length < 2)
      dB = dB = '0' + dB;

    dA = y + dA + '00';
    dB = y + dB + '99';
    this.loadServ.showloading(this.loaddata, 0);
    this.anDeedService.getall(this.localst.yearnow_get(),-11)
      .subscribe((res) => {
       
        this.loadServ.hideloading(this.loaddata, 0);
      
        this.modlelist = new DataSource({
          store: {
            type: "array",
            key: "id",
            data: res.data,
            
          }
 
        });
      
       // this.modlelist = res;

        this.gridContainer.scrolling.scrollByContent = true;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

  }

 
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';
 
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.load();
  }


  reloadrows(): void {
   // this.load();
  //  console.log("m:page refleshe done");
   // this.load();
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
   // console.log(JSON.stringify(this.selectedrow));

  }



  showDel: boolean = false;

  currentDelete: AnDeed = null;

  onClickDelete() {

    this.currentDelete = null;
    var key = this.gridContainer.focusedRowKey;

    if (key) {

      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (this.modlelist._items[j].id == key) {
          this.currentDelete = this.modlelist._items[j];
          this.showDel = true;

          break;
        }
      }

    }


  }


 

  

  dodelete() {


    if (this.currentDelete) {

      this.loadServ.showloading(this.loaddata, 2);
      this.anDeedService.delete(this.currentDelete.id, null).subscribe((res) => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.showDel = false;
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {


          this.load();
          this.refleshDarKhastShomare();
          this.alertify.success(res.success[0]);
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });


    }
     
  }

  onClickCancelDelete() {

    this.showDel = false;
    this.currentDelete = null;

  }


  OnMyCancelEvent(e) {
    console.log('cancel form');
   // console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }

  }
  onClickNew() {

    console.log('newopen');


    this.openedit = true;


  }


  onClickEdit() {


    if (this.selectedrow == null) return;


    this.openeditId = this.selectedrow.id;

    this.openedit = true;

  }
  OnMySaveEvent(e) {

 
    if (e.reloadlist) {

      this.load();

    }

    if (e.closepage) {

      this.openedit = false;
      this.openeditId = -1;

    }


 

  }


  refleshDarKhastShomare() {

  

  }

  //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  //{ id: 2, name: 'موقت' },
  //{ id: 3, name: 'بررسی شده' },
  //{ id: 4, name: 'قطعی شده' }

  //]


  onClickbarasi(vaziatnumber : number) {


    if (this.gridContainer.selectedRowKeys == null)
      return;
 
    //console.log(JSON.stringify(this.gridContainer.selectedRowKeys));

    var vaziat = this.anDeedService.getvaz(vaziatnumber);

    if (vaziat == null)
      return;


    this.loadServ.showloading(this.loaddata, 4);
    this.anDeedService.updateVaz(this.gridContainer.selectedRowKeys, vaziat.id).subscribe((res: ApiRet) => {

      res;
      this.loadServ.hideloading(this.loaddata,4);
     

    }, error => {
        this.loadServ.hideloading(this.loaddata,4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  

  }


  onClickghati(v: number) {

      let mess = 'از قطعی شدن اسناد اطمینان دارید؟';
    this.valdata = { title: mess, rows: [' در صورت قطعی شدن امکان برگشت وجود ندارد'], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
     

    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let dialogdata = result;

      if (dialogdata != null) {


        console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.onClickbarasi(4);

        }
      }
    });

     

  }
  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

  onCellPrepared(e) {


    
    if (e.rowType === "data" && e.column.dataField === "vaziat") {
    //  if (e.data.Amount==2) 
    //  e.cellElement.style.backgroundColor  =  "green" ;
      // Tracks the `Amount` data field
      //e.watch(function () {
      //  return e.data.Amount;
      //}, function () {
      //  e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      //})
    }



  }
  //فرمت
  //[calculateCellValue] = "calculateCellDateValue"
  //calculateCellDateValue(data) {
  //  let ddd = data.date.toString();
  //  ddd = ddd.substring(1, 2)

  //  return ddd;
  //}

  onClickReflesh() {

    
 
    this.load();



  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance,' لیست اسناد ');

 
  }

  onExporting(e) {

  }
  onClickPrint() {

    

    this.openeprint = true;

    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }
    //اگر پرینت از لیست سند صدا شود
  onCloseprint(e) {
 
    //console.log('click2');
    this.openeprint = false;

  }
  onPrintPrint(e) {

    this.onPrint.emit(e);
    this.openeprint = false;

  }
  //اگر پرینت از صفحه ویرایش سند صدا شود
  OnEditPrintEvent(e) {

    if (e == null)
      return;


    this.printnum = e;
   // console.log(e);
    this.openeprint = true;

    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }
  test8() {
   // this.gridContainer.instance.sc
    console.log("test8");
  //  this.gridContainer.instance.columnOption(5, "width", 900);
  }

  onClickDublctaeDarKhast() {

   let id =  this.gridContainer.focusedRowKey;

    if (id == null)
      return;

    for (var r = 0; r < this.modlelist._items.length; r++) {

      if (this.modlelist._items[r].id == id) {


        this.duplcateOption.copyId = this.modlelist._items[r].id;
        this.duplcateOption.copyCSh = this.modlelist._items[r].cShomare;
        this.duplcateOption.paseId = null;
        this.duplcateOption.paseCSh = null;
        this.duplcateOption.date = null;
      //  this.duplcateOption.date = this.modlelist[r].date_String;

        if (this.modlelist._items[r].vaziat != 200) {
          this.alertify.alert('سند باید در وضعیت موقت قرار داشته باشد');
          return;

        }

        break;
      }

    }



    if (this.duplcateOption.copyId == null)
      return;

    this.openeDuplcate = true;
    if (this.openeprint)
      this.openeprint = false;

  }



  onClickMergeDarKhast() {

    var keyss = this.gridContainer.selectedRowKeys;

   

   var selected = [];
   var ignored = [];

    if (keyss != null && keyss.length > 0)
    for (var i = 0; i < keyss.length; i++) {


      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (keyss[i] == this.modlelist._items[j].id) {
          if (this.modlelist._items[j].vaziat == 200) {
            selected.push(this.modlelist._items[j].id);
          }
          else {
            ignored.push(this.modlelist._items[j].id);

          }

          break;
        }

      }

    }

    if (ignored.length > 0) {

      this.alertify.message(ignored.length.toString() +' ردیف نادیده گرفته شد ' + ' همه ی اسناد باید در وضعیت موقت قرار داشته باشند ');
     

    }

    if (selected.length > 0) {
      this.mergeOption.inList = selected;
      this.mergeOption.byKind = 'inList';

    }  


    this.openeMerge = true;
    if (this.openeprint)
      this.openeprint = false;
    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }

  onCloseSSS(e) {

    this.openeDuplcate = false;

  }
  onClosemerge(e) {

    this.openeMerge = false;

  }
  onDuplcateDateChanged(e) {


    var newcshomare = -1;
    for (var r = 0; r < this.modlelist._items.length; r++) {

      newcshomare = this.modlelist._items[r].cShomare;

      if (this.modlelist._items[r].date <= e)
        break;
      

    }

    newcshomare++;

    this.duplcateOption.paseCSh = newcshomare;


  }


 
  onValueChangedrang(e) {

    this.load();
  }
  saveuioption() {
    var res = [];
    for (var i = 0; i < this.gridContainer.instance.columnCount(); i++) {
      var w = this.gridContainer.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
    }
    this.localst.optioncli.gw2006 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
}


