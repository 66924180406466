import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { HItemG } from '../../../../_models/app99/app99model';
import { App99Service } from '../../../../_services/app99/App99.service';
 
 
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app99-HItemG-list',
  templateUrl: './HItemG-list.component.html',
  styleUrls: ['./HItemG-list.component.css']
})


export class HItemGListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;

 

  modlelist: HItemG[];
 
 
 
 
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService,
    private app99Service: App99Service,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
    this.modlelist = [];
 
  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();

  }
 
 
  
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.app99Service.HItemG_getall()
      .subscribe((res) => {
        this.modlelist = [];
        this.modlelist = res.data;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
 
 
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

 
 


  onClickDelete() {

    if (this.loaddata.showed)
      return;

    
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
     
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {
    var key = this.gridContainer.focusedRowKey;

    if (key == null) {
      this.alertify.warning('ابتدا موردی را انتخاب کنید');
      return;
    }

    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.HItemG_delete(key).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
      else { 
        this.alertify.success(res.mes);
        this.load();

      }
   

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }







  onClickEdit() {

    var key = this.gridContainer.focusedRowKey;

    if (key == null) {
      this.alertify.warning('ابتدا موردی را انتخاب کنید');
      return;
    }

    if (this.loaddata.showed)
      return;
   


    this.openeditId = key;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.load();

  }
  onClickExportexcel() {

 

    this.excelService.exportAsExcelFile(this.modlelist, 'لیست اشخاص');
  }

  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }
}
