import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
import { parse } from 'querystring';
import { FileToUpload, SherkatInfo, SherkatInfoKeys } from '../../_models/General/sherkatInfo';
import { Localst } from '../localst.service';
import { ApiRet } from '../../_models/General/ApiRet';

let EmptySherkatInfoData : SherkatInfo = {



  id: -1,

  customer: null,
  name: null,

  shEghtesadi: null,

  shSabt: null,

  shenaseMelli: null,

  email: null,

  fax: null,

  post: null,

  tell: null,
  adressA: null,
  adressB: null,
  adressC: null,

ostanAId: null,
  shahrAId: null,
  ostanAN: null,
  shahrAN: null,
  ostanBId: null,
  shahrBId:  null,
  ostanBN: null,
  shahrBN: null,
  taxVer: 1,
  editCnt: 0,
  demoApp1: 0,
  demoApp2: 0,
  demoApp3: 0

};
 
 

 
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
 
  origin = location.origin;
 // baseUrl = environment.apiUrl;
  public GeneralChangEmitter$: EventEmitter<number>;


  constructor(private http: HttpClient, private localstService: Localst) {

    this.GeneralChangEmitter$ = new EventEmitter();
  }


  

  getSherkatInfo_null(): SherkatInfo {

    return JSON.parse(JSON.stringify(EmptySherkatInfoData)) ;

  }
 
  getSherkatInfo(): Observable<SherkatInfo>  {
    let apiadress = '/api/General/General/getSherkatInfo';
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<SherkatInfo>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;
 
  }
  //getTarazVersion(): Observable<ApiRet> {
   

  //  let apiadress = '/api/General/General/getTarazVersion';
  //  var headers = this.localstService.header_get(apiadress, null);
  //  return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  //}

  updateSherkatInfo(item: SherkatInfo) :Observable<ApiRet> {



    let apiadress = '/api/General/General/updateSherkatInfo';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  getSherkatInfoEmpty(): SherkatInfo {



    return JSON.parse(JSON.stringify(EmptySherkatInfoData));

  }


  updateSherkatInfoKeys(item: SherkatInfoKeys) :Observable<ApiRet> {



    let apiadress = '/api/General/General/updateSherkatInfoKeys';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  getSherkatInfoKeys(): Observable<ApiRet> {



    let apiadress = '/api/General/General/getSherkatInfoKeys';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }









  
  Paziresh_vaziatChang(): Observable<any> {
    let apiadress = '/api/General/General/Paziresh_vaziatChang';
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<any>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;

  }

  Paziresh_reflesh(years: number): Observable<any> {

    let apiadress = '/api/General/General/Paziresh_reflesh?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress,null);

    //var splitted = headers.split(" ", 3);
  /*  if (headers var splitted = str.split(" ", 3);)*/

    let v = this.http.get<any>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;

  }

  limitdadghostari_Get(): Observable<ApiRet> {

    let apiadress = '/api/General/General/limitdadghostari_Get' ;
    var headers = this.localstService.header_get(apiadress, null);

    //var splitted = headers.split(" ", 3);
    /*  if (headers var splitted = str.split(" ", 3);)*/

    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;

  }

  limitdadghostari_Set(res: any): Observable<any> {

    let apiadress = '/api/General/General/limitdadghostari_Set';
    var headers = this.localstService.header_get(apiadress, null);

    //var splitted = headers.split(" ", 3);
    /*  if (headers var splitted = str.split(" ", 3);)*/

    let v = this.http.post<any>(this.origin + apiadress, res, { 'headers': headers }).pipe(

    );

    return v;

  }

 
  uploadFile(theFile: FileToUpload): Observable<any> {
    let apiadress = '/api/General/General/uploadFile';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<FileToUpload>(this.origin + apiadress, theFile, { 'headers': headers });
  }
  getSherkatInfoLogo(): Observable<Blob> {

    let apiadress = '/api/General/General/getSherkatInfoLogo';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get(this.origin + apiadress, { responseType: "blob", 'headers': headers });

  }
  parivarMove(): Observable<ApiRet> {

    let apiadress = '/api/General/General/parivarMove';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
    
  }
  getVersionTax(): any[] {

    var data: any[] = [{ id: 1, name: 'نسخه یک' }, { id: 2, name: 'نسخه دو(همراه گواهی امضا)' }];
   
      

    return data;
  }


  uploadCerKeys(theFile: FileToUpload): Observable<any> {
    let apiadress = '/api/General/General/uploadCerKeys';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<FileToUpload>(this.origin + apiadress, theFile, { 'headers': headers });
  }
}
