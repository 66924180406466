import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnDeedJameRep, AnDeedJameRepOp } from '../../../_models/app2/rep/AnDeedJameRep';
import { IdName } from '../../../_models/General/IdName';
 
 
//let EmptyAnDeedRep: AnDeedRep= {
//  option: null,
//  ones: []
//}

let EmptyAnDeedJameRep: AnDeedJameRep = {
  option: null,
  rows: []
}

let EmptyAnDeedJameRepOp: AnDeedJameRepOp= {
  codeS: 0,
  codeE: 0,
  dateS_S: null,
  dateS: 0,
  dateE_S: null,
  dateE: 0,
  year: 0,
  prcA: 0,
  prcB:0,
  kind: [],
  anbars: [],
  kalaIds: [],
  tabages:[],
  vaziat:[],
  hesabs: [],
  taf4s: [],
  taf5s: [],
  taf6s: [],
  gUnSelect: [
    { id: 1, name: 'نوع سند', colum: 'KindId' },
    { id: 2, name: 'کالا', colum: 'kalaC' },
    { id: 3, name: 'انبار', colum: 'anbarId' },
    { id: 4, name: 'طبقه کالا', colum: 'TabaghId' },
    { id: 5, name: 'حساب معین', colum: 'HesabC' },
    { id: 6, name: 'طرف مقابل', colum: 'taf4C' },
    { id: 7, name: 'تفصیلی پنج', colum: 'taf5C' },
    { id: 8, name: 'تفصیلی شش', colum: 'taf6C' },
    { id: 9, name: 'شماره سند', colum: 'anDeedCode' }
  ],
  //مدل گروه بندی انتخاب شده
  gSelect: [],
  //ایا گروهبندی از این بابت صورت گرفته؟
  //برای حذف ستون ها استفاده میشود
 isKindId :false,
  isDateA: false,
  isAnbarId: false,
  isHesabC: false,
  isTaf4C: false,
  isTaf5C: false,
  isTaf6C: false,
  isKalaC: false,
  isTabaghId: false,
  isAnDeedCode: false,

  havePrc: false,
  haveSan: false,
  //havePrcE: false,
  haveSanE: false,
  isKharid: false,
  isExitNeg:true
}


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class AnDeedJameRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
  constructor(private http: HttpClient, private localstService: Localst) {

 

  }
   





  getAnDeedJameRepEmpty(): AnDeedJameRep {

    let a = JSON.parse(JSON.stringify(EmptyAnDeedJameRep));
    a.option = JSON.parse(JSON.stringify(EmptyAnDeedJameRepOp));

    return a;

  }

  AnDeedJameRep(item: any): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnDeedJameRep/AnDeedJameRep';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  DateRangAnDeed(year): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnDeedJameRep/DateRangAnDeed?year=' + year.toString();

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }




  getfirstCodeAnDeed(year: number, date: string): Observable<number> {
    
    let apiadress = '/api/App2/Rep/AnDeedJameRep/getfirstCodeAnDeed?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getfirstDateAnDeed(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App2/Rep/AnDeedJameRep/getfirstDateAnDeed?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  getlastCodeAnDeed(year: number, date: string): Observable<number> {

    let apiadress = '/api/App2/Rep/AnDeedJameRep/getlastCodeAnDeed?year=' + year.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }

  getlastDateAnDeed(year: number, codesanad: number): Observable<number> {

    let apiadress = '/api/App1/Rep/AnDeedJameRep/getlastDateAnDeed?year=' + year.toString() + '&code=' + codesanad;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });

  }
  AnDeedJameRepAsExcel(item: any): Observable<Blob> {

    let apiadress = '/api/App2/Rep/AnDeedJameRep/AnDeedJameRepAsExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<Blob>(this.origin + apiadress, item, { 'headers': headers, responseType: 'blob' as 'json' });

  }
}
