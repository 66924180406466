import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
import {  AnSell, AnSellRow, AnSellRowForMabna } from '../../../../_models/app3/opr/AnSell';
import { AnSellService } from '../../../../_services/app3/opr/AnSell.service';
import { FormGroup, FormBuilder, Validator } from '@angular/forms';

import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxComponent, DxTextBoxModule, DxDataGridComponent, DxTextAreaComponent, DxNumberBoxComponent, DxListComponent, DxLookupComponent, DxValidatorComponent, DxDropDownBoxComponent, DxScrollViewComponent} from 'devextreme-angular';

//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';

import { OstanService } from '../../../../_services/app1/paye/Ostan.service';


  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
 
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
 
import { MyTimeService } from '../../../../_services/myTime.service';
 
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { SaleMali } from '../../../../_models/General/saleMali';
import { GSharh } from '../../../../_models/General/GSharh';
import { GSharhService } from '../../../../_services/General/GSharh.service';
import { DxiColumnComponent, DxoGridComponent, DxoScrollBarComponent } from 'devextreme-angular/ui/nested';
import { LoadingService } from '../../../../_services/loading.service';
import { TafsiliGbydaste } from '../../../../_models/app1/paye/tafsiliGbydaste';
 
import { ExcelService } from '../../../../_services/excel.service';
 
import DataSource from "devextreme/data/data_source";
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import dxValidator from 'devextreme/ui/validator';
import { KalaForCombo } from '../../../../_models/app2/paye/Kala';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
import { AnDeedRowForMabna } from '../../../../_models/app2/opr/AnDeed';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';
import { AnSellHesService } from '../../../../_services/app3/paye/AnSellHes.service';
import { AnSellHes, AnSellHesTab } from '../../../../_models/app3/paye/AnSellHes';
import { LastPrc } from '../../../../_models/app3/opr/LastPrcDto';
import { PdLinkService } from '../../../../_services/app4/opr/PdLink.service';
import { PdLinkBoxRow, PdLinkBoxOp, TableName, AutoPdLink } from '../../../../_models/app4/opr/PdLink';
import { AccService } from '../../../../_services/General/Acc.service';
 

 
const sendRequest = function (value) {
  const validEmail = 'test@dx-email.com';
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value === validEmail);
    }, 1000);
  });
};
 
 
@Component({
  selector: 'app3-AnSell-edit',
  templateUrl: './AnSell-edit.component.html',
  styleUrls: ['./AnSell-edit.component.css']

})
export class AnSellEditComponent implements OnInit {
  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) { 
    this.width = width;
  }
  lastrowidtemplate: number;
  @Input() fchanged: boolean;
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() pishId: number|null;
  @Input() focusedAnSellRowId: number;
  @Input() kindpage: number;
  @Input() ftabindex: number;
  debounceTime = 500;

 

  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMySaveEvent = new EventEmitter<any>();
  @Output() OnMyRefleshEvent = new EventEmitter<boolean>();
  @Output() onfchanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowPdlinkListEvent: EventEmitter<any> = new EventEmitter<any>();
  isfchanged: boolean;
  //اگر -1 بود حالت دستی
  nextCode: number=0;
  isAutoCode: boolean=true;
  reloadMainList: boolean=false;
 
  title: string;
  baropen: boolean = false;
  Reg_Direct: boolean ;
 

  @Input() viewfreze: boolean;
  //
 // printoption: AnSellRepOption;

  @Output() OnMyPrintEvent = new EventEmitter<any>();
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();


  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("editColumnkalaC", { static: false }) editColumnkalaC: DxSelectBoxComponent;
  @ViewChild("editColumnTaraf", { static: false }) editColumnTaraf: DxSelectBoxComponent;
  @ViewChild("editColumnvahed", { static: false }) editColumnvahed: DxSelectBoxComponent;
  @ViewChild("cellTempMab", { static: false }) cellTempMab: DxSelectBoxComponent;
 

  @ViewChild("distempedit", { static: false }) distempedit: DxTextAreaComponent;
  @ViewChild("distempedit2", { static: false }) distempedit2: DxLookupComponent;


  @ViewChild("discolumeditedit", { static: false }) discolumeditedit: DxiColumnComponent;

  @ViewChild("listsharh", { static: false }) listsharh: DxListComponent;
  @ViewChild("ttt", { static: false }) ttt: DxTextBoxComponent;

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;
 
 
  @ViewChild("valllC", { static: false }) valllC: DxValidatorComponent;
  @ViewChild("valan", { static: false }) valan: DxValidatorComponent;
  @ViewChild('testForm', { static: true }) testFormElement;



  @ViewChild("moientempedit", { static: false }) moientempedit: DxSelectBoxComponent;
  @ViewChild("taf4tempedit", { static: false }) taf4tempedit: DxSelectBoxComponent;
  @ViewChild("taf5tempedit", { static: false }) taf5tempedit: DxSelectBoxComponent;
  @ViewChild("taf6tempedit", { static: false }) taf6tempedit: DxSelectBoxComponent;



  @ViewChild("mabdrpdown", { static: false }) mabdrpdown: DxDropDownBoxComponent;
  @ViewChild("mabdrpdownAnDeed", { static: false }) mabdrpdownAnDeed: DxDropDownBoxComponent;
  @ViewChild("griddown", { static: false }) griddown: DxDataGridComponent;
  @ViewChild("griddownAnDeed", { static: false }) griddownAnDeed: DxDataGridComponent;


  @ViewChild("numcodevalid", { static: false }) numcodevalid: DxValidatorComponent;


 
  @ViewChild("mabcolum", { static: false }) mabcolum: DxiColumnComponent;
  @ViewChild("mabAnDeedcolum", { static: false }) mabAnDeedcolum: DxiColumnComponent;



  bedhaveadad: boolean;
  focusedColumnIndex: number;
 
 
  ontopselector: string = 'ontopselector';

  sanadopen: boolean = false;
  sanadkind: number = 0;

  mymodle: AnSell;
  mymodle_old: AnSell;
 
 
  sho: false;
  somest: string;
  someint: number;
  onvandata: IdName[] = [];
  AfsKindsList: any[] = [];
  setmList: any[] = [];


  ///ابطال ها
  ansell_706list: any = {};


  l1: any[] = [];
  l2: any[] = [];
 

  lockAll: boolean = false;
  lockHeader: boolean = false;
  lockAllCSS: string = '';

  lock707: boolean = false;
  lock707CSS: string = '';
  //lockPrice: boolean = false;
  //lockPriceE: boolean = false;
  //lockKala: boolean = false;
  //lockHeader: boolean = false;
 
  test: string;
  n: number;
  selectedrow: AnSellRow;
  selectedrowOld: AnSellRow;
  firstShitedRowid: number;

  usernow: User;
  yearnow: number;
 
  lastprcList: LastPrc[] =[];
  lastprc: LastPrc;



  valdata: DialogData;
  selecteddisindext: number;
  messagetemp: DialogData;
  
 //مقدار دیفالت مالیات از رجیستری
  maliatDfault: number;
  showtakhDarsad: boolean;
 
 
 
  candatechang: boolean;
  disableghat: boolean;
  afsopen: boolean = false;

  SaleMaliselected: SaleMali;
  ColDisSelector: number ;
  vazcolor: string;
  loaddata: loadingCl;
  isreadonly = true;
  //yadashtandnew: boolean;
  moieninchang: boolean;

  firsttimeopen: boolean;
  radDisable: boolean = false;
  anbartitle: string;

  mabnas: any= [];
  mabnasAnDeed: any= [];

  mablist: AnSellRowForMabna[] = [];
  mablistAnDeed: AnDeedRowForMabna[] =[];

 
  tafMolist: any = {};
  anbarlist: any = {};
  kalaCombolist: any = {};

  devDataTaf4: any = {};
  devDataTaf5: any = {};
  devDataTaf6: any = {};

  Taf4bala: any = {};
 Taf5bala: any = {};
  Taf6bala: any = {};

  TafG: TafsiliForGroupRead;
  TafGbala: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];

  TafGListallCount = 0;
  tafjustdaste: TafsiliGbydaste[] = [];
  hesablist: Hesab[] = [];
  selestedkeys: number[] = [];
  selestedkeysAnDeed: number[] = [];

/*  AnSellHess: AnSellHesOnKalasDto[] = [];*/


  //لودینک موجودی را فعال میکند
  loadingM: boolean = false;
  //دریافت مقدار مو جودی در این متغییر
mojudiData: any = { amt: 0, vahedN :null};
  //ایا فرم در حالت خرو کالا قرار دارد
  isExit: boolean = false;

  hidePdlink: boolean = false;

  constructor(private userService: UserService, private alertify: AlertifyService,
    private router: Router,
    private AnSellService: AnSellService,
    //برای گرفتن مبنا حواله فروش
    private anDeedService: AnDeedService,
    private anRepService: AnRepService,
    private kalaService: KalaService,
    private anSellHesService: AnSellHesService,
   
    private AnAmelService: AnAmelService,
    private hesabService: HesabService,
 
    private myTimeService: MyTimeService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private loadServ: LoadingService,
    private localst: Localst,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private TafsiliService: TafsiliService,
    private anbarService: AnbarService,
    private accService: AccService,
    private pdLinkService: PdLinkService,
    private httpClient: HttpClient) {
    this.candatechang = false;
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);

    //if (this.kindpage > 0)
    //  this.anbartitle = 'انبار تحویل گیرنده';
    //else
    //  this.anbartitle = 'انبار تحویل دهنده';



    this.vazcolor = 'vaz-none';
    this.disableghat = true;
   

    

 
    this.ColDisSelector = 1;
    this.SaleMaliselected = localst.yearnow_Class_get();
 
 
    this.moieninchang = false;
    this.selectedrowOld = null;
    this.firstShitedRowid = null;
    this.firsttimeopen = true;
 
    //localst.optioncli.ui_AnSell_taf6_width;
   // this.yadashtandnew = false;
 
    this.lastprc = this.AnSellService.getLastPrcEmpty();
    this.TafGbala = this.TafsiliService.getTafsiliGroupEmpty();
    this.asyncValidation = this.asyncValidation.bind(this);
    this.pdLinkBoxRow = new PdLinkBoxRow();
  }
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);
    if (event.altKey==true) {
      if (event.keyCode == 83) {

        //if (event.shiftKey == true)
        //  this.addthis('AnSell');
        //else
        //  this.yaddasht('yaddasht');
      }
      //n
     if (event.keyCode == 78) {
        this.ctrNthis();
        //this.ttt.instance.focus();
      }
    }

  }

 
  Testing() {

    var r = $("#disdiv");
    console.log(r);
    r.find('.dx-texteditor-input').focus();
    console.log(r.find('dx-texteditor-input'));


  }
  myloclset() {

 //   this.localst.yearnow_set(0);

  }


  mychek() {

    console.log(this.mymodle);

  }

  testb() {


 

  }
  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;

  }

  onClicktest() {
/*    this.scrool.color=re*/
    //this.dataGrid.instance
 
    //var scrollContainer = $("#gridContainer").find(".dx-scrollable").first().dxScrollable("instance");
    //scrollContainer.scrollTo({ top: scrollContainer.scrollTop() + 10 });
  }
  ngOnInit() {

    if (!this.accService.menuRows.find(x => x.accC == 14000000)) {

      this.hidePdlink = true;

    }


    this.setmList = this.AnSellService.getsetmlist();

    if (this.kindpage > 0) {
      this.isExit = false;
     this.anbartitle = 'انبار تحویل گیرنده';

    }
    
    else {
      this.isExit = true;
      this.anbartitle = 'انبار تحویل دهنده';
    }
    

    //برگشت از فروش را هم از رجیستری فروش میخوانیم
    let kind = this.kindpage;
    //if (kind == 706)
    //  kind = -706;
     
    this.isAutoCode = this.localst.GetRegistryByPath('App3-AnSell-[Auto' + kind.toString() + ']').vBool;
    this.maliatDfault = this.localst.GetRegistryByPath('App3-AnSell-[Maliat]').vDouble;
    this.showtakhDarsad = this.localst.GetRegistryByPath('App3-AnSell-[takhDarsad]').vBool;

    this.AfsKindsList = this.AnSellService.AfsKindsList();

    this.reloadMainList = false;
    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.myoninit();
    this.refleshhesabvatafsili();

    this.selectedrow = this.AnSellService.getAnSellRowEmpty();
 
    this.selectedrow.hesabN = '-';
    this.selectedrow.taf4N = '-';
    this.selectedrow.taf5N = '-';
    this.selectedrow.taf6N = '-';

    if (this.viewfreze) {
      this.isreadonly = true;
    }


    //اگر فرم ابطال بود دیتا سند فروش را مبخوانیم

    if (this.kindpage == 707) {

      this.lock707 = true;
      this.lock707CSS = 'mydisble';
 

      this.loadServ.showloading(this.loaddata, 3);
      this.AnSellService.getall(this.localst.yearnow_get(), -706).subscribe(

        (res) => {
          this.loadServ.hideloading(this.loaddata, 3);

          this.ansell_706list = new DataSource({
            store: {
              type: "array",
              key: "id",
              data: res.data,

            }

          });
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }





    this.bindLock();
  }

  async  refleshhesabvatafsili() {


 



    this.loadServ.showloading(this.loaddata, 17);

    let a = null;
    if (this.kindpage == -706 || this.kindpage == 706 || this.kindpage == 707 || this.kindpage == 708 || this.kindpage == -701)
      a = await this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId ,'ansell').toPromise();
   

    this.tafMolist = new DataSource({
      store: a,
      paginate: true,
      pageSize: 50
    });


  
    this.anbarlist =await (await this.anbarService.getalltiny().toPromise()).data;
 
    
 
    this.tafjustdaste = (await this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'ansell').toPromise()).data;



    //اگر انباری را انتخاب کرده بودیم و کالایی در همان انبار را اضافه کردیم لیست کالا بروز شود
    if (this.mymodle.anbarId != null)
      this.loadkala(this.mymodle.anbarId);




    ////اطلاعات حسابداری





    
    this.hesablist = await this.hesabService.getHesabs_treenum(3).toPromise();


    
    this.TafGListall = await this.TafsiliService.getTafsilisGroupList().toPromise();
    this.TafGListallCount = this.TafGListall.length;
    this.tafg_reflesh(this.selectedrow.hesabId);



    
    this.tafjustdaste = (await this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'ansell').toPromise()).data;

    //پاک کردن کش تمام حساب های tafglist
    for (var r = 0; r < this.TafGListall.length; r++) {
      if (this.TafGListall[r].taf4List.length > 0) {
        this.TafGListall[r].taf4List = [];
        this.TafGListall[r].taf5List = [];
        this.TafGListall[r].taf6List = [];
        this.tafg_reflesh(this.TafGListall[r].hesabId);
      }
    }



    this.loadServ.hideloading(this.loaddata, 17);
    //console.log('down' + this.tafjustdaste.length);




    this.tafg_reflesh(this.mymodle.hesabId);
  }

  // refleshhesabvatafsili() {






  //  this.loadServ.showloading(this.loaddata, 10);
  //  this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002).toPromise().then(data => {
  //    this.loadServ.hideloading(this.loaddata, 10);



  //    this.tafMolist = new DataSource({
  //      store: data,
  //      paginate: true,
  //      pageSize: 50
  //    });

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 10);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error);
  //  });

  //  this.loadServ.showloading(this.loaddata, 12);
  //  this.anbarService.getalltiny().subscribe(res => {
  //    this.loadServ.hideloading(this.loaddata, 12);



  //    this.anbarlist = res.data;

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 10);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error);
  //  });




  //  this.loadServ.showloading(this.loaddata, 7);
  //  this.TafsiliService.getTafsiliJustGrupedByDaste().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 7);
  //    this.tafjustdaste = data;

  //    console.log('down' + this.tafjustdaste.length);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 7);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });


  //  ////اطلاعات حسابداری



  //  this.loadServ.showloading(this.loaddata, 15);
  //  this.hesabService.getHesabs_treenum(3).subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 15);
  //    this.hesablist = data;

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 15);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });



  //  this.loadServ.showloading(this.loaddata, 16);
  //  this.TafsiliService.getTafsilisGroupList().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 16);
  //    this.TafGListall = data;
  //    this.TafGListallCount = this.TafGListall.length;

  //    this.tafg_reflesh(this.selectedrow.hesabId);

  //    console.log('Tafglistgeted!!! ' + this.TafGListallCount);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 16);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });




  //  this.loadServ.showloading(this.loaddata, 17);
  //  this.TafsiliService.getTafsiliJustGrupedByDaste().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 17);
  //    this.tafjustdaste = data;



  //    //پاک کردن کش تمام حساب های tafglist
  //    for (var r = 0; r < this.TafGListall.length; r++) {
  //      if (this.TafGListall[r].taf4List.length > 0) {
  //        this.TafGListall[r].taf4List = [];
  //        this.TafGListall[r].taf5List = [];
  //        this.TafGListall[r].taf6List = [];
  //        this.tafg_reflesh(this.TafGListall[r].hesabId);
  //      }
  //    }




  //    console.log('down' + this.tafjustdaste.length);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 17);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });




  //}

  onanbarchanged(e) {

    this.loadMabnaAnDeed();
    //لود وقی بار اول نیست
    if (e.previousValue != null) {
      this.mymodle.rows = [];
      this.pushnewrow(true);


    }
    //ghjgh4ghjfjyf
    if (e.value == null) {

      this.isreadonly = true;
     
      this.kalaCombolist = new DataSource({
        store: [],
        paginate: true,
        pageSize: 50
      });
    }
   else {
      this.isreadonly = false;
     
      this.loadkala(+e.value);
    }
    this.loadMabna();
    this.disbRadion();

  }
  disbRadion() {

    //باید هر دوی تفصیلی و طرف مقابل مشخص باشد تا بتوان مبنا را مشخص کرد
    //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    if (this.mymodle.anbarId == null || this.mymodle.taf4Id == null  )
    this.radDisable = true;
    else
      this.radDisable = false;

  }


  loadkala(anbarId) {


    this.loadServ.showloading(this.loaddata, 11);
    this.kalaService.getallForCombo(anbarId, this.kindpage, 'ansell!' + this.editId.toString()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);
      
      this.kalaCombolist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });

      this.filvahedlis();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  pdLinkBoxRow : PdLinkBoxRow

  loadPdlink(ansellid: number) {

 
    this.pdLinkBoxRow = new PdLinkBoxRow();
    if (ansellid > 0) {
      this.loadServ.showloading(this.loaddata, 10);
      this.pdLinkService.getPdLinkBoxRow(3, ansellid).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 10);


        this.pdLinkBoxRow = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 10);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });
    }
   
  }

  //در حالت ویرایش بعد از لود لیست کالا و اقلام ورود خروج . لیست واحد های هر ردیف را باید بارگذاری کنیم
  //تا اگر روی لیست کالا های هر ردیف کلیک کردیم لیست خالی نباشد
  filvahedlis() {

    if (this.isnew)
      return;

    if (this.kalaCombolist._store == undefined )
      return;

    if (this.kalaCombolist._store._array.length == 0 ||
      this.mymodle.rows.length == 0)
      return;


    for (var r of this.mymodle.rows) {

      for (var j of this.kalaCombolist._store._array) {


        if (r.kalaId == j.id) {

        
          r.vs = j.vs;

          break;
        }

      }
    }


  }
  


  modelloaded: boolean = false;
  myoninit() {

    //این متغییر جلو پاک شدن جدول با تغییر رادیون در بار اول میگیرد
    this.flo = false;
    this.flo2 = false;
     

    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.candatechang = true;
    /////////////////////////constractor///////////////////////////
    this.validationCallback = this.validationCallback.bind(this);
    this.mymodle = this.AnSellService.getAnSellEmpty(this.kindpage);
    this.mabnas = this.AnSellService.getMabnaRadionData(this.kindpage);
    this.mabnasAnDeed = this.AnSellService.getMabnaAnDeedRadionData(this.kindpage);


    this.mymodle.kind = this.kindpage;
 
    this.mymodle.rows = [];
    
 
    this.enc = 0;




    this.lastrowidtemplate = -2000100000;
    this.lastrowidtemplate--;
    this.selectedrow = this.AnSellService.getAnSellRowEmpty();
    this.usernow = this.localst.usernow_get();
    this.yearnow = this.localst.yearnow_get();

  
     

    this.bedhaveadad = false;
    this.focusedColumnIndex = 2;

 
    if (this.editId == -1) {
      this.isreadonly = true;
      console.log('new mode');
      this.mymodle = this.AnSellService.getAnSellEmpty(this.kindpage);
      this.mymodle.kind = this.kindpage;
      this.mymodle.years = this.localst.yearnow_get();
      this.refreshvazColor(this.mymodle.vaz);
      this.mymodle.dateA_S = this.localst.dateEmruz.dateString;
 
      this.mymodle.uSa = this.localst.usernow_get().username;
      this.mymodle.uSaF = this.localst.usernow_get().name;

      if (this.kindpage == -701) {
        this.mymodle.setm = 1;
      }
      if(this.pishId >0)
        this.loadPish(this.pishId);

      this.refleshAnSellcods(true);
      this.pushnewrow(true);
      this.isnew = true;
      this.setCode();
      this.modelloaded = true;
   

      //اگر سیستم مستقیم بود
      if (this.localst.GetRegistryByPath('App3-AnSell-[Direct]').vBool) {
        this.mymodle.mabnaAnDeed = 0;
        this.radDisable = false;
        this.mymodle.mabna = 0;
      }
      else {
        this.mymodle.mabnaAnDeed = -106;
        this.radDisable = true;
        this.mymodle.mabna = 0;
      }



      this.fillAfslhesab();
      this.loadPdlink(this.editId);
    }
    else {
     
      this.isnew = false;
      this.loadServ.showloading(this.loaddata,3);
      this.AnSellService.getbyid(this.editId).subscribe( 
   
        (data: AnSell) => {
          this.loadServ.hideloading(this.loaddata,3);
          this.isreadonly = false;
       
           
          this.mymodle = data;

          this.mymodle.uSaE = this.localst.usernow_get().username;
          this.mymodle.uSaEF = this.localst.usernow_get().name;
          //45764754754
          this.mymodle_old = JSON.parse(JSON.stringify(this.mymodle));
     

          //رنگ وضعیت
          this.refreshvazColor(this.mymodle.vaz);


          //فیلتری برای جلو گیری از پاک شدن جدول هنگامی که ویرایش را باز میکنیم
          //چون هنگام لود مدل رادیون باکس عوض میشه جلو این رویداد را برای بار اول میگیریم
          this.flo=true

          this.setCode();

          this.tafg_reflesh(this.mymodle.hesabId);
           
         // this.inmyintilized = false;
          this.pushnewrow(true);

        
          this.modelloaded = true;
        
          this.calcallTakhAll();
          this.filvahedlis();
          this.bindLock();
          this.loadPdlink(this.editId);
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }


  


  }


  loadPish(pishid: number) {

    this.loadServ.showloading(this.loaddata, 3);
    this.AnSellService.getbyid(pishid).subscribe(

      (data: AnSell) => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.isreadonly = false;


        let pish = data as AnSell;

        this.mymodle.anbarId = pish.anbarId;
        this.mymodle.taf4Id = pish.taf4Id;
        this.mymodle.setm = 1;

        this.mymodle.rows = pish.rows;
        this.mymodle.anSellIdPish = pish.id;
        this.mymodle.anSellIdPishCode = pish.code;


      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 3);
        console.log(error);
        this.alertify.error(error);
      }

    
      )

  }




  //گرفتن نوع اتو بودن کد و اگر بود کد بعدی
  setCode() {


    this.loadServ.showloading(this.loaddata, 13);
    this.AnSellService.nextCode(this.localst.yearnow_get(), this.kindpage).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.nextCode = res.value;

     
        if(this.isnew)
        this.mymodle.code = this.nextCode;
  
        
    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });




  }


  refleshAnSellcods(isnew: boolean) {

   //// return;
   // console.log('reflesh');
   // var g: AnSell = JSON.parse(JSON.stringify(this.mymodle));
   // g.rows = null;
   // if (g.dateD_S != null) {
   //   this.loadServ.showloading(this.loaddata,4);
   //   this.AnSellService.getAnSellEmpty().subscribe(data => {
   //     this.loadServ.hideloading(this.loaddata,4);
   //     var r = data as AnSell;

   //     //    this.mymodle.cAtf = r.cAtf;
   //     //    this.mymodle.cFare = r.cFare;
   //     //   this.mymodle.cRozane = r.cRozane;
   //     //  this.mymodle.cShomare = r.cShomare;

   //     this.mymodle.cAtf = 0;
   //     this.mymodle.cFare = 0;
   //     this.mymodle.cRozane = 0;
   //     this.mymodle.cShomare = 0;

   //     this.mymodle.date = r.date;


   //     this.mymodle.date_String = r.date_String;

   //   //  this.ttt.instance.focus(); 
   //  //   console.log(JSON.stringify(this.mymodle));
   //   }, error => {
   //       this.loadServ.hideloading(this.loaddata,4);
   //     this.alertify.error(error);
   //     console.log("myerror:" + error)
   //   });

   //  }

  }
  calculateCellValue(data) {
    return [data.kalaId, data.hesabName].join(" ");
  }
  calledsubmitvaz;

  
 
  addthis(condition: string) {
 
  
    if (this.loaddata.showed)
      return;
    this.calledsubmitvaz = this.AnSellService.getvaz(200);

    this.onFormSubmit(condition);

  }
  
  cancelthis(e) {

 
    this.onfchanged.emit(false);
    this.OnMyCancelEvent.emit(true);
    this.OnMySaveEvent.emit({ reloadlist: this.reloadMainList , closepage: false });

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
   
        this.cancelthis(e);
      });
      return;
    }
 
 

  }
  //onFormSubmit = function (e) {

  onFormSubmit(condition : string) {

  
 
    //برای رفلش مانده
    this.pushnewrow(false);

 
    this.repairlostKalaId();


    if (this.myvalidationchek(condition) == false) {
      this.pushnewrow(false);
      return;
    } 



      this.refreshvazColor(this.calledsubmitvaz.id);
 
 
    if (this.mymodle.id == -1) {
    
        this.loadServ.showloading(this.loaddata,6);
        this.AnSellService.add(this.mymodle).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,6);
 
          if (res.errors.length > 0) {

            this.alertify.showListString(res.errors);
            return;
          }
          else {
            this.alertify.success(res.success[0]);
            this.reloadMainList = true;

            this.editId = res.data;
          //  this.myoninit();
 
            this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });
          }

   

        
       
          if (condition == 'addAndNew') {
            this.ctrNthis(); 
          } 
          else { 
            this.myoninit(); 
          } 
        
           
           

      }, error => {
          this.loadServ.hideloading(this.loaddata,6);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,8);
      this.AnSellService.update(this.mymodle).subscribe(res => {
      
        this.loadServ.hideloading(this.loaddata,8);
  
        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.success[0]);

          this.editId = res.data;
     
        //  this.mymodle = res.data;
        }

        this.reloadMainList = true;
        //  ؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟
        this.OnMyRefleshEvent.emit(true);
        this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });

  
        if (condition == 'addAndNew') {
          this.ctrNthis(); 
      
        }
        else {
          this.myoninit();
        }

        this.loadMabna();
        //???????????در ویرایش مبنا دیگه نشه عوض کرد
        //this.radDisable = true;

      }, error => {
          this.loadServ.hideloading(this.loaddata,8);
        this.alertify.error(error);
          console.log("myerror:" + error);
      });

    }

    this.isfchanged = false;
    this.onfchanged.emit(false);
    //  e.preventDefault();
  }






 

  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;


  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {

    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);


    //  this.AnSellService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");

    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });

    //console.log('sec ' + ccode);


  }
  // public  validationCallback(e: any): boolean {
  ctrNthis() {

 

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.ctrNthis();
      }); 
      return;
    }
    //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم
    
    if (this.loaddata.showed)
      return;
    //4543thrtrrt
    this.editId = -1; 
    this.myoninit();
    this.alertify.message('سند جدید');
    this.ttt.instance.focus();
  }

  validationCallback(e) {
    return true

   

  }


  validateNumber(e) {



  }
 
  dateAnSellRow(e) {
     
    if (this.candatechang)
      return;



   

    const previousValue = e.previousValue;
    const newValue = e.value;
 
 let valid=   this.myTimeService.isvalidday(newValue);






    //if(this.isnew)
    //this.refleshAnSellcods(false);
     
 



  }
  onFocusIn(e) {

 

  }
  onFocusIn2(e) {
  
    e.element.querySelector("input.dx-texteditor-input").select();  
  //  e.select();
  //  e.instance.select();
    //reset hide
    this.candatechang = false;

  

  }
  onFocusOut(e) {

 

  }

  onInitialized(e) {
    //   e.component.focus();  
    //  this.inputName.instance.focus();
    //  $("#ttt").focus();  


    // console.log(e.element);
    // console.log(e.instance.focus());

  }

  focusMethod = function getFocus() {
    document.getElementById("myTextField").focus();
  }


  onFocusRow(e) {




    //for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
    //  this.mymodle.rows[_i]._rowStyle = 'csnone';
    //  if (this.mymodle.rows[_i].id == e) {
    //    this.mymodle.rows[_i]._rowStyle = 'csfocus';
    //  }


    //}


  }
  deleteDone(e) {

  }










  ///////////////////////////////////table function ----------------------

 
  onFocusedRowChanged(e) {



   
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    } 
    let f = e.row.data;
 
 



   


    for (var _i = 0; _i < this.mymodle.rows.length; _i++) {


      if (this.mymodle.rows[_i].id == f.id) {
         
        //اگر اویل بار اولین ردیف را کلیک کردیم
        if (this.selectedrowOld == null)
          this.selectedrowOld = JSON.parse(JSON.stringify(this.mymodle.rows[_i]));
        else
          this.selectedrowOld = JSON.parse(JSON.stringify(this.selectedrow));

        this.selectedrow = this.mymodle.rows[_i];
      }
       


    }

 



    this.pushnewrow(true);


    if (e.row.data.kalaId == null) {
      this.dataGrid.instance.editRow(e.row.rowIndex);


    }
    // else this.dataGrid.instance.cancelEditData();

    this.loadfooter(e.row.data.kalaId, e.row.data.vahBId);
  
  }


  loadfooter(kalaid, vahedId: number | null) {
    this.mojudiData.amt = 0;

    let ignorid = this.mymodle.anDeedId;
    if (ignorid == null)
      ignorid = -1;
    //   this.mojudiData.
    if (this.mymodle.dateA_S != null, kalaid != null) {
      this.loadingM = true;
      this.anRepService.Mojudi(this.localst.yearnow_get(), this.mymodle.dateA_S, kalaid, this.mymodle.anbarId, ignorid).subscribe(res => {

        this.mojudiData = res.data;

        this.loadingM = false;
      }, error => {
        this.loadingM = false;
        this.alertify.error(error);
      });
    }




    //this.lastprc.prcLast = 0;
    //this.lastprc.prcMi = 0;
    //this.lastprc.prcTaf = 0;
    this.lastprcList = [];
    this.tempvahedid = null;

    if (kalaid != null && this.mymodle.taf4Id != null) {

      this.AnSellService.getLastPrc(kalaid, this.mymodle.taf4Id).subscribe(res => {

        this.lastprcList = res;


        //اگر در این فاصله جواب نیامده بود ولی واحد عوض شده بود
        if (this.tempvahedid != null) {
          vahedId = this.tempvahedid;
          this.tempvahedid = null;
        }
        this.selectLastPrc(vahedId);



      }, error => {

        this.alertify.error(error);
      });
      }
  }
  //اگرواحد انتخاب کردیم و لی هنوز جواب
  //getLastPrc
  //نیامده بود
  tempvahedid: number | null;



  selectLastPrc(vahedId: number | null) {
 



        //اگر جواب لیست قیمت ها نیامده  بود
    if (this.lastprcList.length == 0) {
      this.tempvahedid = vahedId;
      return;

    }
    else {
      this.tempvahedid = null;

    }
    


    if (this.lastprcList.length == 1) {
      this.lastprc = this.lastprcList[0];
     
    }
    else if (vahedId != null) {

      for (var r of this.lastprcList) {

        if (r.vahedId == vahedId) {

          this.lastprc = r;
          
        }
      }


    }
    else {
      this.lastprc = this.AnSellService.getLastPrcEmpty();
    }


  }
  pushnewrow(focusnwerow : boolean) {

    this.mymodle.rows = this.mymodle.rows || [];

    if (this.mymodle.rows.length == 0) {
      let a = this.AnSellService.getAnSellRowEmpty();
      //ای دی منفی میدهیم تا جدول قاطی نکنه
      //منفی نشاندهنده جدید بودن و نبود در دیتا بیس است
      a.id = this.lastrowidtemplate;


      this.mymodle.rows.push(a);
      this.refreshradifnumber();
       //حساب ردیف جدید پر بشه
      this.filrowheasbs(true);
      return;
    }
     

    let existnewrow = true;
    let radif = 1;
 


    if (this.mymodle.rows != null) {
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
        radif = radif + 1;

        if (this.mymodle.rows[_i].kalaId == null) {

          existnewrow = false;
        }


      }
    }
   


  
    if (existnewrow) {
  
      var r = this.AnSellService.getAnSellRowEmpty();
      this.lastrowidtemplate--;
      r.id = this.lastrowidtemplate;
      r.radif = radif;
    
      this.mymodle.rows.push(r);
    }
    //حساب ردیف جدید پر بشه
    this.filrowheasbs(true);
 
  }
  tafchanged(e) {
     
    var h = e.component.option('selectedItem') as TafsiliForSmall;
    if (h == null)
      return;

    /////tofmali
    /////تا مبنا نال نمونه
    /////مبنا در مدل خالی نمیتونیم مقدار بدیم و باید اینا باشه چون موقع لود صفحه رویداد تغییر ردیف جدول خالی میکنه
    //if (this.mymodle.AnSellId == null)
    //  this.mymodle.AnSellRowId = 0;

    this.mymodle.taf4Id = h.id;
    this.mymodle.taf4C = h.code;
    this.mymodle.taf4N = h.name;

    this.disbRadion();
    this.filrowheasbs(false);

    this.loadMabna();
    this.loadMabnaAnDeed();
  }

  onRowUpdating(e) {

 


  }


  //tmptae edit
  onss(e, cellInfo) {


 
    cellInfo.setValue('123435');
  }
  oncheckchanged() {
    this.loadMabna();
    this.loadMabnaAnDeed();


  }


  datechanged() {
    this.loadMabna();
    this.loadMabnaAnDeed();

    this.numcodevalid.instance.validate();
  }
   
  temp_kalachanged(e, cellInfo) {

    if (e.value == undefined)
      return;


    let IsKalaDupl = false;
    for (var r of this.anbarlist) {

      if (this.mymodle.anbarId == r.id) {

        IsKalaDupl = r.isKalaDupl;
      }

    }

    if (!IsKalaDupl)
    for (var t of this.mymodle.rows) {

      //766776568654
      if (t.kalaId == e.value && t.id != cellInfo.data.id) {
        this.alertify.message('کد کالا تکراری است');
        cellInfo.data.kalaId = null;
        cellInfo.data.kalaC = null;
        cellInfo.data.kalaN = null;

        return;
      }
    }

  
    this.isfchangcall();

   

  

    for (var r of this.kalaCombolist._store._array){

      if (r.id == e.value) { 

        var temp = r as KalaForCombo;
        cellInfo.data.kalaId = temp.id;
        cellInfo.data.kalaC = temp.c;
        cellInfo.data.kalaN = temp.n; 
        cellInfo.data.vahId = temp.vs[0].id;
        cellInfo.data.vahN = temp.vs[0].n;
        cellInfo.data.vahBId = temp.vs[0].id;
        cellInfo.data.vahBN = temp.vs[0].n;
        cellInfo.data.zMulti = temp.vs[0].z;
        cellInfo.data.vs = temp.vs;
        cellInfo.data.fi = temp.p;
        cellInfo.data.darsadM = this.maliatDfault;

        this.loadfooter(e.value, temp.vs[0].id);
        break;
      }

    }

 
  

  }

 
  temp_vahedchanged(e, cellInfo) {

    if (e.value == undefined)
      return;



 

    for (var r of cellInfo.data.vs) {

      if (r.id == e.value) {
        cellInfo.data.vahBId = r.id;
        cellInfo.data.vahBN = r.n;
        cellInfo.data.zMulti = r.z;
    
     
          this.selectLastPrc(r.id);
      
        break;
      }
    }
    cellInfo.data.vahBId = e.value;

  }


  setbarname(AnSellRowId) {



    if (this.mymodle.barname.id != -1)
      return; 

      //????????
    if (this.kindpage == 706)
      return;

    this.loadServ.showloading(this.loaddata, 16);
    this.AnSellService.getAnSellBar(AnSellRowId).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 16);
   
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.success[0]);

        this.mymodle.barname = res.data;
        //برای کپی شدن بارنامه 
        this.mymodle.barname.id = 0;
      }
      

    }, error => {
      this.loadServ.hideloading(this.loaddata, 16);
      this.alertify.error(error);
    });







  }

 

 

  //////////////////////////////////////////////////////////////>



  onEditCanceled(e) {

 
  }

  logEvent(eventName) {
    
  }

  onSavedrow(eventName) {
 
  }
  onFocusInrowend(e) {

    // this.dataGrid.instance.saveEditData();






  }


  onValueChangedamtB(e, cellInfo) {

    //fy575rht
    this.isfchangcall();
    if (e.value > cellInfo.data._maxAmt && this.mymodle.mabnaAnDeed != 0) {
      this.alertify.warning('مقدار وارد شده بزرگتر از حداکثر مجاز مبنااست');
      return;
    }
    if (e.value > cellInfo.data._maxAmt && this.mymodle.mabna != 0) {
      this.alertify.warning('مقدار وارد شده بزرگتر از حداکثر مجاز مبنااست');
      return;
    }

 
    


   
    //برگشت از فروش مبنا دار
    if (this.kindpage == 706 && this.mymodle.mabna ==-706) {
      let zarib = 1;
      //u6875685689
      if (cellInfo.data.amtB <= 0) {
        this.alertify.warning('مقدار نمیتواند صفر باشد');
        return;
      }
      

      zarib = (e.value).toFixed(3) / cellInfo.data.amtB;

      cellInfo.data.amtB = (e.value).toFixed(3);
      cellInfo.data.prc = Math.round(cellInfo.data.amtB * cellInfo.data.fi);

      this.afterprice707(cellInfo , zarib);
    }
    else {


      cellInfo.data.amtB = (e.value).toFixed(3);

      cellInfo.data.prc = Math.round(cellInfo.data.amtB * cellInfo.data.fi);
      this.afterprice(cellInfo);
    }
  }




  onValueChangedFi(e, cellInfo) {
    this.isfchangcall(); 



    cellInfo.data.fi = e.value;

    cellInfo.data.prc = Math.round(cellInfo.data.amtB * cellInfo.data.fi);

    this.afterprice(cellInfo);

  } 
  onValueChangedPrc(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prc = Math.round( e.value);
    if (cellInfo.data.amtB > 0.00001) {
      cellInfo.data.fi = cellInfo.data.prc / cellInfo.data.amtB;
    }
    else {
      cellInfo.data.fi = 0;
    }

    this.afterprice(cellInfo);

  }



  onValueChangedPrcTkh(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prcTkh = Math.round(e.value);
    if (cellInfo.data.prcTkh > cellInfo.data.prc + cellInfo.data.prcHaz) {
      cellInfo.data.prcTkh = cellInfo.data.prc + cellInfo.data.prcHaz;
    }


    if (cellInfo.data.prc > 0)
      cellInfo.data.prcTkhDrsd = (cellInfo.data.prcTkh / cellInfo.data.prc) *100;

    this.afterprice(cellInfo);
    this.calcallTakhAll();

  }
  onValueChangedPrcHaz(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prcHaz = Math.round(e.value);
    if (cellInfo.data.prc >0)
      cellInfo.data.prcHazDrsd = (cellInfo.data.prcHaz /cellInfo.data.prc) *100;

    this.afterprice(cellInfo);

  }
  ///
  onValueChangedDarsadM(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.darsadM = e.value;

    this.afterprice(cellInfo);
  

  }

  //بعد از بها کل همه چیز دیکه را محاسبه میکنید
  afterprice(cellInfo) {

    cellInfo.data.prc9 =
      Math.round(
      cellInfo.data.prc
      + cellInfo.data.prcHaz
      - cellInfo.data.prcTkh);

    cellInfo.data.prc10 = Math.trunc(cellInfo.data.prc9 * cellInfo.data.darsadM);
    cellInfo.data.prc11 = Math.round(cellInfo.data.prc10 + cellInfo.data.prc9);
  }


  //بعد از بها کل همه چیز دیکه را محاسبه میکنید
  afterprice707(cellInfo , zarib:number) {


    cellInfo.data.prc9 = Math.round(cellInfo.data.prc9 * zarib);

    let balance = cellInfo.data.prc9 - cellInfo.data.prc;

    if (balance >0) {

      cellInfo.data.prcHaz = balance;
      cellInfo.data.prcTkh = 0;
    }
   else if (balance < 0) {
      cellInfo.data.prcHaz = 0;
      cellInfo.data.prcTkh = (balance*-1);
    }
   else {

      cellInfo.data.prcHaz = 0;
      cellInfo.data.prcTkh = 0;
    }
 

    cellInfo.data.prc10 = Math.trunc(cellInfo.data.prc9 * cellInfo.data.darsadM);
    cellInfo.data.prc11 = Math.round(cellInfo.data.prc10 + cellInfo.data.prc9);
  }

  
  


  cellTempFi(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.fi = (e.value).toFixed(3);

  }
  cellTempPrc(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prc = Math.round( e.value);

  }

 
  onInputbes(e, cellInfo) {

    var newval = e.value;



  }

  onKeyDownGrid(e) {

  //  console.log('hhh: '+ e.event.shiftKey);
    this.mypresskey(e.event);

  }

 

  //searchedKalaid : number;
  //onSearchChangekala(searchValue: string): void {
  //  this.searchedKalaid = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  // // console.log(thenum);
  //    //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
  //  if (thenum.length >2)
  //    for (var r = 0; r < this.kalaCombolist._store._array.length; r++) {
  //      if (this.kalaCombolist._store._array[r].c == thenum) {

  //     // console.log(JSON.stringify(this.moientempedit.items[r]));
  //      //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //     // this.moientempedit.value = this.moientempedit.items[r].id;
  //        this.searchedKalaid = this.kalaCombolist._store._array[r].id;
  //      break;
  //    }

  //  }
  //}

 
  tw;
  onKeyDownkala(e) {
    //return;
    if ( e.event.key == 'Insert') {

     // this.editColumnkalaC.minSearchLength = 0;
    }
   
    //this.mypressMovekey(e.event);
  }
 
 

 
  onFocusInDis() {

    this.selecteddisindext = this.dataGrid.focusedRowIndex;
  }

  gDisRow_insert: number =-1;
  gDisColumn_insert: number = -1;;
 
  onKeyDowndis2(e) {


   
  }

  temp_Dischanged(e, cellInfo) {
    this.isfchangcall();
    
    const newValue = e.value;
     
  //  this.seleggggfhgtrthyrthyctedrow.dis = newValue;
    cellInfo.setValue(newValue);
    cellInfo.data.dis = e.value;


 
 

  }


  onClosedis2() {

    if (this.ColDisSelector === 2) {
      
      this.ColDisSelector = 1;
      this.discolumeditedit.editCellTemplate = 'editColumnDis';
    }
  }


  temp_DisonSelectionChanged2(e, cellInfo) {


    if (e.selectedItem == null)
      return;

    const newValue = e.selectedItem;
    if (newValue.text == null)
      return;
    //console.log(JSON.stringify(newValue));

    if (this.selectedrow.dis == null)
      this.selectedrow.dis = newValue.text;
    else
      this.selectedrow.dis += newValue.text;

    this.ColDisSelector = 1;



    const rowindex = this.dataGrid.focusedRowIndex;
    const columnindex = this.dataGrid.focusedColumnIndex;

    var navtocell = this.dataGrid.instance.getCellElement(rowindex, columnindex);
    //  var cellolv = this.dataGrid.instance.cellValue(rowindex, columnindex);
    // this.dataGrid.instance.cellValue(rowindex, columnindex, cellolv);
    this.dataGrid.instance.editCell(rowindex, columnindex);
    this.dataGrid.instance.focus(navtocell);
    this.discolumeditedit.editCellTemplate = 'editColumnDis';
  }

  onKeyDownmove(e) {

    this.mypressMovekey(e.event);
  }


  selnum: number=0;
  onKeyDownmoveFi(e, cellInfo) {


    if ((e.event as KeyboardEvent).key == '/') {
      this.selnum++;

      if (this.selnum == 5) 
        this.selnum = 1

      if (this.selnum ==1)
        cellInfo.data.fi = this.lastprc.prcLast;
      if (this.selnum == 2)
        cellInfo.data.fi = this.lastprc.prcTaf;
      if (this.selnum == 3)
        cellInfo.data.fi = this.lastprc.prcMi;
      if (this.selnum == 4)
        cellInfo.data.fi = 0;

      cellInfo.data.prc = Math.round( cellInfo.data.fi * cellInfo.data.amtB);
 
      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;

      var navtocell = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      this.dataGrid.instance.focus(navtocell);
    }



    this.mypressMovekey(e.event);
  }
  mypressMovekey(event: KeyboardEvent) {

    this.oldkey = event.key;
   
    //console.log(event.key);

    if (event.key == 'ArrowDown') {

      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;

      row++;
      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      // console.log('ArrowDown');

    }
    if (event.key == 'ArrowUp') {



      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;
      row--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
    }

    if (event.key == 'ArrowLeft') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 6)
        return;

      col++;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }
    if (event.key == 'ArrowRight') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 7)
        return;

      col--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }



  }


  mypresskey(event: KeyboardEvent) {
    this.oldkey = event.key;
    if (event.key == 'Enter') {
      this.pushnewrow(false);

      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;

      //بدون مبنا
      if (this.mymodle.mabnaAnDeed == 0) {
        //ستون درصد تخفیف دارد
        if (this.showtakhDarsad) {
          if (indexcolum == 0) {

            indexcolum = 1;
          }
          //kala
          else if (indexcolum == 1) {
            //2/برگشت
            if (event.shiftKey) {
              if (indexrow > 0) {
                indexrow--;
                indexcolum = 15;
              }
            }
            //2/رفت
            else {
              //this.mymodle.mabna مقتی مبنا داریم برابر یک و بدون مبنا صفر
              indexcolum = 3
            }
          }

          //نام کالا
          else if (indexcolum == 3) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 1;
            }
            //2/رفت
            else {
              indexcolum = 4;
            }
          }
          //تعداد
          else if (indexcolum == 4) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 3;
            }
            //2/رفت
            else {
              indexcolum = 5;
            }
          }
          //واحد
          else if (indexcolum == 5) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 4;
            }
            //2/رفت
            else {
              indexcolum = 6;
            }
          }
          //مبلغ واحد
          else if (indexcolum == 6) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 5;
            }
            //2/رفت
            else {
              indexcolum = 7;
            }
          }
          //کل
          else if (indexcolum == 7) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 6;
            }
            //2/رفت
            else {
              indexcolum = 8;
            }
          }
          else if (indexcolum == 8) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 7;
            }
            //2/رفت
            else {
              indexcolum = 9;
            }
          }
          else if (indexcolum == 9) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 8;
            }
            //2/رفت
            else {
              indexcolum = 10;
            }
          }
          else if (indexcolum == 10) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 9;
            }
            //2/رفت
            else {
              indexcolum = 12;
            }

          }
          else if (indexcolum == 12 || indexcolum == 13) {
            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 10;
            }
            //2/رفت
            else {
              indexcolum = 15;
            }
          }
          else if (indexcolum == 15) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 12;
            }
            //2/رفت
            else {
              this.repairlostKalaId();
              if (this.selectedrow.kalaId == null) {
                indexcolum = 1;
              }
              else {
                indexrow++;
                // this.focusedRowKey = this.dataGrid.instance.getKeyByRowIndex(indexrow);
                indexcolum = 1;
                //var navtocell = this.dataGrid.instance.getCellElement(indexrow, 3);
                //this.dataGrid.instance.focus(navtocell);
              }



            }

          }
          else if (indexcolum == 16) {


            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 14;

            }
            //2/رفت
            else {
              indexcolum = 17;
            }

          }
         
          else if (indexcolum == 17) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 15;
            }
            //2/رفت
            else {
              indexrow++;
              indexcolum = 17;
            }

          }
        }
        //بدون ستون درصد تخفیف
        else {
          if (indexcolum == 0) {

            indexcolum = 1;
          }
          //kala
          else if (indexcolum == 1) {
            //2/برگشت
            if (event.shiftKey) {
              if (indexrow > 0) {
                indexrow--;
                indexcolum = 13;
              }
            }
            //2/رفت
            else {
              //this.mymodle.mabna مقتی مبنا داریم برابر یک و بدون مبنا صفر
              indexcolum = 3
            }
          }

          //نام کالا
          else if (indexcolum == 3) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 1;
            }
            //2/رفت
            else {
              indexcolum = 4;
            }
          }
          //تعداد
          else if (indexcolum == 4) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 3;
            }
            //2/رفت
            else {
              indexcolum = 5;
            }
          }
          //واحد
          else if (indexcolum == 5) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 4;
            }
            //2/رفت
            else {
              indexcolum = 6;
            }
          }
          //مبلغ واحد
          else if (indexcolum == 6) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 5;
            }
            //2/رفت
            else {
              indexcolum = 7;
            }
          }
          //کل
          else if (indexcolum == 7) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 6;
            }
            //2/رفت
            else {
              indexcolum = 8;
            }
          }
          else if (indexcolum == 8) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 7;
            }
            //2/رفت
            else {
              indexcolum = 10;
            }
          }
          else if (indexcolum == 9) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 8;
            }
            //2/رفت
            else {
              indexcolum = 10;
            }
          }
          else if (indexcolum == 10) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 8;
            }
            //2/رفت
            else {
              indexcolum = 13;
            }

          }
          else if (indexcolum == 11 || indexcolum == 12) {
            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 10;
            }
            //2/رفت
            else {
              indexcolum = 13;
            }
          }
          else if (indexcolum == 13) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 10;
            }
            //2/رفت
            else {
              this.repairlostKalaId();
              if (this.selectedrow.kalaId == null) {
                indexcolum = 1;
              }
              else {
                indexrow++;
                // this.focusedRowKey = this.dataGrid.instance.getKeyByRowIndex(indexrow);
                indexcolum = 1;
                //var navtocell = this.dataGrid.instance.getCellElement(indexrow, 3);
                //this.dataGrid.instance.focus(navtocell);
              }



            }

          }
          else if (indexcolum == 14) {


            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 14;

            }
            //2/رفت
            else {
              indexcolum = 15;
            }

          }
          else if (indexcolum == 15) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 14;
            }
            //2/رفت
            else {
              indexcolum = 16;
            }

          }
          else if (indexcolum == 16) {

            //2/برگشت
            if (event.shiftKey) {
              indexcolum = 15;
            }
            //2/رفت
            else {
              indexrow++;
              indexcolum = 17;
            }

          }
        }





      }
      ////////////////////
      ///با مبنا
      //////////////////
      else  {

        if (indexcolum == 0 || indexcolum == 1 || indexcolum == 2 || indexcolum == 3 || indexcolum == 4 || indexcolum == 5) {
 
          //2/برگشت
          if (event.shiftKey) {

            if (indexrow > 0) {
              indexrow--;
              indexcolum = 14;
            }

          }
          //2/رفت
          else {
            //this.mymodle.mabna مقتی مبنا داریم برابر یک و بدون مبنا صفر
            indexcolum = 6
          }

        }

        //نام کالا
        else if (indexcolum == 6) {

          //2/برگشت
          if (event.shiftKey) {

            indexcolum = 5;
          }
          //2/رفت
          else {
            indexcolum = 7;
          }
        }
        //تعداد
        else if (indexcolum == 7) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 6;
          }
          //2/رفت
          else {
            indexcolum = 8;
          }
        }
        else if (indexcolum == 8) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 7;
          }
          //2/رفت
          else {
            indexcolum = 9;
          }
        }
        //واحد
        else if (indexcolum == 9) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 8;
          }
          //2/رفت
          else {
            indexcolum = 11;

          }

        }
        //مبلغ واحد
        else if (indexcolum == 10) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 9;
          }
          //2/رفت
          else {
            indexcolum = 11;
          }
        }
        //کل
        else if (indexcolum == 11) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 9;
          }
          //2/رفت
          else {
            indexcolum = 14;
          }

        }
        else if (indexcolum == 12 || indexcolum == 13) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 11;
          }
          //2/رفت
          else {
            indexcolum = 14;
          }

        }
       
        else if (indexcolum >= 14) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 11;
          }
          //2/رفت
          else {


              indexrow++;
           
              indexcolum = 5;


          }

        }
      }

     

      this.xx = indexcolum;
      this.yy = indexrow;
      var navtocell = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      this.enc++;



      this.dataGrid.instance.focus(navtocell);
      var cellolv = this.dataGrid.instance.cellValue(indexrow, indexcolum);
      // console.log('celkl value : ' +JSON.stringify(this.dataGrid.instance.cellValue(indexrow, indexcolum)));

      this.dataGrid.instance.cellValue(indexrow, indexcolum, cellolv);
      this.dataGrid.instance.editCell(indexrow, indexcolum);
      this.dataGrid.instance.focus(navtocell);




      this.oldrowindex = indexrow;
      this.oldcolumindex = indexcolum;
    }



  }





   setrowinfoBykala(moid : number){

     var selected;
    
     if (selected != null) {



    



     }
     else {

      

     }



}

 
  xx;
  yy;
  oldrowindex: number;
  oldkey: string;
  enc: number;
  oldcolumindex: number;
  testtt() {
    this.distempedit2.instance.open();

 

  }
  onFocusedCellChanged(e) {
   
    this.selnum = 0;
  }



  LasthesabDar = null;
  //بروز رسانی نفصیلی
  onFocusedCellChanging(e) {
    //ytryte5555


   let r= e.newRowIndex;
   let c= e.newColumnIndex;

    if (c >= 14) {

      const hesab  = e.rows[r].data.hesabDarId;
      //برای جلوگیری از تکرار بی مورد تابع
      //tafg_reflesh
      if (hesab != this.LasthesabDar) {

        this.LasthesabDar = hesab;


        if (this.LasthesabDar > 0)
          this.tafg_reflesh(this.LasthesabDar);
        else
          this.tafg_reflesh(null);
      }
     




    }



    console.log(e.data);
     
  }
  onEditingStart(e) {




  }
  mochanged(e) {

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

    this.mymodle.hesabC = h.codeSum; 
    this.mymodle.hesabN = h.name;

    //gfu68ui76i67
    if (e.previousValue != null) {
      this.mymodle.taf5Id = null;
      this.mymodle.taf5C = null;
      this.mymodle.taf5N = null;
      this.mymodle.taf6Id = null;
      this.mymodle.taf6C = null;
      this.mymodle.taf6N = null;
    }

    this.tafg_reflesh(e.value);


 
    //kklkl777
    this.filrowheasbs(false);
  }
 
  t5changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    if (h == null)
      return;

    this.mymodle.taf5C = h.code;
    this.mymodle.taf5N = h.name;
    this.filrowheasbs(false);
  }
  t6changed(e) {
    if (e.value == null)
      return;
    if (h == null)
      return;
     var h = e.component.option('selectedItem');
    this.mymodle.taf6C = h.code;
    this.mymodle.taf6N = h.name;

    this.filrowheasbs(false);

  }

  filrowheasbs(justnull: boolean) {

    for (var r of this.mymodle.rows) {
      if (r.kalaId == null)
        continue;

      if (justnull && r.taf4Id != null)
        continue;


      r.hesabId = this.mymodle.hesabId;
      r.hesabC = this.mymodle.hesabC;
      r.hesabN = this.mymodle.hesabN;

      r.taf4Id = this.mymodle.taf4Id;
      r.taf4C = this.mymodle.taf4C;
      r.taf4N = this.mymodle.taf4N;
   

      r.taf5Id = this.mymodle.taf5Id;
      r.taf5C = this.mymodle.taf5C;
      r.taf5N = this.mymodle.taf5N;


      r.taf6Id = this.mymodle.taf6Id;
      r.taf6C = this.mymodle.taf6C;
      r.taf6N = this.mymodle.taf6N;
    }


  }

  adjustWidth(e,w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {
 
   
         this.firsttimeopen = false;
   
    }
  }
 
  kaisopen: number;
  onFocuskalaCedit(e, item) {

 
     
    //if (this.editColumnkalaC.minSearchLength == 0)
    //  this.editColumnkalaC.minSearchLength = 2;

    if (this.kaisopen == null) this.kaisopen = 0;
     
    this.editColumnkalaC.instance.open();
    this.kaisopen++;
 
  }
 
 
 
 
  onFocusVahededit(e, item) {

    this.editColumnvahed.instance.open();
 

  }
  onFocusMab(e, item) {

    this.cellTempMab.instance.open();


  }
  onFocusDis2dit(e) {
  
    this.distempedit2.instance.open();
    //    e.instance.open();
  }

  testthis(e) {

    let dialogRef = this.dialog.open(DialogvalidComponent, {
      width: '250px',
      data: {}
    });

 
    dialogRef.afterClosed().subscribe(result => {

      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        console.log('dell');

      }

    });
 
  }

  onFocusInbes(e) {



  }


 
 




  onCellPrepared(e) {

    
 
  
   
    

  }

  onRowPrepared(e) {
   
    //  e.rowElement.style.backgroundColor = 'yellow';
    //  e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
    
  }


  myvalidationchek(condition: string): boolean {


   

    const verror = [];


    if (this.mymodle == null)
      verror.push('model is empty');
    else {

     

 
      if (!this.valllC.instance.validate().isValid) {
        verror.push('درخواست کننده خالی است');

      }
      if (!this.valan.instance.validate().isValid) {
        verror.push('طرف مقابل خالی است');

      }

      if (this.mymodle.code == null)
        verror.push(' شماره درخواست خالی است');


      if (this.kindpage < 0) {
        if (this.mymodle.setm == null || this.mymodle.setm < 1)
          verror.push(' روش تسویه خالی است');
      }
      else
          this.mymodle.setm = 0;

      let datevalid = this.myTimeService.isvalidday(this.mymodle.dateA_S);

 
     
      if (datevalid != null) {
        
        verror.push(datevalid);
      }

      var years = this.localst.yearnow_Class_get();

 
      var dajfouy = parseInt(this.mymodle.dateA_S.replace('/', '').replace('/', '').replace('/', '').toString()); 
 

      if (dajfouy > years.dateE) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }

      if (dajfouy < years.dateS) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }
 
    


 
      let c = 0;
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {

        if (this.mymodle.rows[_i].kalaId == null || this.mymodle.rows[_i].kalaId == -1) {
          this.mymodle.rows.splice(_i, 1);
          continue;
        }
         


          c++;

 
       
     
      }


      if (c == 0) {

          verror.push('هیچ ردیفی ثبت نشده است');

      }






    }
   // verror.push('ارور');

    if (verror.length == 0)
      return true;

    



    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

  
 

    return false;;
  }
 
 
  dodghativaz(v : number) {

    var n = [];
    n.push(this.mymodle.id);
    let vaz = this.AnSellService.getvaz(v);

    if (vaz == null)
      return;

    this.loadServ.showloading(this.loaddata,12);
     this.AnSellService.updatevaz(n, vaz.id).subscribe((res: number[]) => {

       res;
       this.loadServ.hideloading(this.loaddata,12);
      this.alertify.success(' وضعیت سند به ' + vaz.name + ' تغییر یافت ');
      this.mymodle.vaz = vaz.id;
 
         //رنگ وضعیت
       this.refreshvazColor(this.mymodle.vaz);
      // this.loadAnSell();

     }, error => {
         this.loadServ.hideloading(this.loaddata,12);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  showmessage(message: string) {


    this.messagetemp = { title: 'خطا', rows: [], valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
    this.messagetemp.rows = [message];
    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.messagetemp;
    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);
   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The message dialog was closed');
      return;
    });
    return;
 
  }

 
  onClickDeleterow() {
 
    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
   // console.log(JSON.stringify(rowkersselected));

    if (rowkersselected == null)
        rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);

    let afterindex = -1;



    if (rowkersselected != null)
    for (var i = 0; i < rowkersselected.length; i++) {

 
      for (var j = 0; j < this.mymodle.rows.length; j++) {

        if (rowkersselected[i] == this.mymodle.rows[j].id) {

         
          this.mymodle.rows.splice(j, 1);
          afterindex = j - 1;
          break;
        }
      }


    }
 
    //if (afterindex < this.mymodle.rows.length && afterindex >= 0)
    //  this.focusedRowKey = this.mymodle.rows[afterindex].id;
    //else if (this.mymodle.rows.length>0)
    //  this.focusedRowKey = this.mymodle.rows[0].id;

    this.refreshradifnumber();
    this.pushnewrow(false);

  }
  onClickinsertrow() {

    let AnSellRowempty = this.AnSellService.getAnSellRowEmpty();
    this.lastrowidtemplate--;
    AnSellRowempty.id = this.lastrowidtemplate;
  //  AnSellRowempty.id = this.getnextrowid();
    
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {
       // console.log(JSON.stringify(AnSellRowempty));


        this.mymodle.rows.splice(i, 0, AnSellRowempty);
        break;
      }

    }

    this.refreshradifnumber();


  }


  onClickcopyrow() {

    let data =[];

   
    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.clipboard_set( 'AnSellRow' , data);
    this.alertify.warning('رونوشت صورت گرفت');
    this.dataGrid.instance.clearSelection();

  }
  onClickcutrow() {
    let data = [];


    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));
    this.localst.clipboard_set('AnSellRow', data);
    this.alertify.warning('برش صورت گرفت');
    this.onClickDeleterow();
    this.dataGrid.instance.clearSelection();

  }
  onClickpaserow() {
    var data = this.localst.clipboard_get('AnSellRow');
    if (data == null) {

      this.alertify.warning('رونوشتی یافت نشد');
      return;
    }
     

  
    let nextid = this.getnextrowid();

    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {

        //ردیف مورد نظر را پیدا میکنیم

        //ردیف های کپی شده را جایگزاری میکنیم

        for (var k = 0; k < data.length; k++) {

          this.lastrowidtemplate--;
          data[k].id = this.lastrowidtemplate;
          this.mymodle.rows.splice(i, 0, data[k]);



         }
        this.alertify.warning('ردیف جایگذاری شد');
        break;
      }

    }

    this.refreshradifnumber();

  }
  onClickexchangRow() {

    

  }
  getnextrowid() : number {

    let nextid = -2000000011;
    for (var j = 0; j < this.mymodle.rows.length; j++) {
      if (this.mymodle.rows[j].id > nextid) {
        nextid = this.mymodle.rows[j].id;
      }
    }
    nextid++;
    return nextid;
  }
  refreshradifnumber() {

   
    let c = 0;
    for (var k = 0; k < this.mymodle.rows.length; k++) {
      c++;
      this.mymodle.rows[k].radif = c;

    }

  }

  nextAnSell() {
  

  }




  backAnSell() {



  }
  onClickdelete() {

   

 

    this.openDialog();

  


  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

    

      }

    }); 
  }

  dodelete() {


 
 
    this.loadServ.showloading(this.loaddata,16);
    this.AnSellService.delete(this.editId).subscribe((data) => {

      this.loadServ.hideloading(this.loaddata,16);
     
      this.alertify.success('رسید انبار  با موفقیت حذف شد');
       
               this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });

            this.ctrNthis(); 
    }, error => {
        this.loadServ.hideloading(this.loaddata,16);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 

  }








 

  refreshvazColor(vaz: number) {

   
    //ثبت نشده
    if (vaz == -1) {

      this.vazcolor = 'vaz-none';
      //  this.mainwarperCSS = 'UnfrzAnSell';
      this.isreadonly = false;

    }

    //موقت
    else if (vaz == 200) {
      this.vazcolor = 'vaz-yellow';
      // this.mainwarperCSS = 'UnfrzAnSell';
      this.isreadonly = false;
    }
    //قیمت گذاری شده
    else if (vaz == 300) {
      this.vazcolor = 'vaz-orange';
      // this.mainwarperCSS = 'UnfrzAnSell';
      this.isreadonly = false;
    }
    //تایید
    else if (vaz == 400) {

      this.vazcolor = 'vaz-green';
      // this.mainwarperCSS = 'frzAnSell opAnSell';
      this.isreadonly = true;
    }
      //سند حسابداری
    else if (vaz == 500) {
      this.vazcolor = 'vaz-blue';
     // this.isreadonly = false;
    }


   // this.mymodle._VazN = this.AnSellService.getvazName(this.mymodle.vaz);
 

  }
  onValueChangedGSharh(e){

  }

  onKeyDownDate(e) {


    if (e.event.key == 'Enter') {


      var cellolv = this.dataGrid.instance.cellValue(0, 1);

 
      this.dataGrid.instance.cellValue(0, 1, cellolv);
      this.dataGrid.instance.editCell(0, 1);
      this.dataGrid.instance.focus(cellolv);

    }


  }
  onClickmoveRow(step: number) {


    //حرکت روبه بالا
    if (step > 0) {

      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر اولین سطر انتخاب شده بود هیچ کاری نمیکنیم
          if (i == 0)
            return


          var tempmove = this.mymodle.rows[i-1];

          this.mymodle.rows[i - 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
     
        }
      }


    }
     //حرکت روبه پایین
    else if (step < 0) {

      let stopdown = this.mymodle.rows.length - 2;
      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر ردیف اخر به بعد بود هیچ کاری نمیکنیم
          if (i > stopdown)
            return


          var tempmove = this.mymodle.rows[i + 1];

          this.mymodle.rows[i + 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
          
        }
      }

    }
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;

    }

  }

  onClicrepedrows() {


 

    var foc = this.dataGrid.focusedRowKey;


    if (foc == null)
      return;


       var foradd = null;
  

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == foc) {

            foradd = JSON.parse(JSON.stringify(this.mymodle.rows[j]));
            //ای دی منفی به ردیف جدید میدهیم یعنی از نوع جدید در دیتا بیس است
            //موقع ذخیره تمام ای دی های منفی صفر میشوند و اینسرت
            this.lastrowidtemplate--;
            foradd.id = this.lastrowidtemplate;

            break;

          }


        }
     
    if (foradd == null)
      return;


    if (this.mymodle.rows == null)
      this.mymodle.rows = [];


    let indexforinsert = 0;

    if (this.mymodle.rows.length > 0)
      indexforinsert = this.mymodle.rows.length - 1;


    this.mymodle.rows.splice(indexforinsert, 0, foradd);

    this.lastrowidtemplate--;
   
 
  
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;
   

    }



  }
  onClickPrint() {

   
    if (this.loaddata.showed)
      return; 

    if (this.mymodle.id == -1) {
      this.alertify.warning('فاکتور ذخیره نشده است');
      return;
    }
     

    let op = { year: this.mymodle.years, codeS: this.mymodle.code, codeE: this.mymodle.code, dateS: 0, dateE: 0, dateE_S: null, dateS_S: null, kind: this.mymodle.kind};

    this.OnMyPrintEvent.emit(op);
  

  }
  refreshbottum() {

  
    if (this.loaddata.showed)
      return;

    this.refleshhesabvatafsili();
    this.loadPdlink(this.editId);
  //  this.tafg_reflesh(this.selectedrow.kalaId);
   // this.selecttafg(this.selectedrow.kalaId);
  }


  repairShitRow() {

 
   //// return;


   // if (this.firstShitedRowid == null)
   //   return;

   // if (this.pv == -1)
   //   return;



   // console.log('<<<shit>>>' + this.firstShitedRowid);

   // for (var r = 0; r < this.mymodle.rows.length; r++) {
      
   //   if (this.mymodle.rows[r].id == this.firstShitedRowid) {


       

   //     let hitem = null;
   //     for (var h = 0; h < this.hesablist.length; h++) {

   //       if (this.hesablist[h].id == this.pv) {

   //         hitem = this.hesablist[h];
   //       }

   //     }

   //     if (hitem != null && hitem.codeSum != this.selectedrowOld.hesabCode) {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = hitem.codeSum;
   //       this.mymodle.rows[r].hesabName = hitem.name;


   //       this.mymodle.rows[r].groupId = null;
   //       this.mymodle.rows[r].groupCode = null;
   //       this.mymodle.rows[r].groupName = null;

   //       this.mymodle.rows[r].kolId = null;
   //       this.mymodle.rows[r].kolCode = null;
   //       this.mymodle.rows[r].kolName = null;


   //       this.mymodle.rows[r].tafsili4Id = null;
   //       this.mymodle.rows[r].tafsili4Code = null;
   //       this.mymodle.rows[r].tafsili4Name = null;

   //       this.mymodle.rows[r].tafsili5Id = null;
   //       this.mymodle.rows[r].tafsili5Code = null;
   //       this.mymodle.rows[r].tafsili5Name = null;

   //       this.mymodle.rows[r].tafsili6Id = null;
   //       this.mymodle.rows[r].tafsili6Code = null;
   //       this.mymodle.rows[r].tafsili6Name = null;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;
   //     }
   //     else {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = this.selectedrowOld.hesabCode;
   //       this.mymodle.rows[r].hesabName = this.selectedrowOld.hesabName;


   //       this.mymodle.rows[r].groupId = this.selectedrowOld.groupId;
   //       this.mymodle.rows[r].groupCode = this.selectedrowOld.groupCode;
   //       this.mymodle.rows[r].groupName = this.selectedrowOld.groupName;

   //       this.mymodle.rows[r].kolId = this.selectedrowOld.kolId;
   //       this.mymodle.rows[r].kolCode = this.selectedrowOld.kolCode;
   //       this.mymodle.rows[r].kolName = this.selectedrowOld.kolName;


   //       this.mymodle.rows[r].tafsili4Id = this.selectedrowOld.tafsili4Id;
   //       this.mymodle.rows[r].tafsili4Code = this.selectedrowOld.tafsili4Code;
   //       this.mymodle.rows[r].tafsili4Name = this.selectedrowOld.tafsili4Name;

   //       this.mymodle.rows[r].tafsili5Id = this.selectedrowOld.tafsili5Id;
   //       this.mymodle.rows[r].tafsili5Code = this.selectedrowOld.tafsili5Code;
   //       this.mymodle.rows[r].tafsili5Name = this.selectedrowOld.tafsili5Name;

   //       this.mymodle.rows[r].tafsili6Id = this.selectedrowOld.tafsili6Id;
   //       this.mymodle.rows[r].tafsili6Code = this.selectedrowOld.tafsili6Code;
   //       this.mymodle.rows[r].tafsili6Name = this.selectedrowOld.tafsili6Name;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;


   //     }

       

   //      }

   //   }
   

  }


  repairlostKalaId() {
    

    for (var k of this.mymodle.rows) {

      if (k.kalaC != null && k.kalaId == null) {

        for (var r of this.kalaCombolist._store._array) {

          if (r.c == k.kalaC) {

            k.kalaId = r.id;
            break;
          }

        }
      }
    }

  } 

  lastindex = -1;
  
  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw3001 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }

 
  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw3001.length; i++) {
      this.localst.optioncli.gw3001[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw3001 = this.localst.optionClient_readDefault('gw3001');
  }
 
  isfchangcall() {
    if (!this.isfchanged) {
      this.isfchanged = true;
      this.onfchanged.emit(true);
    }
  }
  confirmfchang() {

  
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        return false;
      });
    }
    else return true
  }


 
  exportexcel() {

    //let excelmodle: AnSellRowExel[] =[] ;
   
    //for (var r = 0; r < this.mymodle.rows.length; r++) {

    //  let greeter = new AnSellRowExel(this.mymodle.rows[r]);


    //  excelmodle.push(greeter);



    //}


    //this.excelService.exportAsExcelFile(excelmodle, 'اسناد');


  }
  AnSellRowtemp: AnSellRow;
  asyncValidation(params) {

    //return new Promise((resolve) => {
    //  setTimeout(() => {
    //    resolve(1 === 1);
    //  }, 1000);
    //});
   
    if (this.isAutoCode) {
       return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
    }
    //hjhj66frf447676
    return new Promise((resolve, reject) => {
      this.AnSellService.validationCode(params.value, this.editId, this.mymodle.dateA_S, this.kindpage, this.localst.yearnow_get())
        .then((res: ApiRet) => {
          if (res.errors.length > 0) {

            this.alertify.error(res.errors.join('\n'));
          }


          resolve(res.value);
        })
        .catch(error => {
          console.error("خطا ارتباط با سرور", error);

        });
    });
   
  }

  onClickctrl() {

  
 

  }

  barname() {

    this.baropen = !this.baropen;

  }

 
   

  shiftsanad(s) {
    
    if (this.editId == -1) {

      this.alertify.warning('سندی وجود ندارد');
      return;
    }
 
    this.loadServ.showloading(this.loaddata, 14);
    this.AnSellService.getAnSellbyshift(this.editId, s).subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 14);
   
        if (res.warnings.length > 0) {
          this.alertify.warning(res.warnings[0]);

         }

         if(res.value != null) {

          this.editId = res.value;
          //از نو بخونه بیخیال بار سرور
          this.myoninit();
        }
         
   
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 14);
        console.log(error);
        this.alertify.error(error);
      }


    );


  }


  moisopen: number;
  onFocusmoienedit(e, item) {


    if (this.moientempedit.minSearchLength == 0)
      this.moientempedit.minSearchLength = 2;

    if (this.moisopen == null) this.moisopen = 0;

    this.moientempedit.instance.open();
    this.moisopen++;
 
  }

  onFocustaf4dit(e) {
    if (this.taf4tempedit.minSearchLength == 0)
      this.taf4tempedit.minSearchLength = 3;

    this.taf4tempedit.instance.open();
    // e.instance.open();
  }
  onFocustaf5dit(e) {
    if (this.taf5tempedit.minSearchLength == 0)
      this.taf5tempedit.minSearchLength = 3;

      //et5466666

    //this.tafg_reflesh();

    this.taf5tempedit.instance.open();
    // e.instance.open();
  }
  onFocustaf6dit(e) {
    if (this.taf6tempedit.minSearchLength == 0)
      this.taf6tempedit.minSearchLength = 3;

    this.taf6tempedit.instance.open();
    //    e.instance.open();
  }


  //searchedmoid: number;
  //onSearchChangeMo(searchValue: string): void {
  //  this.searchedmoid = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  //  // console.log(thenum);

  //  if (thenum.length > 2)
  //    for (var r = 0; r < this.hesablist.length; r++) {
  //      if (this.hesablist[r].codeSum == thenum) {

  //        // console.log(JSON.stringify(this.moientempedit.items[r]));
  //        //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //        // this.moientempedit.value = this.moientempedit.items[r].id;
  //        this.searchedmoid = this.hesablist[r].id;
  //        break;
  //      }

  //    }
  //}

  //searchedtaf4id: number;
  //onSearchChangeTaf4(searchValue: string): void {
  //  this.searchedtaf4id = -1;

  //  //if (searchValue == '   ') {
  //  //  this.taf4tempedit.minSearchLength = 0;
  //  //  this.taf4tempedit.value = '';

  //  //}

  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf4List.length; r++) {
  //      if (this.TafG.taf4List[r].code == thenum) {
  //        this.searchedtaf4id = this.TafG.taf4List[r].id;
  //        break;
  //      }

  //    }
  //}

  //searchedtaf5id: number;
  //onSearchChangeTaf5(searchValue: string): void {
  //  this.searchedtaf5id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf5List.length; r++) {
  //      if (this.TafG.taf5List[r].code == thenum) {
  //        this.searchedtaf5id = this.TafG.taf5List[r].id;
  //        break;
  //      }

  //    }
  //}

  //searchedtaf6id: number;
  //onSearchChangeTaf6(searchValue: string): void {
  //  this.searchedtaf6id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf6List.length; r++) {
  //      if (this.TafG.taf6List[r].code == thenum) {
  //        this.searchedtaf6id = this.TafG.taf6List[r].id;
  //        break;
  //      }

  //    }


  //}

  temp_taf4changed(e, cellInfo) {

    this.isfchangcall();

    var previousValue = e.previousValue;
    var newValue = e.value;
    //  console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    //دلمه کلیر زده شد
    if (e.value == null) {
      this.selectedrow.taf4DarId = null;
      this.selectedrow.taf4DarN = null;
      this.selectedrow.taf4DarC = null;
      cellInfo.setValue(null);
      return;
    }



    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }

    //  برای اراور خالی ماندن اولین سطر
    ////  if (newValue != null && newValue != undefined && newValue != undefined)
    //   this.searchedtaf4id = newValue;


    var selected = null;
    for (let i = 0; i < this.TafG.taf4List.length; i++) {
      if (this.TafG.taf4List[i].id == newValue) {
        selected = this.TafG.taf4List[i];
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }

    if (selected != null) {
      // cellInfo.data.tafsili4Id = selected.id;
      // cellInfo.data.tafsili4Name = selected.name; 
      this.selectedrow.taf4DarId = selected.id;
      this.selectedrow.taf4DarN = selected.name;
      this.selectedrow.taf4DarC = selected.code;


      cellInfo.data.tafsili4DarId = selected.id;
      cellInfo.data.tafsili4DarName = selected.name;
      //  cellInfo.data.tafsili4Code = selected.code;

      cellInfo.setValue(selected.code);

    }


    if (this.TafG.have5) {




    }
    else {


    }
    // cellInfo.setValue('667567');
  }


  temp_taf5changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;

    //676fgjgtf
    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    //دلمه کلیر زده شد
    if (e.value == null) {
      this.selectedrow.taf5DarId = null;
      this.selectedrow.taf5DarN = null;
      this.selectedrow.taf5DarC = null;
      cellInfo.setValue(null);
      return;
    }


    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //    this.searchedtaf5id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == newValue) {
        selected = this.TafG.taf5List[i];
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null) {

      this.selectedrow.taf5DarId = selected.id;
      this.selectedrow.taf5DarN = selected.name;
      this.selectedrow.taf5DarC = selected.code;
      cellInfo.setValue(selected.code);

    }

    if (this.TafG.have6) {




    }
    else {


    }

  }


  temp_taf6changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;
    // console.log('cellinfo ' + JSON.stringify(cellInfo.data));


    //دلمه کلیر زده شد
    if (e.value == null) {
      this.selectedrow.taf6DarId = null;
      this.selectedrow.taf6DarN = null;
      this.selectedrow.taf6DarC = null;
      cellInfo.setValue(null);
      return;
    }


    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;
    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //   this.searchedtaf6id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == newValue) {
        selected = this.TafG.taf6List[i];
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null) {
      //cellInfo.data.tafsili6Id = selected.id;
      //cellInfo.data.tafsili6Name = selected.name;
      this.selectedrow.taf6DarId = selected.id;
      this.selectedrow.taf6DarN = selected.name;
      this.selectedrow.taf6DarC = selected.code;
      cellInfo.setValue(selected.code);

    }



    if (this.TafG.have6) {




    }
    else {


    }
    //  this.dataGrid.instance.saveEditData();

  }
  onKeyDownmoien(e) {

    if (e.event.key == 'Insert') {

      this.moientempedit.minSearchLength = 0;
    }
  }
  onKeyDowntaf4(e) {

    if (e.event.key == 'Insert') {

      this.taf4tempedit.minSearchLength = 0;
    }
    //if (this.taf4tempedit.items.length == 1) {

    //  this.tw = this.taf4tempedit.items[0];
    //  this.taf4tempedit.selectedItem = this.tw;
    //  this.taf4tempedit.value = this.tw.id;
    //  console.log('select one');
    //}

  }
  onKeyDowntaf5(e) {
    if (e.event.key == 'Insert') {

      this.taf5tempedit.minSearchLength = 0;
    }
    //if (this.taf5tempedit.items.length == 1) {

    //  this.tw = this.taf5tempedit.items[0];
    //  this.taf5tempedit.selectedItem = this.tw;
    //  this.taf5tempedit.value = this.tw.id;
    //  console.log('select one');
    //}


  }
  onKeyDowntaf6(e) {

    if (e.event.key == 'Insert') {

      this.taf6tempedit.minSearchLength = 0;
    }
    //if (this.taf6tempedit.items.length == 1) {

    //  this.tw = this.taf6tempedit.items[0];
    //  this.taf6tempedit.selectedItem = this.tw;
    //  this.taf6tempedit.value = this.tw.id;
    //  console.log('select one');
    //}



  }

 

  ///???????????
  tafg_reflesh(hesabid: number) {
    console.log('hes : ' + hesabid);
    if (hesabid == null) {
      //hhjhjg776g

      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
  
     // this.devDataTaf4 = [];
      this.devDataTaf5 = [];
      this.devDataTaf6 = [];
      //this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
      //this.devDataTaf4 = new DataSource({
      //  store: this.TafG.taf4List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf5 = new DataSource({
      //  store: this.TafG.taf5List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf6 = new DataSource({
      //  store: this.TafG.taf6List,
      //  paginate: true,
      //  pageSize: 50
      //});
      return;


    }
    //?????????????????????????????? چرا
    //if (this.TafG.hesabId == null) {
    //  return;
    //}
    //if (this.TafG.hesabId == hesabid) {
    //  return;
    //}

    this.TafG = null;



    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListallCount; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];



        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب

        for (var j = 0; j < this.tafjustdaste.length; j++) {
          //خواندن تفصیلی 4
          // if (this.TafGListall[_i].taf4List.length == 0)
          for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

            if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی   5
          // if (this.TafGListall[_i].taf5List.length == 0)
          for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

            if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی 6
          // if (this.TafGListall[_i].taf6List.length == 0)
          for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

            if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
            }

          }


        }




        this.TafG = this.TafGListall[_i];
        //this.devDataTaf4 = new DataSource({
        //  store: this.TafG.taf4List,
        //  paginate: true,
        //  pageSize: 50
        //});
        this.devDataTaf5 = new DataSource({
          store: this.TafG.taf5List,
          paginate: true,
          pageSize: 50
        });
        this.devDataTaf6 = new DataSource({
          store: this.TafG.taf6List,
          paginate: true,
          pageSize: 50
        });






        this.TafGbala = JSON.parse(JSON.stringify(this.TafG));

        this.Taf5bala = new DataSource({
          store: this.TafGbala.taf5List,
          paginate: true,
          pageSize: 50
        });
        this.Taf6bala = new DataSource({
          store: this.TafGbala.taf6List,
          paginate: true,
          pageSize: 50
        });


        if (!this.TafG.have4)
          this.selectedrow.taf4C = "-----";
        if (!this.TafG.have5)
          this.selectedrow.taf5C = "-----";
        if (!this.TafG.have6)
          this.selectedrow.taf6C = "-----";
 

        return;
      }

    }


 



 


  }


  first_moienintilized: boolean;

  temp_moienintilized(e, cellInfo) {
    console.log(this.first_moienintilized);

    if (this.first_moienintilized == undefined) {
      this.first_moienintilized = true;
      console.log(this.first_moienintilized);
    }

  }

  setrowinfoBymoien(moid: number) {

    var selected;
    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == moid) {
        selected = this.hesablist[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected != null) {



      


      this.selectedrow.hesabId = selected.id;
      this.selectedrow.hesabN = selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
      this.selectedrow.hesabC = selected.codeSum;
      // cellInfo.data.hesabCode = selected.codeSum;
      //خود سلول را باید حتما از این تابع استفاده کنیم تا مقدار بگیرد و بشه تب را زد
      // cellInfo.setValue(selected.codeSum);




    }
    else {

     

      this.selectedrow.hesabId = null;
      this.selectedrow.hesabN = null;
      // this.selectedrow.setValue(null);

    }



  }

  pv: any;
  temp_moienchanged(e, cellInfo) {

    this.isfchangcall();


    this.pv = -1;
    // return;
    //  if (this.moieninchang)
    //   return;
    var selected;
    // this.moieninchang = true;
    //console.log('temp_moienchanged');


    //دلمه کلیر زده شد
    if (e.value == null) {
      this.selectedrow.hesabDarId = null;
      this.selectedrow.hesabDarN = null;
      this.selectedrow.hesabDarC = null;
      cellInfo.setValue(null);
      this.tafg_reflesh(null);
      return;
    }

    var previousValue = e.previousValue;
    var newValue = e.value;

    console.log('ttt' + previousValue + '-' + newValue);
    if (e.value == undefined) {
      console.log('temp_moienchanged undefinedundefinedundefinedundefinedundefinedundefinedundefined' + previousValue + '-' + newValue);
      console.log(this.selectedrow.id);
      this.pv = previousValue;
      console.log('this.pv  ' + this.pv);
      this.firstShitedRowid = this.selectedrow.id;

    }





    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected != null) {



      ////////////////////

 


      cellInfo.data.hesabDarId = selected.id;
      cellInfo.data.hesabDarN= selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkgfjkkkkkkkkkkkkkkk';
      cellInfo.data.hesabDarC = selected.codeSum;



    }
    else {
 

      cellInfo.data.hesabDarId = null;
      cellInfo.data.hesabDarN = null;
      cellInfo.data.hesabDarC = null;
      // this.selectedrow.setValue(null);

    }

    this.selectedrow.taf4DarId = null;
    this.selectedrow.taf4DarC = null;
    this.selectedrow.taf4DarN = null;

    this.selectedrow.taf5DarId = null;
    this.selectedrow.taf5DarC = null;
    this.selectedrow.taf5DarN = null;

    this.selectedrow.taf6DarId = null;
    this.selectedrow.taf6DarC = null;
    this.selectedrow.taf6DarN = null;


    if (this.dataGrid.focusedRowIndex == 0) {

    }

    if (selected != null)
      this.tafg_reflesh(selected.id);
    else
      this.tafg_reflesh(null);


    //  console.log('moien changed');
    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    this.dataGrid.instance.focus();
    //this.pushnewrow(); 

    // this.dataGrid.instance.saveEditData();
    //this.mypresskey('Enter');
    //  this.moieninchang = false;

  }

          //فیلتری برای جلو گیری از پاک شدن جدول هنگامی که ویرایش را باز میکنیم
          //چون هنگام لود مدل رادیون باکس عوض میشه جلو این رویداد را برای بار اول میگیریم
  flo: boolean = true;
  mabRadChanged(e) {

  
    //برای بار اول این تابع را غیر فعال میکنیم تا ردیف ها در حالت ویرایش دارای مبنا پاک نشه
    if (this.flo && this.isnew == false) {
      this.flo = false;
      return;
    }
    this.flo = false;

    if (!this.modelloaded)
      return;

    if (e.previousValue != null) {
      this.mymodle.rows = [];
    }
     
   
    //اگر مبنا داشت
    //کد نوع صفحه مبنا این مقدار است
    if (e.value != 0) {
      if (
        this.mymodle.taf4Id == null 
        || this.mymodle.anbarId == null || this.mymodle.dateA_S == null) {
        this.alertify.message('ابتدا انبار و طرف مقابل را انتخاب کنید');
        return;
      }
      else {
        this.loadMabna();
      }
    }
    else {
  
    }
     
   
    this.pushnewrow(false);

   

  }
  flo2: boolean = true;
  mabRadAnDeedChanged(e) {


    ////برای بار اول این تابع را غیر فعال میکنیم تا ردیف ها در حالت ویرایش دارای مبنا پاک نشه
    //if (this.flo2 && this.isnew == false) {
    //  this.flo2 = false;
    //  return;
    //}
    //this.flo2 = false;

    //if (!this.modelloaded)
    //  return;

    //if (e.previousValue != null) {
    //  this.mymodle.rows = [];
    //}


    ////اگر مبنا داشت
    ////کد نوع صفحه مبنا این مقدار است
    //if (e.value != 0) {
    //  if (
    //    this.mymodle.taf4Id == null
    //    || this.mymodle.anbarId == null || this.mymodle.dateA_S == null) {
    //    this.alertify.message('ابتدا انبار و طرف مقابل را انتخاب کنید');
    //    return;
    //  } 
    //  else {
    //    this.loadMabnaAnDeed();
    //  }
    //}
    //else {

    //}


    //this.pushnewrow(false);



  }

  //لود کردن مبنا از همین جدول فروش
  loadMabna() {
    //this.mymodle.mabna == 0 مبنا نداشت
        //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    if (
      this.mymodle.mabna == 0 ||
      this.mymodle.anbarId == null||
     this.mymodle.dateA_S == null ||
      this.mymodle.taf4Id == null ) {
      this.mablist = [];
      return;
    } 
    ///ghfhghr56yu7546
    let anbarid = this.mymodle.anbarId;

    //درخواست کالا انبار ندارد و مبنا بدون انبار را انتخاب میکنیم
    //-1= بدون انبار ،نه همه انبارها
    if (this.mymodle.mabna == -11)
      anbarid = -1;


    let year = this.mymodle.years;
    if (this.mymodle.isMabnaAllY)
      year = -1;

    this.loadServ.showloading(this.loaddata, 15);
    //mymodle.mabna = هر نوع سند انبار یک مبنا عطف دارد که با انتخاب رادیون باکس ای دی نوع ان انتخاب میشود
        //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    this.AnSellService.getMabnaForRow(anbarid, this.mymodle.taf4Id, this.mymodle.dateA_S, year, this.mymodle.mabna).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 15);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
       // this.alertify.success(res.success[0]);


        this.mablist = res.data;
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 15);
      this.alertify.error(error);
    });
 

  }
  //لود کردن مبنا از جدول انبار
  loadMabnaAnDeed() {
    //this.mymodle.mabna == 0 مبنا نداشت
    
    if (
      this.mymodle.mabnaAnDeed == 0 ||
      this.mymodle.anbarId == null ||
      this.mymodle.dateA_S == null ||
      this.mymodle.taf4Id == null) {
      this.mablistAnDeed = [];
      return;
    }
    ///ghfhghr56yu7546
    let anbarid = this.mymodle.anbarId;

    //درخواست کالا انبار ندارد و مبنا بدون انبار را انتخاب میکنیم
    //-1= بدون انبار ،نه همه انبارها
    if (this.mymodle.mabna == -11)
      anbarid = -1;
     


    this.loadServ.showloading(this.loaddata, 15);
    //mymodle.mabna = هر نوع سند انبار یک مبنا عطف دارد که با انتخاب رادیون باکس ای دی نوع ان انتخاب میشود
    //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    this.anDeedService.getMabnaForRow_ForAnSell(anbarid, this.mymodle.taf4Id, this.mymodle.dateA_S, this.mymodle.years, this.mymodle.mabnaAnDeed).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 15);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        // this.alertify.success(res.success[0]);

        ///ytuiytiyti55
        this.mablistAnDeed = res.data;
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 15);
      this.alertify.error(error);
    });


  }

  gridBox_displayExpr(item) {
    return item && item.codeSum + '-'+ item.name;
  }

  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }
 


  onAcceptmabna(e) {


   // console.log(e);

    this.mabdrpdown.instance.close();
  
    let exist = false;
      for (var r of this.mymodle.rows) {
//اگر در دیتابیس ثبت شده بود ان را به موجودی قابل قبول اضافه میکنیم
        //در حالت ویرایش
        exist = false;
        if (r.id > 0) {

          for (var v of this.mablist) {
            if (v.id == r.id) {
              v.amtB += r.amtB;
              exist = true;
              break
            }
          }

          if (!exist) {
            var a=  this.AnSellService.getAnSellRowForMabnaEmpty();

            a.id = r.anSellRowId;
            a.kalaId = r.kalaId;
            a.kalaC = r.kalaC;
            a.kalaN = r.kalaN;

            a.vahId = r.vahId;
            a.vahN = r.vahN;
            a.vahBId = r.vahBId;
            a.vahBN = r.vahBN;
            a.zMulti = r.zMulti;
      
            a.anSellCode = r.anSellRowCode;
            //a.amtmB = r.amtFaB;
         
            this.mablist.push(a);

          }
        }

      }


    this.mymodle.rows = [];
    let i = -1000;
    let j = 0;

    var selects = this.griddown.selectedRowKeys;
    
       
      for (var u of selects) {

        if (u == undefined)
          continue;

          let a = this.AnSellService.getAnSellRowEmpty();
        i--;
        j++;
          a.id = i;
          a.radif = j;
       
        //  var h = e.component.option('selectedItem') as AnSellRowForMabna;
          //fghhgf65
          a.kalaId = u.kalaId;
          a.kalaC = u.kalaC;
          a.kalaN = u.kalaN;
 
           a.amtB = u.amtmB;
          a.vahId = u.vahId;
          a.vahN = u.vahN;
          a.vahBId = u.vahBId;
          a.vahBN = u.vahBN;
        a.zMulti = u.zMulti;

          a.anSellRowId = u.id;
          a.anSellRowCode = u.anSellCode;
         // a.anSellCode = u.anSellCode;
          a.darsadM = this.maliatDfault;
          //مقدار باقی مانده رسید نشده
          //واحد ثانویه را قرار میدیم حال همان واحد اصلی باشد یا فرعی
          a._maxAmt = u.amtmB;

        a.fi = u.fi;
        a.prc = u.prc;
        a.prcTkh = u.prcTkh;
        a.prcHaz = u.prcHaz;
        a.prc9 = u.prc9;
        a.prc10 = u.prc10;
        a.prc11 = u.prc11;
        a.darsadM = u.darsadM;


          this.setbarname(u.AnSellId);
          this.mymodle.rows.push(a);

    }
    this.pushnewrow(false);

  }

  onAcceptmabnaAnDeed(e) {


    // console.log(e);

    this.mabdrpdownAnDeed.instance.close();

    let exist = false;
    for (var r of this.mymodle.rows) {
      //اگر در دیتابیس ثبت شده بود ان را به موجودی قابل قبول اضافه میکنیم
      //در حالت ویرایش
      exist = false;
      if (r.id > 0) {

        for (var v of this.mablistAnDeed) {
          if (v.id == r.id) {
            v.amtB += r.amtB;
            exist = true;
            break
          }
        }

        if (!exist) {
          var a = this.anDeedService.getAnDeedRowForMabnaEmpty();

          a.id = r.anSellRowId;
          a.kalaId = r.kalaId;
          a.kalaC = r.kalaC;
          a.kalaN = r.kalaN;

          a.vahId = r.vahId;
          a.vahN = r.vahN;
          a.vahBId = r.vahBId;
          a.vahBN = r.vahBN;
          a.zMulti = r.zMulti;
       
          //a.AnSellCode = r.AnSellCode;
          //a.amtmB = r.amtFaB;

          this.mablistAnDeed.push(a);

        }
      }

    }


    this.mymodle.rows = [];
    let i = -1000;
    let j = 0;

    var selects = this.griddownAnDeed.selectedRowKeys;


    for (var u of selects) {

      if (u == undefined)
        continue;

      let a = this.AnSellService.getAnSellRowEmpty();
      i--;
      j++;
      a.id = i;
      a.radif = j;

      //  var h = e.component.option('selectedItem') as AnSellRowForMabna;
      //fghhgf65
      a.kalaId = u.kalaId;
      a.kalaC = u.kalaC;
      a.kalaN = u.kalaN;

      a.amt = 0;
      a.amtB = u.amtmB;

      a.vahId = u.vahId;
      a.vahN = u.vahN;
      a.vahBId = u.vahBId;
      a.vahBN = u.vahBN;
      a.zMulti = u.zMulti;
      //ای دی مبنا انبار
      a.anDeedRowId = u.id;
      //??????????
      a.anDeedCode = u.anDeedCode;

      //مقدار باقی مانده رسید نشده
      //واحد ثانویه را قرار میدیم حال همان واحد اصلی باشد یا فرعی
      a._maxAmt = u.amtmB;



      //????????????
     // this.setbarname(u.AnSellId);
      this.mymodle.rows.push(a);

    }
    this.pushnewrow(false);

  }

  adjustWidth2(e, w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {


      this.firsttimeopen = false;

    }
   // this.selestedkeys = [];
    var a = [];
    for (var r of this.mymodle.rows) {
   
      a.push(r.anSellRowId);
    //  this.selestedkeys.push(r.AnSellRowId);
     

    }
    this.griddown.instance.selectRows(a,true);
//var navcel = this.griddown.instance.selectRows(0, 0);
  

  }
  adjustWidth2AnDeed(e, w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {


      this.firsttimeopen = false;

    }
    // this.selestedkeys = [];
    var a = [];
    for (var r of this.mymodle.rows) {

      a.push(r.anSellRowId);
      //  this.selestedkeys.push(r.AnSellRowId);


    }
    this.griddownAnDeed.instance.selectRows(a, true);
    //var navcel = this.griddown.instance.selectRows(0, 0);


  }

  afsclick() {

    this.afsopen = !this.afsopen;

    if (this.editId == -1) {
      this.alertify.warning('برای مشاهده قیمت عوامل افزاینده و کاهنده سند را ذخیره کنید');

    }
    else {
      this.calcclick();
    }


  }

  calcclick() {

    if (this.myvalidationchek('') == false) {
      this.pushnewrow(false);
      return;
    }
    this.mymodle.kind = this.kindpage;

    if (this.editId == -1) {
      this.alertify.message("ابتدا سند را ذخیره کنید");
      return;
    }
    //this.pushnewrow(false);
    //gfddfg4545htr
    this.loadServ.showloading(this.loaddata, 12);
    this.AnSellService.calcPrc(this.mymodle).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 12);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.success[0]);
        this.mymodle = res.data;

        this.refreshbottum();

        this.filvahedlis();
        this.pushnewrow(false);
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 12);
      this.alertify.error(error);
    });
  }


  Afschanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    var h = e.component.option('selectedItem');


    cellInfo.data.kindId = h.id;
    cellInfo.data.kindN = h.name;

    if (h.id == 0) {
      cellInfo.data._IsDar = false;
     // cellInfo.data._IsPrc = false;

      cellInfo.data.darsad = 0;
      cellInfo.data.prc = 0;
    }
    else if (h.id == 1) {
      cellInfo.data._IsDar = true;
     // cellInfo.data._IsPrc = false;
      cellInfo.data.prc = 0;
    }
    else {
      cellInfo.data._IsDar = false;
    //  cellInfo.data._IsPrc = true;
      cellInfo.data.darsad = 0;
    }




    //حمل
    if (h.id != 0 && cellInfo.data.titC == 103 && cellInfo.data.prc == 0) {

      cellInfo.data.prc = this.mymodle.barname.cost;

    }

  }



 
  temp_hesabchanged(e, cellInfo) {
 
 
    var selected;
 
    var newValue = e.value;

    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        break;
      }

    }
    if (selected != null) {

      cellInfo.data.hesabId = selected.id;
      cellInfo.data.hesabN = selected.codeSum + ' - '+ selected.name;
 
    }
 

 
    

 
  }


  Sanadids: number[];
  onClicksanadjoiner(e) {


    if (this.editId < 1) {
      this.alertify.warning('ابتدا سند انبار را ذخیره کنید');
      return;
    }

    this.sanadopen = !this.sanadopen;
    this.sanadkind = e;
    this.Sanadids = [];
    this.Sanadids.push(this.editId);




  }
  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }
  OnChangeSanad(e) {

    this.myoninit();

    
    this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });

  }

  closesanad() {
    this.sanadopen = false;
    this.sanadkind = 0;
  }

  onValueChangededitdafss(e, cellInfo) {


    cellInfo.data.prc = e.value;


  }


  onClickAmel(e) {




    let rows = [];
    for (var r of this.mymodle.rows) {
      if (r.kalaId == null)
        continue;

      rows.push({
        id: r.id,
        kalaId: r.kalaId,
        hesabId: null,
        hesabC: null,
        hesabN: null,

        taf5Id: null,
        taf5C: null,
        taf5N: null,

        taf6Id: null,
        taf6C: null,
        taf6N: null,
      })

    }

    let data = {
      kind: e,
      tableN: 'ansell',
      anKind: this.mymodle.kind,
      groupKind: true,
      taf4Id: this.mymodle.taf4Id,
      rows: rows

    };


    this.loadServ.showloading(this.loaddata, 13);
    this.AnAmelService.doAmelOne(data).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        ///34gr4e5y4eyh4e

        //if (res.data.rows.length > 0) {
        //  this.mymodle.hesabId = res.data.rows[0].hesabId;
        //  this.mymodle.hesabC = res.data.rows[0].hesabC;
        //  this.mymodle.hesabN = res.data.rows[0].hesabN;

        //  this.mymodle.taf5Id = res.data.rows[0].taf5Id;
        //  this.mymodle.taf5C = res.data.rows[0].taf5C;
        //  this.mymodle.taf5N = res.data.rows[0].taf5N;

        //  this.mymodle.taf6Id = res.data.rows[0].taf6Id;
        //  this.mymodle.taf6C = res.data.rows[0].taf6C;
        //  this.mymodle.taf6N = res.data.rows[0].taf6N;
        //}


        if (res.data.rows[0] != null && res.data.rows[0] != undefined) {

          var r = res.data.rows[0];
          this.mymodle.hesabId = r.hesabId;
          this.mymodle.hesabC = r.hesabC;
          this.mymodle.hesabN = r.hesabN;
          this.mymodle.taf5Id = r.taf5Id;
          this.mymodle.taf5C = r.taf5C;
          this.mymodle.taf5N = r.taf5N;
          this.mymodle.taf6Id = r.taf6Id;
          this.mymodle.taf6C = r.taf6C;
          this.mymodle.taf6N = r.taf6N;
        }
        else {

          this.mymodle.hesabId = null;
          this.mymodle.hesabC = null;
          this.mymodle.hesabN = null;

          this.mymodle.taf5Id = null;
          this.mymodle.taf5C = null;
          this.mymodle.taf5N = null;

          this.mymodle.taf6Id = null;
          this.mymodle.taf6C = null;
          this.mymodle.taf6N = null;

        }
  

        for (var r of res.data.rows) {

          for (var u of this.mymodle.rows) {
            if (u.id == r.id) {
              u.hesabId = r.hesabId;
              u.hesabC = r.hesabC;
              u.hesabN = r.hesabN;
              u.taf5Id = r.taf5Id;
              u.taf5C = r.taf5C;
              u.taf5N = r.taf5N;
              u.taf6Id = r.taf6Id;
              u.taf6C = r.taf6C;
              u.taf6N = r.taf6N;

              u.hesabDarId = r.hesabDarId;
              u.hesabDarC = r.hesabDarC;
              u.hesabDarN = r.hesabDarN;


              break
            }
          }
        }



        this.alertify.message(res.mes);
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });
  }
  fillAfslhesab() {

    this.anSellHesService.getFirst_AnSellHes()

    this.loadServ.showloading(this.loaddata, 18);
    this.anSellHesService.getFirst_AnSellHes().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 18);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {

        var hr = res.data as AnSellHes;



          for (var f of this.mymodle.afs) {

            if (f.titC ==206) {
              f.hesabId=   hr.hb206Id;
              f.hesabN = hr.hb206C + ' ' + hr.hb206N;
            }
            else if (f.titC == 207) {
              f.hesabId = hr.hb207Id;
              f.hesabN = hr.hb207C + ' ' + hr.hb207N;

            }
            else if (f.titC == 208) {
              f.hesabId = hr.hb208Id;
              f.hesabN = hr.hb208C + ' ' + hr.hb208N;
            }
           else if (f.titC == 209) {
              f.hesabId = hr.hb209Id;
              f.hesabN = hr.hb209C + ' ' + hr.hb209N;
            }
          }
        



        

      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 18);
      this.alertify.error(error);
    });
  }




  bindLock() {
    this.lockHeader = false;
    this.lockAll = false;
    this.lockAllCSS = '';
  

    if (this.mymodle.vaz <= 200) {
      this.lockAll = false;
      this.lockAllCSS = '';
    }

    if (this.mymodle.vaz == 500) {
      this.lockAll = true;
      this.lockAllCSS = 'mydisble';
      this.lockHeader = true;
    }

    if (this.mymodle.taxVaz == 1) {
      this.lockAll = true;
      this.lockAllCSS = 'mydisble';
      this.lockHeader = true;
    }
 
  }

  onPrepared(e) {
    // this.load();

    this.disbRadion();
    //this.dataGrid.dataSource = this.modlelist;
  }
 

  reloadGrid() {

    let index = this.dataGrid.focusedRowIndex;
    if (index == -1) {
      index = 0;
    }
    if (index > -1) {

      var yyy = this.dataGrid.instance.getCellElement(index + 2, 0);
      if (yyy == undefined)
        yyy = this.dataGrid.instance.getCellElement(index, 0);
      var y = this.dataGrid.instance.getScrollable();
 
      if (yyy != undefined)
        y.scrollToElement(yyy);

      this.dataGrid.instance.refresh();
    }

  }

  opn: boolean = false;
  onansell707changed(e) {


    if (this.editId > 0)
      return;



    //if (e.previousValue == null && !this.opn) {
    //  this.opn = true;
    //  return;
    //}
     
    if (e.value == null) {

      this.mymodle.rows = [];
      this.mymodle.anbarId = null;
      this.mymodle.taf4Id = null;
      this.mymodle.dis = null;
      this.pushnewrow(true);
      return;
    }

    this.mymodle.mabna = -706;

    //var h = e.component.option('selectedItem');
    //if (h == null)
    //  return;

 //yu568u756

    this.loadServ.showloading(this.loaddata, 4);
    this.AnSellService.GetForEbtal(e.value).subscribe(

      (res) => {
        this.loadServ.hideloading(this.loaddata, 4);







        this.isreadonly = true;
        console.log('create mode');
        //  this.mymodle = this.AnSellService.getAnSellEmpty(this.kindpage);
       
        this.mymodle = res.data;
       
        this.mymodle.kind = this.kindpage;
        this.mymodle.years = this.localst.yearnow_get();
        this.refreshvazColor(this.mymodle.vaz);
        this.mymodle.dateA_S = this.localst.dateEmruz.dateString;

        this.mymodle.uSa = this.localst.usernow_get().username;
        this.mymodle.uSaF = this.localst.usernow_get().name;
        this.refleshAnSellcods(true);
        this.pushnewrow(true);
        this.isnew = true;
        this.setCode();
        this.modelloaded = true;


        if (this.editId > 0) {
          this.mymodle.id = this.editId;
          this.mymodle.barname = this.mymodle_old.barname;
          this.mymodle.afs = this.mymodle_old.afs;
        }
         

        ////اگر سیستم مستقیم بود
        //if (this.localst.GetRegistryByPath('App3-AnSell-[Direct]').vBool) {
        //  this.mymodle.mabnaAnDeed = 0;
        //  this.radDisable = false;
        //  this.mymodle.mabna = 0;
        //}
        //else {
        //  this.mymodle.mabnaAnDeed = -106;
        //  this.radDisable = true;
        //  this.mymodle.mabna = 0;
        //}


        this.fillAfslhesab();










      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log(error);
        this.alertify.error(error);
      }


    );


  }

  onValueChangedPrcHazDrsd(e, cellInfo) {


 
    cellInfo.data.prcHazDrsd = e.value;
    cellInfo.data.prcHaz = Math.trunc(cellInfo.data.prc * cellInfo.data.prcHazDrsd) /100;
    
    this.afterprice(cellInfo);
    this.calcallTakhAll();
  }

  onValueChangedPrcTkhDrsd(e, cellInfo) {

    cellInfo.data.prcTkhDrsd = e.value;
    cellInfo.data.prcTkh = Math.trunc(cellInfo.data.prc * cellInfo.data.prcTkhDrsd) /100;
    this.afterprice(cellInfo);
    this.calcallTakhAll();
}
 
   displayExprtaf(item) {
     return item && item.code + ' / ' + item.name + ' / ' + item.addCode;
  }

  bgColorCSS: string = '';
  onValueChangedPrcTkhAll(e) {


    if (e.value == null || e.value == undefined)
      return;

    let prcTkh = 0;
    for (var r of this.mymodle.rows) {
      prcTkh += r.prcTkh;
    }
 
    if (Math.round(e.value) != Math.round(prcTkh)) {

      this.bgColorCSS = 'mybgColor';
    }
    else {
      this.bgColorCSS = '';
    }

  }


  calcallTakhAll() {

    let prcTkh = 0;
    let prc = 0;
    for (var r of this.mymodle.rows) {

      prc += r.prc;
      prcTkh += r.prcTkh;
    }
  

 
    if (this.mymodle.prc > 0)
      this.mymodle.prcTkhDrsdAll = (prcTkh / prc) * 100;



 
 

  }


  onValueChangedPrcTkhDrsdAll(e) {




 

    //ytu68768689659
    this.mymodle.prcTkhDrsdAll = e.value;


    

    var rowsf = this.mymodle.rows.filter(x => x.kalaId > 0);
    let rowsLength = rowsf.length;
    let prcSum = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSum += rowsf[i].prc;
    }
    this.mymodle.prcTkhAll = Math.trunc(prcSum * this.mymodle.prcTkhDrsdAll) / 100;


  }


  onClickTashimTkh(kind) {

    //68568956986
   // var y = this.dataGrid.instance.row(1);

    let PrcAllTkh = this.mymodle.prcTkhAll;

 

    var rowsf = this.mymodle.rows.filter(x => x.kalaId > 0);
    let rowsLength = rowsf.length;

 


    let prcSum = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSum += rowsf[i].prc;
    }

 


    if (PrcAllTkh > prcSum) {
      this.alertify.warning('مبلغ تخفیف نمیتواند بزرگ تر مبلغ فاکتور باشد');
    
      this.mymodle.prcTkhAll = prcSum;
      PrcAllTkh = prcSum;
    }

    let rowValus = [];
    for (var i = 0; i < rowsLength; i++) {

      if (prcSum >0)
        rowValus.push(Math.trunc((rowsf[i].prc / prcSum) * PrcAllTkh));
      else
      rowValus.push(0);
    }


    let prcSumResult = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSumResult += rowValus[i];
    }


    let mandeh = PrcAllTkh - prcSumResult;
     
    if (mandeh > 0 && rowsLength >0) {

      rowValus[0] += mandeh;
    }


    for (var i = 0; i < rowsLength; i++) {



      var cellInfo = { data: null };

      cellInfo.data = rowsf[i];

      var e = { value: rowValus[i] };


      this.onValueChangedPrcTkh(e, cellInfo);

    

    }
    this.calcallTakhAll();
    this.dataGrid.instance.refresh();



     

    let prcTkh = 0;
    for (var r of this.mymodle.rows) {
      prcTkh += r.prcTkh;
    }
    if (Math.round(prcTkh) != Math.round( PrcAllTkh)) {

      this.bgColorCSS = 'mybgColor';
    }
    else {
      this.bgColorCSS = '';
    }
  }


  onShowPdlinkAnalyze() {


    let op = new PdLinkBoxOp();
    op.dateA_S = this.mymodle.dateA_S;
    op.dateA = this.mymodle.dateA;
    var y = this.localst.yearnow_Class_get();
      op.dateB_S = y.dateE_s;
    op.dateB = y.dateE;
    op.tafId = this.mymodle.taf4Id;

    //let data = JSON.parse(JSON.stringify(this.pdLinkBoxRow));

    this.onShowPdlinkListEvent.emit(op);


  }
  showPdLink: boolean = false;

  autoPdLinkData: AutoPdLink;

  onAddPdLink() {

    if (this.editId > 0) {

      this.autoPdLinkData = new AutoPdLink();
      this.autoPdLinkData.tableName = TableName.AnSell;
      this.autoPdLinkData.tableId = this.editId;
      this.autoPdLinkData.tafId = this.mymodle.taf4Id;
      this.autoPdLinkData.data_S = this.mymodle.dateA_S;

      this.showPdLink = true;
    }
    else {
      this.alertify.warning('سند ذخیره نشده');
    }


 
  }
 
  OnMySavePdPay(){
    this.loadPdlink(this.editId);
  }



  //gridBox_displayExpr(item) {
  //  return item && item.codeSum + '-' + item.name;
  //}
}
