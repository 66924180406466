import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';

import { PhotoArch } from '../_models/photoArch';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Arch } from '../_models/arch';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyArch: Arch = {

  Id: -1,
  Name: '',
  Dis: '',
  KnownAs: '',
  Created: new Date,
  Createdint: -1,
  LastActive: new Date,
  UserId: -1,
  UserN: '',
 
  ArchPersonId: -1,
  ArchPersonName: '',
  ArchPersonMelli: '',
  ArchPersonTell: '',
   
  ArchKindId: -1,
  ArchKindName: '',
 
  datesabt: -1,
  datemadrak :-1
};
@Injectable({
  providedIn: 'root'
})
export class ArchService {

  
    getArchEmpty(): Arch {

      return EmptyArch;


    }
  baseUrl = environment.apiUrl;
 
  constructor(private http: HttpClient) {}

  getArchs(): Observable<Arch[]> {

   
    console.log('get : ' + this.baseUrl + 'Arch/GetArchs');
    return this.http.get<Arch[]>(this.baseUrl + 'Arch/GetArchs');
  }

  getArchs_P(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Arch[]>> {

    const paginatedResult: PaginatedResult<Arch[]> = new PaginatedResult<Arch[]>();
    let params = new HttpParams();
 
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    //if (userParams != null) {
    //  params = params.append('minAge', userParams.minAge);
    //  params = params.append('maxAge', userParams.maxAge);
    //  params = params.append('gender', userParams.gender);
    //  params = params.append('orderBy', userParams.orderBy);
    //}

    return this.http.get<Arch[]>(this.baseUrl + 'Arch/GetArchsP', { observe: 'response', params })
      .pipe(

        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
      );
     


  }


  getArchPhotos(): Observable<PhotoArch[]> {

    console.log(this.baseUrl + 'ArchPhoto/getArchPhotos');
    return this.http.get<PhotoArch[]>(this.baseUrl + 'ArchPhoto/getArchPhotos');

  }
  getArchPhotosById(Archid): Observable<PhotoArch[]> {

    console.log(this.baseUrl + 'ArchPhoto/getArchPhotos' + Archid);
    return this.http.get<PhotoArch[]>(this.baseUrl + 'ArchPhoto/getArchPhotos/' + Archid);

  }
  GetArchPhotosStream(): Observable<Blob> {

    console.log(this.baseUrl + 'ArchPhoto/GetArchPhotos');
    return this.http.get(this.baseUrl + 'ArchPhoto/GetArchPhotosStream', { responseType: "blob" });

  }
  GetArchPhotosStream2(): Observable<Blob> {

    console.log(this.baseUrl + 'ArchPhoto/GetArchPhotos');
    return this.http.get(this.baseUrl + 'ArchPhoto/GetArchPhotosStream', { responseType: "blob" });


  }
  addarch(arch: Arch) {
    
    console.log('add api : ' + this.baseUrl + 'Arch/addarch');
    //Headers.ap('Authorization', 'Bearer AADDFFKKKLLLL');
 
    return this.http.post(this.baseUrl + 'Arch/addarch', arch);
  }
    getUser(id): Observable<User> {
        return this.http.get<User>(this.baseUrl + 'users/' + id)
   // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }

    //getUser(id): Observable<User> {
    //    return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
    //}


    updateUser(id: number, user: User) {
        return this.http.put(this.baseUrl + 'users/' + id, user);
    }

  setMainPhoto(userId: number, id: number) {
    return this.http.post(this.baseUrl + 'users/' + userId + '/photos/' + id + '/setMain', {});

  }
  deleteArch(archId: number) {
 
    console.log(this.baseUrl + 'arch/' + archId );
    return this.http.delete(this.baseUrl + 'arch/' + archId );
  }

  

 
}
