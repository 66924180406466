import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Localst } from '../localst.service';
import { ApiRet } from '../../_models/General/ApiRet';
import { Backupc } from '../../_models/accounts/Backupc';
 
let EmptyBackupc: Backupc ={
  id: 0,
  name: '',
  fileN: 'none',
  dateA: "2012-01-23T18:25:43.511Z", 
  path: 'none',
  isSystem: false,
  perdate: null
}



@Injectable({
  providedIn: 'root'
})
export class BackupService {
 // baseUrl = environment.apiUrl;
  origin = location.origin;
  

  constructor(private http: HttpClient, private localstService: Localst) { }


  getall(): Observable<ApiRet> {
    let apiadress = '/api/accounts/Backupc/getall';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getallSys(): Observable<ApiRet> {
    let apiadress = '/api/accounts/Backupc/getallSys';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  takeBackup(data: Backupc): Observable<ApiRet> {
    let apiadress = '/api/accounts/Backupc/takeBackup';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }
  deleteBackup(id: number): Observable<ApiRet> {
    let apiadress = '/api/accounts/Backupc/deleteBackup?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  DownloadBackupFile(id: number): Observable<Blob> {
    let apiadress = '/api/accounts/Backupc/DownloadBackupFile?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<Blob>(this.origin + apiadress, { 'headers': headers });
  }

  //GetZipFile(id: number): Observable<Blob> {
  //  let apiadress = '/api/accounts/Backupc/GetZipFile';
  //  var headers = this.localstService.header_get(apiadress, null);
  //  return this.http.get<Blob>(this.origin + apiadress, { 'headers': headers });
  //}
  public GetZipFile(id: number) {
    let apiadress = '/api/accounts/Backupc/GetZipFile?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get(this.origin + apiadress, {
      'headers': headers,
      reportProgress: true,
      responseType: 'blob',
    });
  }

  public GetZipFileSys(item) {
    let apiadress = '/api/accounts/Backupc/GetZipFileSys';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post(this.origin + apiadress, item, {
      'headers': headers,
      reportProgress: true,
      responseType: 'blob',
    });
  }
  getBackupcEmpty(): Backupc {
    return JSON.parse(JSON.stringify(EmptyBackupc));
  }
}
