import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { Ghar } from '../../../../_models/app99/app99model';
import { App99Service } from '../../../../_services/app99/App99.service';
import { Localst } from '../../../../_services/localst.service';
 
 
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app99-Ghar-list',
  templateUrl: './Ghar-list.component.html',
  styleUrls: ['./Ghar-list.component.css']
})


export class GharListComponent implements OnInit {

  openedit: boolean;
  openeditId: number =-1;
  @Output() OnMyprint = new EventEmitter<any>();
 

  modlelist: Ghar[];
 
 
 
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private Ghar_Service: App99Service,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private localst: Localst,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
    this.modlelist = [];
 
  }


 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();

  }
 
 
  
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.Ghar_Service.Ghar_getall()
      .subscribe((res: Ghar[]) => {
        this.modlelist = [];
        this.modlelist = res;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
 
 
  reloadrows(): void {
    this.load();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

  }
 


  onClickDelete() {

    if (this.loaddata.showed)
      return;

    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {



    

    this.loadServ.showloading(this.loaddata, 1);
    this.Ghar_Service.Ghar_delete(this.selectedrow.id).subscribe(data => {

   
      if (data.errors.length > 0) {
        this.alertify.showListString(data.errors);
      }
  
        if (data.code==200)
          this.alertify.success(data.mes);
        else
          this.alertify.warning(data.mes);
 

      this.loadServ.hideloading(this.loaddata,1);
      this.load();

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }







  onClickEdit() {

    if (this.loaddata.showed)
      return;
    if (this.selectedrow.id == null) {



      return;
    }



    this.openeditId = this.selectedrow.id;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
 
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
  
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.load();

  }
  onClickExportexcel() {

 

    this.excelService.exportAsExcelFile(this.modlelist, 'لیست اشخاص');
  }


  onClickprint() {

    //بلتابتبفغت
    if (this.selectedrow.id > -1) {

      this.OnMyprint.emit({ value: this.selectedrow.id });
    }
   

   

  }
}
