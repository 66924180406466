 
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 
let Emptyinter: loadingCl = {

  loadingNum: 0,
  bollist: [],
  showed: false


};
@Injectable({
  providedIn: 'root'
})
export class LoadingService {



  //loadingNum: number;
  //bollist: boolean[];
  //public showed: boolean;
  constructor(private http: HttpClient) {
   // this.loadingNum = 0;
   // this.bollist = [];
   
   // this.showed = false;

  }
  create(data: loadingCl ,_loadingNum: number) {

    data.loadingNum = _loadingNum;
    data.bollist = [];
    data.showed = false;
    for (var r = 0; r < data.loadingNum; r++) {
      data.bollist.push(false);
    }

  }
  showloading(data: loadingCl ,loaded: number) {

    if (loaded > (data.loadingNum - 1))
      return;

    data.bollist[loaded] = true;

    data.showed = true;
  }
  hideloading(data: loadingCl ,loaded: number) {

    if (loaded > (data.loadingNum - 1))
      return;

    data.bollist[loaded] = false;


    data.showed = this.check(data ,loaded);

  }

  check(data: loadingCl ,loaded: number) {


    for (var r = 0; r < data.loadingNum; r++) {
      if (data.bollist[r])
        return true;

    }

    return false;

  }

  clear(data: loadingCl) {

    for (var r = 0; r < data.loadingNum; r++) {
      data.bollist[r] = false;
    }
    data.showed = false;

  }
  fill(data: loadingCl) {

    for (var r = 0; r < data.loadingNum; r++) {
      data.bollist[r] = true;
    }
    data.showed = true;
  }

  getEmpty(): loadingCl {


    return JSON.parse(JSON.stringify(Emptyinter));

  }
}
