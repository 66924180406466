import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Shakhz } from '../../../../_models/app1/paye/Shakhz';
import { ShakhzService } from '../../../../_services/app1/paye/Shakhz.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { Localst } from '../../../../_services/localst.service';
 
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-Shakhz-list',
  templateUrl: './Shakhz-list.component.html',
  styleUrls: ['./Shakhz-list.component.css']
})


export class ShakhzListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;

  defaultpagation: Pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  };


 

  isdadghostari: boolean = false;


  modlelist: Shakhz[];
  header_Shkhz: Shakhz;
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
  header: any;
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private shakhzService: ShakhzService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private localst: Localst,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
  
    this.loadServ.create(this.loaddata,8);
    this.pagination = this.defaultpagation;
    this.header = this.shakhzService.getTafsiliHeader();
  }


 


  ngOnInit() {

  
    


    this.resetFilters();
    this.isdelete = "no";;
    this.loadShakhz();

    let cus = this.localst.sherkatInfo_get().customer;

    if (cus == 'dadghostari') {

      this.isdadghostari = true;
    }

  }
  onClicksearch() {

    this.loadShakhz();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadShakhz();
    console.log('m:searching... all');
  }
  rrr() {
    this.gridContainer.instance.refresh();
    this.gridContainer.instance.getDataSource().reload();
    console.log('refleshrrr: ' + this.modlelist.length.toString());
  }
  rrr2() {

    this.gridContainer.instance.getDataSource().reload();
    console.log('refleshrrr: ' + this.modlelist.length.toString());
  }
  loadShakhz() {


    this.loadServ.showloading(this.loaddata,0);
    this.shakhzService.getShakhzsnopage()
      .subscribe((res: Shakhz[]) => {
        this.modlelist = res;
        console.log('reflesh: ' + this.modlelist.length.toString());
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

    //this.shakhzService.getShakhzs_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.shakhsParams)
    //  .subscribe((res: PaginatedResult<Shakhz[]>) => {
    //    this.modlelist = res.result;
    //    this.pagination = res.pagination;
    //  }, error => {
    //    this.alertify.error(error);
    //  });

  }
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';

  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadShakhz();
  }


  reloadrows(): void {
    this.loadShakhz();
    console.log("m:page refleshe done");
    //  this.loadShakhz();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

    console.log(JSON.stringify(this.selectedrow));

  }
  onClickedickNew() {

    console.log(JSON.stringify(this.selectedrow));


    if (this.selectedrow.id != null)
      this.router.navigate(['/app1/paye/TafDaste/Edit', this.selectedrow.id]);

  }


  onClickDelete() {

    if (this.loaddata.showed)
      return;

    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }
  onRowDblClick(e) {
    //fgdhgt6565
    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }
  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {





    this.loadServ.showloading(this.loaddata,1);
    this.shakhzService.deleteshakhz(this.selectedrow.id).subscribe(data => {
      let dd = data;
      // 0  وجود ندارد
      // 1  پاک شد
      // 2  اجازه پاک شدن ندارد
      if (dd == 1)
        this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

      if (dd == 2)
        this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

      if (dd == 0)
        this.alertify.warning('این کد در سرور یافت نشد');

      this.loadServ.hideloading(this.loaddata,1);
      this.loadShakhz();

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }







  onClickEdit() {

    if (this.loaddata.showed)
      return;
    if (this.selectedrow.id == null) {



      return;
    }



    this.openeditId = this.selectedrow.id;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.loadShakhz();

  }
  onClickExportexcel() {


    // console.log(JSON.stringify(this.modlelist[0]));


    // var p=  this.shakhzService.convertToShakhzForPrint(this.modlelist);
 

    //this.excelService.exporttableRowbyRow(p , this.header, 'لیست اشخاص');

    this.excelService.exportAsExcelFile(this.modlelist, 'لیست اشخاص');
  }

}
