import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../_models/pagination';

import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { NoteService } from '../../../_services/General/Note.service';
import { OstanKol } from '../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../_models/app1/paye/Ostan';
import { Note } from '../../../_models/General/Note';
import { mySF } from '../../../../../my_modules/mySF';
import { DialogvalidComponent } from '../../../dialogvalid/dialogvalid.component';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../dialog/deldialog.component';
import { Localst } from '../../../_services/localst.service';
import { LoadingService } from '../../../_services/loading.service';
 
/*import * as variable from 'angularTrix';*/
 

@Component({
  selector: 'app-Note-edit',
  templateUrl: './Note-edit.component.html',
  styleUrls: ['./Note-edit.component.css']
})
export class NoteEditComponent implements OnInit {

 
 
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() oncancel = new EventEmitter();
  @ViewChild("myGrid", { static: false }) myGrid: DxDataGridComponent;
 
  loaddata: loadingCl;

  shkhscodelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
   
  id: number;
  private sub: any;
  test: string;
    n: number;
  mytitle: string;
  modlelist: Note[];
  selected: Note;
  cheked: boolean = false;
  editclass: string;

  constructor(private userService: UserService, private alertify: AlertifyService,

    private NoteService: NoteService,
    private localst: Localst,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private ostanService: OstanService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private sf: mySF,
  ) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);
 
    this.selected = this.NoteService.getNoteEmpty();
    this.modlelist = [];

    this.validationCallback = this.validationCallback.bind(this);

  //  this.myModel = this.NoteService.getNoteEmpty();
    this.editclass = 'mydisble';

  }

  ngOnInit() {
   
 

 
 
    this.isnew = true;



    this.reloadlist();
  }

  onFocusedRowChanged(e) {

    if (e.row.data == null)
      return;

    this.selected = e.row.data;


  }
  addthis = e => {


  }
  cancelthis = e => {


  }

  reloadlist() {


    this.NoteService.getNotes(this.cheked , this.localst.yearnow_get())
      .subscribe(res => {
        this.modlelist = res.data;
      }, error => {
        this.alertify.error(error);
      });


  }


  onFormSubmit = function (e) {
 
 
 
  

   


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }



  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
  

  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    return true

    let v = e.value;


    for (var val of this.shkhscodelist) {

      if (v == val) return false;
    }
    return true



}


  validateNumber(e) {

    

  }

  numberBox_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

 
   
    
     
  }
  
  datestart_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log(newValue);

    let t =  this.sf.calcOtherThing(2);

 
  
   console.log(t);
 
  }

  onDelete() {

    this.loadServ.showloading(this.loaddata, 3);
    this.NoteService.deleteNote(this.selected.id).subscribe(next => {
      this.reloadlist();
      this.loadServ.hideloading(this.loaddata, 3);
    }, error => {

      this.alertify.error(error);
      this.loadServ.hideloading(this.loaddata, 3);
    });


  }


  

  onClickNew() {
    let a = this.NoteService.getNoteEmpty();
    let u = this.localst.usernow_get();
    a.uFLog = u.username;
    a.uFLog = u.name;
    a.years = this.localst.yearnow_get();
 
    this.loadServ.showloading(this.loaddata, 1);
    this.NoteService.addNote(a).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.selected = res.data;
      this.modlelist = [this.selected].concat(this.modlelist);// [ 4, 3, 2, 1 ]
   

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
    });
    
 

  }
  onSaveEdit() {


 



  }

  onEditrow(info) {

 
 
  }
  onDeleterow(info) {


 
    this.openDialog(info.data.id);
  }

  openDialog(id): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete(id);

      }

    });
  }

  dodelete(id: number) {

    this.NoteService.deleteNote(id).subscribe(result => {
    
      this.reloadlist();
    });

  }

  onFocusOutarea(e) {

    console.log('note saved');
    let u = this.localst.usernow_get();
    this.selected.uFLog = u.username;
    this.selected.uFLog = u.name;

    this.loadServ.showloading(this.loaddata, 2);
    this.NoteService.updateNote(this.selected).subscribe(res => {

  
      for (var r of this.modlelist) {
        if (r.id == res.data.id) {
          this.selected.tit = res.data.tit;
          r.tit = res.data.tit;
         
          break;
        }
      }
      this.loadServ.hideloading(this.loaddata, 2);
 

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
    });

  }

  chekChanged(e) {

  }

  onCloseNote() {
    this.oncancel.emit();

  }

}

class MyStaticFunction2 {
  static pi = 3.14;

  static ChekExistDate(radius: string) {
    return true;
  }


  static calculateArea(radius: number) {
    return this.pi * radius * radius;
  }

  calculateCircumference(radius: number): number {
    return 2;
  }





}
