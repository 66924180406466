import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { LoadingService } from '../../../../_services/loading.service';
import { VmDar, VmDarForForm } from '../../../../_models/app98/opr/VmDar';
import { VmDarService } from '../../../../_services/app98/opr/VmDar.service';
import { VmGrs } from '../../../../_models/app98/paye/VmGrs';
import { VmGrsService } from '../../../../_services/app98/paye/VmGrs.service';
import DataSource from "devextreme/data/data_source";
import { Localst } from '../../../../_services/localst.service';
import * as FileSaver from 'file-saver';
import { ExcelService } from '../../../../_services/excel.service';
import { ApiRet } from '../../../../_models/General/ApiRet';

@Component({
  selector: 'app98-VmDar-edit',
  templateUrl: './VmDar-edit.component.html',
  styleUrls: ['./VmDar-edit.component.css']
})
export class VmDarEditComponent implements OnInit {



  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() kind: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;
  myModel: VmDarForForm;
  myModel_old: VmDarForForm;


  vmGrslist: VmGrs[] =[];
  tafMolist: any = {};
 VmDarcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
   n: number;
   showGrid: boolean = false;
   showExel: boolean = false;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private vmDarService: VmDarService,
    private vmGrsService: VmGrsService,
    private tafsiliService: TafsiliService,
    private excelService: ExcelService,
    private localst: Localst,
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.vmDarService.getVmDarForFormEmpty(this.kind);
    this.VmDarcodelist = [];
 
  }

  ngOnInit() {


    this.tafMolist = new DataSource({
      store: [],
      paginate: true,
      pageSize: 50
    });

    this.bindall();
    this.isnew = true;
 
    if (this.editId == -1) {
 
   
      this.myModel = this.vmDarService.getVmDarForFormEmpty(this.kind);
      this.myModel.taki.years = this.localst.yearnow_get();
      this.myModel.taki.dateA_S = this.localst.dateEmruz.dateString;
      this.myModel.taki.uSa = this.localst.usernow_get().username;
      this.myModel.taki.uSaF = this.localst.usernow_get().name;

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.vmDarService.getbyid(this.editId).subscribe(
        (res) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = res.data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          this.myModel.taki.uSaE = this.localst.usernow_get().username;
          this.myModel.taki.uSaEF = this.localst.usernow_get().name;
      
 

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }
    bindall() {

      this.loadServ.showloading(this.loaddata, 0);
      this.tafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId, 'vmdar').subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.tafMolist = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

      this.loadServ.showloading(this.loaddata, 1);
      this.vmDarService.getallCode().subscribe(data => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.VmDarcodelist = data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });


      this.loadServ.showloading(this.loaddata, 5);
      this.vmGrsService.getall(true).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.vmGrslist = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

    }
  refreshbottum() {

    this.bindall();

  }

  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.taki.id == null) {


      this.myModel.taki.id =-1

    }

 
 
    if (this.myModel.taki.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.vmDarService.add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.vmDarService.update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

 
    return true


  }
 
 

  validateNumber(e) {

    

  }

  vmGrschanged(e) {

    var key = e.value;

    let vs = this.vmGrslist.find(c => c.id == key);
    if (vs == null)
      return;

    this.myModel.vmGrsN = vs.name;


    if (key == -1) {
      this.myModel.rows = [];
      this.myModel.taki.tafId = null;
      this.showGrid = false;
      this.showExel = false;

    }
     else if (key == -2) {
      this.myModel.rows = [];
      this.myModel.taki.tafId = -1;
      this.showGrid = true;
      this.showExel = true;

    }
    else {
      this.showGrid = true;
      this.showExel = false;
      this.myModel.taki.tafId = -1;
      this.loadServ.showloading(this.loaddata, 6);
      this.vmDarService.getRowOnVmGrs(key).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.myModel.rows = res.data;

 

      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });



    }


  }
  exportexcel() {


    let def = [{ TafsiliCode: 'text', CodeMelli: 'text', Name: '', Price: 0, Description:'' }];

    this.excelService.exportAsExcelFile(def , 'Daryafti');




    //this.loadServ.showloading(this.loaddata, 3);
    //this.vmDarService.getDefaultXls().subscribe((buffer) => {
    //  this.loadServ.hideloading(this.loaddata, 3);

    //  const data: Blob = new Blob([buffer], {
    //    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
    //  });
    //  FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 3);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});




  }
  onValueChangedPrc(e, cellInfo) {

    cellInfo.data.prc = e.value;

  }

  readExcel(event) {
    //گرفتن دیتاا
    //گرفتن دیتاا
    this.excelService.importExcelFile(event).then((data : any[][]) => {
      //console.log(JSON.stringify(data));



      if (data.length < 2)
        return;
      //try4dseftger
      // this.alertify.showListString(['gfh', 'dddfghy', 'fff', '444']);
      this.loadServ.showloading(this.loaddata, 11);
      this.vmDarService.checkReadedExcelGrs(data ).subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 11);
 
        if (res.errors.length > 0) {
        //  this.myModel.rows = res.data;
          this.alertify.showListString(res.errors);

        }

        else {
          this.myModel.rows = res.data;
    
          // this.dataGrid.instance.refresh();
          this.alertify.success(res.mes);
        }
     
      }, error => {
        this.loadServ.hideloading(this.loaddata, 11);
        this.alertify.error(error);
        // console.log("myerror:" + error)
      });
      this.myInputexcel.nativeElement.value = "";

    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      // console.log("myerror:" + error)
    });



  }

}
