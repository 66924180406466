import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { Morur, MorurOption } from '../../../_models/app1/rep/Morur';
import { Rizgardesh } from '../../../_models/app1/rep/rizgardesh';
import { Localst } from '../../localst.service';
 
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};



 
 
@Injectable({
  providedIn: 'root'
})
export class RizgardeshService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  EmptyRizghadesh: Rizgardesh = {
    id: null,
    radif: null,
    cShomare: null,
    cRozane: null,
    cAtf: null,
    cFare: null,
    date: null,
    bed: 0,
    bes: 0,
    dis: null,
    DateS: null,
    sanadId: -1,
    bedMan: 0,
    besMan: 0,
    legpx: 0
  };

  constructor(private http: HttpClient, private localstService: Localst) { }



 
  getRizghardesh(option: MorurOption): Observable<Rizgardesh[]> {
    let apiadress = '/api/App1/Rep/RizGhardesh/GetRizGhardesh';
    var headers = this.localstService.header_get(apiadress,null);

    console.log(apiadress);
    return this.http.post<Rizgardesh[]>(this.origin + apiadress, option, { 'headers': headers } );

  }


  getemptyRizghardesh(): Rizgardesh {
    return JSON.parse(JSON.stringify(this.EmptyRizghadesh));
  }

  getprint(moror: Morur[]): string {

    let row =
      `
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
`
      
    if (moror != null) {


      for (var _i = 0; _i < moror.length; _i++) {

        row = row + '<tr><td>' + moror[_i].id + '</td>' +
          '<td>' + moror[_i].name + '</td>' +
          '<td>' + moror[_i].code + '</td></tr>';

   
    
      }

    }
  

    return `

<style>

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 20mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 1cm;
        border: 5px red solid;
        height: 257mm;
        outline: 2cm #FFEAEA solid;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }




</style>



  <div> 
<div class="book">
  <div class="page">
    <div class="subpage"><table style="width:100%">
  <tr>
    <th>لببلا</th>
    <th>Lastname</th>
    <th>Age</th>
  </tr>
  `+

      row + row + row + row


      +`
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table></div>
  </div>
  <div class="page">
    <div class="subpage">Page 2/2</div>
  </div>
</div>



</div>




`;






  }






}
