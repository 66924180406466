import { Component, OnInit, ViewChild, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { User } from '../../_models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ArchService } from '../../_services/arch.service';
import { Observable } from 'rxjs';
import { MyService } from '../../_services/my.service';

import { ArchPersonService } from '../../_services/archperson.service';
import { ArchPerson } from '../../_models/archperson';
import { Arch } from '../../_models/arch';

@Component({
  selector: 'app-arch-edit',
  templateUrl: './arch-edit.component.html',
  styleUrls: ['./arch-edit.component.css']
})
export class ArchEditComponent implements OnInit {


  @Input() user: User;
  @Input() isnew: boolean;
  @Output() cancelRegister = new EventEmitter();
  str: string;
  @ViewChild('editForm', { static: true }) editForm: NgForm;
  

  @Input() arch: Arch;
  photoUrl: string;
  archForm: FormGroup;
  selectedtab=0;
  dataSource: User[];
  dsArchPerson: ArchPerson[];
  lookupDataSource: string[];
    //برای وقتی که مرورگر کلا میبندیم
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    
    }
  }

  constructor(private route: ActivatedRoute, private alertify: AlertifyService,
    private userService: UserService, private authService: AuthService
    , private archService: ArchService, private myService: MyService, private archpersonservice: ArchPersonService 
    , private fb: FormBuilder, private router: Router) {
  
    this.str = "strt";
    
   // this.dataSource = myService.getUsersAll();

    
    //this.dataSource = new DataSource({
    //  store: new ArrayStore({
    //    data: service.getTasks(),
    //    key: "Id",
    //  }),
    //  group: "Assigned"
    //});

    //this.user.username = "jjj";
  }
  
  public demo1BtnClick() {
    
    const tabCount = 3;
    this.selectedtab = (this.selectedtab + 1) % tabCount;
  }
  loadperson() {
  //  this.lookupDataSource = ["HD Video Player", "علی محمدی", "SuperPlasma 50"];
    this.arch = this.archService.getArchEmpty();

    //this.archpersonservice.getArchPersons().subscribe((res: ArchPerson[]) => {
    //  this.dsArchPerson = res;
    //  console.log('load numper of archperson:'+ this.dsArchPerson.length.toString())
    //}, error => {
    //  console.log('myerror: ');
    //  this.alertify.error(error);
    //});


  }
  ngOnInit() {
    this.loadperson();
    this.route.data.subscribe(data => {
      this.user = data['user'];
    });
    if (this.isnew == null) {
      this.isnew = false;
     
    }
    
   // this.createarchFormForm();
  }
  //createarchFormForm() {
  //  this.archForm = this.fb.group({
      
  //    UserId:[-1],
  //    UserN: ['', Validators.required],
  //    name: ['', Validators.required],
  //    dis: [''],
  //    created: ['0001-01-01T00:00:00', Validators.required],
  //    knownAs: ['' ],
  //    lastActive: ['0001-01-01T00:00:00', Validators.required],
  //    userId: [-1, Validators.required]
  
  
  //  });
  //}

  Addthis() {
    //console.log(this.archForm.errors);

   // if (this.archForm.valid) {
    console.log('m:formValid');
  
      //تمام فیلد های فرم که همنام در کلاس کپی میکند
    //  this.arch = Object.assign({}, this.archForm.value);
      console.log(JSON.stringify(this.arch));


    this.archService.addarch(this.arch).subscribe(data  => {
        this.alertify.success('Adding successful');
      this.selectedtab = 1;
      this.arch.Id = parseInt(data.toString(), 10);
      console.log('inserted arch id: '+this.arch.Id.toString());
      //  this.router.navigate(['/arch']);
      }, error => {
        this.alertify.error(error);
        console.log('myerror: ' + error);
      });
   // }
   // else {
   //   console.log('m:formUnValid');
   // }
    //  console.log(this.registerForm.value);
  }
    updateUser() {
 
    }
  
  updateMainPhoto(photoUrl) {

    

  }

  cancel() {
    this.cancelRegister.emit(false);
    console.log('cancelled');
    this.router.navigate(['/arch']);
  }

  onKey(e) {

  }
  beforeChange(e) {
    console.log('tabclicked');
  }

  addthis = e => {
    // const buttonText = e.component.option("text");
    //notify("The button save was clicked");
    console.log("The button save was clicked");
    this.Addthis();

  }
  cancelthis = e => {
    // const buttonText = e.component.option("text");
    //notify("The button save was clicked");
    console.log("The button cancel was clicked");
    this.router.navigate(['/arch']);

  }




  onFormSubmit = function (e) {


   
    console.log("m:submitform");
   // this.Addthis();


   // e.preventDefault();
  }
}
