import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent, DxListComponent, DxValidatorComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadingService } from '../../../../_services/loading.service';
import { tabeventobj } from '../../../../_models/tabeventobj';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { AnEndService } from '../../../../_services/app2/end/AnEnd.service';
import { AnEnd } from '../../../../_models/app2/end/AnEnd';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
 

@Component({
  selector: 'app2-AnEnd-list',
  templateUrl: './AnEnd-list.component.html',
  styleUrls: ['./AnEnd-list.component.css']
})
 

export class AnEndListComponent implements OnInit {
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;;
  @Input() hyperLinkOption: any;

  @Input() kindpage: number;
  @ViewChild("validAnbar", { static: false }) validAnbar: DxValidatorComponent;
  @ViewChild("validDate", { static: false }) validDate: DxValidatorComponent;
 
  @ViewChild("dxcolumlist", { static: false }) dxcolumlist: DxListComponent;
     //kind نوع صفحه
        //AnEndId ای دی سند
        //AnEndRowId ردیف فکوس
  @Input() inputdata: any;

 
  myModel: AnEnd;

  valdata: DialogData;
  selectedrow: any;
  anbarlist: Anbar[];
  myModelList: AnEnd[] = [];
  openeditId: number = -1;
  openedit: boolean = false;
   
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  loaddata: loadingCl;

  constructor(private userService: UserService,
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anEndService: AnEndService,
    private anRepService: AnRepService,
    private anbarService: AnbarService,
    private anDeedService: AnDeedService,
  
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {
    
  

 
 
    this.loaddata = loadServ.getEmpty();
 
    this.loadServ.create(this.loaddata,20);
 
     
  } 
 

 
  ngOnInit() {

    this.myModel = this.anEndService.getAnEndEmpty();


    this.loadServ.showloading(this.loaddata, 12);
    this.anbarService.getall().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 12);

      this.anbarlist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });






    this.load();
 
  }

  
  load() {

    let b = this.anEndService.getAnEndEmpty();

    this.loadServ.showloading(this.loaddata, 0);
    this.anEndService.getall(this.localst.yearnow_get())
      .subscribe(data => {
       
        this.loadServ.hideloading(this.loaddata, 0);
        this.myModelList =data;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });
       
  }


 

 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


  

  }


  onClickDelete() {


     
    this.openDialog();
 

  }

  openDialog(): void {

   
    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });
   // dialogRef.componentInstance.message = 'jjj';
   
    dialogRef.afterClosed().subscribe(result => {
     
     // console.log('m:The dialog was closed');
      let isdelete = result;
     // console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }


  

  dodelete() {


    var key = this.dataGrid.focusedRowKey;
 
 

    this.loadServ.showloading(this.loaddata,2);
    this.anEndService.delete(key).subscribe((res) => {
      this.loadServ.hideloading(this.loaddata,2);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      } else {
        this.load();
      }
    
      
  

    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 
  

  
     
  }


 

 


 onRowDblClick(e) {
 

  }

 

  onClickReflesh() {

 
 
    this.load();

  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' لیست اسناد ');

  }
  
  
  saveuioption() {
    //ghh5uytbn7777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2002 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw2002.length; i++) {
      this.localst.optioncli.gw2002[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw2002 = this.localst.optionClient_readDefault('gw2002');
  }
  onanbarchanged(e) {

    if (e.value == undefined)
      return;
    var h = e.component.option('selectedItem') as Anbar;

    this.myModel.anbarId = h.id;
    this.myModel.anbarN = h.name;
   


    this.loadServ.showloading(this.loaddata, 2);
    this.anDeedService.getMaxDateOfDeed(this.localst.yearnow_get() , h.id).subscribe((res) => {
      this.loadServ.hideloading(this.loaddata, 2);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.myModel.dateA_S = res.data.date_S;
      }

 


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




     
  }

  onFormSubmit(condition: string) {


    if (this.loaddata.showed)
      return;



    if (this.myvalidationchek(condition) == false) {
  
      return;
    } 

    this.myModel.years = this.localst.yearnow_get();

    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata, 4);
      this.anEndService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 4);
        //ytu568658
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
         // this.OnMySaveEvent.emit(true);
          this.load();
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);

      });

    }
    else {

      //this.loadServ.showloading(this.loaddata, 5);
      //this.anEndService.update(this.myModel).subscribe(res => {
      //  this.loadServ.hideloading(this.loaddata, 5);
      //  if (res.errors.length > 0) {
      //    this.alertify.showListString(res.errors);
      //  }
      //  else {
      //    this.OnMySaveEvent.emit(true);

      //  }




      //}, error => {
      //  this.loadServ.hideloading(this.loaddata, 5);
      //  this.alertify.error(error);

      //});

    }





  }
  onEftetah() {





  }


  myvalidationchek(condition: string): boolean {




    const verror = [];


    if (this.myModel == null)
      verror.push('model is empty');
    else {

      if (!this.validAnbar.instance.validate().isValid) {
        verror.push('انبار خالی است');

      }

      if (!this.validDate.instance.validate().isValid) {
        verror.push('تاریخ خالی است');

      }

   
    
       
    }
    // verror.push('ارور');

    if (verror.length == 0)
      return true;





    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);




    return false;;
  }
  onClickShow() {
    this.openeditId = -1;
    let key = this.dataGrid.focusedRowKey;

    if (key > 0) {

      this.openeditId = key;

      this.openedit = true;

    }

  }
  OnMyCancelEvent(e) {
 
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }

  }

 
}


