import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { TafDaste, TafDasteSort } from '../../../../_models/app1/paye/TafDaste';
import { TafDasteService } from '../../../../_services/app1/paye/TafDaste.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ViewChild } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-TafDaste-list',
  templateUrl: './TafDaste-list.component.html',
  styleUrls: ['./TafDaste-list.component.css']
})
 

export class TafDasteListComponent implements OnInit {


  openedit: boolean;
  openeditId: number;
 
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 5,
  totalItems: 0,
  totalPages: 0,
   };
  selectedrow: any;
  TafDastes: TafDaste[];
  header: any;
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  searchParams: any = {};
  pagination: Pagination;


  //animal: string;
  //name: string;
  isdelete: string;
  enableedit: string;
  enabledelete: string;
  loaddata: loadingCl;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
  constructor(private userService: UserService, private alertify: AlertifyService, private TafDasteService: TafDasteService,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private router: Router,
    private excelService: ExcelService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,5);
    this.pagination = this.defaultpagation;
    this.header = this.TafDasteService.getTafDasteHeader();
    this.enableedit = "mydisble";
    this.enabledelete = "mydisble";
    this.openedit = false;
    this.openeditId = -1;



    //this.emailButtonOptions = {
    //  icon: 'email',
    //  stylingMode: 'contained',
    //  text: 'Send',
    //  onClick: () => {
    //    const message = `Email is sent to`;
    //    //notify({
    //    //  message,
    //    //  position: {
    //    //    my: 'center top',
    //    //    at: 'center top',
    //    //  },
    //    //}, 'success', 3000);
    //  },
    //};
    //this.closeButtonOptions = {
    //  text: 'Close',
    //  stylingMode: 'outlined',
    //  type: 'normal',
    //  onClick: () => {
    //    this.popupVisible = false;
    //  },
    //};
  }
  userType: number = 0;
  ngOnInit() {
    this.resetFilters();
    this.isdelete = "no";;
    this.loadTafDaste();

    
    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
        // this.alertify.showListString(res.errors);
      }
      else {
        // this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
  }
  onClicksearch() {

    this.loadTafDaste();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadTafDaste();
    console.log('m:searching... all');
  }
  loadTafDaste() {

   
   // console.log(JSON.stringify(this.searchParams));

    this.loadServ.showloading(this.loaddata, 0);
    this.TafDasteService.getTafDastes().subscribe((res: TafDaste[]) => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.TafDastes = res;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
    });



 
 
  }
  resetFilters() {
    this.searchParams.Name = '';
    this.searchParams.CodeStart = '';
 
     
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadTafDaste();
  }


  reloadrows(): void {
    this.loadTafDaste();
 
    
  }
  refleshProgress() {
    

  }
  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;
    
    if (this.selectedrow == null) {

      this.enableedit = "mydisble";
      this.enabledelete = "mydisble";
    }
    else if (this.selectedrow.id < 0) {

      this.enableedit = "myenable";
      this.enabledelete = "mydisble";

    }
    else {

      this.enableedit = "myenable";
      this.enabledelete = "myenable";
    }
    
   // console.log(JSON.stringify(this.selectedrow));

  }

  onClickedickNew() {


    console.log('newopen');


    this.openedit = true;
    this.openeditId = -1;

    //console.log(JSON.stringify(this.selectedrow));


    //if (this.selectedrow.id != null)
    //this.router.navigate(['/app1/paye/TafDaste/Edit', this.selectedrow.id]);

  }


  onClickEdit() {


    if (this.loaddata.showed)
      return;

    if (this.selectedrow.id == null) {
      return;
    }

  


      this.openeditId = this.selectedrow.id;
      this.openedit = true;

  }

  onClickDelete() {
    if (this.loaddata.showed)
      return;

    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }

  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }

  }

  OnMySaveEvent(e) {
  
    console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditId = -1;
    }
    this.loadTafDaste();
  }
  dodelete() {

    this.loadServ.showloading(this.loaddata, 1);
    this.TafDasteService.deleteTafDaste(this.selectedrow.id).subscribe((data) => {
      let dd = data;
      // 0  وجود ندارد
      // 1  پاک شد
      // 2  اجازه پاک شدن ندارد
      if (dd == 1)
        this.alertify.success('ردیف مورد نظر با موفقیت حذف شد');

      if (dd == 2)
        this.alertify.warning('این کد مورد استفاده قرار گرفته است و قابلیت حذف ندارد');

      if (dd == 0)
        this.alertify.warning('این کد در سرور یافت نشد');
      console.log("m:deleted successful");
      this.loadTafDaste();
      this.loadServ.hideloading(this.loaddata, 1);

    }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }

  onClickNew() {

    if (this.loaddata.showed)
      return;

    console.log('newopen');


    this.openedit = true;
    this.openeditId = -1;

  }
  onClickExportexcel() {


    // console.log(JSON.stringify(this.modlelist[0]));


    // var p=  this.shakhzService.convertToShakhzForPrint(this.modlelist);


    //this.excelService.exporttableRowbyRow(p , this.header, 'لیست اشخاص');

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance, ' لیست گروه تفصیلی ');
  }


  emailButtonOptions: any;

  closeButtonOptions: any;



 // currentTafdaste: TafDasteSort = null;
 //popupVisible : boolean =false;

  showSort: boolean = false;
  tafDasteSort: TafDasteSort;

  onClickSort() {


    if (this.selectedrow.id == null || this.selectedrow.id == undefined) {
      this.alertify.warning('موردی انتخاب شده');
      return;
    }
 
    this.tafDasteSort = new TafDasteSort();
    this.tafDasteSort.tafDasteId = this.selectedrow.id;
    this.tafDasteSort.startCode = this.selectedrow.codeStart;
    this.tafDasteSort.tafDasteN = this.selectedrow.name;
 


    //this.currentTafdaste = tafDasteSort;
    //this.popupVisible = true;

    this.showSort = true;


  }

  onClickClose() {

    this.showSort = false;
    this.tafDasteSort = new TafDasteSort();

  }
  inuse: boolean = false;
  onClickSaveSort() {

    if (this.inuse) {
      return;
    }


    this.inuse = true;
    this.loadServ.showloading(this.loaddata, 2);
    this.TafDasteService.SortCodes(this.tafDasteSort).subscribe(res => {
      this.inuse = false;
  
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.showSort = false;
      }
      this.loadTafDaste();
      this.loadServ.hideloading(this.loaddata, 2);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      this.inuse = false;
    });



  }
  calculateCellValue(data) {
    console.log(JSON.stringify(data));
    return 'l';
  }
}


