import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User, UserForEdit, UserforAdd } from '../_models/user';
import { Localst } from './localst.service';
import { ApiRet } from '../_models/General/ApiRet';
import { Router } from '@angular/router';
import { AlertifyService } from './alertify.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = location.origin + '/api/';
   // baseUrl = environment.apiUrl + 'auth/';
    jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: any;
  photoUrl = new BehaviorSubject<string>('../../assets/user.png');


  userforaddEmpty: UserforAdd = {
    id: null,
    customerName: null,
    name:null,
    username: null,
    knownAs: null,
    password: null,
    confirmPassword: null,
    isAdmin: false,
    isSuperAdmin: false,
  };

  constructor(private http: HttpClient, private localst: Localst,
    private alertify: AlertifyService,
    private router: Router) { }
  currentPhotoUrl = this.photoUrl.asObservable();

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);    
  }
  getemptyuserforadd(): UserforAdd {

    return JSON.parse(JSON.stringify(this.userforaddEmpty));
  }

  login(model: any) {
    

    return this.http.post(this.baseUrl + 'auth/login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user == null) {
          this.alertify.error('خطا در اتصال به سرور');
        }

        else if (user.valid) {
          //gfhgfh444
          // خروجی تابع لوگ این یوزر و توکن که در مرورگر ذخیره میکنیم
          localStorage.setItem('token', user.token);
          localStorage.setItem('user', JSON.stringify(user.user));


          // console.log(  JSON.stringify(user.user));
          this.localst.usernow_set(user.user as User);

          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          this.currentUser = user.user;
          this.alertify.success('ورود به سیستم با موفقیت انجام شد');
          this.router.navigate(['/maintab']);
          // console.log(this.decodedToken);
          // this.changeMemberPhoto(this.currentUser.photoUrl)
        }
        else {


          this.alertify.error(user.error);

        }
      })
    );



  }

  register(user: User) {
    return this.http.post(this.baseUrl + 'auth/register', user);
  }
  registerbyadmin(user: UserforAdd) : Observable<ApiRet> {
    return this.http.post<ApiRet>(this.baseUrl + 'auth/registerbyadmin', user);
  }
  changpass(user: UserForEdit) {
    return this.http.post(this.baseUrl + 'auth/changpass', user);
  }

    loggedIn() {
        const token = localStorage.getItem('token');
        return !this.jwtHelper.isTokenExpired(token);
    }

  GetSherkatlist(us: string, cu: string) {
  //  console.log(this.baseUrl + 'auth/GetCustomerSherkats?un=' + us + '&cu=' + cu);
    return this.http.get(this.baseUrl + 'auth/GetCustomerSherkats?un=' + us + '&cu=' + cu);
  }
}
