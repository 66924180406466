import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnSell, AnSellAf, AnSellBar, AnSellRow, AnSellRowForMabna } from '../../../_models/app3/opr/AnSell';
import { LastPrc } from '../../../_models/app3/opr/LastPrcDto';
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
//let EmptyAnSell: AnSell = {

//  id: -1,
//  code: null,
//  dateA: 0,
//  dateA_S: null,
//  vaz: -1,
//  anSellId:null,
//  _VazN: 'ثبت نشده',
//  kind: null,
//  kindN: null,
//  kindAtfId: null,
//  kindAtfN: null,
//  anbarId: null,
//  anbarN: null,

//  //بدون مبنا یا عطف
//  //با مبنا یا عطف
//  mabna: 0,
//  mabnaAnDeed:0,
//  hesabId: null,
//  hesabC: null,
//  hesabN: null,
//  taf4Id: null,
//  taf4C: null,
//  taf4N: null,
//  taf5Id: null,
//  taf5C: null,
//  taf5N: null,
//  taf6Id: null,
//  taf6C: null,
//  taf6N: null,
//  elecCode:null,
//  dis: null,
//  rows: [],
//  afs: [],
//  barname: null,
//  fi: 0,
//  prc: 0,
//  prcTkh: 0,
//  prctHaz: 0,
//  prc9: 0,
//  prc10: 0,
//  prc11: 0,
//  years: 0,
//  uSa: null,
//  uSaF: null,
//  uSaE: null,
//  uSaEF: null,
//  dateELog: null,
//  dateLog: '',
//  ctn: 0,
//  anDeedId:null,
//  sanadId: null,
//  more:null,
//  _CAtf: 0,
//  setm: null,
//  taxVaz: 0,
//  taxErD: null,
//  taxVazN:null,
//  taxIdShow: null,
//  taxDateP: null,
//  taxIdEdit: null,
//  ins:1,
//  insN: null,
//  pdPayrecId: null,
//   prcTkhAll:0,
//  prcTkhDrsdAll: 0,
// anSellIdPish:null,
//  anSellIdPishCode: null,
//  isMabnaAllY: false
//};
 
 

//let EmptyDarAnSellRow: AnSellRow = {

//  id: null,
//  kind: -1,
//  opA: null,
//  anSellId: 0,
//  anSellCode: 0,
//  anSellRowId: null,
//  anSellRowCode: null,
//  anbarId: -1,
//  dateA: 0,
//  years: 0,
//  vaz: 0,
//  kalaId: null,
//  kalaN: null,
//  kalaC: null,
//  amt: 0,
//  amtB: 0,
//  amtDone: 0,
//  amtDoneB: 0,
//  darsadM: 0,
//  vahBId: null,
//  vahBN: null,
//  vahId: null,
//  vahN: null,
//  zMulti: null,
//  fi: 0,
//  prc: 0,
//  prcTkhDrsd:0,
//  prcTkh: 0,
//  prcHazDrsd:0,
//  prcHaz: 0,
//  prc9: 0,
//  prc10: 0,
//  prc11: 0,
//  hesabId: null,
//  hesabC: null,
//  hesabN: null,
//  taf4Id: null,
//  taf4C: null,
//  taf4N: null,
//  taf5Id: null,
//  taf5C: null,
//  taf5N: null,
//  taf6Id: null,
//  taf6C: null,
//  taf6N: null,
//  hesabDarId: null,
//  hesabDarC: null,
//  hesabDarN: null,
//  taf4DarId: null,
//  taf4DarC: null,
//  taf4DarN: null,
//  taf5DarId: null,
//  taf5DarC: null,
//  taf5DarN: null,
//  taf6DarId: null,
//  taf6DarC: null,
//  taf6DarN: null,
//  dis: null,
//  disB: null,
//  radif: null,
//  ctn: 0,
//  anDeedRowId: null,
//  anDeedCode: null,
//  _maxAmt: 0,
//  _lock: false,
//  vs: [],
//  sanadId: null,
//}


//let EmptyAnSellBar: AnSellBar = {
//  id: -1,
//  shomare: null,
//  date: null,
//  feres: null,
//  giran: null,
//  tafHamlId: null,
//  tafHamlN: null,
//  ran: null,
//  ranmeli: null,
//  rangovah: null,
//  girpost: null,
//  ferpost: null,
//  shCar: null,
//  kindCar: null,
//  ranCart: null,
//  mahmu: null,
//  kindBox: null,
//  tedadBas: null,
//  barA: null,
//  barB: null,
//  vaznP: null,
//  vaznKhali: null,
//  vaznKhales: null,
//  cost: 0,
//  anSellId: 0,
//  //anSell: null,
//}

 
 

let EmptyAnSellRowFroMabna: AnSellRowForMabna = {
  id: null,
  dateA: null,
  dateA_S: null,
  kalaId: null,
  kalaN: null,
  kalaC: null,
  amtB: null,
  amtmB: null,
  vahBId: null,
  vahBN: null,
  vahId: null,
  vahN: null,
  zMulti: null,
  anbarId: null,
  tafMoId: null,
  anSellId: null,
  anSellCode: null,

    fi: 0,
  prc: 0,
  prcTkh: 0,
  prcHaz: 0,
  prc9: 0,
  prc10: 0,
  prc11: 0,
  darsadM: 0,


}
 

let AnSellKinds: any[] = [
  { id: -706, name: "فاکتور فروش", atfKindid: null },
  { id: 706, name: "برگشت از فروش", atfKindid: -706 },
  { id: 707, name: "ابطال فروش", atfKindid: -706 },
  

 


]

let AnDeedAfsKinds: any[] = [
  { id: 0, name: 'ندارد' },
  { id: 2, name: 'تسهیم به بطور مساوی ' },
 


]

let setmList: any[] = [
  { id: 1, name: 'نقد' },
  { id: 2, name: 'نسیه' },
  { id: 3, name: 'نقد/نسیه' }
]
//let defultAf: AnSellAf[] = [
 
//  { id: -2, isUse: true, isM: false, anSellId: 0, act: true, titC: 206, tit: 'پیش دریافت', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی ', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
//  { id: -3, isUse: true, isM: false, anSellId: 0, act: true, titC: 207, tit: 'سپرده بیمه', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
//  { id: -6, isUse: true, isM: false, anSellId: 0, act: true, titC: 208, tit: 'سپرده حسن انجام کار', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
//  { id: -7, isUse: true, isM: false, anSellId: 0, act: true, titC: 209, tit: 'مالیات تکلیفی', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
 

//]


let EmptyLastPrc: LastPrc = {

    kalaId: 0,
tafId:  null,
prcLast: 0,
prcMi: 0,
  prcTaf: 0,
  vahedId: 0,
  vat: null,
smId : null,

}




const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class AnSellService {
  baseUrl = environment.apiUrl;
  origin = location.origin;


  val = 'm222m222m';
  constructor(private http: HttpClient, private localstService: Localst) {

    console.log(this.val);

  }

  setval(any) {
    this.val = any;
    console.log(this.val);
  }

  getall(year,kind): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/getall?year=' + year.toString() + '&kind=' + kind;

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }




  getbyid(id): Observable<AnSell> {
    let apiadress = '/api/app3/Opr/AnSell/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<AnSell>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  //ورود و خروج
  add(item: AnSell): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/add';
    if (item.kind < 100)
      apiadress = '/api/app3/Opr/AnSell/addExit';

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //رسید موقت
  addAnMova(item: AnSell): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/addAnMova';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  //درخواست کالا
  addDarkhast(item: AnSell): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/addDarkhast';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  getMabnaRadionData(kindId): any[] {

    var data: any[] = [];
    data.push({ id: 0, name: 'بدون مبنا' });
    var mb = this.getMabnaAtf(kindId);
    if (mb.id != null) {
      data.push({ id: mb.id, name: mb.name });
    }

    return data;
  }
  getMabnaAnDeedRadionData(kindId): any[] {

    var data: any[] = [];
    if (kindId != -701) {
      data.push({ id: 0, name: 'خودکار' });
      data.push({ id: -106, name: 'بر مبنا تحویل فروش انبار' });

    }
    else {

    }
    

    return data;
  }

  getAnSellEmpty(kindId): AnSell {


    var a = new AnSell();

    var mb = this.getMabnaAtf(kindId);
    a.kindAtfId = mb.id;
    a.kindAtfN = mb.name;
    a.kind = kindId;
   
   
   
   // a.afs = JSON.parse(JSON.stringify(defultAf)) as AnSellAf[];
    return  a;

  }
  //با توجه به نوع فرم عطف متناسب با ان انتخاب شده و در
  //radionbox
  //اضافه میشود و اگر عطفی نداشت فقط گزینه بدون مبنا باقی می ماند و چیزی اضافه نمیشود
  private getMabnaAtf(kindId): any{

    switch (kindId) {
    
      case 706: {
        return { id: -706, name: 'بر مبنا فروش' }
      }
      case 707: {
        return { id: -706, name: 'بر مبنا فروش' }
      }

      default: {
        return { id: null, name: null };
      }

    }

  
   




  }


  AfsKindsList(): any[] {


    return AnDeedAfsKinds;

  }

  getAnSellRowEmpty(): AnSellRow {


    return new AnSellRow();

  }

 

 
    //ورود و خروج
  update(item: AnSell): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/update';
    if (item.kind < 0)
       apiadress = '/api/app3/Opr/AnSell/updateExit';

    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //رسید موقت
  updateAnMova(item: AnSell, ctrlMode): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/updateAnMova?ctrlMode=' + ctrlMode;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //درخواست کالا
  updateDarkhast(item: AnSell, ctrlMode): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/updateDarkhast?ctrlMode=' + ctrlMode;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(AnSellId: number): Observable<ApiRet> {

    let apiadress = '/api/app3/Opr/AnSell/delete?id=' + AnSellId.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
     
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/app3/Opr/AnSell/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  getvaz(any): any {
    let apiadress = '/api/app3/Opr/AnSell/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }




  updatevaz(ids: number[], vc: number) {

    let apiadress = '/api/app3/Opr/AnSell/setvaz?vc=' + vc.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post(this.origin + apiadress, ids, { 'headers': headers });

  }



  validationCode(text, id, dataA: string, kind, year): Promise<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/validationCode?text=' + text + '&id=' + id + '&dataA=' + dataA + '&kind=' + kind + '&year=' + year;
    var headers = this.localstService.header_get(apiadress, null);
    

    //  return this.http.get<any>(this.origin + apiadress, { 'headers': headers });

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).toPromise();





  }

  nextCode(year:number, kind : number): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/nextCode?year=' + year +'&kind=' + kind;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getAnSellbyshift(id: number, shift: number): Observable<ApiRet> {

    let apiadress = '/api/app3/Opr/AnSell/getAnSellbyshift?id=' + id + '&shift=' + shift;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }
  calcPrc(item: AnSell): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/calcPrc';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  getAnSellBar(AnSellId): Observable<ApiRet> {

    let apiadress = '/api/app3/Opr/AnSell/getAnSellBar?AnSellId=' + AnSellId;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }


  getMabnaForRow(anbarId, TafId, date, year,mabna): Observable<ApiRet> {

    let apiadress = '/api/app3/Opr/AnSell/getMabnaForRow?anbarId=' + anbarId + '&TafId=' + TafId + '&date=' + date + '&year=' + year + '&mabna=' + mabna;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }
 
  getAnSellRowForMabnaEmpty(): AnSellRowForMabna {


    return JSON.parse(JSON.stringify(EmptyAnSellRowFroMabna));

  }

  getLastPrcEmpty(): LastPrc {


    return JSON.parse(JSON.stringify(EmptyLastPrc));

  }



  getLastPrc(kalaId : number,tafId : number|null): Observable<LastPrc[]> {

    let apiadress = '/api/app3/Opr/AnSell/getLastPrc?kalaId=' + kalaId.toString() + '&tafId=' + tafId;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<LastPrc[]>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
  
  }


  GetForEbtal(ansellId: number): Observable<ApiRet> {
    let apiadress = '/api/app3/Opr/AnSell/GetForEbtal?ansellId=' + ansellId.toString();;

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getsetmlist(): any[] {


    return JSON.parse(JSON.stringify(setmList));

  }
  getkindList(): any {


    return AnSellKinds;

  }

  SortCodes(years, kind, cstart): Observable<ApiRet> {
    let apiadress = '/api/App3/Opr/AnSell/sortCode?years=' + years.toString() + '&kind=' + kind + '&c=' + cstart;

    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
