import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { Sanad } from '../../../../_models/app1/opr/sanad';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
 
import { Localst } from '../../../../_services/localst.service';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { LoadingService } from '../../../../_services/loading.service';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { KalaForList } from '../../../../_models/app2/paye/Kala';
import { ApiRet } from '../../../../_models/General/ApiRet';

@Component({
  selector: 'app2-Kala-merge',
  templateUrl: './Kala-merge.component.html',
  styleUrls: ['./Kala-merge.component.css']
})
export class KalamergeComponent implements OnInit {
  
 
  @Input() option: any;

 

  @Output() OnMySaveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnMyCancelEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
 
 
  myModel: any;
  loaddata: loadingCl;
 
  constructor(private userService: UserService, private alertify: AlertifyService,
    public myTimeService: MyTimeService,
    public dialog: MatDialog,
    private localst: Localst,
    private loadServ: LoadingService,
    private kalaService: KalaService,

  ) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 8);
 
    this.myModel = {
          toId :null,
             removeIds :[] }
  }

  modlelist: KalaForList[] =[];
  modlelistSelecteds: KalaForList[]=[];

  ngOnInit() {
  


    this.loadServ.showloading(this.loaddata, 0);
    this.kalaService.getallForlist()
      .subscribe((res: KalaForList[]) => {
        this.modlelist = res;
        console.log('reflesh: ' + this.modlelist.length.toString());
        this.loadServ.hideloading(this.loaddata, 0);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
      });


  }
 
  onSelectionChangedGrid(e) {

 
    this.modlelistSelecteds = [];


    for (var r of e.selectedRowsData) {

      this.modlelistSelecteds.push(r as KalaForList);


    }



  }
  onClickClose() {
 
    this.OnMyCancelEvent.emit(true);

  }
   
  onsave() {


    

    if  (this.myModel.toId == null){
      this.alertify.warning('کالای مقصد را انتخاب کنید');
      return;
    }

    this.myModel.removeIds = [];
    for (var r of this.modlelistSelecteds) {
      this.myModel.removeIds.push(r.id);
    }


    if (this.myModel.removeIds.length == 0) {
      this.alertify.warning('کالایی انتخاب نشده');
      return;
    }

    




    this.loadServ.showloading(this.loaddata, 0);
    this.kalaService.MergeKala(this.myModel)
      .subscribe((res: ApiRet) => {

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.alertify.showListString(res.success);
          this.OnMySaveEvent.emit(true);

        }
  
      
      }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
        this.alertify.error(error);
      });




  }
  oncancel() {

    this.OnMyCancelEvent.emit();
  }
 

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    
  }
 
  gridBox_displayExpr(item) {
    return item && item.code + ' /' + item.name;
  }
}



