import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdMoveRep, PdMoveRepOps } from '../../../_models/app4/rep/PdMoveRep';
import { CheckSadereRep, CheckSadereOps } from '../../../_models/app4/rep/CheckSadereRep';
 
 
 


//////////////////////////////////////////////////////
let EmptyCheckSadereRep: CheckSadereRep ={
  option: null,
  rows: []
}

let EmptyCheckSadereOps : CheckSadereOps= {
  dateS: 0,
  dateS_S: null,
  dateE: 0,
dateE_S: null,
  pdBankId: null,
  prcS:  0,
  prcE: 0,
  tafId: null,
  showEnded: false,
  kindPage: 0,
  kindRow: 0,
  showRuz:false
}









const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdPayrecRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }
  

 
  ////////////////////////////////
  getCheckSadereRepEmpty(): CheckSadereRep {

    let model = JSON.parse(JSON.stringify(EmptyCheckSadereRep));
    model.option = JSON.parse(JSON.stringify(EmptyCheckSadereOps));
    return model;

  }


  GetCheckSadereRep(item: any): Observable<ApiRet> {
    let apiadress = '/api/App4/Rep/PdPayrecRep/GetCheckSadereRep';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
 


  DateRangPdPayrec(year: any): Observable<ApiRet> {
    let apiadress = '/api/App4/Rep/PdPayrecRep/DateRangPdPayrec?year=' + year.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  /////////////////////گزارش چک های دریافتی یا پرداختی
  GetPdPayrecCheckRep(item: any): Observable<ApiRet> {
    let apiadress = '/api/App4/Rep/PdPayrecRep/GetPdPayrecCheckRep';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  ///گزارش چک تضمینی
  GetPdPayrecTazminiRep(item: any): Observable<ApiRet> {
    let apiadress = '/api/App4/Rep/PdPayrecRep/GetPdPayrecTazminiRep';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


}

