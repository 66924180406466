import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User, UserforAdd, UserForTinyList } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 

import { LoadingService } from '../../../../_services/loading.service';
import { PdBank } from '../../../../_models/app4/paye/PdBank';
import { PdBankService } from '../../../../_services/app4/paye/PdBank.service';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { Localst } from '../../../../_services/localst.service';
 
@Component({
  selector: 'app4-PdBank-edit',
  templateUrl: './PdBank-edit.component.html',
  styleUrls: ['./PdBank-edit.component.css']
})
export class PdBankEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PdBank;
  myModel_old: PdBank;
 
 
  @Input() kindpage: number;
 
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
  n: number;
  isAdmin: boolean;
  hesablist: Hesab[] = [];
  userTinylist: UserForTinyList[] = [];

  tafsiliForGroupRead: TafsiliForGroupRead;

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private hesabService: HesabService,
    private pdBankService: PdBankService,
    private tafsiliService: TafsiliService,
    private localst: Localst,
  
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.pdBankService.getPdBankEmpty();
    this.tafsiliForGroupRead = this.tafsiliService.getTafsiliGroupEmpty();
 
  }

  ngOnInit() {
 


   

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.pdBankService.getPdBankEmpty();
 

      this.isnew = true;
      this.loaduserlist();
 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.pdBankService.getbyid(this.editId).subscribe(
        (data : PdBank) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          

          this.loadTafsilisGroup(this.myModel.hesabAId);
          this.loaduserlist();
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }



    this.load();



 
  

   // console.log(this.editId.toString());
  
  }
  load() {

    this.hesabService.getHesabs_treenum(3).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);

      this.hesablist = res;



    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


   
  }

  loaduserlist() {
    this.userService.GetUserForTinyListByCuHeader().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.userTinylist = res.data;

        let u = this.userTinylist.find(x => x.username == this.localst.usernow_get().username);
        if (u != null) {

          this.isAdmin = u.isAdmin;
          if (this.editId == -1) {
            this.myModel.uOwner = u.username;
            this.myModel.uOwnerF = u.name;
            this.myModel.uOwnerCu = u.customerName;
          }
     
        }
        

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }

  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    this.myModel.kind = this.kindpage;

   
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.pdBankService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
 
        this.OnMySaveEvent.emit(true);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.pdBankService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }
     
       
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }

  refreshbottum() {
    this.loadTafsilisGroup(this.myModel.hesabAId);
    this.load();
  }
 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
 

  validateNumber(e) {

    

  }

  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }


  mochangedA(e) {

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

    this.myModel.hesabAC = h.codeSum;
    this.myModel.hesabAN = h.name;

    //gfu68ui76i67
    if (e.previousValue != null) {
      this.myModel.taf4Id = null;
      this.myModel.taf4C = null;
      this.myModel.taf4N = null;
   
    }


    this.loadTafsilisGroup(h.id);


 
  }

  loadTafsilisGroup(id: number) {

    if (id == null)
      return;

    this.tafsiliService.getTafsilisGroup(id).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);

      this.tafsiliForGroupRead = res;
      this.tafsiliForGroupRead.taf4List

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }

  t4changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    if (h == null)
      return;

    this.myModel.taf4C = h.code;
    this.myModel.taf4N = h.name;
 
  }




  mochangedB(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    this.myModel.hesabBC = h.codeSum;
    this.myModel.hesabBN = h.name;
 
    this.myModel.uOwner

  }
  userchanged(e) {
    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as UserForTinyList;

    if (h == null)
      return;


    this.myModel.uOwner = h.username;
    this.myModel.uOwnerF = h.name;
    this.myModel.uOwnerCu = h.customerName;


  }


  mochangedC(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;
 
    if (h == null)
      return;

    this.myModel.hesabCC = h.codeSum;
    this.myModel.hesabCN = h.name;
  }


  mochangedD(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    this.myModel.hesabDC = h.codeSum;
    this.myModel.hesabDN = h.name;
  }

  
  
}
