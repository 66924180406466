import { IdName } from "../../General/IdName";

export interface printpage {
      
  pages: pagep[];

 HMahdude: string;
 HHesab: string;


 HDatepring: string;
  HSakhtar: string;

 HNoemandeh: string;

 
 }


export class pagep {
  
  pindex: number;
  ppage: number;
  ppages: number;
  islastpage: boolean;

  pagesum: any;
  pagesumall: any;
  mandeall: any;
  rowsp: any[];
  rowsp2: any[];
  havet1: boolean;
  havet2: boolean;
  constructor(pnn : number) {
    this.pindex = pnn;
    this.ppage = pnn +1;
    this.pindex = 9999;
    this.pagesum = null;
    this.pagesumall = null;
    this.islastpage = false;
  
    this.rowsp = [];
    this.rowsp2 = [];

    this.havet1= false;
    this.havet2= false;
  }

}


 
