import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnGar } from '../../../_models/app2/end/AnGar';
 

let EmptyAnGar: AnGar = {

  id: 0,
  anbarId: null,
  anbarC: null,
  anbarN: null,
  dateA: 0,
  dateA_S: null,
  name: null,
  num: null,
  numN: null,
  masul: null,
  vaz: 0,
  vazN: null,
  years: null,
  radif: null,
   datelast:null
};
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnGarService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(years : number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnGar/getall?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
 
  add(item: AnGar): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnGar/add';
 

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  sabt(id: number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnGar/sabt?id=' + id.toString();


    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  delete(id: number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnGar/delete?id=' + id.toString();


    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getAnGarEmpty(): AnGar {

 
    return JSON.parse(JSON.stringify(EmptyAnGar));

  }
 
  
}
