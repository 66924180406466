import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmGrs, VmGrsRow } from '../../../_models/app98/paye/VmGrs';
 
 
let EmptyVmGrs: VmGrs = {

  id: -1,
  code: null,
  name: null,
  dis: null,
  rows: []

};

let EmptyVmGrsRow: VmGrsRow = {
 
    id: 0,
    vmGrsId: 0,
    vmGrsN: null,
    cMelli:null,
    prc: 0,
    tafId: null,
    tafC: null,
    tafN: null,
    radif:0,
};
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmGrsService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(addnone:boolean): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmGrs/getall?addnone=' + addnone;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getallOnRep(addnone: boolean): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmGrs/getallOnRep?addnone=' + addnone;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmGrs/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: VmGrs) {
    let apiadress = '/api/App98/Opr/VmGrs/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getVmGrsEmpty(): VmGrs {

 
    return JSON.parse(JSON.stringify(EmptyVmGrs));

  }
  getVmGrsRowEmpty(): VmGrsRow {


    return JSON.parse(JSON.stringify(EmptyVmGrsRow));

  }

  update(item: VmGrs) {
    let apiadress = '/api/App98/Opr/VmGrs/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(VmGrsId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmGrs/delete?id=' + VmGrsId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmGrs/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
