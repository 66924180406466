import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
import { GhardeshTafsiliRep, GhardeshTafsiliRepOp } from '../../../_models/app1/rep/ghardeshTafsiliRep';
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
 
@Injectable({
  providedIn: 'root'
})
export class ReportApp1Service {
  baseUrl = environment.apiUrl;
  origin = location.origin;


   
 




  constructor(private http: HttpClient, private localstService: Localst) {
  }


 
  GhardeshTafsiliRep(option: GhardeshTafsiliRepOp): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/Report/GhardeshTafsiliRep';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<ApiRet>(this.origin + apiadress, option, { 'headers': headers } );

  }
 

}
