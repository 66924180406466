import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmsHes } from '../../../_models/app98/paye/VmsHes';
 
 
let EmptyVmsHes: VmsHes = {

  id: -1,
  hb101Id: null,
  hb101C: null,
  hb101N: null,
  hb102Id: null,
  hb102C: null,
  hb102N: null,
  hb103Id: null,
  hb102Taf4Id: null,
  hb102Taf4C: null,
  hb102Taf4N: null,
  hb103C: null,
  hb103N: null,
  hb201Id: null,
  hb201C: null,
  hb201N: null,
  hb201Taf4Id: null,
hb201Taf4C: null,
hb201Taf4N: null,
  hb202Id: null,
  hb202C: null,
  hb202N: null,
};
 
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmsHesService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmsHes/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmsHes/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  getFirst(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmsHes/getFirst';
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  getVmsHesEmpty(): VmsHes {

 
    return JSON.parse(JSON.stringify(EmptyVmsHes));

  }
 

  UpdateAndReplace(item: VmsHes): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmsHes/UpdateAndReplace';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



}
