import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdLinkBoxOp, PdLinkPost, TableName } from '../../../_models/app4/opr/PdLink';
 

const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdLinkService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getPdLinkModel(op:PdLinkBoxOp): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdLink/getPdLinkModel';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress,op ,  { 'headers': headers });
  }


  AddOrUpdateNoSide(op: PdLinkPost): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdLink/AddOrUpdateNoSide';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, op, { 'headers': headers });
  }
 
  getPdLinkBoxRow(tableName: TableName, tableId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Opr/PdLink/getPdLinkBoxRow?tableName=' + tableName.toString() + '&tableId=' + tableId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
}

