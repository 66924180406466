import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule, DxTreeListComponent, DxTreeViewComponent, DxValidationGroupComponent, DxListComponent} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { Kala } from '../../../../_models/app2/paye/Kala';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
import { VahedService } from '../../../../_services/app2/paye/Vahed.service';
import { Vahed } from '../../../../_models/app2/paye/Vahed';
import { TabagheService } from '../../../../_services/app2/paye/Tabaghe.service';
import { Tabaghe } from '../../../../_models/app2/paye/Tabaghe';
import { VahedFormul } from '../../../../_models/app2/paye/VahedFormul';
import { AnbarKala } from '../../../../_models/app2/paye/AnbarKala';
import { GrKala, GrmaskOption } from '../../../../_models/app2/paye/GrKala';
import { GrKalaService } from '../../../../_services/app2/paye/GrKala.service';
import { event } from 'devextreme/events';
import { EventHandlerVars } from '@angular/compiler/src/compiler_util/expression_converter';
import dxTreeList from 'devextreme/ui/tree_list';
import { SMoadService } from '../../../../_services/app2/paye/SMoad.service';
import DataSource from "devextreme/data/data_source";
import { SMoad } from '../../../../_models/app2/paye/SMoad';

@Component({
  selector: 'app2-Kala-edit',
  templateUrl: './Kala-edit.component.html',
  styleUrls: ['./Kala-edit.component.css']
})
export class KalaEditComponent implements OnInit {

  @ViewChild('treeView', { static: false }) treeView: DxTreeViewComponent;
  @ViewChild('treelist', { static: false }) treelist: DxTreeListComponent;


  @ViewChild('ggdfdfg', { static: false }) ggdfdfg: DxValidationGroupComponent;

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: Kala;
  myModel_old: Kala;
  GrKalalist: GrKala[]=[];
  focuslist: number =-1;
 anbarlist: Anbar[]=[];
  anbartemp: AnbarKala;
  codemask: string = '';
  maxleghcode: number = 0;
  vahedlist: Vahed[] = [];

  modlelist: GrKala[];
  vahedAlist: Vahed[] = [];
  vahedBlist: Vahed[] = [];

  popupVisible: boolean=false;
  selectedanbars: number[]=[];
  kindlist: any[];
 Kalacodelist: string[];
 tabaghelist: Tabaghe[]=[];
  grmaskOption: GrmaskOption;

  tVahedFormul: VahedFormul;

  moadlist: any = {};

  rules: any;
  sho: false;
  codeReadonly:boolean= false;
  somest: string;
  someint: number;
  closeoftersave: number;
  changnum: number;
 
  private sub: any;
  test: string;
    n: number;
 
  selectmodeList: any[];
  taxKind: any[];

  smoadKinds: any[];
  selectedsmoadKinds: any[] =[];

  loaddata: loadingCl;

  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private KalaService: KalaService,
    private anbarService: AnbarService,
    private vahedService: VahedService,
    private tabagheService: TabagheService,
   private GrKala_Service: GrKalaService,
    private sMoadService: SMoadService,
    private tafsiliService: TafsiliService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    this.anbartemp = this.anbarService.getAnbarEmptyKala();
    this.kindlist = KalaService.getKinds();
    this.myModel = this.KalaService.getKalaEmpty();
    this.myModel_old = this.KalaService.getKalaEmpty();
    this.selectmodeList = this.KalaService.getselectmodeList();
    this.taxKind = this.KalaService.gettaxKind();
    this.smoadKinds = this.sMoadService.getsmoadKinds();
    this.selectedsmoadKinds = this.smoadKinds.filter(x => x.id == 1 || x.id == 3 || x.id == 5);

    this.tVahedFormul = this.vahedService.getVahedFormulEmpty();
    this.Kalacodelist = [];
    this.grmaskOption = this.GrKala_Service.getGrmaskOptionEmpty();
    this.changnum = 0;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode);


    if (this.popupVisible) {

      if (event.keyCode === 40) {

        this.treeView.instance.focus();
      }
      if (event.keyCode === 13) {

        if (this.treeView.searchEnabled)
          this.treeView.searchEnabled = false;
        else
        this.treeView.searchEnabled = true;
    
      }

    }
    //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeSanad();
    //}




  }
  ngOnInit() {
   // this.grchanged({ value: null });
   
    this.refreshbottum();

   

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.KalaService.getKalaEmpty();
      this.myModel.selectmode = 2;
    //  this.grchanged({value:null});

      this.setanbars(true);

    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.KalaService.getbyid(this.editId).subscribe(
        (data : Kala) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));

          this.myModel.selectmode = 2;
          


          this.setanbars(false);
       //   this.setvahed(false);
 

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }
  refreshbottum() {

    this.loadServ.showloading(this.loaddata, 9);
    this.GrKala_Service.getall()
      .subscribe((res: GrKala[]) => {

        this.modlelist = [];
        this.modlelist = res;
        if (this.modlelist.length > 0)
          this.focuslist = this.modlelist[0].treeId;
        this.loadServ.hideloading(this.loaddata, 9);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 9);
        this.alertify.error(error);
      });

    this.loadServ.showloading(this.loaddata, 8);
    this.GrKala_Service.readPattern()
      .subscribe((res: GrmaskOption) => {
       
        this.grmaskOption = res;
 

        if (this.grmaskOption.kind > 1) {
          this.codeReadonly = true;
        }
        else
          this.codeReadonly = false;




        this.codemask = ''
        this.maxleghcode = this.grmaskOption.leg;
        if (this.grmaskOption.kind == 1) {
          this.codemask = ''
        }
        else {
          for (var i = 0; i < this.grmaskOption.leg; i++) {

        
              this.codemask = 'C' + this.codemask;
          

          }
        }
        //شمارنده
        if (this.grmaskOption.kind == 2 && this.editId==-1) {
          this.grchanged({ value :-1});
        }
        this.loadServ.hideloading(this.loaddata, 8);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.alertify.error(error);
      });

    this.loadServ.showloading(this.loaddata, 6);
    this.GrKala_Service.getall()
      .subscribe((res: GrKala[]) => {
        this.GrKalalist = [];
        this.GrKalalist = res;
        this.loadServ.hideloading(this.loaddata, 6);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);
      });



    this.loadServ.showloading(this.loaddata, 2);
    this.tabagheService.getall(false).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.tabaghelist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.loadServ.showloading(this.loaddata, 1);
    this.KalaService.getallCode().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.Kalacodelist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.loadServ.showloading(this.loaddata, 5);
    this.vahedService.getall().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.vahedlist = data;
      this.vahedAlist = data;
      this.vahedBlist = data;


    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
    });



    //this.loadServ.showloading(this.loaddata, 5);
    //this.sMoadService.getall().subscribe(data => {
    //  this.loadServ.hideloading(this.loaddata, 5);
 
    //  this.moadlist = new DataSource({
    //      store: data,
    //      paginate: true,
    //      pageSize: 50
    //    });


    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 5);
    //  this.alertify.error(error);
    //});




  }
  onFocusIn(e: any) {
    e.element.querySelector("input.dx-texteditor-input").select();
  }
  setanbars(isnew: boolean) {
 
    this.loadServ.showloading(this.loaddata, 0);
    this.anbarService.getall().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.anbarlist = data;
      this.selectedanbars=[] 

      if (isnew) {
        for (var i = 0; i < this.anbarlist.length; i++) {
         // this.selectedanbars.push(this.anbarlist[i].id);
        }
      }
      else {
        for (var i = 0; i < this.myModel.anbarKalas.length; i++) {
          this.selectedanbars.push(this.myModel.anbarKalas[i].anbarId);
        }

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


   


  }

  serchText: string = '';
  showTexSearch: boolean = true;
  searchSMId() {

    if (!this.showTexSearch) {
      this.showTexSearch = true;

      this.moadlist = new DataSource({
        store: [],
        paginate: true,
        pageSize: 50
      });
      return;
    }


    if (this.serchText.length < 3 || this.serchText == null) {
      this.alertify.warning('حد اقل سه کاراکتر را وارد کنید');
      return;
    }


    this.loadServ.showloading(this.loaddata, 5);
    this.sMoadService.getallSearch(this.serchText, this.selectedsmoadKinds.map(x => x.id)).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 5);

      this.moadlist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });

      this.showTexSearch = false;
    }, error => {

      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
    });




  }



  showlist() {

    if (this.myModel._IsUsed)
      return;


    this.popupVisible = true;

  }

  onItemClick(e) {
 
    var d = e.itemData as GrKala;
     console.log(d);
    this.myModel.grKalaId = d.id;
    this.popupVisible = false;

  }


  onFocusedRowChanged(e) {

  }
  onCellDblClick() {

    this.selectgr();
  }
  //onKeyDown(e) {

  //  console.log(e.event.keyCode);
  // // this.treeView.instance.focus();
  //  if (e.event.keyCode === 13) {

  //   // this.selectgr();
  //    var d = e.itemData as GrKala;
  //    // console.log(d);
  //    this.myModel.grKalaId = d.id;
  //    this.popupVisible = false;
  //  }
  //}

  selectgr() {

   // console.log(this.focuslist);
    for (var r of this.modlelist) {
      if (r.treeId == this.focuslist) {
        this.myModel.grKalaId = r.id;
        break;
      }
    }

    this.popupVisible = false;
  }

  onNodesInitialized(e) {
   // console.log(e);
  //  this.treelist.instance.focus();
   // e.component.instance.focus();
    this.treelist.instance.focus();
  }
  test3() {
    this.treelist.instance.focus();

  }
  onshown() {
   // this.treelist.instance.focus();

  }
  
   

  addthis(closeoftersave ){
    this.closeoftersave = closeoftersave;
    
  }
  cancelthis(e) {

    if (this.changnum > 0) {
      this.OnMySaveEvent.emit(true);
    }


    this.OnMyCancelEvent.emit(true);

  }

  clearGroup() {
    this.myModel.grKalaId = null;


  }
  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;


    if (this.tVahedFormul.vahedAId != null && this.tVahedFormul.vahedBId != null) {

      this.alertify.warning('فرمول واحد دوم اضافه نشده');
      return;
    }

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }
    this.getanbars();

     
    if (this.myModel.anbarKalas.length == 0) {

      this.alertify.showListString( [ 'محل انباری مشخص نشده است']);
      return;
    }

    if (this.grmaskOption.kind == 1 || this.grmaskOption.kind == 2) {

      if (this.myModel.grKalaId > 0) {
       //اگر در حالت یک یا دو الگو کالا بودیم و در حالت ویرایش به این قسمت وارد شدیم نیازی نیست مقدار 
        //ای دی گروه کالا را منفی یک کنیم چون ای دی دیفالت و تک ردیف از گروه کالا در ان قرار دارد و موقع ابدیت ارور میدهد
      }
      else {
        // منفی یک میذاریم تا ای دی ان موقع ذخیره خوانده شوداما اگر در حالت جدید بودیم یعنی مقدار ای دی اولین گردیف از گروه کالا را در متغییر نداشتیم مقدار ان را
        this.myModel.grKalaId = -1;
      }
    
    }

   // console.log(JSON.stringify(this.myModel));
 
    if (this.myModel.id == -1) {

      if (this.grmaskOption.kind == 1 || this.grmaskOption.kind == 2) {
        //موقع ذخیره منقی یک نشان دهنده اولین ردیف از گروه کالا
        //در حالت یک و دو از الگوی کدینگ کالا یک ردیف دیفالت از گروه کالا ایجاد میشود و همان برای تمام کالا ها استفاده میشود
        // منفی یک یعنی دیفالت
        this.myModel.grKalaId = -1;
      }

      this.loadServ.showloading(this.loaddata,4);
      this.KalaService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else { 
          this.changnum++;

          if (this.closeoftersave)
            this.OnMySaveEvent.emit(true);


          this.editId = -1;

          this.ngOnInit();
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
      //پاک شد
      //if (this.grmaskOption.kind == 1 || this.grmaskOption.kind == 2) {
      //  //در حالت ویرایش مقدار دیفالت گروه کالا در حالت یک و دو الگو کدینگ کالا در این متفییر است و نباید ان را تغییر داد
      //}

      this.loadServ.showloading(this.loaddata,5);
      this.KalaService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {

          this.changnum++;

          if (this.closeoftersave)
            this.OnMySaveEvent.emit(true);


          this.editId = -1;

          this.ngOnInit();
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }




   // e.preventDefault();
  }

  //گرفتن انبار های انتخاب شده
  getanbars() {
    this.myModel.anbarKalas = [];
    for (var r of this.selectedanbars) {

      for (var a of this.anbarlist) {

        if (a.id == r) {
          this.anbartemp.id = -1;
          this.anbartemp.anbarId = r;
          this.anbartemp.kalaId = null;
          this.myModel.anbarKalas.push(JSON.parse(JSON.stringify(this.anbartemp)));

        }

      }



    }


  }


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;



    if (previousValue != newValue) {
      //this.myModel.vahedFormuls = [];


    }
  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

   
    let v = e.value;

    if (this.editId != -1)
    if (this.myModel_old.code != null)
      if (this.myModel_old.code == v)
        return true;

    for (var val of this.Kalacodelist) {
      if (v == val) return false;
    }

    return true


  }
 
  


  validateNumber(e) {

    

  }

  selector: number = null;
  vahedfarichang(e, selec) {
    //console.log(e);

    if (this.myModel.vahedId == null) {
      this.alertify.alert('واحد اصلی کالا انتخاب نشده');
      this.tVahedFormul.vahedAId = null;
      this.tVahedFormul.vahedBId = null;
      console.log('cleared');
      return;

    }

    let v = e.value;

    if (v == null) {
      this.vahedAlist = [];
      this.vahedBlist = [];
      this.tVahedFormul.vahedAId = null;
      this.tVahedFormul.vahedBId = null;
      this.tVahedFormul.vahedAN = null;
      this.tVahedFormul.vahedBN = null;
      for (var r of this.vahedlist) {
          this.vahedAlist.push(r);
          this.vahedBlist.push(r);

      }
      this.selector = null;
      return;
    }



    if (selec == 1) {

      if (this.selector == 2)
        return;
      if (this.selector == null)
        this.selector = 1



      this.tVahedFormul.vahedBId = null;
      this.vahedBlist = [];
      if (this.tVahedFormul.vahedAId == this.myModel.vahedId) {

    
        for (var r of this.vahedlist) {
          if (r.id != this.myModel.vahedId) {
            this.vahedBlist.push(r);
          }
         
        } 
      }
      else {
        
        for (var r of this.vahedlist) {
          if (r.id == this.myModel.vahedId) {
            this.vahedBlist.push(r);
          }
        }
      }
    }
    else if (selec == 2) {

      if (this.selector == 1)
        return;
      if (this.selector == null)
        this.selector = 2


      this.tVahedFormul.vahedAId = null;
      this.vahedAlist = [];
      if (this.tVahedFormul.vahedBId == this.myModel.vahedId) {


        for (var r of this.vahedlist) {
          if (r.id != this.myModel.vahedId) {
            this.vahedAlist.push(r);
          }

        }
      }
      else {

        for (var r of this.vahedlist) {
          if (r.id == this.myModel.vahedId) {
            this.vahedAlist.push(r);
          }
        }
      }
    }

 


  }
  onAddvahedfari() {
    let m = [];
    if (this.tVahedFormul.vahedAId == null) {
      m.push( 'واحد اول انتخاب نشده');
    }
    if (this.tVahedFormul.vahedBId == null) {
      m.push( 'واحد دوم انتخاب نشده');
    }
    if (this.tVahedFormul.zShow == null) {
      m.push('ظریب تبدیل خالی است');
    }
    if (m.length != 0) {
      this.alertify.showListString(m);
      return;
    }
    for (var r of this.vahedlist) {
      if (r.id == this.tVahedFormul.vahedAId)
        this.tVahedFormul.vahedAN = r.name;
      if (r.id == this.tVahedFormul.vahedBId)
        this.tVahedFormul.vahedBN = r.name;
    }
 
    this.myModel.vahedFormuls.push(JSON.parse(JSON.stringify(this.tVahedFormul)));

    this.tVahedFormul.vahedAId = null;
    this.tVahedFormul.vahedBId = null;
    this.tVahedFormul.zShow = 1;



  }

  grchanged(e) {

    let value = e.value;
    if (value == null) value = -1;

    if ( !this.isnew && e.value == this.myModel_old.grKalaId) {

        this.myModel.code = this.myModel_old.code

     
    }

    else {
      this.loadServ.showloading(this.loaddata, 7);



      this.GrKala_Service.nextcode(value)
        .subscribe((res: any) => {

          this.myModel.code = res.value;
          this.loadServ.hideloading(this.loaddata, 7);

        }, error => {
          this.loadServ.hideloading(this.loaddata, 7);
          this.alertify.error(error);
        });

    }
  

  }

  onItemDeleting(e) {

    if (e.itemData._IsUsed) {
      e.cancel = true;
      this.alertify.message('این فرمول مورد استفاده قرار گرفته');
       
    }
     
    else
      e.cancel = false;
  }

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    
  }
 
  smoadchanged(e) {

   var ee= e.component as DxSelectBoxComponent;
    //yiutyityu66
    if (e.value == undefined || e.value == null) {

     
      //this.myModel.smId = null;
      //this.myModel.sn = null;
      //this.myModel.tp = null;
      //this.myModel.taxId = null;
      //this.myModel.tax = null;
      //this.myModel.vat = null;
 
      return;
    }
     

    let key = e.value;
    ///tyuty6868688686

    for (var r of this.moadlist._store._array) {

      if (r.smId == key) {

        var temp = r as SMoad;

        this.myModel.smId = temp.smId;
        this.myModel.sn = temp.sn;
        this.myModel.tp = temp.tp;
        this.myModel.taxId = temp.taxId;
        this.myModel.tax = temp.tax;
        this.myModel.vat = temp.vat;
      
        break;
      }

    }

 

  }


  smReadonly: boolean = false;
  fld23: boolean = true;


  onValueChangeSM(e) {



    if (e.value == 1) {
      this.smReadonly = true;
    }
    else if (e.value == 2) {

      this.smReadonly = false;


    }


    if (this.editId > 0 && this.fld23) {
      this.fld23 = false;
      return;

    } else {
      this.myModel.smId = null;
      this.myModel.sn = null;
      this.myModel.tp = null;
      this.myModel.taxId = null;
      this.myModel.tax = null;
      this.myModel.vat = null;

    }

  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  
}
