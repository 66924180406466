import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { MatDialog } from '@angular/material';
 
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxDropDownBoxComponent, DxListComponent } from 'devextreme-angular';
import { MorurOption } from '../../../_models/app1/rep/Morur';
import { SaleMaliService } from '../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../_models/General/saleMali';
import { MorurService } from '../../../_services/app1/rep/Morur.service';
import { SanadService } from '../../../_services/app1/opr/Sanad.service';
import { IdName } from '../../../_models/General/IdName';
import { Localst } from '../../../_services/localst.service';
import { parse } from 'url';
import { MyTimeService } from '../../../_services/myTime.service';
import { LoadingService } from '../../../_services/loading.service';
import { HesabService } from '../../../_services/app1/paye/Hesab.service';
 
 
 
export interface DialogData {
  animal: string;
  name: string;
}
 
@Component({
  selector: 'app-RepFilter-box',
  templateUrl: './RepFilter-box.component.html',
  styleUrls: ['./RepFilter-box.component.css']
})
  
  
export class RepFilterComponent implements OnInit {

  openprint: boolean;
  @Output() OnRizGhardesh = new EventEmitter<MorurOption>();
  @Output() Ondofilterclick = new EventEmitter<MorurOption>();
  @Output() OnKindChanged = new EventEmitter<string>();
  @Input() morurOption: MorurOption;
  @Input() showKindSelect: boolean;
  kindlist: any[];


  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent; 
  @ViewChild("dxliststr2", { static: false }) dxliststr2: DxListComponent; 
  @ViewChild("dropddd", { static: false }) dropddd: DxDropDownBoxComponent;
  //@ViewChild(DxListComponent, { static: false }) dxliststr;
  openedit: boolean;
  openeditId: number;
 
  ttt: number[];

  isfirstload: boolean;
  //فیلتر ها
  salemalilist: SaleMali[];
  salemalistart: SaleMali;
  salemaliend: SaleMali;
  salemaliMax: number;
  salemaliMin: number;



  sanadkind: IdName[];
 
  hesabkind: IdName[];
  sakhtarlist: IdName[];
  kindMandehlist: IdName[];

  loaddata: loadingCl;
  constructor(
    private saleMaliService: SaleMaliService,
    private morurservice: MorurService,
    private sanadService: SanadService,
    private hesabService: HesabService,
    private localst: Localst,
    private loadServ: LoadingService,
    public dialog: MatDialog) {
    this.kindlist = [
      { id: 'taraz', name: 'گزارش تراز' },
      { id: 'daftar', name: 'گزارش دفتر' }];

  
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 6);
   // this.kindlist.push({ id: 'morur', name: 'گزارش مرور حساب ها' });
   
    //this.kind = 'morur';
    this.openprint = false;
    this.salemaliMax=0;
    this.salemaliMin=9999;
    this.isfirstload = true;

    if (this.morurOption == null)
      this.morurOption = morurservice.getemptyoption();

    this.sakhtarlist = morurservice.getSakhtar();
    this.kindMandehlist = morurservice.getkindMandehlist();


    this.salemalistart = this.saleMaliService.getSaleMaliEmpty();
    this.salemaliend = this.saleMaliService.getSaleMaliEmpty();
    this.sanadkind = this.sanadService.getKinds(); 
    this.hesabkind = this.hesabService.gethesabKinds();

  


    console.log(this.morurOption.kindReport);
    //this.morurOption.yearS = 1399;
    //this.morurOption.yearE = 1399;
    this.ttt = []; 

    this.ttt.push(2);
  //  اگر ورودی در کامپونن نبود همه نوع ها را انتخاب میکنیم
    this.morurOption.KindSanad = [];
    for (var i=0; i < this.sanadkind.length; i++) {

       
      this.morurOption.KindSanad.push(this.sanadkind[i]);
 
    }
    //
    this.morurOption.hesabKind = [];

    for (var i = 0; i < this.hesabkind.length; i++) {
      this.morurOption.hesabKind.push(this.hesabkind[i]);
    }
    
  }

  ngOnInit() {
 

    this.loadServ.showloading(this.loaddata, 0);
    //خواندن سال مالی
    this.saleMaliService.getSaleMalis().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.salemalilist = data;

    //  console.log(JSON.stringify(this.salemalilist));

      if (this.salemalilist == null)
        this.salemalilist = [];

      for (var i = 0; i < this.salemalilist.length; i++) {

        //  console.log(this.salemalilist[i].codeSal);
        if (this.salemalilist[i].codeSal > this.salemaliMax) {
          this.salemaliMax = this.salemalilist[i].codeSal;

        }
        if (this.salemalilist[i].codeSal < this.salemaliMin) {

          this.salemaliMin = this.salemalilist[i].codeSal;

        }

      }

      this.morurOption.yearS = this.localst.yearnow_get();
      this.morurOption.yearE = this.localst.yearnow_get();

   


    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      console.log("myerror:" + error)
    });

 

  }
 
 
  onValueChangedStartYear(e) {


    if (e.value == null) return;

    let newval = e.value;


    for (var i = 0; i < this.salemalilist.length; i++) {

      //  console.log(this.salemalilist[i].codeSal);
      if (this.salemalilist[i].codeSal == newval) {
        this.salemalistart = this.salemalilist[i];

      }


    }


    if (this.morurOption.yearE < this.morurOption.yearS)
      this.morurOption.yearE = this.morurOption.yearS;



    this.morurOption.dateS = this.salemalistart.dateS_s;
    this.focusfilter = "DateS";
    
  


  }


  onValueChangedEndYear(e) {


    if (e.value == null) return;

    let newval = e.value;



     

    for (var i = 0; i < this.salemalilist.length; i++) {

      //console.log(this.salemalilist[i].codeSal);
      if (this.salemalilist[i].codeSal == newval) {
        this.salemaliend = this.salemalilist[i];

      
      }

       
    }


    if (this.morurOption.yearS > this.morurOption.yearE)
    this.morurOption.yearS = this.morurOption.yearE;

 
    
    this.morurOption.dateE = this.salemalistart.dateE_s;
    this.focusfilter = "DateE";
  }


 


  loadMorurSub() {


 

  }





  datestartchang(e) {

    if (this.focusfilter != 'DateS')
      return;

    let nu = 0;
    this.morurOption.dateS = e.value.toString();
    this.loadServ.showloading(this.loaddata, 1);
    this.sanadService.getFirstsanad(this.morurOption.yearS, this.morurOption.dateS).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
        nu = data;
      if (nu != null)
       this.morurOption.cShomareS = nu; 

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      console.log("myerror:" + error)
    });

 
   
  }


  dateendchang(e) {

    if (this.focusfilter != 'DateE')
      return;

    let nu = 0;
   // console.log(e.value);

    this.morurOption.dateE = e.value.toString();
    this.sanadService.getLastsanad(this.morurOption.yearE, this.morurOption.dateE).subscribe(data => {

      nu = data;
      if (nu != null)
      this.morurOption.cShomareE = nu;

      //  برای رفرش تاریخ پایان بعد از گرفتن کد پایان فقط برای اولین بار
      if (this.isfirstload) {
        this.isfirstload = false;
        this.loadServ.showloading(this.loaddata, 2);
        this.sanadService.getLastDate(this.morurOption.yearE, this.morurOption.cShomareE).subscribe(data => {

          nu = data;
          console.log(nu);
          this.loadServ.hideloading(this.loaddata, 2);
          if (nu != null) {
            this.morurOption.dateE = nu.toString();
          }
        }, error => {
          this.loadServ.hideloading(this.loaddata, 2);
          console.log("myerror:" + error)
        });
      }
      ///

    });

  }
  CShomareSchang(e) {

  //  console.log(e.value + this.focusfilter);

    if (this.focusfilter != 'CShomareS')
    return;


  let nu = 0;
 // console.log(e.value);

    this.morurOption.cShomareS = e.value;
    this.loadServ.showloading(this.loaddata, 3);
    this.sanadService.getFirstDate(this.morurOption.yearS, this.morurOption.cShomareS).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 3);
    nu = data;
    if (nu!= null)
    this.morurOption.dateS = nu.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      console.log("myerror:" + error);
    });

  }
  CShomareEchang(e) {

    console.log('ppp : '+e.value);

    if (this.focusfilter != 'CShomareE')
      return;

    let nu = 0;
   // console.log(e.value);

    this.morurOption.cShomareE = e.value;
    this.sanadService.getLastDate(this.morurOption.yearE, this.morurOption.cShomareE).subscribe(data => {
 
      nu = data;
      console.log(nu);
      if (nu != null) {
        this.morurOption.dateE = nu.toString();
      }
    });

  }

  selectedkindchang() {







  }
  onSelectionChanged(e) {


    if (this.firstclreate)
      return;

    var keys = this.dxliststr.selectedItemKeys;
    this.morurOption.KindSanad = [];
    //console.log('oooooooooooop');
    //console.log(JSON.stringify(keys));
    if (keys!= null)
      for (var j = 0; j < keys.length; j++) {

        this.morurOption.KindSanad.push(keys[j]);
      }
  }
  onSelectionChanged2(e) {


    if (this.firstclreate2)
      return;

    var keys = this.dxliststr2.selectedItemKeys;
    this.morurOption.hesabKind = [];
    //console.log('oooooooooooop');
    //console.log(JSON.stringify(keys));
    if (keys != null)
      for (var j = 0; j < keys.length; j++) {

        this.morurOption.hesabKind.push(keys[j]);
      }
  }



  onContentReady(e) {

    console.log('contant ready');

  }
  onContentReady2(e) {

    console.log('contant ready');

  }
  cccc() {
    this.dropddd.instance.open();
    this.dropddd.instance.close();
    //this.dxliststr.instance.selectItem(1);


  }

  firstclreate = true;
  firstclreate2 = true;

  dropopened() {



    console.log('opened');
    if (this.firstclreate) { 
      this.dxliststr.instance.selectAll();

      this.firstclreate = false;

      }
  }

  dropopened2() {

    if (this.firstclreate2) {
      this.dxliststr2.instance.selectAll();

      this.firstclreate2 = false;

    }
  }
  dofilter() {
   // console.log(JSON.stringify(this.morurOption))
    this.Ondofilterclick.emit(this.morurOption);
  }


  focusfilter: string;

  onFocusInDateS(e) {

    this.focusfilter = "DateS";
  }
  onFocusInDateE(e) {

    this.focusfilter = "DateE";
  }
  onFocusInCShomareS(e) {

    this.focusfilter = "CShomareS";
  }
  onFocusInCShomareE(e) {

    this.focusfilter = "CShomareE";
  }


  onOpenedkind(e) {



    e.component._popup.option('height', 80);
  }

  kindreport(e) {

 ////jgjhghkgyhkg
   // console.log(e.value);
    this.OnKindChanged.emit(e.value);

  }
}


