import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Sanad } from '../../../_models/app1/opr/Sanad';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { of } from 'rxjs';
import { IdName } from '../../../_models/General/IdName';
import { Localst } from '../../localst.service';
import { bastanOp } from '../../../_models/app1/opr/autoSanad/bastanOp';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptybastanOp: bastanOp = {


  formulSid: null,
  dateSanad: null,
  year: null,
  hesabIds: [],
  sanadId: null,
  dis: 'بابت بستن حساب های موقت',


};
 
 



 
@Injectable({
  providedIn: 'root'
})
export class AutoSanadService {
  origin = location.origin;
 

  constructor(private http: HttpClient, private localstService: Localst) {



  }
  


  getEmptybastanOp(): bastanOp {


    return JSON.parse(JSON.stringify(EmptybastanOp));

  }
  getHesabs_ForBastan(years: number): Observable<any[]> {
    let apiadress = '/api/App1/Opr/AutoSanad/GetHesabForBastan?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<any[]>(this.origin + apiadress, { 'headers': headers });
  }
  CreateBastan(data: bastanOp) {
    let apiadress = '/api/App1/Opr/AutoSanad/createbastan';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.post(this.origin + apiadress, data, { 'headers': headers });
  }

  createbastandaermi(years: number, date: string): Observable<number> {
    let apiadress = '/api/App1/Opr/AutoSanad/createbastandaermi?years=' + years.toString()+ '&date='+date;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  createstartefteteh(years: number, date: string): Observable<number> {
    let apiadress = '/api/App1/Opr/AutoSanad/createstartefteteh?years=' + years.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
  createstarttadil(years: number, date: string): Observable<number> {
    let apiadress = '/api/App1/Opr/AutoSanad/createstarttadil?years=' + years.toString() + '&date=' + date;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<number>(this.origin + apiadress, { 'headers': headers });
  }
}
