import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { VmTas } from '../../../../_models/app98/opr/VmTas';
import { VmTasService } from '../../../../_services/app98/opr/VmTas.service';
import { Localst } from '../../../../_services/localst.service';
 
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app98-VmTas-list',
  templateUrl: './VmTas-list.component.html',
  styleUrls: ['./VmTas-list.component.css']
})


export class VmTasListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;
 
 
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Input() kind: number;
  @Input() tabindex: number;
  modlelist: VmTas[];
 
 
 
  selectedrow: any;
  //animal: string;
  //name: string;
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private VmTasService: VmTasService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
    this.openeditId = -1;
    this.loadServ.create(this.loaddata,8);
    this.modlelist = [];
 
  }


 

  mytabindex: number = -1;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    this.isdelete = "no";;
    this.load();

  }
 
  ngOnChanges(changes: any) {

    if (this.gridContainer != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.gridContainer.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.gridContainer.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.gridContainer.instance.getCellElement(index, 0);
          var y = this.gridContainer.instance.getScrollable();
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.gridContainer.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }
  }

  
  load() {


    this.loadServ.showloading(this.loaddata,0);
    this.VmTasService.getall()
      .subscribe((res) => {
        this.modlelist = [];
        this.modlelist = res.data;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

 

  }
 
 
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

   // console.log(JSON.stringify(this.selectedrow));

  }
 


  onClickDelete() {

    if (this.loaddata.showed)
      return;

   
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
     
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {


    var keyss = this.gridContainer.selectedRowKeys;

    if (keyss == null)
      return;

    if (keyss.length == 0)
      return;



    this.loadServ.showloading(this.loaddata, 1);
    this.VmTasService.deletes(keyss).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
      else {
        this.alertify.success(res.mes);
        this.load();

      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);

    });


  }

  





  onClickEdit() {

    if (this.loaddata.showed)
      return;
    if (this.selectedrow.id == null) {



      return;
    }



    this.openeditId = this.selectedrow.id;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.load();

  }
  onClickExportexcel() {

 

    this.excelService.exportAsExcelFile(this.modlelist, 'لیست ');
  }

  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

 
  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }



  gwname = 'gw9802';

  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.gridContainer.instance.columnCount(); i++) {
      var w = this.gridContainer.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli[this.gwname] = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli[this.gwname].length; i++) {
      this.localst.optioncli[this.gwname][i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli[this.gwname] = this.localst.optionClient_readDefault(this.gwname);
  }


}
