import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';
import { Sanad } from '../../../../_models/app1/opr/sanad';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { SanadRepOption } from '../../../../_models/app1/opr/sanadrep';
import { Localst } from '../../../../_services/localst.service';

@Component({
  selector: 'app-Sanad-rep',
  templateUrl: './Sanad-rep.component.html',
  styleUrls: ['./Sanad-rep.component.css']
})
export class SanadrepComponent implements OnInit {
  
 
  @Input() cshomareOne: number = -1;

 

  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();



  @Input() set printnum(valu: number) {
    if (valu) {
      this.option.cShS = valu;
      this.option.cShE = valu;
    }
 
  }

 

  option: SanadRepOption;
  lv2: boolean;
  lv3: boolean;
  lv4: boolean;
  lv5: boolean;
  lv6: boolean;

  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private localst: Localst,
  ) {

    let y = localst.yearnow_get();

    this.option = { year: y, levelDetail: 6, cShS: 1, cShE: 1 };

   
  

  }
 
  ngOnInit() {
    if (this.option.levelDetail == 2) {
      this.lv2 = true;
      this.lv3 = false;
      this.lv4 = false;
      this.lv5 = false;
      this.lv6 = false;
    }
    else if (this.option.levelDetail == 3) {
      this.lv2 = true;
      this.lv3 = true;
      this.lv4 = false;
      this.lv5 = false;
      this.lv6 = false;
    }
    else if (this.option.levelDetail == 4) {

      this.lv2 = true;
      this.lv3 = true;
      this.lv4 = true;
      this.lv5 = false;
      this.lv6 = false;
    }
    else if (this.option.levelDetail == 5) {
      this.lv2 = true;
      this.lv3 = true;
      this.lv4 = true;
      this.lv5 = true;
      this.lv6 = false;
    }
    else if (this.option.levelDetail == 6) {
      this.lv2 = true;
      this.lv3 = true;
      this.lv4 = true;
      this.lv5 = true;
      this.lv6 = true;
    }
 
  }

 
  onClickClose() {
    console.log('click');
    this.onClose.emit(true);

  }
  onValueChangedop(e) {

    if (!e.value) {
  
      if (!this.lv3) {
        this.lv4 = false;
        this.lv5 = false;
        this.lv6 = false;

      }
      if (!this.lv4) {

        this.lv5 = false;
        this.lv6 = false;

      }
      if (!this.lv5) {

        this.lv6 = false;

      }
    }
    ///////////////////
    else if (e.value) {
      if (this.lv4) {

   
        this.lv3 = true;


      }
      if (this.lv5) {

        this.lv3 = true;
        this.lv4 = true;

      }
      if (this.lv6) {


        this.lv3 = true;
        this.lv4 = true;
        this.lv5 = true;

      }
    }


  }

  print() {

    if (this.lv2)
      this.option.levelDetail = 2;
    if (this.lv3)
      this.option.levelDetail = 3;
    if (this.lv4)
      this.option.levelDetail = 4;
    if (this.lv5)
      this.option.levelDetail = 5;
    if (this.lv6)
      this.option.levelDetail = 6;

    this.onPrint.emit(this.option);

    

  }
}



