import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { VmTas } from '../../../_models/app98/opr/VmTas';
 
 
 
let EmptyVmTas: VmTas = {
  id: -1,
  tafId: null,
  tafC: null,
  tafN: null,
  dateA: 13400101,
  dateA_S:'1340/01/01',

  prcDar1:0,
  prcDar3: 0,
  prcDarSum31: 0,
  prcDar2: 0,
  prcSumDar: 0,
  prcVam: 0,
  prcVamMandeh: 0,
};


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmTasService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: VmTas): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getVmTasEmpty(): VmTas {

    return JSON.parse(JSON.stringify(EmptyVmTas)) as VmTas;

  }
 
  update(item: VmTas): Observable<ApiRet>{
    let apiadress = '/api/App98/Opr/VmTas/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(VmTasId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/delete?id=' + VmTasId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }

  deletes(VmTasId: number[]): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/deletes'
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, VmTasId, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<ApiRet> {
    let apiadress = '/api/App98/Opr/VmTas/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 
 
 

}
