import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
import {  AnDeed, AnDeedAf, AnDeedRow, AnDeedRowForMabna } from '../../../../_models/app2/opr/AnDeed';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
import { FormGroup, FormBuilder, Validator } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxComponent, DxTextBoxModule, DxDataGridComponent, DxTextAreaComponent, DxNumberBoxComponent, DxListComponent, DxLookupComponent, DxValidatorComponent, DxDropDownBoxComponent} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
 
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
 

  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
 
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
 
import { MyTimeService } from '../../../../_services/myTime.service';
 
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { SaleMali } from '../../../../_models/General/saleMali';
import { GSharh } from '../../../../_models/General/GSharh';
import { GSharhService } from '../../../../_services/General/GSharh.service';
import { DxiColumnComponent, DxoGridComponent } from 'devextreme-angular/ui/nested';
import { LoadingService } from '../../../../_services/loading.service';
import { TafsiliGbydaste } from '../../../../_models/app1/paye/tafsiliGbydaste';
 
import { ExcelService } from '../../../../_services/excel.service';
 
import DataSource from "devextreme/data/data_source";
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import dxValidator from 'devextreme/ui/validator';
import { KalaForCombo } from '../../../../_models/app2/paye/Kala';
import { AnbarService } from '../../../../_services/app2/paye/Anbar.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { AnPrcBasService } from '../../../../_services/app2/glob/AnPrcBas.service';
import { DoAmel } from '../../../../_models/app2/glob/AnAmel';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';

 
const sendRequest = function (value) {
  const validEmail = 'test@dx-email.com';
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value === validEmail);
    }, 1000);
  });
};
 
 
@Component({
  selector: 'app2-AnDeedBuy-edit',
  templateUrl: './AnDeedBuy-edit.component.html',
  styleUrls: ['./AnDeedBuy-edit.component.css']

})
export class AnDeedBuyEditComponent implements OnInit {
  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) { 
    this.width = width;
  }
  lastrowidtemplate: number;
  @Input() fchanged: boolean;
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() focusedAnDeedRowId: number;
  @Input() kindpage: number;
 
  debounceTime = 500;

 

  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMySaveEvent = new EventEmitter<any>();
  @Output() OnMyRefleshEvent = new EventEmitter<boolean>();
  @Output() onfchanged: EventEmitter<any> = new EventEmitter<any>();
  isfchanged: boolean;
  //اگر -1 بود حالت دستی
  nextCode: number=0;
  isAutoCode: boolean=true;
  reloadMainList: boolean=false;
 
  title: string;
  baropen: boolean = false;
  afsopen: boolean = false;
  sanadopen: boolean = false;
  sanadkind: number = 0;

  lockTedad: boolean = false ;
  lockPrice: boolean = false ;
  lockPriceE: boolean = false ;
  lockKala: boolean = false ;
  lockHeader: boolean = false ;
 

  @Input() viewfreze: boolean;
  //
 // printoption: AnDeedRepOption;

  @Output() OnMyPrintEvent = new EventEmitter<any>();
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();


  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("editColumnkalaC", { static: false }) editColumnkalaC: DxSelectBoxComponent;
  @ViewChild("editColumnTaraf", { static: false }) editColumnTaraf: DxSelectBoxComponent;
  @ViewChild("editColumnvahed", { static: false }) editColumnvahed: DxSelectBoxComponent;
  @ViewChild("cellTempMab", { static: false }) cellTempMab: DxSelectBoxComponent;
 

  @ViewChild("distempedit", { static: false }) distempedit: DxTextAreaComponent;
  @ViewChild("distempedit2", { static: false }) distempedit2: DxLookupComponent;


  @ViewChild("discolumeditedit", { static: false }) discolumeditedit: DxiColumnComponent;

  @ViewChild("listsharh", { static: false }) listsharh: DxListComponent;
  @ViewChild("ttt", { static: false }) ttt: DxTextBoxComponent;

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;
 
 
  @ViewChild("valllC", { static: false }) valllC: DxValidatorComponent;
  @ViewChild("valan", { static: false }) valan: DxValidatorComponent;
  @ViewChild('testForm', { static: true }) testFormElement;



  @ViewChild("moientempedit", { static: false }) moientempedit: DxSelectBoxComponent;
  @ViewChild("taf4tempedit", { static: false }) taf4tempedit: DxSelectBoxComponent;
  @ViewChild("taf5tempedit", { static: false }) taf5tempedit: DxSelectBoxComponent;
  @ViewChild("taf6tempedit", { static: false }) taf6tempedit: DxSelectBoxComponent;



  @ViewChild("mabdrpdown", { static: false }) mabdrpdown: DxDropDownBoxComponent;
  @ViewChild("griddown", { static: false }) griddown: DxDataGridComponent;


  @ViewChild("numcodevalid", { static: false }) numcodevalid: DxValidatorComponent;


  @ViewChild("mabcolum", { static: false }) mabcolum:  DxiColumnComponent;

  bedhaveadad: boolean;
  focusedColumnIndex: number;
 
 
  ontopselector: string = 'ontopselector';


  mymodle: AnDeed;
  mymodle_old: AnDeed;
 
 
  sho: false;
  somest: string;
  someint: number;
  onvandata: IdName[] = [];
  AfsKindsList: any[] = [];
  l1: any[] = [];
  l2: any[] = [];
 


 
  test: string;
  n: number;
  selectedrow: AnDeedRow;
  selectedrowOld: AnDeedRow;
  firstShitedRowid: number;

  usernow: User;
  yearnow: number;
 

  valdata: DialogData;
  selecteddisindext: number;
  messagetemp: DialogData;
  
 
  
  //مقدار دیفالت مالیات از رجیستری
  maliatDfault: number;
 
 
  candatechang: boolean;
  disableghat: boolean;
  
  SaleMaliselected: SaleMali;
  ColDisSelector: number ;
  vazcolor: string;
  loaddata: loadingCl;
  isreadonly = true;
  //yadashtandnew: boolean;
  moieninchang: boolean;
  allowAdd: any = true;

  firsttimeopen: boolean;
  radDisable: boolean = false;
  anbartitle: string;


  visHesab: boolean=true;

  mabnas: any= [];

  mablist: AnDeedRowForMabna[] =[];

 
  tafMolist: any = {};
  anbarlist: any[] = [];
  kalaCombolist: any = {};

  devDataTaf4: any = {};
  devDataTaf5: any = {};
  devDataTaf6: any = {};

  Taf4bala: any = {};
 Taf5bala: any = {};
  Taf6bala: any = {};

  TafG: TafsiliForGroupRead;
  TafGbala: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];

  TafGListallCount = 0;
  tafjustdaste: TafsiliGbydaste[] = [];
  hesablist: Hesab[] = [];
  selestedkeys: number[] = [];

  //لودینک موجودی را فعال میکند
  loadingM: boolean = false;
  //دریافت مقدار مو جودی در این متغییر
  mojudiData: any = { amt: 0 };
  //ایا فرم در حالت خرو کالا قرار دارد
  isExit: boolean = false;


  lockedPrcKalaId : number[] =[]
  constructor(private userService: UserService, private alertify: AlertifyService,
    private excelService: ExcelService,
    private anDeedService: AnDeedService,
    private anRepService: AnRepService,
    private kalaService: KalaService,
    private AnAmelService: AnAmelService,
    private hesabService: HesabService,
    private myTimeService: MyTimeService,
  
    private loadServ: LoadingService,
    private localst: Localst,
    public dialog: MatDialog,
    private TafsiliService: TafsiliService,
    private anbarService: AnbarService,
    private anPrcBasService: AnPrcBasService,
    private httpClient: HttpClient) {
    this.candatechang = false;
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);

    //if (this.kindpage > 0)
    //  this.anbartitle = 'انبار تحویل گیرنده';
    //else
    //  this.anbartitle = 'انبار تحویل دهنده';




    this.mymodle = this.anDeedService.getAnDeedEmpty(this.kindpage);

 
    this.mymodle.rows = [];
/*    this.mymodle.rows.push(    this.anDeedService.getAnDeedRowEmpty());*/















    this.vazcolor = 'vaz-none';
    this.disableghat = true;
   



 
    this.ColDisSelector = 1;
    this.SaleMaliselected = localst.yearnow_Class_get();
 
 
    this.moieninchang = false;
    this.selectedrowOld = null;
    this.firstShitedRowid = null;
    this.firsttimeopen = true;
 
    //localst.optioncli.ui_AnDeed_taf6_width;
   // this.yadashtandnew = false;
 
    this.TafGbala = this.TafsiliService.getTafsiliGroupEmpty();
    this.asyncValidation = this.asyncValidation.bind(this);
  }
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);
    if (event.altKey==true) {
      if (event.keyCode == 83) {

        //if (event.shiftKey == true)
        //  this.addthis('AnDeed');
        //else
        //  this.yaddasht('yaddasht');
      }
      //n
     if (event.keyCode == 78) {
        this.ctrNthis();
        //this.ttt.instance.focus();
      }
    }

  }

 
  Testing() {

    var r = $("#disdiv");
    console.log(r);
    r.find('.dx-texteditor-input').focus();
    console.log(r.find('dx-texteditor-input'));


  }
  myloclset() {

 //   this.localst.yearnow_set(0);

  }


  mychek() {

    console.log(this.mymodle);

  }

  testb() {

 
 

  }
  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;

  } 
  ngOnInit() {

    if (this.kindpage == 150 || this.kindpage == -50 || this.kindpage == 111 || this.kindpage == -111 || this.kindpage == 107 || this.kindpage == -107) {
      this.visHesab = false;
    }
    else {
      this.visHesab = true;
    }

    if (this.kindpage > 0) {
      this.isExit = false;
     this.anbartitle = 'انبار تحویل گیرنده';

    }
    
    else {
      this.isExit = true;
      this.anbartitle = 'انبار تحویل دهنده';
    }
    
    //fghrtg6546h45
    this.isAutoCode= this.localst.GetRegistryByPath('App2-AnDeed-[Auto' + this.kindpage.toString() + ']').vBool;
    this.maliatDfault = this.localst.GetRegistryByPath('App3-AnSell-[Maliat]').vDouble;

    this.l1 = this.anDeedService.AfsKindsList();
    this.l2 = this.anDeedService.AfsKindsList2();
    this.reloadMainList = false;
    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.myoninit();
    this.refleshhesabvatafsili();

    this.selectedrow = this.anDeedService.getAnDeedRowEmpty();
 
    this.selectedrow.hesabN = '-';
    this.selectedrow.taf4N = '-';
    this.selectedrow.taf5N = '-';
    this.selectedrow.taf6N = '-';
    
    if (this.viewfreze) {


    //  this.dataGrid.editing.allowUpdating = false;
    //  this.dataGrid.editing.allowDeleting = false;
   //   this.dataGrid.editing.allowAdding = false;
      this.isreadonly = true;
    }

   // this.dataGrid.focusedRowIndex = 0;
   // this.dataGrid.selectedRowKeys = this.dataGrid.dataSource[0].id;

   // this.selectedrow = this.dataGrid.dataSource[0];
  

    //اگر سیستم مستقیم بود
    if (this.localst.GetRegistryByPath('App3-AnSell-[Direct]').vBool && this.kindpage ==-106) {
      this.allowAdd = 'mydisble';
    }
    else {
      this.allowAdd = '';
    }

  }

  async  refleshhesabvatafsili() {

    

    this.loadServ.showloading(this.loaddata, 17);

   // let a = null;
 
   // a = await this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId, 'andeed').toPromise();
    let a = await this.TafsiliService.GetTafsiliForSmallByAnFormCode(this.kindpage, true, this.editId, 'andeed').toPromise();


    this.tafMolist = new DataSource({
      store: a,
      paginate: true,
      pageSize: 50
    });




  
    this.anbarlist =await (await this.anbarService.getalltiny().toPromise()).data;

    //??????? در دکمه رفرش میزاریم
    //اگر انباری را انتخاب کرده بودیم و کالایی در همان انبار را اضافه کردیم لیست کالا بروز شود
  //  if (this.mymodle.anbarId != null)
  //    this.loadkala(this.mymodle.anbarId);


    this.loadMabna();

    this.tafjustdaste = (await this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'andeed').toPromise()).data;

 
    ////اطلاعات حسابداری
 


    
    this.hesablist = await this.hesabService.getHesabs_treenum(3).toPromise();


    
    this.TafGListall = await this.TafsiliService.getTafsilisGroupList().toPromise();
    this.TafGListallCount = this.TafGListall.length;
    this.tafg_reflesh(this.selectedrow.hesabId);



    
    this.tafjustdaste = (await this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'andeed').toPromise()).data;

    //پاک کردن کش تمام حساب های tafglist
    for (var r = 0; r < this.TafGListall.length; r++) {
      if (this.TafGListall[r].taf4List.length > 0) {
        this.TafGListall[r].taf4List = [];
        this.TafGListall[r].taf5List = [];
        this.TafGListall[r].taf6List = [];
        this.tafg_reflesh(this.TafGListall[r].hesabId);
      }
    }



    this.loadServ.hideloading(this.loaddata, 17);



  }


  bindLock() {
    this.lockTedad = false;
    this.lockPrice = false;
    this.lockPriceE = false;
 
    this.lockKala= false;
    this.lockHeader = false;



    //if (this.mymodle.vaz <= 200) {
    // // this.lockPrice = true;
    //  this.lockKala = true;
    //}




    if (this.mymodle.vaz >= 300) {
      this.lockKala = true;
      this.lockTedad = true;
      this.lockHeader = true;
      this.lockPriceE = false;
    }

    if (this.mymodle.vaz == 500) {
      this.lockKala = true;
      this.lockTedad = true;
      this.lockHeader = true;
      this.lockPrice = true;
      this.lockPriceE = false;
    }
    if (this.mymodle.sanadEId != null) {
   
      this.lockPriceE = true;
    }
    //if (this.mymodle.mabna != 0 && this.mymodle.kind != 101) {
    //  this.lockPrice = true;
    //}


    
    if(this.isExit)
      this.lockPrice = true;


    //5665654f4
    ////قفل کردن قیمت در حالت مبنا دارد
    //if (e.value != 0 && e.value != 11) {
    //  this.lockPrcOnMabna = true;
    //}
    //else if (this.kindpage == 109) {
    //  this.lockPrcOnMabna = true;
    //}
    //else {
    //  this.lockPrcOnMabna = false;
    //}

  }
  // refleshhesabvatafsili() {






  //  this.loadServ.showloading(this.loaddata, 10);
  //  this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002).toPromise().then(data => {
  //    this.loadServ.hideloading(this.loaddata, 10);



  //    this.tafMolist = new DataSource({
  //      store: data,
  //      paginate: true,
  //      pageSize: 50
  //    });

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 10);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error);
  //  });

  //  this.loadServ.showloading(this.loaddata, 12);
  //  this.anbarService.getalltiny().subscribe(res => {
  //    this.loadServ.hideloading(this.loaddata, 12);



  //    this.anbarlist = res.data;

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 10);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error);
  //  });




  //  this.loadServ.showloading(this.loaddata, 7);
  //  this.TafsiliService.getTafsiliJustGrupedByDaste().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 7);
  //    this.tafjustdaste = data;

  //    console.log('down' + this.tafjustdaste.length);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 7);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });


  //  ////اطلاعات حسابداری



  //  this.loadServ.showloading(this.loaddata, 15);
  //  this.hesabService.getHesabs_treenum(3).subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 15);
  //    this.hesablist = data;

  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 15);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });



  //  this.loadServ.showloading(this.loaddata, 16);
  //  this.TafsiliService.getTafsilisGroupList().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 16);
  //    this.TafGListall = data;
  //    this.TafGListallCount = this.TafGListall.length;

  //    this.tafg_reflesh(this.selectedrow.hesabId);

  //    console.log('Tafglistgeted!!! ' + this.TafGListallCount);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 16);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });




  //  this.loadServ.showloading(this.loaddata, 17);
  //  this.TafsiliService.getTafsiliJustGrupedByDaste().subscribe(data => {
  //    this.loadServ.hideloading(this.loaddata, 17);
  //    this.tafjustdaste = data;



  //    //پاک کردن کش تمام حساب های tafglist
  //    for (var r = 0; r < this.TafGListall.length; r++) {
  //      if (this.TafGListall[r].taf4List.length > 0) {
  //        this.TafGListall[r].taf4List = [];
  //        this.TafGListall[r].taf5List = [];
  //        this.TafGListall[r].taf6List = [];
  //        this.tafg_reflesh(this.TafGListall[r].hesabId);
  //      }
  //    }




  //    console.log('down' + this.tafjustdaste.length);
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 17);
  //    this.alertify.error(error);
  //    console.log("myerror:" + error)
  //  });




  //}

  onanbarchanged(e) {
    //لود وقی بار اول نیست
    if (e.previousValue != null) {
      this.mymodle.rows = [];
      this.pushnewrow(true);


    }
    //ghjgh4ghjfjyf
    if (e.value == null) {

      this.isreadonly = true;
     
      this.kalaCombolist = new DataSource({
        store: [],
        paginate: true,
        pageSize: 50
      });
    }
   else {
      this.isreadonly = false;
     
      this.loadkala(+e.value);
    }
    this.loadMabna();
    this.disbRadion();

  }
  onanbarBchanged(e) {
  
    this.loadMabna();
  }


  disbRadion() {

    //باید هر دوی تفصیلی و طرف مقابل مشخص باشد تا بتوان مبنا را مشخص کرد
    //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    if (this.mymodle.anbarId == null || this.mymodle.taf4Id == null  )
    this.radDisable = true;
    else
      this.radDisable = false;

    if (this.kindpage == -107 || this.kindpage == 107)
      this.radDisable = false;
  }


  loadkala(anbarId) {


    this.loadServ.showloading(this.loaddata, 11);
    this.kalaService.getallForCombo(anbarId, this.kindpage, 'andeed!'+ this.editId).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);
      
      this.kalaCombolist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });

      this.filvahedlis();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



    this.loadServ.showloading(this.loaddata, 18);
    this.anPrcBasService.getlockedkalaId(this.localst.yearnow_get(), this.mymodle.dateA_S).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 18);
      this.lockedPrcKalaId = res.data;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 18);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  //در حالت ویرایش بعد از لود لیست کالا و اقلام ورود خروج . لیست واحد های هر ردیف را باید بارگذاری کنیم
  //تا اگر روی لیست کالا های هر ردیف کلیک کردیم لیست خالی نباشد
  filvahedlis() {

 

    if (this.kalaCombolist._store == undefined )
      return;

    if (this.kalaCombolist._store._array.length == 0 ||
      this.mymodle.rows.length == 0)
      return;


    for (var r of this.mymodle.rows) {

      for (var j of this.kalaCombolist._store._array) {


        if (r.kalaId == j.id) {

        
          r.vs = j.vs;

          break;
        }

      }
    }


  }



  modelloaded: boolean = false;
  myoninit() {
  
    //این متغییر جلو پاک شدن جدول با تغییر رادیون در بار اول میگیرد
    this.flo = false;
    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.candatechang = true;
    /////////////////////////constractor/////6654//////////////////////
    this.validationCallback = this.validationCallback.bind(this);
    this.mymodle = this.anDeedService.getAnDeedEmpty(this.kindpage);
    this.mabnas = this.anDeedService.getMabnaRadionData(this.kindpage);


    this.mymodle.kind = this.kindpage;
 
    this.mymodle.rows = [];
    
 
    this.enc = 0;


     

    this.lastrowidtemplate = -2000100000;
    this.lastrowidtemplate--;
    this.selectedrow = this.anDeedService.getAnDeedRowEmpty();
    this.usernow = this.localst.usernow_get();
    this.yearnow = this.localst.yearnow_get();

  
     

    this.bedhaveadad = false;
    this.focusedColumnIndex = 2;
 
 
 
    if (this.editId == -1) {
      this.cntFirst = 2;
      this.isreadonly = true;
      console.log('new mode');

     // this.mymodle.mabna = null;
      this.mymodle = this.anDeedService.getAnDeedEmpty(this.kindpage);

 


    // this.mymodle.mabna = null;
     // this.mymodle.mabna = null;
     // this.mymodle.mabna = -102;
     // this.dataGrid.instance.refresh();

      this.mymodle.kind = this.kindpage;
      this.mymodle.years = this.localst.yearnow_get();
      this.refreshvazColor(this.mymodle.vaz);
      this.mymodle.dateA_S = this.localst.dateEmruz.dateString;
 
      this.mymodle.uSa = this.localst.usernow_get().username;
      this.mymodle.uSaF = this.localst.usernow_get().name;
      this.refleshAnDeedcods(true);
      this.pushnewrow(true);
      this.isnew = true;
      this.setCode();
      this.modelloaded = true;

      if (this.kindpage == 150 || this.kindpage == -50 || this.kindpage == 111 || this.kindpage == -111) {
        this.mymodle.taf4Id = -1;
        this.mymodle.taf4C = '-1';
        this.mymodle.taf4N = 'ندارد';
      }
      if (this.kindpage == 107 ) {
        this.mymodle.mabna = -107;
      }
      if (this.kindpage == -107) {
        this.mymodle.mabna = 0;
      }

  

      this.bindLock();

    }
    else {
     
      this.isnew = false;
      this.loadServ.showloading(this.loaddata,3);
      this.anDeedService.getbyid(this.editId).subscribe( 
   
        (data: AnDeed) => {
          this.loadServ.hideloading(this.loaddata,3);
          this.isreadonly = false;
       
          this.cntFirst = 0;

         // this.mymodle = this.anDeedService.getAnDeedEmpty(this.kindpage);


          this.mymodle = data;
       //gfth5r67u56h5
          ////اگر تایید شده بود یعنی از لحاط تعدادی بسته شده بود
          //if (this.mymodle.vaz == 300) {
          //  this.allowTedadEdit = false;
          //}
          //else {
          //  this.allowTedadEdit = true;
          //}
         
          //45764754754
          this.mymodle_old = JSON.parse(JSON.stringify(this.mymodle));
          this.mymodle.uSaE = this.localst.usernow_get().username;
          this.mymodle.uSaEF = this.localst.usernow_get().name;

          //رنگ وضعیت
          this.refreshvazColor(this.mymodle.vaz);


          //فیلتری برای جلو گیری از پاک شدن جدول هنگامی که ویرایش را باز میکنیم
          //چون هنگام لود مدل رادیون باکس عوض میشه جلو این رویداد را برای بار اول میگیریم
          this.flo=true

          this.setCode();

           
         // this.inmyintilized = false;
          this.pushnewrow(true);

        
          this.modelloaded = true;
        
      
          this.filvahedlis();

          ////قفل کردن قیمت در حالت مبنا دارد
          //if (this.mymodle.mabna != 0 && this.mymodle.mabna != 11) {
          //  this.lockPrcOnMabna = true;
          //}
          //else if (this.kindpage == 109) {
          //  this.lockPrcOnMabna = true;
          //}
          //else {
          //  this.lockPrcOnMabna = false;
          //}
          this.calcallTakhAll();
          this.bindLock();
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }


  


  }

  //گرفتن نوع اتو بودن کد و اگر بود کد بعدی
  setCode() {


    this.loadServ.showloading(this.loaddata, 13);
    this.anDeedService.nextCode(this.localst.yearnow_get(), this.kindpage).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.nextCode = res.value;

    
        if(this.isnew)
        this.mymodle.code = this.nextCode;
     
        
    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });




  }


  refleshAnDeedcods(isnew: boolean) {

   //// return;
   // console.log('reflesh');
   // var g: AnDeed = JSON.parse(JSON.stringify(this.mymodle));
   // g.rows = null;
   // if (g.dateD_S != null) {
   //   this.loadServ.showloading(this.loaddata,4);
   //   this.anDeedService.getAnDeedEmpty().subscribe(data => {
   //     this.loadServ.hideloading(this.loaddata,4);
   //     var r = data as AnDeed;

   //     //    this.mymodle.cAtf = r.cAtf;
   //     //    this.mymodle.cFare = r.cFare;
   //     //   this.mymodle.cRozane = r.cRozane;
   //     //  this.mymodle.cShomare = r.cShomare;

   //     this.mymodle.cAtf = 0;
   //     this.mymodle.cFare = 0;
   //     this.mymodle.cRozane = 0;
   //     this.mymodle.cShomare = 0;

   //     this.mymodle.date = r.date;


   //     this.mymodle.date_String = r.date_String;

   //   //  this.ttt.instance.focus(); 
   //  //   console.log(JSON.stringify(this.mymodle));
   //   }, error => {
   //       this.loadServ.hideloading(this.loaddata,4);
   //     this.alertify.error(error);
   //     console.log("myerror:" + error)
   //   });

   //  }

  }
  calculateCellValue(data) {
    return [data.kalaId, data.hesabName].join(" ");
  }
  calledsubmitvaz;

  
 
  addthis(condition: string) {
 
  
    if (this.loaddata.showed)
      return;
    this.calledsubmitvaz = this.anDeedService.getvaz(200);

    this.onFormSubmit(condition);

  }
  
  cancelthis(e) {

 
    this.onfchanged.emit(false);
    this.OnMyCancelEvent.emit(true);
    this.OnMySaveEvent.emit({ reloadlist: this.reloadMainList , closepage: false });

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
   
        this.cancelthis(e);
      });
      return;
    }
 
 

  }
  //onFormSubmit = function (e) {

  onFormSubmit(condition : string) {

  
 
    //برای رفلش مانده
    this.pushnewrow(false);

 
    this.repairlostKalaId();

    


    if (this.myvalidationchek(condition) == false) {
      this.pushnewrow(false);
      return;
    } 



      this.refreshvazColor(this.calledsubmitvaz.id);
 
 
    if (this.mymodle.id == -1) {
    
        this.loadServ.showloading(this.loaddata,6);
        this.anDeedService.addBuy(this.mymodle).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,6);
 
          if (res.errors.length > 0) {

            this.alertify.showListString(res.errors);
            return;
          }
          else {
            this.alertify.success(res.success[0]);
            this.reloadMainList = true;

            this.editId = res.data;
          //  this.myoninit();
 
            this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });
          }

   

        
       
          if (condition == 'addAndNew') {
           // this.mymodle.mabna = 0;
            this.ctrNthis(); 
             
          } 
          else { 
             
            this.myoninit(); 
          } 
        
           
           

      }, error => {
          this.loadServ.hideloading(this.loaddata,6);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,8);
      this.anDeedService.updateBuy(this.mymodle).subscribe(res => {
      
        this.loadServ.hideloading(this.loaddata,8);
  
        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.success[0]);

          this.editId = res.data;
     
        //  this.mymodle = res.data;
        }

        this.reloadMainList = true;
        //  ؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟
        this.OnMyRefleshEvent.emit(true);
        this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });

  
        if (condition == 'addAndNew') {
          this.ctrNthis(); 
      
        }
        else {
          this.myoninit();
        }

        this.loadMabna();
        //???????????در ویرایش مبنا دیگه نشه عوض کرد
        this.radDisable = true;

      }, error => {
          this.loadServ.hideloading(this.loaddata,8);
        this.alertify.error(error);
          console.log("myerror:" + error);
      });

    }

    this.isfchanged = false;
    this.onfchanged.emit(false);
    //  e.preventDefault();
  }






 

  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;


  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {

    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);


    //  this.anDeedService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");

    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });

    //console.log('sec ' + ccode);


  }
  // public  validationCallback(e: any): boolean {
  ctrNthis() {

    //با لود مدل جدید ستون مبنا مخفی نمیشد و مشکل در بروز رسانی کامپوننت بود و مجبور شدیم مستقیم ستون را مخفی کنیم
    this.mabcolum.visible = false;
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.ctrNthis();
      }); 
      return;
    }
    //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم
    
    if (this.loaddata.showed)
      return;
    //4543thrtrrt
    this.editId = -1;
   // this.mymodle.mabna = 0;
    this.myoninit();
    this.alertify.message('سند جدید');
    this.ttt.instance.focus();
  }

  validationCallback(e) {
    return true

   

  }


  validateNumber(e) {



  }
 
  dateAnDeedRow(e) {
     
    if (this.candatechang)
      return;



   

    const previousValue = e.previousValue;
    const newValue = e.value;
 
 let valid=   this.myTimeService.isvalidday(newValue);






    //if(this.isnew)
    //this.refleshAnDeedcods(false);
     
 



  }
  onFocusIn(e) {

 

  }
  onFocusIn2(e) {
  
    e.element.querySelector("input.dx-texteditor-input").select();  
  //  e.select();
  //  e.instance.select();
    //reset hide
    this.candatechang = false;

  

  }
  onFocusOut(e) {

 

  }

  onInitialized(e) {
    //   e.component.focus();  
    //  this.inputName.instance.focus();
    //  $("#ttt").focus();  


    // console.log(e.element);
    // console.log(e.instance.focus());

  }

  focusMethod = function getFocus() {
    document.getElementById("myTextField").focus();
  }


  onFocusRow(e) {




    //for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
    //  this.mymodle.rows[_i]._rowStyle = 'csnone';
    //  if (this.mymodle.rows[_i].id == e) {
    //    this.mymodle.rows[_i]._rowStyle = 'csfocus';
    //  }


    //}


  }
  deleteDone(e) {

  }










  ///////////////////////////////////table function ----------------------

 
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    } 
    let f = e.row.data;
  //  console.log(e);

    if (e.rowIndex == 2)
      this.AfsKindsList = this.l2;
    else
      this.AfsKindsList = this.l1;







    for (var _i = 0; _i < this.mymodle.rows.length; _i++) {


      if (this.mymodle.rows[_i].id == f.id) {
         
        //اگر اویل بار اولین ردیف را کلیک کردیم
        if (this.selectedrowOld == null)
          this.selectedrowOld = JSON.parse(JSON.stringify(this.mymodle.rows[_i]));
        else
          this.selectedrowOld = JSON.parse(JSON.stringify(this.selectedrow));

        this.selectedrow = this.mymodle.rows[_i];
      }
       


    }

 



    this.pushnewrow(true);


    if (e.row.data.kalaId == null) {
      this.dataGrid.instance.editRow(e.row.rowIndex);


    }
    // else this.dataGrid.instance.cancelEditData();

    this.loadmojudi(e.row.data.kalaId );
  
  }


  loadmojudi(kalaid) {
    this.mojudiData.amt = 0;
    //   this.mojudiData.
    if (this.mymodle.dateA_S != null, kalaid != null) {
      this.loadingM = true;
      this.anRepService.Mojudi(this.localst.yearnow_get(), this.mymodle.dateA_S, kalaid, this.mymodle.anbarId, this.editId).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 16);
        this.mojudiData = res.data;

        this.loadingM = false;
      }, error => {
        this.loadingM = false;
        this.alertify.error(error);
      });
    }

  }

  pushnewrow(focusnwerow : boolean) {

    this.mymodle.rows = this.mymodle.rows || [];

    if (this.mymodle.rows.length == 0) {
      let a = this.anDeedService.getAnDeedRowEmpty();
      //ای دی منفی میدهیم تا جدول قاطی نکنه
      //منفی نشاندهنده جدید بودن و نبود در دیتا بیس است
      a.id = this.lastrowidtemplate;
   
      this.mymodle.rows.push(a);
      this.refreshradifnumber();
       //حساب ردیف جدید پر بشه
      //??????????
      this.filrowheasbs(true);
      return;
    }
     

    let existnewrow = true;
    let radif = 1;
 


    if (this.mymodle.rows != null) {
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {
        radif = radif + 1;

        if (this.mymodle.rows[_i].kalaId == null) {

          existnewrow = false;
        }


      }
    }
   


  
    if (existnewrow) {
  
      var r = this.anDeedService.getAnDeedRowEmpty();
      this.lastrowidtemplate--;
      r.id = this.lastrowidtemplate;
      r.radif = radif;
      this.mymodle.rows.push(r);
    }
    //حساب ردیف جدید پر بشه
    this.filrowheasbs(true);
 
  }
  tafchanged(e) {
     
    var h = e.component.option('selectedItem') as TafsiliForSmall;
    if (h == null)
      return;

    ///tofmali
    ///تا مبنا نال نمونه
    ///مبنا در مدل خالی نمیتونیم مقدار بدیم و باید اینا باشه چون موقع لود صفحه رویداد تغییر ردیف جدول خالی میکنه
    if (this.mymodle.anDeedRowId == null)
      this.mymodle.anDeedRowId = 0;

    this.mymodle.taf4Id = h.id;
    this.mymodle.taf4C = h.code;
    this.mymodle.taf4N = h.name;

    this.disbRadion();
    this.filrowheasbs(false);

    this.loadMabna();
  }

  onRowUpdating(e) {

 


  }


  //tmptae edit
  onss(e, cellInfo) {


 
    cellInfo.setValue('123435');
  }

  datechanged() {
    this.loadMabna();

    this.numcodevalid.instance.validate();
  }
  temp_kalachanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    let IsKalaDupl = false;
    for (var r of this.anbarlist) {

      if (this.mymodle.anbarId == r.id) {

        IsKalaDupl = r.isKalaDupl;
      }

    }


    if (!IsKalaDupl)
    for (var t of this.mymodle.rows) {

      //766776568654
      if (t.kalaId == e.value && t.id != cellInfo.data.id) {
        this.alertify.message('کد کالا تکراری است');
        cellInfo.data.kalaId = null;
        cellInfo.data.kalaC = null;
        cellInfo.data.kalaN = null;

        return;
      }
    }

    this.loadmojudi(e.value);
    this.isfchangcall();

   

  

    for (var r of this.kalaCombolist._store._array){

      if (r.id == e.value) { 

        var temp = r as KalaForCombo;
        cellInfo.data.kalaId = temp.id;
        cellInfo.data.kalaC = temp.c;
        cellInfo.data.kalaN = temp.n;
        cellInfo.data.vahId = temp.vs[0].id;
        cellInfo.data.vahN = temp.vs[0].n;
        cellInfo.data.vahBId = temp.vs[0].id;
        cellInfo.data.vahBN = temp.vs[0].n;
        cellInfo.data.zMulti = temp.vs[0].z;
        cellInfo.data.vs = temp.vs;
       
        break;
      }

    }
    cellInfo.data.darsadM = this.maliatDfault;
 
  

  }

 
  temp_vahedchanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    if (this.mymodle.mabna != 0)
      return;
   
    for (var r of cellInfo.data.vs) {

      if (r.id == e.value) {
        cellInfo.data.vahBId = r.id;
        cellInfo.data.vahBN = r.n;
        cellInfo.data.zMulti = r.z;
        break;
      }
    }
    cellInfo.data.vahBId = e.value;

  }


  setbarname(anDeedRowId) {



    if (this.mymodle.barname.id != -1)
      return; 
 


    this.loadServ.showloading(this.loaddata, 16);
    this.anDeedService.getAnDeedBar(anDeedRowId).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 16);
   
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.success[0]);

        this.mymodle.barname = res.data;
        //برای کپی شدن بارنامه 
        this.mymodle.barname.id = 0;
      }
      

    }, error => {
      this.loadServ.hideloading(this.loaddata, 16);
      this.alertify.error(error);
    });







  }

 

 

  //////////////////////////////////////////////////////////////>



  onEditCanceled(e) {


    console.log('canceled....................................................');

  }

  logEvent(eventName) {
    
  }

  onSavedrow(eventName) {
 
  }
  onFocusInrowend(e) {

    // this.dataGrid.instance.saveEditData();






  }


  onValueChangedamtFaB(e, cellInfo) {
    this.isfchangcall();

    //اگر مبنا داشتیم بررسی میکنیم که مقدار زده شده بیشتر از مقدار مجاز مبنا نباشد
    if (e.value > cellInfo.data._maxAmt && this.mymodle.mabna != 0) {
      this.alertify.warning('مقدار وارد شده بزرگتر از حداکثر مجاز مبنااست');
      return;
    }
   
  
    cellInfo.data.amtFaB = (e.value).toFixed(3);

  
    cellInfo.data.prcFa = Math.round( cellInfo.data.amtFaB * cellInfo.data.fiFa);
    cellInfo.data.prcE = Math.round(cellInfo.data.amtFaB * cellInfo.data.fiE);

  }
  onValueChangedFiFa(e, cellInfo) {

    
    if (this.lockedPrcKalaId.indexOf(cellInfo.data.kalaId) > -1) {
      this.alertify.warning('قسمت ریالی این کد کالا تا این تاریخ بسته شده است');
      return;
     
    }

    this.isfchangcall();

    cellInfo.data.fiFa = e.value;

    cellInfo.data.prcFa = Math.round( cellInfo.data.amtFaB * cellInfo.data.fiFa);


  }
  onValueChangedPrcFa(e, cellInfo) {
    if (this.lockedPrcKalaId.indexOf(cellInfo.data.kalaId) > -1) {
      this.alertify.warning('قسمت ریالی این کد کالا تا این تاریخ بسته شده است');
      return;

    }

    this.isfchangcall();


    cellInfo.data.prcFa = Math.round(e.value);
    if (cellInfo.data.amtFaB > 0.00001) {
      cellInfo.data.fiFa = cellInfo.data.prcFa / cellInfo.data.amtFaB;
    }

  }
  onValueChangedPrc102(e, cellInfo) {
   
    cellInfo.data.prc102 = Math.round(e.value);
  }
  onValueChangedPrc101(e, cellInfo) {

    cellInfo.data.prc101 = Math.round(e.value);
    this.calcallTakhAll();
  }
  ///

  onValueChangedamtEB(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.amtEB = (e.value).toFixed(3);

  }
  onValueChangedFiE(e, cellInfo) {
    this.isfchangcall();



    cellInfo.data.fiE = e.value;

    cellInfo.data.prcE = Math.round( cellInfo.data.amtFaB * cellInfo.data.fiE);

  }
  onValueChangedPrcE(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prcE = Math.round(e.value);

    if (cellInfo.data.amtFaB > 0.00001) {
      cellInfo.data.fiE = cellInfo.data.prcE / cellInfo.data.amtFaB;
    }

  }
  onValueChangedPrc104(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prc104 = Math.round(e.value);

   

  }


  cellTempFi(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.fi = e.value;

  }
  cellTempPrc(e, cellInfo) {
    this.isfchangcall();


    cellInfo.data.prc = e.value;

  }

 
  onInputbes(e, cellInfo) {

    var newval = e.value;



  }

  onKeyDownGrid(e) {

  //  console.log('hhh: '+ e.event.shiftKey);
    this.mypresskey(e.event);

  }

 

  //searchedKalaid : number;
  //onSearchChangekala(searchValue: string): void {
  //  this.searchedKalaid = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  // // console.log(thenum);
  //    //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
  //  if (thenum.length >2)
  //    for (var r = 0; r < this.kalaCombolist._store._array.length; r++) {
  //      if (this.kalaCombolist._store._array[r].c == thenum) {

  //     // console.log(JSON.stringify(this.moientempedit.items[r]));
  //      //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //     // this.moientempedit.value = this.moientempedit.items[r].id;
  //        this.searchedKalaid = this.kalaCombolist._store._array[r].id;
  //      break;
  //    }

  //  }
  //}

 
  tw;
  onKeyDownkala(e) {
    //return;
    if ( e.event.key == 'Insert') {

     // this.editColumnkalaC.minSearchLength = 0;
    }
   
    //this.mypressMovekey(e.event);
  }
 
 

 
  onFocusInDis() {

    this.selecteddisindext = this.dataGrid.focusedRowIndex;
  }

  gDisRow_insert: number =-1;
  gDisColumn_insert: number = -1;;
 
  onKeyDowndis2(e) {


   
  }

  temp_Dischanged(e, cellInfo) {
    this.isfchangcall();
 
    const newValue = e.value;
     
  //  this.seleggggfhgtrthyrthyctedrow.dis = newValue;
    cellInfo.data.dis = e.value;

    cellInfo.setValue(newValue);
   // cellInfo.data.dis = e.value;


 
 

  }


  onClosedis2() {

    if (this.ColDisSelector === 2) {
      
      this.ColDisSelector = 1;
      this.discolumeditedit.editCellTemplate = 'editColumnDis';
    }
  }


  temp_DisonSelectionChanged2(e, cellInfo) {


    if (e.selectedItem == null)
      return;

    const newValue = e.selectedItem;
    if (newValue.text == null)
      return;
    //console.log(JSON.stringify(newValue));

    if (this.selectedrow.dis == null)
      this.selectedrow.dis = newValue.text;
    else
      this.selectedrow.dis += newValue.text;

    this.ColDisSelector = 1;



    const rowindex = this.dataGrid.focusedRowIndex;
    const columnindex = this.dataGrid.focusedColumnIndex;

    var navtocell = this.dataGrid.instance.getCellElement(rowindex, columnindex);
    //  var cellolv = this.dataGrid.instance.cellValue(rowindex, columnindex);
    // this.dataGrid.instance.cellValue(rowindex, columnindex, cellolv);
    this.dataGrid.instance.editCell(rowindex, columnindex);
    this.dataGrid.instance.focus(navtocell);
    this.discolumeditedit.editCellTemplate = 'editColumnDis';
  }

  onKeyDownmove(e) {

    this.mypressMovekey(e.event);
  }

  mypressMovekey(event: KeyboardEvent) {

    this.oldkey = event.key;

    //console.log(event.key);

    if (event.key == 'ArrowDown') {

      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;

      row++;
      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      // console.log('ArrowDown');

    }
    if (event.key == 'ArrowUp') {



      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;
      row--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
    }

    if (event.key == 'ArrowLeft') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 6)
        return;

      col++;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }
    if (event.key == 'ArrowRight') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col != 7)
        return;

      col--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }



  }

  OnChangeSanad(e) {

    this.myoninit();

  }
  mypresskey(event: KeyboardEvent) {

  
    this.oldkey = event.key;





    if (event.key == 'Enter') {
      this.pushnewrow(false);

      // console.log('***mypresskey***');

      //  this.dataGrid.instance.focus(navtocell);
      // this.dataGrid.instance.editCell(indexrow, indexcolum);

      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;


      //var nnn = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      //this.dataGrid.instance.focus(nnn);
      //this.dataGrid.instance.editCell(indexrow, indexcolum);

      //بدون مبنا
      if (this.mymodle.mabna == 0) {
        if (indexcolum == 0) {

          indexcolum = 1;
        }

        //kala
        else if (indexcolum == 1) {

          //if (this.searchedKalaid != -1) {
          //  //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
          //  this.editColumnkalaC.value = this.searchedKalaid;
          //  this.selectedrow.kalaId = this.searchedKalaid;

          //  //ست کردن نام گروه و ....
          //  this.setrowinfoBykala(this.searchedKalaid);
          //  this.searchedKalaid = -1;

          //}

          //2/برگشت
          if (event.shiftKey) {

            if (indexrow > 0) {
              indexrow--;
              indexcolum = 6;
            }

          }
          //2/رفت
          else {
            //this.mymodle.mabna مقتی مبنا داریم برابر یک و بدون مبنا صفر

            indexcolum = 3




          }

        }

        //نام کالا
        else if (indexcolum == 2) {



          //2/برگشت
          if (event.shiftKey) {

            indexcolum = 1;

          }
          //2/رفت
          else {
            indexcolum = 3;

          }
        }
        //تعداد
        else if (indexcolum == 3) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 1;


          }
          //2/رفت
          else {


            indexcolum = 4;
         

          }

        }
        //واحد
        else if (indexcolum == 4) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 3;


          }
          //2/رفت
          else {

            indexcolum = 5;
            if (this.isExit)
              indexcolum = 14;

          }

        }
        //مبلغ واحد
        else if (indexcolum == 5) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 3;


          }
          //2/رفت
          else {
            indexcolum = 6;
          }

        }
        //کل
        else if (indexcolum == 6) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 5;


          }
          //2/رفت
          else {




            indexcolum = 7;


          }

        }
        //کل
        else if (indexcolum == 7) {

          //2/برگشت
          if (event.shiftKey) {

            indexcolum =6;

          }
          //2/رفت
          else {
            indexcolum = 8;
          }

        }
        else if (indexcolum == 8) {

          //2/برگشت
          if (event.shiftKey) {

            indexcolum = 7;

          }
          //2/رفت
          else {
            indexcolum = 14;
          }

        }
        //توضیحات
        else if (indexcolum == 14) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 8;
          }
          //2/رفت
          else {
            

            this.repairlostKalaId();
            if (this.selectedrow.kalaId == null) {
              indexcolum = 1;

            }
            else {
      
              indexrow++;
             // this.focusedRowKey = this.dataGrid.instance.getKeyByRowIndex(indexrow);
              indexcolum = 1;

              //var navtocell = this.dataGrid.instance.getCellElement(indexrow, 3);
              //this.dataGrid.instance.focus(navtocell);
            }



          }

        }


          //معین
        else if (indexcolum == 17) {

          //2/برگشت
          if (event.shiftKey) {
             indexcolum = 16;
          }
          //2/رفت
          else {
            indexcolum = 18;
          }

        }
        //4 سطح
        else if (indexcolum == 18) {

          //2/برگشت
          if (event.shiftKey) {
            //  indexcolum = 8;
          }
          //2/رفت
          else {
            //  indexcolum = 10;
          }

        }
        //5 سطح
        else if (indexcolum == 19) {

          //2/برگشت
          if (event.shiftKey) {
            //  indexcolum = 9;
          }
          //2/رفت
          else {
            //  indexcolum = 11;
          }

        }
        //سطح 6
        else if (indexcolum == 20) {



          //2/برگشت
          if (event.shiftKey) {

          }
          //2/رفت
          else {

           
          }
        }
      }
      ////////////////////
      ///با مبنا
      //////////////////
      else  {
        if (indexcolum == 0) {

          indexcolum = 1;
        }

        //مبنا
        else if (indexcolum == 1) {

          //if (this.searchedKalaid != -1) {
          //  //اگه سلکت نکردیم و تنها ایتم سرچ شده بود
          //  this.editColumnkalaC.value = this.searchedKalaid;
          //  this.selectedrow.kalaId = this.searchedKalaid;

          //  //ست کردن نام گروه و ....
          //  this.setrowinfoBykala(this.searchedKalaid);
          //  this.searchedKalaid = -1;

          //}

          //2/برگشت
          if (event.shiftKey) {

            if (indexrow > 0) {
              indexrow--;
              indexcolum = 12;
            }
          }
          //2/رفت
          else {

            indexcolum = 5
          }

        }

        //کد کالا
        else if (indexcolum == 2|| indexcolum == 3||indexcolum == 4) {
          //2/برگشت
          if (event.shiftKey) {

            indexcolum = 1;

          }
          //2/رفت
          else {
            indexcolum = 5;

          }
        }
      
        //تعداد
        else if (indexcolum == 5) {

          //2/برگشت
          if (event.shiftKey) {

            indexcolum = 1;

          }
          //2/رفت
          else {


            indexcolum = 6;

          }

        }
        //واحد
        else if (indexcolum == 6) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 5;


          }
          //2/رفت
          else {

            indexcolum = 7;

          }

        }
        //مبلغ واحد
        else if (indexcolum == 7) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 6;


          }
          //2/رفت
          else {
            indexcolum = 13;
          }

        }
        //اصلاح تعداد
        else if (indexcolum == 8) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 7;


          }
          //2/رفت
          else {




            indexcolum = 9;


          }

        }
        else if (indexcolum == 9) {

          //2/برگشت
          if (event.shiftKey) {



            indexcolum = 8;


          }
          //2/رفت
          else {




            indexcolum = 13;


          }

        }
        //توضیحات
        else if (indexcolum == 13) {

          //2/برگشت
          if (event.shiftKey) {
            indexcolum = 6;
          }
          //2/رفت
          else {
            indexcolum = 8;

           // this.repairlostKalaId();
            if (this.selectedrow.anDeedRowId == null) {
              indexcolum = 1;
            }
            else {
              var navtocell = this.dataGrid.instance.getCellElement(indexrow, 4);
              this.dataGrid.instance.focus(navtocell);
              indexrow++;
            //  this.dataGrid.focusedRowIndex++;
              indexcolum = 5;
            }



          }

        }
        //معین
        else if (indexcolum == 8) {

          //2/برگشت
          if (event.shiftKey) {
            // indexcolum = 7;
          }
          //2/رفت
          else {
            // indexcolum = 9;
          }

        }
        //4 سطح
        else if (indexcolum == 9) {

          //2/برگشت
          if (event.shiftKey) {
            //  indexcolum = 8;
          }
          //2/رفت
          else {
            //  indexcolum = 10;
          }

        }
        //5 سطح
        else if (indexcolum == 10) {

          //2/برگشت
          if (event.shiftKey) {
            //  indexcolum = 9;
          }
          //2/رفت
          else {
            //  indexcolum = 11;
          }

        }
        //سطح 6
        else if (indexcolum == 11) {



          //2/برگشت
          if (event.shiftKey) {

          }
          //2/رفت
          else {


          }
        }
      }

     

      this.xx = indexcolum;
      this.yy = indexrow;
      var navtocell = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      this.enc++;



      this.dataGrid.instance.focus(navtocell);
      var cellolv = this.dataGrid.instance.cellValue(indexrow, indexcolum);
      // console.log('celkl value : ' +JSON.stringify(this.dataGrid.instance.cellValue(indexrow, indexcolum)));

      this.dataGrid.instance.cellValue(indexrow, indexcolum, cellolv);
      this.dataGrid.instance.editCell(indexrow, indexcolum);
      this.dataGrid.instance.focus(navtocell);




      this.oldrowindex = indexrow;
      this.oldcolumindex = indexcolum;
    }



  }





   setrowinfoBykala(moid : number){

     var selected;
    
     if (selected != null) {



    



     }
     else {

      

     }



}

 
  xx;
  yy;
  oldrowindex: number;
  oldkey: string;
  enc: number;
  oldcolumindex: number;
  testtt() {
    this.distempedit2.instance.open();

 

  }
  onFocusedCellChanged(e) {
   
   
  }
  onFocusedCellChanging(e) {

  
     
  }
  onEditingStart(e) {




  }
  mochanged(e) {

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

  //  this.mymodle.hesabC = h.codeSum; 
  //  this.mymodle.hesabN = h.name;

    this.tafg_reflesh(e.value);

    this.TafGbala = JSON.parse(JSON.stringify(this.TafG));
  
    this.Taf5bala = new DataSource({
      store: this.TafGbala.taf5List,
      paginate: true,
      pageSize: 50
    });
    this.Taf6bala = new DataSource({
      store: this.TafGbala.taf6List,
      paginate: true,
      pageSize: 50
    });
    //kklkl777
    this.filrowheasbs(false);
  }
 
  t5changed(e) {
    if (e.value == null)
      return;
     var h = e.component.option('selectedItem');
    this.mymodle.taf5C = h.code;
    this.mymodle.taf5N = h.name;
    this.filrowheasbs(false);
  }
  t6changed(e) {
    if (e.value == null)
      return;
     var h = e.component.option('selectedItem');
    this.mymodle.taf6C = h.code;
    this.mymodle.taf6N = h.name;

    this.filrowheasbs(false);

  }

  filrowheasbs(justnull: boolean) {

   // return;
    //tfryuh54657
    for (var r of this.mymodle.rows) {
      if (r.kalaId == null)
        continue;

      if (justnull && r.hesabId != null)
        continue;




      r.taf4Id = this.mymodle.taf4Id;
      r.taf4C = this.mymodle.taf4C;
      r.taf4N = this.mymodle.taf4N;


      //if (r.hesabId == null) {
        //r.hesabId = this.mymodle.hesabId;
        //r.hesabC = this.mymodle.hesabC;
        //r.hesabN = this.mymodle.hesabN;
      

        //r.taf5Id = this.mymodle.taf5Id;
        //r.taf5C = this.mymodle.taf5C;
        //r.taf5N = this.mymodle.taf5N;
      

        //r.taf6Id = this.mymodle.taf6Id;
        //r.taf6C = this.mymodle.taf6C;
        //r.taf6N = this.mymodle.taf6N;
     // }
    }


  }

  adjustWidth(e,w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {
 
   
         this.firsttimeopen = false;
   
    }
  }
 
  kaisopen: number;
  onFocuskalaCedit(e, item) {

 
     
    //if (this.editColumnkalaC.minSearchLength == 0)
    //  this.editColumnkalaC.minSearchLength = 2;

    if (this.kaisopen == null) this.kaisopen = 0;
     
    this.editColumnkalaC.instance.open();
    this.kaisopen++;
 
  }
 
 
 
 
  onFocusVahededit(e, item) {

    this.editColumnvahed.instance.open();
 

  }
  onFocusMab(e, item) {

    this.cellTempMab.instance.open();


  }
  onFocusDis2dit(e) {
  
    this.distempedit2.instance.open();
    //    e.instance.open();
  }

  testthis(e) {

    let dialogRef = this.dialog.open(DialogvalidComponent, {
      width: '250px',
      data: {}
    });

 
    dialogRef.afterClosed().subscribe(result => {

      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        console.log('dell');

      }

    });
 
  }

  onFocusInbes(e) {



  }


 
 





  onCellPrepared(e) {

    //lockTedad: boolean = false;
    //lockPrice: boolean = false;
    //lockKala: boolean = false;
    //lockHeader: boolean = false;


    if (e.rowType == 'header' )
      return;

      ////fgh547y546
      //if (this.lockTedad) {
      //  if (e.column.dataField == 'amtFaB') {

      //    e.cellElement.style.backgroundColor = '#bfbfb5';
      //  }

      //}
      //if (this.lockKala) {
      //  if (e.column.dataField == 'anDeedCode' || e.column.dataField == 'kalaC' || e.column.dataField == 'kalaN' || e.column.dataField == 'vahBN') {

      //    e.cellElement.style.backgroundColor = '#bfbfb5';
      //  }

      //}
 
   

  }
  ghfgh: boolean = false;
  onRowPrepared(e) {
   
    //  e.rowElement.style.backgroundColor = 'yellow';
    //  e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
    
  }


  myvalidationchek(condition: string): boolean {


   

    const verror = [];


    if (this.mymodle == null) 
      verror.push('model is empty');
    else {

     


      if (this.kindpage != 150 && this.kindpage != -50 && this.kindpage != 111 && this.kindpage != -111 && this.kindpage != -107 && this.kindpage != 107)
      if(!this.valllC.instance.validate().isValid) {
        verror.push('درخواست کننده خالی است');
      }

      if (!this.valan.instance.validate().isValid) {
        verror.push('طرف مقابل خالی است');

      }

      if (this.mymodle.code == null)
        verror.push(' شماره درخواست خالی است');


      let datevalid = this.myTimeService.isvalidday(this.mymodle.dateA_S);

 
     
      if (datevalid != null) {
        
        verror.push(datevalid);
      }

      var years = this.localst.yearnow_Class_get();

 
      var dajfouy = parseInt(this.mymodle.dateA_S.replace('/', '').replace('/', '').replace('/', '').toString()); 
 

      if (dajfouy > years.dateE) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }

      if (dajfouy < years.dateS) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }
 

      //if (this.mymodle.vaz > 200) {


      //  verror.push('ابتدا سند را در وضعیت موقت قرار دهید');


      //}



 
      let c = 0;
      for (var _i = 0; _i < this.mymodle.rows.length; _i++) {

        if (this.mymodle.rows[_i].kalaId == null || this.mymodle.rows[_i].kalaId == -1) {
          this.mymodle.rows.splice(_i, 1);
          continue;
        }
         


          c++;

 
       
     
      }


      if (c == 0) {

          verror.push('هیچ ردیفی ثبت نشده است');

      }






    }
   // verror.push('ارور');

    if (verror.length == 0)
      return true;

    



    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

  
 

    return false;;
  }
 
 
  dodghativaz(v : number) {

    var n = [];
    n.push(this.mymodle.id);
    let vaz = this.anDeedService.getvaz(v);

    if (vaz == null)
      return;

    this.loadServ.showloading(this.loaddata, 12);
    this.anDeedService.updateVaz(n, vaz.id).subscribe((res: ApiRet) => {

       res;
       this.loadServ.hideloading(this.loaddata,12);
   
      this.mymodle.vaz = vaz.id;
 
         //رنگ وضعیت
       this.refreshvazColor(this.mymodle.vaz);
      // this.loadAnDeed();

     }, error => {
         this.loadServ.hideloading(this.loaddata,12);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  showmessage(message: string) {


    this.messagetemp = { title: 'خطا', rows: [], valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
    this.messagetemp.rows = [message];
    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.messagetemp;
    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);
   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The message dialog was closed');
      return;
    });
    return;
 
  }

 
  onClickDeleterow() {
 
    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
   // console.log(JSON.stringify(rowkersselected));

    if (rowkersselected == null)
        rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);

    let afterindex = -1;



    if (rowkersselected != null)
    for (var i = 0; i < rowkersselected.length; i++) {

 
      for (var j = 0; j < this.mymodle.rows.length; j++) {

        if (rowkersselected[i] == this.mymodle.rows[j].id) {

         
          this.mymodle.rows.splice(j, 1);
          afterindex = j - 1;
          break;
        }
      }


    }

    //if (afterindex < this.mymodle.rows.length && afterindex >= 0)
    //  this.focusedRowKey = this.mymodle.rows[afterindex].id;
    //else if (this.mymodle.rows.length>0)
    //  this.focusedRowKey = this.mymodle.rows[0].id;

    this.refreshradifnumber();
    this.pushnewrow(false);

  }
  onClickinsertrow() {

    let AnDeedRowempty = this.anDeedService.getAnDeedRowEmpty();
    this.lastrowidtemplate--;
    AnDeedRowempty.id = this.lastrowidtemplate;
    
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {
       // console.log(JSON.stringify(AnDeedRowempty));
        this.mymodle.rows.splice(i, 0, AnDeedRowempty);
        break;
      }

    }

    this.refreshradifnumber();


  }


  onClickcopyrow() {

    let data =[];

   
    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.clipboard_set( 'AnDeedRow' , data);
    this.alertify.warning('رونوشت صورت گرفت');
    this.dataGrid.instance.clearSelection();

  }
  onClickcutrow() {
    let data = [];


    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == rowkersselected[i]) {

            data.push(this.mymodle.rows[j]);
            break;
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));
    this.localst.clipboard_set('AnDeedRow', data);
    this.alertify.warning('برش صورت گرفت');
    this.onClickDeleterow();
    this.dataGrid.instance.clearSelection();

  }
  onClickpaserow() {
    var data = this.localst.clipboard_get('AnDeedRow');
    if (data == null) {

      this.alertify.warning('رونوشتی یافت نشد');
      return;
    }
     

  
    let nextid = this.getnextrowid();

    for (var i = 0; i < this.mymodle.rows.length; i++) {
      if (this.selectedrow.id == this.mymodle.rows[i].id) {

        //ردیف مورد نظر را پیدا میکنیم

        //ردیف های کپی شده را جایگزاری میکنیم

        for (var k = 0; k < data.length; k++) {

          this.lastrowidtemplate--;
          data[k].id = this.lastrowidtemplate;

          this.mymodle.rows.splice(i, 0, data[k]);
         }
        this.alertify.warning('ردیف جایگذاری شد');
        break;
      }

    }

    this.refreshradifnumber();
 

  }
  onClickexchangRow() {

    

  }
  getnextrowid() : number {

    let nextid = -2000000011;
    for (var j = 0; j < this.mymodle.rows.length; j++) {
      if (this.mymodle.rows[j].id > nextid) {
        nextid = this.mymodle.rows[j].id;
      }
    }
    nextid++;
    return nextid;
  }
  refreshradifnumber() {

   
    let c = 0;
    for (var k = 0; k < this.mymodle.rows.length; k++) {
      c++;
      this.mymodle.rows[k].radif = c;

    }

  }

  nextAnDeed() {
  

  }




  backAnDeed() {



  }
  onClickdelete() {

   

    if (this.loaddata.showed)
      return;

    if (this.editId == -1)
      return;


    if (this.mymodle.vaz > 200)
      return;


    this.openDialog();

  


  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

    

      }

    }); 
  }

  dodelete() {


 
 
    this.loadServ.showloading(this.loaddata,16);
    this.anDeedService.delete(this.editId, null).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata,16);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
      this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });
            this.ctrNthis(); 
    }, error => {
        this.loadServ.hideloading(this.loaddata,16);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 

  }








 

  refreshvazColor(vaz: number) {

   
    //ثبت نشده
    if (vaz == -1) {

      this.vazcolor = 'vaz-none';
      //  this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;

    }

    //موقت
    else if (vaz == 200) {
      this.vazcolor = 'vaz-yellow';
      // this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;
    }
          //تایید
  
    else if (vaz == 300) {
      this.vazcolor = 'vaz-orange';
      // this.mainwarperCSS = 'UnfrzAnDeed';
      this.isreadonly = false;
    }
        //قیمت گذاری شده
    else if (vaz == 400) {

      this.vazcolor = 'vaz-green';
      // this.mainwarperCSS = 'frzAnDeed opAnDeed';
      this.isreadonly = true;
    }
      //سند حسابداری
    else if (vaz == 500) {
      this.vazcolor = 'vaz-blue';
     // this.isreadonly = false;
    }


   // this.mymodle._VazN = this.anDeedService.getvazName(this.mymodle.vaz);
 

  }
  onValueChangedGSharh(e){

  }

  onKeyDownDate(e) {


    if (e.event.key == 'Enter') {


      var cellolv = this.dataGrid.instance.cellValue(0, 1);

 
      this.dataGrid.instance.cellValue(0, 1, cellolv);
      this.dataGrid.instance.editCell(0, 1);
      this.dataGrid.instance.focus(cellolv);

    }


  }
  onClickmoveRow(step: number) {


    //حرکت روبه بالا
    if (step > 0) {

      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر اولین سطر انتخاب شده بود هیچ کاری نمیکنیم
          if (i == 0)
            return


          var tempmove = this.mymodle.rows[i-1];

          this.mymodle.rows[i - 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
     
        }
      }


    }
     //حرکت روبه پایین
    else if (step < 0) {

      let stopdown = this.mymodle.rows.length - 2;
      for (var i = 0; i < this.mymodle.rows.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.rows[i].id) {


          //اگر ردیف اخر به بعد بود هیچ کاری نمیکنیم
          if (i > stopdown)
            return


          var tempmove = this.mymodle.rows[i + 1];

          this.mymodle.rows[i + 1] = this.mymodle.rows[i];
          this.mymodle.rows[i] = tempmove;

          break;
          
        }
      }

    }
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;

    }

  }

  onClicrepedrows() {


 

    var foc = this.dataGrid.focusedRowKey;


    if (foc == null)
      return;

 
       var foradd = null;
  

        for (var j = 0; j < this.mymodle.rows.length; j++) {

          if (this.mymodle.rows[j].id == foc) {

          
            foradd = JSON.parse(JSON.stringify(this.mymodle.rows[j]));
            this.lastrowidtemplate--;
            foradd.id = this.lastrowidtemplate;
 
      
            break;
          }


        }
     


    if (foradd == null)
      return;

 


    if (this.mymodle.rows == null)
      this.mymodle.rows = [];



 




    let indexforinsert = 0;

    if (this.mymodle.rows.length > 0)
      indexforinsert = this.mymodle.rows.length - 1;


    this.mymodle.rows.splice(indexforinsert, 0, foradd);


  
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.rows.length; i++) {
      c++;

      this.mymodle.rows[i].radif = c;
   

    }



  }
  onClickPrint() {

   

    if (this.loaddata.showed)
      return;

    if (this.mymodle.id == -1) {
      this.alertify.warning('فاکتور ذخیره نشده است');
      return;
    }


    let op = { year: this.mymodle.years, codeS: this.mymodle.code, codeE: this.mymodle.code, dateS: 0, dateE: 0, dateE_S: null, dateS_S: null, kind: this.mymodle.kind };

    this.OnMyPrintEvent.emit(op);
  

  }
  refreshbottum() {

  
    if (this.loaddata.showed)
      return;


        if (this.mymodle.anbarId != null)
      this.loadkala(this.mymodle.anbarId);

    this.refleshhesabvatafsili();
  //  this.tafg_reflesh(this.selectedrow.kalaId);
   // this.selecttafg(this.selectedrow.kalaId);
  }


  repairShitRow() {

 
   //// return;


   // if (this.firstShitedRowid == null)
   //   return;

   // if (this.pv == -1)
   //   return;



   // console.log('<<<shit>>>' + this.firstShitedRowid);

   // for (var r = 0; r < this.mymodle.rows.length; r++) {
      
   //   if (this.mymodle.rows[r].id == this.firstShitedRowid) {


       

   //     let hitem = null;
   //     for (var h = 0; h < this.hesablist.length; h++) {

   //       if (this.hesablist[h].id == this.pv) {

   //         hitem = this.hesablist[h];
   //       }

   //     }

   //     if (hitem != null && hitem.codeSum != this.selectedrowOld.hesabCode) {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = hitem.codeSum;
   //       this.mymodle.rows[r].hesabName = hitem.name;


   //       this.mymodle.rows[r].groupId = null;
   //       this.mymodle.rows[r].groupCode = null;
   //       this.mymodle.rows[r].groupName = null;

   //       this.mymodle.rows[r].kolId = null;
   //       this.mymodle.rows[r].kolCode = null;
   //       this.mymodle.rows[r].kolName = null;


   //       this.mymodle.rows[r].tafsili4Id = null;
   //       this.mymodle.rows[r].tafsili4Code = null;
   //       this.mymodle.rows[r].tafsili4Name = null;

   //       this.mymodle.rows[r].tafsili5Id = null;
   //       this.mymodle.rows[r].tafsili5Code = null;
   //       this.mymodle.rows[r].tafsili5Name = null;

   //       this.mymodle.rows[r].tafsili6Id = null;
   //       this.mymodle.rows[r].tafsili6Code = null;
   //       this.mymodle.rows[r].tafsili6Name = null;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;
   //     }
   //     else {

   //       this.mymodle.rows[r].kalaId = this.pv;
   //       this.mymodle.rows[r].hesabCode = this.selectedrowOld.hesabCode;
   //       this.mymodle.rows[r].hesabName = this.selectedrowOld.hesabName;


   //       this.mymodle.rows[r].groupId = this.selectedrowOld.groupId;
   //       this.mymodle.rows[r].groupCode = this.selectedrowOld.groupCode;
   //       this.mymodle.rows[r].groupName = this.selectedrowOld.groupName;

   //       this.mymodle.rows[r].kolId = this.selectedrowOld.kolId;
   //       this.mymodle.rows[r].kolCode = this.selectedrowOld.kolCode;
   //       this.mymodle.rows[r].kolName = this.selectedrowOld.kolName;


   //       this.mymodle.rows[r].tafsili4Id = this.selectedrowOld.tafsili4Id;
   //       this.mymodle.rows[r].tafsili4Code = this.selectedrowOld.tafsili4Code;
   //       this.mymodle.rows[r].tafsili4Name = this.selectedrowOld.tafsili4Name;

   //       this.mymodle.rows[r].tafsili5Id = this.selectedrowOld.tafsili5Id;
   //       this.mymodle.rows[r].tafsili5Code = this.selectedrowOld.tafsili5Code;
   //       this.mymodle.rows[r].tafsili5Name = this.selectedrowOld.tafsili5Name;

   //       this.mymodle.rows[r].tafsili6Id = this.selectedrowOld.tafsili6Id;
   //       this.mymodle.rows[r].tafsili6Code = this.selectedrowOld.tafsili6Code;
   //       this.mymodle.rows[r].tafsili6Name = this.selectedrowOld.tafsili6Name;
   //       this.firstShitedRowid = null;
   //       this.pv = -1;
   //       break;


   //     }

       

   //      }

   //   }
   

  }


  repairlostKalaId() {
    

    for (var k of this.mymodle.rows) {

      if (k.kalaC != null && k.kalaId == null) {

        for (var r of this.kalaCombolist._store._array) {

          if (r.c == k.kalaC) {

            k.kalaId = r.id;
            break;
          }

        }
      }
    }

  }

  lastindex = -1;
  
  saveuioption() {
    //ghh5566546574h67777
  // var p = [];
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      var wjjj = this.dataGrid.instance.columnOption(i);
    //  p.push(w);
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2008 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
  addwidth(e) {
     
    for (var i = 0; i < this.localst.optioncli.gw2008.length; i++) {
      this.localst.optioncli.gw2008[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw2008 = this.localst.optionClient_readDefault('gw2008');
  }
  isfchangcall() {
    if (!this.isfchanged) {
      this.isfchanged = true;
      this.onfchanged.emit(true);
    }
  }
  confirmfchang() {

  
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        return false;
      });
    }
    else return true
  }


 
  exportexcel() {
    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' لیست خرید ');
    //let excelmodle: AnDeedRowExel[] =[] ;
   
    //for (var r = 0; r < this.mymodle.rows.length; r++) {

    //  let greeter = new AnDeedRowExel(this.mymodle.rows[r]);


    //  excelmodle.push(greeter);



    //}


    //this.excelService.exportAsExcelFile(excelmodle, 'اسناد');


  }
  AnDeedRowtemp: AnDeedRow;
  asyncValidation(params) {

    //return new Promise((resolve) => {
    //  setTimeout(() => {
    //    resolve(1 === 1);
    //  }, 1000);
    //});
   
    if (this.isAutoCode) {
       return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
    }
    //56645yhgrfyhtr
    return new Promise((resolve, reject) => {
      this.anDeedService.validationCode(params.value , this.editId , this.mymodle.dateA_S , this.kindpage , this.localst.yearnow_get())
        .then((res: ApiRet) => {
          if (res.errors.length > 0) {

            this.alertify.error(res.errors.join('\n'));
          }

         
          resolve(res.value);
        })
        .catch(error => {
          console.error("خطا ارتباط با سرور", error);
 
        });
    });

   
  }

  onClickctrl() {

  
 

  }

  barname() {

    this.baropen = !this.baropen;

  }

  afsclick() {

    this.afsopen = !this.afsopen;

    if (this.editId == -1) {
      this.alertify.warning('برای مشاهده قیمت عوامل افزاینده و کاهنده سند را ذخیره کنید');

    }
    else {
      this.calcclick();
    }


  }
   

  shiftsanad(s) {
    
    if (this.editId == -1) {

      this.alertify.warning('سندی وجود ندارد');
      return;
    }
 
    this.loadServ.showloading(this.loaddata, 14);
    this.anDeedService.getAnDeedbyshift(this.editId, s).subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 14);
   
        if (res.warnings.length > 0) {
          this.alertify.warning(res.warnings[0]);

         }

         if(res.value != null) {

          this.editId = res.value;
          //از نو بخونه بیخیال بار سرور
          this.myoninit();
        }
         
   
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 14);
        console.log(error);
        this.alertify.error(error);
      }


    );


  }


  moisopen: number;
  onFocusmoienedit(e, item) {


    if (this.moientempedit.minSearchLength == 0)
      this.moientempedit.minSearchLength = 2;

    if (this.moisopen == null) this.moisopen = 0;

    this.moientempedit.instance.open();
    this.moisopen++;
 
  }

  onFocustaf4dit(e) {
    if (this.taf4tempedit.minSearchLength == 0)
      this.taf4tempedit.minSearchLength = 3;

    this.taf4tempedit.instance.open();
    // e.instance.open();
  }
  onFocustaf5dit(e) {
    if (this.taf5tempedit.minSearchLength == 0)
      this.taf5tempedit.minSearchLength = 3;

    this.taf5tempedit.instance.open();
    // e.instance.open();
  }
  onFocustaf6dit(e) {
    if (this.taf6tempedit.minSearchLength == 0)
      this.taf6tempedit.minSearchLength = 3;

    this.taf6tempedit.instance.open();
    //    e.instance.open();
  }


  searchedmoid: number;
  onSearchChangeMo(searchValue: string): void {
    this.searchedmoid = -1;

    var thenum = searchValue.replace(/[^0-9]/g, '');

    // console.log(thenum);

    if (thenum.length > 2)
      for (var r = 0; r < this.hesablist.length; r++) {
        if (this.hesablist[r].codeSum == thenum) {

          // console.log(JSON.stringify(this.moientempedit.items[r]));
          //this.moientempedit.selectedItem = this.moientempedit.items[r];
          // this.moientempedit.value = this.moientempedit.items[r].id;
          this.searchedmoid = this.hesablist[r].id;
          break;
        }

      }
  }

  //searchedtaf4id: number;
  //onSearchChangeTaf4(searchValue: string): void {
  //  this.searchedtaf4id = -1;

  //  //if (searchValue == '   ') {
  //  //  this.taf4tempedit.minSearchLength = 0;
  //  //  this.taf4tempedit.value = '';

  //  //}

  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf4List.length; r++) {
  //      if (this.TafG.taf4List[r].code == thenum) {
  //        this.searchedtaf4id = this.TafG.taf4List[r].id;
  //        break;
  //      }

  //    }
  //}

  //searchedtaf5id: number;
  //onSearchChangeTaf5(searchValue: string): void {
  //  this.searchedtaf5id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf5List.length; r++) {
  //      if (this.TafG.taf5List[r].code == thenum) {
  //        this.searchedtaf5id = this.TafG.taf5List[r].id;
  //        break;
  //      }

  //    }
  //}

  //searchedtaf6id: number;
  //onSearchChangeTaf6(searchValue: string): void {
  //  this.searchedtaf6id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf6List.length; r++) {
  //      if (this.TafG.taf6List[r].code == thenum) {
  //        this.searchedtaf6id = this.TafG.taf6List[r].id;
  //        break;
  //      }

  //    }


  //}

  temp_taf4changed(e, cellInfo) {

    this.isfchangcall();

    var previousValue = e.previousValue;
    var newValue = e.value;
    //  console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }

    //  برای اراور خالی ماندن اولین سطر
    ////  if (newValue != null && newValue != undefined && newValue != undefined)
    //   this.searchedtaf4id = newValue;


    var selected = null;
    for (let i = 0; i < this.TafG.taf4List.length; i++) {
      if (this.TafG.taf4List[i].id == newValue) {
        selected = this.TafG.taf4List[i];
        break;
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }

    if (selected != null) {
      // cellInfo.data.tafsili4Id = selected.id;
      // cellInfo.data.tafsili4Name = selected.name; 
      this.selectedrow.taf4Id = selected.id;
      this.selectedrow.taf4N = selected.name;
      this.selectedrow.taf4C = selected.code;


      cellInfo.data.tafsili4Id = selected.id;
      cellInfo.data.tafsili4Name = selected.name;
      //  cellInfo.data.tafsili4Code = selected.code;

      cellInfo.setValue(selected.code);

    }


    if (this.TafG.have5) {




    }
    else {


    }
    // cellInfo.setValue('667567');
  }


  temp_taf5changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;

    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //    this.searchedtaf5id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == newValue) {
        selected = this.TafG.taf5List[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null) {

      this.selectedrow.taf5Id = selected.id;
      this.selectedrow.taf5N = selected.name;
      this.selectedrow.taf5C = selected.code;
      cellInfo.setValue(selected.code);

    }

    if (this.TafG.have6) {




    }
    else {


    }

  }


  temp_taf6changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;
    // console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;
    }
    //  برای اراور خالی ماندن اولین سطر
    //  if (newValue != null && newValue != undefined && newValue != undefined)
    //   this.searchedtaf6id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == newValue) {
        selected = this.TafG.taf6List[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null) {
      //cellInfo.data.tafsili6Id = selected.id;
      //cellInfo.data.tafsili6Name = selected.name;
      this.selectedrow.taf6Id = selected.id;
      this.selectedrow.taf6N = selected.name;
      this.selectedrow.taf6C = selected.code;
      cellInfo.setValue(selected.code);

    }



    if (this.TafG.have6) {




    }
    else {


    }
    //  this.dataGrid.instance.saveEditData();

  }
  onKeyDownmoien(e) {

    if (e.event.key == 'Insert') {

      this.moientempedit.minSearchLength = 0;
    }
  }
  onKeyDowntaf4(e) {

    if (e.event.key == 'Insert') {

      this.taf4tempedit.minSearchLength = 0;
    }
    //if (this.taf4tempedit.items.length == 1) {

    //  this.tw = this.taf4tempedit.items[0];
    //  this.taf4tempedit.selectedItem = this.tw;
    //  this.taf4tempedit.value = this.tw.id;
    //  console.log('select one');
    //}

  }
  onKeyDowntaf5(e) {
    if (e.event.key == 'Insert') {

      this.taf5tempedit.minSearchLength = 0;
    }
    //if (this.taf5tempedit.items.length == 1) {

    //  this.tw = this.taf5tempedit.items[0];
    //  this.taf5tempedit.selectedItem = this.tw;
    //  this.taf5tempedit.value = this.tw.id;
    //  console.log('select one');
    //}


  }
  onKeyDowntaf6(e) {

    if (e.event.key == 'Insert') {

      this.taf6tempedit.minSearchLength = 0;
    }
    //if (this.taf6tempedit.items.length == 1) {

    //  this.tw = this.taf6tempedit.items[0];
    //  this.taf6tempedit.selectedItem = this.tw;
    //  this.taf6tempedit.value = this.tw.id;
    //  console.log('select one');
    //}



  }

 

  ///???????????
  tafg_reflesh(hesabid: number) {
     
    if (hesabid == null) {
      //hhjhjg776g

      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
  
     // this.devDataTaf4 = [];
      this.devDataTaf5 = [];
      this.devDataTaf6 = [];
      //this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
      //this.devDataTaf4 = new DataSource({
      //  store: this.TafG.taf4List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf5 = new DataSource({
      //  store: this.TafG.taf5List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf6 = new DataSource({
      //  store: this.TafG.taf6List,
      //  paginate: true,
      //  pageSize: 50
      //});
      return;


    }
    //?????????????????????????????? چرا
    //if (this.TafG.hesabId == null) {
    //  return;
    //}
    //if (this.TafG.hesabId == hesabid) {
    //  return;
    //}

    this.TafG = null;



    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListallCount; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];



        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب

        for (var j = 0; j < this.tafjustdaste.length; j++) {
          //خواندن تفصیلی 4
          // if (this.TafGListall[_i].taf4List.length == 0)
          for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

            if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی   5
          // if (this.TafGListall[_i].taf5List.length == 0)
          for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

            if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی 6
          // if (this.TafGListall[_i].taf6List.length == 0)
          for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

            if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
            }

          }


        }




        this.TafG = this.TafGListall[_i];
        //this.devDataTaf4 = new DataSource({
        //  store: this.TafG.taf4List,
        //  paginate: true,
        //  pageSize: 50
        //});
        this.devDataTaf5 = new DataSource({
          store: this.TafG.taf5List,
          paginate: true,
          pageSize: 50
        });
        this.devDataTaf6 = new DataSource({
          store: this.TafG.taf6List,
          paginate: true,
          pageSize: 50
        });

        if (!this.TafG.have4)
          this.selectedrow.taf4C = "-----";
        if (!this.TafG.have5)
          this.selectedrow.taf5C = "-----";
        if (!this.TafG.have6)
          this.selectedrow.taf6C = "-----";

      //  console.log('$TafG:' + this.TafG.hesabId.toString() + ' l4:' + this.TafG.have4 + ' l5:' + this.TafG.have5 + ' l6:' + this.TafG.have6);


        return;
      }

    }


 



 


  }


  first_moienintilized: boolean;

  temp_moienintilized(e, cellInfo) {
    console.log(this.first_moienintilized);

    if (this.first_moienintilized == undefined) {
      this.first_moienintilized = true;
      console.log(this.first_moienintilized);
    }

  }

  setrowinfoBymoien(moid: number) {

    var selected;
    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == moid) {
        selected = this.hesablist[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected != null) {



      


      this.selectedrow.hesabId = selected.id;
      this.selectedrow.hesabN = selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
      this.selectedrow.hesabC = selected.codeSum;
      // cellInfo.data.hesabCode = selected.codeSum;
      //خود سلول را باید حتما از این تابع استفاده کنیم تا مقدار بگیرد و بشه تب را زد
      // cellInfo.setValue(selected.codeSum);




    }
    else {

     

      this.selectedrow.hesabId = null;
      this.selectedrow.hesabN = null;
      // this.selectedrow.setValue(null);

    }



  }

  pv: any;
  temp_moienchanged(e, cellInfo) {

    this.isfchangcall();

    //54654hrth
    this.pv = -1;
    // return;
    //  if (this.moieninchang)
    //   return;
    var selected;
    // this.moieninchang = true;
   // console.log('temp_moienchanged');

    var previousValue = e.previousValue;
    var newValue = e.value;

   // console.log('ttt' + previousValue + '-' + newValue);
    if (e.value == undefined) {
     // console.log('temp_moienchanged undefinedundefinedundefinedundefinedundefinedundefinedundefined' + previousValue + '-' + newValue);
     // console.log(this.selectedrow.id);
      this.pv = previousValue;
    //  console.log('this.pv  ' + this.pv);
      this.firstShitedRowid = this.selectedrow.id;

    }





    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected != null) {



      ////////////////////

 


      this.selectedrow.hesabId = selected.id;
      this.selectedrow.hesabN= selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
      this.selectedrow.hesabC = selected.codeSum;



    }
    else {
 

      this.selectedrow.hesabId = null;
      this.selectedrow.hesabN = null;
      // this.selectedrow.setValue(null);

    }

    this.selectedrow.taf4Id = null;
    this.selectedrow.taf4C = null;
    this.selectedrow.taf4N = null;

    this.selectedrow.taf5Id = null;
    this.selectedrow.taf5C = null;
    this.selectedrow.taf5N = null;

    this.selectedrow.taf6Id = null;
    this.selectedrow.taf6C = null;
    this.selectedrow.taf6N = null;


    if (this.dataGrid.focusedRowIndex == 0) {

    }

    if (selected != null)
      this.tafg_reflesh(selected.id);
    else
      this.tafg_reflesh(null);


    //  console.log('moien changed');
    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    this.dataGrid.instance.focus();
    //this.pushnewrow(); 

    // this.dataGrid.instance.saveEditData();
    //this.mypresskey('Enter');
    //  this.moieninchang = false;

  }

          //فیلتری برای جلو گیری از پاک شدن جدول هنگامی که ویرایش را باز میکنیم
          //چون هنگام لود مدل رادیون باکس عوض میشه جلو این رویداد را برای بار اول میگیریم
  flo: boolean = true;
  mabRadChanged(e) {

  
    //برای بار اول این تابع را غیر فعال میکنیم تا ردیف ها در حالت ویرایش دارای مبنا پاک نشه
    if (this.flo && this.isnew == false) {
      this.flo = false;
      return;
    }
    this.flo = false;

    if (!this.modelloaded)
      return;

    if (e.previousValue != null) {
      this.mymodle.rows = [];
    }
     
   
    //اگر مبنا داشت
    //کد نوع صفحه مبنا این مقدار است
    if (e.value != 0) {
      if (
        this.mymodle.taf4Id == null 
        || this.mymodle.anbarId == null || this.mymodle.dateA_S == null) {
        this.alertify.message('ابتدا انبار و طرف مقابل را انتخاب کنید');
        return;
      }
      else {
        this.loadMabna();
      }
    }
    else {
  
    }



    this.bindLock();

   
    this.pushnewrow(false);

   

  }
  oncheckchanged() {

    this.loadMabna();

  }

  cntFirst = 0;
  loadMabna() {


    if (this.modelloaded == false)
      return;

    //جلو گیری از لود چند تایی
    //if (this.isnew == false && this.cntFirst < 2) {
    //  this.cntFirst++;
    //  return;
    //}

    //this.mymodle.mabna == 0 مبنا نداشت
        //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    if (
      this.mymodle.mabna == 0 ||
      this.mymodle.anbarId == null||
     this.mymodle.dateA_S == null ||
      (this.mymodle.taf4Id == null && this.kindpage !=107)==true ) {
      this.mablist = [];
      return;
    } 
    ///ghfhghr56yu7546
    let anbarid = this.mymodle.anbarId;

    //درخواست کالا انبار ندارد و مبنا بدون انبار را انتخاب میکنیم
    //-1= بدون انبار ،نه همه انبارها
    if (this.mymodle.mabna == -11)
      anbarid = -1;

    let taf4 = this.mymodle.taf4Id;
    if (taf4 == null)
      taf4 = -1;

    let anB = this.mymodle.anbarBId;
    if (anB == null)
      anB = -1; 

    let year = this.mymodle.years;
    if (this.mymodle.isMabnaAllY)
      year = -1;


    this.loadServ.showloading(this.loaddata, 15);
    //mymodle.mabna = هر نوع سند انبار یک مبنا عطف دارد که با انتخاب رادیون باکس ای دی نوع ان انتخاب میشود
        //اگر نوع سند از نوع موجودی اول دوره بود طرف مقابل -1 میباسد  taf4Id=-1
    this.anDeedService.getMabnaForRow(anbarid, taf4, this.mymodle.dateA_S, year, this.mymodle.mabna, this.kindpage, anB).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 15);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
       // this.alertify.success(res.success[0]);

        this.mablist = res.data;
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 15);
      this.alertify.error(error);
    });


  }


  gridBox_displayExpr(item) {
    return item && item.codeSum + '-'+ item.name;
  }


  Afschanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    var h = e.component.option('selectedItem');

    
    cellInfo.data.kindId = h.id;
    cellInfo.data.kindN = h.name;

    if (h.id == 0) {
      cellInfo.data._IsDar = false;
      cellInfo.data._IsPrc = false;

      cellInfo.data.darsad = 0;
      cellInfo.data.prc = 0;
    }
    else if (h.id == 1) {
      cellInfo.data._IsDar = true;
      cellInfo.data._IsPrc = false;
      cellInfo.data.prc = 0;
    }
    else {
      cellInfo.data._IsDar = false;
      cellInfo.data._IsPrc = true;
      cellInfo.data.darsad = 0;
    }




    //حمل
    if (h.id != 0 && cellInfo.data.titC == 103 && cellInfo.data.prc == 0) {

      cellInfo.data.prc  = this.mymodle.barname.cost;

    }

  }
  onValueChangeddarsad(e, cellInfo) {
 


    cellInfo.data.darsad = e.value;

  }
  onValueChangededitdafss(e, cellInfo) {
 


    cellInfo.data.prc = e.value;

  }


  calcclick() {

    if (this.myvalidationchek('') == false) {
      this.pushnewrow(false);
      return;
    }
    this.mymodle.kind = this.kindpage;

    if (this.editId == -1) {
      this.alertify.message("ابتدا سند را ذخیره کنید");
      return;
    }
    //this.pushnewrow(false);
   //gfddfg4545htr
  this.loadServ.showloading(this.loaddata, 12);
  this.anDeedService.calcPrc(this.mymodle).subscribe(res => {
    this.loadServ.hideloading(this.loaddata, 12);
    if (res.errors.length > 0) {
      this.alertify.showListString(res.errors);
    }
    else {
      this.alertify.success(res.success[0]);
      this.mymodle = res.data;

      this.refreshbottum();

      this.filvahedlis();
      this.pushnewrow(false);
    }
  }, error => {
    this.loadServ.hideloading(this.loaddata, 12);
    this.alertify.error(error);
  });
  }
  onClicktest() {


    this.mabcolum.visible = false;
   // this.dataGrid.focusedRowIndex++;

  }
  onAcceptmabna(e) {


   // console.log(e);

    this.mabdrpdown.instance.close();
  
    let exist = false;
      for (var r of this.mymodle.rows) {
//اگر در دیتابیس ثبت شده بود ان را به موجودی قابل قبول اضافه میکنیم
        //در حالت ویرایش
        exist = false;
        if (r.id > 0) {

          for (var v of this.mablist) {
            if (v.id == r.id) {
              v.amtB += r.amtB;
              exist = true;
              break
            }
          }

          if (!exist) {
            var a=  this.anDeedService.getAnDeedRowForMabnaEmpty();

            a.id = r.anDeedRowId;
            a.kalaId = r.kalaId;
            a.kalaC = r.kalaC;
            a.kalaN = r.kalaN;

            a.vahId = r.vahId;
            a.vahN = r.vahN;
            a.vahBId = r.vahBId;
            a.vahBN = r.vahBN;
            a.zMulti = r.zMulti;
            a.anDeedCode = r.anDeedCode;
            a.amtmB = r.amtFaB;
         
            this.mablist.push(a);

          }
        }

      }


    this.mymodle.rows = [];
    let i = -1000;
    let j = 0;

    var selects = this.griddown.selectedRowKeys;
    
       
      for (var u of selects) {

        if (u == undefined)
          continue;

          let a = this.anDeedService.getAnDeedRowEmpty();
        i--;
        j++;
          a.id = i;
          a.radif = j;
          a.anDeedRowId = u.id;
        //  var h = e.component.option('selectedItem') as AnDeedRowForMabna;
          //fghhgf65
          a.kalaId = u.kalaId;
          a.kalaC = u.kalaC;
          a.kalaN = u.kalaN;

          a.amtFa = 0;
          a.amtFaB = u.amtmB;

          a.vahId = u.vahId;
          a.vahN = u.vahN;
          a.vahBId = u.vahBId;
          a.vahBN = u.vahBN;
          a.zMulti = u.zMulti;
          a.anDeedCode = u.anDeedCode;

          //مقدار باقی مانده رسید نشده
          //واحد ثانویه را قرار میدیم حال همان واحد اصلی باشد یا فرعی
          a._maxAmt = u.amtmB;
 

    

          this.setbarname(u.anDeedId);
          this.mymodle.rows.push(a);

    }


    this.pushnewrow(false);

  }


  adjustWidth2(e, w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {


      this.firsttimeopen = false;

    }
   // this.selestedkeys = [];
    var a = [];
    for (var r of this.mymodle.rows) {
   
      a.push(r.anDeedRowId);
    //  this.selestedkeys.push(r.anDeedRowId);
     

    }
    this.griddown.instance.selectRows(a,true);
//var navcel = this.griddown.instance.selectRows(0, 0);
  

  }
 
  onClickAmel(e) {

  
    
  
    let rows = [];
    for (var r of this.mymodle.rows) {
      if (r.kalaId == null)
        continue;

      rows.push({
        id: r.id,
        kalaId: r.kalaId,
        hesabId: null,
        hesabC: null,
        hesabN: null,

        taf5Id: null,
        taf5C: null,
        taf5N: null,

        taf6Id: null,
        taf6C: null,
        taf6N: null,
      })

    }
 
    let data = {
      kind: e,
      tableN: 'andeed',
      anKind: this.mymodle.kind,
      groupKind:true,
      taf4Id: this.mymodle.taf4Id,
      rows: rows

    };


    this.loadServ.showloading(this.loaddata, 13);
    this.AnAmelService.doAmelOne(data).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
     ///34gr4e5y4eyh4e

        //if (res.data.rows.length > 0) {
        //  this.mymodle.hesabId = res.data.rows[0].hesabId;
        //  this.mymodle.hesabC = res.data.rows[0].hesabC;
        //  this.mymodle.hesabN = res.data.rows[0].hesabN;

        //  this.mymodle.taf5Id = res.data.rows[0].taf5Id;
        //  this.mymodle.taf5C = res.data.rows[0].taf5C;
        //  this.mymodle.taf5N = res.data.rows[0].taf5N;

        //  this.mymodle.taf6Id = res.data.rows[0].taf6Id;
        //  this.mymodle.taf6C = res.data.rows[0].taf6C;
        //  this.mymodle.taf6N = res.data.rows[0].taf6N;
        //}
           this.mymodle.hesabId = null;
        this.mymodle.hesabC = null;
        this.mymodle.hesabN = null;

        this.mymodle.taf5Id = null;
        this.mymodle.taf5C = null;
        this.mymodle.taf5N = null;

        this.mymodle.taf6Id = null;
        this.mymodle.taf6C = null;
        this.mymodle.taf6N = null;

        for (var r of res.data.rows) {

          for (var u of this.mymodle.rows) {
            if (u.id == r.id) {
              u.hesabId = r.hesabId;
              u.hesabC = r.hesabC;
              u.hesabN = r.hesabN;
              u.taf5Id = r.taf5Id;
              u.taf5C = r.taf5C;
              u.taf5N = r.taf5N;
              u.taf6Id = r.taf6Id;
              u.taf6C = r.taf6C;
              u.taf6N = r.taf6N;
              break
            }
          }
        }



        this.alertify.message(res.mes);
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });
  }



  Sanadids: number[];
  onClicksanadjoiner(e) {


    if (this.editId < 1) {
      this.alertify.warning('ابتدا سند انبار را ذخیره کنید');
      return;
    }

    this.sanadopen = !this.sanadopen;
    this.sanadkind = e;
    this.Sanadids = [];
    this.Sanadids.push(this.editId) ;




  }
  closesanad() {
    this.sanadopen = false;
    this.sanadkind = 0;
  }


  RowPrepared(e) {

    if (e.key < 0) {
      /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }

    //e.rowElement.css('background', 'green');
  }

  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }


  onValueChangedDarsadM(e, cellInfo) {
   


    cellInfo.data.darsadM = e.value;

   // cellInfo.data.prc104 = Math.round(cellInfo.data.darsadM * cellInfo.data.prc);

  }
  onPrepared(e) {
    // this.load();

    this.disbRadion();
    //this.dataGrid.dataSource = this.modlelist;
  }
  displayExprtaf(item) {
    return item && item.code + ' / ' + item.name + ' / ' + item.addCode;
  }


  onValueChangedPrc101DrsdAll(e) {






    //ytu68768689659
    this.mymodle.prc101DrsdAll = e.value;




    var rowsf = this.mymodle.rows.filter(x => x.kalaId > 0);
    let rowsLength = rowsf.length;
    let prcSum = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSum += rowsf[i].prc;
    }
    this.mymodle.prc101All = Math.trunc(prcSum * this.mymodle.prc101DrsdAll) / 100;


  }


  bgColorCSS: string = '';
  onValueChangedPrc101All(e) {


    if (e.value == null || e.value == undefined)
      return;

    let prcTkh = 0;
    for (var r of this.mymodle.rows) {
      prcTkh += r.prc101;
    }

    if (Math.round(e.value) != Math.round(prcTkh)) {

      this.bgColorCSS = 'mybgColor';
    }
    else {
      this.bgColorCSS = '';
    }
    //this.calcallTakhAll();
  }
    calcallTakhAll() {

    let prcTkh = 0;
    let prc = 0;
    for (var r of this.mymodle.rows) {

      prc += r.prc;
      prcTkh += r.prc101;
    }
  

 
    if (this.mymodle.prc > 0)
      this.mymodle.prc101DrsdAll = (prcTkh / prc) * 100;



 
 

  }

   onClickTashimPrc101(kind) {

    //68568956986
   // var y = this.dataGrid.instance.row(1);

    let PrcAllTkh = this.mymodle.prc101All;

 

    var rowsf = this.mymodle.rows.filter(x => x.kalaId > 0);
    let rowsLength = rowsf.length;

 


    let prcSum = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSum += rowsf[i].prc;
    }

 


    if (PrcAllTkh > prcSum) {
      this.alertify.warning('مبلغ کسورات نمیتواند بزرگ تر مبلغ فاکتور باشد');
    
      this.mymodle.prc101All = prcSum;
      PrcAllTkh = prcSum;
    }

    let rowValus = [];
    for (var i = 0; i < rowsLength; i++) {

      if (prcSum >0)
        rowValus.push(Math.trunc((rowsf[i].prc / prcSum) * PrcAllTkh));
      else
      rowValus.push(0);
    }


    let prcSumResult = 0;
    for (var i = 0; i < rowsLength; i++) {
      prcSumResult += rowValus[i];
    }


    let mandeh = PrcAllTkh - prcSumResult;
     
    if (mandeh > 0 && rowsLength >0) {

      rowValus[0] += mandeh;
    }


    for (var i = 0; i < rowsLength; i++) {



      var cellInfo = { data: null };

      cellInfo.data = rowsf[i];

      var e = { value: rowValus[i] };


      this.onValueChangedPrc101(e, cellInfo);

    

    }
    this.calcallTakhAll();
    this.dataGrid.instance.refresh();



     

    let prcTkh = 0;
    for (var r of this.mymodle.rows) {
      prcTkh += r.prc101;
    }
    if (Math.round(prcTkh) != Math.round( PrcAllTkh)) {

      this.bgColorCSS = 'mybgColor';
    }
    else {
      this.bgColorCSS = '';
    }
  }


}
