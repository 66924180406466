import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Ostan } from '../../../_models/app1/paye/Ostan';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { OstanKol } from '../../../_models/app1/paye/ostanKol';
import { Localst } from '../../localst.service';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyOstan: Ostan = {

  id: null,
  codeOstan: null,
  codeShahr: null,
  nameOstan: null,
  nameShahr: null
   
};
let HeaderOstan: any = {

  id: null,
  codeOstan: null,
  codeShahr: null,
  nameOstan: null,
  nameShahr: null

};
 
@Injectable({
  providedIn: 'root'
})
export class OstanService {
  baseUrl = environment.apiUrl;
  origin = location.origin ;
  constructor(private http: HttpClient, private localstService: Localst) { }

  getOstans(ostankolid): Observable<Ostan[]> {
    let apiadress = '/api/App1/Paye/Ostan/readOstan?ckol=' + ostankolid.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<Ostan[]>(this.origin + apiadress, { 'headers': headers });
  }

  getOstanKols(): Observable<OstanKol[]> {
    let apiadress = '/api/App1/Paye/Ostan/getall';
    var headers = this.localstService.header_get(apiadress,null);


 
    return this.http.get<OstanKol[]>(this.origin + apiadress, { 'headers': headers });
  }

 
  getOstan(id): Observable<Ostan> {
    let apiadress = '/api/App1/Paye/Ostan/' + id.toString()
    var headers = this.localstService.header_get(apiadress,null);


    
    return this.http.get<Ostan>(this.origin + apiadress, { 'headers': headers }).pipe();
  
 
  }


 
  addOstan(item: Ostan) {
    let apiadress = '/api/App1/Paye/Ostan/addOstan';
    var headers = this.localstService.header_get(apiadress,null);


 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers } );
  }



  getOstanEmpty(): Ostan {

    return EmptyOstan;


  }

  getOstanHeader(): Ostan {

    return HeaderOstan;


  }
 

 
  deleteOstan(OstanId: number) {
    let apiadress = '/api/App1/Paye/Ostan/' + OstanId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
 
  }
 
 
}
