import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Sanad } from '../../../_models/app1/opr/Sanad';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { of } from 'rxjs';
import { IdName } from '../../../_models/General/IdName';
import { Localst } from '../../localst.service';
import { bastanOp } from '../../../_models/app1/opr/autoSanad/bastanOp';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
 
 
 

 
@Injectable({
  providedIn: 'root'
})
export class SanadJoinerPdsService {
  origin = location.origin;





  constructor(private http: HttpClient, private localstService: Localst)
  {

  }
  
 
  
   

  CreateSanadByPdMoveIds(opbox: any): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/SanadJoinerPds/CreateSanadByPdMoveIds';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, opbox, { 'headers': headers });

  }
 
  AddSanadOnPdMove(addi: any): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/SanadJoinerPds/AddSanadOnPdMove';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, addi, { 'headers': headers });

  }
  DeleteSanadByPdMove(andeedid: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/SanadJoinerPds/DeleteSanadByPdMove?sabtId=' + andeedid.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }

  DeleteSanadBySanadIdPdMove(sanadid: number): Observable<ApiRet> {
    let apiadress = '/api/App1/Opr/SanadJoinerPds/DeleteSanadBySanadIdPdMove?sanadId=' + sanadid.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }



}
