 

 

export class MyClipboard {
  id: string;
  data: any;

 public constructor(idName: string) {
  this.id = idName;
   this.data = null;
}

}
