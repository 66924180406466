import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';
//declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  constructor() {}

    confirm(message: string, okCallback: () => any) {
        alertify.confirm(message,(e: any) => {
            if (e) {
                okCallback();
            } else {

            }
        });
    }
  confirmClosetab(okCallback: () => any) {
    alertify.confirm('  ', (e: any) => {
      if (e) {
        okCallback();
      } else {

      }
    }).set({ transition: 'fade', frameless: false }).set('labels', { ok: 'بله', cancel: 'لغو' })
      .setContent('<h4 style="direction:rtl; float:right;"> سند تغییر یافته است. از بستن پنجره اطمینان دارید؟</h4>')
      .setHeader(' ');;
  }
  showListString(mes: string[]) {

    let row = "";
    for (var i = 0; i < mes.length; i++) {

      row += '<li> '+ mes[i]+' </li>';

    }
      row =  '<ul>' + row + '</ul>';
    let st = '<h4 style="direction:rtl; text-align:right; float:right;"> ' + row+'</h4>'


    alertify.alert('  ', (e: any) => {
      
    }).set({ transition: 'fade', frameless: false }).set('label',  'بستن' ).set('resizable', true).resizeTo('40%', '60%')
      .setContent(st)
      .setHeader(' خطا');

     
  }
  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
  accessdeni() {
    alertify.message('دسترسی لازم وجود ندارد');
  }
  alert(message: string) {
    alertify.alert('پیغام', message ,function () {  });
   // alertify.dia('پیغام', message, function () { alertify.success('تایید'); });
  }

  frameless(message: string) {
    let st = '<h4 style="direction:rtl; margin:30px 10px 10px 0px; text-align:right; float:right;"> ' + message + '</h4>'

    alertify.alert(' ').set({ transition: 'fade', frameless: true }).set('resizable', true).resizeTo('20%', '40%')
      .setContent(st)
      .setHeader(' خطا');
  }

  confidrm(message: string) {
    alertify.alert(message).set({ transition: 'fade', frameless: true });
  }
}
