import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, UserForAcc, UserforAdd } from '../_models/user';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { Localst } from './localst.service';
import { ApiRet } from '../_models/General/ApiRet';

//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyUserForAcc: UserForAcc = {
  id: null,
  customerName: null,
  username: null,
  name: null,
  isAdmin: null,
  isSuperAdmin: null,
 

}
let EmptyUserForAdd: UserforAdd = {
  id: null,
  customerName: null,
  username: null,
  name : null,
  knownAs: null,
  password: null,
  confirmPassword: null,
  isAdmin: false,
  isSuperAdmin: false,

}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  origin = location.origin;
  //baseUrl = environment.apiUrl;
  baseUrl = location.origin + '/api/';
  constructor(private http: HttpClient, private localstService: Localst) {}

  getUsers(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<User[]>> {

    const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<User[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (userParams != null) {
      params = params.append('minAge', userParams.minAge);
      params = params.append('maxAge', userParams.maxAge);
      params = params.append('gender', userParams.gender);
      params = params.append('orderBy', userParams.orderBy);
    }

    return this.http.get<User[]>(this.baseUrl + 'users', { observe: 'response', params })
      .pipe(
        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
      );
    //  return this.http.get<User[]>(this.baseUrl + 'users');
  }


  getUsersAll(): Observable<User[]> {
      return this.http.get<User[]>(this.baseUrl + 'users');
  }

    getUser(id): Observable<User> {
      return this.http.get<User>(this.baseUrl + 'users/' + id)
   // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  GetUsersbyCustomer(cu): Observable<UserforAdd[]> {
    return this.http.get<UserforAdd[]>(this.baseUrl + 'users/GetUsersbyCustomer?cu=' + cu);
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }


    //getUser(id): Observable<User> {
    //    return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
    //}

    updateUser(id: number, user: User) {
      return this.http.put(this.baseUrl + 'users/' + id, user);
    }

  setMainPhoto(userId: number, id: number) {
    return this.http.post(this.baseUrl + 'users/' + userId + '/photos/' + id + '/setMain', {});

  }
  deletePhoto(userId: number, id: number) {
    return this.http.delete(this.baseUrl + 'users/' + userId + '/photos/' + id);
  }

  getUserForList(cu :string): Observable<any[]> {
    return this.http.get<User[]>(this.baseUrl + 'users/GetUserForList?cu=' + cu);
  }

  GetUserForAcc(): Observable<ApiRet> {
    let apiadress = '/api/Users/GetUserForAcc';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
 
  }
  GetUsersListEdit(): Observable<ApiRet> {
    let apiadress = '/api/Users/GetUsersListEdit';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  UpdateIsActive(id: number, isAvtive: boolean): Observable<ApiRet> {
    let apiadress = '/api/Users/UpdateIsActive?id=' + id.toString() + '&isActive=' + isAvtive;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  GetUserForTinyListByCuHeader(): Observable<ApiRet> {
    let apiadress = '/api/Users/GetUserForTinyListByCuHeader';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  getUserForAccEmpty(): UserForAcc {


    return JSON.parse(JSON.stringify(EmptyUserForAcc));

  }

  getUserForAddEmpty(): UserforAdd {


    return JSON.parse(JSON.stringify(EmptyUserForAdd));

  }
}
