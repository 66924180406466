import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
 
import { Pagination } from '../../../../_models/pagination';
 
import { environment } from '../../../../../environments/environment';

 

import { LoadingService } from '../../../../_services/loading.service';
import { PdMove } from '../../../../_models/app4/opr/PdMove';
import { PdMoveService } from '../../../../_services/app4/opr/PdMove.service';
 
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { PdPayrecRow, PdPayrecRowForChek } from '../../../../_models/app4/opr/PdPayrec';

import { PdPayrecService } from '../../../../_services/app4/opr/PdPayrec.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';

import { PdBankService } from '../../../../_services/app4/paye/PdBank.service';
import { PdBank, PdBankTiny } from '../../../../_models/app4/paye/PdBank';
import { Localst } from '../../../../_services/localst.service';
import { PdHesService } from '../../../../_services/app4/paye/PdHes.service';
import { PdHes } from '../../../../_models/app4/paye/PdHes';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
@Component({
  selector: 'app4-PdMoveSimple-edit',
  templateUrl: './PdMoveSimple-edit.component.html',
  styleUrls: ['./PdMoveSimple-edit.component.css']
})
export class PdMoveSimpleEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PdMove;
  myModel_old: PdMove;
 
 
  @Input() kindpage: number;
 
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
  n: number;

 
  pdHeslist: PdHes[] = [];

  hesablist: Hesab[] = [];
  PdPayRecRowlist: PdPayrecRowForChek[] = [];
  kindMovelist: any[] = [];
  paykindRowlist: any[] = [];
  paykindInlist: any[] = [];
  
  tafsmalllist: TafsiliForSmall[];
  pdBankTinylist: PdBankTiny[];
  pdBankTinylistFiltered: PdBankTiny[];


  oldMovelist: PdMove[];

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
 
    private PdMoveService: PdMoveService,
    private tafsiliService: TafsiliService,
    private pdPayrecService: PdPayrecService,
    private pdBankService: PdBankService,
    private pdHesService: PdHesService,
    private hesabService: HesabService,
    private localst: Localst,
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
   
    this.myModel = this.PdMoveService.getPdMoveEmpty();
   // this.paykindRowlist = this.pdPayrecService.getKindRowList();
    this.paykindInlist = this.pdPayrecService.getMahalList();
     
 
  }

  ngOnInit() {
 
 
    this.kindMovelist = [{ id: 205, name: 'ابطال چک ' }, { id: 703, name: 'ابطال سفته' }];


    var optaf = { dasteIds: [-1001, -1002], filter: true, visId: this.editId, visTbl: 'PdPayrec' }

    this.loadServ.showloading(this.loaddata, 11);
    this.tafsiliService.GetTafsiliForSmallByDastes(optaf).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.tafsmalllist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.loadServ.showloading(this.loaddata, 11);
    this.pdBankService.getallTiny(-1,false).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.pdBankTinylist = res.data;


    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



    this.hesabService.getHesabs_treenum(3).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);

      this.hesablist = res;



    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.PdMoveService.getPdMoveEmpty();
 
   

      this.myModel.years = this.localst.yearnow_get();

      this.myModel.dateO_S = this.localst.dateEmruz.dateString;

      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;




      this.isnew = true;


      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.PdMoveService.getbyid(this.editId).subscribe(
        (data : PdMove) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          this.loadOldMove(this.myModel.pdPayrecRowId);
          let u = this.localst.usernow_get();
          this.myModel.uSaE = u.username;
          this.myModel.uSaEF = u.name;

          //لود چک ها
          this.loadAvailables();
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
    



 
  

    console.log(this.editId.toString());
  
  }

  loadpdhes(pdMoveKind: number) {


    this.pdHesService.getall(pdMoveKind).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.pdHeslist = res.data;


    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 
    this.myModel.kindPage = this.kindpage;

   
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.PdMoveService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);
        //ytu568658
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.OnMySaveEvent.emit(true);
        }
       
     
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
 
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.PdMoveService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.OnMySaveEvent.emit(true);

        }

 
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
   
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  
 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
 

  validateNumber(e) {

    

  }

  //gridBox_displayExpr(item) {
  //  return item && item.codeSum + '-' + item.name;
  //}
 
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }
  PaykindRowchanged(e) {
  
    //درحالت ویرایش پاک نشه
    if (e.previousValue != null) {
      this.myModel.paykindIn = null;
      this.myModel.kindMove = null;
    }

    this.bindTafAndBank();

    //نقد
    if (e.value == 1) {
      //لود چک ها
      this.loadAvailables();
      this.myModel.paykindIn = 2;
    }
   else  {

      this.pdPayrecService.getPayrecRowForCheckAll(this.editId, this.kindpage, e.value).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 6);

        this.PdPayRecRowlist = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });


    }






  }
  
  kindMovechanged(e) {
    this.bindTafAndBank();









    if (e.value == null) {
      this.pdHeslist = [];
      return;

    }


    //ujr4658u756
    this.pdPayrecService.getPayrecRowForMove(this.editId, e.value).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);

      this.PdPayRecRowlist = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




      this.loadpdhes(e.value);


      
      
       
     

 
  }

  selectedPdrow: PdPayrecRowForChek = null;
  pdPayrecRowchanged(e) {
    this.myModel.paykindIn = null;
    this.myModel.prc = 0;
 


    if (e.value == null)
      return;

    this.selectedPdrow = null;

    this.selectedPdrow = e.component.option('selectedItem') as PdPayrecRowForChek;
    if (this.selectedPdrow == null)
      return;

    this.myModel.prc = this.selectedPdrow.prc;
 

     
    this.myModel.paykindIn = this.selectedPdrow.kindIn;

    this.myModel.tafId = null;
    this.myModel.tafC = null;
    this.myModel.tafN = null;

    this.myModel.pdBankId = null;
    this.myModel.pdBankC = null;
    this.myModel.pdBankN = null;

    //this.myModel.kindMove = null;
    //this.myModel.kindMoveN = null;
 
    this.myModel.paykindRow = this.selectedPdrow.kindRow; 
    this.myModel.paykindIn = this.selectedPdrow.kindIn; 
 
    this.loadAvailables();

    this.loadOldMove(this.selectedPdrow.id);

  }

  loadAvailables() {

  



  }



  loadOldMove(PayrecRowId: number) {

    //757865fgghfhg
    this.PdMoveService.getallByPayrecRowId(PayrecRowId,true).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);

      this.oldMovelist = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }

  onRowPreparedOldMove(e) {
    //gjuytuty6666

    if (e.rowType == "data") {

      var ed = e.data.id;

      if (ed == this.editId) {

        e.rowElement.style.backgroundColor = 'yellow';
      }

    }
   
  }
  tafchanged(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    if (h == null)
      return;

    this.myModel.tafC = h.code;
    this.myModel.tafN = h.name;
 
  }

  pdbankchanged(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem') as PdBankTiny;
    if (h == null)
      return;

    this.myModel.pdBankC = h.code;
    this.myModel.pdBankN = h.name;

  }

  pdbank303changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem') as PdBankTiny;
    if (h == null)
      return;

    this.myModel.pdBank303C = h.code;
    this.myModel.pdBank303N = h.name;

  }
  visTaf: boolean = false;
  visBank: boolean = false;
  visBank303: boolean = false;
  visPay: boolean = false;
  bindTafAndBank() {

  let m=  this.myModel.kindMove;
  let i=  this.myModel.paykindIn;
  let p=  this.myModel.paykindRow;

    this.visTaf = false;
    this.visBank = false;
    this.visBank303 = false;
    this.visPay = false;

    if (
      m == 101 ||
      m == 102 ||
      m == 105 ||
      m == 202 ||
      m == 204 ||
      m == 302||
         m == 402 
    ) {
      this.visBank = true;
    }


    if (
      m == 106 ||
      m == 206 ||
      m == 403
    ) {
      this.visTaf = true;
    }


    if (m == 302) {
      this.visBank303 = true;
    }

    if (p ==2 || p==3) {
      this.visPay = true;
    }


    if (m == 402)
      this.pdBankTinylistFiltered = this.pdBankTinylist.filter(x => x.kind == 2);
    else if (m == 204)
      this.pdBankTinylistFiltered = this.pdBankTinylist.filter(x => x.kind == 2);
    else
      this.pdBankTinylistFiltered = this.pdBankTinylist;
  }


  mochanged(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    this.myModel.hesabC = h.codeSum;
    this.myModel.hesabN = h.name;

  }

  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
  pdHeschanged(e) {
    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as PdHes;

    if (this.myModel.pdHesId == null)
      return;

     var pdh =  this.pdHeslist.find(x => x.id == this.myModel.pdHesId);


    if (pdh == null || pdh == undefined)
      return;

    this.myModel.hesabId = pdh.hesabAId;
    this.myModel.hesabC = pdh.hesabAC;
    this.myModel.hesabN = pdh.hesabAN;
   
    this.myModel.dis= this.generateDis(pdh.pattern);
    

    


  }
  generateDis( pattern : string): string {
    
    
    if (pattern == null)
      return null;
    //tyyu75665
    return pattern
      .replace('*1*', this.myModel.tafN)
      .replace('*2*', this.myModel.pdBankN)
      //   .replace('*3*', this.myModel.dateA_S)
      .replace('*4*', this.myModel.mos);
    //  .replace('*5*', this.myModel.CkBank)
    //  .replace('*6*', this.myModel.ckCode)
   //   .replace('*7*', this.myModel.ckDate_S)
   //   .replace('*8*', this.myModel.mahalN);


  }
  displayExprtaf(item) {

    if (item && item.kindRow ==2)
      return item && item.ckCode + ' به تاریخ ' + item.dateB_S ;
    else if (item && item.kindRow == 3)
      return item && item.mos + ' به تاریخ ' + item.dateB_S +' /' + item.tafN;
    else
      return item && item.mos ;
  }
}
