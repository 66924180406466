import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnDeed, AnDeedAf, AnDeedBar, AnDeedReadOp, AnDeedRow, AnDeedRowForMabna } from '../../../_models/app2/opr/AnDeed';
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyAnDeed: AnDeed = {
  id: -1,
  code: null,
  dateA: 0,
  dateA_S: null,
  vaz: -1,
  _VazN: 'ثبت نشده',
  kind: null,
  kindN: null,
  kindAtfId: null,
  kindAtfN: null,
  //نوع درخواست کالا فقط در درخواست کالا
  opA: null,

  anbarId: null,
  anbarN: null,

  anbarBId: null,
  anbarBN: null,

  //بدون مبنا یا عطف
  //با مبنا یا عطف
  mabna: 0,
  hesabId: null,
  hesabC: null,
  hesabN: null,
  taf4Id: null,
  taf4C: null,
  taf4N: null,
  taf5Id: null,
  taf5C: null,
  taf5N: null,
  taf6Id: null,
  taf6C: null,
  taf6N: null,
  dis: null,
   anDeedRowId: null,
  rows: [],
  afs: [],
  barname: null,
  fi: 0,
  prc: 0,
  fiFa: 0,
  prcFa: 0,
  years: 0,
  dateD: 0,
  dateZ: 0,
  tafDarId: null,
  tafDarN: null,
  uSa: null,
  uSaF: null,
  uSaE: null,
  uSaEF: null,
  dateELog: null,
  uBa: null,
  uBaF: null,
  uTa: null,
  uTaF: null,
  dateLog: null,
  dateD_S: null,
  dateZ_S: null,
 isSys:null,
  _isSysN:null,
  sanadId: null,
  sanadEId: null,
  _isE: null,
  _isES: null,
  _isEnded: false,
  prc101All: 0,
  prc101DrsdAll: 0,
  taf4TahId: null,
  isMabnaAllY: false
};
  
 
let EmptyAnDeedAf:AnDeedAf= {
   id: 0,
  anDeedId: 0,
  act: true,
  titC: null,
  tit: null,
  kindId: null,
  kindN: null,
  darsad: null,
  prc: 0,
  hesabId: 0,
  tafId: null,
  dis: null,
  _IsDar:false,
  _IsPrc: false,
  isM: false,
  isUse: true
}


let EmptyDarAnDeedRow: AnDeedRow = {
  id: 0,
  anDeedId: 0,
  radif: null,
  dateA: 0,
  kalaId: null,
  kalaN: null,
  kalaC: null,
  amt: 0,
  amtB: 0,
  amtFa: 0,
  amtFaB: 0,
  amtE: 0,
  amtEB: 0,
  vahBId: null,
  vahBN: null,
  vahId: null,
  vahN: null,
  zMulti: null,
  dis: null,
  fi: 0,
  prc: 0,
  fiFa: 0,
  prcFa: 0,
  prc101:0,
  prc102:0,
  prc103:0, 
  prc104:0,
  prc105:0,
  prc106:0,
  prc107:0,
  prcS1:0,
  darsadM:0,
  vaz: 0,
  vazR: 0,
  anDeedRowId: null,
  anDeedRowCode: null,
  anDeedRowIdSb: null,
  anDeedRowCodeSb: null,
  anDeedCode: 0,
  hesabId:  null,
  hesabC: null,
  hesabN: null,
  taf4Id: null,
  taf4C: null,
  taf4N: null,
  taf5Id: null,
  taf5C: null,
  taf5N: null,
  taf6Id: null,
  taf6C: null,
  taf6N: null,
  anbarId: -1,
  anbarBId: null,
  kind: -1,
  years: 0,
  _maxAmt: 0,
   
  amtDone: 0,
  amtDoneB: 0,
  amtSellDone: 0,
  amtSellDoneB: 0,
  amtRej: 0, 
  amtRejB: 0,
  disB: null,
  isSys: null,
  dateA_S: null,
  _lock:false,
  vs: [],
   taf4TahId:null
}
let EmptyAnDeedBar: AnDeedBar = {
  id: -1,
  shomare: null,
  date: null,
  feres: null,
  giran: null,
  tafHamlId: null,
  tafHamlN: null,
  ran: null,
  ranmeli: null,
  rangovah: null,
  girpost: null,
  ferpost: null,
  shCar: null,
  kindCar: null,
  ranCart: null,
  mahmu: null,
  kindBox: null,
  tedadBas: null,
  barA: null,
  barB: null,
  vaznP: null,
  vaznKhali: null,
  vaznKhales: null,
  cost: 0,
  anDeedId: 0,
  anMovaBarId:null,
  //anDeed: null
}


let EmptyAnMovaRowFroMabna: AnDeedRowForMabna = {
  id: null,
  dateA: null,
  dateA_S: null,
  kalaId: null,
  kalaN: null,
  kalaC: null,
  amtB: null,
  amtmB: null,
  vahBId: null,
  vahBN: null,
  vahId: null,
  vahN: null,
  zMulti: null,
  anbarId: null,
  tafMoId: null,
  anDeedId: null,
  anDeedCode: null
}

let EmptyAnDeedReadOp: AnDeedReadOp ={
  years: null,
  kinds: [],
  dataA_S: null,
  dataB_S: null
}
let AnDeedAfsKinds: any[] = [
  { id: 0, name: 'ندارد' },
  { id: 1, name: 'درصدی' },
  { id: 2, name: 'تسهیم به بطور مساوی ' },
  { id: 3, name: 'تسهیم به قیمت اقلام' },
  { id: 4, name: 'تسهیم به تعداد اقلام ' }

 
]
let AnDeedAfsKinds2: any[] = [
  { id: 0, name: 'ندارد' },
  { id: 2, name: 'تسهیم به بطور مساوی ' },
  { id: 3, name: 'تسهیم به قیمت اقلام' },
  { id: 4, name: 'تسهیم به تعداد اقلام ' }


]

let defultAf: AnDeedAf[] = [
  { id: -1, isUse: true, isM: false, anDeedId: 0, act: true, titC:-101, tit: 'تخفیف', _IsDar:false, _IsPrc:false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -2, isUse: true, isM: false,anDeedId: 0, act: true, titC: 102, tit: 'سایر اضافات رسید خرید', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -3, isUse: true, isM: false,anDeedId: 0, act: true, titC: 103, tit: 'کرایه حمل', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -4, isUse: false, isM: true,anDeedId: 0, act: true, titC: 104, tit: 'مالیات و عوارض', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -5, isUse: false, isM: true,anDeedId: 0, act: true, titC: 105, tit: '-', _IsDar: false, _IsPrc: false,kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -6, isUse: false, isM: false,anDeedId: 0, act: true, titC: -106, tit: 'پیش پرداخت', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -7, isUse: true, isM: false,anDeedId: 0, act: true, titC: 107, tit: 'سایر هزینه ها', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },

]
let defultAfBuy: AnDeedAf[] = [
 { id: -3, isUse: true, isM: false, anDeedId: 0, act: true, titC: 103, tit: 'کرایه حمل', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null },
  { id: -6, isUse: false, isM: false, anDeedId: 0, act: true, titC: -106, tit: 'پیش پرداخت', _IsDar: false, _IsPrc: false, kindId: 0, kindN: 'ندارد', darsad: 0, prc: 0, hesabId: 0, tafId: 0, dis: null }
]

let AnDeedKinds: any[] = [

  //{ id : 11, name : "رسید موقت", atfKindid : null },
 // { id : 101, name : "رسید خرید", atfKindid : 11 },
  { id: 201, name: "رسید خرید", atfKindid: 11 },
  { id : 102, name : "برگشت از مصرف", atfKindid : -102 },
  { id : 103, name : "برگشت امانی ما نزد دیگران", atfKindid : -103 },
  { id : 104, name : "دریافت امانی دیگران نزد ما", atfKindid : null },
  { id : 105, name : "دریافت از تولید", atfKindid : null },
  { id : 106, name : "برگشت از تحویل فروش", atfKindid : -106 },
  { id : 107, name : "رسید انتقال بین انبار", atfKindid : -107 },
  { id : 108, name : "برگشت از حواله تحویل دارایی های ثابت", atfKindid : -108 },
  { id : 109, name : "رسید ضایعات", atfKindid : -109 },
  { id : 110, name : "برگشت از فروش ضایعات", atfKindid : -110 },
  { id : 111, name: "اضافات انبار گردانی", atfKindid : null },
  { id: 112, name: "برگشت از ارسال به تولید", atfKindid : -112 },


  { id : 150, name : "موجودی اول دوره", atfKindid : -50 },



  //{ id : -11, name : "درخواست کالا", atfKindid : null },
  //{ id : -101, name : "برگشت از خرید", atfKindid : 101 },
  { id: -201, name: "برگشت از رسید خرید", atfKindid: 201 },
  { id : -102, name : "حواله مصرف", atfKindid : -11 },
  { id : -103, name : "ارسال امانی ما نزد دیگران", atfKindid : -11 },
  { id : -104, name : "برگشت از دریافت امانی دیگران نزد ما", atfKindid : 104 },
  { id : -105, name : "مرجوعی به تولید", atfKindid : 105 },
  { id : -106, name : "تحویل فروش ", atfKindid : -11 },
  { id : -107, name : "حواله انتقال بین انبار ها", atfKindid : null },
  { id : -108, name : "حواله تحویل دارایی های ثابت", atfKindid : -11 },
  { id : -109, name : "برگشت از رسید ضایعات", atfKindid : null },
  { id : -110, name : "فروش ضایعات", atfKindid : null },
  { id : -111, name: "کسورات انبار گردانی", atfKindid : null },
  { id : -112, name: "ارسال به تولید", atfKindid : null },
 

  { id : -21, name : "برگشت از رسید موقت", atfKindid : 11 },
  { id : -50, name : "موجودی پایان دوره", atfKindid : -50 },


]

let vazlist: any[] = [
  { id: 200, name: 'موقت' },
  { id: 300, name: 'تایید شده' },
  { id: 400, name: 'قیمت گذاری شده' },
  { id: 500, name: 'سند حسابداری صادر شده' },

]

 


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class AnDeedService {
  baseUrl = environment.apiUrl;
  origin = location.origin;


  val = 'm222m222m';
  constructor(private http: HttpClient, private localstService: Localst) {

    console.log(this.val);

  }

  setval(any) {
    this.val = any;
    console.log(this.val);
  }

  getall(year,kind): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/getall?year=' + year.toString() + '&kind=' + kind;

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



  getallByReadOp(op): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/getallByReadOp';

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress,op, { 'headers': headers });
  }


  getbyid(id): Observable<AnDeed> {
    let apiadress = '/api/App2/Opr/AnDeed/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<AnDeed>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  //ورود و خروج
  add(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/add';
    if (item.kind < 100)
      apiadress = '/api/App2/Opr/AnDeed/addExit';
 

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //رسید موقت
  addAnMova(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/addAnMova';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  //درخواست کالا
  addDarkhast(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/addDarkhast';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //خرید
  addBuy(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/addBuy';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  getMabnaRadionData(kindId): any[] {

    var data: any[] = [];
    //در انتقال بین مبنا روش بدون مبنا نداریم
    if (kindId != 107)
      data.push({ id: 0, name: 'بدون مبنا' });

    var mb = this.getMabnaAtf(kindId);
    if (mb.id != null) {
      data.push({ id: mb.id, name: mb.name });
    }

    return data;
  }


  getAnDeedEmpty(kindId): AnDeed {


    var a = JSON.parse(JSON.stringify(EmptyAnDeed)) as AnDeed;

    var mb = this.getMabnaAtf(kindId);
    a.kindAtfId = mb.id;
    a.kindAtfN = mb.name;
    a.kind = kindId;
   
    a.rows = [];

    if (kindId == 201 || kindId == -201) {
      a.afs = JSON.parse(JSON.stringify(defultAfBuy)) as AnDeedAf[];
    }
    else {
      a.afs = JSON.parse(JSON.stringify(defultAf)) as AnDeedAf[];
    }
   

    a.barname = JSON.parse(JSON.stringify(EmptyAnDeedBar)) as AnDeedBar;
    return  a;

  }



  //با توجه به نوع فرم عطف متناسب با ان انتخاب شده و در
  //radionbox
  //اضافه میشود و اگر عطفی نداشت فقط گزینه بدون مبنا باقی می ماند و چیزی اضافه نمیشود
  private getMabnaAtf(kindId): any{

    switch (kindId) {
      case 101:
      case -21:
      case 201:
        {
        return { id: 11, name: 'برمبنا رسید موقت'}
      }
      case 102: {
        return { id: -102, name: 'برمبنا مصرف انبار' }
      }
      case 103: {
        return { id: -103, name: 'ارسال امانی ما نزد دیگران' }
      }
      case 106: {
        return { id: -106, name: 'تحویل فروش' }
      }
      case 107: {
        return { id: -107, name: 'حواله انتقال بین انبار ها' }
      }
      case 108: {
        return { id: -108, name: 'حواله تحویل دارایی های ثابت' }
      }
      case -109: {
        return { id: 109, name: 'رسید ضایعات' }
      }
      case 110: {
        return { id: -110, name: 'فروش ضایعات' }
      }
      case 112: {
        return { id: -112, name: 'برمبنا ارسال به تولید' }
      }
      case -101: {
        return { id: 101, name: 'برمبنا رسید خرید' }
      }
  
      case -102:
      case  -103:
      case  -106:
      case  - 108:
      {
        return { id: -11, name: 'برمبنا درخواست کالا' }
      }
      case -104: {
        return { id: 104, name: 'برمبنا دریافت امانی دیگران نزد ما' }
      }
      case -105: {
        return { id: 105, name: 'برمبنا دریافت از تولید' }
      }
      case -201:
        {
          return { id: 201, name: 'برمبنا رسید خرید' }
        }
      case 150: {
        return { id: -50, name: 'موجودی پایان دوره سال قبل' }
      }
      default: {
        return { id: null, name: null };
      }

    }

  
   




  }




  getAnDeedRowEmpty(): AnDeedRow {


    return JSON.parse(JSON.stringify(EmptyDarAnDeedRow));

  }

  getAnDeedReadOpEmpty(): AnDeedReadOp {


    return JSON.parse(JSON.stringify(EmptyAnDeedReadOp));

  }
  AfsKindsList(): any[] {


    return AnDeedAfsKinds ;

  }
  AfsKindsList2(): any[] {


    return AnDeedAfsKinds2;

  }
  

 
    //ورود و خروج
  update(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/update';
    if (item.kind < 0)
       apiadress = '/api/App2/Opr/AnDeed/updateExit';

    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //رسید موقت
  updateAnMova(item: AnDeed, ctrlMode): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/updateAnMova?ctrlMode=' + ctrlMode;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //درخواست کالا
  updateDarkhast(item: AnDeed, ctrlMode): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/updateDarkhast?ctrlMode=' + ctrlMode;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  //خرید کالا
  updateBuy(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/updateBuy';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  delete(AnDeedId: number, force:string): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/delete?id=' + AnDeedId.toString() + '&force=' + force;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
     
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App2/Opr/AnDeed/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  getvaz(any): any {
    let apiadress = '/api/App2/Opr/AnDeed/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }




  updateVaz(ids: number[], vc: number): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/updateVaz?vc=' + vc.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, ids, { 'headers': headers });

  }

 

validationCode(text, id, dataA : string, kind, year): Promise<ApiRet> {
  let apiadress = '/api/App2/Opr/AnDeed/validationCode?text=' + text + '&id=' + id + '&dataA=' + dataA + '&kind=' + kind + '&year=' + year;
    var headers = this.localstService.header_get(apiadress, null);
    //  return this.http.get<any>(this.origin + apiadress, { 'headers': headers });

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).toPromise();

  }

  nextCode(year:number, kind : number): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/nextCode?year=' + year +'&kind=' + kind;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getAnDeedbyshift(id: number, shift: number): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/getAnDeedbyshift?id=' + id + '&shift=' + shift;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }
  calcPrc(item: AnDeed): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/calcPrc';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

  getAnDeedBar(anDeedId): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/getAnDeedBar?anDeedId=' + anDeedId;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }


  getMabnaForRow(anbarId, TafId, date, year,mabna , kind,anbarB): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/getMabnaForRow?anbarId=' + anbarId + '&TafId=' + TafId + '&date=' + date + '&year=' + year + '&mabna=' + mabna + '&kind=' + kind + '&anbarBId=' + anbarB;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }
  
  getMabnaForRow_ForAnSell(anbarId, TafId, date, year, mabna): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/getMabnaForRow_ForAnSell?anbarId=' + anbarId + '&TafId=' + TafId + '&date=' + date + '&year=' + year + '&mabna=' + mabna;
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe(
    );
    return v;
  }
  getAnDeedRowForMabnaEmpty(): AnDeedRowForMabna {


    return JSON.parse(JSON.stringify(EmptyAnMovaRowFroMabna));

  }

  getkindList(): any {


    return AnDeedKinds;

  }
  //int anbarId, int TafId, string date, int year, int kalaId, int ? editId
  getMabnaSbForRow(anbarId: number, date: string, year: number, kalaId: number, editId: number|null): Observable<ApiRet> {

    let apiadress = '/api/App2/Opr/AnDeed/getMabnaSbForRow?anbarId=' + anbarId  + '&date=' + date + '&year=' + year + '&kalaId=' + kalaId + '&editId=' + editId;

    var headers = this.localstService.header_get(apiadress, null);

    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
    
    return v;
  }



  getVazList(): any[] {
    return JSON.parse(JSON.stringify(vazlist));

  }
  getMaxDateOfDeed(years: number, anbarId: number): Observable<ApiRet> {


    let apiadress = '/api/App2/Opr/AnDeed/getMaxDateOfDeed?years=' + years.toString() + '&anbarId=' + anbarId.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
 
  }
  getMaxDateOfDeeds(years: number): Observable<ApiRet> {


    let apiadress = '/api/App2/Opr/AnDeed/getMaxDateOfDeeds?years=' + years.toString();;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }



  SortCodes(years, kind , cstart): Observable<ApiRet> {
    let apiadress = '/api/App2/Opr/AnDeed/sortCode?years=' + years.toString() + '&kind=' + kind + '&c=' + cstart;

    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

}
