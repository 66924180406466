import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent } from 'devextreme-angular';
 

import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ExcelService } from '../../../_services/excel.service';
import { Localst } from '../../../_services/localst.service';

import { ApiRet } from '../../../_models/General/ApiRet';

import { CheckSadereRep } from '../../../_models/app4/rep/CheckSadereRep';

import { PdPayrecRow, PdPayrecRowForChek } from '../../../_models/app4/opr/PdPayrec';
import { PdPayrecService } from '../../../_services/app4/opr/PdPayrec.service';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../_models/app1/paye/tafsiliForSmall';
import { PdPayrecRepService } from '../../../_services/app4/rep/PdPayrecRep.service';
import { PdBank } from '../../../_models/app4/paye/PdBank';
import { PdBankService } from '../../../_services/app4/paye/PdBank.service';
 
 

@Component({
  selector: 'app4-CheckSadere-Rep',
  templateUrl: './CheckSadere-Rep.component.html',
  styleUrls: ['./CheckSadere-Rep.component.css']
})
 

export class CheckSadereRepComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @Input() CheckSadereRepOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  @ViewChild("UnSelectlist", { static: false }) UnSelectlist: DxListComponent;
  @ViewChild("Selectlist", { static: false }) Selectlist: DxListComponent;

  @ViewChild("pdpayrecrowComp", { static: false }) pdpayrecrowComp: DxSelectBoxComponent;
  @ViewChild("tafComp", { static: false }) tafComp: DxSelectBoxComponent;
  
 
  selectedrow: any;
 
  taflist: any = {};
  myModel: CheckSadereRep;
  pdPayrecRowForChekList: PdPayrecRowForChek[];
 
  devDataTaf: any = {};
 
 
  loaddata: loadingCl;
  pdBanklist:  PdBank[] = [];
 
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private pdPayrecRepService: PdPayrecRepService,
    private pdPayrecService: PdPayrecService,
    private tafsiliService: TafsiliService,
    private pdBankService: PdBankService,

    private excelService: ExcelService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 
 
 
    this.loadServ.create(this.loaddata,8);



    this.myModel = pdPayrecRepService.getCheckSadereRepEmpty();

    this.myModel.option.dateS_S = this.localst.yearnow_Class_get().dateS_s;
    this.myModel.option.dateE_S = this.localst.dateEmruz.dateString;


 //   this.model.option.gSelect.

  }
  
  ngOnInit() {


    this.loadServ.showloading(this.loaddata, 10);
    this.pdBankService.getall(1).subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 10);
      this.pdBanklist = res.data;
    
    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    var optaf = { dasteIds: [-1001, -1002], filter: true, visId: -1, visTbl: 'PdPayrec' }

    this.loadServ.showloading(this.loaddata, 11);
    this.tafsiliService.GetTafsiliForSmallByDastes(optaf).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.taflist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });



    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



    this.loadServ.showloading(this.loaddata, 2);
    this.pdPayrecService.getPayrecRowForCheckForRep()
      .subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 2);
     
        this.pdPayrecRowForChekList = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });




    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.GetTafsiliForSmall()
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 4);


        this.devDataTaf = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });

  
  }

 
 


  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 
 
  

 



  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,'  hh ');

 

  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }

  onClickReflesh() {

    
    
    this.loadServ.showloading(this.loaddata, 5);
    this.pdPayrecRepService.GetCheckSadereRep(this.myModel.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        //tyytu676767
        this.myModel = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
   
  }
 
  OnMyCancelEvent(e) {
 
  }
  OnMySaveEvent(e) {

 

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

   
 

  firstclreate = true;
 

  dropopened(e) {


    e.component._popup.option('width', 500);
    
    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

     

      this.firstclreate = false;

    }
  }

   

  onCellPrepared(e) {

   //// console.log(e.data);
   // if (e.data == undefined)
   //   return;


   // if (e.column.dataField == "prcMan") {

   //   if (e.data.anDeedC == 0) {
   //     if (e.data.prcMan > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.amtMan < 0) {
   //       e.cellElement.style.backgroundColor = '#ca0000';

   //     }
 
   //   }
   // }
 
   // if (e.column.dataField == "prcOut") {

   //   if (e.data.anDeedC == 0) {
        

   //     if (e.data.prcOut > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.prcOut < 0) {
   //       e.cellElement.style.color = '#ca0000';

   //     }

   //   }
   // }


   // if (e.column.dataField == "fiOut") {

   //   if (e.data.anDeedC == 0) {



   //     if (e.data.fiOut > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.fiOut < 0) {
   //       e.cellElement.style.color = '#ca0000';

   //     }
   //   }
   // }


 

   



  }
  onRowPrepared(e) {

   // // console.log(e.data);
   // if (e.data == undefined)
   //   return;
     
   // //hjg5565655
   // if (e.data.kind == 9  ) {
 
   //   e.rowElement.style.color = '#19bf00';
   // //  e.rowElement.style.border = '1px solid';

     
   // }
   //else if (e.data.kind == -9) {

   //   e.rowElement.style.color = '#ca0000';
   // //  e.rowElement.style.border = '1px solid';

   // }

   //  if (e.data.anDeedC == 0) {


   //   e.rowElement.style.border = '1px solid';

   // }





  }

  
  
  RowPrepared(e) {

    if (e.key < 0) {
      /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }

    //e.rowElement.css('background', 'green');
  }


  onClickgoSanad() {

    //yttyyt566767
    var key = this.dataGrid.focusedRowKey;
    if (key == null)
      return;
 
  }


  

  saveuioption() {

    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw4008 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw4008.length; i++) {
      this.localst.optioncli.gw4008[i] *= e;
    }

  }
  defwidth() {

    this.localst.optioncli.gw4008 = this.localst.optionClient_readDefault('gw4008');

  }


  onClickprint(kind) {


 


    //var mdata = { model: JSON.parse(JSON.stringify(this.model)), printKind: kind, showRial: this.showRial }

    //this.onPrint.emit(mdata);

  }
 
}


