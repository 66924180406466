import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import {  Diri, Morur, MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../../_models/General/saleMali';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { ExcelService } from '../../../../_services/excel.service';
import { LoadingService } from '../../../../_services/loading.service';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { DafKolService } from '../../../../_services/app1/rep/DafKol.service';
import { DafKol } from '../../../../_models/app1/rep/DafKol';
import { MyTimeService } from '../../../../_services/myTime.service';
import {  DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ApiRet } from '../../../../_models/General/ApiRet';
 
 
export interface DialogData {
  animal: string;
  name: string;
} 

@Component({
  selector: 'app-DafKol-list',
  templateUrl: './DafKol-list.component.html',
  styleUrls: ['./DafKol-list.component.css']
})
 

export class DafKolListComponent implements OnInit {

  openprint: boolean;
 
  @Output() OnMyprint = new EventEmitter<any>();
  @Output() OnMyprintAll = new EventEmitter<any>();

  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;

  //کلاس کالا کامل برای نمایش ردیف
  myDeatail: DafKol;
  openDetail: boolean = false;

  mymodel: DafKol;
  modlelist: DafKol[]=[];

  //نوع صدور
  kindr: any= [{ id: 1, name: 'روزانه' }, { id: 2, name: 'ماهیانه' }];
  //نوع صدور انتخاب شده
  kindrv = 2;

  //مانده سند
  mandeh: number = 0;;
  valdata: any;

  bastanCSS: string;

 focudedCom:string ='none'
 

  loaddata: loadingCl;
  

  constructor(private userService: UserService, private alertify: AlertifyService, private MorurService: MorurService, 
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private sanadService: SanadService,
    private dafKolService: DafKolService,
    private excelService: ExcelService,
    private hesabService: HesabService,
    private saleMaliService: SaleMaliService,
    private localst: Localst,
    private myTimeService: MyTimeService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 8);

    this.openprint = false;


    this.mymodel = dafKolService.getDafKolEmpty();
    this.myDeatail = dafKolService.getDafKolEmpty();
    //رنج یک ماه اخیر میگیریم
    let start = this.localst.yearnow_Class_get().dateS;
    let end = myTimeService.addmonth(start.toString(), 1);
    this.mymodel.dateA_S = myTimeService.addslashtostring(start.toString());
    this.mymodel.dateB_S = myTimeService.addslashtostring(end.toString());
    this.mymodel.dateS_S = this.mymodel.dateB_S;

    this.mymodel.year = this.localst.yearnow_get();


    this.bastanCSS = 'mydisble';
  }

  ngOnInit() {

    this.onFocusCom('dateA_S');
    //شماره سند اول تاریخ را بایند میکنیم
    this.dateAchang({ value: this.mymodel.dateA_S});
     
    this.onFocusCom('dateB_S');
    //شماره سند اخر تاریخ را بایند میکنیم
    this.dateBchang({ value: this.mymodel.dateB_S });

    this.loadgrid();
    this.loadmandeh();
  }
 
 
  onFocusedRowChanged(e) {


 
 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {



    //  return;
    //}



    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

  


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }


  printmoruroption(morur : MorurOption) {



     

  }

  onClicklevel(level: number) {

    this.dxgrid.instance.clearFilter();

 

 
 


  }
 
 
 


   
  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' مروز حساب ها ');

  }
  onClickprint() {


    //var t = [];
    //for (var i = 0; i < this.modlelist.length; i++) {

    //  t.push(this.modlelist[i]);
    //}


    //var mdata = { orgrows: t,   orgheader: this.morurOption}

    //this.OnMyprint.emit(mdata);

  }
  radChanged(e) {
    //اگر سند مدیریتی بود
    if (e.value == 2) {

      this.loadgrid();

    }




  }
  onClicksodur() {

    if (this.mymodel.dateA_S == null || this.mymodel.dateB_S == null || this.mymodel.dateS_S == null) {

      this.alertify.message('فیلد تاریخ را تکمیل کنید');
      return;

    }

    this.loadServ.showloading(this.loaddata, 1);
    this.dafKolService.add(this.mymodel).subscribe((res: any) => {
      this.loadServ.hideloading(this.loaddata, 1);

      //console.log(data.vgf);

      // console.log(JSON.stringify(data));
      //ftghutr5555554
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }

      else {
        this.loadmandeh();
        //this.mymodel = res.data;
        this.loadgrid();
        this.alertify.success('سند با موفقیت ذخیره شد');
      }

    
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
    
    });
  }

  onClickAll(){



    this.OnMyprintAll.emit(this.localst.yearnow_get());


  }

  loadgrid() {


    this.loadServ.showloading(this.loaddata, 2);
    this.dafKolService.getall(this.localst.yearnow_get()).subscribe((res: any) => {
      this.loadServ.hideloading(this.loaddata, 2);

      //console.log(data.vgf);

      // console.log(JSON.stringify(data));
      //hjkyuhkiyu66
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }

      else {

        this.modlelist = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);

    });

  }

  onFocusCom(e) {

    this.focudedCom = e;

  }
  dateAchang(e) {
    if (this.focudedCom != 'dateA_S')
      return;

 
  
    this.loadServ.showloading(this.loaddata, 4);
    this.sanadService.getFirstsanad(this.mymodel.year, e.value.toString()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 4);
   
      if (data != null)
        this.mymodel.cShomareA = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      console.log("myerror:" + error)
    });



  }
  dateBchang(e) {
    if (this.focudedCom != 'dateB_S')
      return;

   

    this.loadServ.showloading(this.loaddata, 4);
    this.sanadService.getLastsanad(this.mymodel.year, e.value.toString()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 4);
      
      if (data != null)
        this.mymodel.cShomareB = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      console.log("myerror:" + error)
    });


  }
  cShomareAchang(e) {
    if (this.focudedCom != 'cShomareA')
      return;


    this.loadServ.showloading(this.loaddata, 4);
    this.sanadService.getFirstDate(this.mymodel.year, e.value).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 4);
  
      if (data != null)
        this.mymodel.dateA_S = data.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      console.log("myerror:" + error);
    });

  }
  cShomareBchang(e) {
    if (this.focudedCom != 'cShomareB')
      return;

    this.loadServ.showloading(this.loaddata, 4);
    this.sanadService.getLastDate(this.mymodel.year, e.value).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 4);

      if (data != null)
        this.mymodel.dateB_S = data.toString();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      console.log("myerror:" + error);
    });
  }

  onRowDblClick() {
    this.onClickdetail();
  }
  //نمایش جزییات
  onClickdetail() {

    this.openDetail = !this.openDetail;

    if (this.dxgrid == undefined)
      return;

    let key = this.dxgrid.focusedRowKey;
    if (key == null)
      return;

    this.myDeatail = this.dafKolService.getDafKolEmpty();


    this.loadServ.showloading(this.loaddata, 7);
    this.dafKolService.getbyid(key).subscribe((res: any) => {
      this.loadServ.hideloading(this.loaddata, 7);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }

      else {

        this.myDeatail = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);

    });


  }
 

  onDeleterow(e) {

    if (e.data.id == null)
      return;


      this.loadServ.showloading(this.loaddata, 6);
    this.dafKolService.delete(e.data.id).subscribe((res: any) => {
        this.loadServ.hideloading(this.loaddata, 6);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.loadmandeh();
          this.loadgrid();
        }


      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);

      });

    }
  onPrint(e) {

    if (e.data.id == null)
      return;
    //hjjkhy6666

    this.OnMyprint.emit(e.data.id);
   


  }

  loadmandeh() {

   


    this.loadServ.showloading(this.loaddata, 6);
    this.dafKolService.mandeh(this.localst.yearnow_get()).subscribe((res: any) => {
      this.loadServ.hideloading(this.loaddata, 6);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }
      else {

        this.mandeh = res.data;

        if (this.mandeh == 0)
          this.bastanCSS = 'myenable';
        else
          this.bastanCSS = 'mydisble';


      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);

    });

  }



  onClickbastan() {


    let y= this.localst.yearnow_get();

    let mess = ' آیا از بستن سال مالی ' + y.toString() + ' اطمینان دارید؟ ';

    this.valdata = { title: mess, rows: [' در بستن امکان برگشت وجود ندارد'], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }

   // DialogData 
    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {
 
      let dialogdata = result;

      if (dialogdata != null) {

 
        if (dialogdata.selectbutton == 'yesdone') {


          this.loadServ.showloading(this.loaddata, 2);
          this.saleMaliService.bastanSal(this.localst.yearnow_get()).subscribe((res: ApiRet) => {
            this.loadServ.hideloading(this.loaddata, 2);
 
            if (res.errors.length > 0) {
              this.alertify.showListString(res.errors);
              return;
            }

            else {

              this.alertify.success(res.mes);
            }


          }, error => {
            this.loadServ.hideloading(this.loaddata, 2);
            this.alertify.error(error);

          });


        }
      }
    });

 


  }


  

}


