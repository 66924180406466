import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../../_models/pagination';
 
import { Localst } from '../localst.service';
import { AllM, BankM, ChekM, DarM, DarMRow, Ghar, GharOprt, GharRow, HazM, HazMRow, HItem, HItemG, HItemGRow, KindHaz, Oprt, PayM, PerM, TahvilM, TahvilMRow } from '../../_models/app99/app99model';
import { ApiRet } from '../../_models/General/ApiRet';
 
 

 

let EmptyAllM: AllM ={
  id: -1,
  sId: null,
  cA: null,
  cB: null,
  permId: null,
  perm: null,
  gharId: null,
  ghar: null,
  date: null,
  chekMId: null,
  chekM: null,
  hItemId: null,
  hItem: null,
  cnt: null,
  cost: null,
  costAll: null,
  costTakh: null,
  moien: null,
  bankmId: null,
  bankm: null,
  dis: null,
  bed: null,
  bes: null,
  unLog: null,
  dtLog: null,
}

let EmptyBankM: BankM ={
  id: -1,
  name: null,
  unLog: null,
  dtLog: null,
}

let EmptyChekM: ChekM ={
  id: 0,
  shomare: '',
  dateSabt: 0,
  dateSabt_S: null,
  dateVos: 0,
  dateVos_S: null,
  cost: 0,
  isDarOrPar: false,
  personId: -1,
  personN: null,
  vaziat: 1,
  vaziatN: null,
  unLog: null,
  dtLog: null,
}

let EmptyGhar: Ghar ={
  id: -1,
  code:0,
  name: null,
  permId: null,
  perm: null,
  permN: null,
  visitormId:null,
 
  visitormN: null,
  dateA: 0,
  dateB: 0,
  dateC: 0,
  vaziat: 1,
  vaziatN: '',
  dis: null,
  costAll: 0,
  costAdd: 0,
  costRem: 0,
  costTakh: 0,
  costMash: 0,
  rowsAsli: [],
  rowsAdd: [],
  rowsRem: [],
  gharOprts:[],
  unLog: null,
  dtLog: null,
  dateA_S: '',
  dateB_S: '',
  dateC_S: '',
  finish: false,
  hItemGId: null,
  cGhar: null,
  costSumDar: 0,
  costMandeh:0,
}

let EmptyGharRow: GharRow ={
  id: -1,
  gharId: -1,
  kind: -1,
  hItemId: null,
  hItemN: null,
  hItem: null,
  dis: null,
  cnt: null,
  cost: null,
  costAll: null,
  costFi: null,
  costAllFi: null,
  darsad: 0,
}
let EmptyGharOprt: GharOprt = {
  id: 0,
  gharId: 0,
  oprtId: 0,
  oprtN: null
}

let EmptyHItem: HItem= {
  id: -1,
  name: null,
  costFi: null,
  unLog: null,
  dtLog: null,
}

let EmptyKindHaz: KindHaz= {
  id: -1,
  name: null,
  unLog: null,
  dtLog: null,
}

let EmptyPerM: PerM ={
  id: -1,
  cMelli: null,
  name: null,
  tell: null,
  mobile: null,
  adress: null,
  dis: null,
  moaref: null,
  dateT: 0,
  rate: 0,
 visitor :false,
  limit: 0,
  radif: 0
}



let EmptyOprt: Oprt ={
  id: -1,
  name: null,
  dis: null,
  isActive: true,
  radif: null,
}







let EmptyDarM: DarM= {
  id: null,
  code: 0,
  sId: 0,
  babKind: 1,
  permId: null,
  permN: null,
  perm: null,
  visitormId: null,
  visitormN: null,

  dateA: 0,
  dateA_S: null,
  dis: null,
  costAll: 0,
  parKind: null,
  chekmId: null,
  chekm: null,
  bankmId: null,
  bankm: null,
  mos: null,
  rows: [],
  unLog: null,
  dtLog: null,
}

let EmptyDarMRow:  DarMRow= {
  id: null,
  darMId: -1,
  gharId: null,
  gharDis: null,
  cost: null,
  parKind: 0,
  dateA: 0,
  dis: ''
}

let EmptyHazM: HazM ={
  id: null,
  code: 0,
  sId: -1,
  permId: null,
  permN: null,
  perm: null,
  visitormId: null,
  visitormN: null,

  dateA: 0,
  dateA_S: null,
  dis: null,
  costAll: 0,
  parKind: null,
  chekmId: null,
  chekm: null,
  bankmId: null,
  bankm: null,
  mos: null,
  rows: [],
  unLog: null,
  dtLog: null,
  kindHazId: null,
  kindHazN: null,
  kindHaz: null,
  babKind:1
}

let EmptyTahvilM: TahvilM = {
  id: -1,
  code: 0,
  gharId: null,
  permId: null,
  permN: null,
  dateA: 0,
  gharDis: null,
  dis:null,
  dateA_S: null,
  rows: []
}

let EmptyTahvilMRow :TahvilMRow ={
  id: 0,
  hIthem:  null,
  hIthemN: null,

  cntAsli:0,
  cntman: 0,
  cnt: 0,
  tahvilMId: 0
}


let EmptyPayM: PayM = {
 
  id: -1,
  code: 0,
  sId: 0,
  permId: null,
  permN: null,
  dateA: 0,
  dateA_S: null,
  dis: null,
  costAll: 0,
  parKind: null,
  chekmId: null ,
  bankmId: null ,
  chekm: null,
  mos: null,
  unLog: null,
  dtLog: null,
conform: null,
 
}

let ParKindList: any[] = [
  {
  id: 1,
    name: 'نقد'
  },
  {
    id: 2,
    name: 'بانک'
  },
  {
    id: 5,
    name: 'دستگاه پوز'
  },
  {
    id: 3,
    name: 'چک'
  },

  {
    id: 4,
    name: 'تعهدی'
  },
]

let BabKindList: any[] = [
  {
    id: 1,
    name: 'بابت قرارداد'
  },
  {
    id: 2,
    name: 'متفرقه'
  } 
]

let Bab2KindList: any[] = [
  {
    id: 1,
    name: 'بابت قرارداد'
  },
  {
    id: 2,
    name: 'بدون قرارداد'
  }
]


let vaziatList: any[] = [
  {
    id: -1,
    name: 'همه'
  },
  {
    id: 1,
    name: 'قبل از اجرا'
  },
  {
    id: 2,
    name: 'درحال اجرا'
  },
  {
    id: 3,
    name: 'پایان یافته'
  }
]


let EmptyHazMRow:  HazMRow ={
  id: null,
  permId: null,
  permN: null,
  garId: null,
  garDis: null,
  hItemId: null,
  hItemN: null,
  dis: null,
  cnt: 0,
  cost: 0,
  costAll: 0,
  darsad: 0,
  cntAllow: 0,
  parKind: 0,
  dateA: 0
}



let EmptyHItemG: HItemG = {
  id: -1,
  code: null,
  name: null,
  cntItem: 0,
  rows: []
}


let EmptyHItemGRow: HItemGRow = {
  id: 0,
  hItemId: null,
  name: null,
  costFi: 0,
  costFi2: 0,
  amt: 0,
  radif:1
}

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class App99Service {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }



  //////////////   AllM
  AllM_getall(): Observable<AllM[]> {
    let apiadress = '/api/App99/AllM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<AllM[]>(this.origin + apiadress, { 'headers': headers });
  }
  AllM_getbyid(id): Observable<AllM> {
    let apiadress = '/api/App99/AllM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<AllM>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  AllM_add(item: AllM) {
    let apiadress = '/api/App99/AllM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  AllM_getAllMEmpty(): AllM {

 
    return JSON.parse(JSON.stringify(EmptyAllM));

  }
 
  AllM_update(item: AllM) {
    let apiadress = '/api/App99/AllM_update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  AllM_delete(AllMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/AllM_delete?id=' + AllMId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  AllM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/AllM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
  AllM_mandehPerm(permid: number): Observable<any> {
    let apiadress = '/api/App99/AllM_mandehPerm?permid=' + permid.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }


  //BankM[]
  //////////////   BankM
  BankM_getall(comp): Observable<ApiRet> {
    let apiadress = '/api/App99/BankM_getall';
    var headers = this.localstService.header_get(apiadress, comp);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  BankM_getbyid(id): Observable<BankM> {
    let apiadress = '/api/App99/BankM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<BankM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  BankM_add(item: BankM) {
    let apiadress = '/api/App99/BankM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  BankM_getBankMEmpty(): BankM {


    return JSON.parse(JSON.stringify(EmptyBankM));

  }

  BankM_update(item: BankM) {
    let apiadress = '/api/App99/BankM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  BankM_delete(BankMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/BankM_delete?id=' + BankMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  BankM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/BankM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }




  //////////////   ChekM
  ChekM_getall(): Observable<ChekM[]> {
    let apiadress = '/api/App99/ChekM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ChekM[]>(this.origin + apiadress, { 'headers': headers });
  }
  ChekM_getbyid(id): Observable<ChekM> {
    let apiadress = '/api/App99/ChekM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<ChekM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  ChekM_add(item: ChekM) {
    let apiadress = '/api/App99/ChekM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  ChekM_getChekMEmpty(): ChekM {


    return JSON.parse(JSON.stringify(EmptyChekM));

  }

  ChekM_update(item: ChekM) {
    let apiadress = '/api/App99/ChekM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  ChekM_delete(ChekMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/ChekM_delete?id=' + ChekMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  ChekM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/ChekM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }



  //////////////   Ghar
  Ghar_getall(): Observable<Ghar[]> {
    let apiadress = '/api/App99/Ghar_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Ghar[]>(this.origin + apiadress, { 'headers': headers });
  }
  Ghar_GetAllAsGhartiny(id  , permid): Observable<Ghar[]> {
    let apiadress = '/api/App99/Ghar_GetAllAsGhartiny?id=' + id.toString() +'&permId='+ permid.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Ghar[]>(this.origin + apiadress, { 'headers': headers });
  }
  Ghar_getbyid(id): Observable<Ghar> {
    let apiadress = '/api/App99/Ghar_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<Ghar>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  Ghar_add(item: Ghar) : Observable<ApiRet> {
    let apiadress = '/api/App99/Ghar_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  Ghar_getGharEmpty(): Ghar {


    return JSON.parse(JSON.stringify(EmptyGhar));

  }
  Ghar_vaziatlist(): any[] {


    return vaziatList;

  }

  Ghar_update(item: Ghar): Observable<ApiRet> {
    let apiadress = '/api/App99/Ghar_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  Ghar_delete(GharId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/Ghar_delete?id=' + GharId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  Ghar_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/Ghar_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   GharRow
  GharRow_getall(): Observable<GharRow[]> {
    let apiadress = '/api/App99/GharRow_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<GharRow[]>(this.origin + apiadress, { 'headers': headers });
  }
  GharRow_getbyid(id): Observable<GharRow> {
    let apiadress = '/api/App99/GharRow_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<GharRow>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  GharRow_add(item: GharRow) {
    let apiadress = '/api/App99/GharRow_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  GharRow_getGharRowEmpty(): GharRow {


    return JSON.parse(JSON.stringify(EmptyGharRow));

  }
  Ghar_getEmptyGharOprtEmpty(): GharOprt {


    return JSON.parse(JSON.stringify(EmptyGharOprt));

  }

  GharRow_update(item: GharRow) {
    let apiadress = '/api/App99/GharRow_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  GharRow_delete(GharRowId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/GharRow_delete?id=' + GharRowId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  GharRow_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/GharRow_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  GharRow_getAllowitem( gharid ,hazmId): Observable<any[]> {
    let apiadress = '/api/App99/GharRow_getAllowitem?gharid=' + gharid + '&hazmId=' + hazmId;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<any[]>(this.origin + apiadress, { 'headers': headers });
  }
  GharRow_getByHItemGId(hItemGId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/GharRow_getByHItemGId?hItemGId=' + hItemGId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  //////////////   HItem
  HItem_getall(): Observable<HItem[]> {
    let apiadress = '/api/App99/HItem_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<HItem[]>(this.origin + apiadress, { 'headers': headers });
  }
  HItem_getbyid(id): Observable<HItem> {
    let apiadress = '/api/App99/HItem_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<HItem>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  HItem_add(item: HItem) {
    let apiadress = '/api/App99/HItem_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  HItem_getHItemEmpty(): HItem {


    return JSON.parse(JSON.stringify(EmptyHItem));

  }

  HItem_update(item: HItem) {
    let apiadress = '/api/App99/HItem_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  HItem_delete(HItemId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/HItem_delete?id=' + HItemId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  HItem_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/HItem_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   KindHaz
  KindHaz_getall(): Observable<KindHaz[]> {
    let apiadress = '/api/App99/KindHaz_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<KindHaz[]>(this.origin + apiadress, { 'headers': headers });
  }
  KindHaz_getbyid(id): Observable<KindHaz> {
    let apiadress = '/api/App99/KindHaz_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<KindHaz>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  KindHaz_add(item: KindHaz) {
    let apiadress = '/api/App99/KindHaz_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  KindHaz_getKindHazEmpty(): KindHaz {


    return JSON.parse(JSON.stringify(EmptyKindHaz));

  }

  KindHaz_update(item: KindHaz) {
    let apiadress = '/api/App99/KindHaz_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  KindHaz_delete(KindHazId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/KindHaz_delete?id=' + KindHazId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  KindHaz_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/KindHaz_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   PerM
  PerM_getall(visitor : number): Observable<PerM[]> {
    let apiadress = '/api/App99/PerM_getall?visitor=' + visitor;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<PerM[]>(this.origin + apiadress, { 'headers': headers });
  }
  PerM_getallForList(visitor: number): Observable<any[]> {
    let apiadress = '/api/App99/PerM_getallForList?visitor=' + visitor;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<PerM[]>(this.origin + apiadress, { 'headers': headers });
  }
  PerM_getallForhaveGhars(visitor: number): Observable<any[]> {
    let apiadress = '/api/App99/PerM_getallForhaveGhars?visitor=' + visitor;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<PerM[]>(this.origin + apiadress, { 'headers': headers });
  }
  PerM_getbyid(id): Observable<PerM> {
    let apiadress = '/api/App99/PerM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<PerM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  PerM_add(item: PerM) {
    let apiadress = '/api/App99/PerM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  PerM_getPerMEmpty(): PerM {


    return JSON.parse(JSON.stringify(EmptyPerM));

  }

  PerM_update(item: PerM) {
    let apiadress = '/api/App99/PerM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  PerM_delete(PerMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/PerM_delete?id=' + PerMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  PerM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/PerM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   Oprt
  Oprt_getall(): Observable<Oprt[]> {
    let apiadress = '/api/App99/Oprt_getall' ;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<Oprt[]>(this.origin + apiadress, { 'headers': headers });
  }
  Oprt_getallForList(): Observable<any[]> {
    let apiadress = '/api/App99/Oprt_getallForList';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<Oprt[]>(this.origin + apiadress, { 'headers': headers });
  }
  
  Oprt_getbyid(id): Observable<Oprt> {
    let apiadress = '/api/App99/Oprt_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<Oprt>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  Oprt_add(item: Oprt) {
    let apiadress = '/api/App99/Oprt_add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  Oprt_getOprtEmpty(): Oprt {


    return JSON.parse(JSON.stringify(EmptyOprt));

  }

  Oprt_update(item: Oprt) {
    let apiadress = '/api/App99/Oprt_update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  Oprt_delete(OprtId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/Oprt_delete?id=' + OprtId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  Oprt_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/Oprt_getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  //////////////   DarM
  DarM_getall(): Observable<DarM[]> {
    let apiadress = '/api/App99/DarM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<DarM[]>(this.origin + apiadress, { 'headers': headers });
  }
  DarM_getbyid(id): Observable<DarM> {
    let apiadress = '/api/App99/DarM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<DarM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  DarM_add(item: DarM): Observable<ApiRet> {
    let apiadress = '/api/App99/DarM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  DarM_getDarMEmpty(): DarM {


    return JSON.parse(JSON.stringify(EmptyDarM));

  }

  DarM_update(item: DarM) : Observable<ApiRet> {
    let apiadress = '/api/App99/DarM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  DarM_delete(DarMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/DarM_delete?id=' + DarMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  DarM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/DarM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   DarMRow
  DarMRow_getall(): Observable<DarMRow[]> {
    let apiadress = '/api/App99/DarMRow_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<DarMRow[]>(this.origin + apiadress, { 'headers': headers });
  }
  DarMRow_getbyid(id): Observable<DarMRow> {
    let apiadress = '/api/App99/DarMRow_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<DarMRow>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  DarMRow_add(item: DarMRow) {
    let apiadress = '/api/App99/DarMRow_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  DarMRow_getDarMRowEmpty(): DarMRow {


    return JSON.parse(JSON.stringify(EmptyDarMRow));

  }

  DarMRow_update(item: DarMRow) {
    let apiadress = '/api/App99/DarMRow_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  DarMRow_delete(DarMRowId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/DarMRow_delete?id=' + DarMRowId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  DarMRow_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/DarMRow_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }



  DarMRow_getParKindList(): any[] {

    return ParKindList;

  }


  DarMRow_getBabKindList(): any[] {

    return BabKindList;

  }





  //////////////   HazM
  HazM_getall(): Observable<HazM[]> {
    let apiadress = '/api/App99/HazM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<HazM[]>(this.origin + apiadress, { 'headers': headers });
  }
  HazM_getbyid(id): Observable<HazM> {
    let apiadress = '/api/App99/HazM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<HazM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  HazM_add(item: HazM): Observable<ApiRet> {
    let apiadress = '/api/App99/HazM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  HazM_getHazMEmpty(): HazM {


    return JSON.parse(JSON.stringify(EmptyHazM));

  }

  HazM_update(item: HazM): Observable<ApiRet> {
    let apiadress = '/api/App99/HazM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  HazM_delete(HazMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/HazM_delete?id=' + HazMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  HazM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/HazM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   HazMRow
  HazMRow_getall(): Observable<HazMRow[]> {
    let apiadress = '/api/App99/HazMRow_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<HazMRow[]>(this.origin + apiadress, { 'headers': headers });
  }
  HazMRow_getbyid(id): Observable<HazMRow> {
    let apiadress = '/api/App99/HazMRow_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<HazMRow>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  HazMRow_add(item: HazMRow) {
    let apiadress = '/api/App99/HazMRow_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  HazMRow_getHazMRowEmpty(): HazMRow {


    return JSON.parse(JSON.stringify(EmptyHazMRow));

  }

  HazMRow_update(item: HazMRow) {
    let apiadress = '/api/App99/HazMRow_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  HazMRow_delete(HazMRowId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/HazMRow_delete?id=' + HazMRowId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  HazMRow_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/HazMRow_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  HazMRow_getParKindList():  any[] {
   
    return ParKindList;
     
  }



  HazMRow_getBabKindList(): any[] {

    return Bab2KindList;

  }




  //////////////   TahvilM
  TahvilM_getall(): Observable<TahvilM[]> {
    let apiadress = '/api/App99/TahvilM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<TahvilM[]>(this.origin + apiadress, { 'headers': headers });
  }
  TahvilM_getbyid(id): Observable<TahvilM> {
    let apiadress = '/api/App99/TahvilM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<TahvilM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  TahvilM_add(item: TahvilM) {
    let apiadress = '/api/App99/TahvilM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  TahvilM_getTahvilMEmpty(): TahvilM {


    return JSON.parse(JSON.stringify(EmptyTahvilM));

  }
  EmptyTahvilMRow_getEmpty(): TahvilMRow {


    return JSON.parse(JSON.stringify(EmptyTahvilMRow));

  }
  TahvilM_update(item: TahvilM) {
    let apiadress = '/api/App99/TahvilM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  TahvilM_delete(TahvilMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/TahvilM_delete?id=' + TahvilMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  TahvilM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/TahvilM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

  TahvilM_loaddataRow(editId, gharid): Observable<TahvilMRow[]> {
    let apiadress = '/api/App99/TahvilM_loaddataRow?editId=' + editId.toString() + '&gharid=' + gharid.toString();
    var headers = this.localstService.header_get(apiadress,null);
 

    return this.http.get<TahvilMRow[]>(this.origin + apiadress, { 'headers': headers });
  }
  //////////////   PayM
  PayM_getall(): Observable<PayM[]> {
    let apiadress = '/api/App99/PayM_getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<PayM[]>(this.origin + apiadress, { 'headers': headers });
  }
  PayM_getbyid(id): Observable<PayM> {
    let apiadress = '/api/App99/PayM_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    let v = this.http.get<PayM>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  PayM_add(item: PayM) {
    let apiadress = '/api/App99/PayM_add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  PayM_getPayMEmpty(): PayM {


    return JSON.parse(JSON.stringify(EmptyPayM));

  }

  PayM_update(item: PayM) {
    let apiadress = '/api/App99/PayM_update';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  PayM_delete(PayMId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/PayM_delete?id=' + PayMId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  PayM_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/PayM_getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  //////////////   HItemG
  HItemG_getall(): Observable<ApiRet> {
    let apiadress = '/api/App99/HItemG_getall';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  HItemG_getbyid(id): Observable<ApiRet>  {
    let apiadress = '/api/App99/HItemG_getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  HItemG_add(item: HItemG): Observable<ApiRet> {
    let apiadress = '/api/App99/HItemG_add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  HItemG_getHItemGEmpty(): HItemG {


    return JSON.parse(JSON.stringify(EmptyHItemG));

  }

  HItemGRow_getHItemGEmpty(): HItemGRow {


    return JSON.parse(JSON.stringify(EmptyHItemGRow));

  }
  HItemG_update(item: HItemG): Observable<ApiRet> {
    let apiadress = '/api/App99/HItemG_update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  HItemG_delete(HItemGId: number): Observable<ApiRet> {
    let apiadress = '/api/App99/HItemG_delete?id=' + HItemGId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  HItemG_getallCods(): Observable<string[]> {
    let apiadress = '/api/App99/HItemG_getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }

}
