import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Morur, MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';
import { templateJitUrl } from '@angular/compiler';



 
 
@Component({
  selector: 'app98-vmTajRep-ht',
  templateUrl: './vmTajRep-ht.component.html',
  styleUrls: ['./vmTajRep-ht.component.css']
})

 

export class vmTajRepHtComponent implements OnInit {

 
  @Input() inputdata: any;

  orgheader: any; 
  orgrows: any[];
  compKind: number;
  mah: string;
  reportname: string;

  nowdate: MyTimeClass;
 

  printpage: printpage;
  constructor(private userService: UserService, private alertify: AlertifyService, private MorurService: MorurService,
    private route: ActivatedRoute,
    private localst: Localst,
    private router: Router,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {


     templateJitUrl
    //54rh6urt6utrf
    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
      HNoemandeh: null
    };

   
  }
 
  ngOnInit() {

    

    if (this.inputdata == null)
      return;
       
    this.localst.sherkatInfo.name;
    this.orgheader = this.inputdata.modelData.op;
    this.orgrows = this.inputdata.modelData.rows;
    this.compKind = this.inputdata.compKind;


    var sum = this.inputdata.modelData.rowSum;
    if (sum != null)
      this.orgrows.push(sum);



    if (this.compKind ==1)
      this.reportname = 'گزارش تجمیعی';
  else  if (this.compKind == 2)
      this.reportname = 'گزارش نوبت وام';


    if (!this.orgheader.isDateFilter) {
      this.mah = 'کل بازه زمانی';
    }
    else {
      this.mah = ' از تاریخ ' + this.orgheader.dateS_S + ' تا تاریخ ' + this.orgheader.dateE_S;
    }
 

    if (this.inputdata == null)
      return;
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();

    let indexreaded = 0;


    let pageindex = 0;

 

    let datalegh = this.orgrows.length;
    let sumIndex = datalegh - 1;




    if (datalegh == 0)
      return;

    while (indexreaded < datalegh) {
      //pindex: number;
      //ppage: number;
      //ppages: number;

      //pagesum: any;
      //pagesumall: any;
      //mandeall: any;
      //rowsp: any[];

      this.printpage.pages.push(new pagep(pageindex));

      //this.printpage.pages[pageindex] = new pagep(pageindex);


      let counterpage = 0;
      var sumpage = 0;
      var rowpagesum = { name: 'جمع صفحه', bed: 0, bes: 0, mandehBed: 0, mandehBes: 0 };

      while (indexreaded < datalegh) {


        ///چون جمع ها است دو ردیف اخر را نادیده میگیریم
        if (indexreaded < datalegh - 1) {
          //شماره رذدیف
          this.orgrows[indexreaded].radif = indexreaded + 1;
          //جمع صفحه
          rowpagesum.bed += this.orgrows[indexreaded].bed;
          rowpagesum.bes += this.orgrows[indexreaded].bes;
          rowpagesum.mandehBed += this.orgrows[indexreaded].mandehBed;
          rowpagesum.mandehBes += this.orgrows[indexreaded].mandehBes;
          //جمع کل

          this.orgrows[sumIndex].bed += this.orgrows[indexreaded].bed;
          this.orgrows[sumIndex].bes += this.orgrows[indexreaded].bes;







        }


        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.orgrows[indexreaded]);



        //  console.log(skiprow);
        counterpage = counterpage + this.getskiprow(this.orgrows[indexreaded]);


        indexreaded++;
        counterpage++;
        if (counterpage > 18)
          break;

      }
      this.printpage.pages[pageindex].pagesum = rowpagesum;





      pageindex++;



    }

    let leghpage = this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }




  }
 


  public getskiprow(st: any): number {


    let skips: number[] = [];

         //<th style="width:35px;">ردیف</th>
         // <th style="width: 45px;">کد تفصیلی</th>
         // <th style="width: 45px;">کد ملی</th>
         // <th>طرف مقابل</th>
         // <th style="width: 120px;">گروه اشخاص</th>
         // <th style="width: 80px;">پس انداز دریافتی</th>
         // <th style="width: 80px;">رفاهی دریافتی</th>
         // <th style="width: 80px;">اقساط دریافتی</th>


         // <th style="width: 80px;">جمع کل دریافتی</th>

    if (this.compKind == 1) {
      skips.push(this.masurtest(st.radif, 25));
      skips.push(this.masurtest(st.tafC, 58));
      skips.push(this.masurtest(st.tafMelli, 78));
      skips.push(this.masurtest(st.tafN, 248));
      skips.push(this.masurtest(st.vmGrsNs, 148));
      skips.push(this.masurtest(st.prcDar1, 99));
      skips.push(this.masurtest(st.prcDar3, 99));
      skips.push(this.masurtest(st.prcDar2, 99));

      skips.push(this.masurtest(st.prcSumDar, 99));
    }
    else if (this.compKind == 2) {

      skips.push(this.masurtest(st.radif, 25));
      skips.push(this.masurtest(st.tafC, 58));
     // skips.push(this.masurtest(st.tafMelli, 35));
      skips.push(this.masurtest(st.tafN, 138));
      skips.push(this.masurtest(st.vmGrsNs, 149));
      skips.push(this.masurtest(st.prcDar1, 79));
      skips.push(this.masurtest(st.prcDar3, 79));
      skips.push(this.masurtest(st.prcDarSum31, 79));
      skips.push(this.masurtest(st.prcDar2, 79));

      skips.push(this.masurtest(st.prcSumDar, 79));
      skips.push(this.masurtest(st.prcVam, 79));
      skips.push(this.masurtest(st.prcVamMandeh, 79));
    }
   

    let max = 0;
    for (var i of skips) {
      if (i > max)
        max = i;
    }
    return max;
  }
  masurtest(test, maxWidth: number): number {


    //sum padding
    // maxWidth -= 4;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '12pt B Nazanin';

    if (test == null)
      test = '';
    const metrics = ctx.measureText(test.toString()).width;
    return +Math.floor(metrics / maxWidth);

  }


  resetFilters() {
   
 
  }
 

 

  onFocusedRowChanged(e) {


 

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

   
   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
    console.log(d.outerHTML);


    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


  //  let popupWinindow
   // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
  //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 12pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: white;
}


 

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

     .t-table {
          width: 277mm;
          margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
            font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
            word-break: break-all;
          }

          .t-table th {
           font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
         
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
  font: 12pt "B Nazanin" ;
  font-weight:400;
          width: 277mm;
         margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
          font-family: 'B Nazanin';
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }

              .t-headerB {
        width: 277mm;
        margin-right: 7mm;
        margin-top: 0;
        direction: rtl;
        font: 12pt "B Nazanin";
        font-weight: 400;
        text-align: right;
        height: 20px;
      }

        .t-headerB td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          padding: 5px 5px 5px 5px;

   
        }

        .grayrow {
        }

@page {
   size: A4 landscape;
   margin: 0; 
 
}
@media print {
 html, body {
    width: 292mm;
    height: 205mm;

  }
    div.centerOnPrintedPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }
  /* ... the rest of the rules ... */

body {
  width: 100%;
  height: 100%;
  padding: 0mm;
  margin: 0mm auto;

  font: 12pt "B Nazanin";
}


 .page {

display:block; page-break-before:always;
  height: 205mm;
  width: 297mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;

  border-radius: 0px;


}




 


        .t-table {
          width: 277mm;
           margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;

        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
            word-break: break-all;
          }

          .t-table th {
            font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
          width: 277mm;
        margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }

              .t-headerB {
        width: 277mm;
        margin-right: 7mm;
        margin-top: 0;
        direction: rtl;
        font: 12pt "B Nazanin";
        font-weight: 400;
        text-align: right;
        height: 20px;
      }

        .t-headerB td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          padding: 5px 5px 5px 5px;

   
        }

        .grayrow {
        }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}


//popupWin.document.write(`
//      <html>
//        <head>
//          <title>Print tab</title>
//          <style>
//          //........Customized style.......

// body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//  background-color: #FAFAFA;
//  font: 8pt "Tahoma";
//}

//* {
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}

//.page {
    
//  width: 297mm;
//  min-height: 210mm;
//  padding: 5mm;
//  margin: 10mm auto;
//  border: 1px #D3D3D3 solid;
//  border-radius: 5px;
//  background: white;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

 
 


// -webkit-transform: rotate(-90deg) ; 
//     -moz-transform:rotate(-90deg) ;

//     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

//}

//.subpage {
//  padding: 5mm;
//  border: 5px rgb(128, 128, 128) solid;
//  height: 200mm;
//  outline: 5mm #D3D3D3 solid;
//}



 
 


//@media print {

//   @page {size: A4  ;

// margin: -10;


//}
 
//  html, body {
 
    
//  }

//  .page {
//    margin: 0;
    
//    border: initial;
//    border-radius: initial;
//    width: initial;
//    min-height: initial;
//    box-shadow: initial;
//    background: initial;
//    page-break-after: always;
//  }
//  #section-to-print, #section-to-print * {
//    visibility: visible;
//  }

//  #section-to-print {
//    position: absolute;
//    left: 0;
//    top: 0;
//  }

//}



//          </style>
//        </head>
//    <body onload="window.print();window.close()">${d.innerHTML}</body>
//      </html>`
//);
