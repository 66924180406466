import { PdPayrecRow } from "../opr/PdPayrec";
import { PdMove } from "../opr/PdMove";

export class PdPayrecTazminiRep {
  option: PdPayrecTazminiRepOps;
  rows: PdPayrecTazminiRepRow[];
  constructor() {
    this.option = new PdPayrecTazminiRepOps();
    this.rows = [];

  }
}

export class PdPayrecTazminiRepOps {
  kindType: number;
  showTazmini: boolean;
  showSafte: boolean;
  showZemanat: boolean;
  constructor() {


  }
}

export class PdPayrecTazminiRepRow {
  id: number;
  radif: number;
  pdPayrecRow: PdPayrecRow;
  lastPdMove: PdMove;
  lastTaf: string;
  lastPdBank: string;
  constructor() {


    }
}
