import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
 
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import saveAs from 'file-saver';
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';
import { AnSellService } from '../../../../_services/app3/opr/AnSell.service';
 
import { tabeventobj } from '../../../../_models/tabeventobj';
import { GeneralService } from '../../../../_services/General/General.service';
import DataSource from "devextreme/data/data_source";
import { DoAmel } from '../../../../_models/app2/glob/AnAmel';
import { AnAmelService } from '../../../../_services/app2/glob/AnAmel.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TaxGovService } from '../../../../_services/General/tax/taxGov.service';
import { elementAt, retry } from 'rxjs/operators';
import { AnSellEditComponent } from '../AnSell-edit/AnSell-edit.component';
import { AnSell } from '../../../../_models/app3/opr/AnSell';

 
//const express = require('express-session');
//const fs = require('fs');
//const bodyparser = require('body-parser');
//const https = require('https')

//const { Fido2Lib } = require("fido2-lib");

@Component({
  selector: 'app3-AnSell-list',
  templateUrl: './AnSell-list.component.html',
  styleUrls: ['./AnSell-list.component.css']
})
 


export class AnSellListComponent implements OnInit {
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Output() onShowPdlinkListEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;

  @Input() kindpage: number;

     //kind نوع صفحه
        //AnSellId ای دی سند
        //AnSellRowId ردیف فکوس
  @Input() inputdata: any;
  @Input() tabindex: number;

  items: any;
  openeprint: boolean;
  openPayrec: boolean;
  openeDuplcate : boolean;
  openeMerge : boolean;
  openedit: boolean;
  openeditId: number;

  openeditOnPish: boolean = false;
  openeditIdOnPish: number;


  sanadopen: boolean = false;
  sanadkind: number = 0;

  monthRang: number[];


  focusedAnSellRowId: number;


 



  valdata: DialogData;
  selectedrow: any;
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
   };


 
 



 // modlelist: AnSell[];
  modlelist: any = {};
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  @ViewChild("AnSellEditComp", { static: false }) AnSellEditComp: AnSellEditComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  mergeOption: any;
  constructor(private userService: UserService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private AnAmelService: AnAmelService,
    private loadServ: LoadingService,
    private taxGovService: TaxGovService,
    private alertify: AlertifyService,
    private AnSellService: AnSellService,
    private generalService: GeneralService,
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {

   this.modlelist = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: null
      }

    });
    this.loaddata = loadServ.getEmpty();
  //  this.loadServ = new loadingClass(8);
   // let sam: loadingCl = new loadingClass(5);
  //  let myTask: Task = new MyTask('someTask');
   // myTask.run("test");
    this.loadServ.create(this.loaddata,10);
   // this.loadServ2.showloading(7);
   // this.loadServ2.showed = true;

   // this.loadServ2.showloading(0);
   // this.monthRang = [localst.dateEmruz.month -3, localst.dateEmruz.month]
    this.monthRang = [1, 12]
 
    if (this.monthRang[0] < 1)
      this.monthRang[0] = 1;

    this.pagination = this.defaultpagation;
  
    this.openeprint = false;
    this.openPayrec = false;
    this.openedit = false;
    this.openeDuplcate = false;
    this.openeMerge = false;
    this.openeditId = -1;
    this.selectedrowkeys = [];
    this.selectedrowkeysIgnored = [];
    this.focusedAnSellRowId = -1;
    this.duplcateOption = {
      copyCSh : null,
      copyId: null,
      paseCSh: null,
      paseId: null,
      date : '1000/00/00'

    }
    this.mergeOption = {

      years: 0,
      byKind: 'inList',

      inCShomareS: 1,
      inCShomareE: 1,

      inDaterangS: null,
      inDaterangE: null,

      inDateOneS: null,
      inDateOneE: null,

      inList: [],
    }

    this.items = [
    { text: 'کپی سند'  , id:'copy'},
    { text: '        '  , id:'null'},
      { text: 'ویرایش'  , id:'edit'},
    { text: 'حذف'  , id:'delete'},
    { text: 'قطعی'  , id:'vaz4'},
    { text: ' لغو بررسی'  , id:'vaz2'},
    { text: 'بررسی'  , id:'vaz3'}
    //  items: [
    //    { text: 'Facebook' },
    //    { text: 'Twitter' }],
    //},
 
    ];








  } 
   
 
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);

 //c=67
    //if (event.ctrlKey && event.keyCode == 67) {

    //  this.onClickDublctaeAnSell();
    //}
    



  }
 


  tabClicked(active: boolean) {

    if (active) {

      this.gridContainer.instance.refresh();

      if (this.openedit) 
      this.AnSellEditComp.reloadGrid();

     // this.alertify.success('true');
    }
     
    else {

     // this.alertify.success('false');
    }
 

  }

  autoPayrec: boolean = false;
  mytabindex: number = -1;
  userType: number = 0;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    this.resetFilters();
    this.isdelete = "no";

    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
        // this.alertify.showListString(res.errors);
      }
      else {
        // this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });
    //اگه سند از کاردکس یا دیگر گزارش ها فراخوانده شده بود
       //kind نوع صفحه
        //AnSellId ای دی سند
        //AnSellRowId ردیف فکوس
    if (this.inputdata != null) {

      this.openeditId = this.inputdata.AnSellId;
      this.openedit = true;

    }


    this.autoPayrec = this.localst.GetRegistryByPath('App3-AnSell-[autoPay]').vBool;
      //this.route.data.subscribe(data => {
      
      //  this.pagination = data['users'].pagination;
      //});

   // console.log('+=+=+=+ : ' + JSON.stringify(this.hyperLinkOption));

    
    //e شامل اپشن زیر استe
       // temp.op_moruroption = { "HPAnSellId": e.HPAnSellId, "HPSabtId": e.HPAnSellId, "HPOpenOnInit": e.HPOpenOnInit };


    //اگر هیپرلینک ورودی داشت
    if (this.hyperLinkOption != null) {
      if (this.hyperLinkOption.HPAnSellId != null)
        this.openeditId = this.hyperLinkOption.HPAnSellId;

      if (this.hyperLinkOption.HPSabtId != null)
        this.focusedAnSellRowId = this.hyperLinkOption.HPSabtId;


      if (this.hyperLinkOption.HPAnSellId != null)
        this.openedit = this.hyperLinkOption.HPOpenOnInit;





    }
 

  }
  //ftabindex: number =-1;
  //ngOnChanges(changes: SimpleChanges) {
  //  //gtfut555
  //  const changIndex = changes.ftabindex.currentValue;
  // // var y = this.ftabindex;
  //  if (this.tabindex == -1) {
  //    this.tabindex = changIndex;
  //  }
  //  else if (this.tabindex == changIndex) {
  //    this.tabClicked(true);
  //  }
  //  else {
  //    this.tabClicked(false);
  //  }
  //}
  ngOnChanges(changes: any) {

    if (this.gridContainer != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.gridContainer.focusedRowIndex;
        //tyrtur555

        if (index == -1) {
          index = 0;
        }

        if (index > -1) {

          var yyy = this.gridContainer.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.gridContainer.instance.getCellElement(index, 0);
          var y = this.gridContainer.instance.getScrollable();
        
          if (yyy != undefined)
            y.scrollToElement(yyy);

          this.gridContainer.instance.refresh();
          //y.scrollTo(0,2);
          if (this.openedit)
            this.AnSellEditComp.reloadGrid();
          //y.sc(0);
        }
        

      }
      else {


      }
  }


 //change;
 // fchang(e) {

 //   // this.fchanged = true;
 //   this.change = new tabeventobj(this.ftabindex);
 //   this.change.value = e;


 //   this.onfchanged.emit(this.change);
 //   console.log(this.ftabindex);

 // }
  ngAfterViewInit() {

    this.loaddata.showed = true;
    this.cd.detectChanges();

  }

  onClicksearch() {

    this.load();
 
  }
  onClicksearchall() {
    this.resetFilters();
    this.load();
 
  }
  load() {

   
    //console.log(this.monthRang);

    let y = this.localst.yearnow_get().toString();
    let dA = this.monthRang[0].toString();
    let dB = this.monthRang[1].toString();

    if (dA.length < 2)
      dA = dA = '0' + dA;
    if (dB.length < 2)
      dB = dB = '0' + dB;

    dA = y + dA + '00';
    dB = y + dB + '99';
    this.loadServ.showloading(this.loaddata, 0);
    this.AnSellService.getall(this.localst.yearnow_get(),this.kindpage)
      .subscribe((res) => {
       
        this.loadServ.hideloading(this.loaddata, 0);
      
        this.modlelist = new DataSource({
          store: {
            type: "array",
            key: "id",
            data: res.data,
            
          }
 
        });
      
       // this.modlelist = res;

        this.gridContainer.scrolling.scrollByContent = true;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

  }

 
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';
 
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.load();
  }


  reloadrows(): void {
   // this.load();
  //  console.log("m:page refleshe done");
   // this.load();
  }

 
 
 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

  //  this.gridContainer.instance.refresh();
   // const dataGridDataSource = this.gridContainer.instance.getDataSource();
  //  dataGridDataSource.reload();

    //this.dss = new DataSource({
    //  store: this.modlelist,
    //  paginate: true,
    //  pageSize: 50
    //});

    //this.gridContainer.dataSource = this.dss;

    //this.dss.reload();
    ////this.dss.re
  
   // this.gridContainer.lo
    //var y =  this.gridContainer.dataSource;

    //this.gridContainer.instance.refresh();

    //this.gridContainer.dataSource = [];
    //this.gridContainer.instance.refresh();
    //this.gridContainer.dataSource = y;

    //const sc = this.gridContainer.instance.getScrollable();
 
    //sc.scrollTo({ left: 3000 });

  }

  showDel: boolean = false;

  currentDelete: AnSell = null;
  onClickDelete() {

    this.currentDelete = null;
    var key = this.gridContainer.focusedRowKey;

    if (key) {

      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (this.modlelist._items[j].id == key) {
          this.currentDelete = this.modlelist._items[j];
          this.showDel = true;

          break;
        }
      }

    }

   // this.openDialog();
    //  this.dodelete();

  }
  onClickCancelDelete() {

    this.showDel = false;
    this.currentDelete = null;

  }
   
  

  dodelete() {


   // var key = this.gridContainer.focusedRowKey;

    if (this.currentDelete) {


      this.loadServ.showloading(this.loaddata, 2);
      this.AnSellService.delete(this.currentDelete.id).subscribe((res) => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.showDel = false;
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;

        }
      
        this.load();
        this.refleshAnSellShomare();

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });


    }
     
  }



  OnMyCancelEvent(e) {
    console.log('cancel form');
   // console.log(e);
    if (e) {

      this.openedit = false;
      this.openeditOnPish = false;
      this.openeditId = -1;
      this.openeditIdOnPish = -1;
    }

  }
  onClickNew() {

    console.log('newopen');


    this.openedit = true;


  }


  onClickEdit() {


    if (this.selectedrow == null) return;


    this.openeditId = this.selectedrow.id;

    this.openedit = true;

  }
  OnMySaveEvent(e) {

 
    if (e.reloadlist) {

      this.load();

    }

    if (e.closepage) {

      this.openedit = false;
      this.openeditOnPish = false;

      this.openeditId = -1;
      this.openeditIdOnPish = -1;
    }

    if (this.autoPayrec) {

    //  this.openPayrec = true;


    }
 

  }


  refleshAnSellShomare() {

  

  }

 
  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

  onCellPrepared(e) {


    
    if (e.rowType === "data" && e.column.dataField === "vaziat") {
    //  if (e.data.Amount==2) 
    //  e.cellElement.style.backgroundColor  =  "green" ;
      // Tracks the `Amount` data field
      //e.watch(function () {
      //  return e.data.Amount;
      //}, function () {
      //  e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
      //})
    }



  }
  //فرمت
  //[calculateCellValue] = "calculateCellDateValue"
  //calculateCellDateValue(data) {
  //  let ddd = data.date.toString();
  //  ddd = ddd.substring(1, 2)

  //  return ddd;
  //}

  onClickReflesh() {

    
    this.load();




  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance,' لیست اسناد ');

 
  }

  onExporting(e) {

  }
  onClickPrint() {


  

    let indx = this.gridContainer.focusedRowIndex;

    if (indx == -1) {
      if (this.modlelist != undefined)
        if (this.modlelist._items.length > 0)
          indx = 0;
    }

    if (indx != -1) {
      var data = this.gridContainer.instance.getCellElement(indx, 'code');
      this.printnum = +data.textContent;
    }

    this.openeprint = true;

  



    //if (this.openeDuplcate)
    //  this.openeDuplcate = false;

  }

  pdpayrecEditId: number =-1;
  pdpayrecAnSellId: number = -1;
  onClickPdPayrec() {

   
    
    let id = this.gridContainer.focusedRowKey;

    if (id == null)
      return;

    for (var r = 0; r < this.modlelist._items.length; r++) {

      if (this.modlelist._items[r].id == id) {
        this.pdpayrecAnSellId = id;
        var pdid = this.modlelist._items[r].pdPayrecId;
        if (pdid == null) {
          this.pdpayrecEditId = -1;
        }
        else {
          this.pdpayrecEditId = pdid;
        }

        break;
      }
    }

    this.openPayrec = true;


  }
    //اگر پرینت از لیست سند صدا شود
  onCloseprint(e) {
 
    //console.log('click2');
    this.openeprint = false;

  }
  onPrintPrint(e) {

    this.onPrint.emit(e);
    this.openeprint = false;

  }
  //اگر پرینت از صفحه ویرایش سند صدا شود
  OnEditPrintEvent(e) {

    if (e == null)
      return;

    this.onPrint.emit(e);
    this.openeprint = false;

    

  }
  test8() {
   // this.gridContainer.instance.sc
    console.log("test8");
  //  this.gridContainer.instance.columnOption(5, "width", 900);
  }

  onClickDublctaeAnSell() {

   let id =  this.gridContainer.focusedRowKey;

    if (id == null)
      return;

    for (var r = 0; r < this.modlelist._items.length; r++) {

      if (this.modlelist._items[r].id == id) {


        this.duplcateOption.copyId = this.modlelist._items[r].id;
        this.duplcateOption.copyCSh = this.modlelist._items[r].cShomare;
        this.duplcateOption.paseId = null;
        this.duplcateOption.paseCSh = null;
        this.duplcateOption.date = null;
      //  this.duplcateOption.date = this.modlelist[r].date_String;

        if (this.modlelist._items[r].vaziat != 200) {
          this.alertify.alert('سند باید در وضعیت موقت قرار داشته باشد');
          return;

        }

        break;
      }

    }



    if (this.duplcateOption.copyId == null)
      return;

    this.openeDuplcate = true;
    if (this.openeprint)
      this.openeprint = false;

  }



  onClickMergeAnSell() {

    var keyss = this.gridContainer.selectedRowKeys;

   

   var selected = [];
   var ignored = [];

    if (keyss != null && keyss.length > 0)
    for (var i = 0; i < keyss.length; i++) {


      for (var j = 0; j < this.modlelist._items.length; j++) {
        if (keyss[i] == this.modlelist._items[j].id) {
          if (this.modlelist._items[j].vaziat == 200) {
            selected.push(this.modlelist._items[j].id);
          }
          else {
            ignored.push(this.modlelist._items[j].id);

          }

          break;
        }

      }

    }

    if (ignored.length > 0) {

      this.alertify.message(ignored.length.toString() +' ردیف نادیده گرفته شد ' + ' همه ی اسناد باید در وضعیت موقت قرار داشته باشند ');
     

    }

    if (selected.length > 0) {
      this.mergeOption.inList = selected;
      this.mergeOption.byKind = 'inList';

    }  


    this.openeMerge = true;
    if (this.openeprint)
      this.openeprint = false;
    if (this.openeDuplcate)
      this.openeDuplcate = false;

  }

  onCloseSSS(e) {

    this.openeDuplcate = false;

  }
  onClosemerge(e) {

    this.openeMerge = false;

  }
  onDuplcateDateChanged(e) {


    var newcshomare = -1;
    for (var r = 0; r < this.modlelist._items.length; r++) {

      newcshomare = this.modlelist._items[r].cShomare;

      if (this.modlelist._items[r].date <= e)
        break;
      

    }

    newcshomare++;

    this.duplcateOption.paseCSh = newcshomare;


  }


 
  onValueChangedrang(e) {

    this.load();
  }



  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.gridContainer.instance.columnCount(); i++) {
      var w = this.gridContainer.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw3002 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw3002.length; i++) {
      this.localst.optioncli.gw3002[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw3002 = this.localst.optionClient_readDefault('gw3002');
  }






  OnMyLoadSanad(e: any) {

    this.OnMyLoadSanadEvent.emit(e);

  }


  Sanadids: number[];
  onClicksanadjoiner(e) {

    this.Sanadids = this.gridContainer.selectedRowKeys;



    if (this.Sanadids.length < 1) {
      this.alertify.warning('هیچ سندی انتخاب نشده است');
      return;
    }

    this.sanadopen = !this.sanadopen;
    this.sanadkind = e;




  }


  closesanad() {
    this.sanadopen = false;
    this.sanadkind = 0;
  }

  doAmel: DoAmel;
  onClickAmel(e) {

    this.doAmel = this.AnAmelService.getDoAnAmelEmpty();
    this.doAmel.kind = e;
    this.doAmel.tableN = 'ansell';
    this.doAmel.sanadIds = [];
    let keys = this.gridContainer.selectedRowKeys as number[];
    if (keys.length == 0) {
      this.alertify.warning('ابتدا ردیفی را انتخاب کنید');
      return;
    }
    //frdty4356
    this.doAmel.sanadIds = keys;
   

    this.loadServ.showloading(this.loaddata, 13);
    this.AnAmelService.doAmel(this.doAmel).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 13);
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.message(res.mes);
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });
  }

  firstper: boolean = true;
  onPrepared() {

  

    if (this.firstper) {
      this.firstper = false;
      this.load();
    }
    

 
  }

  //onClickMoadEbtal() {

  //  // this.alertify.showListString(['توکن امضا الکترونیک شما فعال نیست']);


  //  var keys = this.gridContainer.selectedRowKeys;

  //  if (keys == null)
  //    return;


  //  this.loadServ.showloading(this.loaddata, 3);
  //  this.taxGovService.EditAsEbtalAnSell(keys).subscribe(res => {
  //    this.loadServ.hideloading(this.loaddata, 3);
  //    if (res.errors.length > 0) {

  //      this.alertify.showListString(res.errors);

  //    }
  //    else {
  //      this.alertify.frameless(res.mes);

  //    }
  //    this.load();
  //  }, error => {
  //    this.loadServ.hideloading(this.loaddata, 3);
  //    this.alertify.error(error);
  //  });


  //}

  onClickMoadModify() {

    // this.alertify.showListString(['توکن امضا الکترونیک شما فعال نیست']);


    var keys = this.gridContainer.selectedRowKeys;

    if (keys == null)
      return;


    this.loadServ.showloading(this.loaddata, 3);
    this.taxGovService.EditAsModifyAnSell(keys).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (res.errors.length > 0) {

        this.alertify.showListString(res.errors);

      }
      else {
        this.alertify.frameless(res.mes);

      }
      this.load();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
    });


  }
  //ttty() {

  //  this.alertify.success('hhhh');



  //}
  onAcceptinfo(e) {
    if (e == true) {
      this.showSendReaded = true;
      this.showSendTax = true;
      this.onClickMoad();
    }
    else {
      this.showSendTax = false;
      this.showSendReaded = false;
    }

  }
  showSendTax: boolean = false;
  showSendReaded: boolean = false;
  onClickMoad() {

   // this.alertify.showListString(['توکن امضا الکترونیک شما فعال نیست']);



    var keys = this.gridContainer.selectedRowKeys;

    if (keys == null)
      return;


    //هستاربرای برگشت فروش
    if (this.kindpage == 706 && !this.showSendReaded) {
      this.showSendTax = true;
      return;
    }

     var s=  this.localst.sherkatInfo_get();

    if (s.taxVer == null ||  s.taxVer == 0 || s.taxVer == undefined) {
      this.alertify.warning('نسخه کلایت شما بروز نیست ');
      this.alertify.warning('مرورگر را رفلش کنید ');
      return;
    }

    this.loadServ.showloading(this.loaddata, 3);
    this.taxGovService.sendAnSell(keys, s.taxVer).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (res.errors.length > 0) {

        this.alertify.showListString(res.errors);

      }
      else {
        this.alertify.frameless(res.mes);
       
      }
      this.load();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
    });


  }
  onClickChek() {
    var s = this.localst.sherkatInfo_get();

    if (s.taxVer == null || s.taxVer == 0 || s.taxVer == undefined) {
      this.alertify.warning('نسخه کلایت شما بروز نیست ');
      this.alertify.warning('مرورگر را رفلش کنید ');
      return;
    }

    this.loadServ.showloading(this.loaddata, 3);
    this.taxGovService.checkProcess(this.localst.yearnow_get(),s.taxVer).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      if (res.errors.length > 0) {
        this.load();
        this.alertify.showListString(res.errors);
   //5745756gfjhgh

      }
      else {
        this.alertify.message(res.mes);
   
      }
      this.load();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
    });


  }

  onClickEr() {

    var keys = this.gridContainer.selectedRowKeys;

    let a = [];
    //tgu65856856
   
    for (var r of this.modlelist._items) {


        for (var k of keys) {



        
            if (r.id == k) {

              if (r.taxErD == null)
                a.push("بدون خطا");
              else
                a.push(r.taxErD);
            }
           

        }
      

      }
    
    this.alertify.showListString(a);

  }
  onClicktest() {
   // const signer = require('pkcs11-smartcard-sign');
  //  var pkcs11 = new pkcscls.PKCS11();
 
    //const f2l = new Fido2Lib({
    //  timeout: 42,
    //  rpId: "example.com",
    //  rpName: "ACME",
    //  rpIcon: "https://example.com/logo.png",
    //  challengeSize: 128,
    //  attestation: "none",
    //  cryptoParams: [-7, -257],
    //  authenticatorAttachment: "platform",
    //  authenticatorRequireResidentKey: false,
    //  authenticatorUserVerification: "required"
    //});





    //console.log(fs);
    ////console.log(express);
    //console.log(JSON.stringify(fs));
    //console.log(bodyparser);
   // var bodyparse = require('express');

    //var express = require('express');
   // const fs = require('fs')
   // const https = require('https')
  //  const X509Security = require('azure-iot-security-x509').X509Security;


   // greet();
   // CryptoUtils.g1();
  }

  async style() {
    const url = 'http://api.deepai.org/api/fast-style-transfer';
    const headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('api-key', 'myKey');

    let requestBody = new FormData();

    requestBody.append('content', 'https://www.dmarge.com/cdn-cgi/image/width=1200,quality=85,fit=scale-down,format=auto/https://www.dmarge.com/wp-content/uploads/2021/01/dwayne-the-rock-.jpg');
    requestBody.append('style', 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/starry-night-print-by-vincent-van-gogh-vincent-van-gogh.jpg');

    const resp = await this.http.post(url, requestBody, {
      headers: headers
    }).toPromise().then();

    console.log(resp);
  }


  OnMyPdPaySaveEvent(e) {


    if (e.reloadlist) {

      this.load();

    }

    if (e.closepage) {

      this.pdpayrecEditId = -1;
      this.openPayrec = false;

    }




  }
  OnMyPdPayCancelEvent(e) {
    if (e) {
      this.pdpayrecEditId = -1;
      this.openPayrec = false;

    }


  }

  OnChangeSanad(e) {

    if (e) {
      this.load();
    }

  }
  onClickPishtoansell() {

    let key = this.gridContainer.focusedRowKey;

    if (key == null)
      return;

    this.openeditIdOnPish = -1;


    var y = this.modlelist._items.find(x => x.id == key);

    this.openeditIdOnPish = y.id;

    if (y.anSellIdPish == null) {
      
      this.openeditId = -1;
    }

    else {
      this.openeditId = y.anSellIdPish;

    }
     
       

    this.openeditOnPish = true;
  


  }

  showSort: boolean = false;
  numberSort: number = 10000;

  onClickSort() {



    //this.currentTafdaste = tafDasteSort;
    //this.popupVisible = true;

    this.showSort = true;


  }
  inuse: boolean = false;
  onClickSaveSort() {

    if (this.inuse) {
      return;
    }

    if (this.userType != 19) {
      return;
    }

    this.inuse = true;
    this.loadServ.showloading(this.loaddata, 2);
    this.AnSellService.SortCodes(this.localst.yearnow_get(), this.kindpage, this.numberSort).subscribe(res => {
      this.inuse = false;

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.alertify.success(res.mes);
        this.showSort = false;
      }
      this.load();
      this.loadServ.hideloading(this.loaddata, 2);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      this.inuse = false;
    });



  }

  onShowPdlinkList(e) {
    //tryu546856856
    this.onShowPdlinkListEvent.emit(e);
  }
}


