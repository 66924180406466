import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormBuilder } from '@angular/forms';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { LoadingService } from '../../../../_services/loading.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { VmVam } from '../../../../_models/app98/opr/VmVam';
import { VmVamService } from '../../../../_services/app98/opr/VmVam.service';
import DataSource from "devextreme/data/data_source";
import { Localst } from '../../../../_services/localst.service';


@Component({
  selector: 'app98-VmVam-edit',
  templateUrl: './VmVam-edit.component.html',
  styleUrls: ['./VmVam-edit.component.css']
})
export class VmVamEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() kind: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();

  @ViewChild("gridSelect", { static: false }) gridSelect: DxDataGridComponent;
  @ViewChild("gridAll", { static: false }) gridAll: DxDataGridComponent;

  myModel: VmVam;
  myModel_old: VmVam;
 
  taflist: TafsiliForSmall[]=[];
  taflistFiltred: TafsiliForSmall[]=[];
  tafMolist: any = {};

 VmVamcodelist: string[];
 defaultPrc: number =0;
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  disInput: boolean =false;
 

  tasKind: any[];
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor( private alertify: AlertifyService, private loadServ: LoadingService,
    private vmVamService: VmVamService,
    private tafsiliService: TafsiliService,
    private localst: Localst,
 
 
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    
    this.myModel = this.vmVamService.getVmVamEmpty();
    this.tasKind = this.vmVamService.getTasKind();
    this.VmVamcodelist = [];
 
  }

  ngOnInit() {


    this.tafMolist = new DataSource({
      store: [],
      paginate: true,
      pageSize: 50
    });


    this.bindall();

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.vmVamService.getVmVamEmpty();

      this.myModel.years = this.localst.yearnow_get();
      this.myModel.dateA_S = this.localst.dateEmruz.dateString;
   
      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;
      this.isnew = true;
      this.disInput = false;
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.vmVamService.getbyid(this.editId).subscribe(
        (res : ApiRet) => {

          this.loadServ.hideloading(this.loaddata, 3);

          this.myModel = res.data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          this.disInput = true;
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
 
     

 }


  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  
 
    
     if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.vmVamService.add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 4);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

    
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.vmVamService.update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
   
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {

          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


  refreshbottum() {

    this.bindall();

  }

  bindall() {

    this.loadServ.showloading(this.loaddata, 0);
    this.tafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, this.editId, 'vmdar').subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.tafMolist = new DataSource({
        store: res,
        paginate: true,
        pageSize: 50
      });

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);

    });

    this.loadServ.showloading(this.loaddata, 1);
    this.vmVamService.getallCode().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.VmVamcodelist = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }
  onValueChangedkarDarsad(e, cellInfo) {
 

    var darsad = e.value;
   // cellInfo.data.karDarsad = darsad;

    if (darsad == 0) {
      this.myModel.prcKar = 0;
    }
    else {

        this.myModel.prcKar = Math.round(this.myModel.prc * darsad);


    }



    if (this.myModel.karOnVam) {
      this.myModel.prcSum = this.myModel.prc;
    }
    else {
      this.myModel.prcSum = this.myModel.prc + this.myModel.prcKar;
    }
   

  }


  onValueChangedprc(e, cellInfo) {

    var prc = e.value;
    if (this.myModel.karDarsad == 0) {
      this.myModel.prcKar = 0;
    }
    else {

      this.myModel.prcKar = Math.round(prc * this.myModel.karDarsad);
    }

    if (this.myModel.karOnVam) {
      this.myModel.prcSum = this.myModel.prc ;
    }
    else {
      this.myModel.prcSum = this.myModel.prc + this.myModel.prcKar;
    }
   
     
  }
  onValueChangedkarOnVam(e) {

    if (this.myModel.karOnVam) {
      this.myModel.prcSum = this.myModel.prc;
    }
    else {
      this.myModel.prcSum = this.myModel.prc + this.myModel.prcKar;
    }

  }
  onClickCreate() {

    let model = this.vmVamService.getVmVamForCreateRow(this.myModel);
    //fdgshe5y54u
    this.loadServ.showloading(this.loaddata, 7);
    this.vmVamService.createRows(model).subscribe(
      (res: ApiRet) => {

        this.loadServ.hideloading(this.loaddata, 7);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.disInput = true;
          this.myModel.rows = res.data.rows;
          //this.myModel.rDateE_S = res.data[0].dateA_S;
          this.myModel.rDateE_S = res.data.cmodel.rDateE_S ;
          this.myModel.roundOnRow = res.data.cmodel.roundOnRow ;
          this.myModel.karOnRow = res.data.cmodel.karOnRow ;
          this.myModel.lastOnRow = res.data.cmodel.lastOnRow ;
          this.myModel.rs = res.data.cmodel.rs ;
          this.myModel.prcRow = res.data.cmodel.prcRow ;
        }
   
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 7);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );
    

  }
  onClickClear() {
    this.disInput = false;
    this.myModel.rows = [];
    this.myModel.rDateE_S = null;


  }

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
  
  }
  mabRadAnDeedChanged(e) {

   // if (e.value == 2) {
   //  // this.myModel.rs = 9999;
   // }
   //// else if (e.value == 1 && this.myModel.rs == 9999) {
   // else if (e.value == 1 ) {
   //   this.myModel.rs = 12;
   // }

  }

  mandehAsGhbl: number = 0;


  onValueChangedOldVam(e) {


    if (this.disInput)
      return;

    this.mandehAsGhbl = 0;
    if (e.value) {



      let modelId = -1;
      let tafId = -1;

      if (this.myModel.id != null)
        modelId = this.myModel.id;

      if (this.myModel.tafId != null)
        tafId = this.myModel.tafId;

      this.loadServ.showloading(this.loaddata, 8);
      this.vmVamService.getMandehAghsat(tafId, modelId).subscribe(
        (res: ApiRet) => {

          this.loadServ.hideloading(this.loaddata, 8);
          if (res.errors.length > 0) {
            this.alertify.showListString(res.errors);
          }
          else {
            this.mandehAsGhbl = res.data;
            this.myModel.prcOldVam = res.data;
          }

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 8);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );




    }

  }

  onValueChangeOldVamdprc() {

  }
}
