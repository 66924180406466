import {Routes} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MessagesComponent } from './messages/messages.component';
import { ListsComponent } from './lists/lists.component';
import { AuthGuard } from './_guards/auth.guard';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { MemberDetailResolver } from './_resolvers/member-detail.resolver';
import { MemberListResolver } from './_resolvers/member-list.resolver';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { ArchListComponent } from './arch/arch-list/arch-list.component';
import { ArchEditComponent } from './arch/arch-edit/arch-edit.component';
import { ShakhzListComponent } from './app1/paye/Shakhz/Shakhz-list/Shakhz-list.component';
import { ShakhzEditComponent } from './app1/paye/Shakhz/Shakhz-edit/Shakhz-edit.component';
import { DeldialogComponent } from './dialog/deldialog.component';
import { HesabListComponent } from './app1/paye/Hesab/Hesab-list/Hesab-list.component';
import { TafsiliListComponent } from './app1/paye/Tafsili/Tafsili-list/Tafsili-list.component';
import { TafsiliEditComponent } from './app1/paye/Tafsili/Tafsili-edit/Tafsili-edit.component';
import { TafDasteListComponent } from './app1/paye/TafDaste/TafDaste-list/TafDaste-list.component';
import { TafDasteEditComponent } from './app1/paye/TafDaste/TafDaste-edit/TafDaste-edit.component';
import { SherkatListComponent } from './app1/paye/Sherkat/Sherkat-list/Sherkat-list.component';
import { SherkatEditComponent } from './app1/paye/Sherkat/Sherkat-edit/Sherkat-edit.component';
 
import { SaleMaliEditComponent } from './appgeneral/SaleMali/SaleMali-edit/SaleMali-edit.component';
import { SanadListComponent } from './app1/opr/Sanad/Sanad-list/Sanad-list.component';
import { SanadEditComponent } from './app1/opr/Sanad/Sanad-edit/Sanad-edit.component';
import { tabpComponent } from './tabp.component';
import { MorurHtComponent } from './app1/rep/morur/morur-ht/Morur-ht.component';
 
import { RegisterComponent } from './register/register.component';
import { TetrismainComponent } from './app1/plugin/tetris/tetris-main/tetris-main.component';
import { tabapp99Component } from './tabApp99/tabapp99.component';
import { SuperReportListComponent } from './SuperAdmin/SuperReport/SuperReport-list.component';
import { SettingListComponent } from './appsetting/setting-list.component';

export const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'MorurHt', component: MorurHtComponent },
  { path: 'maintab', component: tabpComponent }, 
  { path: 'sectab', component: tabapp99Component },
  { path: 'plugin/tetris', component: TetrismainComponent },
  { path: 'Accounts', component: SettingListComponent }, 
  { path: 'Registerx', component: RegisterComponent }, 
  { path: 'SuperAdmin/SuperReport', component: SuperReportListComponent }, 
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'members', component: MemberListComponent,
                resolve: { users:  MemberListResolver }
            },
            { path: 'members/:id', component: MemberDetailComponent,
                resolve: { user: MemberDetailResolver }
            },
            { path: 'member/edit', component: MemberEditComponent,

                resolve: { user: MemberEditResolver },
                //اگر خواستیم تب را ببندیم ولی درحالی که تغییرات را ذخیره نکرده بودیم
                   canDeactivate:[PreventUnsavedChanges]
          },
          { path: '', component: HomeComponent },
          { path:  'messages', component: MessagesComponent},
          { path: 'lists', component: ListsComponent },
          { path: 'arch', component: ArchListComponent },
          { path: 'arch/edit', component: ArchEditComponent },
          { path: 'app1/paye/Shakhz', component: ShakhzListComponent },
          { path: 'app1/paye/shakhz/edit/:id', component: ShakhzEditComponent },

          { path: 'app1/paye/Sherkat', component: SherkatListComponent},
  
          { path: 'app1/paye/Sherkat/Edit/:id', component: SherkatEditComponent },

  
          { path: 'appgeneral/SaleMali/Edit/:id', component: SaleMaliEditComponent },

          { path: 'app1/opr/Sanad', component: SanadListComponent },
          { path: 'app1/opr/Sanad/Edit/:id', component: SanadEditComponent },

          { path: 'app1/paye/Hesab/list', component: HesabListComponent },
          { path: 'app1/paye/Tafsili/list', component: TafsiliListComponent },
          { path: 'app1/paye/Tafsili/Edit/:id', component: TafsiliEditComponent },
          { path: 'app1/paye/TafDaste/List', component: TafDasteListComponent },
          { path: 'app1/paye/TafDaste/Edit/:id', component: TafDasteEditComponent },
          { path: 'dialog/', component: DeldialogComponent },
      
        ]

       
    },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },

];
