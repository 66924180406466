import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdPayrec, PdPayrecRow } from '../../../_models/app4/opr/PdPayrec';
 
 
let EmptyPdPayrec: PdPayrec = {
  id: -1,
  kind: 0,
  code: 0,
  mahal:null,
  mahalN:null,
  dateA: 0,
  dateA_S: null,
  dis: null,
  years: null,
  rows: [],
  uSa: null,
  uSaF: null,
  dateLog: new Date(),
  uSaE: null,
  uSaEF: null,
  dateELog: null ,
  cnt: 0,
  tafId: null,
  tafC: null,
  tafN: null,
  pdBankId: null,
  pdBankC: null,
  pdBankN: null,
  sanadInfo: {
    id: -1,
    cShomare: 0,
    cRozane: 0,
    cAtf: 0,
    cFare: 0,
    date: 0,
    date_S: 0
  }
  ,autoPdLinkData:null
};



let EmptyPdPayRecRow: PdPayrecRow ={
  id: 0,
  pdPayrecId: 0,
  code: 0,
  years: 0,
  dateA: 0,
  dateA_S: null,
  mos: null,
  dateB: 0,
  dateB_S: null,
  kind: 0,
  kindIn:0,
  kindInN:null,
  kindRow: 0,
  radif: 0,
  tafId:  null,
  tafC: null,
  tafN: null,
  pdHesId: null,
  pdHesC: null,
  pdHesN: null,
  hesabId: null ,
  hesabC: null,
  hesabN: null,
  prc: 0,
  ckBank: null,
  dis: null,
  pdMyCkRowId: null,
  pdMyCkRow:null,
  ckCode: null,
  ckDate: 0,
  ckDate_S: null,
  ckNoe: null,
  ckNoeN: null,
  ckSahed: null,
  pdBankInId: null,
  pdBankInC: null,
  pdBankInN: null,
  pdBankId: null,
  pdBankC: null,
  pdBankN: null,
  saderN: null,
  pdPayrecCnt: 0,
  dis_PdHesTemp: '',
  sayadi: null,
  saheb: null,
  pozTar: null,
  _lastTafOrBank: null,
  pdPosId:null,
  pdPos:null,
}

let mahalList: any[] = [
  { id: 11, name: 'خارجی'},
  { id: 12, name: 'داخلی'},
];

let KindRowList: any[] = [
  { id: 1, name: 'نقد' },
  { id: 2, name: 'چک' },
  { id: 3, name: 'سفتع/ضمانت نامه' },
  { id: 4, name: 'حواله' },
  { id: 5, name: 'کارتخوان' },

];


const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdPayrecService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(kind:number): Observable<PdPayrec[]> {
    let apiadress = '/api/App4/Paye/PdPayrec/getall?kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<PdPayrec[]>(this.origin + apiadress, { 'headers': headers });
  }

  getalltiny(): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getalltiny';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

 
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  GetPdPayrecRow(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/GetPdPayrecRow?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });


    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  GetPdPayrecRowForPrint(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/GetPdPayrecRowForPrint?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  add(item: PdPayrec) : Observable< ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getPdPayrecEmpty(kind : number): PdPayrec {

    let l = JSON.parse(JSON.stringify(EmptyPdPayrec)) as PdPayrec;
    l.kind = kind;




    return l;

  }
  getPdPayRecRowEmpty(): PdPayrecRow {

    let l = JSON.parse(JSON.stringify(EmptyPdPayRecRow)) as PdPayrecRow;


    return l;

  }
  getMahalList(): any[] {

    return mahalList;

  }
  getKindRowList(): any[] {

    return KindRowList;

  }

  update(item: PdPayrec): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(PdPayrecId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/delete?id=' + PdPayrecId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App4/Paye/PdPayrec/getallCods';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }


  nextCode(year: number, kind: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/nextCode?year=' + year + '&kind=' + kind;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  validationCode(text, id, dataA: string, kind, year): Promise<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/validationCode?text=' + text + '&id=' + id + '&dataA=' + dataA + '&kind=' + kind + '&year=' + year;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).toPromise();

  }

  getRowsBykindRow(kindRow: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getRowsBykindRow?kindRow=' + kindRow.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  getPayrecRowForCheck(pdMoveEditId :number,  kindPage :number,  paykindIn: number, kindMove: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPayrecRowForCheck?pdMoveEditId='+ pdMoveEditId.toString() +'&kindPage=' + kindPage  + '&paykindIn=' + paykindIn.toString() + '&kindMove=' + kindMove.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }

  getPayrecRowForCheckAll(pdMoveEditId: number, kindPage: number, paykindRow: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPayrecRowForCheckAll?pdMoveEditId=' + pdMoveEditId.toString() + '&kindPage=' + kindPage.toString() + '&paykindRow=' + paykindRow.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
 
  getPayrecRowForMove(pdMoveEditId: number, KindMove: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPayrecRowForMove?pdMoveEditId=' + pdMoveEditId.toString() + '&KindMove=' + KindMove.toString() ;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  getPayrecRowForCheckForRep(): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPayrecRowForCheckForRep';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
 
  getPayrecRowForPdMoveGroup(pdMoveGroupEditId: number, pdMoveKind: number, pdBankId:number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPayrecRowForPdMoveGroup?pdMoveGroupEditId=' + pdMoveGroupEditId.toString() + '&pdMoveKind=' + pdMoveKind.toString() + '&pdBankId=' + pdBankId;
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }

  getPdPayrecResidPrint(ids: number[]): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdPayrec/getPdPayrecResidPrint';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, ids, { 'headers': headers });

  }
}

