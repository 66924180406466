import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DafKol, DafKolRow } from '../../../../_models/app1/rep/DafKol';
import { DafKolService } from '../../../../_services/app1/rep/DafKol.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';



 
 
@Component({
  selector: 'app-DafKol-ht',
  templateUrl: './DafKol-ht.component.html',
  styleUrls: ['./DafKol-ht.component.css']
})
 

export class DafKolHtComponent implements OnInit {

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }

  @Input() inputdata: any;

 
  model: DafKol;
  wwd: any =300;

  nowdate: MyTimeClass;
  printpage: printpage;
  sumall: DafKolRow;

  x: number=50;

  xs: number[] = [40, 50, 0, 120, 120];
  px: number;

  width: number;
  height: number;
  minArea: number;
  draggingCorner: boolean;
  draggingWindow: boolean;

  constructor(private userService: UserService, private alertify: AlertifyService, private DafKolService: DafKolService,
    private route: ActivatedRoute,
    private localst: Localst,
    private router: Router,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {

   // console.log('hjddddddddddddddddddddddddddddd');

   
  }
  onWindowPress(event: MouseEvent ,v) {
 //   console.log('a');
    this.draggingWindow = true;
    this.px = event.clientX;
    
  }
  onWindowDrag(event: MouseEvent,v) {


    if (!this.draggingWindow) {
      return;
    }
   // console.log('b');
    let offsetX = event.clientX - this.px;

   // console.log(offsetX);

    this.xs[v] -= offsetX;

    if((v+1)<5)
    this.xs[v +1] += offsetX;

    if ((v - 1) > 0)
      this.xs[v - 1] += offsetX;

    this.px = event.clientX;
  
  }

  //onWindowPress(event: MouseEvent) {
  //  console.log('onWindowPress');
  //  console.log(event);
  //  this.draggingWindow = true;
  //  this.px = event.clientX;

  //}
  //onWindowup(event: MouseEvent) {
  //  console.log('onWindowup');
  //  this.draggingWindow = true;
  //  this.px = event.clientX;

  //  let offsetX = event.clientX - this.px;
  //  console.log(offsetX);

  //}

  //onWindowDrag(event: MouseEvent) {
  //  console.log('onWindowDrag');

  //  if (!this.draggingWindow) {
  //    return;
  //  }
  //  let offsetX = event.clientX - this.px;


  //  this.x += offsetX;

  //  this.px = event.clientX;

  //}
  ngOnInit() {
    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
      HNoemandeh: null
    };
  //  this.inputdata = ای دی
    this.DafKolService.getbyid(this.inputdata).subscribe(res => {

      this.model = res.data;

      this.initPrint();
    }, error => {
      this.alertify.error(error);
    });

  

  }
 
 
 
  initPrint() {
    if (this.inputdata == null)
      return;

   
 





    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();

    let indexreaded = 0;


    let pageindex = 0;

 

    this.sumall = this.DafKolService.getDafKolRowEmpty();
    this.sumall.hesabN = 'جمع کل';
    this.sumall.id = -1;


  
    for (var r of this.model.rows) {
      this.sumall.bed += r.bed;
      this.sumall.bes += r.bes;

    }
    this.model.rows.push(this.sumall);




    let datalegh = this.model.rows.length;


    
  


    if (datalegh == 0)
      return;




    while (indexreaded < datalegh) {


      this.printpage.pages.push(new pagep(pageindex));



      let counterpage = 0;
      var sumpage = 0;
      var rowpagesum = { name: 'جمع صفحه', bed: 0, bes: 0, mandehBed: 0, mandehBes: 0 };

      while (indexreaded < datalegh) {


     
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.model.rows[indexreaded]);

 

        indexreaded++;
        counterpage++;
        if (counterpage > 30)
          break;

      }
      // rowpagesum.name =  'جمع صفحه';
      this.printpage.pages[pageindex].pagesum = rowpagesum;





      pageindex++;



    }


    let leghpage = this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }


   // this.printpage.pages[0].rowsp[0].bed =  this.printpage.pages[0].rowsp[0].bed.toString();







  }
 
 

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

  

    //this.printpage.HMahdude = ' از تاریخ ' + this.myTimeService.addslashtostring(this.orgheader.dateS) + ' الی ' + this.myTimeService.addslashtostring(this.orgheader.dateE) + '    از شماره سند           ' + this.orgheader.cShomareS + ' الی ' + this.orgheader.cShomareE;


 


  
 
   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
    console.log(d.outerHTML);


    //-moz - transform: scale(2);

    //zoom: 125 %;

    //-ms - transform: rotate(-90deg);
    //-o - transform: rotate(-90deg);
    //transform: rotate(-90deg);


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation = 3);


  //  let popupWinindow
   // let innerContents = document.getElementById("d").innerHTML;
    //popupWinindow = window.open('', '_blank');
    //popupWinindow.document.open();
    //popupWinindow.document.write( innerContents );
    //popupWinindow.document.close();


    //-webkit - transform: rotate(-90deg);
    //-moz - transform: rotate(-90deg);
    //  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    let printContents, popupWin;
  //   printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 12pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: #E2E2E2;
}


 

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  width: 210mm;
  height: 297mm;
  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

     .t-table {
        width: 190mm;
        margin: 1mm 7mm 0mm 7mm;
        font: 12pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: 'B Nazanin';
        direction: rtl;
        text-align: right;
      }

        .t-table td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          border: 1px solid black;
          padding: 0px 5px 0px 5px;
          height: 18px;
        }

        .t-table th {
          font: 12pt "B Nazanin";
          font-weight: 400;
          border: 2px solid black;
          text-align: center;
          height: 20px;
          background-color: lightgrey;
        }

      .t-header {
        width: 190mm;
        margin-right: 7mm;
        margin-top: 5mm;
        direction: rtl;
        font: 12pt "B Nazanin";
        font-weight: 400;
        text-align: right;
        height: 90px;
      }

        .t-header td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          padding: 5px 5px 5px 5px;
          height: 18px;
        }


      .grayrow {
      }
@media print {

* {
  margin: 0;
  padding: 0;
}
 .page-break  { display:block; page-break-before:always; }
   @page {size: A4 ; }
 
  html, body {
 
  width:210mm ;
  height: 292mm;
  }

 .page {

display:block; page-break-before:always;
  width: 195mm;
  height: 280mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;
 
  border-radius: 0px;
  background: white;
  page-break-after: always;
}

    
 


         .t-table {
        width: 190mm;
        margin: 1mm 7mm 0mm 7mm;
        font: 12pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        border-collapse: collapse;
        font-family: 'B Nazanin';
        direction: rtl;
        text-align: right;
      }

        .t-table td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          border: 1px solid black;
          padding: 0px 5px 0px 5px;
          height: 18px;
        }

        .t-table th {
          font: 12pt "B Nazanin";
          font-weight: 400;
          border: 2px solid black;
          text-align: center;
          height: 20px;
          background-color: lightgrey;
        }

      .t-header {
        width: 190mm;
        margin-right: 7mm;
        margin-top: 5mm;
        direction: rtl;
        font: 12pt "B Nazanin";
        font-weight: 400;
        text-align: right;
        height: 90px;
      }

        .t-header td {
          font: 12pt "B Nazanin";
          font-weight: 400;
          padding: 5px 5px 5px 5px;
          height: 18px;
        }


      .grayrow {
      }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );


    popupWin.document.close();



  }

}


//popupWin.document.write(`
//      <html>
//        <head>
//          <title>Print tab</title>
//          <style>
//          //........Customized style.......

// body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//  background-color: #FAFAFA;
//  font: 12pt "Tahoma";
//}

//* {
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}

//.page {
    
//  width: 297mm;
//  min-height: 210mm;
//  padding: 5mm;
//  margin: 10mm auto;
//  border: 1px #D3D3D3 solid;
//  border-radius: 5px;
//  background: white;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

 
 


// -webkit-transform: rotate(-90deg) ; 
//     -moz-transform:rotate(-90deg) ;

//     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

//}

//.subpage {
//  padding: 5mm;
//  border: 5px rgb(128, 128, 128) solid;
//  height: 200mm;
//  outline: 5mm #D3D3D3 solid;
//}



 
 


//@media print {

//   @page {size: A4  ;

// margin: -10;


//}
 
//  html, body {
 
    
//  }

//  .page {
//    margin: 0;
    
//    border: initial;
//    border-radius: initial;
//    width: initial;
//    min-height: initial;
//    box-shadow: initial;
//    background: initial;
//    page-break-after: always;
//  }
//  #section-to-print, #section-to-print * {
//    visibility: visible;
//  }

//  #section-to-print {
//    position: absolute;
//    left: 0;
//    top: 0;
//  }

//}



//          </style>
//        </head>
//    <body onload="window.print();window.close()">${d.innerHTML}</body>
//      </html>`
//);
