import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';
 
import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../../_services/loading.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
 
 
import { ApiRet } from '../../../../_models/General/ApiRet';
import { KalaForList } from '../../../../_models/app2/paye/Kala';
import { Vahed } from '../../../../_models/app2/paye/Vahed';
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { VmRep } from '../../../../_models/app98/rep/VmRep';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { VmRepService } from '../../../../_services/app98/rep/VmRep.service';
import { VmGrsService } from '../../../../_services/app98/paye/VmGrs.service';
import { VmGrs } from '../../../../_models/app98/paye/VmGrs';
 
 

@Component({
  selector: 'app98-VmRep-list',
  templateUrl: './VmRep-list.component.html',
  styleUrls: ['./VmRep-list.component.css']
})
 

export class VmReplistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;
  @Input() tabindex: number;
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  @Output() OnMyprint = new EventEmitter<any>();


  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;

  tafMolist: any = {};

  focusedSabtId: number;
  
 //لیست کالا ها
  devDataKalas: any = {};
 
  vahedList: Vahed[] = [];
  //لیست انبارها 
  anbarList: Anbar[] = [];

  
 
  selectedrow: any;
 

  model: VmRep;
  vmGrsList: VmGrs;
 
 
 
  
 

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
 
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private vmRepService: VmRepService,
    private vmGrsService: VmGrsService,
    private tafsiliService: TafsiliService,

 
    private excelService: ExcelService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 

    this.openedit= false;
    this.openeditId = -1;
    this.focusedSabtId = -1;
 
    this.loadServ.create(this.loaddata,8);



    this.model = vmRepService.getVmRepEmpty();
  //  this.model.op.year = localst.yearnow_get();


  }
  mytabindex: number = -1;
  ngOnInit() {
    this.mytabindex = this.tabindex;
    this.tafMolist = new DataSource({
      store: [],
      paginate: true,
      pageSize: 50
    });

    this.loadServ.showloading(this.loaddata, 5);
    this.vmGrsService.getallOnRep(true)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.vmGrsList = res.data;
        }

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });



 

    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002, true, -1, 'none')
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.tafMolist = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });


  
  }
  ngOnChanges(changes: any) {

    if (this.dataGrid != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.dataGrid.focusedRowIndex;

        if (index == -1) {
          index = 0;
        }
        if (index > -1) {

          var yyy = this.dataGrid.instance.getCellElement(index + 4, 0);
          if (yyy == undefined)
            yyy = this.dataGrid.instance.getCellElement(index, 0);
          var y = this.dataGrid.instance.getScrollable();
          if (yyy != undefined)
          y.scrollToElement(yyy);
          this.dataGrid.instance.refresh();
          //y.scrollTo(0,2);

          //y.sc(0);
        }

      }
      else {


      }
  }

  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 

  onClickReflesh() {

    //اگر لیست انبار ها را کار بر باز نکند کامپوننت ایجاد نمیشود . دیفالت هم انتخاب تمام انبار ها در زمان بار گذاری لیست انبار هاست و نیاز به سلکت نداریم
    if (this.dxliststr != undefined) {
      var keys = this.dxliststr.selectedItemKeys;
      this.model.op.vmGrss = [];
      if (keys != null)
        for (var j = 0; j < keys.length; j++) {
          this.model.op.vmGrss.push({ id: keys[j].id, name: keys[j].name });
        }
    }


    this.loadServ.showloading(this.loaddata, 5);
    this.vmRepService.getRep(this.model.op)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.model = res.data;
        }


       

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,' کاردکس کالا ');

   // this.excelService.exportAsExcelFile(this.modelsearch, 'sample');

  }

  onExporting(e) {

  }

 
 
  adjustWidth(e , w) {

    e.component._popup.option('width', w);

  }
 
  onSelectionChanged(e) {


  }
  firstclreate = true;

  dropopened(e) {


    e.component._popup.option('width', 500);

    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

      this.firstclreate = false;

    }
  }

  onCellPrepared(e) {

   // console.log(e.data);
    if (e.data == undefined)
      return;


    if (e.column.dataField == "prcMan") {

      if (e.data.anDeedC == 0) {
        if (e.data.prcMan > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.amtMan < 0) {
          e.cellElement.style.backgroundColor = '#ca0000';

        }
 
      }
    }
 
    if (e.column.dataField == "prcOut") {

      if (e.data.anDeedC == 0) {
        

        if (e.data.prcOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.prcOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }

      }
    }


    if (e.column.dataField == "fiOut") {

      if (e.data.anDeedC == 0) {



        if (e.data.fiOut > 0) {
          e.cellElement.style.color = '#19bf00';

        }
        else if (e.data.fiOut < 0) {
          e.cellElement.style.color = '#ca0000';

        }
      }
    }


 

   
 
  }
 


  gwname = 'gw9805';

  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli[this.gwname] = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }

  addwidth(e) {
    for (var i = 0; i < this.localst.optioncli[this.gwname].length; i++) {
      this.localst.optioncli[this.gwname][i] *= e;
    }
  }


  defwidth() {
    this.localst.optioncli[this.gwname] = this.localst.optionClient_readDefault(this.gwname);
  }
 
 
 modelTemp: VmRep;
  onClickprint(printKind: number) {

    this.model.op.dateS_S;

    if (printKind == 1) {

      this.modelTemp = JSON.parse(JSON.stringify(this.model));

    }
    else if (printKind == 11) {

      let filterExpr = this.dataGrid.instance.getCombinedFilter(true);
      const dataSource = this.dataGrid.instance.getDataSource();
      const loadOptions = dataSource.loadOptions();

      this.modelTemp = JSON.parse(JSON.stringify(this.model));

      dataSource
        .store()
        .load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group })
        .then((result: any) => {
          // your code...
          this.modelTemp.rows = result;
        });


      this.modelTemp.rowSum.radif = this.dataGrid.instance.getTotalSummaryValue('radif');
      this.modelTemp.rowSum.prcSum = this.dataGrid.instance.getTotalSummaryValue('prcSum');
      this.modelTemp.rowSum.prcMan = this.dataGrid.instance.getTotalSummaryValue('prcMan');
      this.modelTemp.rowSum.prcDone = this.dataGrid.instance.getTotalSummaryValue('prcDone');


    }
    else {
      this.alertify.error('الگو یافت نشد');
    }
   
    if (this.modelTemp == undefined || this.modelTemp == null) {
      this.alertify.alert('مدل یافت نشد');
    }

    //let kindN = '';
    //for (var r of this.kindlist) {
    //  if (r.id == this.model.op.kind) {
    //    kindN = r.name;
    //    break;
    //  }
    //}

    //let tafN = '';
    //for (var r of this.tafMolist._store._array) {
    //  if (r.id == this.model.op.tafId) {
    //    tafN = r.name;
    //    break;
    //  }
    //}

    //let grN = '';
    //for (var r of this.model.op.vmGrss) {
    //  grN += r + '  ';
    //}
    var otherData = { kindN: '', tafN: '', grN: '' }

    var modelData = JSON.parse(JSON.stringify(this.modelTemp));
    //let kind = this.kindlist.filter(x => x.id == this.model.op.kind);


    var mdata = { modelData: modelData, printKind: printKind, compKind: 0, otherData: otherData }

    this.OnMyprint.emit(mdata);

  }

}


