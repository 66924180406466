import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Localst } from '../localst.service';
import { ApiRet } from '../../_models/General/ApiRet';
import { Registry } from '../../_models/General/Registry';
 
 

@Injectable({
  providedIn: 'root'
})
export class RegistryService {
  origin = location.origin;
 


  constructor(private http: HttpClient, private localstService: Localst) {
 
  }
  

  getbypath(path, years): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/getbypath?path=' + path.toString() + '&years=' + years.toString();
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getall( years): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/getall?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  getRegistryAnbar(years): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/getRegistryAnbar?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  updateRegistryAnbar(RegistryAnbarDto): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/updateRegistryAnbar';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, RegistryAnbarDto, { 'headers': headers });
  }


  getRegistryApp2Code(years): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/getRegistryApp2Code?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  updateRegistryApp2Code(item): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/updateRegistryApp2Code';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  getRegistryApp3Code(years): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/getRegistryApp3Code?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  updateRegistryApp3Code(item): Observable<ApiRet> {
    let apiadress = '/api/AppSetting/Registry/updateRegistryApp3Code';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
}
