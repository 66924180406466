import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { MorurOption } from '../../../../_models/app1/rep/Morur';
 
import { Localst } from '../../../../_services/localst.service';
import { BrowserModule } from '@angular/platform-browser'
import { skip } from 'rxjs/operators';

 
 
@Component({
  selector: 'app2-Kardex2-ht',
  templateUrl: './Kardex2-ht.component.html',
  styleUrls: ['./Kardex2-ht.component.css']
})
 

export class Kardex2HtComponent implements OnInit {

 
  @Input() inputdata: any;

  orgheader: MorurOption; 
  orgrows: any[];
  printKind: number;
  showRial: boolean;
  tit: string;

  nowdate: MyTimeClass;
  pVeiw: any = { atf: true ,vertic :false}

  printpage: printpage;
  constructor(private userService: UserService, private alertify: AlertifyService, 
    private route: ActivatedRoute,
    private router: Router,
    private localst: Localst,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {

 

   
  }

  ngOnInit() {

   // console.log(JSON.stringify(this.inputdata));

    //option: Kardex2Ops;
    //rows: Kardex2Row[];
    
    //tguy65568865u56u865
    this.orgheader = this.inputdata.model.option;
    this.orgrows = this.inputdata.model.rows;
    this.printKind = this.inputdata.printKind;
    this.showRial = this.inputdata.showRial;

   this.orgrows.push( this.inputdata.model.rowSum);
    if (this.showRial) 
      this.tit = 'گزارش کاردکس ریالی کالا';
    else
      this.tit = 'گزارش کاردکس تعدادی کالا';


    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
        HNoemandeh: null
    };

    if (this.inputdata == null)
      return;
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();

    let indexreaded= 0;


    let pageindex = 0;

    //برای صفحه بندی درست نیاز است جمع اخرین صفحه هم جزو ردیف ها بیاریم
    //var rowpagesumall = { dis: 'جمع کل', bed: 0, bes: 0 , mandehBed: 0, mandehBes: 0 };
   
    //this.orgrows.push(rowpagesumall);
 

    let datalegh = this.orgrows.length;
    let sumIndex = datalegh-1;
 

 

    if (datalegh == 0)
      return;


  

   
    while (indexreaded < datalegh) {
      //pindex: number;
      //ppage: number;
      //ppages: number;

      //pagesum: any;
      //pagesumall: any;
      //mandeall: any;
      //rowsp: any[];

      this.printpage.pages.push(new pagep(pageindex));

      //this.printpage.pages[pageindex] = new pagep(pageindex);
  

      let counterpage = 0;
      var sumpage = 0;
      var rowpagesum = { name:'جمع صفحه', bed: 0, bes:0, mandehBed: 0, mandehBes:0};

      while (indexreaded < datalegh) {


        ///چون جمع ها است دو ردیف اخر را نادیده میگیریم
        if (indexreaded < datalegh - 1) {
          //شماره رذدیف
          this.orgrows[indexreaded].radif = indexreaded + 1;
          //جمع صفحه
          rowpagesum.bed += this.orgrows[indexreaded].bed;
          rowpagesum.bes += this.orgrows[indexreaded].bes;
          rowpagesum.mandehBed += this.orgrows[indexreaded].mandehBed;
          rowpagesum.mandehBes += this.orgrows[indexreaded].mandehBes;
          //جمع کل

          this.orgrows[sumIndex].bed += this.orgrows[indexreaded].bed;
          this.orgrows[sumIndex].bes += this.orgrows[indexreaded].bes;
     


   


          
        }

    
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.orgrows[indexreaded]);


  
      //  console.log(skiprow);
        counterpage = counterpage + this.getskiprow(this.orgrows[indexreaded]) ;
   

        indexreaded++;
        counterpage++;
        if (counterpage > 48)
          break;

      }
      this.printpage.pages[pageindex].pagesum = rowpagesum;



   

      pageindex++;



    }

    let leghpage =  this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }


   // this.printpage.pages[0].rowsp[0].bed =  this.printpage.pages[0].rowsp[0].bed.toString();


    //var table = document.getElementById('tbtb');
    //alert(table.offsetHeight);
  

  }






 public getskiprow(st: any): number{


    let skips: number[] = [];

   if (this.showRial) {
     skips.push(this.masurtest(st.radif, 25));
     skips.push(this.masurtest(st.anDeedC, 40));
     skips.push(this.masurtest(st.date_S, 55));
     skips.push(this.masurtest(st.kindN, 40));
     skips.push(this.masurtest(st.taf4N, 65));
     skips.push(this.masurtest(st.vahedN, 30));
     skips.push(this.masurtest(st.anbarN, 40));

     skips.push(this.masurtest(st.amtIn, 30));
     skips.push(this.masurtest(st.fiIn, 50));
     skips.push(this.masurtest(st.prcIn, 60));

     skips.push(this.masurtest(st.amtOut, 30));
     skips.push(this.masurtest(st.fiOut, 50));
     skips.push(this.masurtest(st.prcOut, 60));


     skips.push(this.masurtest(st.amtMan, 30));
     skips.push(this.masurtest(st.fiMan, 50));
     skips.push(this.masurtest(st.prcMan, 60));
   }
   else {

     skips.push(this.masurtest(st.radif, 30));
     skips.push(this.masurtest(st.anDeedC, 40));
     skips.push(this.masurtest(st.date_S, 60));
     skips.push(this.masurtest(st.kindN, 60));
     skips.push(this.masurtest(st.taf4N, 170));
     skips.push(this.masurtest(st.vahedN, 50));
     skips.push(this.masurtest(st.anbarN, 100));

     skips.push(this.masurtest(st.amtIn, 70));

     skips.push(this.masurtest(st.amtOut, 70));
     skips.push(this.masurtest(st.amtMan, 70));

   }

   let max = 0;
   for (var i of skips) {
     if (i > max)
       max = i;
   }
   return max;
  }
  masurtest(test, maxWidth: number) : number{


    //sum padding
  // maxWidth -= 4;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '10pt B Nazanin';

    if (test == null)
      test = '';
    const metrics = ctx.measureText(test.toString()).width;
    return +Math.floor(metrics / maxWidth);

  }


  ppp() {

   

 
  }
  ngAfterViewInit() {

 
  }
  resetFilters() {
   
 
  }
 

 

  onFocusedRowChanged(e) {


 

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

    //this.printpage.HMahdude = ' از تاریخ ' + this.myTimeService.addslashtostring(this.orgheader.dateS) + ' الی ' + this.myTimeService.addslashtostring(this.orgheader.dateE)+ '  از شماره سند           ' + this.orgheader.cShomareS + ' الی ' + this.orgheader.cShomareE;

 
  

 
   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
 
    console.log(d.outerHTML);

    var ttt = document.getElementById("tbtb");
    console.log(ttt.offsetHeight);

    let printContents, popupWin;
    popupWin = window.open('', '_blank');
    popupWin.document.open();

    this.pVeiw.vertic = false;
 
    popupWin.document.write(`  <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 8pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: white;
}


 

.page {
  size: A4;
  font: 8pt "B Nazanin" ;
  font-weight:400;


  height: 292mm;
  width: 205mm;

  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

    
    .t-table {
      width: 190mm;
      margin: 1mm 7mm 0mm 7mm;
      font: 8pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;
    }

      .t-table td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        padding: 0px 2px 0px 2px;
        height: 18px;
        word-break: break-all;
      }

      .t-table th {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 2px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
      }

    .t-header {
      width: 190mm;
      margin-right: 7mm;
      margin-top: 5mm;
      direction: rtl;
      font: 8pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
      }

  .t-headerB {
         width: 190mm;
      margin-right: 7mm;
      margin-top: 0;
      direction: rtl;
      font: 9pt "B Nazanin";
      font-weight: 800;
      text-align: right;
    }

      .t-headerB td {
        padding: 5px 5px 5px 5px;
   
      }

    .grayrow {
    }

@page {
  size: A4;
 
}
@media print {
 html, body {

    width: 210mm;
    height: 292mm;
  }
    div.centerOnPrintedPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }
  /* ... the rest of the rules ... */

body {
  width: 100%;
  height: 100%;
  padding: 0mm;
  margin: 0mm auto;

  font: 8pt "B Nazanin";
}


 .page {

display:block; page-break-before:always;
 height: 292mm;
  width: 205mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;

  border-radius: 0px;


}




    .t-table {
      width: 190mm;
      margin: 1mm 7mm 0mm 7mm;
      font: 8pt "B Nazanin";
      font-weight: 400;
      border: 1px solid black;
      border-collapse: collapse;
      font-family: 'B Nazanin';
      direction: rtl;
      text-align: right;
    }

      .t-table td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 1px solid black;
        padding: 0px 2px 0px 2px;
        height: 18px;
        word-break: break-all;
      }

      .t-table th {
        font: 8pt "B Nazanin";
        font-weight: 400;
        border: 2px solid black;
        text-align: center;
        height: 20px;
        background-color: lightgrey;
      }

    .t-header {
      width: 190mm;
      margin-right: 7mm;
      margin-top: 5mm;
      direction: rtl;
      font: 8pt "B Nazanin";
      font-weight: 400;
      text-align: right;
      height: 90px;
    }

      .t-header td {
        font: 8pt "B Nazanin";
        font-weight: 400;
        padding: 5px 5px 5px 5px;
        height: 18px;
      }
   .t-headerB {
        width: 190mm;
      margin-right: 7mm;
      margin-top: 0;
      direction: rtl;
      font: 9pt "B Nazanin";
      font-weight: 800;
      text-align: right;
    }

      .t-headerB td {
        padding: 5px 5px 5px 5px;
  
      }

    .grayrow {
    }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
      );


  

    popupWin.document.close();



  }

}

