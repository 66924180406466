import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { SaleMali } from '../../_models/General/saleMali';
 
import { Localst } from '../localst.service';
import { ApiRet } from '../../_models/General/ApiRet';
 
 

 
let EmptySaleMali: SaleMali = {
  id: -1,
  codeSal: 0,
  text: null,
  dateS: 0,
  dateE: 0,
  dateS_s:'',
  dateE_s: '',
  dateMotamamS: 0,
  dateMotamamE: 0,

  dateMotamamS_s: '',
  dateMotamamE_s: '',
  dis: null,

};
let HeaderSaleMali: any = {

  id: -1,

  codeSal: 'کد سال',

  text: 'عنوان سال',
  dateS_string: '',
  dateE_string: '',
  dateS: 0,
  dateMotamamS_string: '',
  dateMotamamE_string: '',
  dateE: 0,
  dateMotamamS: 0,
  dateMotamamE: 0,


  dis: '',


};
@Injectable({
  providedIn: 'root'
})
export class SaleMaliService {
  origin = location.origin;
  public salemaliChangEmitter$: EventEmitter<number>;


  constructor(private http: HttpClient, private localstService: Localst) {

    this.salemaliChangEmitter$ = new EventEmitter();
  }

  getSaleMalis(): Observable<SaleMali[]> {
    let apiadress = '/api/General/SaleMali/getall';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<SaleMali[]>(this.origin + apiadress, { 'headers': headers });
  }
 

 
  getSaleMalibyid(id): Observable<SaleMali> {
    let apiadress = '/api/General/SaleMali/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.get<SaleMali>(this.origin + apiadress, { 'headers': headers }).pipe();
  
   
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  getSaleMaliBycodesal(id): Observable<SaleMali> {
    let apiadress = '/api/General/SaleMali/getbyCodeSal?CodeSal=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    
    let v = this.http.get<SaleMali>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(this.baseUrl + 'users/' + id, httpOptions);
  }
  addSaleMali(item: SaleMali) {
    let apiadress = '/api/General/SaleMali/addSaleMali';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getSaleMaliEmpty(): SaleMali {

    return JSON.parse(JSON.stringify(EmptySaleMali)); 


  }
  getTafsiliHeader(): any {

    return HeaderSaleMali;


  }
  updateSaleMali(item: SaleMali) {
    let apiadress = '/api/Paye/SaleMali/updateSaleMali';
    var headers = this.localstService.header_get(apiadress,null);

 
 
    return this.http.post(this.origin + apiadress,item, { 'headers': headers });
  }

 
  deleteSaleMali(SaleMaliId: number) {
    let apiadress = '/api/General/SaleMali/deletebyid?id=' + SaleMaliId.toString();
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.delete(this.origin + apiadress, { 'headers': headers });
 
  }
 
  countCode(item: string) {
    let apiadress = '/api/SaleMali/countSaleMalicode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  SaleMaliListCode(): Observable<string[]> {
    let apiadress = '/api/General/SaleMali/SaleMalicodelist';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }

  bastanSal(year: number): Observable<ApiRet> {
    let apiadress = '/api/General/SaleMali/bastanSal?year=' + year.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }

 
  OnGlobalSalemaliChang(salemali: number) {
    this.salemaliChangEmitter$.emit(salemali)
  }
 
}
