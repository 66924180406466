import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { User } from '../../_models/user';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.css']
})
export class MemberEditComponent implements OnInit {

    @ViewChild('editForm', { static: true }) editForm: NgForm;
  user: User;
  photoUrl: string;
    //برای وقتی که مرورگر کلا میبندیم
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }

  constructor(private route: ActivatedRoute, private alertify: AlertifyService,
    private userService: UserService, private authService: AuthService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data['user'];
    });
    console.log("username:" + this.user.username);
    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
  }

    updateUser() {

        console.log(this.user);
        
        //پیغام از دست دادن دیتا از بین میبرد
    //    this.editForm.reset(this.user);
    this.userService.updateUser(this.authService.decodedToken.nameid, this.user).subscribe(next => {
        this.alertify.success('مشخصات شما با موفقت ذخیره شد');
      this.editForm.reset(this.user);
    }, error => {
      this.alertify.error(error);
    });
    }

  updateMainPhoto(photoUrl) {

    this.user.photoUrl = photoUrl;

  }
}
