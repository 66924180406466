import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
import { sabtExel, Sanad } from '../../../../_models/app1/opr/Sanad';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxComponent, DxTextBoxModule, DxDataGridComponent, DxTextAreaComponent, DxNumberBoxComponent, DxListComponent, DxLookupComponent} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
 

  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { Sabt } from '../../../../_models/app1/opr/sabt';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { parse } from 'url';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { createElementCssSelector } from '@angular/compiler';
import { MyService } from '../../../../_services/my.service';
import { MyTimeService } from '../../../../_services/myTime.service';
import { dxEvent } from 'devextreme/events';
import { sabtCopyTemp } from '../../../../_models/app1/opr/sabtCopyTemp';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { SaleMali } from '../../../../_models/General/saleMali';
import { GSharh } from '../../../../_models/General/GSharh';
import { GSharhService } from '../../../../_services/General/GSharh.service';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { LoadingService } from '../../../../_services/loading.service';
import { TafsiliGbydaste } from '../../../../_models/app1/paye/tafsiliGbydaste';
import { debounceTime } from 'rxjs/operators';
import { SanadRepOption } from '../../../../_models/app1/opr/sanadrep';
import { ExcelService } from '../../../../_services/excel.service';
 import DataSource from "devextreme/data/data_source";
import { AnDeedRow } from '../../../../_models/app2/opr/AnDeed';
 
 
@Component({
  selector: 'app-Sanad-edit',
  templateUrl: './Sanad-edit.component.html',
  styleUrls: ['./Sanad-edit.component.css']

})
export class SanadEditComponent implements OnInit {
  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) { 
    this.width = width;
  }
  lastrowidtemplate: number;
  @Input() fchanged: boolean;
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Input() focusedSabtId: number; 
  debounceTime = 500;
  focusedRowKey: number;
  countsanadadded: number;
  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMySaveEvent = new EventEmitter<any>();
  @Output() OnMyRefleshEvent = new EventEmitter<boolean>();
  @Output() onfchanged: EventEmitter<any> = new EventEmitter<any>();
  isfchanged: boolean;
 


  @Input() viewfreze: boolean;
  //
 // printoption: SanadRepOption;

  @Output() OnMyPrintEvent = new EventEmitter<number>();

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("moientempedit", { static: false }) moientempedit: DxSelectBoxComponent;
  @ViewChild("taf4tempedit", { static: false }) taf4tempedit: DxSelectBoxComponent;
  @ViewChild("taf5tempedit", { static: false }) taf5tempedit: DxSelectBoxComponent;
  @ViewChild("taf6tempedit", { static: false }) taf6tempedit: DxSelectBoxComponent;

  @ViewChild("distempedit", { static: false }) distempedit: DxTextAreaComponent;
  @ViewChild("distempedit2", { static: false }) distempedit2: DxLookupComponent;



  @ViewChild("bestempedit", { static: false }) bestempedit: DxNumberBoxComponent;
  @ViewChild("bedtempedit", { static: false }) bedtempedit: DxNumberBoxComponent;

  @ViewChild("discolumeditedit", { static: false }) discolumeditedit: DxiColumnComponent;

  @ViewChild("listsharh", { static: false }) listsharh: DxListComponent;
  @ViewChild("ttt", { static: false }) ttt: DxTextBoxComponent;

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;


  bedhaveadad: boolean;
  focusedColumnIndex: number;
  mandehbed: number;
  mandehbes: number;
  sumbed: number;
  sumbes: number;
  ontopselector: string = 'ontopselector';


  mymodle: Sanad;
  mymodle_old: Sanad;
  shkhscodelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  onvandata: IdName[] = [];

  loadingA: boolean;
  loadlistA: boolean[];
  loadingB: boolean;
  loadlistB: boolean[];
  private sub: any;
  test: string;
  n: number;
  selectedrow: Sabt;
  selectedrowOld: Sabt;
  firstShitedRowid: number;

  usernow: User;
  yearnow: number;
  gsharhlist: GSharh[] = [];
  gsharhSelected: GSharh ;

  valdata: DialogData;
  selecteddisindext: number;
  messagetemp: DialogData;
  hesablist: Hesab[] = [];
  TafG: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];
 
  TafGListallCount = 0;
  tafjustdaste: TafsiliGbydaste[] = [];
  candatechang: boolean;
  disableghat: boolean;
  mainwarperCSS: string;
  bSaveCSS: string;
  bYaddashtCSS: string;
  bBarrasiCSS: string;
  bBarrasiCancelCSS: string;
  bGhatiCSS: string;
  bDeleteCSS: string;
  SaleMaliselected: SaleMali;
  ColDisSelector: number ;
  vaziatcolor: string;
  loaddata: loadingCl;
  isreadonly = true;
  //yadashtandnew: boolean;
  moieninchang: boolean;

  firsttimeopen: boolean;

  devDataTaf4: any = {};
  devDataTaf5: any = {};
  devDataTaf6: any = {};
  constructor(private userService: UserService, private alertify: AlertifyService,
    private router: Router,
    private SanadService: SanadService,
    private SabtService: SabtService,
    private hesabService: HesabService,
    private gSharhService: GSharhService,
    private myTimeService: MyTimeService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private loadServ: LoadingService,
    private localst: Localst,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private TafsiliService: TafsiliService,
    private httpClient: HttpClient) {
    this.candatechang = false;
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);
    
    this.rules = { "X": /[02-9]/ };
    this.countsanadadded = 0;
    this.vaziatcolor = 'noneVcolor';
    this.disableghat = true;
    this.mainwarperCSS = 'UnfrzSanad';

    this.isreadonly = false;

    this.bSaveCSS = 'btn btn-light border UnOpSanad';
    this.bYaddashtCSS = 'btn btn-light border UnOpSanad';
    this.bBarrasiCSS = 'btn btn-light border UnOpSanad';
    this.bBarrasiCancelCSS = 'btn btn-light border UnOpSanad';
    this.bGhatiCSS = 'btn btn-light border UnOpSanad';

    this.bDeleteCSS = 'btn btn-light border UnOpSanad';
    this.ColDisSelector = 1;
    this.SaleMaliselected = localst.yearnow_Class_get();
    this.gsharhSelected = gSharhService.getGSharhEmpty();
    this.onvandata = SanadService.getKinds();
    //this.alertify.error(this.SaleMaliselected.codeSal.toString());
    this.loadingA= false;
    this.loadlistA = [true, true, true, true, true, true, true, true, true, true, true, true ];
    this.loadingB= false;
    this.loadlistB = [true, true, true, true, true, true, true, true, true, true, true, true ];
    this.moieninchang = false;
    this.selectedrowOld = null;
    this.firstShitedRowid = null;
    this.firsttimeopen = true;
    this.pv = -1;
    //localst.optioncli.ui_sanad_taf6_width;
   // this.yadashtandnew = false;

   

  }
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
   // console.log(event.keyCode);
    if (event.altKey==true) {
      if (event.keyCode == 83) {

        if (event.shiftKey == true)
          this.addthis('sanad');
        else
          this.yaddasht('yaddasht');
      }
      //n
     if (event.keyCode == 78) {
        this.ctrNthis();
        //this.ttt.instance.focus();
      }
    }

  }

  vadidGo(isimport: boolean , vIndex : number , value : boolean) {

    if (isimport) {

      this.loadlistA[vIndex] = value;

      for (var i = 0; i < this.loadlistA.length; i++) {
        if (this.loadlistA[i] == false) {
          this.loadingA = true;
          return;
        }

      }

      this.loadingA = false;
    }
    else {

      this.loadlistB[vIndex] = value;

      for (var i = 0; i < this.loadlistB.length; i++) {
        if (this.loadlistB[i] == false) {
          this.loadingB = true;
          return;
        }
      }
      this.loadingB = false;
    }


  }

  Testing() {

    var r = $("#disdiv");
    console.log(r);
    r.find('.dx-texteditor-input').focus();
    console.log(r.find('dx-texteditor-input'));


  }
  myloclset() {

 //   this.localst.yearnow_set(0);

  }


  mychek() {

    console.log(this.mymodle);

  }

  testb() {


    console.log('testb');

    // this.mymodle.sabts[1].vaziatN = 'hhh';
    //var newrowsabt = this.SabtService.getSabtEmpty();



    //// Well don't push to nonexistent array maybe? You can explicitly check if it's not null and create one if needed:
    //this.mymodle.sabts = this.mymodle.sabts || [];
    //  newrowsabt.vaziatN = 'mnbmbnnbm';
    //this.mymodle.sabts.push(newrowsabt);


  }
  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;

  }
  ngOnInit() {

   


    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.myoninit();
    this.refleshhesabvatafsili();


    if (this.viewfreze) {


    //  this.dataGrid.editing.allowUpdating = false;
    //  this.dataGrid.editing.allowDeleting = false;
   //   this.dataGrid.editing.allowAdding = false;
      this.isreadonly = true;
    }

   // this.dataGrid.focusedRowIndex = 0;
   // this.dataGrid.selectedRowKeys = this.dataGrid.dataSource[0].id;

   // this.selectedrow = this.dataGrid.dataSource[0];
  
 
  }

  refleshhesabvatafsili() {

    this.loadServ.showloading(this.loaddata ,0);
    this.gSharhService.getGSharhs().subscribe(data => {
      this.loadServ.hideloading(this.loaddata,0);
      this.gsharhlist = data;
      // this.listsharh.selectedItemKeys = [6];

    }, error => {
        this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    this.loadServ.showloading(this.loaddata,1);
    this.hesabService.getHesabs_treenum(3).subscribe(data => {
      this.loadServ.hideloading(this.loaddata,1);
      this.hesablist = data;

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



      this.loadServ.showloading(this.loaddata,2);
      this.TafsiliService.getTafsilisGroupList().subscribe(data => {
      this.loadServ.hideloading(this.loaddata,2);
      this.TafGListall = data;
      this.TafGListallCount = this.TafGListall.length;



      ///???????????????
      this.tafg_reflesh(this.selectedrow.hesabId);

      console.log('Tafglistgeted!!! ' + this.TafGListallCount);
    }, error => {
        this.loadServ.hideloading(this.loaddata,2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




    this.loadServ.showloading(this.loaddata,7);
    this.TafsiliService.getTafsiliJustGrupedByDaste(true ,this.editId, 'sanad').subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 7);

      ///tyutyui78789
      this.tafjustdaste = res.data;
      //پاک کردن کش تمام حساب های tafglist
      for (var r = 0; r < this.TafGListall.length; r++) {
        if (this.TafGListall[r].taf4List.length > 0) {
          this.TafGListall[r].taf4List = [];
          this.TafGListall[r].taf5List = [];
          this.TafGListall[r].taf6List = [];
          this.tafg_reflesh(this.TafGListall[r].hesabId);
        }
      }


    
    }, error => {
        this.loadServ.hideloading(this.loaddata,7);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



    //this.loadServ.showloading(this.loaddata, 7);
    //this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'sanad').subscribe(res => {
    //  this.loadServ.hideloading(this.loaddata, 7);

    //  ///tyutyui78789
    //  if (this.TafsiliService.getTafsiliJustGrupedByDaste__cashGet() == res.cacheId) {

    //    this.tafjustdaste = this.TafsiliService.getTafsiliJustGrupedByDaste__DataGet();
    //  }
    //  else {

    //    this.TafsiliService.getTafsiliJustGrupedByDaste__cashSet(res.cacheId);
    //    this.TafsiliService.getTafsiliJustGrupedByDaste__DataSet(res.data);
    //    this.tafjustdaste = res.data;
    //  }




    //  //پاک کردن کش تمام حساب های tafglist
    //  for (var r = 0; r < this.TafGListall.length; r++) {
    //    if (this.TafGListall[r].taf4List.length > 0) {
    //      this.TafGListall[r].taf4List = [];
    //      this.TafGListall[r].taf5List = [];
    //      this.TafGListall[r].taf6List = [];
    //      this.tafg_reflesh(this.TafGListall[r].hesabId);
    //    }
    //  }



    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 7);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});





    //this.loadServ.showloading(this.loaddata, 7);
    //this.TafsiliService.getTafsiliJustGrupedByDaste(true, this.editId, 'sanad').subscribe(res => {
    //  this.loadServ.hideloading(this.loaddata, 7);
    //  this.tafjustdaste = res.data;



    //  //پاک کردن کش تمام حساب های tafglist
    //  for (var r = 0; r < this.TafGListall.length; r++) {
    //    if (this.TafGListall[r].taf4List.length > 0) {
    //      this.TafGListall[r].taf4List = [];
    //      this.TafGListall[r].taf5List = [];
    //      this.TafGListall[r].taf6List = [];
    //      this.tafg_reflesh(this.TafGListall[r].hesabId);
    //    }
    //  }




    //  console.log('down' + this.tafjustdaste.length);
    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 7);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});


  }



  myoninit() {

    this.isfchanged = false;
    this.onfchanged.emit(false);
    this.candatechang = true;
    //////////////////////////////constractor///////////////////////////
    this.validationCallback = this.validationCallback.bind(this);
    this.mymodle = this.SanadService.getSanadEmpty();
    this.mymodle.sabts = [];
    
 
    this.enc = 0;


    this.focusedRowKey = -1;


    this.lastrowidtemplate = -2000100000;
    this.lastrowidtemplate--;
    this.selectedrow = this.SabtService.getSabtEmpty();
    this.selectedrow.groupName = '-';
    this.selectedrow.kolName = '-';
    this.selectedrow.hesabName = '-';
    this.selectedrow.tafsili4Name = '-';
    this.selectedrow.tafsili5Name = '-';
    this.selectedrow.tafsili6Name = '-';
    let date: Date = new Date();
    console.log("Date = " + date); //Date = Tue Feb 05 2019 12:05:22 GMT+0530 (IST)
    this.usernow = this.localst.usernow_get();

  this.yearnow = this.localst.yearnow_get();

  


    this.sumbed = 0;
    this.sumbes = 0;
    this.mandehbed = 0;
    this.mandehbes = 0;

    this.bedhaveadad = false;
    this.focusedColumnIndex = 2;

    console.log('edit open by id : ' + this.editId);


    //////////////////////////////oninit///////////////////////////

    this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
 
    //this.sub = this.route.params.subscribe(params => {

    //  //console.log(params['id']);
    //  //let t = params['id']
    //  //this.id = t;


    //});



    //this.mymodle.code = '123';
    if (this.editId == -1) {
     // console.log('in new mode');
      this.mymodle = this.SanadService.getSanadEmpty();
      this.mymodle.userFLog = this.usernow.name;
      this.mymodle.userNLog = this.usernow.username;
      this.mymodle.years = this.yearnow;
      this.mymodle.kindId = this.onvandata[0].id;
      this.mymodle.kindN = this.onvandata[0].name;
      this.refleshsanadcods(true);
      this.pushnewrow(true);
      this.isnew = true;

      this.refreshVaziatColor(this.mymodle.vaziat);
     // this.inmyintilized = false;
    }
    else {
      console.log('in edit mode');
      this.isnew = false;

      if (this.mymodle.years == null) {
        this.mymodle.years = this.yearnow;


      }

      //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
      //{ id: 2, name: 'موقت' },
      //{ id: 3, name: 'بررسی شده' },
      //{ id: 4, name: 'قطعی شده' }

      //]

      //ولید 0
      this.vadidGo(true, 0, false);

      this.loadServ.showloading(this.loaddata,3);
      this.SanadService.getSanad(this.editId).subscribe(
   
        (data: Sanad) => {
          this.loadServ.hideloading(this.loaddata,3);
          this.vadidGo(true, 0, true);
       

          this.mymodle = data;


          this.mymodle_old = JSON.parse(JSON.stringify(this.mymodle));
       
              this.mymodle.uSaEF = this.usernow.name;
          this.mymodle.uSaE = this.usernow.username;

          if (this.mymodle.isSys != null) {
            if (this.mymodle.isSys.length > 0) {
              this.viewfreze = true;
              this.isreadonly = true;
            }
           
          }
          else {
            this.viewfreze = false;
            this.isreadonly = false;
          }
          for (var _i = 0; _i < this.mymodle.sabts.length; _i++) {


            if (this.mymodle.sabts[_i].id == null)
              if (this.mymodle.sabts[_i].tafsili4Id == null)
                this.mymodle.sabts[_i].tafsili4Code = '----';
            if (this.mymodle.sabts[_i].tafsili5Id == null)
              this.mymodle.sabts[_i].tafsili5Code = '----';
            if (this.mymodle.sabts[_i].tafsili6Id == null)
              this.mymodle.sabts[_i].tafsili6Code = '----';

       
            
          }

          //رنگ وضعیت
          this.refreshVaziatColor(this.mymodle.vaziat);


         // this.inmyintilized = false;
          this.pushnewrow(true);

          if (this.focusedSabtId != null && this.focusedSabtId != -1) {

          //  console.log('hhhhh  : ' + this.focusedSabtId);
            this.focusedRowKey = this.focusedSabtId;
          }
      

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }


   // this.refreshVaziatColor(this.mymodle.vaziat);


  }


  refleshsanadcods(isnew: boolean) {

   // return;
    console.log('reflesh');
    var g: Sanad = JSON.parse(JSON.stringify(this.mymodle));
    g.sabts = null;
    if (g.date_String != null) {
      this.loadServ.showloading(this.loaddata,4);
      this.SanadService.getNewSanadEmpty(g, isnew).subscribe(data => {
        this.loadServ.hideloading(this.loaddata,4);
        var r = data as Sanad;

        //    this.mymodle.cAtf = r.cAtf;
        //    this.mymodle.cFare = r.cFare;
        //   this.mymodle.cRozane = r.cRozane;
        //  this.mymodle.cShomare = r.cShomare;

        this.mymodle.cAtf = 0;
        this.mymodle.cFare = 0;
        this.mymodle.cRozane = 0;
        this.mymodle.cShomare = 0;

        this.mymodle.date = r.date;


        this.mymodle.date_String = r.date_String;

      //  this.ttt.instance.focus(); 
     //   console.log(JSON.stringify(this.mymodle));
      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

     }

  }
  calculateCellValue(data) {
    return [data.hesabId, data.hesabName].join(" ");
  }
  calledsubmitvaziat;

  
  yaddasht(condition: string) {
    //ثبت یادداشت

   // if (this.bbb)
   //   return;

    
    if (this.loaddata.showed)
      return;

 //  this.loadServ.showloading(20);
    this.calledsubmitvaziat = this.SanadService.getVaziat(1);

    this.onFormSubmit(condition);
 
  }
  addthis(condition: string) {
    //ذخیره موقت
  
    if (this.loaddata.showed)
      return;
    this.calledsubmitvaziat = this.SanadService.getVaziat(2);

    this.onFormSubmit(condition);

  }
  addandnewthis(condition: string) {
    //ذخیره  موقت

    if (this.loaddata.showed)
      return;


    this.calledsubmitvaziat = this.SanadService.getVaziat(2);
    this.onFormSubmit(condition);


  }
  cancelthis(e) {


    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.cancelthis(e);
      });
      return;
    }
 

    if (this.countsanadadded ==0)
      this.OnMyCancelEvent.emit(true);
    else
      this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true ,closepage: true});

  }
  //onFormSubmit = function (e) {

  onFormSubmit(condition : string) {

    //برای رفلش مانده
    this.pushnewrow(false);

    console.log(condition);


    this.repairlostedtaf();


    if (this.myvalidationchek(condition) == false) return;
    else console.log('form custom valided!');

    //اگر اخطاری وجود نداشت وضعیت مدل کلاینت را ابدیت میکنیم
    this.mymodle.vaziatN = this.calledsubmitvaziat.name;
    this.mymodle.vaziat = this.calledsubmitvaziat.id;
     

    let allow = null;

 

      this.refreshVaziatColor(this.calledsubmitvaziat.id);
    if (this.mymodle == null) return;


    //گرفتن اسم نوع
    for (var i = 0; i < this.onvandata.length; i++) {

      if (this.mymodle.kindId == this.onvandata[i].id) {
        this.mymodle.kindN = this.onvandata[i].name;
      }
    }
     

    this.mymodle.years = this.yearnow;
 

    if (this.mymodle.sabts != null) {

      var Sabts: Sabt[] = [];

      for (var val of this.mymodle.sabts) {

        val.kindId = this.mymodle.kindId;
        val.kindN = this.mymodle.kindN;


        if (val.hesabId != null)
          Sabts.push(val);

      }

      this.mymodle.sabts = Sabts;
    }
 //   console.log('list');
  //  console.log(JSON.stringify(this.mymodle));


    //  console.log(val); // prints values: 10, 20, 30, 40
    if (this.mymodle.id == -1) {

        this.vadidGo(true, 1, false);
        this.loadServ.showloading(this.loaddata,6);
        this.SanadService.addSanad(this.mymodle).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,6);
        this.vadidGo(true, 1, true);
       
       

          if (res.errors.length > 0) {

            this.alertify.showListString(res.errors);

          }
          else {
            this.alertify.success(res.mes);
            this.countsanadadded++;


            //سند ذخیره شده را میگیریم
            this.mymodle = res.data as Sanad;


            //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم
            this.editId = this.mymodle.id;


            this.OnMySaveEvent.emit({ refleshShomare: false, reloadlist: false, closepage: false });


            console.log('fffffsave: ' + condition);
            if (condition == 'sanadAndNew' || condition == 'yaddashtAndNew') {
              this.ctrNthis();

            }
            else {

              this.myoninit();
            }


          }

      }, error => {
          this.loadServ.hideloading(this.loaddata,6);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
      this.mymodle.userFLog = this.usernow.name;
      this.mymodle.userNLog = this.usernow.username;
      //  this.mymodle.tafsilisId.name = this.mymodle.nameFarsi + ' ' + this.mymodle.familyFarsi;
      //  this.mymodle.tafsilisId.nameE = this.mymodle.nameEnglish + ' ' + this.mymodle.familyEnglish;
      this.vadidGo(true, 2, false);
      this.loadServ.showloading(this.loaddata,8);
      this.SanadService.updateSanad(this.mymodle).subscribe(res => {
        this.vadidGo(true, 2, true);
        this.loadServ.hideloading(this.loaddata,8);
   
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        this.alertify.success(res.mes);



        this.countsanadadded++;
        //  ؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟
        this.OnMyRefleshEvent.emit(true);
        this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });

    
        if (condition == 'sanadAndNew') {
          this.ctrNthis(); 
      
        }
        if (condition == 'yaddashtAndNew') {
          this.ctrNthis();
    
        }
      }, error => {
          this.loadServ.hideloading(this.loaddata,8);
        this.alertify.error(error);
          console.log("myerror:" + error);
      });

    }

    this.isfchanged = false;
    this.onfchanged.emit(false);
    //  e.preventDefault();
  }





  values = '';


  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;


  


  }


  onostankolValueChanged(e) {

    if (e.value == null) return;

    var ostankolcode = e.value;


  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {

    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);


    //  this.SanadService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");

    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });

    //console.log('sec ' + ccode);


  }
  // public  validationCallback(e: any): boolean {
  ctrNthis() {

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.ctrNthis();
      });
      return;
    }
    //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم
    
    if (this.loaddata.showed)
      return;

    this.viewfreze = false;
    this.editId = -1; 
    this.myoninit();
    this.alertify.message('سند جدید');
    this.ttt.instance.focus();
    
    //رنگ وضعیت
  //  this.refreshVaziatColor(this.mymodle.vaziat);
  }

  validationCallback(e) {
    return true

    let v = e.value;


    for (var val of this.shkhscodelist) {

      if (v == val) return false;
    }
    return true



  }


  validateNumber(e) {



  }
 
  datesabt(e) {
     
    if (this.candatechang)
      return;



   

    const previousValue = e.previousValue;
    const newValue = e.value;
 
 let valid=   this.myTimeService.isvalidday(newValue);



    console.log('isvalid date : ' + valid);


    //if(this.isnew)
    //this.refleshsanadcods(false);
     
 



  }
  onFocusIn(e) {

 

  }
  onFocusIn2(e) {
  
    e.element.querySelector("input.dx-texteditor-input").select();  
  //  e.select();
  //  e.instance.select();
    //reset hide
    this.candatechang = false;

  

  }
  onFocusOut(e) {

 

  }

  onInitialized(e) {
    //   e.component.focus();  
    //  this.inputName.instance.focus();
    //  $("#ttt").focus();  


    // console.log(e.element);
    // console.log(e.instance.focus());

  }

  focusMethod = function getFocus() {
    document.getElementById("myTextField").focus();
  }


  onFocusRow(e) {
    console.log(e);



    for (var _i = 0; _i < this.mymodle.sabts.length; _i++) {
      this.mymodle.sabts[_i]._rowStyle = 'csnone';
      if (this.mymodle.sabts[_i].id == e) {
        this.mymodle.sabts[_i]._rowStyle = 'csfocus';
      }


    }


  }
  deleteDone(e) {

  }










  ///////////////////////////////////table function ----------------------

 
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    for (var _i = 0; _i < this.mymodle.sabts.length; _i++) {


      if (this.mymodle.sabts[_i].id == f.id) {
         
        //اگر اویل بار اولین ردیف را کلیک کردیم
        if (this.selectedrowOld == null)
          this.selectedrowOld = JSON.parse(JSON.stringify(this.mymodle.sabts[_i]));
        else
          this.selectedrowOld = JSON.parse(JSON.stringify(this.selectedrow));

        this.selectedrow = this.mymodle.sabts[_i];
      }
       


    }




    console.log('selectedrow : ' + this.selectedrow.id);
    this.focusedRowKey = this.selectedrow.id;
    console.log(e.rowIndex);


    this.pushnewrow(true);

    console.log('now index' + e.row.rowIndex);
    console.log('now index hesabid' + e.row.data.hesabId);
    if (e.row.data.hesabId == null) {
      this.dataGrid.instance.editRow(e.row.rowIndex);


    }
    // else this.dataGrid.instance.cancelEditData();
 
    this.tafg_reflesh(this.selectedrow.hesabId);

  }

  pushnewrow(focusnwerow : boolean) {

    this.mymodle.sabts = this.mymodle.sabts || [];

    //if (this.mymodle.sabts.length == 0) {

    //  this.mymodle.sabts.push(this.SabtService.getSabtEmpty());

    //  return;
    //}


    let existnewrow = 0;
    let radif = 1;
    this.sumbed = 0;
    this.sumbes = 0;
    this.mandehbed = 0;
    this.mandehbes = 0;


    if (this.mymodle.sabts != null) {
      for (var _i = 0; _i < this.mymodle.sabts.length; _i++) {
        radif = radif + 1;

        if (this.mymodle.sabts[_i].hesabId == null) {

          existnewrow++;
        }


        this.sumbed = this.sumbed + this.mymodle.sabts[_i].bed;
        this.sumbes = this.sumbes + this.mymodle.sabts[_i].bes;

        if (this.sumbed > this.sumbes) {

          this.mandehbed = this.sumbed - this.sumbes;
          this.mandehbes = 0;
        }

        else if (this.sumbed < this.sumbes) {
          this.mandehbed = 0;
          this.mandehbes = this.sumbes - this.sumbed;

        }
        else {
          this.mandehbed = 0;
          this.mandehbes = 0;
        }
      }

    }
    else {

      this.sumbed = 0;
      this.sumbes = 0;

    }


  //  console.log('existnewrow ' + existnewrow);


    //if (this.mymodle.sabts == null) {
    //  this.mymodle.sabts.push(this.SabtService.getSabtEmpty());
    //}
    if (existnewrow ==0 ) {
  
      var r = this.SabtService.getSabtEmpty();
      this.lastrowidtemplate--;
      r.id = this.lastrowidtemplate;
      r.radif = radif;
      this.mymodle.sabts.push(r);
      var r2 = this.SabtService.getSabtEmpty();
      this.lastrowidtemplate--;
      r2.id = this.lastrowidtemplate;
      r2.radif = radif;
      this.mymodle.sabts.push(r2);
      //  this.dataGrid.instance.editRow(this.mymodle.sabts.length);
     // if (focusnwerow)
     // this.focusedRowKey = r.id;

    }

    // console.log('hhhhhhhhh  ' + JSON.stringify(this.mymodle.sabts));

    // console.log(JSON.stringify(this.mymodle.sabts));
  }
 

  onRowUpdating(e) {

    console.log('row updating');


  }


  //tmptae edit
  onss(e, cellInfo) {


    console.log('cellinfo ' + cellInfo);
    cellInfo.setValue('123435');
  }

  pv: any;
  temp_moienchanged(e, cellInfo) {

    this.isfchangcall();
   // return;

    this.pv = -1;
   // return;
  //  if (this.moieninchang)
   //   return;
    var selected;
   // this.moieninchang = true;
  //  console.log('temp_moienchanged');

    var previousValue = e.previousValue;
    var newValue = e.value;

    console.log('ttt' + previousValue + '-' + newValue);
    if (e.value == undefined) {
      console.log('temp_moienchanged undefinedundefinedundefinedundefinedundefinedundefinedundefined'+ previousValue +'-'+newValue);
      console.log(this.selectedrow.id);
      this.pv = previousValue;
      console.log('this.pv  ' + this.pv );
      this.firstShitedRowid = this.selectedrow.id;
 
    }

 


   
    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        break;
        // console.log(this.hesablist[i].id)
      }
       
    }




    if (selected != null) {

 

      ////////////////////

      cellInfo.data.groupId =  selected.groupId;
      cellInfo.data.groupCode = selected.groupCode;
      cellInfo.data.groupName = selected.groupName;


      cellInfo.data.kolId = selected.kolId;
      cellInfo.data.kolCode = selected.kolCode;
      cellInfo.datakolName = selected.kolName;


      cellInfo.data.hesabId = selected.id;
      cellInfo.data.hesabName = selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
      cellInfo.data.hesabCode = selected.codeSum;
    


    }
    else {

      cellInfo.data.groupId = null;
      cellInfo.data.groupCode = null;
      cellInfo.data.groupName = null;


      cellInfo.data.kolId = null;
      cellInfo.data.kolCode = null;
      cellInfo.data.kolName = null;

      cellInfo.data.hesabId = null;
      cellInfo.data.hesabName = null;
     // this.selectedrow.setValue(null);

    }

 



    //اگر معین تغییر نکرده بود تفصیلی ها پاک نشه
        //رفرش شدن تفصیلی باعث باک شدن ان تنفضیلی میشود
    if (previousValue != newValue && newValue != undefined) {

      cellInfo.data.tafsili4Id = null;
      cellInfo.data.tafsili4Code = null;
      cellInfo.data.tafsili4Name = null;

      cellInfo.data.tafsili5Id = null;
      cellInfo.data.tafsili5Code = null;
      cellInfo.data.tafsili5Name = null;

      cellInfo.data.tafsili6Id = null;
      cellInfo.data.tafsili6Code = null;
      cellInfo.data.tafsili6Name = null;

      if (selected != null)
        this.tafg_reflesh(selected.id);
      else
        this.tafg_reflesh(null);

      }
 
    
 


  //  console.log('moien changed');
    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    this.dataGrid.instance.focus();
    //this.pushnewrow(); 

    // this.dataGrid.instance.saveEditData();
    //this.mypresskey('Enter');
  //  this.moieninchang = false;

  }

  first_moienintilized : boolean;

  temp_moienintilized(e, cellInfo) {
    console.log(this.first_moienintilized);
    //console.log( JSON.stringify(e.value));
    if (this.first_moienintilized == undefined) {
      this.first_moienintilized = true;
      console.log(this.first_moienintilized);
    }

  }

  temp_taf4changed(e, cellInfo) {
    //y54ey75475468

  
    this.isfchangcall();

    var previousValue = e.previousValue;
    var newValue = e.value;
  //  console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }
  
    //  برای اراور خالی ماندن اولین سطر
  ////  if (newValue != null && newValue != undefined && newValue != undefined)
   //   this.searchedtaf4id = newValue;


    var selected = null;
    for (let i = 0; i < this.TafG.taf4List.length; i++) {
      if (this.TafG.taf4List[i].id == newValue) {
        selected = this.TafG.taf4List[i];
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }
     
    if (selected != null && previousValue != newValue) {
      // cellInfo.data.tafsili4Id = selected.id;
      // cellInfo.data.tafsili4Name = selected.name; 
      cellInfo.data.tafsili4Id = selected.id;
      cellInfo.data.tafsili4Name = selected.name;
      cellInfo.data.tafsili4Code = selected.code;

 

     // cellInfo.setValue(selected.code);

    }


    if (this.TafG.have5) {




    }
    else {


    }
    // cellInfo.setValue('667567');
  }


  temp_taf5changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;

    //console.log('cellinfo ' + JSON.stringify(cellInfo.data));

    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;

    }
    //  برای اراور خالی ماندن اولین سطر
  //  if (newValue != null && newValue != undefined && newValue != undefined)
  //    this.searchedtaf5id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == newValue) {
        selected = this.TafG.taf5List[i];
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null && previousValue != newValue) {

      cellInfo.data.tafsili5Id = selected.id;
      cellInfo.data.tafsili5Name = selected.name;
      cellInfo.data.tafsili5Code = selected.code;
    //  cellInfo.setValue(selected.code);

    }

    if (this.TafG.have6) {




    }
    else {


    }

  }


  temp_taf6changed(e, cellInfo) {
    this.isfchangcall();
    var previousValue = e.previousValue;
    var newValue = e.value;
   // console.log('cellinfo ' + JSON.stringify(cellInfo.data));
    if (e.value == undefined) {
      //  this.searchedmoid = previousValue
      e.value = previousValue;
      newValue = previousValue;
      // return;
    }
    //  برای اراور خالی ماندن اولین سطر
  //  if (newValue != null && newValue != undefined && newValue != undefined)
   //   this.searchedtaf6id = newValue;



    var selected = null;
    for (let i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == newValue) {
        selected = this.TafG.taf6List[i];
        // console.log(this.hesablist[i].id)
      }

    }

    if (selected != null && previousValue != newValue) {
      //cellInfo.data.tafsili6Id = selected.id;
      //cellInfo.data.tafsili6Name = selected.name;
      cellInfo.data.tafsili6Id = selected.id;
      cellInfo.data.tafsili6Name = selected.name;
      cellInfo.data.tafsili6Code = selected.code;
     // cellInfo.setValue(selected.code);

    }



    if (this.TafG.have6) {




    }
    else {


    }
    //  this.dataGrid.instance.saveEditData();

  }



  ///???????????
  tafg_reflesh(hesabid: number) {
    console.log('hes : ' + hesabid) ;
    if (hesabid == null) {
       
      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
      this.devDataTaf4 = new DataSource({
        store: this.TafG.taf4List,
        paginate: true,
        pageSize: 50
      });
      this.devDataTaf5 = new DataSource({
        store: this.TafG.taf5List,
        paginate: true,
        pageSize: 50
      });
      this.devDataTaf6 = new DataSource({
        store: this.TafG.taf6List,
        paginate: true,
        pageSize: 50
      }); 
      return;


    }
    //?????????????????????????????? چرا
    //if (this.TafG.hesabId == null) {
    //  return;
    //}
    //if (this.TafG.hesabId == hesabid) {
    //  return;
    //}

    this.TafG = null;

    

    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListallCount; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];

         

        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب
   
          for (var j = 0; j < this.tafjustdaste.length; j++) {
          //خواندن تفصیلی 4
         // if (this.TafGListall[_i].taf4List.length == 0)
          for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

            if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی   5
         // if (this.TafGListall[_i].taf5List.length == 0)
          for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

            if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
            }

          }
         //خواندن تفصیلی 6
         // if (this.TafGListall[_i].taf6List.length == 0)
          for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

            if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
            }

          }

        
        }
     



        this.TafG = this.TafGListall[_i];
        this.devDataTaf4 = new DataSource({
          store: this.TafG.taf4List,
          paginate: true,
          pageSize: 50
        });
        this.devDataTaf5 = new DataSource({
          store: this.TafG.taf5List,
          paginate: true,
          pageSize: 50
        });
        this.devDataTaf6 = new DataSource({
          store: this.TafG.taf6List,
          paginate: true,
          pageSize: 50
        });

        if (!this.TafG.have4)
          this.selectedrow.tafsili4Code = "-----";
        if (!this.TafG.have5)
          this.selectedrow.tafsili5Code = "-----";
        if (!this.TafG.have6)
          this.selectedrow.tafsili6Code = "-----";

      //  console.log('$TafG:' + this.TafG.hesabId.toString() + ' l4:' + this.TafG.have4 + ' l5:' + this.TafG.have5 + ' l6:' + this.TafG.have6);


        return;
      }

    }


    /////حالت قدیمی گرفتن گروه هنگام تنتخواب تفصیلی ... سرور ساید
    //this.TafsiliService.getTafsilisGroup(hesabid).subscribe(data => {

    //  this.TafG = data;

    //  console.log(JSON.stringify(this.TafG));

    //  if (this.TafG.have4) {


    //  }
    //  else {

    //  }

    //  if (this.TafG.have5) {

    //  }
    //  else {

    //  }

    //  if (this.TafG.have6) {

    //  }
    //  else {


    //  }



    //  //this.myvs.tafsili4Code = null;
    //  //this.myvs.tafsili5Code = null;
    //  //this.myvs.tafsili6Code = null;
    //  //if (!this.TafG.have4)
    //  //  this.myvs.tafsili4Code = '-------------------------';
    //  //if (!this.TafG.have5)
    //  //  this.myvs.tafsili5Code = '-------------------------';
    //  //if (!this.TafG.have6)
    //  //  this.myvs.tafsili6Code = '-------------------------';

    //}, error => {
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});



  }


  //////////////////////////////////////////////////////////////>



  onEditCanceled(e) {


    console.log('canceled....................................................');

  }

  logEvent(eventName) {
    console.log(eventName + 'yyyy');
  }

  onSavedrow(eventName) {
    console.log(eventName + 'ssssss');
  }
  onFocusInrowend(e) {

    // this.dataGrid.instance.saveEditData();






  }


  onValueChangedbed(e, cellInfo) {
    this.isfchangcall();
    var newval = e.value;

  //  this.selectedrow.bed = newval;
   // cellInfo.setValue(newval);

    if (newval != null) {
      {
        if (newval < 0)
          newval = 0;

        cellInfo.data.bed = newval;
        //this.selectedrow.bed = newval;
      //  cellInfo.setValue(newval);
        if (newval > 0) {

          cellInfo.data.bes = 0;
         // this.selectedrow.bes = 0;
        }


      }


    }

   


  }
  onValueChangedbes(e, cellInfo) {
    this.isfchangcall();
   // console.log('selectedrow is : ' + this.selectedrow.hesabCode);



    var newval = e.value;

  //  this.selectedrow.bes = newval;
  //  cellInfo.setValue(newval);

    if (newval != null) {
      {
        if (newval < 0)
          newval = 0;
         
        cellInfo.data.bes = newval;
      //  this.selectedrow.bes = newval;
      //  cellInfo.setValue(newval);
        if (newval > 0) {

          cellInfo.data.bed = 0;
         // this.selectedrow.bed = 0;
        }


      }


    }


    //if (this.selectedrow.bes>0) {

    //  this.selectedrow.bed = 0;
    //}

  }


  onInputbed(e, cellInfo) {




  }
  onInputbes(e, cellInfo) {

    var newval = e.value;



  }

  onKeyDownGrid(e) {

   // this.upshift = 0;
  //  console.log('hhh: '+ e.event.shiftKey);
    this.mypresskey(e.event);

  }

 

  //searchedmoid : number;
  //onSearchChangeMo(searchValue: string): void {
  //  this.searchedmoid = -1;

  //  var thenum = searchValue.replace(/[^0-9]/g, '');

  // // console.log(thenum);

  //  if (thenum.length >2)
  //  for (var r = 0; r < this.hesablist.length; r++) {
  //    if (this.hesablist[r].codeSum == thenum) {

  //     // console.log(JSON.stringify(this.moientempedit.items[r]));
  //      //this.moientempedit.selectedItem = this.moientempedit.items[r];
  //     // this.moientempedit.value = this.moientempedit.items[r].id;
  //      this.searchedmoid = this.hesablist[r].id;
  //      break;
  //    }

  //  }
  //}
  //searchedtaf4id: number;
  //onSearchChangeTaf4(searchValue: string): void {
  //  this.searchedtaf4id = -1;

  //  //if (searchValue == '   ') {
  //  //  this.taf4tempedit.minSearchLength = 0;
  //  //  this.taf4tempedit.value = '';

  //  //}

  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  // // console.log(thenum);
  //  if (thenum.length > 5)
  //  for (var r = 0; r < this.TafG.taf4List.length; r++) {
  //    if (this.TafG.taf4List[r].code == thenum) {
  //      this.searchedtaf4id = this.TafG.taf4List[r].id;
  //      break;
  //    }

  //  }


  //}
   
  //searchedtaf5id: number;
  //onSearchChangeTaf5(searchValue: string): void {
  //  this.searchedtaf5id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf5List.length; r++) {
  //      if (this.TafG.taf5List[r].code == thenum) {
  //        this.searchedtaf5id = this.TafG.taf5List[r].id;
  //        break;
  //      }

  //    } 
  //}

  //searchedtaf6id: number;
  //onSearchChangeTaf6(searchValue: string): void {
  //  this.searchedtaf6id = -1;
  //  var thenum = searchValue.replace(/[^0-9]/g, '');
  //  // console.log(thenum);
  //  if (thenum.length > 5)
  //    for (var r = 0; r < this.TafG.taf6List.length; r++) {
  //      if (this.TafG.taf6List[r].code == thenum) {
  //        this.searchedtaf6id = this.TafG.taf6List[r].id;
  //        break;
  //      }

  //    }


  //}


  moCustumkeydown(event) {
    console.log(event, event.keyCode, event.keyIdentifier);
  }
  tw;
  onKeyDownmoien(e) {
    //return;
    if ( e.event.key == 'Insert') {

      this.moientempedit.minSearchLength = 0;
    }
   
    //if (this.moientempedit != null) {
     
    //  console.log('yyyyyyyyyyyyy');
    ////  console.log( JSON.stringify( this.moientempedit.items[0]));
    ////  if (this.moientempedit.search)
    ////  this.moientempedit.value = 4042;
    //  //if (this.moientempedit.items.length == 1) {

    //  //  this.tw = this.moientempedit.items[0];
    //  //  this.moientempedit.selectedItem = this.tw;
    //  //  this.moientempedit.value = this.tw.id;
    //  //  console.log('select one');
    //  //}

    //}
  }
  onKeyDowntaf4(e) {

    if ( e.event.key == 'Insert') {

      this.taf4tempedit.minSearchLength = 0;
    }
    //if (this.taf4tempedit.items.length == 1) {

    //  this.tw = this.taf4tempedit.items[0];
    //  this.taf4tempedit.selectedItem = this.tw;
    //  this.taf4tempedit.value = this.tw.id;
    //  console.log('select one');
    //}

  }
  onKeyDowntaf5(e) {
    if ( e.event.key == 'Insert') {

      this.taf5tempedit.minSearchLength = 0;
    }
    //if (this.taf5tempedit.items.length == 1) {

    //  this.tw = this.taf5tempedit.items[0];
    //  this.taf5tempedit.selectedItem = this.tw;
    //  this.taf5tempedit.value = this.tw.id;
    //  console.log('select one');
    //}


  }
  onKeyDowntaf6(e) {

    if ( e.event.key == 'Insert' ) {

      this.taf6tempedit.minSearchLength = 0;
    }
    //if (this.taf6tempedit.items.length == 1) {

    //  this.tw = this.taf6tempedit.items[0];
    //  this.taf6tempedit.selectedItem = this.tw;
    //  this.taf6tempedit.value = this.tw.id;
    //  console.log('select one');
    //}



  }



  onFocusInDis() {

    this.selecteddisindext = this.dataGrid.focusedRowIndex;
  }

  gDisRow_insert: number =-1;
  gDisColumn_insert: number = -1;;
  onKeyDowndis(e) {

    
   // console.log(e.event.key);
 //fghtgttttttt5
  
    if (e.event.key == 'Insert') {

  
      
      if (this.ColDisSelector === 1) {
        console.log('11111111');
        this.ColDisSelector = 2;
        this.discolumeditedit.editCellTemplate = 'editColumnDis2';
    

      }
      else if (this.ColDisSelector === 2) {
        console.log('2222222');
        this.ColDisSelector = 1;
        this.discolumeditedit.editCellTemplate = 'editColumnDis';
      }

 
      
 
    }
    if (e.event.key == 'Enter') {
     /* this.discolumeditedit.instance.*/

    }

    this.mypressMovekey(e.event);
 
  

  }
  onKeyDowndis2(e) {


    //if (this.ColDisSelector === 1) {
    //  console.log('11111111');
    //  this.ColDisSelector = 2;
    //  this.discolumeditedit.editCellTemplate = 'editColumnDis2';


    //}
    //else if (this.ColDisSelector === 2) {
    //  console.log('2222222');
    //  this.ColDisSelector = 1;
    //  this.discolumeditedit.editCellTemplate = 'editColumnDis';
    //}


  }

  temp_Dischanged(e, cellInfo) {
    this.isfchangcall();
    const previousValue = e.previousValue;
    const newValue = e.value;

  //  this.selectedrow.dis = newValue;
   // cellInfo.setValue(newValue);
   //ghjhgjhgjhgkjghk
    cellInfo.data.dis = e.value;
    
  }


  onClosedis2() {

    if (this.ColDisSelector === 2) {
      console.log('2222222');
      this.ColDisSelector = 1;
      this.discolumeditedit.editCellTemplate = 'editColumnDis';
    }
  }


  temp_DisonSelectionChanged2(e, cellInfo) {


    if (e.selectedItem == null)
      return;

    const newValue = e.selectedItem;
    if (newValue.text == null)
      return;
    //console.log(JSON.stringify(newValue));

    if (this.selectedrow.dis == null)
      this.selectedrow.dis = newValue.text;
    else
      this.selectedrow.dis += newValue.text;

    this.ColDisSelector = 1;



    const rowindex = this.dataGrid.focusedRowIndex;
    const columnindex = this.dataGrid.focusedColumnIndex;

    var navtocell = this.dataGrid.instance.getCellElement(rowindex, columnindex);
    //  var cellolv = this.dataGrid.instance.cellValue(rowindex, columnindex);
    // this.dataGrid.instance.cellValue(rowindex, columnindex, cellolv);
    this.dataGrid.instance.editCell(rowindex, columnindex);
    this.dataGrid.instance.focus(navtocell);
    this.discolumeditedit.editCellTemplate = 'editColumnDis';
  }


  onKeyDownbed(e) {

   // if (e.event.key = 'Enter')
    this.mypressMovekey(e.event);
  }

  onKeyDownbes(e) {


  //  if (e.event.key = 'Enter') 
    this.mypressMovekey(e.event);
   

  }


  mypressMovekey(event: KeyboardEvent) {

    this.oldkey = event.key;

    //console.log(event.key);

    if (event.key == 'ArrowDown') {

      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;

      row++;
      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      // console.log('ArrowDown');

    }
    if (event.key == 'ArrowUp') {



      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col == 0)
        return;
      row--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
    }

    if (event.key == 'ArrowLeft') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;
      //gyftut678
      if (col > 6 || col < 5)
        return;

      col++;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
       
    }
    if (event.key == 'ArrowRight') {
      var row = this.dataGrid.focusedRowIndex;
      var col = this.dataGrid.focusedColumnIndex;

      if (col < 6)
        return;

      col--;

      var navcel = this.dataGrid.instance.getCellElement(row, col);
      this.dataGrid.instance.focus(navcel);
      this.dataGrid.instance.editCell(row, col);
      //console.log('ArrowLeft');
    }



  }

  upshift: number =0;
  mypresskey(event: KeyboardEvent) {



    if (this.oldkey != event.key) {
      //اگر در یک سلول جابجا نشدیم و دوبار  یا بیشترکلید تکراری زدیم
      this.upshift = 1;
    }
    else {
      this.upshift++;
    }
    this.oldkey = event.key;


    //کلید
    // this.upshift = 1;
    //وقتی سلول جدول تغییر میکند
    //این رویداد در تابع تغییر سلول جدول رخ می دهد
  



    // console.log('***mypre45645645sskey***');

    if (event.key == 'F2') {

      // this.upshift = 1;



      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;

      //رنج را بررسی میکنیم
      if (indexrow -this.upshift  < 0) {
        this.upshift = 1;
      }

      //if (indexrow -this.upshift  ==0)
      //  return;


      var key = this.dataGrid.instance.getKeyByRowIndex(indexrow - this.upshift) ;

      var bala= this.mymodle.sabts.find(x => x.id === key);

      if (bala == null)
        return;


      if (indexcolum == 1) {
       // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.hesabCode);
       // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.he);
        this.mymodle.sabts[indexrow].hesabId = bala.hesabId;
        this.mymodle.sabts[indexrow].hesabCode = bala.hesabCode;
        this.mymodle.sabts[indexrow].hesabName = bala.hesabName;
        this.tafg_reflesh(bala.hesabId);
      }
      else if (indexcolum == 2) {
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.hesabCode);
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.he);
        if (this.mymodle.sabts[indexrow].hesabId == bala.hesabId) {
          this.mymodle.sabts[indexrow].tafsili4Id = bala.tafsili4Id;
          this.mymodle.sabts[indexrow].tafsili4Code = bala.tafsili4Code;
          this.mymodle.sabts[indexrow].tafsili4Name = bala.tafsili4Name;
        }
        else {
          this.alertify.warning('معین یکسان نیست');
        }
      }
      else if (indexcolum == 3) {
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.hesabCode);
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.he);
        if (this.mymodle.sabts[indexrow].hesabId == bala.hesabId) {
          this.mymodle.sabts[indexrow].tafsili5Id = bala.tafsili5Id;
          this.mymodle.sabts[indexrow].tafsili5Code = bala.tafsili5Code;
          this.mymodle.sabts[indexrow].tafsili5Name = bala.tafsili5Name;
        }
        else {
          this.alertify.warning('معین یکسان نیست');
        }
      }
      else  if (indexcolum == 4) {
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.hesabCode);
        // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.he);
        if (this.mymodle.sabts[indexrow].hesabId == bala.hesabId) {
          this.mymodle.sabts[indexrow].tafsili6Id = bala.tafsili6Id;
          this.mymodle.sabts[indexrow].tafsili6Code = bala.tafsili6Code;
          this.mymodle.sabts[indexrow].tafsili6Name = bala.tafsili6Name;
        }
        else {
          this.alertify.warning('معین یکسان نیست');
        }
      }

      else if (indexcolum == 5) {
       // this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.dis);
        this.mymodle.sabts[indexrow].dis = bala.dis;

      }
      else if (indexcolum == 6) {
        this.mymodle.sabts[indexrow].bed = bala.bed;
           if (bala.bed >0)
             this.mymodle.sabts[indexrow].bes = 0;

        //this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.bed);
        //if (bala.bed >0)
        //this.dataGrid.instance.cellValue(indexrow, indexcolum +1, 0);

      }
      else if (indexcolum == 7) {
        this.mymodle.sabts[indexrow].bes = bala.bes;
        if (bala.bes > 0)
          this.mymodle.sabts[indexrow].bed = 0;
        //this.dataGrid.instance.cellValue(indexrow, indexcolum, bala.bes);
        //if (bala.bes > 0)
        //  this.dataGrid.instance.cellValue(indexrow, indexcolum - 1, 0);

      }

    }





    if (event.key == 'Enter') {
      this.pushnewrow(false);

      // console.log('***mypresskey***');

      //  this.dataGrid.instance.focus(navtocell);
      // this.dataGrid.instance.editCell(indexrow, indexcolum);

      var indexrow = this.dataGrid.focusedRowIndex;
      var indexcolum = this.dataGrid.focusedColumnIndex;


      //var nnn = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      //this.dataGrid.instance.focus(nnn);
      //this.dataGrid.instance.editCell(indexrow, indexcolum);


      if (indexcolum == 0) {

        indexcolum++;
      }

      //moien
      else if (indexcolum == 1) {

        //if (this.searchedmoid != -1) {

        //  this.moientempedit.value = this.searchedmoid;
        //  this.selectedrow.hesabId = this.searchedmoid;
      
        //  // this.alertify.message(this.searchedmoid.toString());
        //  //ست کردن نام گروه و ....
        //  this.setrowinfoBymoien(this.searchedmoid);
        //  this.searchedmoid = -1;

        //}
    
        //2/برگشت
        if (event.shiftKey) {

          if (indexrow > 0) {
            indexrow--;
            indexcolum = 7;
          }

        }
        //2/رفت
        else {
          if (this.TafG.have4) {
            indexcolum++;
          }
          else {
            indexcolum = indexcolum + 4;
          }
        }

      }

      //tafsili4
      else if (indexcolum == 2) {

        //console.log('this.searchedtaf4id >>>>>>>' + this.searchedtaf4id);
        //if (this.searchedtaf4id != -1) {
        //  console.log('this.searchedtaf4id >>>>>>>' + this.searchedtaf4id);
        //  this.taf4tempedit.value = this.searchedtaf4id;
        //  this.selectedrow.tafsili4Id = this.searchedtaf4id;
        //  //  this.setrowinfoByTaf4(this.searchedtaf4id);
        //  this.searchedtaf4id = -1;

        //}

        //2/برگشت
        if (event.shiftKey) {

          indexcolum--;

        }
        //2/رفت
        else {

          if (this.TafG.have5) {
            indexcolum++;
          }
          else {

            indexcolum = indexcolum + 3;
          }
        }
      }
      //tafsili5
      else if (indexcolum == 3) {
        //if (this.searchedtaf5id != -1) {
        //  this.taf5tempedit.value = this.searchedtaf5id;
        //  this.selectedrow.tafsili5Id = this.searchedtaf5id;
        //  this.setrowinfoByTaf5(this.searchedtaf5id);
        //  this.searchedtaf5id = -1;
        //}
        //2/برگشت
        if (event.shiftKey) {



          indexcolum--;


        }
        //2/رفت
        else {
          if (this.TafG.have6) {
            indexcolum++;
          }
          else {

            indexcolum = indexcolum + 2;
          }
        }

      }
      //tafsili6
      else if (indexcolum == 4) {
        //if (this.searchedtaf6id != -1) {
        //  this.taf6tempedit.value = this.searchedtaf6id;
        //  this.selectedrow.tafsili6Id = this.searchedtaf6id;
        //  this.setrowinfoByTaf6(this.searchedtaf6id);
        //  this.searchedtaf6id = -1;
        //}
        //2/برگشت
        if (event.shiftKey) {



          indexcolum--;


        }
        //2/رفت
        else {

          indexcolum++;

        }

      }
      //dis
      else if (indexcolum == 5) {

        var navtocell = this.dataGrid.instance.getCellElement(indexrow, 5);
        this.dataGrid.instance.focus(navtocell);

        //2/برگشت
        if (event.shiftKey) {

          if (this.TafG.have6)
            indexcolum--;
          else if (this.TafG.have5)
            indexcolum = indexcolum - 2;
          else if (this.TafG.have4)
            indexcolum = indexcolum - 3;
          else
            indexcolum = indexcolum - 4;
        }
        //2/رفت
        else {
          indexcolum++;
        }

      }
      //bed
      else if (indexcolum == 6) {


        //2/برگشت
        if (event.shiftKey) {

          indexcolum--;
        }
        //2/رفت
        else {

          indexcolum++;

          if (this.selectedrow.bed > 0) {


            if (this.selectedrow.hesabId == null) {
              indexcolum = 1;
            }
            else {
              indexrow++;
              indexcolum = 1;

            }


          }



        }


        if (this.selectedrow.bed > 0) {
          this.selectedrow.bes = 0;
        }
      }
      //radif 
      else if (indexcolum == 7) {

        //2/برگشت
        if (event.shiftKey) {

          indexcolum--;
        }
        //2/رفت
        else {
          if (this.selectedrow.hesabId == null) {
            indexcolum = 1;
          }
          else {

           // this.dataGrid.focusedRowIndex++;
            indexrow++;
            indexcolum = 1;

         
         

         
            //this.dataGrid.instance.refresh();

            //for (var i = 0; i < this.mymodle.sabts.length; i++) {

            //  if (this.mymodle.sabts[i].id == this.focusedRowKey) {


            //  }

            // }


          }
        }
      }

      this.repairShitRow();
     
     // this.focusedRowKey = indexrow;

      this.xx = indexcolum;
      this.yy = indexrow;
      //تا ردیف روی قبلی نماند
    
      //this.dataGrid.instance.clearSelection();
      //this.dataGrid.instance.saveEditData();
      this.dataGrid.instance.closeEditCell();
      // this.delay(1000);
      var navtocell = this.dataGrid.instance.getCellElement(indexrow, indexcolum);

      this.enc++;

     // var navtocell = this.dataGrid.instance.getCellElement(indexrow);

      this.dataGrid.instance.focus(navtocell);
     
      var cellolv = this.dataGrid.instance.cellValue(indexrow, indexcolum);
      // console.log('celkl value : ' +JSON.stringify(this.dataGrid.instance.cellValue(indexrow, indexcolum)));

      this.dataGrid.instance.cellValue(indexrow, indexcolum, cellolv);

 

      this.dataGrid.instance.editCell(indexrow, indexcolum);
    //  this.dataGrid.instance.closeEditCell();
     // this.dataGrid.instance.focus(navtocell);

     // this.dataGrid.instance.closeEditCell();
      //var yyy = this.dataGrid.instance.getCellElement(indexrow, indexcolum);
      //var y = this.dataGrid.instance.getScrollable();
      //if (yyy != undefined)
      //  y.scrollToElement(yyy);
     // this.dataGrid.instance.editCell(indexrow, indexcolum);
      this.oldrowindex = indexrow;
      this.oldcolumindex = indexcolum;

      //var navtorow = this.dataGrid.instance.getRowElement(indexrow);
      //this.dataGrid.instance.focus(navtorow);
      // this.dataGrid.onEditingStart
    }



  }

   delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

   

   setrowinfoBymoien(moid : number){

     var selected;
     for (let i = 0; i < this.hesablist.length; i++) {
       if (this.hesablist[i].id == moid) {
         selected = this.hesablist[i];
         break;
         // console.log(this.hesablist[i].id)
       }

     }
     if (selected != null) {



      this.selectedrow.groupId = selected.groupId;
       this.selectedrow.groupCode = selected.groupCode;
       this.selectedrow.groupName = selected.groupName;


       this.selectedrow.kolId = selected.kolId;
       this.selectedrow.kolCode = selected.kolCode;
       this.selectedrow.kolName = selected.kolName;


       this.selectedrow.hesabId = selected.id;
       this.selectedrow.hesabName = selected.name;
       //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
       this.selectedrow.hesabCode = selected.codeSum;
       // cellInfo.data.hesabCode = selected.codeSum;
       //خود سلول را باید حتما از این تابع استفاده کنیم تا مقدار بگیرد و بشه تب را زد
      // cellInfo.setValue(selected.codeSum);




     }
     else {

       this.selectedrow.groupId = null;
       this.selectedrow.groupCode = null;
       this.selectedrow.groupName = null;


       this.selectedrow.kolId = null;
       this.selectedrow.kolCode = null;
       this.selectedrow.kolName = null;

       this.selectedrow.hesabId = null;
       this.selectedrow.hesabName = null;
      // this.selectedrow.setValue(null);

     }



}

  setrowinfoByTaf4(tafid: number) {

    var selected = null;
    for (let i = 0; i < this.TafG.taf4List.length; i++) {
      if (this.TafG.taf4List[i].id == tafid) {
        selected = this.TafG.taf4List[i];
        break;
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }

    if (selected != null) {
      // cellInfo.data.tafsili4Id = selected.id;
      // cellInfo.data.tafsili4Name = selected.name; 
      this.selectedrow.tafsili4Id = selected.id;
      this.selectedrow.tafsili4Name = selected.name;
      this.selectedrow.tafsili4Code = selected.code;
     // cellInfo.setValue(selected.code);

    }

  }
  setrowinfoByTaf5(tafid: number) {

    var selected = null;
    for (let i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == tafid) {
        selected = this.TafG.taf5List[i];
        break;
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }

    if (selected != null) {
      // cellInfo.data.tafsili5Id = selected.id;
      // cellInfo.data.tafsili5Name = selected.name; 
      this.selectedrow.tafsili5Id = selected.id;
      this.selectedrow.tafsili5Name = selected.name;
      this.selectedrow.tafsili5Code = selected.code;
      // cellInfo.setValue(selected.code);

    }

  }
  setrowinfoByTaf6(tafid: number) {

    var selected = null;
    for (let i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == tafid) {
        selected = this.TafG.taf6List[i];
        break;
        //console.log('ghhhhhhhhhhhhhhhhhhhhhh ' + JSON.stringify(selected))
      }

    }

    if (selected != null) {
      // cellInfo.data.tafsili6Id = selected.id;
      // cellInfo.data.tafsili6Name = selected.name; 
      this.selectedrow.tafsili6Id = selected.id;
      this.selectedrow.tafsili6Name = selected.name;
      this.selectedrow.tafsili6Code = selected.code;
      // cellInfo.setValue(selected.code);

    }

  }
  xx;
  yy;
  oldrowindex: number;
  oldkey: string;
  enc: number;
  oldcolumindex: number;
  testtt() {
    this.distempedit2.instance.open();

   // var navtocell = this.dataGrid.instance.getCellElement(1, 1);
   // this.dataGrid.instance.focus(navtocell);
   // this.dataGrid.instance.editCell(1, 1);

  }
  onFocusedCellChanged(e) {

    this.upshift = 0;
   // this.oldkey = null;
   // console.log('fchaaaaaaaaaaaaaaaaaaa');
   // this.repairShitRow();
   // this.dataGrid.instance.focus(e.cellElement);
    // this.dataGrid.instance.closeEditCell();
   // this.dataGrid.instance.refresh();

   // if (e.columnIndex ==1 )
   // if (this.oldkey !='Enter')

   // this.dataGrid.instance.editCell(e.rowIndex, e.columnIndex);


  //  this.dataGrid.instance.cellValue(e.rowIndex, e.columnIndex,'5');
  }
  onFocusedCellChanging(e) {

    //e.isHighlighted = true;
    //// e.cancel = true;


    //console.log('fchaaaaaaaaaaaaaaaaaaa');

    //// this.dataGrid.instance.focus(e.cellElement);
    //// this.dataGrid.instance.closeEditCell();
   
    //if (this.dataGrid.instance.hasEditData()) {
    //  this.alertify.error('jjjjjj');

    //  this.dataGrid.instance.saveEditData();
    //}
     
  }
  onEditingStart(e) {

    console.log('onEditingStart');


  }

  test44(e) {

    //this.loadServ.showloading(7);
    //this.TafsiliService.getTafsilisGroupList().subscribe(data => {
    //  this.loadServ.hideloading(7);
    //  this.TafGListall = data;
    //  console.log('down');
    //}, error => {
    //    this.loadServ.hideloading(7);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});


  }
  test55(e) { 




  }

  

  adjustWidth(e,w) {

    e.component._popup.option('width', w);
    if (this.firsttimeopen) {
    //  this.moientempedit.minSearchLength = 0;
     // this.dataGrid.instance.editCell(this.lastindex, 5);
   
this.firsttimeopen = false;
    //  this.moientempedit.onValueChanged.emit(4044);
    //  this.moientempedit.onKeyDown.emit(99);
      //var navtocell = this.dataGrid.instance.getCellElement(this.lastindex, 1);
      //this.dataGrid.instance.focus(navtocell);
      //var cellolv = this.dataGrid.instance.cellValue(this.lastindex, 1);
      //this.dataGrid.instance.cellValue(this.lastindex, 1, cellolv);
      //this.dataGrid.instance.editCell(this.lastindex, 1);
      //this.dataGrid.instance.focus(navtocell);

   // this.moientempedit.selectedItem = null;
    //  this.moientempedit.selectedItem = 'hjkhjkh';

      //var navtocell2 = this.dataGrid.instance.getCellElement(this.lastindex, 5);
      //this.dataGrid.instance.focus(navtocell2);
      //var cellolv2 = this.dataGrid.instance.cellValue(this.lastindex, 5);
      //this.dataGrid.instance.cellValue(this.lastindex, 5, cellolv2);
      //this.dataGrid.instance.editCell(this.lastindex, 5);
      //this.dataGrid.instance.focus(navtocell2);

     
    }
  }

  moisopen: number;
  onFocusmoienedit(e, item) {


    if (this.moientempedit.minSearchLength == 0)
      this.moientempedit.minSearchLength = 2;

    if (this.moisopen == null) this.moisopen = 0;

    this.moientempedit.instance.open();
    this.moisopen++;
    //console.log('------------------------------');
    //console.log( JSON.stringify(   this.moientempedit.searchExprChange));
    //this.moientempedit.selectedItem = this.moientempedit.items[0];
   // this.moientempedit.value = this.moientempedit
  }
 
  onFocustaf4dit(e) {
    if (this.taf4tempedit.minSearchLength == 0)
      this.taf4tempedit.minSearchLength = 3;

    this.taf4tempedit.instance.open();
    // e.instance.open();
  }
  onFocustaf5dit(e) {
    if (this.taf5tempedit.minSearchLength ==0)
      this.taf5tempedit.minSearchLength = 3;

     this.taf5tempedit.instance.open();
     // e.instance.open();
  }
  onFocustaf6dit(e) {
    if (this.taf6tempedit.minSearchLength == 0)
      this.taf6tempedit.minSearchLength = 3;

      this.taf6tempedit.instance.open();
  //    e.instance.open();
  }
  onFocusDis2dit(e) {
    console.log('hummmm');
    this.distempedit2.instance.open();
    //    e.instance.open();
  }

  testthis(e) {

    let dialogRef = this.dialog.open(DialogvalidComponent, {
      width: '250px',
      data: {}
    });

 
    dialogRef.afterClosed().subscribe(result => {

      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        console.log('dell');

      }

    });
    //    this.selectedrow.bes = 0;
    // this.focusedRowKey = -2000100002;
    // this.dataGrid.instance.editCell(0, 'dis');
    //this.dataGrid.focusedColumnIndex = 3;
    // console.log(this.dataGrid.focusedColumnIndex);


    //  this.dataGrid.instance.editRow(1);
    // this.moientempedit.instance.focus();
  }

  onFocusInbes(e) {



  }


  onInitializedTaf4(e, cellInfo) {


    console.log('onInitializedTaf4  ');
    // this.taf4tempedit.dataSource = this.TafG.taf4List;
    // console.log('onInitializedTaf4  List ::::::: ' + JSON.stringify(this.TafG.taf4List));

  }

 




  onCellPrepared(e) {


 

  }




  myvalidationchek(condition: string): boolean {


    //this.valdata.rows =  [];
    //this.valdata.rows = ['Apple', 'Orange', 'Banana'];
    //this.valdata.rows.push('ارور');
    //this.valdata.rows.push('sddssdds');
    //if (this.valdata.rowss.length == 0)
    //  return true;

    const verror = [];


    if (this.mymodle == null)
      verror.push('model is empty');
    else {

      console.log(this.mymodle.date_String.length);

      if (this.mymodle.date_String.length < 8)
        verror.push('تاریخ سند وارد نشده');

      if (this.mymodle.kindId == -1 || this.mymodle.kindId == null)
        verror.push('نوع سند وارد نشده');

      console.log(this.mymodle.date_String);

    
     // this.myTimeService.isvalidday(this.mymodle.date_String);

      let datevalid = this.myTimeService.isvalidday(this.mymodle.date_String);

   //   this.mymodle.date = +this.mymodle.date_String.replace(/\/r/g, '');
     
      if (datevalid != null) {
        
        verror.push(datevalid);
      }

      var years = this.localst.yearnow_Class_get();

      console.log(this.mymodle.date_String); 
      var dajfouy = parseInt(this.mymodle.date_String.replace('/', '').replace('/', '').replace('/', '').toString()); 
    //  console.log('jjjjjjjjjjjjjjjjjjjjjjj');
    //  console.log(dajfouy);
     // console.log(JSON.stringify(years))

      if (dajfouy > years.dateE) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }

      if (dajfouy < years.dateS) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }

     // if (this.mymodle.date_String)
 
      //اگر ثبت موقت بود و یادداشت نبود
      if (this.calledsubmitvaziat.id == 2) {

        if (this.mandehbes > 0 || this.mandehbed > 0) {

          verror.push('سند تراز نیست');
 
          console.log(this.mandehbes);
       //   console.log(this.mandehbed);

      



        }
        //this.calledsubmitvaziat.vaziat > 2 
     


 



      }

      if (this.mymodle.vaziat > 2) {


        verror.push('ابتدا سند را در وضعیت موقت قرار دهید');


      }




      let c = 0;
      for (var _i = 0; _i < this.mymodle.sabts.length; _i++) {

        if (this.mymodle.sabts[_i].hesabId == null || this.mymodle.sabts[_i].hesabId ==-1)
          continue;


          c++;


        
              var intafg=   this.selecttafg(this.mymodle.sabts[_i].hesabId);
          console.log('ggg: ' + JSON.stringify( intafg));

        //اگر یاداداشت بود از این میگذریم
        if (this.calledsubmitvaziat.id != 1) {
          if (intafg.have4) {

            if (this.mymodle.sabts[_i].tafsili4Id == null || this.mymodle.sabts[_i].tafsili4Id == -1) {

              verror.push(' ردیف ' + this.mymodle.sabts[_i].radif + ' تفصیلی سطح چهار مشخص نیست ');
            }

          }
          if (intafg.have5) {

            if (this.mymodle.sabts[_i].tafsili5Id == null || this.mymodle.sabts[_i].tafsili5Id == -1) {

              verror.push(' ردیف ' + this.mymodle.sabts[_i].radif + ' تفصیلی سطح پنج مشخص نیست ');
            }

          }
          if (intafg.have6) {

            if (this.mymodle.sabts[_i].tafsili6Id == null || this.mymodle.sabts[_i].tafsili6Id == -1) {

              verror.push(' ردیف ' + this.mymodle.sabts[_i].radif + ' تفصیلی سطح شش مشخص نیست ');
            }

          }


          if (this.mymodle.sabts[_i].bed == 0 && this.mymodle.sabts[_i].bes == 0) {

            verror.push(' ردیف ' + this.mymodle.sabts[_i].radif + ' مبلغ ورودی صفر است ');
          }

        }


       
     
      }


      if (c == 0) {

        //مضعت موقت
        if (this.calledsubmitvaziat.id == 2) {


          verror.push('هیچ ردیفی ثبت نشده است');


        }



      }






    }
   // verror.push('ارور');

    if (verror.length == 0)
      return true;

    



    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let ress = result;

      console.log('result:' + JSON.stringify( ress));
      console.log('result:' + ress.selectbutton);
      console.log('mmm:' + condition);
    
      if (ress.selectbutton == 'yaddasht' || ress.selectbutton == 'yaddashtAndNew') {

        console.log('ooo: ' + condition);
        if (condition == 'sanad' || condition == 'sanadAndNew') {
          this.yaddasht(condition);
        }


      }
 

    });



    console.log('endvalid');

    return false;;
  }


  selecttafg(hesabid: number): TafsiliForGroupRead {


 

    for (var _i = 0; _i < this.TafGListall.length; _i++) {

      if (this.TafGListall[_i].hesabId == hesabid) {
        return this.TafGListall[_i];
      }

    }


    return null;


  }



  onClickbarasi(vaziatnumber: number) {

   
    if (this.loaddata.showed)
      return;

    if (this.mymodle.id == -1)
      return;

    let allow = null;

    if (vaziatnumber == 3) {
      if (this.mymodle.vaziat != 2 ) {

        allow = 'سند باید در وضعیت موقت قرار داشته باشد';
      

      }

    }
   else   if (vaziatnumber == 2) {


      if (this.mymodle.vaziat !=3 ) {
        allow = 'سند باید در وضعیت بررسی شده قرار داشته باشد';
     //   this.showmessage('سند باید در وضعیت بررسی شده قرار داشته باشد');

      }

    }

    if (allow != null) {

      this.showmessage(allow);
      return;

    }

    var n = [];

    n.push(this.mymodle.id);

    let vaziat = this.SanadService.getVaziat(vaziatnumber);

    if (vaziat == null)
      return;

  
    this.loadServ.showloading(this.loaddata,10);
    this.SanadService.updateVaziat(n, vaziat.id, vaziat.name , false).subscribe((res: number[]) => {

      res;
      this.loadServ.hideloading(this.loaddata,10);
      
      this.alertify.success(' وضعیت سند به ' + vaziat.name + ' تغییر یافت ');
      this.mymodle.vaziat = vaziat.id;
      this.mymodle.vaziatN = vaziat.name;

      this.refreshVaziatColor(this.mymodle.vaziat);
      // this.loadSanad();

    }, error => {
        this.loadServ.hideloading(this.loaddata,10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
    //vaziat: IdName[] = [{ id: 1, name: 'یادداشت' },
  //{ id: 2, name: 'موقت' },
  //{ id: 3, name: 'بررسی شده' },
  //{ id: 4, name: 'قطعی شده' }
  //]

  onClickghati(vaziatnumber: number) {

 
    if (this.loaddata.showed)
      return;

    if (this.mymodle.id == -1)
      return;

    
    if (vaziatnumber == 4) {
      if (this.mymodle.vaziat != 3) {

        this.showmessage('سند باید در وضعیت بررسی شده قرار داشته باشد');
        return;
      } 

    }

    let mess = 'از قطعی شدن سند اطمینان دارید؟';

    this.valdata = { title: mess, rows: [' در صورت قطعی شدن امکان برگشت وجود ندارد'], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


   // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);

 
    dialogRef.afterClosed().subscribe(result => {
    
      console.log('m:The dialog was closed');
      let dialogdata = result;

      if (dialogdata != null) {


       // console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.dodghativaziat(4);

        }
      }
    });



    console.log('endvalid');
   // this.dodghativaziat(4);


  }

 
  dodghativaziat(v : number) {

    var n = [];
    n.push(this.mymodle.id);
    let vaziat = this.SanadService.getVaziat(v);

    if (vaziat == null)
      return;

    this.loadServ.showloading(this.loaddata,12);
    this.SanadService.updateVaziat(n, vaziat.id, vaziat.name, false).subscribe((res: number[]) => {

       res;
       this.loadServ.hideloading(this.loaddata,12);
      this.alertify.success(' وضعیت سند به ' + vaziat.name + ' تغییر یافت ');
      this.mymodle.vaziat = vaziat.id;
      this.mymodle.vaziatN = vaziat.name;
         //رنگ وضعیت
       this.refreshVaziatColor(this.mymodle.vaziat);
      // this.loadSanad();

     }, error => {
         this.loadServ.hideloading(this.loaddata,12);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  showmessage(message: string) {


    this.messagetemp = { title: 'خطا', rows: [], valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }
    this.messagetemp.rows = [message];
    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.messagetemp;
    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);
   
    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The message dialog was closed');
      return;
    });
    return;
 
  }

 
  onClickDeleterow() {
 
    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
   // console.log(JSON.stringify(rowkersselected));

    if (rowkersselected == null)
        rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);

    let afterindex = -1;
 

    if (rowkersselected != null)
    for (var i = 0; i < rowkersselected.length; i++) {

 
      for (var j = 0; j < this.mymodle.sabts.length; j++) {

        if (rowkersselected[i] == this.mymodle.sabts[j].id) {
          
          this.mymodle.sabts.splice(j, 1);
          afterindex = j - 1;
          break;
        }
      }


    }

    if (afterindex < this.mymodle.sabts.length && afterindex >= 0)
      this.focusedRowKey = this.mymodle.sabts[afterindex].id;
    else if (this.mymodle.sabts.length>0)
      this.focusedRowKey = this.mymodle.sabts[0].id;

    this.refreshradifnumber();
    this.pushnewrow(false);

  }
  onClickinsertrow() {

    let sabtempty = this.SabtService.getSabtEmpty();
    sabtempty.id = this.getnextrowid();
    
    for (var i = 0; i < this.mymodle.sabts.length; i++) {
      if (this.selectedrow.id == this.mymodle.sabts[i].id) {
       // console.log(JSON.stringify(sabtempty));
        this.mymodle.sabts.splice(i, 0, sabtempty);
        break;
      }

    }

    this.refreshradifnumber();


  }


  onClickcopyrow() {

    let sabt1temp = new sabtCopyTemp('1temp');

    sabt1temp.sabts = [];
    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.sabts.length; j++) {

          if (this.mymodle.sabts[j].id == rowkersselected[i]) {

            sabt1temp.sabts.push(this.mymodle.sabts[j]);
            break;
          }
        }
      }

    sabt1temp.sabts = sabt1temp.sabts.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.sabtcopytemplate_set(sabt1temp);
    this.alertify.warning('رونوشت صورت گرفت');
    this.dataGrid.instance.clearSelection();

  }
  onClickcutrow() {
    let sabt1temp = new sabtCopyTemp('1temp');

    sabt1temp.sabts = [];
    var rowkersselected = this.dataGrid.selectedRowKeys;
    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.sabts.length; j++) {

          if (this.mymodle.sabts[j].id == rowkersselected[i]) {

            sabt1temp.sabts.push(this.mymodle.sabts[j]);
            break;
          }
        }
      }
    sabt1temp.sabts = sabt1temp.sabts.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.sabtcopytemplate_set(sabt1temp);
    this.alertify.warning('برش صورت گرفت');
    this.onClickDeleterow();
    this.dataGrid.instance.clearSelection();

  }
  onClickpaserow() {
    var insertrow = this.localst.sabtcopytemplate_get('1temp');
    if (insertrow == null)
      return

    if (insertrow.sabts == null)
      return

    if (insertrow.sabts.length == 0)
      return

    let nextid = this.getnextrowid();

    for (var i = 0; i < this.mymodle.sabts.length; i++) {
      if (this.selectedrow.id == this.mymodle.sabts[i].id) {

        //ردیف مورد نظر را پیدا میکنیم

        //ردیف های کپی شده را جایگزاری میکنیم

        for (var k = 0; k < insertrow.sabts.length; k++) {
          nextid++;
          insertrow.sabts[k].id = nextid;
          this.mymodle.sabts.splice(i, 0, insertrow.sabts[k]);


         }
        this.alertify.warning('ردیف جایگذاری شد');
        break;
      }

    }

    this.refreshradifnumber();
   // console.log( JSON.stringify(this.localst.sabtcopytemplate_get('1temp') ));

  }
  onClickexchangRow() {

    var rowkersselected = this.dataGrid.selectedRowKeys;

    if (rowkersselected != null)
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.mymodle.sabts.length; j++) {

          if (this.mymodle.sabts[j].id == rowkersselected[i]) {

            var tm = this.mymodle.sabts[j].bed;
            this.mymodle.sabts[j].bed = this.mymodle.sabts[j].bes;
            this.mymodle.sabts[j].bes = tm;
            break;
          }
        }
      }


    if (rowkersselected == null || rowkersselected.length == 0) {
      var foc = this.dataGrid.focusedRowKey;

      for (var j = 0; j < this.mymodle.sabts.length; j++) {

        if (this.mymodle.sabts[j].id == foc) {

          let temp = this.mymodle.sabts[j].bed;
          this.mymodle.sabts[j].bed = this.mymodle.sabts[j].bes;
          this.mymodle.sabts[j].bes = temp;
          break;
        }
      }

    }
   


  }
  getnextrowid() : number {

    let nextid = -2000000011;
    for (var j = 0; j < this.mymodle.sabts.length; j++) {
      if (this.mymodle.sabts[j].id > nextid) {
        nextid = this.mymodle.sabts[j].id;
      }
    }
    nextid++;
    return nextid;
  }
  refreshradifnumber() {

   
    let c = 0;
    for (var k = 0; k < this.mymodle.sabts.length; k++) {
      c++;
      this.mymodle.sabts[k].radif = c;

    }

  }

  nextsanad() {
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.nextsanad();
      });
      return;
    }

    if (this.editId == -1) {

      this.alertify.warning('سندی بعد از این وجود ندارد');
      return;
    }

    this.vadidGo(true, 3, false);
    this.loadServ.showloading(this.loaddata,14);
    this.SanadService.getSanadshift(this.editId,this.yearnow,1 ).subscribe(
 
      (data: Sanad) => {
        this.loadServ.hideloading(this.loaddata,14);
        this.vadidGo(true, 3, true);

        if (data == null) {
          this.alertify.warning('سندی یافت نشد');
          return;
        }
        if (data.id != null) {

          this.editId = data.id;
          this.isnew = false;
        }
        //از نو بخونه بیخیال بار سرور
        this.myoninit();
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata,14);
        console.log(error);
        this.alertify.error(error);
      }


    );


  }




  backsanad() {


    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);
        this.backsanad();
      });
      return;
    }
    //if (this.editId == -1) {

    //  this.alertify.warning('سندی قبل از این وجود ندارد');
    //  return;
    //}

    console.log(this.editId, this.yearnow, -1);
    this.vadidGo(true, 4, false);
    this.loadServ.showloading(this.loaddata,15);
    this.SanadService.getSanadshift(this.editId, this.yearnow, -1).subscribe(

      (data: Sanad) => {
        this.vadidGo(true, 4, true);
        this.loadServ.hideloading(this.loaddata,15);
        if (data == null) {
          this.alertify.warning('سندی یافت نشد');
          return;
        }
        if (data != null) {

          console.log(data.id);
          this.editId = data.id;
          this.alertify.warning(this.editId.toString());
          this.isnew = false;
        }
        //از نو بخونه بیخیال بار سرور
        this.myoninit();
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata,15);
        console.log(error);
        this.alertify.error(error);
      }
    );



  }
  onClickdeletesanad() {

   // this.onfchanged.emit(null);
   // console.log('emit');


    if (this.loaddata.showed)
      return;

    if (this.editId == -1)
      return;


    if (this.mymodle.vaziat > 2)
      return;


    this.openDialog();

  


  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

    

      }

    }); 
  }

  dodelete() {


 
    this.vadidGo(true, 5, false);
    this.loadServ.showloading(this.loaddata,16);
    this.SanadService.deleteSanadAndChild(this.editId).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata,16);
      this.vadidGo(true, 5, true);


      if (res.errors.length > 0) {

        this.alertify.showListString(res.errors);

      }
      else {

        this.alertify.success(res.mes);

        this.OnMySaveEvent.emit({ refleshShomare: true, reloadlist: true, closepage: false });
        this.ctrNthis();
      }



    }, error => {
        this.loadServ.hideloading(this.loaddata,16);
            this.alertify.error(error);
            console.log("myerror:" + error)
          }); 

  }










//   this.bSaveCSS = 'btn btn-light UnOpSanad';
//this.bYaddashtCSS = 'btn btn-light UnOpSanad';
//this.bBarrasiCSS = 'btn btn-light UnOpSanad';
//this.bBarrasiCancelCSS = 'btn btn-light UnOpSanad';
//this.bGhatiCSS = 'btn btn-light UnOpSanad';


  refreshVaziatColor(vaziat: number) {

   // this.mainwarperCSS = 'wrapper UnfrzSanad';
  //  this.mainwarperCSS = this.mainwarperCSS.replace('disbal', '');
   // console.log('-----------11111111111111111111111111' + vaziat);
    //جدید
    if (vaziat == -1 ) {
     // console.log('-----------11111111111111111111111111');
      this.vaziatcolor = 'vcolornone';
      this.mainwarperCSS = 'UnfrzSanad';
      this.isreadonly = false;

      //وضعیت دکمه ها
      this.bSaveCSS = 'btn btn-light border UnOpSanad';
      this.bYaddashtCSS = 'btn btn-light border UnOpSanad';
      this.bBarrasiCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCancelCSS = 'btn btn-light border OpSanad';
      this.bGhatiCSS = 'btn btn-light border OpSanad';
      this.bDeleteCSS = 'btn btn-light border OpSanad';
    }

    //یادداشت
   else if (vaziat == 1) {

      this.vaziatcolor = 'vcoloryadasht';
    //  this.mainwarperCSS = 'UnfrzSanad';
      this.isreadonly = false;

      //وضعیت دکمه ها
      this.bSaveCSS = 'btn btn-light border UnOpSanad';
      this.bYaddashtCSS = 'btn btn-light border UnOpSanad';
      this.bBarrasiCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCancelCSS = 'btn btn-light border OpSanad';
      this.bGhatiCSS = 'btn btn-light border OpSanad';
      this.bDeleteCSS = 'btn btn-light border UnOpSanad';
    }
   
    //موقت
   else if (vaziat == 2) {
     this.vaziatcolor = 'vcolormovaghat';
     // this.mainwarperCSS = 'UnfrzSanad';
      this.isreadonly = false;

      //وضعیت دکمه ها
      this.bSaveCSS = 'btn btn-light border UnOpSanad';
      this.bYaddashtCSS = 'btn btn-light border UnOpSanad';
      this.bBarrasiCSS = 'btn btn-light border UnOpSanad';
      this.bBarrasiCancelCSS = 'btn btn-light border OpSanad';
      this.bGhatiCSS = 'btn btn-light border OpSanad';
      this.bDeleteCSS = 'btn btn-light border UnOpSanad';
    }

      //بررسی
    else if (vaziat == 3) {

      this.vaziatcolor = 'vcolorbarasi';
     // this.mainwarperCSS = 'frzSanad opSanad';
      this.isreadonly = true;


      //وضعیت دکمه ها
      this.bSaveCSS = 'btn btn-light border OpSanad';
      this.bYaddashtCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCancelCSS = 'btn btn-light border UnOpSanad';
      this.bGhatiCSS = 'btn btn-light border UnOpSanad';
      this.bDeleteCSS = 'btn btn-light border OpSanad';
    }

      //قطعی
     else if (vaziat == 4) {
      this.vaziatcolor = 'vcolorghati';
     // this.mainwarperCSS = 'frzSanad';
      this.isreadonly = true;

      //وضعیت دکمه ها
      this.bSaveCSS = 'btn btn-light border OpSanad';
      this.bYaddashtCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCSS = 'btn btn-light border OpSanad';
      this.bBarrasiCancelCSS = 'btn btn-light border OpSanad';
      this.bGhatiCSS = 'btn btn-light border OpSanad';
      this.bDeleteCSS = 'btn btn-light  border OpSanad';

    }


    if (this.viewfreze) {
      this.isreadonly = true;

    }

  }


  onValueChangedGSharh(e) {

  }

  onKeyDownDate(e) {


    if (e.event.key == 'Enter') {


      var cellolv = this.dataGrid.instance.cellValue(0, 1);


  
      // console.log('celkl value : ' +JSON.stringify(this.dataGrid.instance.cellValue(indexrow, indexcolum)));

      this.dataGrid.instance.cellValue(0, 1, cellolv);
      this.dataGrid.instance.editCell(0, 1);
      this.dataGrid.instance.focus(cellolv);

    }


  }
  onClickmoveRow(step: number) {



    ////پاک کردن ردیف بدون شماره معین

    //var sabt1temp = new sabtCopyTemp('none');
    //sabt1temp.sabts = [];
    //for (var q = 0; q < this.mymodle.sabts.length; q++) {

    //  if (this.mymodle.sabts[q].hesabId != null) {
    //    sabt1temp.sabts.push(this.mymodle.sabts[q]);
    //  }

    //}

    //this.mymodle.sabts = sabt1temp.sabts;


    //حرکت روبه بالا
    if (step > 0) {

      for (var i = 0; i < this.mymodle.sabts.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.sabts[i].id) {


          //اگر اولین سطر انتخاب شده بود هیچ کاری نمیکنیم
          if (i == 0)
            return


          var tempmove = this.mymodle.sabts[i-1];

          this.mymodle.sabts[i - 1] = this.mymodle.sabts[i];
          this.mymodle.sabts[i] = tempmove;

          break;
     
        }
      }


    }
     //حرکت روبه پایین
    else if (step < 0) {

      let stopdown = this.mymodle.sabts.length - 2;
      for (var i = 0; i < this.mymodle.sabts.length; i++) {

        //وقتی به ایندکس ذدیف مورد نظر رسیدیم
        if (this.selectedrow.id == this.mymodle.sabts[i].id) {


          //اگر ردیف اخر به بعد بود هیچ کاری نمیکنیم
          if (i > stopdown)
            return


          var tempmove = this.mymodle.sabts[i + 1];

          this.mymodle.sabts[i + 1] = this.mymodle.sabts[i];
          this.mymodle.sabts[i] = tempmove;

          break;
          
        }
      }

    }
    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.sabts.length; i++) {
      c++;

      this.mymodle.sabts[i].radif = c;

    }

  }

  onClicrepedrows() {


 

    var foc = this.dataGrid.focusedRowKey;


    if (foc == null)
      return;

 

    let maxid = 0;
    for (var i = 0; i < this.mymodle.sabts.length; i++) {



      if (this.mymodle.sabts[i].id > maxid) {
        maxid = this.mymodle.sabts[i].id;
      }


    }
    maxid++;

   

       var foradd = null;
  

        for (var j = 0; j < this.mymodle.sabts.length; j++) {

          if (this.mymodle.sabts[j].id == foc) {

          
           foradd = JSON.parse(JSON.stringify(this.mymodle.sabts[j]));
           foradd.id = maxid;
      
            break;
          }


        }
     


    if (foradd == null)
      return;

 


    if (this.mymodle.sabts == null)
      this.mymodle.sabts = [];



 




    let indexforinsert = 0;

    if (this.mymodle.sabts.length > 0)
      indexforinsert = this.mymodle.sabts.length - 1;


    this.mymodle.sabts.splice(indexforinsert, 0, foradd);



    let c = 0;
    //سورت  شماره ردیف
    for (var i = 0; i < this.mymodle.sabts.length; i++) {
      c++;

      this.mymodle.sabts[i].radif = c;

    }



  }
  onClickPrint() {

   
    if (this.loaddata.showed)
      return;


    this.OnMyPrintEvent.emit(this.mymodle.cShomare);
    //this.printoption;
    //this.printoption.year = this.mymodle.years;
    //this.printoption.cShE = this.mymodle.cShomare;
    //this.printoption.cShS = this.mymodle.cShomare;
    //this.printoption.levelDetail = this.mymodle.cShomare;


  }
  refreshbottum() {

  
    if (this.loaddata.showed)
      return;

    this.refleshhesabvatafsili();
  //  this.tafg_reflesh(this.selectedrow.hesabId);
   // this.selecttafg(this.selectedrow.hesabId);
  }


  repairShitRow() {

 
   // return;


    if (this.firstShitedRowid == null)
      return;

    if (this.pv == -1)
      return;



    console.log('<<<shit>>>' + this.firstShitedRowid);

    for (var r = 0; r < this.mymodle.sabts.length; r++) {
      
      if (this.mymodle.sabts[r].id == this.firstShitedRowid) {


       

        let hitem = null;
        for (var h = 0; h < this.hesablist.length; h++) {

          if (this.hesablist[h].id == this.pv) {

            hitem = this.hesablist[h];
          }

        }

        if (hitem != null && hitem.codeSum != this.selectedrowOld.hesabCode) {

          this.mymodle.sabts[r].hesabId = this.pv;
          this.mymodle.sabts[r].hesabCode = hitem.codeSum;
          this.mymodle.sabts[r].hesabName = hitem.name;


          this.mymodle.sabts[r].groupId = null;
          this.mymodle.sabts[r].groupCode = null;
          this.mymodle.sabts[r].groupName = null;

          this.mymodle.sabts[r].kolId = null;
          this.mymodle.sabts[r].kolCode = null;
          this.mymodle.sabts[r].kolName = null;


          this.mymodle.sabts[r].tafsili4Id = null;
          this.mymodle.sabts[r].tafsili4Code = null;
          this.mymodle.sabts[r].tafsili4Name = null;

          this.mymodle.sabts[r].tafsili5Id = null;
          this.mymodle.sabts[r].tafsili5Code = null;
          this.mymodle.sabts[r].tafsili5Name = null;

          this.mymodle.sabts[r].tafsili6Id = null;
          this.mymodle.sabts[r].tafsili6Code = null;
          this.mymodle.sabts[r].tafsili6Name = null;
          this.firstShitedRowid = null;
          this.pv = -1;
          break;
        }
        else {

          this.mymodle.sabts[r].hesabId = this.pv;
          this.mymodle.sabts[r].hesabCode = this.selectedrowOld.hesabCode;
          this.mymodle.sabts[r].hesabName = this.selectedrowOld.hesabName;


          this.mymodle.sabts[r].groupId = this.selectedrowOld.groupId;
          this.mymodle.sabts[r].groupCode = this.selectedrowOld.groupCode;
          this.mymodle.sabts[r].groupName = this.selectedrowOld.groupName;

          this.mymodle.sabts[r].kolId = this.selectedrowOld.kolId;
          this.mymodle.sabts[r].kolCode = this.selectedrowOld.kolCode;
          this.mymodle.sabts[r].kolName = this.selectedrowOld.kolName;


          this.mymodle.sabts[r].tafsili4Id = this.selectedrowOld.tafsili4Id;
          this.mymodle.sabts[r].tafsili4Code = this.selectedrowOld.tafsili4Code;
          this.mymodle.sabts[r].tafsili4Name = this.selectedrowOld.tafsili4Name;

          this.mymodle.sabts[r].tafsili5Id = this.selectedrowOld.tafsili5Id;
          this.mymodle.sabts[r].tafsili5Code = this.selectedrowOld.tafsili5Code;
          this.mymodle.sabts[r].tafsili5Name = this.selectedrowOld.tafsili5Name;

          this.mymodle.sabts[r].tafsili6Id = this.selectedrowOld.tafsili6Id;
          this.mymodle.sabts[r].tafsili6Code = this.selectedrowOld.tafsili6Code;
          this.mymodle.sabts[r].tafsili6Name = this.selectedrowOld.tafsili6Name;
          this.firstShitedRowid = null;
          this.pv = -1;
          break;


        }

       

         }

      }
   

  }


  repairlostedtaf() {
    


    for (var i = 0; i< this.mymodle.sabts.length; i++){

      //اگزتفصیلی خرابی داشت تعمیر میکنیم
      if (this.mymodle.sabts[i].tafsili4Code != null && this.mymodle.sabts[i].tafsili4Id==null) {

        loop2:
        for (var j = 0; j < this.tafjustdaste.length; j++) {
          loop3:
          for (var k = 0; k < this.tafjustdaste[j].rows.length; k++) {

            if (this.tafjustdaste[j].rows[k].code == this.mymodle.sabts[i].tafsili4Code) {

              this.mymodle.sabts[i].tafsili4Id = this.tafjustdaste[j].rows[k].id;
              this.mymodle.sabts[i].tafsili4Name = this.tafjustdaste[j].rows[k].name;
           
              break loop2;
            }
          }
        }

      }
      //اگزتفصیلی خرابی داشت تعمیر میکنیم
      if (this.mymodle.sabts[i].tafsili5Code != null && this.mymodle.sabts[i].tafsili5Id == null) {

        loop2:
        for (var j = 0; j < this.tafjustdaste.length; j++) {
          loop3:
          for (var k = 0; k < this.tafjustdaste[j].rows.length; k++) {

            if (this.tafjustdaste[j].rows[k].code == this.mymodle.sabts[i].tafsili5Code) {

              this.mymodle.sabts[i].tafsili5Id = this.tafjustdaste[j].rows[k].id;
              this.mymodle.sabts[i].tafsili5Name = this.tafjustdaste[j].rows[k].name;

              break loop2;
            }
          }
        }

      }

      //اگزتفصیلی خرابی داشت تعمیر میکنیم
      if (this.mymodle.sabts[i].tafsili6Code != null && this.mymodle.sabts[i].tafsili6Id == null) {

        loop2:
        for (var j = 0; j < this.tafjustdaste.length; j++) {
          loop3:
          for (var k = 0; k < this.tafjustdaste[j].rows.length; k++) {

            if (this.tafjustdaste[j].rows[k].code == this.mymodle.sabts[i].tafsili6Code) {

              this.mymodle.sabts[i].tafsili6Id = this.tafjustdaste[j].rows[k].id;
              this.mymodle.sabts[i].tafsili6Name = this.tafjustdaste[j].rows[k].name;

              break loop2;
            }
          }
        }

      }
    }


  }

  lastindex = -1;
  onClickTest() {

   
  //  //console.log('test click');
  //  //console.log('re');
  
  //  this.lastindex = this.dataGrid.instance.getVisibleRows().length - 1;
  // // this.dataGrid.focusedRowIndex = lastindex;

  //  console.log(this.lastindex);
  //  this.dataGrid.instance.editCell(this.lastindex, 1);
  //  this.dataGrid.focusedRowIndex = this.lastindex;
  // // this.dataGrid.instance.editCell(lastindex, 1);
  ////  this.dataGrid.instance.editCell(lastindex, 5);
  // // this.moientempedit.instance.open();
  }
 

  saveuioption() {
    //ghh5uytbn7777
   
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
     
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw1001 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw1001.length; i++) {
      this.localst.optioncli.gw1001[i] *= e;
    }
  }
  defwidth() {
    this.localst.optioncli.gw1001 = this.localst.optionClient_readDefault('gw1001');
  }








  isfchangcall() {
    if (!this.isfchanged) {
      this.isfchanged = true;
      this.onfchanged.emit(true);
    }
  }
  confirmfchang() {

  
    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        return false;
      });
    }
    else return true
  }


 
  exportexcel() {

    let excelmodle: sabtExel[] =[] ;
   
    for (var r = 0; r < this.mymodle.sabts.length; r++) {

      let greeter = new sabtExel(this.mymodle.sabts[r]);


      excelmodle.push(greeter);



    }


    this.excelService.exportAsExcelFile(excelmodle, 'اسناد');


  }
  sabttemp: Sabt;

  readExcel(event) {
    //گرفتن دیتاا
     this.excelService.importExcelFile(event).then(data => {
       //console.log(JSON.stringify(data));

     

       if (data.length < 2)
         return;

      // this.alertify.showListString(['gfh', 'dddfghy', 'fff', '444']);
       this.loadServ.showloading(this.loaddata, 11);
       this.SanadService.checkReadedExcelSanad(data).subscribe( (data:any) => {
         this.loadServ.hideloading(this.loaddata,11); 

         //console.log(data.vgf);
          
        // console.log(JSON.stringify(data));

         if (data.errors.length > 0) {
           this.alertify.showListString(data.errors);
          
         }

         else {
           this.mymodle.sabts = [];
           this.mymodle.sabts = data.value.sabts;
           this.pushnewrow(true);
          // this.dataGrid.instance.refresh();
           this.alertify.success('سند با موفقیت بارگذاری شد');
         }
          
         //this.alertify.showListString(['gfh' ,'dddfghy']);
         //ghghfdfddtr
         // this.gsharhlist = data;
         // this.listsharh.selectedItemKeys = [6];
         // fd
       }, error => {
         this.loadServ.hideloading(this.loaddata, 11);
         this.alertify.error(error);
        // console.log("myerror:" + error)
       });
       this.myInputexcel.nativeElement.value = "";
     
    });;
    
   

  }


}
