import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';

import { PhotoArch } from '../_models/photoArch';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};

@Injectable({
  providedIn: 'root'
})
export class ArchPhotoService {
  baseUrl = location.origin + '/api/';
 
  constructor(private http: HttpClient) {}


  getArchPhotos(): Observable<PhotoArch[]> {

    console.log(this.baseUrl + 'ArchPhoto/getArchPhotos');
    return this.http.get<PhotoArch[]>(this.baseUrl + 'ArchPhoto/getArchPhotos');

  }
  GetArchPhotosStream(): Observable<Blob> {

    console.log(this.baseUrl + 'ArchPhoto/GetArchPhotos');
    return this.http.get(this.baseUrl + 'ArchPhoto/GetArchPhotosStream', { responseType: "blob" });

  }
  GetArchPhotosStream2(): Observable<Blob> {

    console.log(this.baseUrl + 'ArchPhoto/GetArchPhotos');
    return this.http.get(this.baseUrl + 'ArchPhoto/GetArchPhotosStream', { responseType: "blob" });


  }
  GetArchPhotosStream3(): Observable<Blob> {

    console.log(this.baseUrl + 'ArchPhoto/GetArchPhotosStream?id=2');
    return this.http.get(this.baseUrl + 'ArchPhoto/GetArchPhotosStream?id=2', { responseType: "blob" });


  }

 
}
