import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Hesab } from '../../../_models/app1/paye/Hesab';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { of } from 'rxjs';
import { Localst } from '../../localst.service';
import { hesabsmall } from '../../../_models/app1/paye/hesabsmall';
import { hesabRep, hesabRepRow } from '../../../_models/app1/paye/hesabRep';
import { IdName } from '../../../_models/General/IdName';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyHesab: Hesab = {

  id: -1,
  treeId: null,
  treeHesdId: null,
  codeP:'',
  code: '',
  codeSum: '',
  name: '',
  kindId: null,
  kindN: '',
  mahiyatId: null,
  mahiyatN: '',
  mahiyatChekId: null,
  mahiyatChekN: '',
  mandehSefrId: null,
  mandehSefrN: '',
  arzId: null,
  arzN: '',
  dis: '',

  groupId: null,

  groupCode: null,


  groupName: null,

  kolId: null,

  kolCode: null,

  kolName: null,

  level4: false,
  level5: false,
  level6: false,
  treeNum: -1,
  taf4: null,
  taf5: null,
  taf6: null,
  taf4Daste_IdName: null,
  taf5Daste_IdName: null,
  taf6Daste_IdName: null,
  tadilHesabId:null,
  tadilHesabName: null,




  mysath: null,
  expanded: false,
  _sumName:null
};
let EmptyHesabsmall: hesabsmall = {

  id: -1,
name:null,
  codeSum: null,
  treeNum: null,


 
};

let EmptyhesabRep: hesabRep = {

  date: null,
  rows:[]
};
let EmptyhesabRepRow: hesabRepRow = {


  id: null,
  kolId: null,
  kolCode: null,
  kolName: null,
  hesabId: null,
  hesabCode: null,
  hesabName: null,
  kind: null,
  mahiyat: null,
  l4dastes: null,
  l5dastes: null,
  l6dastes: null,
  showkol: null,
  legpx: null,
  legpxkol: null,
  rowspam: 1,
  legmaxdaste: 0,
  nulkol: false
};




@Injectable({
  providedIn: 'root'
})
export class HesabService {
  baseUrl = environment.apiUrl;
  origin = location.origin;
  hesabkinds: IdName[] = [
    { id: 1, name: "دائمی" },
    { id: 2, name: "موقت" },
    { id: 3, name: "انتظامی" },
  ];

  constructor(private http: HttpClient , private localstService: Localst) { }
 
  getHesabs(): Observable<Hesab[]> {

    let apiadress = '/api/App1/Paye/Hesab/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Hesab[]>(this.origin + apiadress , { 'headers': headers });

  }
  getHesabsBySumName(): Observable<Hesab[]> {

    let apiadress = '/api/App1/Paye/Hesab/getallBySumName';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<Hesab[]>(this.origin + apiadress, { 'headers': headers });
  }
  gethesabsmall(level : number): Observable<hesabsmall[]> {

    let apiadress = '/api/App1/Paye/Hesab/getHesabsmall' + '?level=' + level;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<hesabsmall[]>(this.origin + apiadress, { 'headers': headers });

  }
  getHesabs_P(): Observable<Hesab[]> {

    let apiadress = '/api/App1/Paye/Hesab/getall';
    var headers = this.localstService.header_get(apiadress,null);

  
    return this.http.get<Hesab[]>(this.origin + apiadress , { 'headers': headers })
   
 
  }
  getHesabs_treenum(treenum : number): Observable<Hesab[]> {
    let apiadress = '/api/App1/Paye/Hesab/getallBytreenum/?treenum=' + treenum.toString();
    var headers = this.localstService.header_get(apiadress,null);

  
    return this.http.get<Hesab[]>(this.origin + apiadress, { 'headers': headers });

  }
  getHesabs_treenumAndKind(treenum: number , kind : number): Observable<Hesab[]> {
    let apiadress = '/api/App1/Paye/Hesab/GetBylevelAndKind?treenum=' + treenum.toString() + '&kind=' + kind.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Hesab[]>(this.origin + apiadress, { 'headers': headers });

  }

  getHesab(id): Observable<Hesab> {
    let apiadress = '/api/App1/Paye/Hesab/' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);


    if (id == -1) return of(this.getHesabEmpty());
    
    
   return this.http.get<Hesab>(this.origin + apiadress, { 'headers': headers }  ).pipe();
  
 
  
  }

 
  addHesab(item: Hesab) {
    let apiadress = '/api/App1/Paye/Hesab/addHesab';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item ,{ 'headers': headers } );
  }



  getHesabEmpty(): Hesab {
 
  
    return  JSON.parse(JSON.stringify(EmptyHesab));

  }


  getHesabsmallEmpty(): hesabsmall {
    return JSON.parse(JSON.stringify(EmptyHesabsmall));

  }
  getEmptyhesabRep(): hesabRep {
    return JSON.parse(JSON.stringify(EmptyhesabRep));
 
  }
  getEmptyhesabRepRow(): hesabRepRow {
    return JSON.parse(JSON.stringify(EmptyhesabRepRow));

  }
  updateHesab(item: Hesab) {
    let apiadress = '/api/App1/Paye/Hesab/updateHesab';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

  gethesabKinds(): IdName[] {

    return JSON.parse(JSON.stringify(this.hesabkinds));

  }



  deletebyid(HesabId: number): Observable<number> {
    let apiadress = '/api/App1/Paye/Hesab/deletebyid?id=' + HesabId.toString();
    var headers = this.localstService.header_get(apiadress,null);

    
    return this.http.delete<number>(this.origin + apiadress, { 'headers': headers } );

  }


  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Hesab/countHesabcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);
     
    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }

  HesabListCode(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Hesab/Hesabcodelist';
    var headers = this.localstService.header_get(apiadress,null);
 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }

  HesabListCodeSum(): Observable<string[]> {
    let apiadress = 'App1/Paye/Hesab/HesabcodelistSum';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });

  }


  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }

  countDoublecode(item: string): Observable<string> {

    let apiadress = '/api/App1/Paye/Hesab/countDoublecode?codesum=' + item;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<string>(this.origin + apiadress, { 'headers': headers });
  }


  allowdelete(id: number): Observable<boolean> {

    let apiadress = '/api/App1/Paye/Hesab/allowdelete?id=' + id;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<boolean>(this.origin + apiadress, { 'headers': headers });
  }

  getHesabRep(): Observable<hesabRep> {

    let apiadress = '/api/App1/Paye/Hesab/getHesabRep';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<hesabRep>(this.origin + apiadress, { 'headers': headers });
  }
}
