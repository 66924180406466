import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
 
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
import { Input } from '@angular/core';
import { pagep, printpage } from '../../../../_models/app1/rep/printpage';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { MorurOption } from '../../../../_models/app1/rep/Morur';
import { RizgardeshService } from '../../../../_services/app1/rep/Rizgardesh.service';
import { Localst } from '../../../../_services/localst.service';
import { BrowserModule } from '@angular/platform-browser'

 
 
@Component({
  selector: 'app-Rizgardesh-ht',
  templateUrl: './Rizgardesh-ht.component.html',
  styleUrls: ['./Rizgardesh-ht.component.css']
})
 

export class RizgardeshHtComponent implements OnInit {

 
  @Input() inputdata: any;

  orgheader: MorurOption; 
  orgrows: any[];

  nowdate: MyTimeClass;
  pVeiw: any = { atf: true ,vertic :false}

  printpage: printpage;
  constructor(private userService: UserService, private alertify: AlertifyService, private RizgardeshS: RizgardeshService,
    private route: ActivatedRoute,
    private router: Router,
    private localst: Localst,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {

 

   
  }

  ngOnInit() {

   // console.log(JSON.stringify(this.inputdata));




    this.orgheader = this.inputdata.orgheader;
    this.orgrows = this.inputdata.orgrows;

   

    this.printpage = {
      pages: [],
      HMahdude: null,
      HHesab: null,
      HDatepring: null,
      HSakhtar: null,
        HNoemandeh: null
    };

    if (this.inputdata == null)
      return;
    //گرفتن تاریخ از سرور
    this.myTimeService.GetPersianNow().subscribe(data => {

      this.nowdate = data;
      this.printpage.HDatepring = this.nowdate.dateString;
    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    this.createheader();

    let indexreaded= 0;


    let pageindex = 0;

    //برای صفحه بندی درست نیاز است جمع اخرین صفحه هم جزو ردیف ها بیاریم
    var rowpagesumall = { dis: 'جمع کل', bed: 0, bes: 0 , mandehBed: 0, mandehBes: 0 };
   
    this.orgrows.push(rowpagesumall);
 

    let datalegh = this.orgrows.length;
    let sumIndex = datalegh-1;
 

 

    if (datalegh == 0)
      return;


  

   
    while (indexreaded < datalegh) {
      //pindex: number;
      //ppage: number;
      //ppages: number;

      //pagesum: any;
      //pagesumall: any;
      //mandeall: any;
      //rowsp: any[];

      this.printpage.pages.push(new pagep(pageindex));

      //this.printpage.pages[pageindex] = new pagep(pageindex);
  

      let counterpage = 0;
      var sumpage = 0;
      var rowpagesum = { name:'جمع صفحه', bed: 0, bes:0, mandehBed: 0, mandehBes:0};

      while (indexreaded < datalegh) {


        ///چون جمع ها است دو ردیف اخر را نادیده میگیریم
        if (indexreaded < datalegh - 1) {
          //شماره رذدیف
          this.orgrows[indexreaded].radif = indexreaded + 1;
          //جمع صفحه
          rowpagesum.bed += this.orgrows[indexreaded].bed;
          rowpagesum.bes += this.orgrows[indexreaded].bes;
          rowpagesum.mandehBed += this.orgrows[indexreaded].mandehBed;
          rowpagesum.mandehBes += this.orgrows[indexreaded].mandehBes;
          //جمع کل

          this.orgrows[sumIndex].bed += this.orgrows[indexreaded].bed;
          this.orgrows[sumIndex].bes += this.orgrows[indexreaded].bes;
     


   


          
        }
  
        //درج ردیف در صفحه
        this.printpage.pages[pageindex].rowsp.push(this.orgrows[indexreaded]);


        let skiprow = Math.floor(this.orgrows[indexreaded].legpx / 464);
       // if (skiprow > 0) skiprow++;

      //  console.log(skiprow);


      ///










      ///




        counterpage = counterpage + skiprow ;
   

        indexreaded++;
        counterpage++;
        if (counterpage > 19)
          break;

      }
      this.printpage.pages[pageindex].pagesum = rowpagesum;



   

      pageindex++;



    }

    let leghpage =  this.printpage.pages.length;
    for (var i = 0; i < leghpage; i++) {

      this.printpage.pages[i].ppage = i + 1;
      this.printpage.pages[i].ppages = leghpage;
    }


   // this.printpage.pages[0].rowsp[0].bed =  this.printpage.pages[0].rowsp[0].bed.toString();


    //var table = document.getElementById('tbtb');
    //alert(table.offsetHeight);
  

  }
 
  ngAfterViewInit() {

  //  var ttt = document.getElementById("tbtb");
  //  console.log(ttt.offsetHeight);

  }
  resetFilters() {
   
 
  }
 

 

  onFocusedRowChanged(e) {


 

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }


  createheader() {

    this.printpage.HMahdude = ' از تاریخ ' + this.myTimeService.addslashtostring(this.orgheader.dateS) + ' الی ' + this.myTimeService.addslashtostring(this.orgheader.dateE)+ '  از شماره سند           ' + this.orgheader.cShomareS + ' الی ' + this.orgheader.cShomareE;


    let level = this.orgheader.nowlevel;
    if (level == 1)
      this.printpage.HHesab = ' (گروه) ';
    if (level == 2)
      this.printpage.HHesab = ' (کل) ';
    if (level == 3)
      this.printpage.HHesab = ' (معین) ';
    if (level == 4)
      this.printpage.HHesab = ' (سطح چهارم) ';
    if (level == 5)
      this.printpage.HHesab = ' (سطح پنجم) ';
    if (level == 6)
      this.printpage.HHesab = ' (سطح ششم) ';

  


    if (this.orgheader.dir != null) {
      let leg = this.orgheader.dir.length


      for (var i = 0; i < leg; i++) {

        this.printpage.HHesab += this.orgheader.dir[i].htidCode +'-'+ this.orgheader.dir[i].htidName;
        if (i < leg - 1)
          this.printpage.HHesab += ' | ';
      }
    }

   
  }



  onClickEdit() {

 

  }
  onClickNew() {

   


  }
  OnMyCancelEvent(e) {
    

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    
  }


  onDBClicklevel(level: number) {

  

     
  }

  onClickprint() {


    var d = document.getElementById("d");
 
    console.log(d.outerHTML);

    var ttt = document.getElementById("tbtb");
    console.log(ttt.offsetHeight);

    let printContents, popupWin;
    popupWin = window.open('', '_blank');
    popupWin.document.open();

    this.pVeiw.vertic = false;
 
      popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>

* {
  margin: 0;
  padding: 0;
}

body{
  font: 12pt "B Nazanin" !important;
  font-weight:400;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    color: black;
  
 
    background-color: white;
}


 

.page {
  font: 12pt "B Nazanin" ;
  font-weight:400;
  width: 297mm;
  height: 210mm;
  padding: 0mm;
  margin: 0mm;
 
  background: white;
 
}

     .t-table {
          width: 277mm;
          margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
            font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
           font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
  font: 12pt "B Nazanin" ;
  font-weight:400;
          width: 277mm;
         margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
          font-family: 'B Nazanin';
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }


@page {
   size: A4 landscape;
   margin: 0; 
 
}
@media print {
 html, body {
    width: 292mm;
    height: 205mm;

  }
    div.centerOnPrintedPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }
  /* ... the rest of the rules ... */

body {
  width: 100%;
  height: 100%;
  padding: 0mm;
  margin: 0mm auto;

  font: 12pt "B Nazanin";
}


 .page {

display:block; page-break-before:always;
  height: 205mm;
  width: 297mm;
  padding: 0mm;
  margin: 0mm;
  margin-top:-3px;;

  border-radius: 0px;


}




        .t-table {
          width: 277mm;
           margin: 1mm 7mm 0mm 10mm;

          border: 1px solid black;
          border-collapse: collapse;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          direction: rtl;

          text-align: right;
        }

          .t-table td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 1px solid black;
            padding: 0px 5px 0px 5px;
            height: 18px;
          }

          .t-table th {
            font: 12pt "B Nazanin" ;
  font-weight:400;
            border: 2px solid black;
            text-align: center;
            height: 20px;
            background-color: lightgrey;
          }
        /*   .t-table td:empty {
            border-left: 0;
            border-right: 0;
          }*/

        .t-header {
          width: 277mm;
        margin-right: 7mm;
          margin-top: 5mm;
          direction: rtl;
        font: 12pt "B Nazanin" ;
  font-weight:400;
          text-align: right;
          height: 90px;
        }

          .t-header td {
  font: 12pt "B Nazanin" ;
  font-weight:400;
            padding: 5px 5px 5px 5px;
            height: 18px;
          }


        .grayrow {
        }
 }
          </style>
        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
      );


  

    popupWin.document.close();



  }

}


//popupWin.document.write(`
//      <html>
//        <head>
//          <title>Print tab</title>
//          <style>
//          //........Customized style.......

// body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//  background-color: #FAFAFA;
//  font: 12pt "Tahoma";
//}

//* {
//  box-sizing: border-box;
//  -moz-box-sizing: border-box;
//}

//.page {
    
//  width: 297mm;
//  min-height: 210mm;
//  padding: 5mm;
//  margin: 10mm auto;
//  border: 1px #D3D3D3 solid;
//  border-radius: 5px;
//  background: white;
//  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

 
 


// -webkit-transform: rotate(-90deg) ; 
//     -moz-transform:rotate(-90deg) ;

//     filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

//}

//.subpage {
//  padding: 5mm;
//  border: 5px rgb(128, 128, 128) solid;
//  height: 200mm;
//  outline: 5mm #D3D3D3 solid;
//}



 
 


//@media print {

//   @page {size: A4  ;

// margin: -10;


//}
 
//  html, body {
 
    
//  }

//  .page {
//    margin: 0;
    
//    border: initial;
//    border-radius: initial;
//    width: initial;
//    min-height: initial;
//    box-shadow: initial;
//    background: initial;
//    page-break-after: always;
//  }
//  #section-to-print, #section-to-print * {
//    visibility: visible;
//  }

//  #section-to-print {
//    position: absolute;
//    left: 0;
//    top: 0;
//  }

//}



//          </style>
//        </head>
//    <body onload="window.print();window.close()">${d.innerHTML}</body>
//      </html>`
//);
