import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { RankingOp, RankingRow, Rankingtree, RankingtreeBox } from '../../../_models/app1/rep/RankingRep';

import { ApiRet } from '../../../_models/General/ApiRet';
 
let EmptyRankingOp: RankingOp = {
  id: null,
  name: null,
  code: null,
  tafLevel: null,
  dateS: 0,
  dateS_S: null,
  dateE: 0,
  dateE_S: null,
  years: 0,
  hesabId: null,
  hesabC: null,
  hesabN: null,
  taf4Id: null,
  taf4C: null,
  taf4N: null,
  taf5Id: null,
  taf5C: null,
  taf5N: null,
  taf6Id: null,
  taf6C: null,
  taf6N: null,
  xpUnit: 0,
  isBefor:false,
  maxPrc: 0,
  dateV: 0,
  dateV_S:null,
   
};

let EmptyRankingRow: RankingRow = {
  id: null,
  rankingOpId: null,
  rankingOpC: null,
  tafId: null,
  cShomare: null,
  cAtf: null,
  date: null,
  prc: null,
  dis: null,
  xp: null,
  ruzMul: 0,
  rank: 0
};

let EmptyRankingtree: Rankingtree ={
  radif: null,
  id: null,
  rankingOpId: null,
  tafId: null,
  text: null,
  code:null,
  cShomare: 0,
  cAtf: 0,
  date: 0,
  date_S: null,
  prc: null,
  prcMan: null,
  dis: null,
  xp: 0,
  ruzMul: 0,
  rank: 0,
  sub: []
};


 
let EmptyRankingtreeBox: RankingtreeBox = {
  rows: [],
  dateA_S: null,
  dateA: 0,
  tafId: -1,
  tafC: null,
  tafN: null,
  cMelli: null,
  xp: 0,
  rank: 0
}



const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class RankingRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall_Op(): Observable<ApiRet>  {
    let apiadress = '/api/App1/Rep/RankingRep/getall_Op';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

 


  getRankingOpEmpty(): RankingOp {

 
    return JSON.parse(JSON.stringify(EmptyRankingOp));

  }
  getRankingRowEmpty(): RankingRow {


    return JSON.parse(JSON.stringify(EmptyRankingRow));

  }
  getRankingtreeBoxEmpty(): RankingtreeBox {


    return JSON.parse(JSON.stringify(EmptyRankingtreeBox));

  }

 

  CreateAndSave(item: RankingOp[]) :Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/RankingRep/CreateAndSave';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
  getReport(): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/RankingRep/getReport';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getRiz(key:any): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/RankingRep/getRiz';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, key ,{ 'headers': headers });
  }

  AnRankingAsExcel(): Observable<Blob> {

    let apiadress = '/api/App1/Rep/RankingRep/AsExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<Blob>(this.origin + apiadress, { 'headers': headers, responseType: 'blob' as 'json' });

  }

  exportToClient(): Observable<ApiRet> {
    let apiadress = '/api/App1/Rep/RankingRep/exportToClient';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
}
