import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, HostListener, ElementRef } from '@angular/core';

import { AlertifyService } from '../../../../_services/alertify.service';
 
import {  DirSell, DirSellRow } from '../../../../_models/app3/opr/DirSell';

  import * as $ from 'jquery';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
 
 
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { LoadingService } from '../../../../_services/loading.service';
import { ExcelService } from '../../../../_services/excel.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DirSellService } from '../../../../_services/app3/opr/DirSell.service';
 
 
@Component({
  selector: 'app3-DirSell-edit',
  templateUrl: './DirSell-edit.component.html',
  styleUrls: ['./DirSell-edit.component.css']

})
export class DirSellEditComponent implements OnInit {
 
 
  @Input() editId: number;
  @Input() isnew: boolean;
 
  @Output() OnMyCancelEvent = new EventEmitter<any>();
 

  @Input() viewfreze: boolean;
  //
 // printoption: DirSellRepOption;

  @Output() OnMyPrintEvent = new EventEmitter<any>();
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();


  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  mymodle: DirSell;
 
 
  valdata: DialogData;
 
  messagetemp: DialogData;
 
  vazcolor: string;
 
  loaddata: loadingCl;
 
 
  constructor(
    private alertify: AlertifyService,

    private dirSellService: DirSellService,
    private hesabService: HesabService,
    private loadServ: LoadingService,
    private localst: Localst,
    public dialog: MatDialog,
    private excelService: ExcelService,

  ) {
   
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata ,25);
 
    this.mymodle = this.dirSellService.getDirSellEmpty();
    this.vazcolor = 'vaz-none';
  }
 
  

 
  ngOnInit() {


    if (this.editId == -1) {
     

    }
    else {

      this.isnew = false;
      this.loadServ.showloading(this.loaddata, 3);
      this.dirSellService.getbyid(this.editId).subscribe(

        (data: DirSell) => {
          this.loadServ.hideloading(this.loaddata, 3);
         

          this.mymodle = data;
 
      
          //رنگ وضعیت
          this.refreshvazColor(this.mymodle.vaz);

    
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }


  }

  refreshvazColor(vaz: number) {


    //ثبت نشده
    if (vaz == -1) {

      this.vazcolor = 'vaz-none';
      //  this.mainwarperCSS = 'UnfrzAnSell';
    }

    //موقت
    else if (vaz == 200) {
      this.vazcolor = 'vaz-yellow';
      // this.mainwarperCSS = 'UnfrzAnSell';
    }
    //قیمت گذاری شده
    else if (vaz == 300) {
      this.vazcolor = 'vaz-orange';
    }
    //تایید
    else if (vaz == 400) {
      this.vazcolor = 'vaz-green';
    }
    //سند حسابداری
    else if (vaz == 500) {
      this.vazcolor = 'vaz-blue';
    }

  }

  cancelthis(e) {

 
    this.OnMyCancelEvent.emit(true);
  

  }

}
