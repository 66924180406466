import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { AnFormTafDaste, AnFormTafDasteGroup } from '../../../_models/app2/paye/AnFormTafDaste';
 
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
let EmptyAnFormTafDaste: AnFormTafDaste = {

  id: 0,
  formCode: null,
  formName: null,
  tafDasteId: null,
  tafDaste: null,

};
//let EmptyAnFormTafDasteGroup: AnFormTafDasteGroup = {

//   tafDasteNameS: null,
//  formCode: null,
//  formName: null,
//  tafDastes: []
//};

 
@Injectable({
  providedIn: 'root'
})
export class AnFormTafDasteService {
/*  baseUrl = environment.apiUrl;*/
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<AnFormTafDaste[]> {
    let apiadress = '/api/App2/Paye/AnFormTafDaste/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<AnFormTafDaste[]>(this.origin + apiadress, { 'headers': headers });
  }
  getAnFormTafDasteEmpty(): AnFormTafDaste {
    return JSON.parse(JSON.stringify(EmptyAnFormTafDaste));
  }
 

  getAllAnFormTafDasteGroup(): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/AnFormTafDaste/getAllAnFormTafDasteGroup';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  updateAllAnFormTafDasteGroup(item): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/AnFormTafDaste/updateAllAnFormTafDasteGroup';
  var headers = this.localstService.header_get(apiadress, null);


  return this.http.post<ApiRet>(this.origin + apiadress,item, { 'headers': headers });
}


}
