import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Shakhz } from '../../../../_models/app1/paye/Shakhz';
import { ShakhzService } from '../../../../_services/app1/paye/Shakhz.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';

import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxRadioGroupComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { Localst } from '../../../../_services/localst.service';
import { TaxGovService } from '../../../../_services/General/tax/taxGov.service';
import { TaxpayerInfo } from '../../../../_models/General/tax/TaxpayerInfo';
 

@Component({
  selector: 'app-Shakhz-edit',
  templateUrl: './Shakhz-edit.component.html',
  styleUrls: ['./Shakhz-edit.component.css']
})
export class ShakhzEditComponent implements OnInit {

 
  nameMostar: string = 'نام مستعار';
  mahalSodur: string = 'محل صدور';
  shEghtesadi: string = 'شماره اقتصادی،کد شعبه';
 

  maskRules = {
    // a single character
    S: '$',

    // a regular expression
    H: /[^A-Za-z0-9+#\.-]/,
    U:  / [02 - 5] /,
    T: /[0-9A-F]/,
    // an array of characters
    N: ['$', '%', '&', '@'],
    Q: ['0', '1', '2', '3',],
    M: ['0', '1'],
    O: ['0', '1', '2', '3'],
    // a function
    F: function (char) {
      if (char == '1') return '1';
      if (char == '2') return '2';
      else return null;
      return char == char.toUpperCase();
    }
  };
  oninputs(e) {

   // var keyCode = e.event;


   //console.log(keyCode);


  //  console.log(e);
    // Event handling commands go here
  }
  onKeyDown(e) {
    let keyCode = e.event;

   //// if (keyCode=='s')
   // e.preventDefault()
   // console.log(keyCode);
    // Event handling commands go here
  }
  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
 myShakhz: Shakhz;
  myShakhz_Codemelliold: string;

  valdata: DialogData;
  readOnlyCode: boolean = true;
  mellilist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  onvandata: IdName[] = [
    { id: 1, name: "خانم" },
    { id: 2, name: "آقا" },
  ];
  listdin: IdName[] = [
    { id: 1, name: "اسلام" },
    { id: 2, name: "مسیحیت" },
    { id: 3, name: "زرتشتی" },
    { id: 100, name: "سایر" },
  ];

  mazhab: IdName[] = [
    { id: 1, name: "شیعه" },
    { id: 2, name: "سنی" },
  ];

  listtahsilat: IdName[] = [
    { id: 1, name: "زیر دیپلم" },
    { id: 2, name: "دیپلم" },
    { id: 3, name: "کاردانی" },
    { id: 4, name: "کارشناسی" },
    { id: 5, name: "کارشناسی ارشد" },
    { id: 6, name: "دکتری" },
  ];

  marrylist: IdName[] = [
    { id: 0, name: "مجرد" },
    { id: 1, name: "متاهل" },
   
  ];
  radionmarry: string[];
  radiosex: string[];
  
  toblist: IdName[];
  private sub: any;
  test: string;
    n: number;


  OstanKollist: OstanKol[];
  Ostanlist: Ostan[];


  loadingNum: number;
  loadingBools: boolean[];
  loadingShow: boolean;
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private shakhzService: ShakhzService,
    private tafsiliService: TafsiliService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private localst: Localst,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private taxGovService: TaxGovService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,6);
    this.validationCallback = this.validationCallback.bind(this);  
    this.myShakhz = this.shakhzService.getShakhzEmpty();
    this.toblist = this.shakhzService.gettoblist();

    this.rules = { "X": /[02-9]/ };
    this.myShakhz_Codemelliold = null;
    this.mellilist = [];
   
  }
  handleValueChange(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
 
  }

 
  ngOnInit() {
 


     
    this.loadServ.showloading(this.loaddata,0);
    this.ostanService.getOstanKols().subscribe(data => {

      this.loadServ.hideloading(this.loaddata,0);
      this.OstanKollist = data;
  
    }, error => {
        this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

 

    this.loadServ.showloading(this.loaddata, 5);
    this.shakhzService.codeMellilist().subscribe(data => {
      // console.log('list is  ' + data.toString());
      this.loadServ.hideloading(this.loaddata, 5);
      console.log(JSON.stringify(data));
      this.mellilist = data;

     
    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




    this.isnew = true;
    //this.myShakhz.code ='123';
    if (this.editId == -1) {
      console.log('in new mode');
      this.myShakhz = JSON.parse(JSON.stringify(this.shakhzService.getShakhzEmpty()));
      this.myShakhz.id = this.editId;
      //console.log("mymodel:" + JSON.stringify(this.myShakhz));
      this.myShakhz_Codemelliold = this.myShakhz.codeMelli;
      this.isnew = true;

      this.loadServ.showloading(this.loaddata,2);
      this.tafsiliService.getNextCode(-1001).subscribe(data => {
      //  console.log('list is  ' + data.toString());
        this.loadServ.hideloading(this.loaddata,2);
        this.myShakhz.code = data.toString();
        //tarazravannew فقط برای سلیمانی
        if (this.localst.get_dbName() == 'tarazravannew') {
        //  this.myShakhz.code = '';
          this.readOnlyCode = false;
        }

      }, error => {
          this.loadServ.hideloading(this.loaddata,2);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
      
    }
    else {
      console.log('in edit mode');
      this.isnew = false;

      

      this.loadServ.showloading(this.loaddata,3);
      this.shakhzService.getShakhz(this.editId).subscribe(
        (data : Shakhz) => {

 
          this.loadServ.hideloading(this.loaddata,3);
          this.myShakhz.code ="00000j"
          this.myShakhz = data;
          this.myShakhz_Codemelliold = this.myShakhz.codeMelli;
         // console.log(JSON.stringify(this.myShakhz));
          //tarazravannew فقط برای سلیمانی
          if (this.localst.get_dbName() == 'tarazravannew') {
            this.readOnlyCode = false;
          }

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log(error);
          this.alertify.error(error);
        }


      );
    }
    this.myShakhz.sexId = 0;
   // this.myShakhz.Code = "0001";
  // this.isnew = true;
   // if (this.isnew)
  
    console.log(this.editId.toString());

 
    this.test = "j";
    this.rules = { "X": /[02-9]/ };



    let cus = this.localst.sherkatInfo_get().customer;

    if (cus == 'dadghostari') {
      this.nameMostar = 'معرف';
      this.mahalSodur = 'کد پرسنلی';
      this.shEghtesadi = 'شماره پرونده';

    }
  }



  addthis() {

    
    if (this.loaddata.showed)
      return;
    // const buttonText = e.component.option("text");
    //notify("The button save was clicked");
    console.log("The button save was clicked");
    //let result = e.validationGroup.validate();
    //console.log(result.isValid);
    //if (result.isValid) {
    //  console.log("Thedf"); 
    //}
   

  }
  cancelthis() {
    console.log('cancel');
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    //notify({
    //  message: "You have submitted the form",
    //  position: {
    //    my: "center top",
    //    at: "center top"
    //  }
    //}, "success", 3000);



    //this.myShakhz.marry = this.radionmarry[this.myShakhz.marryId];
    //this.myShakhz.sex = this.onvandata[this.radiosex.sexId];
    if (this.loaddata.showed)
      return;

 

    if (this.myShakhz.ostanCode != null) {
      for (var val of this.OstanKollist) {
        //  console.log(this.myShakhz.ostanCode);
        if (val.codeKol == this.myShakhz.ostanCode) {
          // console.log('jjjjj    '+val.nameOstan);
          this.myShakhz.ostanName = val.nameOstan;
        }


      }
    }
    if (this.myShakhz.shahrCode != null) {
      for (var val2 of this.Ostanlist) {
        //  console.log(this.myShakhz.shahrCode);
        if (val2.codeShahr == this.myShakhz.shahrCode) {
          // console.log('jjjjj    ' + val.nameOstan);
          this.myShakhz.shahrName = val2.nameShahr;
        }

      }
    }


    if (this.myShakhz.dinId != null) {
      for (var val3 of this.listdin) {
     
        if (val3.id == this.myShakhz.dinId) {
 
          this.myShakhz.din = val3.name;
        }

      }
    }

    if (this.myShakhz.marryId != null) {
      for (var val4 of this.marrylist) {

        if (val4.id == this.myShakhz.marryId) {

          this.myShakhz.marry = val4.name;
        }

      }
    }


    if (this.myShakhz.tahsilatId != null) {
      for (var val5 of this.listtahsilat) {

        if (val.id == this.myShakhz.tahsilatId) {

          this.myShakhz.tahsilat = val5.name;
        }

      }
    }

    if (this.myShakhz.adress != null) {
      let al = this.myShakhz.adress.length;
      if (al != 0 && al < 10) {
        this.alertify.warning('آدرس باید بین 10 تا 80 کاراکتر باشد');
        return;
      }
    }

    if (this.myShakhz.codeMelli != null) {
      let m = this.myShakhz.codeMelli.length;
      if (m > 0 && m != 10) {

        this.alertify.warning('کد ملی باید 10 رقم باشد');
        return;
      }

    }
    if (this.myShakhz.codePost != null) {
      let m = this.myShakhz.codePost.length;
      if (m > 0 && m != 10) {

        this.alertify.warning('کد پستی باید 10 رقم باشد');
        return;
      }

    }
  //  console.log(val); // prints values: 10, 20, 30, 40
    if (this.myShakhz.id == -1) {
      this.myShakhz.tafsilisId = null;
      console.log(JSON.stringify(this.myShakhz));
      this.loadServ.showloading(this.loaddata,4);
      this.shakhzService.addshakhz(this.myShakhz).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);

        if (res.value == 'shs') {
          this.es_conform(res);
        }
        else if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }



      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log( error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.shakhzService.updateshakhz(this.myShakhz).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);


        if (res.value == 'shs') {
          this.es_conform(res);
        }
        else if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

      


    
      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 
  es_conform(res :ApiRet) {
    //if (this.myShakhz._ignore_sh == true)
    //  return;
  
    let mess = 'هشدار !';

    this.valdata = { title: mess, rows: res.errors, valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: false, datastring: null ,styleMode:0}


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {

      
      let dialogdata = result;

      if (dialogdata != null) {


        // console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.myShakhz._ignore_sh = true;
          this.onFormSubmit(null);
         // this.dodghativaziat(4);

        }
      }
    });




  }

  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }
  marrytankolValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    if (newValue)

    this.values = newValue;
 
  }

  onostankolValueChanged(e) {
    if (e.value == null) return;
    var ostankolcode = e.value;
    this.ostanService.getOstans(ostankolcode).subscribe(data => {
      this.Ostanlist = data;
    });
  }


  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
    //this.dffd = e;
    //let st = e.value;
    //let ccode = 0;
    //console.log("m:code vale = " + st);

 
    //  this.shakhzService.countCode(st).subscribe(data => {
    //    console.log('fist ' + data.toString());
    //    ccode = parseInt(data.toString());
    //    console.log(ccode.toString() + '-----');
    //    if (ccode == 0) {
    //      this.isvalisss = true;
    //      console.log("true");

    //      //let element = document.getElementById("myTextbox");
    //      //let instance = DevExpress.ui.dxTextBox.getInstance(element);

    //     // this.fdgdf.validate();
    //    }
    //    else {
    //      this.isvalisss = false;
    //      console.log("false");
       
    //    };



    //  }, error => {
    //    this.alertify.error(error);
    //    console.log("myerror:" + error);
    //  });
  
    //console.log('sec ' + ccode);


  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    
    let v = e.value;

    console.log('vaaa');

    if (this.myShakhz_Codemelliold != null && this.editId != -1)
      if (this.myShakhz_Codemelliold == v)
        return true;

    for (var val of this.mellilist) {
      if (v == val) return false;
    }
    return true



}


  validateNumber(e) {

    

  }

 
  onTobChanged(e) {

    this.myShakhz.tob = e.value
  }

  showPayer: boolean = false;
  paymodel: TaxpayerInfo = null;


  onGetTaxpayer() {



    this.paymodel = new TaxpayerInfo();

    if (this.localst.sherkatInfo_get().taxVer != 2) {
      this.alertify.warning("استعلام تنها از طریق سرویس دو سامانه مودیان امکان پذیر است");
      return;
    }


    this.loadServ.showloading(this.loaddata, 2);
    this.taxGovService.GetTaxpayer(this.myShakhz.shEghtesadi).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 2);

     
      //e674578568
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.showPayer = true;
        this.paymodel = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  onGoLink() {

        window.open("https://tax.gov.ir/action/do/InquiryNationalID", "_blank");


  }
}
