import { Component, OnInit } from '@angular/core';
import { AuthService } from './_services/auth.service';

import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
//import { ConnectionService } from './_services/General/Connection.service';
import { LogcliService } from './_services/Logcli.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as localization from 'devextreme/localization';
(localization as any).disableIntl();

import config from "devextreme/core/config";
 
//import fa from 'devextreme/localization/messages/fa.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    jwtHelper = new JwtHelperService();
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  shownoNetwork: boolean;

  constructor(private authService: AuthService, private router: Router, private logcliServic: LogcliService ) {
    this.shownoNetwork = false;
    localization.loadMessages(this.fgfg);
    localization.locale("fa-IR");
     
    
    config({
      thousandsSeparator: ",",
    /*  decimalSeparator:"/"*/
    });


  }

  ngOnInit() {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log('Online...');
      this.shownoNetwork = false;
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      console.log('Offline...');
      this.shownoNetwork = true;
    }));



    console.log('AppComponent ngOnInit');
      const token = localStorage.getItem('token');
      const User = JSON.parse( localStorage.getItem('user'));
        if (token) {
            this.authService.decodedToken = this.jwtHelper.decodeToken(token);
        }
    if (User) {
      this.authService.currentUser = User;
      this.authService.changeMemberPhoto(User.photoUrl);
    }
    else {

      this.router.navigate(['/home'] );

     

    }

    //////////////////////

  //  window.location.reload();
    
    //setInterval(() => {
    //  this.connectionServic.TestGeneralConnection().subscribe(data => {

    //    console.log('connection test Ok!');

    //  }, error => {
    //      console.log('error');
    //  });
    //    // console.log('ooo');
    //}, 60000);
 
 
  }
  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  OnSaleChang(e) {

    console.log('salechang to: ' + e);
     
   // window.location.reload();

  }

  fgfg :any = {
  "fa": {
    "Yes": "بلی",
    "No": "خیر",
    "Cancel": "لغو",
    "Close": "Close",
    "Clear": "حذف",
    "Done": "تایید",
    "Loading": "در حال بارگزاری...",
    "Select": "انتخاب...",
    "Search": "جستجو",
    "Back": "بازگشت",
    "OK": "تایید",

    "dxCollectionWidget-noDataText": "(داده ای برای نمایش موجود نمی باشد)",

    "dxDropDownEditor-selectLabel": "انتخاب",

    "validation-required": "اجباری",
    "validation-required-formatted": "{0} اجباری می باشد",
    "validation-numeric": "مقدار می بایست عدد باشد",
    "validation-numeric-formatted": "{0} می بایست عددی باشد",
    "validation-range": "مقدار خارج از محدوده مجاز می باشد",
    "validation-range-formatted": "{0} خارج از محدوده مجاز می باشد",
    "validation-stringLength": "طول عبارت صحیح نمی باشد",
    "validation-stringLength-formatted": "طول {0} صحیح نمی باشد",
    "validation-custom": "مقدار وارد شده معتبر نمی باشد",
    "validation-custom-formatted": "{0} نامعتبر است",
    "validation-async": "مقدار نامعتبر می باشد",
    "validation-async-formatted": "{0} نامعتبر می باشد",
    "validation-compare": "مقادیر همخوانی ندارد",
    "validation-compare-formatted": "{0} همخوانی ندارد",
    "validation-pattern": "مقدار مطابق الگو نمی باشد",
    "validation-pattern-formatted": "{0} مطابق الگو نمی باشد",
    "validation-email": "ایمیل صحیح نمی باشد",
    "validation-email-formatted": "{0} صحیح نمی باشد",
    "validation-mask": "مقدار نامعتبر است",

    "dxLookup-searchPlaceholder": "حداقل تعداد کاراکتر: {0}",

    "dxList-pullingDownText": "برای تازه کردن به پایین بکشید ...",
    "dxList-pulledDownText": "انتشار برای تازه کردن ...",
    "dxList-refreshingText": "تازه کردن ...",
    "dxList-pageLoadingText": "بارگذاری...",
    "dxList-nextButtonText": "بیشتر",
    "dxList-selectAll": "انتخاب همه",
    "dxListEditDecorator-delete": "حذف",
    "dxListEditDecorator-more": "بیشتر",

    "dxScrollView-pullingDownText": "برای تازه کردن به پایین بکشید ...",
    "dxScrollView-pulledDownText": "انتشار برای تازه کردن ...",
    "dxScrollView-refreshingText": "تازه کردن ...",
    "dxScrollView-reachBottomText": "بارگذاری...",

    "dxDateBox-simulatedDataPickerTitleTime": "زمان را انتخاب نمایید",
    "dxDateBox-simulatedDataPickerTitleDate": "تاریخ را انتخاب نمایید",
    "dxDateBox-simulatedDataPickerTitleDateTime": "تاریخ و زمان را انتخاب نمایید",
    "dxDateBox-validation-datetime": "مقدار می بایست تاریخ و زمان باشد",

    "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
    "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
    "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
    "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
    "dxDateRangeBox-startDateLabel": "Start Date",
    "dxDateRangeBox-endDateLabel": "End Date",

    "dxFileUploader-selectFile": "انتخاب فایل",
    "dxFileUploader-dropFile": "اینجا درگ نمایید",
    "dxFileUploader-bytes": "bytes",
    "dxFileUploader-kb": "KB",
    "dxFileUploader-Mb": "MB",
    "dxFileUploader-Gb": "GB",
    "dxFileUploader-upload": "بارگزاری",
    "dxFileUploader-uploaded": "بارگزاری شد",
    "dxFileUploader-readyToUpload": "آماده بارگزاری",
    "dxFileUploader-uploadAbortedMessage": "بارگزاری لغو شد",
    "dxFileUploader-uploadFailedMessage": "خطا در بارگزاری",
    "dxFileUploader-invalidFileExtension": "فایل انتخابی مجاز نمی باشد",
    "dxFileUploader-invalidMaxFileSize": "فایل حجم بیشتری از مقدار مجاز دارد",
    "dxFileUploader-invalidMinFileSize": "فایل حجم کمتری از مقدار مجاز دارد",

    "dxRangeSlider-ariaFrom": "از",
    "dxRangeSlider-ariaTill": "تا",
    "dxSwitch-switchedOnText": "فعال",
    "dxSwitch-switchedOffText": "غیرفعال",

    "dxForm-optionalMark": "optional",
    "dxForm-requiredMessage": "{0} اجباری می باشد",

    "dxNumberBox-invalidValueMessage": "مقدار وارد شده می بایست عدد باشد",
    "dxNumberBox-noDataText": "(بدون داده)",

    "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
    "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
    "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
    "dxDataGrid-emptyHeaderColumnChooserText": "column chooser",
    "dxDataGrid-columnChooserTitle": "انتخب ستون",
    "dxDataGrid-columnChooserEmptyText": "برای عدم نمایش ستون، ستون را به این قسمت بکشید",
    "dxDataGrid-groupContinuesMessage": "ادامه در صفحه بعد",
    "dxDataGrid-groupContinuedMessage": "ادامه از صفحه قبل",
    "dxDataGrid-groupHeaderText": "گروه بندی با ستون جاری",
    "dxDataGrid-ungroupHeaderText": "حذف گروه",
    "dxDataGrid-ungroupAllText": "حذف کلیه گروه ها",
    "dxDataGrid-editingEditRow": "ویرایش",
    "dxDataGrid-editingSaveRowChanges": "ثبت",
    "dxDataGrid-editingCancelRowChanges": "لغو",
    "dxDataGrid-editingDeleteRow": "حذف",
    "dxDataGrid-editingUndeleteRow": "بازگشت حذف",
    "dxDataGrid-editingConfirmDeleteMessage": "آیا مایل به حذف آیتم انتخابی می باشید?",
    "dxDataGrid-validationCancelChanges": "لغو تغییرات",
    "dxDataGrid-groupPanelEmptyText": "جهت گروه بندی ستون مورد نظر را با ماوس به این قسمت بکشید",
    "dxDataGrid-noDataText": "اطلاعاتی یافت نشد",
    "dxDataGrid-searchPanelPlaceholder": "جستجو...",
    "dxDataGrid-filterRowShowAllText": "(همه)",
    "dxDataGrid-filterRowResetOperationText": "شروع مجدد",
    "dxDataGrid-filterRowOperationEquals": "برابر",
    "dxDataGrid-filterRowOperationNotEquals": "نابرابر",
    "dxDataGrid-filterRowOperationLess": "کوچکتر از",
    "dxDataGrid-filterRowOperationLessOrEquals": "کوچکتر یا مساوی با",
    "dxDataGrid-filterRowOperationGreater": "بزرگتر از",
    "dxDataGrid-filterRowOperationGreaterOrEquals": "بزرگتر یا مساوی با",
    "dxDataGrid-filterRowOperationStartsWith": "شروع با",
    "dxDataGrid-filterRowOperationContains": "شامل",
    "dxDataGrid-filterRowOperationNotContains": "شامل نبودن",
    "dxDataGrid-filterRowOperationEndsWith": "پایان با",
    "dxDataGrid-filterRowOperationBetween": "بین",
    "dxDataGrid-filterRowOperationBetweenStartText": "شروع",
    "dxDataGrid-filterRowOperationBetweenEndText": "پایان",
    "dxDataGrid-ariaSearchBox": "Search box",
    "dxDataGrid-applyFilterText": "اعمال فیلتر",
    "dxDataGrid-trueText": "بلی",
    "dxDataGrid-falseText": "خیر",
    "dxDataGrid-sortingAscendingText": "مرتب سازی",
    "dxDataGrid-sortingDescendingText": "مرتب سازی معکوس",
    "dxDataGrid-sortingClearText": "مرتب سازی پیشفرض",
    "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
    "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
    "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
    "dxDataGrid-ariaSortIndex": "Sort index {0}",
    "dxDataGrid-editingSaveAllChanges": "ثبت تغییرات",
    "dxDataGrid-editingCancelAllChanges": "لغو تغییرات",
    "dxDataGrid-editingAddRow": "افزودن سطر",
    "dxDataGrid-summaryMin": "حداقل: {0}",
    "dxDataGrid-summaryMinOtherColumn": "حداقل مقادیر {1} برابر با {0}",
    "dxDataGrid-summaryMax": "حداکثر: {0}",
    "dxDataGrid-summaryMaxOtherColumn": "حداکثر مقادیر {1} برابر با {0}",
    "dxDataGrid-summaryAvg": "میانگین: {0}",
    "dxDataGrid-summaryAvgOtherColumn": "میانگین مقادیر {1} برابر با {0}",
    "dxDataGrid-summarySum": "جمع: {0}",
    "dxDataGrid-summarySumOtherColumn": "جمع مقادیر {1} برابر با {0}",
    "dxDataGrid-summaryCount": "تعداد: {0}",
    "dxDataGrid-columnFixingFix": "سایز ثابت",
    "dxDataGrid-columnFixingUnfix": "سایز شناور",
    "dxDataGrid-columnFixingLeftPosition": "نمایش از چپ",
    "dxDataGrid-columnFixingRightPosition": "نمایش از راست",
    "dxDataGrid-exportTo": "ایجاد فایل",
    "dxDataGrid-exportToExcel": "ایجاد فایل اکسل",
    "dxDataGrid-exporting": "در حال ایجاد فایل...",
    "dxDataGrid-excelFormat": "فایل اکسل",
    "dxDataGrid-selectedRows": "سطرهای انتخابی",
    "dxDataGrid-exportSelectedRows": "سطرهای انتخابی",
    "dxDataGrid-exportAll": "کلیه داده ها",
    "dxDataGrid-headerFilterLabel": "Filter options",
    "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
    "dxDataGrid-headerFilterEmptyValue": "(خالی)",
    "dxDataGrid-headerFilterOK": "تایید",
    "dxDataGrid-headerFilterCancel": "لغو",
    "dxDataGrid-ariaAdaptiveCollapse": "عدم نمایش اطلاعات تکمیلی",
    "dxDataGrid-ariaAdaptiveExpand": "نمایش اطلاعات تکمیلی",
    "dxDataGrid-ariaColumn": "ستون",
    "dxDataGrid-ariaColumnHeader": "Column header",
    "dxDataGrid-ariaValue": "مقدار",
    "dxDataGrid-ariaError": "Error",
    "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
    "dxDataGrid-ariaFilterCell": "فیلتر سلول",
    "dxDataGrid-ariaCollapse": "عدم نمایش",
    "dxDataGrid-ariaModifiedCell": "Modified",
    "dxDataGrid-ariaDeletedCell": "Deleted",
    "dxDataGrid-ariaEditableCell": "Editable",
    "dxDataGrid-ariaExpand": "نمایش",
    "dxDataGrid-ariaCollapsedRow": "Collapsed row",
    "dxDataGrid-ariaExpandedRow": "Expanded row",
    "dxDataGrid-ariaDataGrid": "جدول",
    "dxDataGrid-ariaSearchInGrid": "جستجو",
    "dxDataGrid-ariaSelectAll": "انتخاب همه",
    "dxDataGrid-ariaSelectRow": "انتخب سطر",
    "dxDataGrid-ariaToolbar": "نوار ابزار",
    "dxDataGrid-ariaEditForm": "Edit form",
    "dxDataGrid-filterBuilderPopupTitle": "فیلتر",
    "dxDataGrid-filterPanelCreateFilter": "ایجاد فیلتر",
    "dxDataGrid-filterPanelClearFilter": "حذف فیلتر",
    "dxDataGrid-filterPanelFilterEnabledHint": "فعال نمودن فیلتر",

    "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
    "dxTreeList-ariaSearchInGrid": "جستجو در لیست درختی",
    "dxTreeList-ariaToolbar": "Tree list toolbar",
    "dxTreeList-editingAddRowToNode": "افزودن",

    "dxPager-infoText": "صفحه {0} از {1} ({2} آیتم)",
    "dxPager-pagesCountText": "از",
    "dxPager-pageSize": "Items per page: {0}",
    "dxPager-pageSizesAllText": "همه",
    "dxPager-page": "Page {0}",
    "dxPager-prevPage": "Previous Page",
    "dxPager-nextPage": "Next Page",
    "dxPager-ariaLabel": "Page Navigation",
    "dxPager-ariaPageSize": "Page size",
    "dxPager-ariaPageNumber": "Page number",

    "dxPivotGrid-grandTotal": "جمع کل",
    "dxPivotGrid-total": "جمع {0}",
    "dxPivotGrid-fieldChooserTitle": "انتخاب فیلد",
    "dxPivotGrid-showFieldChooser": "انتخاب فیلد",
    "dxPivotGrid-expandAll": "نمایش کلیه زیرمجموعه ها",
    "dxPivotGrid-collapseAll": "عدم نمایش زیرمجموعه ها",
    "dxPivotGrid-sortColumnBySummary": "مرتب سازی \"{0}\" مطابق با این ستون",
    "dxPivotGrid-sortRowBySummary": "مرتب سازی \"{0}\" مطابق با این سطر",
    "dxPivotGrid-removeAllSorting": "حذف مرتب سازی اطلاعات",
    "dxPivotGrid-dataNotAvailable": "(بدون داده)",
    "dxPivotGrid-rowFields": "سطرها",
    "dxPivotGrid-columnFields": "ستون ها",
    "dxPivotGrid-dataFields": "داده ها",
    "dxPivotGrid-filterFields": "فیلترها",
    "dxPivotGrid-allFields": "کلیه اطلاعات",
    "dxPivotGrid-columnFieldArea": "فیلدهای مربوط به ستون را به اینجا انتقال دهید (درگ نمایید)",
    "dxPivotGrid-dataFieldArea": "فیلدهای داده ای را به اینجا انتقال دهید (درگ نمایید)",
    "dxPivotGrid-rowFieldArea": "فیلدهای مربوط به سطر را به اینجا انتقال دهید (درگ نمایید)",
    "dxPivotGrid-filterFieldArea": "فیلدهای مربوط به فیلتر را به اینجا انتقال دهید (درگ نمایید)",

    "dxScheduler-editorLabelTitle": "موضوع",
    "dxScheduler-editorLabelStartDate": "تاریخ شروع",
    "dxScheduler-editorLabelEndDate": "تاریخ پایان",
    "dxScheduler-editorLabelDescription": "شرح",
    "dxScheduler-editorLabelRecurrence": "تکرار",

    "dxScheduler-openAppointment": "قرار ملاقات آزاد",

    "dxScheduler-recurrenceNever": "هرگز",
    "dxScheduler-recurrenceMinutely": "هر دقیقه",
    "dxScheduler-recurrenceHourly": "ساعتی",
    "dxScheduler-recurrenceDaily": "روزانه",
    "dxScheduler-recurrenceWeekly": "هفتگی",
    "dxScheduler-recurrenceMonthly": "ماهانه",
    "dxScheduler-recurrenceYearly": "سالانه",

    "dxScheduler-recurrenceRepeatEvery": "تکرار همه",
    "dxScheduler-recurrenceRepeatOn": "تکرار",
    "dxScheduler-recurrenceEnd": "پایان تکرار",
    "dxScheduler-recurrenceAfter": "بعد از",
    "dxScheduler-recurrenceOn": "بر",

    "dxScheduler-recurrenceRepeatMinutely": "دقیقه",
    "dxScheduler-recurrenceRepeatHourly": "ساعت",
    "dxScheduler-recurrenceRepeatDaily": "روز",
    "dxScheduler-recurrenceRepeatWeekly": "هفته",
    "dxScheduler-recurrenceRepeatMonthly": "ماه(ماهها)",
    "dxScheduler-recurrenceRepeatYearly": "سال(ها)",

    "dxScheduler-switcherDay": "روز",
    "dxScheduler-switcherWeek": "هفته",
    "dxScheduler-switcherWorkWeek": "هفته کاری",
    "dxScheduler-switcherMonth": "ماه",

    "dxScheduler-switcherAgenda": "دستور جلسه",

    "dxScheduler-switcherTimelineDay": "روزشمار",
    "dxScheduler-switcherTimelineWeek": "هفته جدول زمانی",
    "dxScheduler-switcherTimelineWorkWeek": "هفته کار جدول زمانی",
    "dxScheduler-switcherTimelineMonth": "ماه جدول زمانی",

    "dxScheduler-recurrenceRepeatOnDate": "در تاریخ",
    "dxScheduler-recurrenceRepeatCount": "رخداد",
    "dxScheduler-allDay": "تمام روز",

    "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
    "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

    "dxScheduler-confirmRecurrenceEditMessage": "آیا می خواهید فقط این قرار ملاقات یا کل مجموعه را ویرایش کنید؟",
    "dxScheduler-confirmRecurrenceDeleteMessage": "آیا می خواهید فقط این قرار ملاقات یا کل مجموعه را حذف کنید؟",

    "dxScheduler-confirmRecurrenceEditSeries": "ویرایش سریال",
    "dxScheduler-confirmRecurrenceDeleteSeries": "حذف مجموعه ها",
    "dxScheduler-confirmRecurrenceEditOccurrence": "ویرایش قرار",
    "dxScheduler-confirmRecurrenceDeleteOccurrence": "حذف قرار",

    "dxScheduler-noTimezoneTitle": "بدون منطقه زمانی",
    "dxScheduler-moreAppointments": "{0} بیشتر",

    "dxCalendar-todayButtonText": "امروز",
    "dxCalendar-ariaWidgetName": "تقویم",
    "dxCalendar-previousMonthButtonLabel": "Previous month",
    "dxCalendar-previousYearButtonLabel": "Previous year",
    "dxCalendar-previousDecadeButtonLabel": "Previous decade",
    "dxCalendar-previousCenturyButtonLabel": "Previous century",
    "dxCalendar-nextMonthButtonLabel": "Next month",
    "dxCalendar-nextYearButtonLabel": "Next year",
    "dxCalendar-nextDecadeButtonLabel": "Next decade",
    "dxCalendar-nextCenturyButtonLabel": "Next century",
    "dxCalendar-captionMonthLabel": "Month selection",
    "dxCalendar-captionYearLabel": "Year selection",
    "dxCalendar-captionDecadeLabel": "Decade selection",
    "dxCalendar-captionCenturyLabel": "Century selection",

    "dxColorView-ariaRed": "قرمز",
    "dxColorView-ariaGreen": "سبز",
    "dxColorView-ariaBlue": "آبی",
    "dxColorView-ariaAlpha": "شفافیت",
    "dxColorView-ariaHex": "کد رنگ",

    "dxTagBox-selected": "{0} انتخاب شده",
    "dxTagBox-allSelected": "همه انتخاب شده اند ({0})",
    "dxTagBox-moreSelected": "{0} سایر",

    "vizExport-printingButtonText": "چاپ",
    "vizExport-titleMenuText": "صادرات / چاپ",
    "vizExport-exportButtonText": "{0} پرونده",

    "dxFilterBuilder-and": "و",
    "dxFilterBuilder-or": "یا",
    "dxFilterBuilder-notAnd": "و بجز",
    "dxFilterBuilder-notOr": "یا بجز",
    "dxFilterBuilder-addCondition": "افزودن شرط",
    "dxFilterBuilder-addGroup": "افزودن گروه",
    "dxFilterBuilder-enterValueText": "<مقدار مورد نظر را وارد نمایید>",
    "dxFilterBuilder-filterOperationEquals": "برابر با",
    "dxFilterBuilder-filterOperationNotEquals": "عدم برابری با",
    "dxFilterBuilder-filterOperationLess": "کوچکتر از",
    "dxFilterBuilder-filterOperationLessOrEquals": "کوچکتر یا مساوی با",
    "dxFilterBuilder-filterOperationGreater": "بزرگتر از",
    "dxFilterBuilder-filterOperationGreaterOrEquals": "بزرگتر یا مساوی با",
    "dxFilterBuilder-filterOperationStartsWith": "شروع با",
    "dxFilterBuilder-filterOperationContains": "شامل",
    "dxFilterBuilder-filterOperationNotContains": "شامل نبودن",
    "dxFilterBuilder-filterOperationEndsWith": "پایان با",
    "dxFilterBuilder-filterOperationIsBlank": "بدون مقدار",
    "dxFilterBuilder-filterOperationIsNotBlank": "دارای مقدار",
    "dxFilterBuilder-filterOperationBetween": "بین",
    "dxFilterBuilder-filterOperationAnyOf": "شامل یکی از",
    "dxFilterBuilder-filterOperationNoneOf": "شامل هیچکدام از",

    "dxHtmlEditor-dialogColorCaption": "تغییر رنگ قلم",
    "dxHtmlEditor-dialogBackgroundCaption": "تغییر رنگ پس زمینه",
    "dxHtmlEditor-dialogLinkCaption": "افزودن پیوند",
    "dxHtmlEditor-dialogLinkUrlField": "URL",
    "dxHtmlEditor-dialogLinkTextField": "متن",
    "dxHtmlEditor-dialogLinkTargetField": "باز کردن پیوند در پنجره جدید",
    "dxHtmlEditor-dialogImageCaption": "تصویر اضافه کن",
    "dxHtmlEditor-dialogImageUrlField": "URL",
    "dxHtmlEditor-dialogImageAltField": "متن جایگزین",
    "dxHtmlEditor-dialogImageWidthField": "عرض (پیکسل)",
    "dxHtmlEditor-dialogImageHeightField": "قد (px)",
    "dxHtmlEditor-dialogInsertTableRowsField": "ردیف",
    "dxHtmlEditor-dialogInsertTableColumnsField": "ستون ها",
    "dxHtmlEditor-dialogInsertTableCaption": "درج جدول",
    "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
    "dxHtmlEditor-dialogImageUpdateButton": "Update",
    "dxHtmlEditor-dialogImageAddButton": "Add",
    "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
    "dxHtmlEditor-dialogImageSelectFile": "From This Device",
    "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
    "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
    "dxHtmlEditor-heading": "عنوان",
    "dxHtmlEditor-normalText": "متن عادی",
    "dxHtmlEditor-background": "Background",
    "dxHtmlEditor-bold": "Bold",
    "dxHtmlEditor-color": "Color",
    "dxHtmlEditor-font": "Font",
    "dxHtmlEditor-italic": "Italic",
    "dxHtmlEditor-link": "Link",
    "dxHtmlEditor-image": "Image",
    "dxHtmlEditor-size": "Size",
    "dxHtmlEditor-strike": "Strike",
    "dxHtmlEditor-subscript": "Subscript",
    "dxHtmlEditor-superscript": "Superscript",
    "dxHtmlEditor-underline": "Underline",
    "dxHtmlEditor-blockquote": "Blockquote",
    "dxHtmlEditor-header": "Header",
    "dxHtmlEditor-increaseIndent": "Increase Indent",
    "dxHtmlEditor-decreaseIndent": "Decrease Indent",
    "dxHtmlEditor-orderedList": "Ordered List",
    "dxHtmlEditor-bulletList": "Bullet List",
    "dxHtmlEditor-alignLeft": "Align Left",
    "dxHtmlEditor-alignCenter": "Align Center",
    "dxHtmlEditor-alignRight": "Align Right",
    "dxHtmlEditor-alignJustify": "Align Justify",
    "dxHtmlEditor-codeBlock": "Code Block",
    "dxHtmlEditor-variable": "Variable",
    "dxHtmlEditor-undo": "Undo",
    "dxHtmlEditor-redo": "Redo",
    "dxHtmlEditor-clear": "Clear",
    "dxHtmlEditor-insertTable": "Insert Table",
    "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
    "dxHtmlEditor-insertRowAbove": "Insert Row Above",
    "dxHtmlEditor-insertRowBelow": "Insert Row Below",
    "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
    "dxHtmlEditor-insertColumnRight": "Insert Column Right",
    "dxHtmlEditor-deleteColumn": "Delete Column",
    "dxHtmlEditor-deleteRow": "Delete Row",
    "dxHtmlEditor-deleteTable": "Delete Table",
    "dxHtmlEditor-cellProperties": "Cell Properties",
    "dxHtmlEditor-tableProperties": "Table Properties",
    "dxHtmlEditor-insert": "Insert",
    "dxHtmlEditor-delete": "Delete",
    "dxHtmlEditor-border": "Border",
    "dxHtmlEditor-style": "Style",
    "dxHtmlEditor-width": "Width",
    "dxHtmlEditor-height": "Height",
    "dxHtmlEditor-borderColor": "Color",
    "dxHtmlEditor-tableBackground": "Background",
    "dxHtmlEditor-dimensions": "Dimensions",
    "dxHtmlEditor-alignment": "Alignment",
    "dxHtmlEditor-horizontal": "Horizontal",
    "dxHtmlEditor-vertical": "Vertical",
    "dxHtmlEditor-paddingVertical": "Vertical Padding",
    "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
    "dxHtmlEditor-pixels": "Pixels",
    "dxHtmlEditor-list": "List",
    "dxHtmlEditor-ordered": "Ordered",
    "dxHtmlEditor-bullet": "Bullet",
    "dxHtmlEditor-align": "Align",
    "dxHtmlEditor-center": "Center",
    "dxHtmlEditor-left": "Left",
    "dxHtmlEditor-right": "Right",
    "dxHtmlEditor-indent": "Indent",
    "dxHtmlEditor-justify": "Justify",
    "dxHtmlEditor-borderStyleNone": "none",
    "dxHtmlEditor-borderStyleHidden": "hidden",
    "dxHtmlEditor-borderStyleDotted": "dotted",
    "dxHtmlEditor-borderStyleDashed": "dashed",
    "dxHtmlEditor-borderStyleSolid": "solid",
    "dxHtmlEditor-borderStyleDouble": "double",
    "dxHtmlEditor-borderStyleGroove": "groove",
    "dxHtmlEditor-borderStyleRidge": "ridge",
    "dxHtmlEditor-borderStyleInset": "inset",
    "dxHtmlEditor-borderStyleOutset": "outset",

    "dxFileManager-newDirectoryName": "فهرست بدون عنوان",
    "dxFileManager-rootDirectoryName": "فایل ها",
    "dxFileManager-errorNoAccess": "دسترسی ممنوع است. عملیات انجام نشد.",
    "dxFileManager-errorDirectoryExistsFormat": "فهرست {0} از قبل وجود دارد.",
    "dxFileManager-errorFileExistsFormat": "پرونده {0} از قبل وجود دارد.",
    "dxFileManager-errorFileNotFoundFormat": "پرونده {0} پیدا نشد.",
    "dxFileManager-errorDirectoryNotFoundFormat": "فهرست {0} پیدا نشد.",
    "dxFileManager-errorWrongFileExtension": "پسوند پرونده مجاز نیست.",
    "dxFileManager-errorMaxFileSizeExceeded": "اندازه پرونده از حداکثر اندازه مجاز بیشتر است.",
    "dxFileManager-errorInvalidSymbols": "این نام حاوی نویسه های نامعتبر است.",
    "dxFileManager-errorDefault": "خطای نامشخص.",
    "dxFileManager-errorDirectoryOpenFailed": "فهرست نمی تواند باز شود",

    "dxFileManager-commandCreate": "دایرکتوری جدید",
    "dxFileManager-commandRename": "تغییر نام",
    "dxFileManager-commandMove": "انتقال به",
    "dxFileManager-commandCopy": "کپی در",
    "dxFileManager-commandDelete": "حذف",
    "dxFileManager-commandDownload": "دانلود",
    "dxFileManager-commandUpload": "بارگذاری پرونده ها",
    "dxFileManager-commandRefresh": "تازه کردن",
    "dxFileManager-commandThumbnails": "نمایش تصاویر کوچک",
    "dxFileManager-commandDetails": "نمایش جزئیات",
    "dxFileManager-commandClearSelection": "پاک کردن انتخاب",
    "dxFileManager-commandShowNavPane": "تغییر پانل ناوبری",

    "dxFileManager-dialogDirectoryChooserMoveTitle": "انتقال به",
    "dxFileManager-dialogDirectoryChooserMoveButtonText": "حرکت",
    "dxFileManager-dialogDirectoryChooserCopyTitle": "کپی در",
    "dxFileManager-dialogDirectoryChooserCopyButtonText": "کپی",
    "dxFileManager-dialogRenameItemTitle": "تغییر نام",
    "dxFileManager-dialogRenameItemButtonText": "صرفه جویی",
    "dxFileManager-dialogCreateDirectoryTitle": "دایرکتوری جدید",
    "dxFileManager-dialogCreateDirectoryButtonText": "ایجاد کردن",
    "dxFileManager-dialogDeleteItemTitle": "حذف",
    "dxFileManager-dialogDeleteItemButtonText": "حذف",
    "dxFileManager-dialogDeleteItemSingleItemConfirmation": "آیا مطمئن هستید که می خواهید {0} را حذف کنید؟",
    "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "آیا مطمئن هستید که می خواهید {0} مورد را حذف کنید؟",
    "dxFileManager-dialogButtonCancel": "لغو",

    "dxFileManager-editingCreateSingleItemProcessingMessage": "ایجاد یک فهرست در داخل {0}",
    "dxFileManager-editingCreateSingleItemSuccessMessage": "یک فهرست درون {0} ایجاد کرد",
    "dxFileManager-editingCreateSingleItemErrorMessage": "فهرست ایجاد نشد",
    "dxFileManager-editingCreateCommonErrorMessage": "فهرست ایجاد نشد",

    "dxFileManager-editingRenameSingleItemProcessingMessage": "تغییر نام یک مورد در داخل {0}",
    "dxFileManager-editingRenameSingleItemSuccessMessage": "تغییر نام یک مورد درون {0}",
    "dxFileManager-editingRenameSingleItemErrorMessage": "نام مورد تغییر نکرد",
    "dxFileManager-editingRenameCommonErrorMessage": "نام مورد تغییر نکرد",

    "dxFileManager-editingDeleteSingleItemProcessingMessage": "حذف مورد از {0}",
    "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "حذف {0} مورد از {1}",
    "dxFileManager-editingDeleteSingleItemSuccessMessage": "موردی از {0} حذف شد",
    "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "{0} مورد از {1} حذف شد",
    "dxFileManager-editingDeleteSingleItemErrorMessage": "مورد حذف نشد",
    "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} مورد حذف نشد",
    "dxFileManager-editingDeleteCommonErrorMessage": "برخی از موارد حذف نشده اند",

    "dxFileManager-editingMoveSingleItemProcessingMessage": "در حال انتقال مورد به {0}",
    "dxFileManager-editingMoveMultipleItemsProcessingMessage": "انتقال {0} مورد به {1}",
    "dxFileManager-editingMoveSingleItemSuccessMessage": "موردی را به {0} منتقل کرد",
    "dxFileManager-editingMoveMultipleItemsSuccessMessage": "{0} مورد به {1} منتقل شد",
    "dxFileManager-editingMoveSingleItemErrorMessage": "مورد منتقل نشد",
    "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} مورد منتقل نشد",
    "dxFileManager-editingMoveCommonErrorMessage": "برخی از موارد منتقل نشدند",

    "dxFileManager-editingCopySingleItemProcessingMessage": "کپی کردن مورد در {0}",
    "dxFileManager-editingCopyMultipleItemsProcessingMessage": "کپی کردن {0} مورد در {1}",
    "dxFileManager-editingCopySingleItemSuccessMessage": "موردی را در {0} کپی کرد",
    "dxFileManager-editingCopyMultipleItemsSuccessMessage": "{0} مورد در {1} کپی شد",
    "dxFileManager-editingCopySingleItemErrorMessage": "مورد کپی نشد",
    "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} مورد کپی نشد",
    "dxFileManager-editingCopyCommonErrorMessage": "برخی از موارد کپی نشدند",

    "dxFileManager-editingUploadSingleItemProcessingMessage": "بارگذاری یک مورد در {0}",
    "dxFileManager-editingUploadMultipleItemsProcessingMessage": "بارگذاری {0} مورد در {1}",
    "dxFileManager-editingUploadSingleItemSuccessMessage": "موردی را در {0} بارگذاری کرد",
    "dxFileManager-editingUploadMultipleItemsSuccessMessage": "{0} مورد را در {1} بارگذاری کرد",
    "dxFileManager-editingUploadSingleItemErrorMessage": "مورد بارگذاری نشد",
    "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} مورد بارگذاری نشد",
    "dxFileManager-editingUploadCanceledMessage": "لغو شد",

    "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
    "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

    "dxFileManager-listDetailsColumnCaptionName": "نام",
    "dxFileManager-listDetailsColumnCaptionDateModified": "تاریخ اصلاح شده",
    "dxFileManager-listDetailsColumnCaptionFileSize": "حجم فایل",

    "dxFileManager-listThumbnailsTooltipTextSize": "اندازه",
    "dxFileManager-listThumbnailsTooltipTextDateModified": "تاریخ اصلاح شده",

    "dxFileManager-notificationProgressPanelTitle": "پیش رفتن",
    "dxFileManager-notificationProgressPanelEmptyListText": "بدون عملیات",
    "dxFileManager-notificationProgressPanelOperationCanceled": "لغو شد",

    "dxDiagram-categoryGeneral": "عمومی",
    "dxDiagram-categoryFlowchart": "نمودار نمودار",
    "dxDiagram-categoryOrgChart": "چارت سازمانی",
    "dxDiagram-categoryContainers": "ظروف",
    "dxDiagram-categoryCustom": "سفارشی",

    "dxDiagram-commandExportToSvg": "صادرات به SVG",
    "dxDiagram-commandExportToPng": "صادرات به PNG",
    "dxDiagram-commandExportToJpg": "صادرات به JPEG",
    "dxDiagram-commandUndo": "واگرد",
    "dxDiagram-commandRedo": "دوباره",
    "dxDiagram-commandFontName": "نام قلم",
    "dxDiagram-commandFontSize": "اندازه فونت",
    "dxDiagram-commandBold": "پررنگ",
    "dxDiagram-commandItalic": "ایتالیک",
    "dxDiagram-commandUnderline": "زیرخط",
    "dxDiagram-commandTextColor": "رنگ قلم",
    "dxDiagram-commandLineColor": "رنگ خط",
    "dxDiagram-commandLineWidth": "عرض خط",
    "dxDiagram-commandLineStyle": "سبک خط",
    "dxDiagram-commandLineStyleSolid": "جامد",
    "dxDiagram-commandLineStyleDotted": "خط چین",
    "dxDiagram-commandLineStyleDashed": "نقطه چین",
    "dxDiagram-commandFillColor": "پر رنگ",
    "dxDiagram-commandAlignLeft": "تراز چپ",
    "dxDiagram-commandAlignCenter": "Align Center",
    "dxDiagram-commandAlignRight": "راست چین",
    "dxDiagram-commandConnectorLineType": "نوع خط اتصال",
    "dxDiagram-commandConnectorLineStraight": "سر راست",
    "dxDiagram-commandConnectorLineOrthogonal": "ارتودنسی",
    "dxDiagram-commandConnectorLineStart": "شروع اتصال دهنده خط",
    "dxDiagram-commandConnectorLineEnd": "انتهای خط اتصال",
    "dxDiagram-commandConnectorLineNone": "هیچ یک",
    "dxDiagram-commandConnectorLineArrow": "فلش",
    "dxDiagram-commandFullscreen": "تمام صفحه",
    "dxDiagram-commandUnits": "واحدها",
    "dxDiagram-commandPageSize": "اندازه صفحه",
    "dxDiagram-commandPageOrientation": "جهت گیری صفحه",
    "dxDiagram-commandPageOrientationLandscape": "چشم انداز",
    "dxDiagram-commandPageOrientationPortrait": "پرتره",
    "dxDiagram-commandPageColor": "رنگ صفحه",
    "dxDiagram-commandShowGrid": "نمایش شبکه",
    "dxDiagram-commandSnapToGrid": "ضربه محکم و ناگهانی به شبکه",
    "dxDiagram-commandGridSize": "اندازه شبکه",
    "dxDiagram-commandZoomLevel": "سطح بزرگنمایی",
    "dxDiagram-commandAutoZoom": "بزرگنمایی خودکار",
    "dxDiagram-commandFitToContent": "متناسب با محتوا",
    "dxDiagram-commandFitToWidth": "متناسب با عرض",
    "dxDiagram-commandAutoZoomByContent": "بزرگنمایی خودکار براساس محتوا",
    "dxDiagram-commandAutoZoomByWidth": "بزرگنمایی خودکار با عرض",
    "dxDiagram-commandSimpleView": "نمای ساده",
    "dxDiagram-commandCut": "قطع کردن",
    "dxDiagram-commandCopy": "کپی",
    "dxDiagram-commandPaste": "چسباندن",
    "dxDiagram-commandSelectAll": "انتخاب همه",
    "dxDiagram-commandDelete": "حذف",
    "dxDiagram-commandBringToFront": "به جلو بیاور",
    "dxDiagram-commandSendToBack": "ارسال به عقب",
    "dxDiagram-commandLock": "قفل کردن",
    "dxDiagram-commandUnlock": "باز کردن قفل",
    "dxDiagram-commandInsertShapeImage": "درج تصویر ...",
    "dxDiagram-commandEditShapeImage": "تغییر تصویر ...",
    "dxDiagram-commandDeleteShapeImage": "حذف تصویر",
    "dxDiagram-commandLayoutLeftToRight": "چپ به راست",
    "dxDiagram-commandLayoutRightToLeft": "راست به چپ",
    "dxDiagram-commandLayoutTopToBottom": "بالا به پایین",
    "dxDiagram-commandLayoutBottomToTop": "پایین به بالا",

    "dxDiagram-unitIn": "که در",
    "dxDiagram-unitCm": "سانتی متر",
    "dxDiagram-unitPx": "px",

    "dxDiagram-dialogButtonOK": "خوب",
    "dxDiagram-dialogButtonCancel": "لغو",
    "dxDiagram-dialogInsertShapeImageTitle": "درج تصویر",
    "dxDiagram-dialogEditShapeImageTitle": "تغییر تصویر",
    "dxDiagram-dialogEditShapeImageSelectButton": "انتخاب تصویر",
    "dxDiagram-dialogEditShapeImageLabelText": "یا پرونده را در اینجا رها کنید",

    "dxDiagram-uiExport": "صادرات",
    "dxDiagram-uiProperties": "Properties",
    "dxDiagram-uiSettings": "تنظیمات",
    "dxDiagram-uiShowToolbox": "نمایش جعبه ابزار",
    "dxDiagram-uiSearch": "جستجو کردن",
    "dxDiagram-uiStyle": "سبک",
    "dxDiagram-uiLayout": "چیدمان",
    "dxDiagram-uiLayoutTree": "درخت",
    "dxDiagram-uiLayoutLayered": "لایه ای",
    "dxDiagram-uiDiagram": "نمودار",
    "dxDiagram-uiText": "متن",
    "dxDiagram-uiObject": "هدف - شی",
    "dxDiagram-uiConnector": "اتصال دهنده",
    "dxDiagram-uiPage": "صفحه",

    "dxDiagram-shapeText": "متن",
    "dxDiagram-shapeRectangle": "مستطیل",
    "dxDiagram-shapeEllipse": "بیضی",
    "dxDiagram-shapeCross": "صلیب",
    "dxDiagram-shapeTriangle": "مثلث",
    "dxDiagram-shapeDiamond": "الماس",
    "dxDiagram-shapeHeart": "قلب",
    "dxDiagram-shapePentagon": "پنتاگون",
    "dxDiagram-shapeHexagon": "شش ضلعی",
    "dxDiagram-shapeOctagon": "هشت وجهی",
    "dxDiagram-shapeStar": "ستاره",
    "dxDiagram-shapeArrowLeft": "فلش سمت چپ",
    "dxDiagram-shapeArrowUp": "پیکان بالا",
    "dxDiagram-shapeArrowRight": "فلش راست",
    "dxDiagram-shapeArrowDown": "پیکان رو به پایین",
    "dxDiagram-shapeArrowUpDown": "Up Down Arrow",
    "dxDiagram-shapeArrowLeftRight": "پیکان چپ راست",
    "dxDiagram-shapeProcess": "روند",
    "dxDiagram-shapeDecision": "تصمیم",
    "dxDiagram-shapeTerminator": "نابود کننده",
    "dxDiagram-shapePredefinedProcess": "فرآیند از پیش تعریف شده",
    "dxDiagram-shapeDocument": "سند",
    "dxDiagram-shapeMultipleDocuments": "چندین سند",
    "dxDiagram-shapeManualInput": "ورودی دستی",
    "dxDiagram-shapePreparation": "آماده سازی",
    "dxDiagram-shapeData": "داده ها",
    "dxDiagram-shapeDatabase": "پایگاه داده",
    "dxDiagram-shapeHardDisk": "هارد دیسک",
    "dxDiagram-shapeInternalStorage": "حافظه داخلی",
    "dxDiagram-shapePaperTape": "نوار کاغذی",
    "dxDiagram-shapeManualOperation": "عملیات دستی",
    "dxDiagram-shapeDelay": "تاخیر انداختن",
    "dxDiagram-shapeStoredData": "اطلاعات ذخیره شده",
    "dxDiagram-shapeDisplay": "نمایش دادن",
    "dxDiagram-shapeMerge": "ادغام",
    "dxDiagram-shapeConnector": "اتصال دهنده",
    "dxDiagram-shapeOr": "یا",
    "dxDiagram-shapeSummingJunction": "Summing Junction",
    "dxDiagram-shapeContainerDefaultText": "کانتینر",
    "dxDiagram-shapeVerticalContainer": "ظرف عمودی",
    "dxDiagram-shapeHorizontalContainer": "ظرف افقی",
    "dxDiagram-shapeCardDefaultText": "نام شخص",
    "dxDiagram-shapeCardWithImageOnLeft": "کارت با تصویر در سمت چپ",
    "dxDiagram-shapeCardWithImageOnTop": "کارت با تصویر در بالا",
    "dxDiagram-shapeCardWithImageOnRight": "کارت دارای تصویر در سمت راست",

    "dxGantt-dialogTitle": "Title",
    "dxGantt-dialogStartTitle": "Start",
    "dxGantt-dialogEndTitle": "End",
    "dxGantt-dialogProgressTitle": "Progress",
    "dxGantt-dialogResourcesTitle": "Resources",
    "dxGantt-dialogResourceManagerTitle": "Resource Manager",
    "dxGantt-dialogTaskDetailsTitle": "Task Details",
    "dxGantt-dialogEditResourceListHint": "Edit Resource List",
    "dxGantt-dialogEditNoResources": "No resources",
    "dxGantt-dialogButtonAdd": "Add",
    "dxGantt-contextMenuNewTask": "New Task",
    "dxGantt-contextMenuNewSubtask": "New Subtask",
    "dxGantt-contextMenuDeleteTask": "Delete Task",
    "dxGantt-contextMenuDeleteDependency": "Delete Dependency",
    "dxGantt-dialogTaskDeleteConfirmation": "Deleting a task also deletes all its dependencies and subtasks. Are you sure you want to delete this task?",
    "dxGantt-dialogDependencyDeleteConfirmation": "Are you sure you want to delete the dependency from the task?",
    "dxGantt-dialogResourcesDeleteConfirmation": "Deleting a resource also deletes it from tasks to which this resource is assigned. Are you sure you want to delete these resources? Resource: {0}",
    "dxGantt-dialogConstraintCriticalViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. This change would conflict with dependency rules. How would you like to proceed?",
    "dxGantt-dialogConstraintViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. How would you like to proceed?",
    "dxGantt-dialogCancelOperationMessage": "Cancel the operation",
    "dxGantt-dialogDeleteDependencyMessage": "Delete the dependency",
    "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Move the task and keep the dependency",
    "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
    "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
    "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
    "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
    "dxGantt-undo": "Undo",
    "dxGantt-redo": "Redo",
    "dxGantt-expandAll": "Expand All",
    "dxGantt-collapseAll": "Collapse All",
    "dxGantt-addNewTask": "Add New Task",
    "dxGantt-deleteSelectedTask": "Delete Selected Task",
    "dxGantt-zoomIn": "Zoom In",
    "dxGantt-zoomOut": "Zoom Out",
    "dxGantt-fullScreen": "Full Screen",
    "dxGantt-quarter": "Q{0}",
    "dxGantt-sortingAscendingText": "Sort Ascending",
    "dxGantt-sortingDescendingText": "Sort Descending",
    "dxGantt-sortingClearText": "حذف سورت",
    "dxGantt-showResources": "Show Resources",
    "dxGantt-showDependencies": "Show Dependencies",
    "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
    "dxGantt-dialogEndDateValidation": "End date must be after {0}",

    "dxGallery-itemName": "Gallery item",

    "dxMultiView-elementAriaRoleDescription": "MultiView",
    "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
    "dxMultiView-itemAriaRoleDescription": "View",
    "dxMultiView-itemAriaLabel": "{0} of {1}"
  }
}

}

