import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OstanKol } from "../src/app/_models/app1/paye/ostanKol";


@Injectable({
  providedIn: 'root'
})
export class mySF{
  

  constructor(private http: HttpClient) { }


  calcOtherThing(parameter: number): boolean {
    return true;
  }

  getOstanKols(): Observable<OstanKol[]> {

    let t =   'App1/Paye/Ostan/getall';
    console.log('get : ' + t);
    return this.http.get<OstanKol[]>(t);
  }

}
