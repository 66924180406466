import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 

import { LoadingService } from '../../../../_services/loading.service';
 
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { PdPayrec, PdPayrecRow } from '../../../../_models/app4/opr/PdPayrec';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { PdPayrecService } from '../../../../_services/app4/opr/PdPayrec.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import { Localst } from '../../../../_services/localst.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { DxValidatorComponent, DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { PdHesService } from '../../../../_services/app4/paye/PdHes.service';
import { PdHes } from '../../../../_models/app4/paye/PdHes';
import { PdBank, CkBank } from '../../../../_models/app4/paye/PdBank';
import { PdBankService } from '../../../../_services/app4/paye/PdBank.service';
import { MyTimeService } from '../../../../_services/myTime.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { IdName } from '../../../../_models/General/IdName';
import { PdMyCkService } from '../../../../_services/app4/paye/PdMyCk.service';
import { PdMyCkRow, PdMyCkRowForList } from '../../../../_models/app4/paye/PdMyCk';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { AnSellService } from '../../../../_services/app3/opr/AnSell.service';
import { AnSell } from '../../../../_models/app3/opr/AnSell';
import { AutoPdLink } from '../../../../_models/app4/opr/PdLink';
import { PdPos } from '../../../../_models/app4/paye/PdPos';

export class DialogData {

  title: string;
  rows: string[];
  valid: boolean;
  kind: string;
  selectbutton: string;
  datastring: string;
  datastringShow: boolean;
  styleMode: number;

}
@Component({
  selector: 'app4-PdPayrec-edit',
  templateUrl: './PdPayrec-edit.component.html',
  styleUrls: ['./PdPayrec-edit.component.css']
})
export class PdPayrecEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMyCancelEvent = new EventEmitter<any>();
  @Output() OnMySaveEvent = new EventEmitter<any>();
  @Output() OnMyRefleshEvent = new EventEmitter<boolean>();
  @Output() onfchanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPrintEvent: EventEmitter<any> = new EventEmitter<any>();

 
  myModel: PdPayrec;
  myModel_loaded: boolean = false;
  mahalChangedinEdit: boolean ;

  myModel_old: PdPayrec;
  myAnsell: AnSell;
  @ViewChild("numcodevalid", { static: false }) numcodevalid: DxValidatorComponent;
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @ViewChild("editColumntafC", { static: false }) editColumntafC: DxSelectBoxComponent;
  @ViewChild("editColumnpdHesC", { static: false }) editColumnpdHesC: DxSelectBoxComponent;
  @ViewChild("editColumnMoien", { static: false }) editColumnMoien: DxSelectBoxComponent;
  @ViewChild("cellTemplatePrc", { static: false }) cellTemplatePrc: DxSelectBoxComponent;
  @ViewChild("editColumnpdBankInC", { static: false }) editColumnpdBankInC: DxSelectBoxComponent;
  @ViewChild("editColumnpdBankC", { static: false }) editColumnpdBankC: DxSelectBoxComponent;
  @ViewChild("editColumnpdPos", { static: false }) editColumnpdPos: DxSelectBoxComponent;


  isfchanged: boolean;


  //@ViewChild("valllC", { static: false }) valllC: DxValidatorComponent;
  @Input() kindpage: number;
  @Input() AutoPdLinkData: AutoPdLink;
  //دریافتی ها از سیستم فروش
  @Input() ansellId: number |null;

  tabIndex: number ;
  //اگر -1 بود حالت دستی
  nextCode: number = 0;
  isAutoCode: boolean = true;
  reloadMainList: boolean = false;

  rules: any;
  sho: false;
  somest: string;
  someint: number;
  lastrowidtemplate: number;
  selectedrow: PdPayrecRow;
  selectedrowOld: PdPayrecRow;
  dynamicData: PdPayrecRow[] =[];
  private sub: any;
  test: string;
  n: number;

  moienlist: Hesab[] = [];

 
  ckBanklist: CkBank[] = [];


  taflist: any = {};
  pdHeslist: PdHes[] = [];
  pdHesFilteredlist: PdHes[] = [];
  pdBanklist: PdBank[] = [];
  pdBankSandoghlist: PdBank[] = [];
  
  pdBanklistFiltered: PdBank[] = [];
  pdMyCkRowlist: PdMyCkRowForList[] = [];
  Mahallist: any[] = [];


  PdPosList: PdPos[] = [];

  ckNoelist: IdName[] = [{ id: 1, name: 'روز' }, { id: 2, name: 'مدت دار' }, { id: 3, name: 'تضمینی' }];
  ckNoelistB: IdName[] = [{ id: 1, name: 'روز' }];


  hideHeader: boolean = false;
  hideMenu: boolean = false;
//  dateText: string = '';

  tafsiliForGroupRead: TafsiliForGroupRead;

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private localst: Localst,
    private hesabService: HesabService,
    private pdPayrecService: PdPayrecService,
    private tafsiliService: TafsiliService,
    private pdHesService: PdHesService,
    private anSellService: AnSellService,
 
    private pdBankService: PdBankService,
    private pdMyCkService: PdMyCkService,
    private myTimeService: MyTimeService,
    public dialog: MatDialog
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    this.mahalChangedinEdit = false;

  


  }

  ngOnInit() {



    //this.pdBankService.getallPoss().subscribe(res => {
    //  this.loadServ.hideloading(this.loaddata, 3);
    //     this.PdPosList = res.data;
    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 3);
    //     this.alertify.error(error);
    //     console.log("myerror:" + error);
    //});



    //if (this.kindpage > 0)
    //  this.dateText = 'تاریخ دریافت';
    //if (this.kindpage < 0)
    //  this.dateText = 'تاریخ پرداخت';
    this.tafsiliForGroupRead = this.tafsiliService.getTafsiliGroupEmpty();
    this.asyncValidation = this.asyncValidation.bind(this);
    this.Mahallist = this.pdPayrecService.getMahalList();
    //+10 or -10
    this.myModel = this.pdPayrecService.getPdPayrecEmpty(this.kindpage);

    if (this.kindpage== 10) {
      this.myModel.mahal == 11;
      this.myModel.mahalN == 'خارجی';
    }

    this.isAutoCode = this.localst.GetRegistryByPath('App4-PdPayrec-[Auto' + this.kindpage.toString() + ']').vBool;

 

    this.loadTafsili();
   
    this.pdBankService.GetAllCkBank_Static().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 5);

      this.ckBanklist = res.data;


    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  

     this.hesabService.getHesabs_treenum(4).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);

       this.moienlist = res;



    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
     });


    this.loadServ.showloading(this.loaddata, 10);
    this.pdMyCkService.GetAllRowUnUsed(this.editId).subscribe(res => {

      this.loadServ.hideloading(this.loaddata, 10);
      this.pdMyCkRowlist = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
    this.loadpdhes();
    this.myoninit();

    this.loadAnSell();
  }


  loadTafsili() {


    this.taflist = new DataSource({
      store: [],
      paginate: true,
      pageSize: 50
    });

    //public  int[] dasteIds { get; set; }
    //     public bool ? filter { get; set; }
    //     public int ? visId { get; set; }
    //     public string visTbl { get; set; }

    var optaf = { dasteIds: [-1001, -1002], filter: true, visId: this.editId, visTbl: 'PdPayrec' }

    this.loadServ.showloading(this.loaddata, 11);
    this.tafsiliService.GetTafsiliForSmallByDastes(optaf).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);

      this.taflist = new DataSource({
        store: data,
        paginate: true,
        pageSize: 50
      });



    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }





  loadAnSell() {

    if (this.ansellId == null) {
      return;
    }

    this.loadServ.showloading(this.loaddata, 9);
    this.anSellService.getbyid(this.ansellId).subscribe(data => {
      
      this.myAnsell = data;
      this.loadServ.hideloading(this.loaddata, 9);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }


  loadpdBank() {

    this.loadServ.showloading(this.loaddata, 10);
    this.pdBankService.getall(-1).subscribe(res => {

      this.pdBanklist = res.data;
      this.PdPosList = [];
      //لیست کارت خوان
      for (var b of this.pdBanklist) {

        for (var r of b.pdPoss) {
          this.PdPosList.push(r);
        }
      }

    
      ///tutu436543ty
      this.pdBankSandoghlist = this.pdBanklist.filter(x => x.kind == 2);

      if (this.editId == -1) {
        var san = this.pdBankSandoghlist.find(x => x.uOwner == this.localst.usernow_get().username);
        if (san != null && san != undefined) {
          this.myModel.pdBankId = san.id;
          this.myModel.pdBankC = san.code;
          this.myModel.pdBankN = san.name;
        }
        else {
          this.alertify.warning('برای این کارر صندوق تعریف نشده است');
        }
      }

      this.LoadedForTab(0);
      this.loadServ.hideloading(this.loaddata, 10);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });





  }

  loadpdhes() {
    //let movekind = -1;
    //if (this.kindpage == 10)
    //  movekind = 101;
    //else if (this.kindpage == -10 && this.myModel.mahal == 11)
    //  movekind = 211;
    //else if (this.kindpage == -10 && this.myModel.mahal == 12)
    //  movekind = 212;
    this.pdHeslist = [];
    this.pdHesFilteredlist = [];

    this.loadServ.showloading(this.loaddata, 10);
    this.pdHesService.getall(-1).subscribe(res => {
      

      this.pdHeslist = res.data;

     // this.filterpdHes(rowData.kindRow, rowData.ckNoe, this.myModel.mahal);
      this.loadServ.hideloading(this.loaddata, 10);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }


  refreshbottum() {
    this.loadpdhes();
    this.loadpdBank();
    this.loadTafsili();


  }

  myoninit() {
   // this.loadServ.showloading(this.loaddata, 9);
    //این متغییر جلو پاک شدن جدول با تغییر رادیون در بار اول میگیرد
 
    this.myModel_loaded = false;
 
    /////////////////////////constractor////6///////////////////////
    this.validationCallback = this.validationCallback.bind(this);
    this.myModel = this.pdPayrecService.getPdPayrecEmpty(this.kindpage);
 
    this.myModel.kind = this.kindpage;

    //this.myModel.rows1 = [];
    //this.myModel.rows2 = [];
    //this.myModel.rows3 = [];

    this.lastrowidtemplate = -2000100000;
    this.lastrowidtemplate--;

    this.setCode();
        this.loadpdBank();

    if (this.editId == -1) {
    
  
      console.log('new mode');

 
      this.myModel = this.pdPayrecService.getPdPayrecEmpty(this.kindpage);

 

      this.myModel.kind = this.kindpage;
      this.myModel.years = this.localst.yearnow_get();
 
      this.myModel.dateA_S = this.localst.dateEmruz.dateString;

      this.myModel.uSa = this.localst.usernow_get().username;
      this.myModel.uSaF = this.localst.usernow_get().name;
    

      if (this.kindpage == 10) {
        this.myModel.mahal = 11;
      }

      //re6y457654856 
      //اگر اتصال اتو ماتیک فعال بود
      if (this.AutoPdLinkData) {
        //

        this.hideHeader = true;
        this.hideMenu = true;
        this.myModel.autoPdLinkData = this.AutoPdLinkData;
        this.myModel.tafId = this.myModel.autoPdLinkData.tafId;
        this.myModel.dateA_S = this.myModel.autoPdLinkData.data_S;


      }

      //this.pushnewrow(true);
     
      this.isnew = true;
      this.setCode();
  /*    this.loadpdBank();*/
      this.LoadedForTab(1);
      this.myModel_loaded = true;
    //  this.pushnewrow(true);
    //  this.dynamicData = this.myModel.rows3;
      this.tabIndex = 1;
      this.onClicktab(this.tabIndex);
     // this.bindLock();
    }
    else {

      this.isnew = false;
      this.loadServ.showloading(this.loaddata, 3);
      this.pdPayrecService.getbyid(this.editId).subscribe( res => {
      
        this.loadServ.hideloading(this.loaddata, 3);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {


          this.myModel = res.data ;

          this.myModel.uSaE = this.localst.usernow_get().username;
          this.myModel.uSaEF = this.localst.usernow_get().name;

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));

         


          this.setCode();
         // this.pushnewrow(true);

          if (this.myModel.rows.length > 0) {
            this.tabIndex = this.myModel.rows[0].kindRow;
          }
         

          this.onClicktab(this.tabIndex);
          //this.loadpdBank();
          this.LoadedForTab(1);
          this.myModel_loaded = true;
        }

       
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 3);
          console.log(error);
          this.alertify.error(error);
        }


      );



    }

   // this.loadServ.hideloading(this.loaddata, 9);



  }
  onInitialized(e) {
    this.LoadedForTab(2);
  }


  bitVar: boolean[] = [false, false, false] 
  LoadedForTab(bit:number) {
    return;
    this.bitVar[bit] = true;

    if (this.bitVar.filter(x => x == false).length == 0) {
      //rtyu75675
      this.onClicktab(2);
    }

  }




  cssActivtab1: string = '';
  cssActivtab2: string = '';
  cssActivtab3: string = '';
  cssActivtab4: string = '';
  cssActivtab5: string = '';


  capC: string;
  capD: string;
  capN: string;

 
  onClicktab(e) {

 
    this.tabIndex = e;

    this.cssActivtab1 = '';
    this.cssActivtab2 = '';
    this.cssActivtab3 = '';
    this.cssActivtab4 = '';
    this.cssActivtab5 = '';

    this.pushnewrow(true);

    if (e == 1) {

      this.dynamicData = this.myModel.rows.filter(x => x.kindRow ==1);
      this.capC = 'ش';
      this.capD = 'ت';
      this.capN = 'ن'
      this.cssActivtab1 = 'active';

      this.pdBanklistFiltered = this.pdBanklist;

    }
    else if (e == 2) {
      this.dynamicData = this.myModel.rows.filter(x => x.kindRow == 2);
      this.capC = 'شماره چک';
      this.capD = 'تاریخ چک';
      this.capN = 'نوع چک';
      this.cssActivtab2 = 'active';
      this.pdBanklistFiltered = this.pdBanklist;

    }
    else if (e == 3) {
      this.dynamicData = this.myModel.rows.filter(x => x.kindRow == 3);
      this.capC = 'شماره سفته';
      this.capD = 'تاریخ سفته';
      this.capN = 'نوع سفته';
      this.cssActivtab3 = 'active';
      this.pdBanklistFiltered = this.pdBanklist.filter(x => x.kind == 2);

    }
    else if (e == 4) {
      this.dynamicData = this.myModel.rows.filter(x => x.kindRow == 4);
      this.capC = 'شماره حواله';
      this.capD = 'تاریخ حواله';
      this.capN = 'نوع حواله';
      this.cssActivtab4 = 'active';
      this.pdBanklistFiltered = this.pdBanklist.filter(x => x.kind == 1);

    }
    else if (e == 5) {
      this.dynamicData = this.myModel.rows.filter(x => x.kindRow == 5);
      this.capC = 'شماره کارت خوان';
      this.capD = 'تاریخ کارت خوان';
      this.capN = 'نوع کارت خوان';
      this.cssActivtab5 = 'active';
      this.pdBanklistFiltered = this.pdBanklist.filter(x => x.kind == 1);

    }
    //??????
   // this.dataGrid.instance.refresh();
  }

  addthis(condition: string) {


    if (this.loaddata.showed)
      return;
  //  this.calledsubmitvaz = this.anDeedService.getvaz(200);

    this.onFormSubmit(condition);

  }

  cancelthis(e) {
    
    this.onfchanged.emit(false);
    this.OnMyCancelEvent.emit(true);
    this.OnMySaveEvent.emit({ reloadlist: this.reloadMainList, closepage: false });

    if (this.isfchanged) {

      this.alertify.confirmClosetab(() => {
        this.isfchanged = false;
        this.onfchanged.emit(false);

        this.cancelthis(e);
      });
      return;
    }


  }


  onFormSubmit(condition: string) {



    //برای رفلش مانده
   // this.pushnewrow(false);
    this.onClicktab(this.tabIndex);


   // this.repairlostKalaId();

   //hyhy5476878976


    if (this.myvalidationchek(condition) == false) {
     // this.pushnewrow(false);
      return;
    }



   // this.refreshvazColor(this.calledsubmitvaz.id);


    if (this.myModel.id == -1) {

      this.loadServ.showloading(this.loaddata, 6);
      this.pdPayrecService.add(this.myModel).subscribe(res => {
       
        this.loadServ.hideloading(this.loaddata, 6);
        if (res.errors.length > 0){

          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.mes);
          this.reloadMainList = true;

          this.editId = res.data;
          //  this.myoninit();
         
          this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });
        }





        if (condition == 'addAndNew') {
          // this.myModel.mabna = 0;
          this.ctrNthis();

        }
        else {

          this.myoninit();
        }

      


      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {

      this.loadServ.showloading(this.loaddata, 8);
      this.pdPayrecService.update(this.myModel).subscribe(res => {

      

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.success[0]);

          this.editId = res.data;
        
          //  this.myModel = res.data;
        }

        this.reloadMainList = true;
        //  ؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟؟
        this.OnMyRefleshEvent.emit(true);
        this.OnMySaveEvent.emit({ reloadlist: false, closepage: false });


        if (condition == 'addAndNew') {
          this.ctrNthis();

        }
        else {
          this.myoninit();
        }
        this.loadServ.hideloading(this.loaddata, 8);
       // this.loadMabna();
        //???????????در ویرایش مبنا دیگه نشه عوض کرد
        //this.radDisable = true;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });

    }

    this.isfchanged = false;
    this.onfchanged.emit(false);
    //  e.preventDefault();
  }
  ctrNthis() {

    //با لود مدل جدید ستون مبنا مخفی نمیشد و مشکل در بروز رسانی کامپوننت بود و مجبور شدیم مستقیم ستون را مخفی کنیم
    //this.mabcolum.visible = false;
    //if (this.isfchanged) {

    //  this.alertify.confirmClosetab(() => {
    //    this.isfchanged = false;
    //    this.onfchanged.emit(false);
    //    this.ctrNthis();
    //  });
    //  return;
    //}
    //ای دی سند را میدهیم و صفحه را از نو بارگذاری میکنیم

    if (this.loaddata.showed)
      return;
    //4543thrtrrt
    this.editId = -1;
    // this.mymodle.mabna = 0;
    this.myoninit();
    this.alertify.message('سند جدید');
    //this.ttt.instance.focus();
  }


  valdata: DialogData;
  myvalidationchek(condition: string): boolean {




    const verror = [];


    if (this.myModel == null)
      verror.push('model is empty');
    else {



 

      if (this.myModel.code == null)
        verror.push(' شماره درخواست خالی است');


      let datevalid = this.myTimeService.isvalidday(this.myModel.dateA_S);



      if (datevalid != null) {

        verror.push(datevalid);
      }

      var years = this.localst.yearnow_Class_get();


      var dajfouy = parseInt(this.myModel.dateA_S.replace('/', '').replace('/', '').replace('/', '').toString());


      if (dajfouy > years.dateE) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }

      if (dajfouy < years.dateS) {
        verror.push('تاریخ در محدوده سال مالی جاری نیست');
      }


      //if (this.myModel.vaz > 200) {


      //  verror.push('ابتدا سند را در وضعیت موقت قرار دهید');


      //}


      let c = 0;
      let banknull = 0;
      let datebnull = 0;
    //  let hesabnull = 0;
 //trytryrtutyu

      var u = [];
      for (var _i = 0; _i < this.myModel.rows.length; _i++) {
        if (this.myModel.rows[_i].prc >0 ) {
          u.push(this.myModel.rows[_i]);
        }

      }
      this.myModel.rows = u;
      for (var _i = 0; _i < this.myModel.rows.length; _i++) {

        //if (this.myModel.rows[_i].pdBankId == null || this.myModel.rows[_i].pdBankId == -1) {
        //  this.myModel.rows.splice(_i, 1);
        //  continue;
        //}
        c++;

        //if (this.myModel.rows[_i].pdBankId == null)
        //  banknull

        //if (this.myModel.rows[_i].dateB_S == null)
         // datebnull++;

        //if (this.myModel.rows1[_i].hesabId == null)
        //  hesabnull++;

 


      }
     




      if (c == 0) {

        verror.push('هیچ ردیفی ثبت نشده است');
   
      }


      if (banknull > 0) {

        verror.push('بانک در ردیف ها مشخص نشده است');

      }
      if (datebnull > 0) {

        verror.push('تاریخ در ردیف ها مشخص نشده است');

      }
      //if (hesabnull > 0) {

      //  verror.push('حساب در ردیف ها مشخص نشده است');

      //}




    }
    // verror.push('ارور');

    if (verror.length == 0)
      return true;





    this.valdata = { title: 'موارد زیر را تکمیل نمایید', rows: [], valid: false, kind: condition, selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1000';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);




    return false;;
  }



  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
 
  asyncValidation(params) {

    //return new Promise((resolve) => {
    //  setTimeout(() => {
    //    resolve(1 === 1);
    //  }, 1000);
    //});

    if (this.isAutoCode) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    }
    //56645yhgrfyhtr
    return new Promise((resolve, reject) => {
      this.pdPayrecService.validationCode(params.value, this.editId, this.myModel.dateA_S, this.kindpage, this.localst.yearnow_get())
        .then((res: ApiRet) => {
          if (res.errors.length > 0) {

            this.alertify.error(res.errors.join('\n'));
          }


          resolve(res.value);
        })
        .catch(error => {
          console.error("خطا ارتباط با سرور", error);

        });
    });


  }
  datechanged() {
 
    this.numcodevalid.instance.validate();
  }
  onKeyDownDate(e) {


    if (e.event.key == 'Enter') {


      //var cellolv = this.dataGrid.instance.cellValue(0, 1);


      //this.dataGrid.instance.cellValue(0, 1, cellolv);
      //this.dataGrid.instance.editCell(0, 1);
      //this.dataGrid.instance.focus(cellolv);

    }


  }
  validateNumber(e) {

    

  }

  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }

  mahalfocus: boolean = false;
  mahalonFocusIn(e) {

    this.mahalfocus = true;

  }
  mahalonFocusOut(e) {
    this.mahalfocus = false;
  }
  mahalchanged(e ) {

    //if (this.loaddata.showed)
    //  return;
    //  //thyru78t687896d
    //if (this.editId > 0 && this.mahalChangedinEdit == false) {
    //    this.mahalChangedinEdit = true;
    //  return
    //}

    if (!this.mahalfocus)
      return;


    var t = e.component as DxSelectBoxComponent;
   var u= t.focusStateEnabled;
   var ur= t.focusStateEnabledChange;

    //چک ها را پاک میکنیم فقط
    this.myModel.rows = this.myModel.rows.filter( x=> x.kindRow != 2);

    this.myModel.tafId =null;
    this.myModel.tafC =null;
    this.myModel.tafN =null;





    this.onClicktab(this.tabIndex);
   // e.value


    //this.loadpdhes();

  }

  mochangedA(e) {
    if (this.loaddata.showed)
      return;

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

    //this.myModel.hesabAC = h.codeSum;
    //this.myModel.hesabAN = h.name;

    //gfu68ui76i67
    if (e.previousValue != null) {
      //this.myModel.taf4Id = null;
      //this.myModel.taf4C = null;
      //this.myModel.taf4N = null;
   
    }


    this.tafsiliService.getTafsilisGroup(h.id).subscribe(res => {
      

      this.tafsiliForGroupRead = res;
      this.tafsiliForGroupRead.taf4List;
      this.loadServ.hideloading(this.loaddata, 6);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


 
  }
  t4changed(e) {
    //if (e.value == null)
    //  return;
    //var h = e.component.option('selectedItem');
    //if (h == null)
    //  return;

    //this.myModel.taf4C = h.code;
    //this.myModel.taf4N = h.name;
 
  }




  mochangedB(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    //this.myModel.hesabBC = h.codeSum;
    //this.myModel.hesabBN = h.name;


  }



  mochangedC(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;
 
    if (h == null)
      return;

    //this.myModel.hesabCC = h.codeSum;
    //this.myModel.hesabCN = h.name;

 

  }


  pushnewrow(focusnwerow: boolean) {

    this.myModel.rows = this.myModel.rows || [];
   


    let c1 = this.myModel.rows.filter(x => (x.prc == 0 || x.prc == null) && x.kindRow == this.tabIndex ).length;
    if (this.myModel.rows.length == 0 || c1==0) {
      let a = this.pdPayrecService.getPdPayRecRowEmpty();
      a.kindRow = this.tabIndex;
      //ای دی منفی میدهیم تا جدول قاطی نکنه
      //منفی نشاندهنده جدید بودن و نبود در دیتا بیس است
      this.lastrowidtemplate--;
      a.id = this.lastrowidtemplate;
      this.myModel.rows.push(a);
     // this.onClicktab(this.tabIndex);
    }

    this.refreshradifnumber();
    this.calcsumAll();
  }
  refreshradifnumber() {


    let c = 0;
    for (var k = 0; k < this.myModel.rows.filter(x => x.kindRow == this.tabIndex).length; k++) {
      c++;
      this.myModel.rows[k].radif = c;

    }
    
  }

  //گرفتن نوع اتو بودن کد و اگر بود کد بعدی
  setCode() {


    this.loadServ.showloading(this.loaddata, 13);
    this.pdPayrecService.nextCode(this.localst.yearnow_get(), this.kindpage).subscribe(res => {
     
      this.nextCode = res.value;


      if (this.isnew)
        this.myModel.code = this.nextCode;

      this.loadServ.hideloading(this.loaddata, 13);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 13);
      this.alertify.error(error);
    });




  }




  onFocusedRowChanged(e) {
    //if (e.value == null)
    //  return;

   // var h = e.component.option('selectedItem') as PdPayRecRow;
    const rowData = e.row && e.row.data;
    if (rowData == null)
      return;

 
    this.filterpdHes(rowData.kindRow, rowData.ckNoe, this.myModel.mahal);

    //for (var _i = 0; _i < this.myModel.rows.length; _i++) {
     

    //  if (this.myModel.rows1[_i].id == f.id) {

    //    //اگر اویل بار اولین ردیف را کلیک کردیم
    //    if (this.selectedrowOld == null)
    //      this.selectedrowOld = JSON.parse(JSON.stringify(this.myModel.rows[_i]));
    //    else
    //      this.selectedrowOld = JSON.parse(JSON.stringify(this.selectedrow));

    //    this.selectedrow = this.myModel.rows[_i];
    //  }



    //}





   // this.pushnewrow(true);


    //if (e.row.data.kalaId == null) {
    //  this.dataGrid.instance.editRow(e.row.rowIndex);


    //}
 

  }
  onKeyDownGrid(e) {

    //  console.log('hhh: '+ e.event.shiftKey);
    //this.mypresskey(e.event);

  }
 
 
 

  pdbankchanged(e, cellInfo) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as PdBank;

    if (h == null)
      return;

    this.myModel.pdBankId = h.id;
    this.myModel.pdBankC = h.code;
    this.myModel.pdBankN = h.name;

    if (this.kindpage == 10) {
      for (var r of this.myModel.rows.filter(x => x.prc > 0 && x.kindRow <= 3)) {

        r.pdBankId = h.id;
        r.pdBankC = h.code;
        r.pdBankN = h.name;
      }
    }
    else if (this.kindpage == -10) {
      for (var r of this.myModel.rows.filter(x => x.prc > 0 && x.kindRow == 1)) {

        r.pdBankId = h.id;
        r.pdBankC = h.code;
        r.pdBankN = h.name;
      }
    }

  }


  tafchanged(e, cellInfo) {
    if (this.loaddata.showed)
      return;
    if (e.value == null)
      return;
      ///dgfedrfyt54
    var h = e.component.option('selectedItem') as PdBank;

    if (h == null)
      return;

    this.myModel.tafId = h.id;
    this.myModel.tafC = h.code;
    this.myModel.tafN = h.name;


    for (var r of this.myModel.rows.filter(x => x.prc > 0)) {

      r.tafId = h.id;
      r.tafC = h.code;
      r.tafN = h.name;
    }

  }


  onFocuspdHesedit(e, item) {


 
    
    this.editColumnpdHesC.instance.open();
  

  }

  temp_pdHeschanged(e, cellInfo) {

  


    var temp = e.component.option('selectedItem') as PdHes;

  //rhy56u875it7y
    if (temp) {
     
        
        cellInfo.data.pdHesId = temp.id;
        cellInfo.data.pdHesC = temp.code;
        cellInfo.data.pdHesN = temp.name;
        cellInfo.data.hesabId = temp.hesabAId;
        cellInfo.data.hesabC = temp.hesabAC;
        cellInfo.data.hesabN = temp.hesabAN;
        cellInfo.data.dis_PdHesTemp = temp.pattern;
  
      }

   


  }

  onFocusMoienedit(e, item) {


 
    this.editColumnMoien.instance.open();
   

  }

  temp_ckNoechanged(e, cellInfo) {

    if (e.value == undefined)
      return;
    var h = e.component.option('selectedItem') as IdName;
 

   
 
        //hyu546u7568u56
        cellInfo.data.ckNoe = h.id;
        cellInfo.data.ckNoeN = h.name;
        cellInfo.data.pdHesId = null;
        cellInfo.data.pdHesC = null;
        cellInfo.data.pdHesN = null;
        cellInfo.data.hesabId = null;
        cellInfo.data.hesabC = null;
        cellInfo.data.hesabN = null;
        this.filterpdHes(this.tabIndex, h.id , this.myModel.mahal);
 
       
    

     




  }

  temp_ckBankchanged(e, cellInfo) {

    if (e.value == undefined)
      return;
    var h = e.component.option('selectedItem') as CkBank;

    cellInfo.data.ckBank = h.name;
 


  }

  temp_ckMyckchanged(e, cellInfo) {

    if (e.value == undefined)
      return;
      //tyh75555555555555
    for (var r of this.pdMyCkRowlist) {

      if (r.id == e.value) {

      

        cellInfo.data.pdMyCkRowId = r.id;
        cellInfo.data.ckCode = r.seri + '-' + r.paperC;

        cellInfo.data.pdBankId = r._PdBankId;
        cellInfo.data.pdBankC = r._PdBankC;
        cellInfo.data.pdBankN = r._PdBankN;
       

        break;
      }

    }




  }


  temp_Moienchanged(e, cellInfo) {

    if (e.value == undefined)
      return;

    for (var r of this.moienlist) {

      if (r.id == e.value) {

        var temp = r as Hesab;
   
        cellInfo.data.hesabId = temp.id;
        cellInfo.data.hesabC = temp.codeSum;
        cellInfo.data.hesabN = temp.name;
        break;
      }

    }


  }


  onValueChangePrc(e, cellInfo) {
    


    cellInfo.data.prc = e.value

    this.calcsumAll();
   // this.pushnewrow(true);
    this.onClicktab(this.tabIndex);

  }
  onValueChangeckDate(e, cellInfo) {

    var r = this.myTimeService.getValueAsDatestring(e);
    if (r == null)
      return;

    cellInfo.data.ckDate_S = r;



  }

  onValueChangeDateB(e, cellInfo) {

    var r = this.myTimeService.getValueAsDatestring(e);
    if (r == null)
      return;

    cellInfo.data.dateB_S = r;

  }
  onValueChangeckMos(e, cellInfo) {

    cellInfo.data.mos = e.value;

  }
  onKeyDownmove(e) {

  //  this.mypressMovekey(e.event);
  }
  onFocuspdBankInCedit(e, item) {



    //if (this.editColumnkalaC.minSearchLength == 0)
    //  this.editColumnkalaC.minSearchLength = 2;
    //  if (this.tafopen == null) this.tafopen = 0;

    this.editColumnpdBankInC.instance.open();
  

  }
  onFocuspdBankCedit(e, item) {

    //if (this.editColumnkalaC.minSearchLength == 0)
    //  this.editColumnkalaC.minSearchLength = 2;
    //  if (this.tafopen == null) this.tafopen = 0;

    this.editColumnpdBankC.instance.open();
 

  }
  onFocuspdPosedit(e, item) {

    //if (this.editColumnkalaC.minSearchLength == 0)
    //  this.editColumnkalaC.minSearchLength = 2;
    //  if (this.tafopen == null) this.tafopen = 0;

    this.editColumnpdPos.instance.open();


  }


  temp_pdBankInchanged(e, cellInfo) {


    if (e.value == null)
      return;
   
    var h = e.component.option('selectedItem') as PdBank;

    if (h == null)
      return;

        cellInfo.data.pdBankInId = h.id;
        cellInfo.data.pdBankInC = h.code;
       cellInfo.data.pdBankInN = h.name;
  


  }
  temp_pdBankchanged(e, cellInfo) {

  if (e.value == undefined)
    return;
    ///gg4457h
    for (var r of this.pdBanklist) {

    if (r.id == e.value) {

      var temp = r as PdBank;
      cellInfo.data.pdBankId = temp.id;
      cellInfo.data.pdBankC = temp.code;
      cellInfo.data.pdBankN = temp.name;
      break;
    }

  }

  
  }

  temp_pdPoschanged(e, cellInfo) {

    if (e.value == undefined)
      return;


    var pos= this.PdPosList.find(x => x.id == e.value);

    if (pos) {

      cellInfo.data.pdPos = pos as PdPos;
 
     var bank = this.pdBanklist.find(x => x.id == pos.pdBankId);

      if (bank) {
        cellInfo.data.pdBankId = bank.id;
        cellInfo.data.pdBankC = bank.code;
        cellInfo.data.pdBankN = bank.name;
      }


    }


     


  }

  onValueChangeckDis(e, cellInfo) {
 
    const newValue = e.value;

    cellInfo.data.dis = newValue;
  
  }

  onFocusInDis(e, cellInfo) {

    if (cellInfo.data.dis == null || cellInfo.data.dis.replace(' ', '') == '') {

     let str= this.generateDis(cellInfo.data);
      //gry547yht
      if (str != null)
        cellInfo.data.dis = str;
    }


  }

//  *1*	طرف مقابل
//  *2*	بانک صندوق
//  *3*	تاریخ انتقال
//  *4*	مستندات
//*5*	بانک صادرکننده
//  *6*	شماره چک
//  *7*	تاریخ چک
//  *8*	نوع چک
  generateDis(data):string {
    var d = data as PdPayrecRow;
    let synt = d.dis_PdHesTemp;
    if (synt == null)
      return null;
    //tyfdg5te656465
    return synt
      .replace('*1*', d.ckCode)
      .replace('*2*', d.mos)
      .replace('*3*', this.myModel.tafN)
      .replace('*4*', this.myModel.pdBankN)
      .replace('*20*', this.myModel.pdBankN)
      .replace('*5*', d.ckBank)
      .replace('*6*', d.ckNoeN)
      .replace('*7*', this.myModel.dateA_S)
      .replace('*8*', d.ckDate_S)
      .replace('*9*', d.saheb)
      .replace('*13*', d.ckDate_S)
      .replace('*15*', d.dateB_S)
      .replace('*16*', d.pozTar)
      .replace('*17*', d.pdBankN)
      .replace('*18*', d.sayadi)
      .replace('*19*', d.pdBankInN)
      .replace('null', '');



  }
  onKeyDownDis(e, cellInfo) {


    if (e.event.key == 'Insert') {

      cellInfo.data.dis = this.generateDis(cellInfo.data);

    }
    //  this.mypressMovekey(e.event);
  }


  onValueChangeckCode(e, cellInfo) {


    cellInfo.data.ckCode = e.value;

  }


  gwname = 'gw4001';

  saveuioption() {
    //ghh555566667777
    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli[this.gwname] = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }

  addwidth(e) {
    for (var i = 0; i < this.localst.optioncli[this.gwname].length; i++) {
      this.localst.optioncli[this.gwname][i] *= e;
    }
  }


  defwidth() {
    this.localst.optioncli[this.gwname] = this.localst.optionClient_readDefault(this.gwname);
  }

  sumAll: number = 0;
  calcsumAll() {

    let sum = this.myModel.rows.reduce((sum, current) => sum + current.prc, 0);
 

    this.sumAll = sum;

  }




  onClickDeleterow() {

    //کلید ای دی های انتخاب شده
    var rowkersselected = this.dataGrid.selectedRowKeys;
    // console.log(JSON.stringify(rowkersselected));

    if (rowkersselected == null)
      rowkersselected = [];

    let fff = this.dataGrid.focusedRowKey;
    rowkersselected.push(fff);
    let afterindex = -1;


    if (rowkersselected != null )
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.myModel.rows.length; j++) {
          if (rowkersselected[i] == this.myModel.rows[j].id) {
            this.myModel.rows.splice(j, 1);
            afterindex = j - 1;
            break;
          }
        }
      }

    this.onClicktab(this.tabIndex);
    this.refreshradifnumber();
   // this.onClicktab(this.tabIndex);
   // this.pushnewrow(false);

  }
  onClickinsertrow() {

    let Rowempty = this.pdPayrecService.getPdPayRecRowEmpty();
    this.lastrowidtemplate--;
    Rowempty.id = this.lastrowidtemplate;
    var focuskey = this.dataGrid.focusedRowKey;

   
      for (var i = 0; i < this.myModel.rows.filter(x => x.kindRow == this.tabIndex).length; i++) {
        if (focuskey == this.myModel.rows[i].id) {
          this.myModel.rows.splice(i, 0, Rowempty);
     
        }
      }
 

    this.refreshradifnumber();


  }


  onClickcopyrow() {

    let data = [];
    const clipboardkey = 'PdPayrec' + this.kindpage + 'k' + this.tabIndex;

    var rowkersselected = this.dataGrid.selectedRowKeys;
  
      for (var i = 0; i < rowkersselected.length; i++) {
        for (var j = 0; j < this.myModel.rows.filter(x => x.kindRow == this.tabIndex).length; j++) {
          if (this.myModel.rows[j].id == rowkersselected[i]) {
            data.push(this.myModel.rows[j]);
          }
        }
      }

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));

    this.localst.clipboard_set(clipboardkey, data);
    this.alertify.warning('رونوشت صورت گرفت');
    this.dataGrid.instance.clearSelection();

  }


  onClickcutrow() {
    let data = [];


    var rowkersselected = this.dataGrid.selectedRowKeys;
    const clipboardkey = 'PdPayrec' + this.kindpage +'k'+this.tabIndex ;

    if (rowkersselected != null && this.tabIndex == 1) {
      for (var i = 0; i < rowkersselected.length; i++) {

        for (var j = 0; j < this.myModel.rows.filter(x => x.kindRow == this.tabIndex).length; j++) {
          if (this.myModel.rows[j].id == rowkersselected[i]) {
            data.push(this.myModel.rows[j]);
            break;
          }
        }
      }
   
    }
    

    data = data.sort((a, b) => (a.radif > b.radif ? -1 : 1));
    this.localst.clipboard_set(clipboardkey, data);
    this.alertify.warning('برش صورت گرفت');
    this.onClickDeleterow();
    this.dataGrid.instance.clearSelection();

  }
  onClickpaserow() {
   
    const clipboardkey = 'PdPayrec' + this.kindpage + 'k' + this.tabIndex;

    var data = this.localst.clipboard_get(clipboardkey);
    if (data == null) {

      this.alertify.warning('رونوشتی یافت نشد');
      return;
    }



    let nextid = this.getnextrowid();
 
      for (var i = 0; i < this.myModel.rows.length; i++) {
        if (this.selectedrow.id == this.myModel.rows[i].id) {
          //ردیف مورد نظر را پیدا میکنیم
          //ردیف های کپی شده را جایگزاری میکنیم
          for (var k = 0; k < data.length; k++) {
            this.lastrowidtemplate--;
            data[k].id = this.lastrowidtemplate;
            this.myModel.rows.splice(i, 0, data[k]);
          }
          this.alertify.warning('ردیف جایگذاری شد');
          break;
        }

      }
 
   


    this.refreshradifnumber();

  }
  getnextrowid(): number {

    let nextid = -2000000011;
    for (var j = 0; j < this.myModel.rows.length; j++) {
      if (this.myModel.rows[j].id > nextid) {
        nextid = this.myModel.rows[j].id;
      }
    }
    
    nextid++;
    return nextid;
  }

  onValueChangeSayadi(e, cellInfo) {


    cellInfo.data.sayadi = e.value;
  }
  onValueChangeSaheb(e, cellInfo) {


    cellInfo.data.saheb = e.value;
  }
  onValueChangepozTar(e, cellInfo) {


    cellInfo.data.pozTar = e.value;
  }

  filterpdHes(kindRow: number, ckNoe: number | null, mahal : number ) {
    //5h45u568i67
    if (this.kindpage == 10) {

      //نقد
      if (kindRow == 1)
        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 301);
        //چک
      else if (kindRow == 2) {
        if (ckNoe ==1)
          this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 107);
        if (ckNoe == 2)
          this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 101);
        if (ckNoe == 3)
          this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 108);
      }
      //سفته
      else if (kindRow == 3) {
        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 401);
     
      }
      else if (kindRow == 4) {
        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 501);

      }
      else if (kindRow == 5) {
        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 801);

      }
    }
    else if (this.kindpage == -10) {
        ///  1  روز
        ///  2   مدت دار
        ///  3  تضمینی

      if (kindRow == 1)
        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 601);
      //چک
      if (kindRow == 2) {
  
        if (mahal == 11) {
          if (ckNoe == 1)
            this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 222);
          if (ckNoe == 2)
            this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 211);
          if (ckNoe == 3)
            this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 233);
        }
        else if (mahal == 12) {
          this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 212);
        }
        //{ id: 11, name: 'خارجی' },
        //{ id: 12, name: 'داخلی' },
      }
      if (kindRow == 3) {

        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 701);
      }
      if (kindRow == 4) {

        this.pdHesFilteredlist = this.pdHeslist.filter(x => x.pdMoveKind == 651);
      }


    }



   
  }
  mych_displayExpr(item) {
    return item && item.seri + '-' + item.paperC;
  }

  openPrintRow: boolean = false;
  pdPayrecRowIdprint: number = null;
  onPrintRow(e) {


    if (e.data.id  > 0) {
      this.pdPayrecRowIdprint = e.data.id;
      this.openPrintRow = true;
    }



  }

  onClickPrint() {

    if (this.editId > 0) {

      let data: number[] = [this.editId];
     
      //7yu5467564
      this.onPrintEvent.emit(data);
    }

  }
}
