import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { Localst } from './localst.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { MyTimeClass } from '../_models/General/myTimeClass';
import { HttpClient } from '@angular/common/http';
 

let EmptyMyTimeClass: MyTimeClass = {

  dateInt: 13500101,
  dateString: '1350/01/01',
  speach: '',
  month: 0,
  daysInMonth:0

};
@Injectable({
  providedIn: 'root'
})
export class MyTimeService {
 // baseUrl = environment.apiUrl;
  baseUrl = location.origin + '/api/';

  origin = location.origin;


  constructor(private localst: Localst,private localstService: Localst,
    private http: HttpClient) { }

  public isvalidday(date : any  ): string {

   //day = day + '/';

    date = date.toString();


    date = date.replace(/\/r/g, '');
     
    let dateint = +date;
    //if (dateint < 13000101)
    //  return false;

    //if (dateint < 19990101)
    //  return false;

    let year = +date.substring(0, 4);
    let mont = +date.substring(4, 6);
    let day = +date.substring(6, 8);


    let ddd = 'تاریخ معتبر نیسن';
    //console.log('fffffffffffffff: ' + date);
    //console.log('year: ' + year);
    //console.log('monbth: ' + mont);
    //console.log('day: ' + day);
 
    if (year > 1500)
      return ddd;

    if (year < 1300)
      return ddd;


    if (mont > 12) {
      return ddd;
    }
    else if (mont <= 6) {

      if (day > 31)
        return ddd;

    }
    else if (mont > 6 && mont <12) {

      if (day > 30)
        return ddd;

    }
    else if (mont == 12) {

      if (day > 30)
        return ddd;
      //؟؟؟؟
    }



   let sal= this.localst.yearnow_Class_get();



    if (sal.dateS > dateint) {


      return 'تاریخ وارد شده کوچکتر از اولین روز سال مالی جاری است';
    }
   
    if (sal.dateE < dateint) {


      return 'تاریخ وارد شده بزرگتر از اخرین روز سال مالی جاری است';
    }



    return null;

    //int[] matches = { 1, 5, 9, 13, 17, 22, 26, 30 };
    //int modulus = y - ((y / 33) * 33);
    //bool K = false;
    //for (int n = 0; n != 8; n++) if (matches[n] == modulus) K = true;
    //return K;

  }


  public getnowAsShamsiString(spliter: string) {

   // var d = new Date();
  
   // console.log('mmdate ' + d.getDate.toString());
    let res = moment('19890124', 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');

    if (spliter != '/') {

      res=   res.replace('/', spliter).replace('/', spliter).replace('/', spliter).replace('/', spliter);

    }
     
    return res;
  }

  public addslashtostring(spliter: string) {

   

    let dend = spliter.replace('/', '').replace('/', '');
    if (dend.length > 7) {
      spliter = [dend.slice(0, 4), dend.slice(4, 6), dend.slice(6, 8)].join('/');
    }

    return spliter;
  }

  public addmonth(spliter: string, addmonth : number ) {



    let dend = spliter.replace('/', '').replace('/', '');
    if (dend.length > 7) {

      let m = +dend.slice(4, 6);
      m = m + addmonth;
      if (m < 0) m = 0;
      let s = m.toString();
      if (s.length == 1)
        s = '0' + s;

      spliter = [dend.slice(0, 4), s, dend.slice(6, 8)].join('/');
    }

    return spliter;
  }
  public getDateAsInt32(date: string) : number {

    date = date.toString();
    date = date.replace(/\/r/g, '');
    let dateint = +date;
    return dateint;
  }
  GetPersianNow(): Observable<MyTimeClass> {

    let apiadress = '/api/General/SaleMali/getnowtime';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<MyTimeClass>(this.origin + apiadress, { 'headers': headers });


  }
  GetEmptyPersianNow(): MyTimeClass {
 

    return JSON.parse(JSON.stringify(EmptyMyTimeClass));

  }
  getValueAsDatestring(e) {

    let d = e.value.replace('/', '').replace('/', '').toString() as string;

    if (d.length != 8)
      return null;

    return d.slice(0, 4) + '/' + d.slice(4, 6) + '/' + d.slice(6, 8);



  }
}
