import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { ShakhzService } from '../_services/app1/paye/Shakhz.service';
import { Shakhz } from '../_models/app1/paye/Shakhz';
import { Customer } from '../_models/customer';
import { Localst } from '../_services/localst.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registerMode = false;
  //@ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
 

  //gfhgfhfghf

  loading = false;
  model: any = {};
  photoUrl: string;
  cusherkatlist: Customer[];

  constructor(public authService: AuthService, private alertify: AlertifyService, private shakhzService: ShakhzService,
    private LocalstService: Localst,
    private router: Router) {
    this.cusherkatlist = [];
  }

  ngOnInit() {
    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
   // this.model.customerName = 'cu1';

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertify.message('logged out');

   // this.LocalstService.setinlogin(false);
    this.LocalstService.clear();
  }
 
  registerToggle() {
    this.registerMode = true;
  }

  cancelRegisterMode(registerMode: boolean) {
    this.registerMode = registerMode;
  }
  loggedIn() {
    //const token = localStorage.getItem('token');
    //  return !!token;
    return this.authService.loggedIn();
  }

  login() {
    this.loading = true;
    this.authService.login(this.model).subscribe(next  => {
 



     // //با رفلش صفحه از بین میرود
     //// this.LocalstService.setinlogin(true);
     // this.alertify.success('ورود به سیستم با موفقیت انجام شد');
     // //this.delay(3000).then(any => {
     // //  this.alertify.success('ورود به سیستم با موفقیت انجام شد');
     // //});
     // this.router.navigate(['/maintab']);
      this.loading = false;
    }, error => {
        this.loading = false;
      this.alertify.error('خطا در اتصال به سرور' );
    }, () => {
      //this.router.navigate(['/members']);

       // this.getsherkats();
    });
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }


  //getsherkats() {
  //  this.authService.GetSherkatlist(this.model.username, this.model.customerName).subscribe(data => {
  //    console.log('sherkat list loaded in successfully')



  //    this.cusherkatlist = data as Customer[];
  //    // console.log(JSON.stringify(this.cusherkatlist));

  //    this.LocalstService.sherkatsList_set(this.cusherkatlist);

  //    this.router.navigate(['/maintab']);

  //  }, error => {
  //    this.alertify.error(error);
  //  }, () => {


  //  });
  //}

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertify.message('logged out');
    this.router.navigate(['/home']);
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  test() {


    console.log('test');
    console.log(location.origin);
  
    this.shakhzService.getShakhzsnopage()
      .subscribe((res: Shakhz[]) => {
    
        console.log(JSON.stringify(res));
      }, error => {
        this.alertify.error(error);
      });
  


  }
  showlarm: boolean = true;
  close(){
    this.showlarm = false;
  }
}
