import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { Localst } from '../_services/localst.service';
import { User } from '../_models/user';
import { AuthService } from '../_services/auth.service';

 
 
/*var pkcs7 = require('pkcs7'), encrypted;*/

//var rs = require('jsrsasign');
// var rsu = require('jsrsasign-util');
// var pem = rsu.readFile('z1.prv.p5e.pem');
// var prvKey = rs.KEYUTIL.getKey(pem, 'passwd');
@Component({
  selector: 'appsetting-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.css']
})



export class SettingListComponent implements OnInit {

   

    

  continents: any[] = [
    {
    id: '1',
    text: 'عمومی',
    expanded: true,
      items: [

      {
      id: '1_1',
      text: 'مشخصات شرکت',
          compo:'appsetting-sherkatInfo-edit'
        },
        //{
        //  id: '1_2',
        //  text: 'کاربرها',
        //  fullName: 'Republic of South Africa',
        //  compo: 'app-user-list'
        //},
        {
          id: '1_3',
          text: 'دسترسی',
          compo: 'app-Acc-edit'
        },
        {
          id: '1_4',
          text: 'پشتیبان گیری',
          compo: 'app-backup-list'
        }



    ],
    },
    {
      id: '2',
      text: 'تنظیمات انبار',
      expanded: true,
      items: [

        {
          id: '2_1',
          text: 'اسناد انبار',
          compo: 'appsetting-registry-app2Code'
        }
   


      ],
    }
    ,
    {
      id: '3',
      text: 'تنظیمات فروش',
      expanded: true,
      items: [

        {
          id: '3_1',
          text: 'اسناد فروش',
          compo: 'appsetting-registry-app3Code'
        }
        ,
        {
          id: '3_2',
          text: 'سامانه مودیان',
          compo: 'appsetting-SherkatInfo-taxgov'
        }


      ],
    }
   ];









  nowuser: User;

  model: any = {};
  selectedcompo: string;

  constructor(private alertify: AlertifyService,
  
  
    private localst: Localst,
 
    private authService: AuthService,
    private router: Router) {
    this.selectedcompo = 'none';
  }

  ngOnInit() {

    this.nowuser = this.localst.usernow_get();
   
  }

 
  changeSelection(e) {

    const itemData = e.itemData;
    //gdfhreyurtu65
    this.selectedcompo = itemData.compo;
 




  }
 
  selectselector(e) {

    this.selectedcompo =e;

  }

   
}
