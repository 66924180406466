import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Input } from '@angular/core';
 
import { MyTimeService } from '../../../../_services/myTime.service';
import { MyTimeClass } from '../../../../_models/General/myTimeClass';
import { Localst } from '../../../../_services/localst.service';
import { LoadingService } from '../../../../_services/loading.service';
import { AnSellService } from '../../../../_services/app3/opr/AnSell.service';
import { AnSellRep, AnSellRepOne, AnSellRepOp } from '../../../../_models/app3/rep/AnSellRep';
 
import { ApiRet } from '../../../../_models/General/ApiRet';
import { GeneralService } from '../../../../_services/General/General.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PdMyCkService } from '../../../../_services/app4/paye/PdMyCk.service';
import { PdPayrecService } from '../../../../_services/app4/opr/PdPayrec.service';
import { PdPayrecRow, PdPayrecRowForPrint } from '../../../../_models/app4/opr/PdPayrec';






@Component({
  selector: 'app4-PdPayrecRow-ht',
  templateUrl: './PdPayrecRow-ht.component.html',
  styleUrls: ['./PdPayrecRow-ht.component.css']
})


export class PdPayrecRowHtComponent implements OnInit {


  @Input() inputdata: any;
  @Input() pdPayrecRowId: number;
 

  loaddata: loadingCl;
  pVeiw: any = { myinfo: true }
 
  posch: any = {
    sn0var: {

      margin1: 60,
      margin2: 0,
      margin3: 0,
      margin4: 0,
      margin: '60mm 0mm 0mm 0mm ',


      scale1: 1,
      transform: 'scale(1)',
      set() {
        this.transform = 'scale(' + this.scale1.toString() + ')';
        this.margin = this.margin1.toString() + 'mm ' + this.margin2.toString() + 'mm ' + this.margin3.toString() + 'mm ' + this.margin4.toString() + 'mm ';

        // this.transform = 'scale(' + this.scale1.toString() + ') translateX(' + this.translateX1.toString() + 'px) translateY(' + this.translateX1.toString() +'px)';

      }
    },
    sn1var: {

      height: 100,
      width: 200,

      top1: 57,
      right1: 95,
      top: '57mm',
      right: '95mm',
      font1: 12,
      font: '12pt "B Nazanin"',

      spacing1: 5,
      spacing: '5px',

      set() {
     
        this.font = this.font1.toString() + 'pt "B Nazanin"';
        this.spacing = this.spacing1.toString() + 'px';
        this.top = this.top1.toString() + 'mm';
        this.right = this.right1.toString() + 'mm';

      }
    },
    sn2var: {

      height: 100,
      width: 200,
      top1: 27,
      right1: 50,
      top: '27mm',
      right: '50mm',

      font1: 12,
      font: '12pt "B Nazanin"',

      spacing1: 1,
      spacing: '1px',

      set() {
        this.font = this.font1.toString() + 'pt "B Nazanin"';
        this.spacing = this.spacing1.toString() + 'px';

        this.top = this.top1.toString() + 'mm';
        this.right = this.right1.toString() + 'mm';
      }
    },
    sn3var: {

      height: 100,
      width: 200,
      top1: 38,
      right1: 20,
      top: '38mm',
      right: '20mm',

      font1: 12,
      font: '12pt "B Nazanin"',

      spacing1: 1,
      spacing: '1px',

      set() {
        this.font = this.font1.toString() + 'pt "B Nazanin"';
        this.spacing = this.spacing1.toString() + 'px';

        this.top = this.top1.toString() + 'mm';
        this.right = this.right1.toString() + 'mm';
      }
    },
      sn4var: {

      height: 100,
      width: 200,
      top1: 40,
      right1: 90,
      top: '40mm',
      right: '90mm',

      font1: 12,
      font: '12pt "B Nazanin"',

      spacing1: 1,
      spacing: '1px',

      set() {
        this.font = this.font1.toString() + 'pt "B Nazanin"';
        this.spacing = this.spacing1.toString() + 'px';

        this.top = this.top1.toString() + 'mm';
        this.right = this.right1.toString() + 'mm';
      }
    },
       sn5var: {

      height: 100,
      width: 200,
      top1: 11,
      right1: 32,
      top: '11mm',
      right: '32mm',

      font1: 12,
      font: '12pt "B Nazanin"',

      spacing1: 3,
      spacing: '3px',

      set() {
        this.font = this.font1.toString() + 'pt "B Nazanin"';
        this.spacing = this.spacing1.toString() + 'px';

        this.top = this.top1.toString() + 'mm';
        this.right = this.right1.toString() + 'mm';
      }
    }

  };


  model: PdPayrecRowForPrint;
 
  constructor(private userService: UserService, private alertify: AlertifyService,
    
    private generalfoService: GeneralService,
    private pdPayrecService: PdPayrecService,
    private sanitizer: DomSanitizer,
    private localst: Localst,
    private loadServ: LoadingService,
    private myTimeService: MyTimeService,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);
  
   
  
  }
  setMyStyles(){
    

  }
  onMouseEnter() {


  }
  
  onClickArrow(varname , arrow) {
    if (varname == 'sn0var') {

      if (arrow == 'right')
        this.posch.sn0var.margin4 += 1;
      if (arrow == 'left')
        this.posch.sn0var.margin4 -= 1;
      if (arrow == 'down')
        this.posch.sn0var.margin1 += 1;
      if (arrow == 'up')
        this.posch.sn0var.margin1 -= 1;
      if (arrow == 'scalein')
        this.posch.sn0var.scale1 += 0.01;
      if (arrow == 'scaleout')
        this.posch.sn0var.scale1 -= 0.01;


      this.posch.sn0var.set();
    }
    if (varname == 'sn1var' ) {

      if (arrow == 'right')
        this.posch.sn1var.right1 -= 1;
      if (arrow == 'left')
        this.posch.sn1var.right1 += 1;
      if (arrow == 'down')
        this.posch.sn1var.top1 += 1;
      if (arrow == 'up')
        this.posch.sn1var.top1 -= 1;
      if (arrow == 'plus')
        this.posch.sn1var.font1 += 1;
      if (arrow == 'unplus')
        this.posch.sn1var.font1 -= 1;
      if (arrow == 'addspace')
        this.posch.sn1var.spacing1 += 1;
      if (arrow == 'downspace')
        this.posch.sn1var.spacing1 -= 1;

      this.posch.sn1var.set();
    }
    if (varname == 'sn2var') {

      if (arrow == 'right')
        this.posch.sn2var.right1 -= 1;
      if (arrow == 'left')
        this.posch.sn2var.right1 += 1;
      if (arrow == 'down')
        this.posch.sn2var.top1 += 1;
      if (arrow == 'up')
        this.posch.sn2var.top1 -= 1;
      if (arrow == 'plus')
        this.posch.sn2var.font1 += 1;
      if (arrow == 'unplus')
        this.posch.sn2var.font1 -= 1;
      if (arrow == 'addspace')
        this.posch.sn2var.spacing1 += 1;
      if (arrow == 'downspace')
        this.posch.sn2var.spacing1 -= 1;

      this.posch.sn2var.set();
    }
    if (varname == 'sn3var') {

      if (arrow == 'right')
        this.posch.sn3var.right1 -= 1;
      if (arrow == 'left')
        this.posch.sn3var.right1 += 1;
      if (arrow == 'down')
        this.posch.sn3var.top1 += 1;
      if (arrow == 'up')
        this.posch.sn3var.top1 -= 1;
      if (arrow == 'plus')
        this.posch.sn3var.font1 += 1;
      if (arrow == 'unplus')
        this.posch.sn3var.font1 -= 1;
      if (arrow == 'addspace')
        this.posch.sn3var.spacing1 += 1;
      if (arrow == 'downspace')
        this.posch.sn3var.spacing1 -= 1;

      this.posch.sn3var.set();
    }
    if (varname == 'sn4var') {

      if (arrow == 'right')
        this.posch.sn4var.right1 -= 1;
      if (arrow == 'left')
        this.posch.sn4var.right1 += 1;
      if (arrow == 'down')
        this.posch.sn4var.top1 += 1;
      if (arrow == 'up')
        this.posch.sn4var.top1 -= 1;
      if (arrow == 'plus')
        this.posch.sn4var.font1 += 1;
      if (arrow == 'unplus')
        this.posch.sn4var.font1 -= 1;
      if (arrow == 'addspace')
        this.posch.sn4var.spacing1 += 1;
      if (arrow == 'downspace')
        this.posch.sn4var.spacing1 -= 1;

      this.posch.sn4var.set();
    }
 
    if (varname == 'sn5var') {

      if (arrow == 'right')
        this.posch.sn5var.right1 -= 1;
      if (arrow == 'left')
        this.posch.sn5var.right1 += 1;
      if (arrow == 'down')
        this.posch.sn5var.top1 += 1;
      if (arrow == 'up')
        this.posch.sn5var.top1 -= 1;
      if (arrow == 'plus')
        this.posch.sn5var.font1 += 1;
      if (arrow == 'unplus')
        this.posch.sn5var.font1 -= 1;
      if (arrow == 'addspace')
        this.posch.sn5var.spacing1 += 1;
      if (arrow == 'downspace')
        this.posch.sn5var.spacing1 -= 1;

      this.posch.sn5var.set();
    }



  }

  ngOnInit() {

    this.loadServ.showloading(this.loaddata, 0);
    this.pdPayrecService.GetPdPayrecRowForPrint(this.pdPayrecRowId).subscribe((res: ApiRet) => {
      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }
      this.model = res.data;
      //this.model.prc
     // this.model.ck
      this.loadServ.hideloading(this.loaddata, 0);
  
    }, error => {
      this.loadServ.hideloading(this.loaddata,0);
      this.alertify.error(error);
    });




  }


  onClickprint() {


    var d = document.getElementById("d");

    console.log(d.outerHTML);

    //var ttt = document.getElementById("tbtb");
   // console.log(ttt.offsetHeight);

    let printContents, popupWin;
    popupWin = window.open('', '_blank');
    popupWin.document.open();

    this.pVeiw.vertic = false;

    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
 
        body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "B Nazanin";
  font-weight: 400;
  direction:ltr;
  float:left;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}


.page {
  font: 12pt "B Nazanin";
  font-weight: 400;
  height: 205mm;
  width: 292mm;
  padding: 0mm;
  margin: 0mm auto;
  border: 1px #ff6a00 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}



.cheksize {
  height: 85mm;
  width: 170mm;
  /*transform: scale(0.4);*/
  border: 1px dashed;
  float: left;
  margin: 60mm 200mm 0mm 0mm;
  
 
}
 
 


.ckshow {
  position: absolute;
  outline: 1px none;
  /*border: 1px solid;*/
  height: 15mm;
  width: 170mm;
  /*transform: scale(0.4);*/

  float: left;
  margin-top: 40mm;
 
}
  .ckshow:hover {
    outline: 1px dashed;
  }






#navshow1 {
  position: absolute;
  display: none;
  visibility: hidden;
 margin-top:0mm;
 margin-right:-30mm;
 
  /*border: 1px solid;*/
}
#textdash1 {
  position: absolute;
  outline: 1px none;
  text-align: right;
  width: auto;
  display: inline-block;
}
  #textdash1:hover {
  
    outline: 1px dashed;
  }
  #textdash1:hover #navshow1 {
    display: block;
    visibility: visible;
  }



#navshow2 {
  position: absolute;
  display: none;
  visibility: hidden;
  margin-top: 0mm;
  margin-right: -30mm;
  /*border: 1px solid;*/
}

#textdash2 {
  position: absolute;
  outline: 1px none;
  text-align: right;
  width: auto;
  display: inline-block;
}

  #textdash2:hover {
    outline: 1px dashed;
  }

    #textdash2:hover #navshow2 {
      display: block;
      visibility: visible;
    }




#navshow3 {
  position: absolute;
  display: none;
  visibility: hidden;
  margin-top: 0mm;
  margin-right: -30mm;
  /*border: 1px solid;*/
}

#textdash3 {
  position: absolute;
  outline: 1px none;
  text-align: right;
  width: auto;
  display: inline-block;
}

  #textdash3:hover {
    outline: 1px dashed;
  }

    #textdash3:hover #navshow3 {
      display: block;
      visibility: visible;
    }





#navshow4 {
  position: absolute;
  display: none;
  visibility: hidden;
  margin-top: 0mm;
  margin-right: -30mm;
  /*border: 1px solid;*/
}

#textdash4 {
  position: absolute;
  outline: 1px none;
  text-align: right;
  width: auto;
  display: inline-block;
}

  #textdash4:hover {
    outline: 1px dashed;
  }

    #textdash4:hover #navshow4 {
      display: block;
      visibility: visible;
    }





#navshow5 {
  position: absolute;
  display: none;
  visibility: hidden;
  margin-top: 0mm;
  margin-right: -30mm;
  /*border: 1px solid;*/
}

#textdash5 {
  position: absolute;
  outline: 1px none;
  text-align: right;
  width: auto;
  display: inline-block;
}

  #textdash5:hover {
    outline: 1px dashed;
  }

    #textdash5:hover #navshow5 {
      display: block;
      visibility: visible;
    }








    
@page {
  size: A4 landscape;
  margin: 0;
}

@media print {
  html, body {
    width: 292mm;
    height: 205mm;
  }

  div.centerOnPrintedPage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  /* ... the rest of the rules ... */

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "B Nazanin";
    font-weight: 400;
    direction: ltr;
    float: left;
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }


  .page {
    font: 12pt "B Nazanin";
    font-weight: 400;
    height: 205mm;
    width: 292mm;
    padding: 0mm;
    margin: 0mm auto;
    border: 1px #ff6a00 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }



  .cheksize {
    height: 85mm;
    width: 170mm;
    /*transform: scale(0.4);*/
    border: 1px dashed;
    float: left;
    margin: 60mm 200mm 0mm 0mm;
   
  }




  .ckshow {
    position: absolute;
    outline: 1px none;
    /*border: 1px solid;*/
    height: 15mm;
    width: 170mm;
    /*transform: scale(0.4);*/

    float: left;
    margin-top: 40mm;
  }

    .ckshow:hover {
      outline: 1px dashed;
    }






  #navshow1 {
    position: absolute;
    display: none;
    visibility: hidden;
    margin-top: 0mm;
    margin-right: -30mm;
    /*border: 1px solid;*/
  }

  #textdash1 {
    position: absolute;
    outline: 1px none;
    text-align: right;
    width: auto;
    display: inline-block;
  }

    #textdash1:hover {
      outline: 1px dashed;
    }

      #textdash1:hover #navshow1 {
        display: block;
        visibility: visible;
      }



  #navshow2 {
    position: absolute;
    display: none;
    visibility: hidden;
    margin-top: 0mm;
    margin-right: -30mm;
    /*border: 1px solid;*/
  }

  #textdash2 {
    position: absolute;
    outline: 1px none;
    text-align: right;
    width: auto;
    display: inline-block;
  }

    #textdash2:hover {
      outline: 1px dashed;
    }

      #textdash2:hover #navshow2 {
        display: block;
        visibility: visible;
      }




  #navshow3 {
    position: absolute;
    display: none;
    visibility: hidden;
    margin-top: 0mm;
    margin-right: -30mm;
    /*border: 1px solid;*/
  }

  #textdash3 {
    position: absolute;
    outline: 1px none;
    text-align: right;
    width: auto;
    display: inline-block;
  }

    #textdash3:hover {
      outline: 1px dashed;
    }

      #textdash3:hover #navshow3 {
        display: block;
        visibility: visible;
      }





  #navshow4 {
    position: absolute;
    display: none;
    visibility: hidden;
    margin-top: 0mm;
    margin-right: -30mm;
    /*border: 1px solid;*/
  }

  #textdash4 {
    position: absolute;
    outline: 1px none;
    text-align: right;
    width: auto;
    display: inline-block;
  }

    #textdash4:hover {
      outline: 1px dashed;
    }

      #textdash4:hover #navshow4 {
        display: block;
        visibility: visible;
      }





  #navshow5 {
    position: absolute;
    display: none;
    visibility: hidden;
    margin-top: 0mm;
    margin-right: -30mm;
    /*border: 1px solid;*/
  }

  #textdash5 {
    position: absolute;
    outline: 1px none;
    text-align: right;
    width: auto;
    display: inline-block;
  }

    #textdash5:hover {
      outline: 1px dashed;
    }

      #textdash5:hover #navshow5 {
        display: block;
        visibility: visible;
      }



}

     </style>

        </head>
      <body onload="window.print();window.close()">${d.innerHTML}</body>
      </html>`
    );




    popupWin.document.close();



  }
}
