import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxDataGridComponent, DxTreeListComponent } from 'devextreme-angular';
 
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
import { IdName } from '../../../../_models/General/IdName';
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { parse } from 'querystring';
import { LoadingService } from '../../../../_services/loading.service';
import { GhardeshTafsiliRep } from '../../../../_models/app1/rep/ghardeshTafsiliRep';
import { ReportApp1Service } from '../../../../_services/app1/rep/ReportApp1.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { Tafsili } from '../../../../_models/app1/paye/tafsili';
import { ApiRet } from '../../../../_models/General/ApiRet';

 



@Component({
  selector: 'app-ghardeshTafsili-list',
  templateUrl: './ghardeshTafsili-list.component.html',
  styleUrls: ['./ghardeshTafsili-list.component.css']
})
  

export class ghardeshTafsiliListComponent implements OnInit {

  openprint: boolean;
  @ViewChild("dxgrid", { static: false }) dxgrid: DxTreeListComponent;
  openedit: boolean;
  openeditId: number;
  model: GhardeshTafsiliRep;

 
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() OnMyprint = new EventEmitter<any>();
  //حالت امکان صدور ثبت
  @Input() InActionMode: boolean;
  salemaliMin: number;
  salemaliMax: number;
  tafsililist: Tafsili[];
 
  valdata: DialogData;
 
 
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};
  focusedRowKey: number;
  bls1: string;
  bls2: string;
  bls3: string;
  bls4: string;
  bls5: string;
  bls6: string;
 
  selectedlevelold: number;
  selectedlevelnew: number;
  selectedlevelDBClicknew: number;
  //animal: string;
  //name: string;
  isdelete: string;
  kindhesab: IdName[] = [
    { id: -1, name: "(همه)" },
    { id: 1, name: "دائمی" },
    { id: 2, name: "موقت" },
    { id: 3, name: "انتظامی" },
  ];
  mahiyatdb: IdName[] = [
    { id: -1, name: "(همه)" },
    { id: 1, name: "بدهکار" },
    { id: 2, name: "بستانکار" },
    { id: 3, name: "بدهکار بستانکار" },
  ];

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private MorurService: MorurService,
    private reportapp1Service: ReportApp1Service,
    private tafsiliServicee: TafsiliService,
    private route: ActivatedRoute,
 
    private localst: Localst,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);


    this.openprint = false;
    this.bls1 = 'btn btn-light btndef';
    this.bls2 = 'btn btn-light btndef';
    this.bls3 = 'btn btn-light btndef';
    this.bls4 = 'btn btn-light btndef';
    this.bls5 = 'btn btn-light btndef';
    this.bls6 = 'btn btn-light btndef';
    this.selectedlevelold = -2;
    this.selectedlevelnew = -1;
    this.selectedlevelDBClicknew = -1;
    this.focusedRowKey = -1;
    this.selectchanginuse = false;





    this.model = new GhardeshTafsiliRep();

      let y = this.localst.yearnow_Class_get();
      this.model.option.yearA = y.codeSal;
      this.model.option.yearB = y.codeSal;
       this.model.option.dateA_S = y.dateS_s;
       this.model.option.dateB_S = y.dateE_s;
   
 

  }

  ngOnInit() {



    this.resetFilters();
    this.isdelete = "no";
    this.salemaliMin= 1390;
    this.salemaliMax = 1444;


    this.loadServ.showloading(this.loaddata, 3);
    this.tafsiliServicee.getTafsilis().subscribe((data) => {

      this.loadServ.hideloading(this.loaddata, 3);
      this.tafsililist = data;

      //  console.log(JSON.stringify(this.model));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  ngOnChanges(changes: any) {

 
    this.loadghardeshTafsili();
  }
  
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = ''; 
    this.shakhsParams.Codmelli = '';
 
  }
 

 

  onFocusedRowChanged(e) {

   
//    this.selectedrow = e.row.data.id;


   // console.log(this.focusedRowKey)
    //for (var _i = 0; _i < this.modlelist.length; _i++) {
    //  var num = numbers[_i];
    //  console.log(num);
    //}

  //  console.log(JSON.stringify(this.selectedrow));

 
  }
  onRowDblClick(e) {

    if (e.key == null)
      return;

    let sabtid = e.key;
     
    if (this.model.rows == null)
      return;

    this.gotoghardesh(sabtid);

  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {



    //  return;
    //}



    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    this.bls1 = this.bls1.replace(' btnbk', '');
    this.bls2 = this.bls2.replace(' btnbk', '');
    this.bls3 = this.bls3.replace(' btnbk', '');
    this.bls4 = this.bls4.replace(' btnbk', '');
    this.bls5 = this.bls5.replace(' btnbk', '');
    this.bls6 = this.bls6.replace(' btnbk', '');


  
    if (level == 1)
      this.bls1 += ' btnbk';
   else  if (level == 2)
      this.bls2 += ' btnbk';
    else  if (level == 3)
      this.bls3 += ' btnbk';
    else if (level == 4)
      this.bls4 += ' btnbk';
    else if (level == 5)
      this.bls5 += ' btnbk';
    else if (level == 6)
      this.bls6 += ' btnbk';


 
    //this.loadghardeshTafsiliSub(

    //  this.selectedrow.hesOrTafId,


    //);

    if (level != this.selectedlevelnew) {
      this.selectedlevelold = this.selectedlevelnew;
    }
    this.selectedlevelnew = level;
    console.log('old ' + this.selectedlevelold + 'new' + this.selectedlevelnew);



    if (this.getselectedrow() == null) {

      this.model.rows = [];

    }
    else  {
      //this.loadghardeshTafsiliSub(
      //  this.selectedlevelold,
      //  this.getselectedrow().hesOrTafId,
      //  this.selectedlevelnew
      //);
    }

  }


 
  loadghardeshTafsili() {

    if (this.model == null)
      return;

    if (this.model.option.tafId == null) {
      this.alertify.warning('عنوان تفصیلی انتخاب نشده');
      return;
    }
     
      //fhgfhgggg6

 //   console.log(JSON.stringify(this.model));
    this.loadServ.showloading(this.loaddata, 0);
   // console.log(JSON.stringify(this.model));
    this.reportapp1Service.GhardeshTafsiliRep(this.model.option).subscribe((res :ApiRet) => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.model = res.data;
      
      for (var u of this.model.rows) {
        this.dxgrid.instance.expandRow(u.id);
      }


     
      //tgurtu4575468568
    //  console.log(JSON.stringify(this.model));

    }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  } 
  mmm: any[];
   
  mtemp: GhardeshTafsiliRep;
  getselectedrow(): GhardeshTafsiliRep {
 

    var r = this.dxgrid.focusedRowKey;
    if (r == null) return null;
    if (this.model.rows == null) return null;

   // console.log(this.focusedRowKey)
    for (var _i = 0; _i < this.model.rows.length; _i++) {

      //if (this.focusedRowKey == this.modlelist[_i].id) {
         

      //  return this.modlelist[_i];
      //}
 
    }

    return null;


  }
  test() {

    var para = document.createElement('p');
    var title = document.createElement('title');

    // define some window attributes
    var features = 'width=1200, height=900, status=1, menubar=1, location=0, left=100, top=100';
    var winName = 'print';

    // populate the html elements
    para.textContent = '<p>fghjfgjghkjgh</p>';
    title.textContent = 'New Window Title';
     


    var winRef = window.open('', winName, features);


  
   // winRef.document.write(this.ghardeshTafsiliService.getprint(this.modlelist));








  }
  ontafchanged(e) {

    //fghgfht6657568568
    e.data;


  }
  onClickghardesh() {

    var item = this.dxgrid.selectedRowKeys;

    if (item == null)
      return;



    for (var i = 0; i < item.length; i++) {

      this.gotoghardesh(i);
    }


 


  }

  gotoghardesh(rowidkey : number) {


    var option = { "HPSanadId": -1, "HPSabtId": -1, "HPOpenOnInit": false };
    for (var i = 0; i < this.model.rows.length; i++) {

      if (this.model.rows[i].id == rowidkey) {

        //console.log(JSON.stringify(this.modlelist[i]));
       // var option = { "HPSanadId": this.modlelist[i].sanadId, "HPSabtId": this.modlelist[i].id, "HPOpenOnInit": true };
        //sanadid = this.modlelist[i].sanadId;

        break;
      }

    }


    if (option.HPSanadId != -1)
      this.OnMyLoadSanadEvent.emit(option);



  }
  onClickReflesh() {

    this.loadghardeshTafsili();
  }

  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' گرردش تفصیلی ');

  }
 
  onClickprint(kind) {


  //  let v = this.model.rows.find((x) => x.isSelect==true);

    var mdata = { model: JSON.parse(JSON.stringify(this.model)), printKind: kind }
 

    this.OnMyprint.emit(mdata);

  }
  onCkChanged(e, item) {
    //tyut6i67
   // item.data.isSelect = e.value;
    for (var i = 0; i < this.model.rows.length; i++) {

      if (this.model.rows[i].id == item.data.id) {
        this.model.rows[i].isSelect = e.value;
      }

    }


  }

  onClickDoAutoSanad() {

    let mess = 'از صدور سند تعدیل ماهیت اخر دوره اطمینان دارید؟';
    this.valdata = { title: mess, rows: [' '], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: true, datastring: null, styleMode: 0 }


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1500';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {
     // console.log('m:The dialog was closed');
    

      if (result != null) {

        let dialogdata = result;
        //console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

          this.createTadilSanad();

        }
      }
    });


  }
 
  modelTemp: GhardeshTafsiliRep;
  createTadilSanad() {
    console.log('createTadilSanad');

    var keys = this.dxgrid.selectedRowKeys;
    if (keys == null)
      return;


  //  console.log(JSON.stringify(keys));

   this.modelTemp = (JSON.parse(JSON.stringify(this.model)));
    this.modelTemp.rows = [];
    
      
 }

   

 


 

  errortadilhesabshow(row : string[]) {

    let mess = 'سطوح حساب تعدیل ماهیت اخر دوره در موارد زیر همخوانی ندارد';
    this.valdata = { title: mess, rows: row, valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1500';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {
      // console.log('m:The dialog was closed');


      if (result != null) {

        let dialogdata = result;
        //console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

   

        }
      }
    });


  }

  RowPrepared(e) {

    if (e.key < 0) {
      /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }

    //e.rowElement.css('background', 'green');
  }
  selectchanginuse: boolean;
  onSelectionChanged(e) {

    //if (this.selectchanginuse == true)
    //  return;

    //this.selectchanginuse = true;

    //var keys = this.dxgrid.selectedRowKeys;
    //var keysAccept : Number[] = [];
    //if (keys == null)
    //  return;


    ////اگر کلیدی داشتیم که مجوز بستن نداشت ان را از حالت انتخاب در میس او ریم
    //for (var k = 0; k < keys.length; k++) {

    //  for (var r = 0; r < this.model.rows.length; r++) {

    //    if (this.model.rows[r].id == keys[k]) {

    //      if (this.model.rows[r].correct == true) {
    //        keysAccept.push(keys[k]);
           
    //      }

    //    }


    //  }

    //}
    //console.log(JSON.stringify(keysAccept));
    //this.dxgrid.instance.clearSelection();
    //this.dxgrid.instance.selectRows(keysAccept, true);

    //this.selectchanginuse = false;


  }
  datestartchang(e) {



  }
  onFocusInDateS(e) {

  }

  saveuioption() {
    //ghh555566667777
   
      var res = [];
      for (var i = 0; i < this.dxgrid.instance.columnCount(); i++) {
        var w = this.dxgrid.instance.columnOption(i).width;
        if (w != undefined)
          res.push(w);
        else res.push(0);
      }
      this.localst.optioncli.gw1017 = res;
      this.localst.optionClient_save();
      this.alertify.message('تغییرات نما ذخیره شد');
  
  }


  addwidth(e) {
 
    for (var i = 0; i < this.localst.optioncli.gw1017.length; i++) {
      this.localst.optioncli.gw1017[i] *= e;
      }
   
  }
  defwidth() {
  
    this.localst.optioncli.gw1017 = this.localst.optionClient_readDefault('gw1017');
  
  }

} 


