 

export class AnSell {
  id: number;
  code: number;
  dateA: number;
  dateA_S: string;
  vaz: number;
  _VazN: string;
  anSellId: number | null;
  kind: number;
  kindN: string;
  kindAtfId: number | null;
  kindAtfN: string;
  anbarId: number;
  anbarN: string;
  mabna: number;
  mabnaAnDeed: number;
  hesabId: number | null;
  hesabC: string;
  hesabN: string;
  taf4Id: number | null;
  taf4C: string;
  taf4N: string;
  taf5Id: number | null;
  taf5C: string;
  taf5N: string;
  taf6Id: number | null;
  taf6C: string;
  taf6N: string;
  elecCode: string;
  dis: string;
  rows: AnSellRow[];
  afs: AnSellAf[];
  barname: AnSellBar;
  fi: number;
  prc: number;
  prcTkh: number;
  prctHaz: number;
  prc9: number;
  prc10: number;
  prc11: number;
  years: number;
  uSa: string;
  uSaF: string;
  dateLog: string;
  uSaE: string;
  uSaEF: string;
  dateELog: Date;
  ctn: number;
  more: any;
  //ای دی سند متقابل انبار اگر حالت یک به یک و ماتوماتیک صدور سند انبار فعال بود
  anDeedId: number | null;
  sanadId: number | null;
  _CAtf: number | null;
  setm: number;
  taxVaz: number;
  taxErD: string;
  taxVazN: string;
  taxIdShow: string;
  taxDateP: string;
  taxIdEdit: string;
  ins: number;
  insN: string;
  pdPayrecId: number | null;


  prcTkhAll: number | null;
  prcTkhDrsdAll: number | null;
  anSellIdPish: number | null;
  anSellIdPishCode: number | null;

  isMabnaAllY: boolean;

  constructor() {
    this.id= -1;
      this.code= null;
    this.dateA= 0;
    this.dateA_S= null;
    this.vaz= -1;
    this.anSellId= null;
    this._VazN= 'ثبت نشده';
    this.kind= null;
    this.kindN= null;
    this.kindAtfId= null;
    this.kindAtfN= null;
    this.anbarId= null;
    this.anbarN= null;

                              //بدون مبنا یا عطف
                              //با مبنا یا عطف
    this.mabna= 0;
    this.mabnaAnDeed= 0;
    this.hesabId= null;
    this.hesabC= null;
    this.hesabN= null;
    this.taf4Id= null;
    this.taf4C= null;
    this.taf4N= null;
    this.taf5Id= null;
    this.taf5C= null;
    this.taf5N= null;
    this.taf6Id= null;
    this.taf6C= null;
    this.taf6N= null;
    this.elecCode= null;
    this.dis= null;
    this.rows= [];
    this.afs= [];
    this.barname = new AnSellBar();
    this.fi= 0;
    this.prc= 0;
    this.prcTkh= 0;
    this.prctHaz= 0;
    this.prc9= 0;
    this.prc10= 0;
    this.prc11= 0;
    this.years= 0;
    this.uSa= null;
    this.uSaF= null;
    this.uSaE= null;
    this.uSaEF= null;
    this.dateELog= null;
    this.dateLog= '';
    this.ctn= 0;
    this.anDeedId= null;
    this.sanadId= null;
    this.more= null;
    this._CAtf= 0;
    this.setm= null;
    this.taxVaz= 0;
    this.taxErD= null;
    this.taxVazN= null;
    this.taxIdShow= null;
    this.taxDateP= null;
    this.taxIdEdit= null;
    this.ins= 1;
    this.insN= null;
    this.pdPayrecId= null;
    this.prcTkhAll= 0;
    this.prcTkhDrsdAll= 0;
    this.anSellIdPish= null;
    this.anSellIdPishCode= null;
    this.isMabnaAllY = false;

    this.afs = [];

    this.afs.push(new AnSellAf( {  id: -2, isUse: true, isM: false, anSellId: 0, act: true, titC: 206, tit: 'پیش دریافت', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی ', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null  }))
    this.afs.push(new AnSellAf({ id: -3, isUse: true, isM: false, anSellId: 0, act: true, titC: 207, tit: 'سپرده بیمه', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null }));
    this.afs.push(new AnSellAf({ id: -6, isUse: true, isM: false, anSellId: 0, act: true, titC: 208, tit: 'سپرده حسن انجام کار', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null }));
    this.afs.push(new AnSellAf({ id: -7, isUse: true, isM: false, anSellId: 0, act: true, titC: 209, tit: 'مالیات تکلیفی', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null }));


    //{ id: -2, isUse: true, isM: false, anSellId: 0, act: true, titC: 206, tit: 'پیش دریافت', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی ', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
    //{ id: -3, isUse: true, isM: false, anSellId: 0, act: true, titC: 207, tit: 'سپرده بیمه', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
    //{ id: -6, isUse: true, isM: false, anSellId: 0, act: true, titC: 208, tit: 'سپرده حسن انجام کار', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },
    //{ id: -7, isUse: true, isM: false, anSellId: 0, act: true, titC: 209, tit: 'مالیات تکلیفی', _IsDar: false, _IsPrc: true, kindId: 2, kindN: 'تسهیم به بطور مساوی', darsad: 0, prc: 0, hesabId: 0, hesabN: null, tafId: 0, dis: null },


 

  }
}

export interface AnSellKinds {
  id: number;
  name: string;
  atfKindId: number | null;
  atfKindName: string;
}

 

export class AnSellBar {
  id: number;
  shomare: string;
  date: string;
  feres: string;
  giran: string;
  tafHamlId: number | null;
  tafHamlN: string;
  ran: string;
  ranmeli: string;
  rangovah: string;
  girpost: string;
  ferpost: string;
  shCar: string;
  kindCar: string;
  ranCart: string;
  mahmu: string;
  kindBox: string;
  tedadBas: string;
  barA: string;
  barB: string;
  vaznP: string;
  vaznKhali: string;
  vaznKhales: string;
  cost: number;

  anSellId: number;
  //anSell: AnSell;


  constructor() {
    this.id= -1;
    this.shomare= null;
    this.date= null;
    this.feres= null;
    this.giran= null;
    this.tafHamlId= null;
    this.tafHamlN= null;
    this.ran= null;
    this.ranmeli= null;
    this.rangovah= null;
    this.girpost= null;
    this.ferpost= null;
    this.shCar= null;
    this.kindCar= null;
    this.ranCart= null;
    this.mahmu= null;
    this.kindBox= null;
    this.tedadBas= null;
    this.barA= null;
    this.barB= null;
    this.vaznP= null;
    this.vaznKhali= null;
    this.vaznKhales= null;
    this.cost= 0;
    this.anSellId= 0;
  }
}


export class AnSellRow {
  id: number;
  kind: number;
  opA: number | null;
  anSellId: number;
  anSellCode: number;
  anSellRowId: number | null;
  anSellRowCode: number | null;
  anbarId: number;
  dateA: number;
  years: number;
  vaz: number;
 
  kalaId: number;
  kalaN: string;
  kalaC: string;
  amt: number;
  amtB: number;
  amtDone: number;
  amtDoneB: number;
  vahBId: number;
  vahBN: string;
  vahId: number;
  vahN: string;
  zMulti: number;
  fi: number;
  prc: number;
  prcTkhDrsd: number;
  prcTkh: number;
  prcHazDrsd: number;
  prcHaz: number;
 
  prc9: number;
  prc10: number;
  prc11: number;

  darsadM: number;

  hesabId: number | null;
  hesabC: string;
  hesabN: string;
  taf4Id: number | null;
  taf4C: string;
  taf4N: string;
  taf5Id: number | null;
  taf5C: string;
  taf5N: string;
  taf6Id: number | null;
  taf6C: string;
  taf6N: string;
  hesabDarId: number | null;
  hesabDarC: string;
  hesabDarN: string;
  taf4DarId: number | null;
  taf4DarC: string;
  taf4DarN: string;
  taf5DarId: number | null;
  taf5DarC: string;
  taf5DarN: string;
  taf6DarId: number | null;
  taf6DarC: string;
  taf6DarN: string;
  dis: string;
  disB: string;
  radif: number;
  ctn: number;
  anDeedRowId: number;
  anDeedCode: number;
  _maxAmt: number;
  _lock: boolean;
  sanadId: number | null;
  vs: any;

  constructor() {

    this.id= null;
this.kind= -1;
this.opA= null;
this.anSellId= 0;
this.anSellCode= 0;
    this.anSellRowId= null;
this.anSellRowCode= null;
this.anbarId= -1;
    this.dateA= 0;
    this.years= 0;
    this.vaz= 0;
    this.kalaId= null;
    this.kalaN= null;
    this.kalaC= null;
    this.amt= 0;
    this.amtB= 0;
    this.amtDone= 0;
    this.amtDoneB= 0;
    this.darsadM= 0;
    this.vahBId= null;
    this.vahBN= null;
    this.vahId= null;
    this.vahN= null;
    this.zMulti= null;
    this.fi= 0;
    this.prc= 0;
    this.prcTkhDrsd= 0;
    this.prcTkh= 0;
    this.prcHazDrsd= 0;
    this.prcHaz= 0;
    this.prc9= 0;
    this.prc10= 0;
    this.prc11= 0;
    this.hesabId= null;
    this.hesabC= null;
    this.hesabN= null;
    this.taf4Id= null;
    this.taf4C= null;
    this.taf4N= null;
    this.taf5Id= null;
    this.taf5C= null;
    this.taf5N= null;
    this.taf6Id= null;
    this.taf6C= null;
    this.taf6N= null;
    this.hesabDarId= null;
    this.hesabDarC= null;
    this.hesabDarN= null;
    this.taf4DarId= null;
    this.taf4DarC= null;
    this.taf4DarN= null;
    this.taf5DarId= null;
    this.taf5DarC= null;
    this.taf5DarN= null;
    this.taf6DarId= null;
    this.taf6DarC= null;
    this.taf6DarN= null;
    this.dis= null;
    this.disB= null;
    this.radif= null;
    this.ctn= 0;
    this.anDeedRowId= null;
    this.anDeedCode= null;
    this._maxAmt= 0;
    this._lock= false;
    this.vs= [];
    this.sanadId= null;
  }
}

export class AnSellRowForMabna {
  id: number;
  dateA: number;
  dateA_S: string;
  kalaId: number | null;
  kalaN: string;
  kalaC: string;
  amtmB: number;
  amtB: number;
  vahBId: number | null;
  vahBN: string;
  vahId: number;
  vahN: string;
  zMulti: number;
  anbarId: number;
  tafMoId: number;
  anSellCode: number;
  anSellId: number;
  fi: number;
  prc: number;
  prcTkh: number;
  prcHaz: number;
  prc9: number;
  prc10: number;
  prc11: number;
  darsadM: number;
  constructor() {
    this.id= null;
    this.dateA= null;
    this.dateA_S= null;
    this.kalaId= null;
    this.kalaN= null;
    this.kalaC= null;
    this.amtB= null;
    this.amtmB= null;
    this.vahBId= null;
    this.vahBN= null;
    this.vahId= null;
    this.vahN= null;
    this.zMulti= null;
    this.anbarId= null;
    this.tafMoId= null;
    this.anSellId= null;
    this.anSellCode= null;

    this.fi= 0;
    this.prc= 0;
    this.prcTkh= 0;
    this.prcHaz= 0;
    this.prc9= 0;
    this.prc10= 0;
    this.prc11= 0;
    this.darsadM= 0;
  }
}


export class AnSellAf {
  id: number;
  anSellId: number;
  act: boolean;
  titC: number;
  tit: string;
  kindId: number;
  kindN: string;
  darsad: number;
  prc: number;
  hesabId: number;
  hesabN: string;
  tafId: number;
  dis: string;
  isUse: boolean;
  isM: boolean;
  _IsDar: boolean;
  _IsPrc: boolean;
  
  constructor(data: Partial<AnSellAf>) {
    Object.assign(this, data);
  }
}
