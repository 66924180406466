import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { Localst } from "../localst.service";
import { HttpClient} from '@angular/common/http';
import { ApiRet } from "../../_models/General/ApiRet";
import { Observable } from "rxjs";
import { SuperDbsReport } from "../../_models/SuperAdmin/SuperDbsReport";
 
 
let EmptySuperDbsReport: SuperDbsReport = {

  rows: [],
  dbNum: 0


};


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class SuperAdminService {
  baseUrl = environment.apiUrl;
  origin = location.origin;


  val = 'm222m222m';
  constructor(private http: HttpClient, private localstService: Localst) {

    console.log(this.val);

  }

  getDbsReport(): Observable<ApiRet> {
    let apiadress = '/api/SuperAdmin/getDbsReport';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  getEmptySuperDbsReport(): SuperDbsReport {


    return JSON.parse(JSON.stringify(EmptySuperDbsReport));

  }


}
