import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
 

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
 
import { Localstclass } from '../_models/localstclass';
import { SaleMaliService } from './General/SaleMali.service';
import { SaleMali } from '../_models/General/saleMali';
import { sabtCopyTemp } from '../_models/app1/opr/sabtCopyTemp';
import { Injectable } from '@angular/core';
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};

 
import saveAs from 'file-saver';
import { exportDataGrid  } from 'devextreme/excel_exporter';
import { MyTimeService } from './myTime.service';
//import { Workbook } from 'exceljs';
import { Shakhz } from '../_models/app1/paye/Shakhz';

 

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({ 
  providedIn: 'root'
})
export class ExcelService {
 // baseUrl = environment.apiUrl;
  baseUrl = location.origin + '/api/';
 


  constructor(private http: HttpClient,
    private saleMaliService: SaleMaliService,
    private MyTimeService: MyTimeService
    
  )
  {


  }
  //نوع devextream

  public exportAsExcelFileDevextream(e , filename : string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(filename);
    filename = filename + this.MyTimeService.getnowAsShamsiString('-');
    exportDataGrid({
      component: e,
      worksheet: worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename+ '.xlsx');
      });
    });


  }

 



  //نوع دوم خروجی
  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

 
 

    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }



  //اپلود فایل
 
 
 

   importExcelFile = async (event: any): Promise<any[]> =>{
      //return new Promise<any>(resolve => {
      //  setTimeout(() => {
      //    resolve('my value');
      //  }, 4);
      //});

      return new Promise<any[]>((resolve) => {
       
     
     
    const workbook = new ExcelJS.Workbook();

    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    let dreded : any[] =[];


    const arryBuffer = new Response(target.files[0]).arrayBuffer();
      arryBuffer.then(function (data) {
     
      workbook.xlsx.load(data)
        .then(function () {

          // play with workbook and worksheet now
          console.log(workbook);
          const worksheet = workbook.getWorksheet(1);
          //ttyrt75675647567
          console.log('rowCount: ', worksheet.rowCount);
          worksheet.eachRow(function (row, rowNumber) {
            //console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            //پاک کردن سلول صفر چون همیشه دعمم است
          //  dreded = dreded.shift();

            dreded.push(row.values);
     
          });


        
          resolve(dreded);
         

        });
    });





      });
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




  public exporttableRowbyRow(datatable: any[],header: any ,  filename: string): void {

    const workbook = new ExcelJS.Workbook();
 
    //const worksheet = workbook.addWorksheet(filename);







    //var rowjustdata = [];
    //rowjustdata.push(this.GetclassValues(header));
    //rowjustdata.push(this.GetclassFiel(header));
    //for (var i = 0; i < datatable.length; i++) {

   
    //  rowjustdata.push(this.GetclassValues(datatable[i]));


    //}





    //for (var k = 0; k < rowjustdata.length; k++) {

    //  worksheet.addRow(rowjustdata[k]);

    //}



    ////پاک نشود
    ////x = نام صفت موجود در کلاس
    ////ddd[x] = مقدار داخل صفت

    ////var ddd = datatable[0];


    ////var fffffff = [];
    ////var x;
    ////for (x in ddd) {
    ////  console.log(ddd[x]);
    ////  fffffff.push(ddd[x]);
    ////  console.log(fffffff);
 
    ////}
 
    ////for (var i = 0; i < 8; i++) {

    ////  worksheet.addRow(fffffff );
  
    ////}
 
  

    //workbook.xlsx.writeBuffer().then((buffer) => {
    //  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename + '.xlsx');
    //});




  }


  GetclassValues(row: any) : any[] {
    var ret = [];
    var x;
    for (x in row) {
      ret.push(row[x]);
    }
    return ret;


  }

  GetclassFiel(row: any): any[] {
    var ret = [];
    var x;
    for (x in row) {
      ret.push(x);
    }
    return ret;


  }

}
 
