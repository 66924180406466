
import { Sabt } from "./sabt";


 

export class sabtCopyTemp {
  idName: string;

  sabts: Sabt[];


 public constructor(idName: string) {
  this.idName = idName;
  this.sabts = [];
}

}
