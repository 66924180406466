export class RegistryApp3Code {
  years: number;
  rowCode: RegistryApp3CodeRow[];
  maliat: number;

takhDarsad: boolean;
  onAnbar: boolean;
  direct: boolean;
  force706mabna: boolean;
  force706one: boolean;

 public constructor() {
   this.years = 0;
   this.maliat = 0;

   this.takhDarsad = false;
   this.onAnbar = false;
   this.force706mabna = false;
   this.force706one = false;

   this.rowCode = [];
  }
}

export interface RegistryApp3CodeRow {
  typeId: number;
  sanadN: string;
  codeStart: number;
  isAuto: boolean;
  isDateCh: boolean;
}
