import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
 
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { ApiRet } from '../../../../_models/General/ApiRet';
import { App99Service } from '../../../../_services/app99/App99.service';
import { HItem, HItemG, HItemGRow } from '../../../../_models/app99/app99model';
 


@Component({
  selector: 'app99-HItemG-edit',
  templateUrl: './HItemG-edit.component.html',
  styleUrls: ['./HItemG-edit.component.css']
})
export class HItemGEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();

  @ViewChild("gridSelect", { static: false }) gridSelect: DxDataGridComponent;
  @ViewChild("gridAll", { static: false }) gridAll: DxDataGridComponent;

  myModel: HItemG;
  myModel_old: HItemG;
 
  HItemlist: HItem[]=[];
  HItemlistFiltred: HItem[]=[];
 

 
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
    private tafsiliService: TafsiliService,
 
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    
    this.myModel = this.app99Service.HItemG_getHItemGEmpty();
 
 
  }

  ngOnInit() {


 

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.app99Service.HItemG_getHItemGEmpty();
 

      this.isnew = true;

      this.loadTaf();
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.HItemG_getbyid(this.editId).subscribe(
        (res : ApiRet) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = res.data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          

          this.loadTaf();
          this.bidRadif();

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }

  loadTaf() {
    this.loadServ.showloading(this.loaddata, 0);
    this.app99Service.HItem_getall().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.HItemlist = res;
      this.filterHitem();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }

  filterHitem() {

 
    let ins = true;
    this.HItemlistFiltred = [];
    for (var f of this.HItemlist) {

      ins = true;

      for (var i of this.myModel.rows) {

        if (i.hItemId == f.id) {

          ins = false;
    
        }
      }
    
      if (ins)
        this.HItemlistFiltred.push(f);
    }
   


  
  }


  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.HItemG_add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }

       
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.HItemG_update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,5);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);
        }


      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 

 
  selectgroup() {

   var keys = this.gridAll.instance.getSelectedRowKeys();

    for (var r of keys) {

      for (var m of this.HItemlist){

        if(m.id == r)
          this.myModel.rows.push(this.createHitemG(m));
      }

    }


    this.filterHitem();
    this.bidRadif();
  }
  unselectgroup() {

    var keys = this.gridSelect.instance.getSelectedRowKeys();


    

    for (var r of keys) {
        this.myModel.rows=  this.myModel.rows.filter(x => x.id != r);
    }

    this.filterHitem();

  }

  cnt: number = -1;

  createHitemG(tsm: HItem): HItemGRow {

    this.cnt--;
    let temp= this.app99Service.HItemGRow_getHItemGEmpty();
    temp.id = this.cnt;
    temp.name = tsm.name;
      temp.hItemId = tsm.id;
      temp.costFi = tsm.costFi;
      temp.costFi2 = tsm.costFi;
      temp.amt = 1;
    

    return temp;

  }



  bidRadif() {
    let c = 0;
    for (var r of this.myModel.rows) {
      c++;
      r.radif = c;
    }
  }

}
