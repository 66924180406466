import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { Tabaghe } from '../../../_models/app2/paye/Tabaghe';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyTabaghe: Tabaghe = {
  id: null,
  code: null,
  name: null,
  raveshId: null,
  raveshN: null,
  hesabAId: null,
  visib: true,

  hb101Id: null,
  hb102Id: null,
  hb103Id: null,
  hb104Id: null,
  hb105Id: null,
  hb106Id: null,
  hb107Id: null,

  hesabBId: null,
  hb201Id: null,
  hb202Id: null,
  hb203Id: null,
  hb204Id: null,
  hb205Id: null,
  hb206Id: null,
  hb207Id: null,
  hb208Id: null,
  hb209Id: null,
  hb210Id: null,
  hb211Id: null,

  _IsUsed: false
};


let raveshs: any = [
 {
  id: 1,
   name: 'میانگین موزون',
},
  {
    id: 2,
    name: 'FIFO',
  },
  //{
  //  id: 3,
  //  name: 'LIFO',
  //},
  //{
  //  id: 4,
  //  name: 'شناسایی ویژه',
  //}


];


const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class TabagheService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(inhesab: boolean): Observable<Tabaghe[]> {
    let apiadress = '/api/App2/Paye/Tabaghe/getall?inhesab=' + inhesab;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Tabaghe[]>(this.origin + apiadress, { 'headers': headers });
  }

  getall_tiny(): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Tabaghe/getall_tiny';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

 
  getbyid(id, inhesab:boolean): Observable<Tabaghe> {
    let apiadress = '/api/App2/Paye/Tabaghe/getbyid?id=' + id.toString() + '&inhesab=' + inhesab;
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Tabaghe>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: Tabaghe) {
    let apiadress = '/api/App2/Paye/Tabaghe/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getTabagheEmpty(): Tabaghe {

 
    return JSON.parse(JSON.stringify(EmptyTabaghe));

  }
  getRaveshs(): any {


    return raveshs;

  }
  update(item: Tabaghe) {
    let apiadress = '/api/App2/Paye/Tabaghe/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  delete(TabagheId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Tabaghe/delete?id=' + TabagheId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCods(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/Tabaghe/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
}
