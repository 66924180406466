import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
 
 
import { parse } from 'querystring';
import { AlertifyService } from '../../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Localst } from '../../../../../_services/localst.service';
import { LoadingService } from '../../../../../_services/loading.service';
import { ExcelService } from '../../../../../_services/excel.service';
import { DeldialogComponent } from '../../../../../dialog/deldialog.component';
import { TadilformulService } from '../../../../../_services/app1/petty/Tadilformul.service';
import { tadilformul, tadilformulName } from '../../../../../_models/app1/petty/tadilformul';
import { Hesab } from '../../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../../_services/app1/paye/Hesab.service';
import { TafsiliForGroupRead } from '../../../../../_models/app1/paye/tafsiliForGroupRead';
import { TafsiliService } from '../../../../../_services/app1/paye/Tafsili.service';
import { TafsiliGbydaste } from '../../../../../_models/app1/paye/tafsiliGbydaste';
import { bastanOp } from '../../../../../_models/app1/opr/autoSanad/bastanOp';
import { AutoSanadService } from '../../../../../_services/app1/opr/AutoSanad.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MyTimeService } from '../../../../../_services/myTime.service';
 

 

@Component({
  selector: 'app-Bastan-list',
  templateUrl: './Bastan-list.component.html',
  styleUrls: ['./Bastan-list.component.css']
})
  

export class BastanListComponent implements OnInit {

 
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("dataGridhesab", { static: false }) dataGridhesab: DxDataGridComponent;
  @ViewChild("formulcombo", { static: false }) formulcombo: DxSelectBoxComponent;
  hesablist: Hesab[] = [];
  hesabforbastan: any[] = [];

  
  TafG: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];
  alloeditgridformul: boolean;
  onvan: string;
  valub: number;
  formulList: tadilformulName[];
  selectedformul: tadilformulName;
  selectedformulrows: tadilformul[];
  tafjustdaste: TafsiliGbydaste[] = [];
  selectedrow: tadilformul;
 bastaop: bastanOp;
  loaddata: loadingCl;
  constructor( private alertify: AlertifyService,

    private route: ActivatedRoute,
    private tadilformulService: TadilformulService,
    private TafsiliService: TafsiliService,
    private hesabService: HesabService,
    private localst: Localst,
    private loadServ: LoadingService,
    private autoSanadService: AutoSanadService,
    private excelService: ExcelService,
    private myTimeService: MyTimeService,

    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 10);
 
    this.onvan = '';
    this.formulList = [];
    this.selectedformulrows = [];
    this.alloeditgridformul = false;
    this.selectedformul = this.tadilformulService.getTadilformulNameEmplty();
    this.valub = null;

    this.bastaop = this.autoSanadService.getEmptybastanOp();
  }
 
  ngOnInit() {
 
    this.reloadformul();

   
    this.bastaop.dateSanad = this.localst.yearnow_Class_get().dateE.toString();

    console.log(this.localst.yearnow_Class_get());
   // console.log(this.dateSanad);


    this.loadServ.showloading(this.loaddata, 1);
    this.hesabService.getHesabs_treenum(3).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.hesablist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    //
    this.loadServ.showloading(this.loaddata, 2);
    this.TafsiliService.getTafsilisGroupList().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.TafGListall = data;
      //this.tafg_reflesh(this.selectedrow.hesabId);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 2);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

    //
    this.loadServ.showloading(this.loaddata, 3);
    this.TafsiliService.getTafsiliJustGrupedByDaste(false, -1, 'none').subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.tafjustdaste = res.data;



      //پاک کردن کش تمام حساب های tafglist
      for (var r = 0; r < this.TafGListall.length; r++) {
        if (this.TafGListall[r].taf4List.length > 0) {
          this.TafGListall[r].taf4List = [];
          this.TafGListall[r].taf5List = [];
          this.TafGListall[r].taf6List = [];
          this.tafg_reflesh(this.TafGListall[r].hesabId);
        }
      }




      console.log('down' + this.tafjustdaste.length);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    this.loadServ.showloading(this.loaddata, 6);
    this.autoSanadService.getHesabs_ForBastan(this.localst.yearnow_get()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.hesabforbastan = data;
      //this.tafg_reflesh(this.selectedrow.hesabId);
    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  ngOnChanges(changes: any) {

 
  }
 
  reloadformul() {

    this.TafG = this.TafsiliService.getTafsiliGroupEmpty();

    this.loadServ.showloading(this.loaddata, 0);
    this.tadilformulService.getTadilformulNames(this.localst.yearnow_get()).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.formulList = data;
      this.valub = null;

      if (this.formulList.length == 0) {
        this.valub = null;
        this.selectedformulrows = [];
        return;
      }
      //this.selectedformulrows = [];
      this.valub = this.formulList[this.formulList.length -1].sid;

      //if (this.valub == null) {

      //  this.valub = this.formulList[i].sid;
      //}

      //for (var i = 0; i < this.formulList.length; i++) {
      //  if (this.formulList[i].sid > valub)
      //    valub = this.formulList[i].sid;
      //}
      


    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }
 

 

  onRowDblClick(e) {

    if (e.key == null)
      return;

    let sabtid = e.key;
 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

 


  }
  onClickNew() {

   
   


  }
  OnMyCancelEvent(e) {
   

  }

  OnMySaveEvent(e) {

  

  }
 

 
 

  onClickghardesh() {

  
 


  }

 
  onClickReflesh() {
    this.ngOnInit();
 
  }

  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' ریز گردش ');

  }
 
  onClickprint() {


 
 
  }

  onClickDoAutoSanad() {


    //this.bastaop = this.autoSanadService.getEmptybastanOp();
    //this.bastaop.dateSanad = this.dateSanad;
    this.bastaop.formulSid = this.selectedformul.sid;
    this.bastaop.sanadId = -1;
    this.bastaop.year = this.localst.yearnow_get();
     // this.selectedformul.years;


  let val=  this.myTimeService.isvalidday(this.bastaop.dateSanad);

    if (val != null) {

      this.alertify.frameless('تاریخ سند در محدوده سال مالی جاری نمیباشد');
      return;
    }
 
    var keyss = this.dataGridhesab.selectedRowKeys;
    if (keyss == null)
      return;

    //fghfgjgfdddd
    this.bastaop.hesabIds = keyss ;

    if (this.bastaop.hesabIds.length == 0) {
      this.alertify.frameless('هیچ حسابی انتخاب نشده');
      return;
    }
    if (this.bastaop.formulSid == null) {
    //  console.log(this.bastaop.formulSid);
      this.alertify.frameless('فرمول تسهیم را انتخاب کنید');
      return;
    }

    this.loadServ.showloading(this.loaddata, 8);
    this.autoSanadService.CreateBastan(this.bastaop).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.message('سند با موفقیت ایجاد شد');
      this.ngOnInit();
      //console.log(data);
     // this.selectedformul = data;
   //   this.reloadformul();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
   
  }
 
 
   
 
  selectchanginuse: boolean;
 
  datestartchang(e) {



  }
  onFocusInDateS(e) {

  }

  adjustWidth(e) {

    e.component._popup.option('width', 400);

  }

  searchedmoid: number;
  onSearchChangeMo(searchValue: string): void {
    this.searchedmoid = -1;

    //var thenum = searchValue.replace(/[^0-9]/g, '');

    //// console.log(thenum);

    //if (thenum.length > 2)
    //  for (var r = 0; r < this.hesablist.length; r++) {
    //    if (this.hesablist[r].codeSum == thenum) {

    //      // console.log(JSON.stringify(this.moientempedit.items[r]));
    //      //this.moientempedit.selectedItem = this.moientempedit.items[r];
    //      // this.moientempedit.value = this.moientempedit.items[r].id;
    //      this.searchedmoid = this.hesablist[r].id;
    //      break;
    //    }

    //  }
  }

  ///???????????
  tafg_reflesh(hesabid: number) {
    console.log('hhhhhhhhhhh : ' + hesabid);
    if (hesabid == null) {

      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
      return;


    }

    this.TafG = null;



    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListall.length; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];



        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب
        if (this.TafGListall[_i].taf4List.length == 0)
          for (var j = 0; j < this.tafjustdaste.length; j++) {

            //خواندن تفصیلی 4
            for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

              if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
                this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
              }

            }
            //خواندن تفصیلی   5
            if (this.TafGListall[_i].taf5List.length == 0)
              for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

                if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
                  this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
                }

              }
            //خواندن تفصیلی 6
            if (this.TafGListall[_i].taf6List.length == 0)
              for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

                if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
                  this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
                }

              }


          }


        this.TafG = this.TafGListall[_i];

        //if (!this.TafG.have4)
        //  this.selectedrow.tafsili4Code = "-----";
        //if (!this.TafG.have5)
        //  this.selectedrow.tafsili5Code = "-----";
        //if (!this.TafG.have6)
        //  this.selectedrow.tafsili6Code = "-----";

        console.log('$TafG:' + this.TafG.hesabId.toString() + ' l4:' + this.TafG.have4 + ' l5:' + this.TafG.have5 + ' l6:' + this.TafG.have6);


        return;
      }

    }









  }



  pv: any;
  temp_moienchanged(e, cellInfo) {
    this.pv = -1;

    var selected;

    console.log('temp_moienchanged');

    var previousValue = e.previousValue;
    var newValue = e.value;





    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newValue) {
        selected = this.hesablist[i];
        this.tafg_reflesh(selected.id);
        //console.log(selected.kolName);
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected != null) {

      this.selectedrow.groupId = selected.groupId;
      this.selectedrow.groupCode = selected.groupCode;
      this.selectedrow.groupName = selected.groupName;


      this.selectedrow.kolId = selected.kolId;
      this.selectedrow.kolCode = selected.kolCode;
      this.selectedrow.kolName = selected.kolName;


      this.selectedrow.hesabId = selected.id;
      this.selectedrow.hesabName = selected.name;
      //cellInfo.data.dis = 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkk';
      this.selectedrow.hesabCode = selected.codeSum;



    }
    else {

      this.selectedrow.groupId = null;
      this.selectedrow.groupCode = null;
      this.selectedrow.groupName = null;


      this.selectedrow.kolId = null;
      this.selectedrow.kolCode = null;
      this.selectedrow.kolName = null;

      this.selectedrow.hesabId = null;
      this.selectedrow.hesabName = null;


    }

    this.selectedrow.tafsili4Id = null;
    this.selectedrow.tafsili4Code = null;
    this.selectedrow.tafsili4Name = null;

    this.selectedrow.tafsili5Id = null;
    this.selectedrow.tafsili5Code = null;
    this.selectedrow.tafsili5Name = null;

    this.selectedrow.tafsili6Id = null;
    this.selectedrow.tafsili6Code = null;
    this.selectedrow.tafsili6Name = null;


    if (this.dataGrid.focusedRowIndex == 0) {

    }

    if (selected != null)
      this.tafg_reflesh(selected.id);
    else
      this.tafg_reflesh(null);

    //this.dataGrid.instance.focus();


  }


  onInitNewRowf(e) {
    this.selectedrow = this.tadilformulService.getTadilformulEmplty();
    this.selectedrow.id = this.getnextid();
  

    e.data = this.selectedrow;
   // console.log(e);
  }
  onEditingStart(e) {

    

    this.selectedrow = e.data;

    this.tafg_reflesh(this.selectedrow.hesabId);
  }

  getnextid() {
    let nexid = -1000
    for (var i = 0; i < this.selectedformulrows.length; i++) {
      if (this.selectedformulrows[i].id > 0)
        continue;

      if (this.selectedformulrows[i].id < nexid) {

        nexid = this.selectedformulrows[i].id;
      }


    }
    nexid--;
    return nexid;
 
  }
  onRowInserted(e) {

    var newrow = e.data as tadilformul;




  //  newrow.groupId = 95959;

    var selected = null;
    for (let i = 0; i < this.hesablist.length; i++) {
      if (this.hesablist[i].id == newrow.hesabId) {
        selected = this.hesablist[i];
        //console.log(selected.kolName);
        break;
        // console.log(this.hesablist[i].id)
      }

    }
    if (selected == null)
      return;

      newrow.groupId = selected.groupId;
      newrow.groupCode = selected.groupCode;
      newrow.groupName = selected.groupName;


      newrow.kolId = selected.kolId;
      newrow.kolCode = selected.kolCode;
      newrow.kolName = selected.kolName;


      newrow.hesabId = selected.id;
      newrow.hesabName = selected.name;
      newrow.hesabCode = selected.codeSum;



    
    this.tafg_reflesh(newrow.hesabId);
 //dfhjfg
    for (var i = 0; i < this.TafG.taf4List.length; i++) {
      if (this.TafG.taf4List[i].id == newrow.tafsili4Id) {

        newrow.tafsili4Code = this.TafG.taf4List[i].code;
        newrow.tafsili4Name = this.TafG.taf4List[i].name;
        break;
      }
    }
    for (var i = 0; i < this.TafG.taf5List.length; i++) {
      if (this.TafG.taf5List[i].id == newrow.tafsili5Id) {

        newrow.tafsili5Code = this.TafG.taf5List[i].code;
        newrow.tafsili5Name = this.TafG.taf5List[i].name;
        break;
      }
    }
    for (var i = 0; i < this.TafG.taf6List.length; i++) {
      if (this.TafG.taf6List[i].id == newrow.tafsili6Id) {

        newrow.tafsili6Code = this.TafG.taf6List[i].code;
        newrow.tafsili6Name = this.TafG.taf6List[i].name;
        break;
      }
    }


    this.tafg_reflesh(null);
   // else {

   //   newrow.groupId = null;
   //  newrow.groupCode = null;
   //   newrow.groupName = null;


   //newrow.kolId = null;
   // newrow.kolCode = null;
   //  newrow.kolName = null;

   // newrow.hesabId = null;
   //   newrow.hesabName = null;


   // }

   // newrow.tafsili4Id = null;
   // newrow.tafsili4Code = null;
   //newrow.tafsili4Name = null;

   //newrow.tafsili5Id = null;
   //newrow.tafsili5Code = null;
   //newrow.tafsili5Name = null;

   //newrow.tafsili6Id = null;
   //newrow.tafsili6Code = null;
   //newrow.tafsili6Name = null;


   // e.data = newrow;
   // console.log(e);



  }

  onClickNewformul() {
    this.selectedformul = null;
    this.selectedformulrows = [];
    this.alloeditgridformul = true;

  }
  onClickEditformul() {
    this.onvan = this.selectedformul.text;
    this.alloeditgridformul = true;

  }
  onClickDeleteformul() {
    this.loadServ.showloading(this.loaddata, 6);
    this.tadilformulService.deleteTadilformul(this.selectedformul.sid).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.selectedformul = data;
      this.reloadformul();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }

  onClickSaveformul() {
    let isnew = false;
    if (this.selectedformul == null )
      isnew = true;
    else if (this.selectedformul.sid == null)
      isnew = true;

    if (this.onvan == null || this.onvan =='') {
      this.alertify.frameless('عنوان فرمول را مشخص کنید');
      return;
    }

    let year = this.localst.yearnow_get();
    let sumdarsad = 0;



    for (var i = 0; i < this.selectedformulrows.length; i++) {
 
      this.selectedformulrows[i].years = year;
      this.selectedformulrows[i].text = this.onvan;

      if (isnew)
        this.selectedformulrows[i].sid = -1;
      else
        this.selectedformulrows[i].sid = this.selectedformul.sid;


      if (this.selectedformulrows[i].hesabId == null) {
        this.alertify.frameless(' ردیف ' + (i+1).toString() + 'حساب معین مشخص نیست');
        return;
      }

      this.tafg_reflesh(this.selectedformulrows[i].hesabId);

      if (this.selectedformulrows[i].tafsili4Id == null && this.TafG.have4) {
        this.alertify.frameless(' ردیف ' + (i + 1).toString() + 'تفصیلی سطح چهارم مشخص نیست');
        return;
      }
      if (this.selectedformulrows[i].tafsili5Id == null && this.TafG.have5) {
        this.alertify.frameless(' ردیف ' + (i + 1).toString() + 'تفصیلی سطح پنجم مشخص نیست');
        return;
      }
      if (this.selectedformulrows[i].tafsili6Id == null && this.TafG.have6) {
        this.alertify.frameless(' ردیف ' + (i + 1).toString() + 'تفصیلی سطح ششم مشخص نیست');
        return;
      }

      this.tafg_reflesh(null);


      if (this.selectedformulrows[i].hesabId == 0) {

        this.alertify.frameless('درصد تسهیم دارای مقادیر صفر میباشد');
        return;
      }





      if (this.selectedformulrows[i].darsad == 0) {

        this.alertify.frameless('درصد تسهیم دارای مقادیر صفر میباشد');
        return;
      }

      sumdarsad += this.selectedformulrows[i].darsad;


    }
    if (sumdarsad > 1) {
      this.alertify.frameless('جمع درصد تسهیم باید کمتر 100 باشد');
      return;
    }


    console.log(JSON.stringify(this.selectedformulrows));


    if (isnew) {
      this.loadServ.showloading(this.loaddata, 4);
      this.tadilformulService.addTadilformul(this.selectedformulrows).subscribe(data => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.message('فرمول تسهیم با موفقیت اضافه شد.');
        this.selectedformul = data;
        this.onvan = null;
        this.reloadformul();
      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
    }
    else {
      this.loadServ.showloading(this.loaddata, 7);
      this.tadilformulService.updateTadilformul(this.selectedformulrows).subscribe(data => {
        this.loadServ.hideloading(this.loaddata, 7);
        this.alertify.message('فرمول تسهیم با موفقیت بروز رسانی شد.');
        this.selectedformul = data;
        this.onvan = null;
        this.reloadformul();
      }, error => {
        this.loadServ.hideloading(this.loaddata, 7);
        this.alertify.error(error);
        console.log("myerror:" + error);
      });
    }






    this.alloeditgridformul = false;
  }
  onClickCancelformul() {
   

    this.valub = null;

    if (this.selectedformul != null)
      this.valub = this.selectedformul.sid;
    
    this.alloeditgridformul = false;

  }

  onValueChangedformul(e) {

    if (!e.value || e.value==null)
      return

    

    for (var i = 0; i < this.formulList.length; i++) {

      if (this.formulList[i].sid == e.value) {

        this.selectedformul = this.formulList[i];
        break;
      }
    }
    if (this.selectedformul.sid == null)
      return;
    this.loadServ.showloading(this.loaddata, 5);
    this.tadilformulService.getTadilformulbySid(this.selectedformul.sid).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.selectedformulrows = data;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }

  onRowValidating(e) {

    console.log(e);
    this.tafg_reflesh(e.newData.hesabId);
    console.log(JSON.stringify(this.TafG));


    if (this.TafG.have4 && e.newData.tafsili4Id == null) {

      e.errorText = 'تقصیلی سطح چهار مشخص نشده'
      e.isValid = false;
      return;
    }
    if (this.TafG.have5 && e.newData.tafsili5Id == null) {

      e.errorText = 'تقصیلی سطح پنج مشخص نشده'
      e.isValid = false;
      return;
    }
    if (this.TafG.have6 && e.newData.tafsili6Id == null) {

      e.errorText = 'تقصیلی سطح شش مشخص نشده'
      e.isValid = false;
      return;
    }
    this.tafg_reflesh(null);
    e.isValid = true;
  }
  onClickaddrow() {
    this.dataGrid.instance.addRow();


  }
}


