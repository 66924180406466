import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import {  Diri, Morur, MorurOption } from '../../../../_models/app1/rep/Morur';
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent, DxoScrollingComponent, DxoScrollingModule } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent, DxScrollViewComponent } from 'devextreme-angular';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../../_models/General/saleMali';
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { ExcelService } from '../../../../_services/excel.service';
import { LoadingService } from '../../../../_services/loading.service';
import { Localst } from '../../../../_services/localst.service';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
 
 
export interface DialogData {
  animal: string;
  name: string;
} 

@Component({
  selector: 'app-Morur-list',
  templateUrl: './Morur-list.component.html',
  styleUrls: ['./Morur-list.component.css']
})
 

export class MorurListComponent implements OnInit {

  openprint: boolean;
  @Output() OnRizGhardesh = new EventEmitter<MorurOption>();
  @Output() OnMyprint = new EventEmitter<any>();

  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;
 
 
  @Input() ftabindex: number;
  @Input() tabindex: number;
  openedit: boolean;
  openeditId: number;

  morurOption: MorurOption;
 
  modlelist: Morur[];
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};
  //focusedRowKey: 1;
  bls1: string;
  bls2: string;
  bls3: string;
  bls4: string;
  bls5: string;
  bls6: string;
 
  selectedlevelold: number;
  selectedlevelnew: number;
  selectedlevelDBClicknew: number;
  //animal: string;
  //name: string;
  isdelete: string;

  loaddata: loadingCl;
  

  constructor(private userService: UserService, private alertify: AlertifyService, private MorurService: MorurService, 
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private sanadService: SanadService,
    private excelService: ExcelService,
    private hesabService: HesabService,
    private localst: Localst,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 8);

    this.openprint = false;
    this.bls1 = 'btn btn-light btn-lg btndef disbal';
    this.bls2 = 'btn btn-light btn-lg btndef disbal';
    this.bls3 = 'btn btn-light btn-lg btndef disbal';
    this.bls4 = 'btn btn-light btn-lg btndef disbal';
    this.bls5 = 'btn btn-light btn-lg btndef disbal';
    this.bls6 = 'btn btn-light btn-lg btndef disbal';
    this.selectedlevelold=-2;
    this.selectedlevelnew = -1;
    this.selectedlevelDBClicknew = -1;
    this.modlelist = [];


  }
  mytabindex: number = -1;
 
  ngOnInit() {

    this.mytabindex = this.tabindex;
    this.isdelete = "no";

    
    this.morurOption = this.MorurService.getemptyoption();
    this.morurOption.kindReport = 'morur';
    //تمام نوع سند را در وضعیت فعال قرار میدهیم
    this.morurOption.KindSanad = this.sanadService.getKinds();
    this.morurOption.hesabKind = this.hesabService.gethesabKinds();


 
  }
 
  ngOnChanges(changes: any) {

    if (this.dxgrid != undefined)
      if (changes['tabindex'].currentValue == this.mytabindex) {

        let index = this.dxgrid.focusedRowIndex;
        if (index == -1) {
          index = 0;
        }
      if (index > -1) {

        var yyy = this.dxgrid.instance.getCellElement(index + 4 ,0);
        if (yyy == undefined)
          yyy = this.dxgrid.instance.getCellElement(index ,0);
        var y = this.dxgrid.instance.getScrollable();
   
        if (yyy != undefined)
        y.scrollToElement(yyy);
        this.dxgrid.instance.refresh();
        //y.scrollTo(0,2);
    
        //y.sc(0);
      }
 
    }
    else {
    
     
    }
  }
 
  onFocusedRowChanged(e) {


   // this.selectedrow = e.row.data;

   // console.log(this.focusedRowKey)
    //for (var _i = 0; _i < this.modlelist.length; _i++) {
    //  var num = numbers[_i];
    //  console.log(num);
    //}

  //  console.log(JSON.stringify(this.selectedrow));

 
  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {



    //  return;
    //}



    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }


  printmoruroption(morur : MorurOption) {



     

  }

  onClicklevel(level: number) {

    this.dxgrid.instance.clearFilter();

 


  //  this.morurOption = this.MorurService.getemptyoption();
    this.morurOption.ismother = true;
    this.morurOption.mainlevel = level;
    this.morurOption.oldlevel = level;
    this.morurOption.nowlevel = level;
    this.morurOption.dir = [];

    //روش قبلی
   // this.loadMorur(level);

    //از همون تابع Sub



    this.loadmorursub(this.morurOption,0);


    this.disableDirbutton(level, true);



 


  }
 
 

 

  onClickDir(level: number) {

    this.dxgrid.instance.clearFilter();

    let selectedrow = this.getselectedrow();
    if (selectedrow == null)
      return;

    if (this.morurOption == null)
      return;

    if (this.morurOption.dir == null) {

      this.morurOption.dir = [];
    }

    if (level != this.morurOption.oldlevel) {
      this.morurOption.oldlevel = this.morurOption.nowlevel;

    }
   
    this.morurOption.nowlevel = level;



    this.disableDirbutton(level, false);


    let sort = 0;

    if (this.morurOption.dir != null) {

      for (var i=0 ; i < this.morurOption.dir.length; i++) {
        if (this.morurOption.dir[i].sort > sort)
          sort = this.morurOption.dir[i].sort;
      }
    }
    sort++;
    let sam = new Diri();
    sam.selectedId = selectedrow.id;

    sam.level = this.morurOption.oldlevel;
    sam.sort = sort;
    sam.htid = selectedrow.hesOrTafId;
    sam.htidCode = selectedrow.code;
    sam.htidName = selectedrow.name;

    if (sam.level == 1)
      sam.levelName = 'گروه';
    if (sam.level == 2)
      sam.levelName = 'کل';
    if (sam.level == 3)
      sam.levelName = 'معین';
    if (sam.level == 4)
      sam.levelName = 'سطح 4';
    if (sam.level == 5)
      sam.levelName = 'سطح 5';
    if (sam.level == 6)
      sam.levelName = 'سطح 6';



    this.morurOption.dir.push(sam);

    this.modlelist = null;

    this.loadmorursub(this.morurOption ,0);



  }

  loadmorursub(option: MorurOption, focusId: number) {

    this.focusonback = focusId;

    this.loadServ.showloading(this.loaddata, 0);
    this.MorurService.getMorursSub(option).subscribe((data) => {
      this.modlelist = [];
      this.modlelist = data;
      this.loadServ.hideloading(this.loaddata, 0);

      
     
        //if(this.focusonback>0) {

        //  let index = 0;
        //  let c = 0;
        //  for (var u of this.modlelist) {

        //    if (u.id == this.focusonback) {
        //      index = c;
        //      break;
        //    }
        //    c++;

        //  }
        //if (index > 0) {
        //  //try547678
        //  console.log(index);
        //  this.dxgrid.instance.navigateToRow(this.focusonback);

        //  //var yyy = this.dxgrid.instance.getCellElement(index , 0);
        //  //if (yyy == undefined)
        //  //  yyy = this.dxgrid.instance.getCellElement(index, 0);
        //  //var y = this.dxgrid.instance.getScrollable();

        //  //if (yyy != undefined)
        //  //  y.scrollToElement(yyy);
        //  //this.dxgrid.instance.refresh();
        //  //y.scrollTo(0,2);

        //  //y.sc(0);
        //}
        //}



    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error);
      this.loadServ.hideloading(this.loaddata, 0);
    });



  }

  focusonback: number = 0;
  onClickbackdir(diri: Diri) {


    if (diri == null)
      return;


    //console.log(diri.level);
    if (this.morurOption.dir == null) {
      return;
    }

   // this.morurOption.dir.push(sam);
    var temp = this.morurOption.dir;
    this.morurOption.dir = []
      ;
    for (var i=0; i < temp.length; i++) {
      if (temp[i].sort < diri.sort) {
       //فقط ما قبل بماند
        this.morurOption.dir.push(temp[i]);
      }
      else {

      }
        
    }

    this.disableDirbutton(15,true);
    for (var j = 0; j < this.morurOption.dir.length; j++) {


      this.disableDirbutton(this.morurOption.dir[j].level,false);

    }
    this.morurOption.nowlevel = diri.level;

    //this.focusonback = diri.selectedId;
    this.loadmorursub(this.morurOption ,diri.selectedId);



  }
 
  loadMorur(level: number) {

    if (level == null)
      return;


    console.log(level);
    this.loadServ.showloading(this.loaddata, 1);
    this.MorurService.getMorurs(level).subscribe((data) => {

      this.modlelist = data;
      this.loadServ.hideloading(this.loaddata, 1);
    // this.dxgrid.focusedRowKey = this.modlelist[0].id;

    }, error => {
      this.alertify.error(error);
      console.log("myerror:" + error);
      this.loadServ.hideloading(this.loaddata, 1);
    });

  }


  disableDirbutton(level: number , reset : boolean) {


    if (reset) {
      this.bls1 = this.bls1.replace('disbal', '');
      this.bls2 = this.bls1.replace('disbal', '');
      this.bls3 = this.bls1.replace('disbal', '');
      this.bls4 = this.bls1.replace('disbal', '');
      this.bls5 = this.bls1.replace('disbal', '');
      this.bls6 = this.bls1.replace('disbal', '');
    }

    if (level == 1) {

      this.bls1 = this.bls1 +' disbal';
      this.morurOption.lvset1 = true;
    }
   else if (level == 2) {

      this.bls2 = this.bls2 + ' disbal';
      this.morurOption.lvset2 = true;
    }
    else if (level == 3) {

      this.bls3 = this.bls3 + ' disbal';
      this.morurOption.lvset3 = true;
    }
    else if (level == 4) {

      this.bls4 = this.bls4 + ' disbal';
      this.morurOption.lvset4 = true;
    }
    else if (level == 5) {

      this.bls5 = this.bls5 + ' disbal';
      this.morurOption.lvset5 = true;
    }
    else if (level == 6) {

      this.bls6 = this.bls6 + ' disbal';
      this.morurOption.lvset6 = true;
    }




  }

  enableDirbutton(level: number) {


    //if (reset) {
    //  this.bls1 = this.bls1.replace('disbal', '');
    //  this.bls2 = this.bls1.replace('disbal', '');
    //  this.bls3 = this.bls1.replace('disbal', '');
    //  this.bls4 = this.bls1.replace('disbal', '');
    //  this.bls5 = this.bls1.replace('disbal', '');
    //  this.bls6 = this.bls1.replace('disbal', '');
    //}

    if (level == 1) {

      this.bls1 = this.bls1.replace('disbal', '');
      this.morurOption.lvset1 = false;
    }
    else if (level == 2) {

      this.bls2 = this.bls2.replace('disbal', '');
      this.morurOption.lvset2 = false;
    }
    else if (level == 3) {

      this.bls3 = this.bls3.replace('disbal', '');
      this.morurOption.lvset3 = false;
    }
    else if (level == 4) {

      this.bls4 = this.bls4.replace('disbal', '');
      this.morurOption.lvset4 = false;
    }
    else if (level == 5) {

      this.bls5 = this.bls5.replace('disbal', '');
      this.morurOption.lvset5 = false;
    }
    else if (level == 6) {

      this.bls6 = this.bls6.replace('disbal', '');
      this.morurOption.lvset6 = false;
    }




  }



 

  mtemp: Morur;
  getselectedrow() : Morur {



    //tgyu7tui7777
    var r = this.dxgrid.focusedRowKey;
    if (r == null) return null;
    if (this.modlelist == null) return null;

   // console.log(this.focusedRowKey)
    for (var _i = 0; _i < this.modlelist.length; _i++) {

      if (r == this.modlelist[_i].id) {


        return this.modlelist[_i];
      }
 
    }

    if (this.modlelist.length > 0)
      return this.modlelist[0];

    return null;


  }
  onRowDblClick(e) {
    this.onClickRizghardesh();
  }
  onClickRizghardesh() {


    let selectedrow = this.getselectedrow();
    if (selectedrow == null)
      return;

    if (this.morurOption.dir == null)
      this.morurOption.dir = [];

    //تنظیمات را کپی کرده و یک سطح بهش اضافه میکنم که همان سطح و ای دی ریز گردش است
    let morurforrizoption = JSON.parse(JSON.stringify(this.morurOption)) as MorurOption;


    let maxsort = 0;
    for (var i = 0; i < morurforrizoption.dir.length; i++) {

      if (morurforrizoption.dir[i].sort > maxsort)
        maxsort = morurforrizoption.dir[i].sort;

    }
    maxsort++;
    let sam = new Diri();
   
    sam.level = morurforrizoption.nowlevel;
    sam.sort = maxsort;
    sam.htid = selectedrow.hesOrTafId;
    sam.htidCode = selectedrow.code;
    sam.htidName = selectedrow.name;

    morurforrizoption.dir.push(sam);


    this.OnRizGhardesh.emit(morurforrizoption);

  }
  onContentReady() {

    this.alertify.message('');
  }
  test() {

   // this.dxgrid.autoNavigateToFocusedRow = true;

   // this.dxss.
    //var y = this.dxgrid.instance.getScrollable();
    //y.scrollTo(100);
 //yh54re76587568
    var y = this.dxgrid.instance.getScrollable();
  
   var yyy= this.dxgrid.instance.getRowElement(20);
    y.scrollToElement(yyy);

    y.update();
    console.log(y.scrollHeight() + 'h2h');
    //var scl = $("#gridContainer").find('.dx-datagrid-rowsview').dxScrollable('instance');
    //scl.scrollTo({ left: 1000 });  

   // var para = document.createElement('p');
   // var title = document.createElement('title');

   // // define some window attributes
   // var features = 'width=1200, height=900, status=1, menubar=1, location=0, left=100, top=100';
   // var winName = 'print';

   // // populate the html elements
   // para.textContent = '<p>fghjfgjghkjgh</p>';
   // title.textContent = 'New Window Title';
     
   // // define a reference to the new window
   // // and open it with defined attributes
   // var winRef = window.open('', winName, features);

   // // append the html elements to the head
   // // and body of the new window
   // //winRef.document.head.appendChild(title);
   // //winRef.document.body.appendChild(para);

  
   // winRef.document.write(this.MorurService.getprint(this.modlelist));
   // //var printContents = document.getElementById('printableArea').innerHTML;
   // //var originalContents = document.body.innerHTML;

   // //document.body.innerHTML = printContents;

   // //window.print();

   // //document.body.innerHTML = originalContents;
   // //this.openprint = true;


   // //let l = origin + '/MorurHt'; 
   // //console.log(l);


   //// window.open(l);
   ////// var r = this.dxgrid.focusedRowKey;

  

   //// console.log(JSON.stringify(r));


  }
  Ondofilterclicked(e) {


  //  console.log(JSON.stringify(this.morurOption));
    this.loadmorursub(this.morurOption,0);
  }
  onClickReflesh() {

    this.loadmorursub(this.morurOption,0);
  }

  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' مروز حساب ها ');

  }
  onClickprint(kind) {


    var t = [];
    for (var i = 0; i < this.modlelist.length; i++) {

      t.push(this.modlelist[i]);
    }


    var mdata = { orgrows: t, orgheader: this.morurOption, printKind: kind }

    this.OnMyprint.emit(mdata);

  }
  saveuioption() {

    // console.log(this.gridContainer.instance.getVisibleColumns()[1].width);

    var v = this.dxgrid.instance.getVisibleColumns();


    var res = [];
    for (var i = 0; i < v.length; i++) {
      res.push(v[i].width);

    }

    this.localst.optioncli.ui_morurrep_grid_width = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }

 
}


