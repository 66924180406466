import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';

import { HttpClientModule, HttpClient } from "@angular/common/http";

import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { MatDialog } from '@angular/material';

import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { Localst } from '../../../../_services/localst.service';
import { AnDeedRepOp } from '../../../../_models/app2/rep/AnDeedRep';
 
@Component({
  selector: 'app-AnDeed-rep',
  templateUrl: './AnDeed-rep.component.html',
  styleUrls: ['./AnDeed-rep.component.css']
})


export class AnDeedrepComponent implements OnInit {


  @Input() cshomareOne: number = -1;



  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();



  @Input() set printnum(valu: number) {
    if (valu) {
      this.option.codeS = valu;
      this.option.codeE = valu;
    }

  }



  option: AnDeedRepOp;

  lv3: boolean;
  lv4: boolean;
  lv5: boolean;
  lv6: boolean;

  constructor(private userService: UserService, private alertify: AlertifyService,
    public dialog: MatDialog,
    private localst: Localst,
  ) {

    let y = localst.yearnow_get();

    this.option = { year: y, codeS: 6, codeE: 1, dateS: 0, dateE: 0, dateE_S: null, dateS_S: null, kind: 0 };




  }

  ngOnInit() {

  }


  onClickClose() {

    this.onClose.emit(true);

  }

  print() {


    this.option.year = this.localst.yearnow_get();
    this.onPrint.emit(this.option);



  }
}

