export module mymodule {


  export class myloadprogses {

    loadeditem: fieldss[];
    public progress: number;
    tedad: number;
    eachitemdarsad: number;
    public alloaded: boolean;




    constructor(tedad: number) {
      this.alloaded = false;
      this.tedad = tedad;
      this.loadeditem = [];
      this.progress = 0;
      this.eachitemdarsad = 100 / this.tedad;

      for (let i = 0; i < this.tedad; i++) {
        var l = new fieldss();
        l.id = i;
        l.Name = '';
        l.loaded = false;
        l.loadcount = 0;
        this.loadeditem.push(l);

      }

    }
    set_Loaded(fieldnum: number) {
      this.loadeditem[fieldnum].loaded = true;
      this.loadeditem[fieldnum].loadcount++;
    }
    set_UnLoaded(fieldnum: number) {
      this.loadeditem[fieldnum].loaded = false;
    }

    readprogress(): number {
      this.alloaded = false;;
      this.progress = 0;
      var counter = 0;
      for (let i = 0; i < this.tedad; i++) {

        if (this.loadeditem[i].loaded == true) {

          this.progress = this.progress + this.eachitemdarsad;
          counter++;

        }
      }
      if (counter == this.tedad) {
        this.alloaded = true;
        this.progress = 100;
      }


      return this.progress;
    }



  }

  class fieldss {

    id: number;
    Name: string;
    loaded: boolean;
    loadcount: number;
  }
}
