import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnGardeshRep, AnGardeshRepOp } from '../../../_models/app2/rep/AnGardesh';
 
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})


export class AnGardeshRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
  constructor(private http: HttpClient, private localstService: Localst) {

 

  }
   


  EmptyAnGardeshRep: AnGardeshRep = {
    option: null,
    rows: null
  }

  EmptyAnGardeshRepOp: AnGardeshRepOp = {
    codeE: 0,
    dateS_S: '',
    dateS: 0,
    dateE_S: null,
    dateE: 0,
    year: 0,
    anbars: [],
    kalaIds: [],
    tabages: []
  }


 

  getEmptyAnGardeshRep(): AnGardeshRep {

    let l = JSON.parse(JSON.stringify(this.EmptyAnGardeshRep)) as AnGardeshRep;
    l.option = JSON.parse(JSON.stringify(this.EmptyAnGardeshRepOp));
    return l;


  }

  getEmptyAnGardeshRepOp(): AnGardeshRepOp {


    return JSON.parse(JSON.stringify(this.EmptyAnGardeshRepOp));


  }

  AnGardeshRep(data: AnGardeshRepOp): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnGardeshRep/AnGardeshRep';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }
}
