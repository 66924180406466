import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Localst } from './localst.service';
import { ApiRet } from '../_models/General/ApiRet';
 
 

@Injectable({
  providedIn: 'root'
})
export class LogcliService {
 // baseUrl = environment.apiUrl;
  origin = location.origin;
  
 
  constructor(private http: HttpClient, private ls: Localst) { }

  TestCon(cu: string, un: string): Observable<ApiRet> {


   
    let apiadress = '/api/Logcli/TestCon?cu='+cu +'&un=' +un;
   // var headers = this.ls.header_get(apiadress,null);
   // console.log(apiadress);
   // if (headers == null)
    //  return;
 
    return this.http.get<ApiRet>(this.origin + apiadress);
   // return this.http.get<any>(this.origin + apiadress, { 'headers': headers });
  }
 


 
}
