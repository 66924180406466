import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import {  VmTajRep, VmTajRepOp, VmTajRepRow } from '../../../_models/app98/rep/VmTajRep';
 
let EmptyVmTajRep: VmTajRep = {
  op: null,
  rows: [],
  rowSum: null,
};

let EmptyVmTajRepOp: VmTajRepOp = {
  isDateFilter: false,
  dateS: 0,
  dateS_S: null,
  dateE: 0,
  dateE_S: null,
  vmGrss: [],
  showTas: false
};

let EmptyVmTajRepRow: VmTajRepRow = {
 
    id: null,
  tafId: null,
  tafC: null,
  tafN: null,
  tafFName: null,
  tafLName: null,
  tafMelli: null,
  prcDar1: 0,
  prcDar2: 0,
  prcDar3: 0,
  prcDarSum31: 0,
  prcSumDar: 0,
  prcVam: 0,
  prcVamMandeh: 0,
vmGrsNs:null,
radif: 0

};
 
const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class VmTajRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

 
 
  getRep(op: VmTajRepOp): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getVmTajRep';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, op,{ 'headers': headers }).pipe();

  }
  getVmTajOneRep(tafId: number): Observable<ApiRet> {
    let apiadress = '/api/App98/Rep/VmRep/getVmTajOneRep?tafId=' + tafId;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

  }
  getVmTajRepEmpty(): VmTajRep {
    let l = JSON.parse(JSON.stringify(EmptyVmTajRep)) as VmTajRep;
    l.op = JSON.parse(JSON.stringify(EmptyVmTajRepOp));
    return l;

  }
  getVmTajRepOpEmpty(): VmTajRepOp {

    return JSON.parse(JSON.stringify(EmptyVmTajRepOp));

  }
 

}
