import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent, DxListComponent, DxValidatorComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadingService } from '../../../../_services/loading.service';
import { tabeventobj } from '../../../../_models/tabeventobj';
import { AnRepService } from '../../../../_services/app2/rep/AnRep.service';
import { ApiRet } from '../../../../_models/General/ApiRet';
 
 
import { Anbar } from '../../../../_models/app2/paye/anbar';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
import { AnDeed } from '../../../../_models/app2/opr/AnDeed';
import { AnEndService } from '../../../../_services/app2/end/AnEnd.service';
import { AnPrcBas } from '../../../../_models/app2/glob/AnPrcBas';
import { AnPrcBasService } from '../../../../_services/app2/glob/AnPrcBas.service';
 

@Component({
  selector: 'app2-AnStart-list',
  templateUrl: './AnStart-list.component.html',
  styleUrls: ['./AnStart-list.component.css']
})
 
 
export class AnStartListComponent implements OnInit {
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() onfchanged: EventEmitter<tabeventobj> = new EventEmitter<tabeventobj>();
  @Input() ftabindex: number;;
  @Input() hyperLinkOption: any;

  @Input() kindpage: number;
  @ViewChild("validAnbar", { static: false }) validAnbar: DxValidatorComponent;
  @ViewChild("validDate", { static: false }) validDate: DxValidatorComponent;
 
  @ViewChild("dxcolumlist", { static: false }) dxcolumlist: DxListComponent;
     //kind نوع صفحه
        //AnStartId ای دی سند
        //AnStartRowId ردیف فکوس
  @Input() inputdata: any;

 
 
  valdata: DialogData;
  selectedrow: any;
 
  myModelList: AnDeed[] = [];
  openeditId: number = -1;
  openedit: boolean = false;
   
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  loaddata: loadingCl;

  constructor(private userService: UserService,
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private anEndService: AnEndService,
    private anRepService: AnRepService,
 
    private anDeedService: AnDeedService,
    private anPrcBasService: AnPrcBasService,
  
 
    private excelService: ExcelService,
    private route: ActivatedRoute, private localst: Localst,
    public dialog: MatDialog) {
    
  

 
 
    this.loaddata = loadServ.getEmpty();
 
    this.loadServ.create(this.loaddata,20);
 
     
  } 
 

 
  ngOnInit() {

 



    this.load();
 
  }

  
  load() {

 

    this.loadServ.showloading(this.loaddata, 0);
    this.anEndService.createEftetah(this.localst.yearnow_get())
      .subscribe(res => {
       
        this.loadServ.hideloading(this.loaddata, 0);
        this.myModelList =res.data;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });
       
  }


 

 

 


  onClickDelete() {


     
    this.openDialog();
 

  }

  openDialog(): void {

   
    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });
   // dialogRef.componentInstance.message = 'jjj';
   
    dialogRef.afterClosed().subscribe(result => {
     
     // console.log('m:The dialog was closed');
      let isdelete = result;
     // console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    });
  }


  

  dodelete() {


    var key = this.dataGrid.focusedRowKey;
 
 

    //this.loadServ.showloading(this.loaddata,2);
    //this.AnEndService.delete(key).subscribe((res) => {
    //  this.loadServ.hideloading(this.loaddata,2);

    //  if (res.errors.length > 0) {
    //    this.alertify.showListString(res.errors);
    //  } else {
    //    this.load();
    //  }
    
      
  

    //}, error => {
    //    this.loadServ.hideloading(this.loaddata,2);
    //        this.alertify.error(error);
    //        console.log("myerror:" + error)
    //      }); 
  

  
     
  }


 

 


 onRowDblClick(e) {
 

  }

 

  onClickReflesh() {

 
 
    this.load();

  }
 
  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance, ' لیست اسناد ');

  }
  
   
  onanbarchanged(e) {

    if (e.value == undefined)
      return;
    var h = e.component.option('selectedItem') as Anbar;

    //this.myModel.anbarId = h.id;
    //this.myModel.anbarN = h.name;
   


    //this.loadServ.showloading(this.loaddata, 2);
    //this.anDeedService.getMaxDateOfDeed(this.localst.yearnow_get() , h.id).subscribe((res) => {
    //  this.loadServ.hideloading(this.loaddata, 2);

    //  if (res.errors.length > 0) {
    //    this.alertify.showListString(res.errors);
    //  }
    //  else {
    //    this.myModel.dateA_S = res.data.date_S;
    //  }

 


    //}, error => {
    //  this.loadServ.hideloading(this.loaddata, 2);
    //  this.alertify.error(error);
    //  console.log("myerror:" + error)
    //});




     
  }

  
  onEftetah() {





  }
 
  onSaveAnDeed(e) {



    if (e == null)
      return;
 //yh54756756875687
    var deed = this.myModelList.find(x => x.id == e.key);

    if (deed == null || deed == undefined)
      return;


    if (deed.id > 0) {
      this.alertify.warning('برای این رکورد سند صادر شده');
      return;
    }
     
    //else
    //  deed.id =-1


    this.loadServ.showloading(this.loaddata, 1);
    this.anDeedService.add(deed)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 1);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.mes);
          this.load();
        }



      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });


  }
  onDeleteAnDeed(e) {

    if (e == null)
      return;
    //yh54756756875687
    var deed = this.myModelList.find(x => x.id == e.key);

    if (deed == null || deed == undefined)
      return;


    this.loadServ.showloading(this.loaddata, 1);
    this.anDeedService.delete(deed.id, 'sysAnDeedStartDelete--force')
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 1);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.mes);
          this.load();
        }



      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });









  }
  temp_dateAchanged(e, cellInfo) {

    if (e.value == undefined || e.value == null || e.value == '') {

      return;
    }

    cellInfo.data.dataA_S = e.value;

    let sd = e.value;
    sd = sd.replace('/', '').replace('/', '');

    if (sd.length < 8)
      return;


    cellInfo.data.dateA_S = sd.substring(0, 4) + '/' + sd.substring(4, 6) + '/' + sd.substring(6, 8);
    cellInfo.data.dateA = +sd;

    


  }



}


