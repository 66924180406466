import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { Sherkat } from '../../../_models/app1/paye/sherkat';
import { Localst } from '../../localst.service';
import { ApiRet } from '../../../_models/General/ApiRet';
import { IdName } from '../../../_models/General/IdName';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptySherkat: Sherkat = {

  id: null,
  code: null,

  name: null,

  nameE: null,
  nameEkhtesar: null,

  shenase: null,

  codeEghtesadi: null,


  shomareSabt: null,

  kindShekatId: null,
  kindShekatN: null,

  faliat: null,

  goruh: null,
  shekatMarja: null,

  radif: null,
  ostanCode: null,
  shahrCode: null,
  ostanName: null,

  shahrName: null,

  adress: null,

  codePost: null,

  mobile: null,
  tell: null,
  email: null,
  webSite: null,

  tafsilis: null,
  tafsilisId: null,
  cnaghsh: null,
  shEghtesadi: null,
  _ignore_sh: false,
  visib: true,
  dis: null,
  tob: 2,
  tobN: null,
    shobe:null

};
let HeaderSherkat: any = {

  id: "Id",
  code: "کد تفصیلی",
  name: "نام",
  nameE: "نام لاتین",
  nameEkhtesar: "نام اختصاری",
  shenase: "شناسه",
  codeEghtesadi: "کد اقتصادی",
  shomareSabt: "شماره ثبت",
  kindShekatId: "نوع شرکت",
  kindShekatN: "نوع شرکت",
  faliat: null,
  goruh: null,
  shekatMarja: null,
  radif: 0,
  ostanCode: null,
  shahrCode: null,
  ostanName: null,
  shahrName: null,
  adress: null,
  codePost: null,
  mobile: null,
  tell: null,
  email: null,
  webSite: null,
  tafsilisId: null ,
  cnaghsh: null ,


};

let toblist: IdName[] = [
  //{ id: 1, name: "حقیقی" },
  { id: 2, name: "حقوقی" },
  //{ id: 3, name: "مشاركت مدنی" },
  //{ id: 4, name: "اتباع غیر ایرانی" },
  //{ id: 5, name: "مصرف کننده نهایی" },

];

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class SherkatService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getallnopage(): Observable<Sherkat[]> {
    let apiadress = '/api/App1/Paye/Sherkat/getallnopage';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Sherkat[]>(this.origin + apiadress, { 'headers': headers });
  }

  getSherkats_P(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Sherkat[]>> {
    let apiadress = '/api/App1/Paye/Sherkat/getall';
    var headers = this.localstService.header_get(apiadress,null);


    const paginatedResult: PaginatedResult<Sherkat[]> = new PaginatedResult<Sherkat[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (userParams != null) {
      params = params.append('NameFarsi', userParams.NameFarsi);
      params = params.append('FamilyFarsi', userParams.FamilyFarsi );
      params = params.append('Code', userParams.Code );
 
    }
    
    return this.http.get<Sherkat[]>(this.origin + apiadress, { 'headers': headers , observe: 'response', params })
      .pipe(
        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
    );
   
    //for (var val of getlist) {

    //  console.log(val); // prints values: 10, 20, 30, 40
    //}
   // console.log(getlist[0].Onvan);
 

  }


 
  getSherkat(id): Observable<Sherkat> {
    let apiadress = '/api/App1/Paye/Sherkat/getone?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Sherkat>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  getSherkatbytafsiliid(tafsiliid): Observable<Sherkat> {
    let apiadress = '/api/App1/Paye/Sherkat/getonebyTafsiliId?tafsiliid=' + tafsiliid.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Sherkat>(this.origin + apiadress, { 'headers': headers }).pipe(

    );

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
  addSherkat(item: Sherkat): Observable<ApiRet>{
    let apiadress = '/api/App1/Paye/Sherkat/addSherkat';
    var headers = this.localstService.header_get(apiadress,null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getSherkatEmpty(): Sherkat {

 
    return JSON.parse(JSON.stringify(EmptySherkat));

  }
  getTafsiliHeader(): any {

    return HeaderSherkat;


  }
  updateSherkat(item: Sherkat) :Observable<ApiRet> {
    let apiadress = '/api/App1/Paye/Sherkat/updateSherkat';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }

 
  deleteSherkat(SherkatId: number): Observable<number> {
    let apiadress = '/api/App1/Paye/Sherkat/deletebyid?id=' + SherkatId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<number>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
 
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Sherkat/countSherkatcode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  SherkatListCode(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Sherkat/Sherkatcodelist';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });


  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }

  codeEghAndShenase(): Observable<string[]> {
    let apiadress = '/api/App1/Paye/Sherkat/listCodeEghAndShenase';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });

  }

  gettoblist(): any {

    return toblist;


  }
}
