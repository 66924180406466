import { IdName } from "../../General/IdName";

 export interface Morur {

   id: number,
   radif: number;

 level: number,
 hesOrTafId: number,
 code: string,
   name: string,
   bed: number,
   bes: number,
   mandehBed: number,
   mandehBes: number,
   MorurRep: number,
   legpx : number
 }

export interface MorurOption {

 

  mainlevel: number,

  nowlevel: number,
  oldlevel: number,





  ismother: boolean,
  dir: Diri[],

  lvset1: boolean,
  lvset2: boolean,
  lvset3: boolean,
  lvset4: boolean,
  lvset5: boolean,
  lvset6: boolean,

  yearS: number,
  yearE: number,
  dateS: string,
  dateE: string,

  useCShomare: boolean,
  cShomareS: number,
  cShomareE: number,

  allkindselected :boolean,
  KindSanad: IdName[],
  hesabKind: IdName[],
  bamandeh: boolean,


  sakhtar: number,
  kindMandeh: number,
  kindReport: string,
  tarazOlaviat: Diri[],
  l1: number[],
  l2: number[],
  l3: number[],
  l4: number[],
  l5: number[],
  l6: number[]

}




export class Diri {
  sort: number;

  level: number;
  levelName: string;
  htid: number;
  htidCode: string;
  htidName: string;


  selectedId: number;



  constructor() {
   
  }
 
}
