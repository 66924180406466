import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { TafDaste } from '../../../../_models/app1/paye/TafDaste';
import { TafDasteService } from '../../../../_services/app1/paye/TafDaste.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';

import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

 
import { aaa } from '../../../../_models/aaa';
import { mymodule  } from '../../../../../../my_modules/mymodule';
import { LoadingService } from '../../../../_services/loading.service';
//import { myloadprogses } from '../../../../_models/myloadprogses';


@Component({
  selector: 'app-TafDaste-edit',
  templateUrl: './TafDaste-edit.component.html',
  styleUrls: ['./TafDaste-edit.component.css']
})
export class TafDasteEditComponent implements OnInit {

  rules: any;

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() evTafDasteChanged = new EventEmitter();
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  myModle: TafDaste;
  myModle_old: TafDaste;
  myModle_loaded = false;

  sho: false;
  somest: string;
  someint: number;
  kindTafDaste: IdName[] = [
    { id: 1, name: "دائمی" },
    { id: 2, name: "موقت" },
    { id: 3, name: "انتظامی" },
  ];
  mahiyatdb: IdName[] = [

    { id: 1, name: "بدهکار" },
    { id: 2, name: "بستانکار" },
    { id: 3, name: "بدهکار بستانکار" },
  ];
  private sub: any;
  TafDastecodelist_loaded = false;
  TafDastecodelist: number[];
  validnum: string;

  ProgressValue: number =0;
  loadbar: mymodule.myloadprogses;
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService,
    private router: Router,
    private loadServ: LoadingService,
    private TafDasteService: TafDasteService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
 
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 5);
    console.log(this.editId);

    console.log(this.myModle);
    this.rules = { "X": /[02-9]/ };
   
    this.validationCallback = this.validationCallback.bind(this);
    this.loadbar = new mymodule.myloadprogses(2);
   
    

  }

  ngOnInit() {
    this.myModle = this.TafDasteService.getTafDasteEmpty();
    this.myModle_old = this.TafDasteService.getTafDasteEmpty();
   

    this.isnew = true;
    console.log("init");
    this.sub = this.route.params.subscribe(params => {
      //this.editId = +params['id']; // (+) converts string 'id' to a number
      this.readmodle();
      // In a real app: dispatch action to load the details here.
    });

    this.loadServ.showloading(this.loaddata, 0);
    this.TafDasteService.TafDasteListCode().subscribe(data => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.TafDastecodelist = data;
      this.TafDastecodelist_loaded = true;
      this.loadbar.set_Loaded(0);
      this.ProgressValue = this.loadbar.readprogress();
    }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }




  readmodle() {
 
    console.log( "edit id : " + this.editId);

    if (this.editId == -1) {
      console.log('in new mode');
      this.TafDastecodelist_loaded = true;
      this.loadbar.set_Loaded(1);
      this.ProgressValue = this.loadbar.readprogress();
      this.myModle = JSON.parse(JSON.stringify(this.TafDasteService.getTafDasteEmpty()));
    }
    else {
      console.log('in edit mode');
      this.isnew = false;

      this.loadServ.showloading(this.loaddata, 1);
      this.TafDasteService.getTafDaste(this.editId).subscribe(
         (data: TafDaste) => {
          this.loadServ.hideloading(this.loaddata, 1);
          this.myModle = data;
          
           this.myModle_old = JSON.parse(JSON.stringify(this.myModle));
     
        //   console.log( JSON.stringify(  this.myModle));

           this.myModle_loaded = true;
           this.TafDastecodelist_loaded = true;
           this.loadbar.set_Loaded(1);
           this.ProgressValue = this.loadbar.readprogress();
     
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 1);
          this.alertify.error(error);
         }

      );
    }




  }

  addthis = e => {


 
    console.log("The button save was clicked");






  }
  cancelthis(e) {


    console.log('cancel');
    this.OnMyCancelEvent.emit(true);


  }
  dooo = e => {

    console.log("dooo clicked");
    //this.loadbar.set_Loaded(1);
    //this.ProgressValue=  this.loadbar.readprogress();

  }

  onFormSubmit = function (e) {

    if (this.loaddata.showed)
      return;

    console.log("m:submitform");



    if (this.myModle.id == -1 && this.isnew) {

      this.loadServ.showloading(this.loaddata, 2);
      this.TafDasteService.addTafDaste(this.myModle).subscribe(next => {
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:iteme saved");
        this.loadServ.hideloading(this.loaddata, 1);
        //this.router.navigate(['/app1/paye/TafDaste/List']);
        this.OnMySaveEvent.emit(true);
      }, error => {
          this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
        console.log("myerror:" + error)
      }).add(() => {
        //Called when operation is complete (both success and error)
        this.evTafDasteChanged.emit();
        console.log('complete emit newHeasab_madule');
      });

    }
    else {

   //   console.log(JSON.stringify(this.myModle));
      this.loadServ.showloading(this.loaddata, 3);
      this.TafDasteService.updateTafDaste(this.myModle).subscribe(next => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.success('مشخصات با موفقیت بروز رسانی شد');
        console.log("m:saved");

        this.OnMySaveEvent.emit(true);

      }, error => {
          this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
        console.log("myerror:" + error)
      }).add(() => {
        //Called when operation is complete (both success and error)

        console.log('complete emit newHeasab_madule');
      });

    }


    e.preventDefault();
  }





  values = '';


  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;


  }
  isvalisss: boolean;
  dffd: any;
  onKey(e) {



  }


  validationCallback(e) {

 
    if (!this.loadbar.alloaded) {
 
      this.validnum = "loading... true"
      return true;
    }

    let v = e.value;
   
    if (v < 9999) {

      this.validnum = "کد وارده معتبر نیست";
      return false;

    }
 
    var q = Math.floor(v / 10000);
    for (var val of this.TafDastecodelist) {

      if (q == Math.floor(val / 10000)  && this.myModle_old.codeStartint != val ) {
    
        this.validnum = "کد وارده معتبر نیست";
        return false;

      }

    }
    this.validnum = "loading... true"

    return true


  }

  validateNumber(e) {



  }



}

//class myloadprogses {

//  loadeditem: fieldss[];
//  public progress: number;
//  tedad: number;
//  eachitemdarsad: number;
// public alloaded: boolean;




//  constructor(tedad: number) {
//    this.alloaded = false;
//    this.tedad = tedad;
//    this.loadeditem = [];
//    this.progress = 0;
//    this.eachitemdarsad = 100 / this.tedad;

//    for (let i = 0; i < this.tedad; i++) {
//      var l = new fieldss();
//      l.id = i;
//      l.Name = '';
//      l.loaded = false;
//      l.loadcount = 0;
//      this.loadeditem.push(l);

//    }

//  }
//  set_Loaded(fieldnum: number) {
//    this.loadeditem[fieldnum].loaded = true;
//    this.loadeditem[fieldnum].loadcount++;
//  }
//  set_UnLoaded(fieldnum: number) {
//    this.loadeditem[fieldnum].loaded = false;
//  }

//  readprogress(): number {
//    this.alloaded = false;;
//    this.progress = 0;
//    var counter = 0;
//    for (let i = 0; i < this.tedad; i++) {

//      if (this.loadeditem[i].loaded == true) {

//        this.progress = this.progress + this.eachitemdarsad;
//        counter++;

//      }
//    }
//    if (counter == this.tedad) {
//      this.alloaded = true;
//      this.progress = 100;
//    }


//    return this.progress;
//  }



//}

//class fieldss {

//  id: number;
//  Name: string;
//  loaded: boolean;
//  loadcount: number;
//}
