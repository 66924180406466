import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent } from 'devextreme-angular';
 

import { ChangeDetectorRef } from '@angular/core';
 
import DataSource from "devextreme/data/data_source";
import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ExcelService } from '../../../_services/excel.service';
import { Localst } from '../../../_services/localst.service';

import { ApiRet } from '../../../_models/General/ApiRet';

import { PdMoveRep } from '../../../_models/app4/rep/PdMoveRep';
import { PdPayrecRepService } from '../../../_services/app4/rep/PdPayrecRep.service';
import { PdPayrecRow, PdPayrecRowForChek } from '../../../_models/app4/opr/PdPayrec';
import { PdPayrecService } from '../../../_services/app4/opr/PdPayrec.service';
import { TafsiliService } from '../../../_services/app1/paye/Tafsili.service';
import { TafsiliForSmall } from '../../../_models/app1/paye/tafsiliForSmall';
import { PdMoveRepService } from '../../../_services/app4/rep/PdMoveRep.service';
 
 

@Component({
  selector: 'app4-PdMoveRep-list',
  templateUrl: './PdMoveRep-list.component.html',
  styleUrls: ['./PdMoveRep-list.component.css']
})
 

export class PdMoveReplistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
  @Input() PdMoveRepOption: any;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
 
  @ViewChild("UnSelectlist", { static: false }) UnSelectlist: DxListComponent;
  @ViewChild("Selectlist", { static: false }) Selectlist: DxListComponent;

  @ViewChild("pdpayrecrowComp", { static: false }) pdpayrecrowComp: DxSelectBoxComponent;
  @ViewChild("tafComp", { static: false }) tafComp: DxSelectBoxComponent;
  
 
  selectedrow: any;
 

  model: PdMoveRep;
  pdPayrecRowForChekList: PdPayrecRowForChek[];
 
  devDataTaf: any = {};
 
 
  loaddata: loadingCl;
 
 
  constructor(
  
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private pdMoveRepService: PdMoveRepService,
    private pdPayrecService: PdPayrecService,
    private tafsiliService: TafsiliService,

    private excelService: ExcelService,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
 
 
 
    this.loadServ.create(this.loaddata,8);



    this.model = pdMoveRepService.getPdMoveRepEmpty();
   
 //   this.model.option.gSelect.

  }
  
  ngOnInit() {


  

    this.loadServ.showloading(this.loaddata, 2);
    this.pdPayrecService.getPayrecRowForCheckForRep()
      .subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 2);
     
        this.pdPayrecRowForChekList = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });




    this.loadServ.showloading(this.loaddata, 4);
    this.tafsiliService.GetTafsiliForSmall()
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 4);


        this.devDataTaf = new DataSource({
          store: res,
          paginate: true,
          pageSize: 50
        });

      }, error => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.alertify.error(error);
      });

  
  }

 
 


  ngAfterViewInit() {

   
  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
 

 


  reloadrows(): void {
 
  }

 
  onpdpayrecrowchanged(e) {

    if (e.value == null)
      return;

    var it = this.pdPayrecRowForChekList.find(obj => { return obj.id === e.value });

 

    this.model.option.pdPayrecRows.push({ id: it.id, name: it.ckCode });

    this.pdpayrecrowComp.value = null;

  }


  ontafchanged(e) {

    if (e.value == null)
      return;

      var it = this.devDataTaf._store._array.find(obj => { return obj.id === e.value });
     
      this.model.option.tafs.push({ id: it.id, name: it.code + ' ' + it.name });
    this.tafComp.value = null;
  }



  delpdpayrecrow(e) {

    this.model.option.pdPayrecRows = this.model.option.pdPayrecRows.filter(({ id }) => id !== e.id);
  }
  deltafrow(e) {

    this.model.option.tafs = this.model.option.tafs.filter(({ id }) => id !== e.id);
  }
  onFocusedRowChanged(e) {
 
 
 

  }




  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.dataGrid.instance,'  hh ');

   // this.excelService.exportAsExcelFile(this.modelsearch, 'sample');

  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickgoSanad();

     
  
 
  }

  onClickReflesh() {

    
    
    this.loadServ.showloading(this.loaddata, 5);
    this.pdMoveRepService.getRep(this.model.option)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 5);

        //tyytu676767
        this.model = res.data;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
      });

  }

  adjustWidth(e, w) {

    e.component._popup.option('width', w);
   
  }
 
  OnMyCancelEvent(e) {
 
  }
  OnMySaveEvent(e) {

 

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

   
 

  firstclreate = true;
 

  dropopened(e) {


    e.component._popup.option('width', 500);
    
    if (this.firstclreate) {


      this.dxliststr.instance.selectAll();

     

      this.firstclreate = false;

    }
  }

   

  onCellPrepared(e) {

   //// console.log(e.data);
   // if (e.data == undefined)
   //   return;


   // if (e.column.dataField == "prcMan") {

   //   if (e.data.anDeedC == 0) {
   //     if (e.data.prcMan > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.amtMan < 0) {
   //       e.cellElement.style.backgroundColor = '#ca0000';

   //     }
 
   //   }
   // }
 
   // if (e.column.dataField == "prcOut") {

   //   if (e.data.anDeedC == 0) {
        

   //     if (e.data.prcOut > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.prcOut < 0) {
   //       e.cellElement.style.color = '#ca0000';

   //     }

   //   }
   // }


   // if (e.column.dataField == "fiOut") {

   //   if (e.data.anDeedC == 0) {



   //     if (e.data.fiOut > 0) {
   //       e.cellElement.style.color = '#19bf00';

   //     }
   //     else if (e.data.fiOut < 0) {
   //       e.cellElement.style.color = '#ca0000';

   //     }
   //   }
   // }


 

   



  }
  onRowPrepared(e) {

   // // console.log(e.data);
   // if (e.data == undefined)
   //   return;
     
   // //hjg5565655
   // if (e.data.kind == 9  ) {
 
   //   e.rowElement.style.color = '#19bf00';
   // //  e.rowElement.style.border = '1px solid';

     
   // }
   //else if (e.data.kind == -9) {

   //   e.rowElement.style.color = '#ca0000';
   // //  e.rowElement.style.border = '1px solid';

   // }

   //  if (e.data.anDeedC == 0) {


   //   e.rowElement.style.border = '1px solid';

   // }





  }

  selectgroup() {

    let se = this.UnSelectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gUnSelect) {
      if (r.id == se[0]) {
        this.model.option.gSelect.push(r);
        break;
      }

    }
    this.model.option.gUnSelect = this.model.option.gUnSelect.filter(({ id }) => id !== se[0]);


  }

  unselectgroup() {
    let se = this.Selectlist.selectedItemKeys;

    if (se[0] == null)
      return;

    for (var r of this.model.option.gSelect) {
      if (r.id == se[0]) {
        this.model.option.gUnSelect.push(r);

        break;
      }

    }
    this.model.option.gSelect = this.model.option.gSelect.filter(({ id }) => id !== se[0]);


  }

  toupgroup(e) {
    for (var i = 0; i < this.model.option.gSelect.length; i++) {
      if (this.model.option.gSelect[i].id == e.id) {

        if (i == 0)
          break;

        var temp = this.model.option.gSelect[i - 1];

        this.model.option.gSelect[i - 1] = this.model.option.gSelect[i]
        this.model.option.gSelect[i] = temp;
        break;
      }

    }

  }
  RowPrepared(e) {

    if (e.key < 0) {
      /*  e.rowElement.style.border = '10px solid';*/
      e.rowElement.style.backgroundColor = '#E1E1F0';
    }

    //e.rowElement.css('background', 'green');
  }


  onClickgoSanad() {

    //yttyyt566767
    var key = this.dataGrid.focusedRowKey;
    if (key == null)
      return;
 
  }


  

  saveuioption() {

    var res = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var w = this.dataGrid.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
      else res.push(0);
    }
    this.localst.optioncli.gw2013 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');

  }


  addwidth(e) {

    for (var i = 0; i < this.localst.optioncli.gw2013.length; i++) {
      this.localst.optioncli.gw2013[i] *= e;
    }

  }
  defwidth() {

    this.localst.optioncli.gw2013 = this.localst.optionClient_readDefault('gw2013');

  }


  onClickprint(kind) {


 


    //var mdata = { model: JSON.parse(JSON.stringify(this.model)), printKind: kind, showRial: this.showRial }

    //this.onPrint.emit(mdata);

  }
 
}


