import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';

import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../_models/General/IdName';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OstanService } from '../../../_services/app1/paye/Ostan.service';
import { mySF } from '../../../../../my_modules/mySF';
import { MatDialog } from '@angular/material';
import { GeneralService } from '../../../_services/General/General.service';
import {  FileToUpload, SherkatInfo, SherkatInfoKeys } from '../../../_models/General/sherkatInfo';
import { Localst } from '../../../_services/localst.service';
import { OstanKol } from '../../../_models/app1/paye/ostanKol';
import { Ostan } from '../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../_services/loading.service';
import { Photo } from '../../../_models/photo';
import { DomSanitizer } from '@angular/platform-browser';
import { TaxGovService } from '../../../_services/General/tax/taxGov.service';

@Component({
  selector: 'appsetting-sherkatInfo-edit',
  templateUrl: './sherkatInfo-edit.component.html',
  styleUrls: ['./sherkatInfo-edit.component.css']
})
export class sherkatInfoEditComponent implements OnInit {

  theFile: any = null;
  messages: string[] = [];



  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() cancelRegister = new EventEmitter();
 
  myModel: SherkatInfo;
 
  shkhscodelist: string[];
  rules: any;
  sho: false;
  somest: string;
  someint: number;
   
  id: number;
  private sub: any;
  test: string;
    n: number;
  mytitle: string;
  fileToUpload: File | null = null;

  OstanKollist: OstanKol[];
  OstanlistA: Ostan[];
  OstanlistB: Ostan[];
 
  KindList: IdName[];
  editclass: string;
  loaddata: loadingCl;
    unsafeImageUrl: string;
  public imageUrl: any;
  constructor(private userService: UserService, private alertify: AlertifyService,
    private loadServ: LoadingService,
    private generalfoService: GeneralService,
    private taxGovService: TaxGovService,
    private localst: Localst,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private sf: mySF,
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 6);
 


    this.validationCallback = this.validationCallback.bind(this);  

    this.editclass = 'mydisble';
    this.myModel = generalfoService.getSherkatInfoEmpty();


  }

  ngOnInit() {
  




    this.loadServ.showloading(this.loaddata, 0);
    this.ostanService.getOstanKols().subscribe(data => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.OstanKollist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

    this.readlogo();
 
    this.isnew = true;



    this.reloadlist();
  }
 
  onFocusedRowChanged(e) {



  }
  addthis = e => {


  }
  cancelthis = e => {


  }


  onFileChange(event) {
    const MAX_SIZE: number = 1048576;
    this.theFile = null;
    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        this.theFile = event.target.files[0];
      }
      else {
        // Display error message
        this.alertify.error(' حجم فایل ' + event.target.files[0].name + ' بیشتر از یک مگابایت است ')
        this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
      }
    }
  }
  uploadFile(): void {
    this.readAndUploadFile(this.theFile);
  }
  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();

      // POST to server
      this.generalfoService.uploadFile(file).subscribe(resp => {
        this.readlogo();
        this.alertify.success('فایل با موفقیت آپلود شد');
        this.messages.push("Upload complete");
      });
    }

    // Read the file
    reader.readAsDataURL(theFile);
  }

  havLogo: boolean = false;

  readlogo() {


    console.log('m:image loading');
    this.generalfoService.getSherkatInfoLogo().subscribe(data => {

      if (data != null) {
        this.unsafeImageUrl = URL.createObjectURL(data);
        // ftruy56r856865
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
        this.havLogo = true;
      }
      else {
        this.havLogo = false;
      }

   
      //let objectURL = 'data:image/png;base64,' + data;
      //this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, error => {
      console.log(error);
    });;
 
 
 

  }

  reloadlist() {

    this.loadServ.showloading(this.loaddata, 8);
    this.generalfoService.getSherkatInfo()
      .subscribe((res: SherkatInfo) => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.myModel = res;
      }, error => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.alertify.error(error);
      });



       


  }

 

 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }



  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
  

  }
 // public  validationCallback(e: any): boolean {

 
  validationCallback(e) {
    return true

    let v = e.value;


    for (var val of this.shkhscodelist) {

      if (v == val) return false;
    }
    return true



}


  validateNumber(e) {

    

  }

  numberBox_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

 
   
    
     
  }
  
  datestart_valueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;
    console.log(newValue);

    let t =  this.sf.calcOtherThing(2);

 
  
   console.log(t);
 
  }

 

  
 
  onSaveEdit() {



    //console.log(JSON.stringify(this.myModel));
    this.loadServ.showloading(this.loaddata, 1);
    this.generalfoService.updateSherkatInfo(this.myModel).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;
      }
      this.alertify.success('مشخصات با موفقت ذخیره شد');
      this.localst.sherkatInfo = this.myModel;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }

  onEditrow(info) {


    this.myModel = info.data;
    this.isnew = false;
    this.editclass = 'myenable';
 
  }
  ostanAChanged(e) {
    if (e.value == null) return;
    this.ostanService.getOstans(e.value).subscribe(data => {
      this.OstanlistA = data;
    });
  }
  ostanBChanged(e) {
    if (e.value == null) return;
    this.ostanService.getOstans(e.value).subscribe(data => {
      this.OstanlistB = data;
    });
  }

 
}



class MyStaticFunction2 {
  static pi = 3.14;

  static ChekExistDate(radius: string) {
    return true;
  }


  static calculateArea(radius: number) {
    return this.pi * radius * radius;
  }

  calculateCircumference(radius: number): number {
    return 2;
  }







}
