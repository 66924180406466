import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdMoveRep, PdMoveRepOps } from '../../../_models/app4/rep/PdMoveRep';
import { CheckSadereRep, CheckSadereOps } from '../../../_models/app4/rep/CheckSadereRep';
 
 
let EmptyPdMoveRep: PdMoveRep = {
  option: null,
  rows: []
};
 



let EmptyPdMoveRepOps: PdMoveRepOps = {
  pdPayrecRows: [],
  kindPages: [],
  kindMoves: [],
  paykindIns: [],
  paykindRows: [],
  tafs: [],
  pdBanks: [],
  gSelect: [],
  gUnSelect: [{ id: 1, name: 'چک/سفته', colum: 'PdPayrecRow' },
    { id: 1, name: 'محل ثبت', colum: 'KindPage' },
    { id: 2, name: 'نوع عملیات', colum: 'KindMove' },
    { id: 3, name: 'داخلی/خارجی', colum: 'PaykindIn' },
    { id: 4, name: 'نوع', colum: 'PaykindRow' },
    { id: 5, name: 'دارنده', colum: 'Taf' },
    { id: 6, name: 'محل بانک', colum: 'PdBank' },
  ],
  isPdPayrecRow: false,
  isKindPage: false,
  isKindMove: false,
  isPaykindIn: false,
  isPaykindRow: false,
  isTaf: false,
  isPdBank: false
};



 





const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdMoveRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }
  

  getPdMoveRepEmpty(): PdMoveRep {

    let model = JSON.parse(JSON.stringify(EmptyPdMoveRep));
    model.option = JSON.parse(JSON.stringify(EmptyPdMoveRepOps));
    return model;

  }

 
   
  
  getRep(item: any): Observable<ApiRet>{
    let apiadress = '/api/App4/Rep/PdPayrecRep/getRep';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }
   
}

