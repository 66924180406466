import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { promise } from 'selenium-webdriver';
import { AnDeed, AnDeedAf, AnDeedBar, AnDeedRow, AnDeedRowForMabna } from '../../../_models/app2/opr/AnDeed';
import { KardexOps, KardexRep, KardexRow } from '../../../_models/app2/rep/KardexRep';
import { DateRangRep } from '../../../_models/General/DateRangRep';
import { AnDeedRep } from '../../../_models/app2/rep/AnDeedRep';
 


let EmptyAnDeedRep: AnDeedRep = {
  option: null,
  ones: []
}


const origin = location.origin;
@Injectable({
  providedIn: 'root'
})



export class AnRepService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

 
  constructor(private http: HttpClient, private localstService: Localst) {

 
  }



  getAnSellRowEmpty(): AnDeedRep {

     
    return JSON.parse(JSON.stringify(EmptyAnDeedRep));

  }
  reportHt_AnDeedsPrint(item: any): Observable<ApiRet> {
    let apiadress = '/api/app2/Rep/AnRep/report_AnDeedsPrint';
    var headers = this.localstService.header_get(apiadress, null);

    // console.log( item);
    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }










 
  Mojudi(year, date, kalaId, anbarId,ignoreId): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnRep/Mojudi?year=' + year.toString() + '&date=' + date + '&kalaId=' + kalaId + '&anbarId=' + anbarId+ '&ignoreId=' + ignoreId;

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  
  EmptyKardexRep: KardexRep= {
  option: null,
    rows: null,
    rowSum:null,
  }

  EmptyKardexOps: KardexOps = {
    year: null,
    dateA: 0,
    dateA_S: null,
    dateB: 0,
    dateB_S: null,
    kalaId: null,
    kalaC: null,
    kalaN: null,

    tabghId: null,
    tabghN: null,
    tabghRaveshId: null,
    tabghRaveshN: null,


    vahedId: null,
    vahedN:null,
    anbarIds: [],
    isAllAnbar: false,
    showRial: true
  }
EmptyKardexRow: KardexRow = {
  id: null,
  radif: null,
  date: null,
  date_S: null,
  anDeedC: null,
 anDeedId:null,
  kind: null,
  kindN: null,
  taf4Id: null,
  taf4N: null,
  vahedId: null,
  vahedN: null,
  anbarId: null,
  anbarN: null,
  amtIn: null,
  fiIn: null,
  prcIn: null,
  amtOut: null,
  fiOut: null,
  prcOut: null,
  amtMan: null,
  fiMan: null,
  prcMan: null,
}
  



  getKardexRepEmpty(): KardexRep {

    var a = JSON.parse(JSON.stringify(this.EmptyKardexRep)) as  KardexRep;
    a.option = JSON.parse(JSON.stringify(this.EmptyKardexOps)) as KardexOps;
    a.rowSum = JSON.parse(JSON.stringify(this.EmptyKardexRow)) as KardexRow;
    a.rows = [];

    return a;


  }
  getKardexOpsEmpty(): KardexOps {


    return JSON.parse(JSON.stringify(this.EmptyKardexOps));


  }
  getKardexRowEmpty(): KardexRow {


    return JSON.parse(JSON.stringify(this.EmptyKardexOps));


  }
  Kardex(data :KardexOps): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnRep/Kardex';

    var headers = this.localstService.header_get(apiadress, null);


    return this.http.post<ApiRet>(this.origin + apiadress, data, { 'headers': headers });
  }

  DateRangAnDeed(year): Observable<ApiRet> {
    let apiadress = '/api/App2/Rep/AnRep/DateRangAnDeed?year=' + year.toString();

    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  EmptyDateRangRep: DateRangRep = {
    yearA: null,
    dateA: null,
    dateA_S: null,
    yearB: null,
    dateB: null,
    dateB_S: null,
  }

  getDateRangRepEmpty(): DateRangRep {


    return JSON.parse(JSON.stringify(this.EmptyDateRangRep));


  }


 
  AnDeedJameRepAsExcel(item: any): Observable<Blob> {

    let apiadress = '/api/App2/Rep/AnRep/AnDeedJameRepAsExcel';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<Blob>(this.origin + apiadress, item, { 'headers': headers, responseType: 'blob' as 'json' });

  }





}


