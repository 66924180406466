import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';


import { MatDialog } from '@angular/material';


import { Localst } from '../../../../_services/localst.service';
import { AnCountService } from '../../../../_services/app2/end/AnCount.service';
import { LoadingService } from '../../../../_services/loading.service';
import { AnDeed } from '../../../../_models/app2/opr/AnDeed';
import { AnDeedService } from '../../../../_services/app2/opr/AnDeed.service';
import { AnCount } from '../../../../_models/app2/end/AnCount';
 
 
@Component({
  selector: 'app2-AnCount-Deed',
  templateUrl: './AnCount-Deed.component.html',
  styleUrls: ['./AnCount-Deed.component.css']
})


export class AnCountDeedComponent implements OnInit {




  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  title: string = '';

  @Input() inOutKey: number;

  @Input() anCountId: number;


  anCount: AnCount;

  myModel: AnDeed;
  loaddata: loadingCl;

  constructor(
    private alertify: AlertifyService,
    public dialog: MatDialog,
    private anCountService: AnCountService,
    private anDeedService: AnDeedService,
    private loadServ: LoadingService,
    private localst: Localst,
  ) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata, 8);
    let y = localst.yearnow_get();

 
 
  }
  lodedDeed: boolean = false;
  haveDeed: boolean = false;
  ngOnInit() {
    this.load();


  }

  load() {

    if (this.inOutKey == 1)
      this.myModel = this.anDeedService.getAnDeedEmpty(111);
    else if (this.inOutKey == -1)
      this.myModel = this.anDeedService.getAnDeedEmpty(-111);


    this.loadServ.showloading(this.loaddata, 3);
    this.anCountService.getbyid(this.anCountId).subscribe(
      (data: AnCount) => {

        this.loadServ.hideloading(this.loaddata, 3);
        this.anCount = data;
    
        this.createAndeed();
      }
      ,
      error => {
        this.loadServ.hideloading(this.loaddata, 3);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );
    
   

  }


  createAndeed() {
    //fgh66567jtu
  

    let createDeed = true;
    if (this.inOutKey == 1) {
      this.title = 'صدور سند اضافات انبارگردانی';
      if (this.anCount.anDeedInId == null) {
        createDeed = true;
        this.haveDeed = false;
      }
      else {
        createDeed = false;
        this.myModel = this.anCount.anDeedIn;
        this.haveDeed = true;
      }

    }
    else if (this.inOutKey == -1) {
      this.title = 'صدور سند کسورات انبارگردانی';
      if (this.anCount.anDeedOutId == null) {
        createDeed = true;
        this.haveDeed = false;
      }
      else {
        createDeed = false;
        this.myModel = this.anCount.anDeedOut;
        this.haveDeed = true;
      }
    }


    if (createDeed) {

      this.loadServ.showloading(this.loaddata, 5);
      this.anCountService.CreateAnDeed(this.anCount.id, this.inOutKey).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.myModel = res.data as AnDeed;
          this.lodedDeed = true;

        }

        // this.onSave.emit(true);


      }, error => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });
    }
  }

  onClickClose() {

    this.onClose.emit(true);

  }

  sodur() {

    //قبل از خواندن سند دکمه صدور نزنیم
    // یا سند صادر شده دوباره صادر نشود
    if (!this.lodedDeed) {
      this.alertify.error('سند ساخته نشده');
      return;
    }
    if (this.haveDeed) {
      this.alertify.error('سند صادر شده');
      return;
    }
    this.loadServ.showloading(this.loaddata, 5);
    this.anDeedService.add(this.myModel).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 5);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        //this.myModel = res.data as AnDeed;
        //this.haveDeed = true;
        this.load();
        this.onSave.emit(true);
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }

  delete() {

 
    if (!this.haveDeed) {
      this.alertify.error('سند صادر نشده');
      return;
    }



    this.loadServ.showloading(this.loaddata, 5);
    this.anDeedService.delete(this.myModel.id,'sysAnCountDelete--force').subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 5);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.myModel = null;
 
        this.load();
        this.haveDeed = false;
        this.onSave.emit(true);
      }
    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });



  }
}

