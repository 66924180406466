import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { PdHes } from '../../../_models/app4/paye/PdHes';
 
 
let EmptyPdHes: PdHes = {
  id: -1,
  code: null,
  name: null,
  dis: null,
  pattern: null,

  hesabAId: null,

  hesabAC: null,

  hesabAN: null,
  pdMoveKind:null,
  pdMoveKindN:null
};



let pdMoveKindList: any[] = [
  { id: 10, name:'فرم دریافت' },
 
];


const origin = location.origin;
@Injectable({
  providedIn: 'root'
})
export class PdHesService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(pdMoveKind: number ): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdHes/getall?pdMoveKind=' + pdMoveKind.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdHes/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress, null);


    let v = this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();

    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }

  add(item: PdHes) {
    let apiadress = '/api/App4/Paye/PdHes/add';
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getPdHesEmpty(): PdHes {


    return JSON.parse(JSON.stringify(EmptyPdHes));

  }
  getkindMovePatternList(): Observable<ApiRet> {

    let apiadress = '/api/App4/Paye/PdHes/getkindMovePatternList';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }

  update(item: PdHes) {
    let apiadress = '/api/App4/Paye/PdHes/update';
    var headers = this.localstService.header_get(apiadress, null);



    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(PdHesId: number): Observable<ApiRet> {
    let apiadress = '/api/App4/Paye/PdHes/delete?id=' + PdHesId.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
 
}

