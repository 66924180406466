import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { GrKala, GrmaskOption } from '../../../../_models/app2/paye/GrKala';
import { GrKalaService } from '../../../../_services/app2/paye/GrKala.service';
import { DxTreeViewComponent } from 'devextreme-angular';
 
 
export interface DialogData {
  animal: string;
  name: string;
}
 
@Component({
  selector: 'app2-GrKala-list',
  templateUrl: './GrKala-list.component.html',
  styleUrls: ['./GrKala-list.component.css']
})


export class GrKalaListComponent implements OnInit {

  openedit: boolean;
  cssGroup: string ='mydisble';

  openeditId: number;

  @ViewChild('treeView', { static: false }) treeView: DxTreeViewComponent;

 
  modlelist: GrKala[];
  grmaskOption: GrmaskOption;
 
  priorities: any[];
  items: any;
 

  charcount: number =0;

  nprint: string='';
 
  lbl: string;
  selectedrow: GrKala;
  codemask: string;
  codelist: string[];
 // newGrKala: GrKala;
  //animal: string;
  //name: string;
  isdelete: string;
  cssone: string;
  csstwo: string;
  cssthree: string;
  isnew: boolean=false;
  isinedit: boolean=false;
  kind2list: any[];

  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private GrKala_Service: GrKalaService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {

    this.loaddata = loadServ.getEmpty();
    this.priorities = [
      { val: 'دستی', id: 1 },
      { val: 'شمارنده', id: 2 },
      { val: 'الگو پذیر', id: 3 },
    
    ];
    this.kind2list= [{ id: 1, name: 'فقط عدد' }, { id: 2, name: 'عدد و حروف' }];
    this.lbl = '';
    this.codemask='0'
    this.openeditId = -1;
    this.validationCallback = this.validationCallback.bind(this);
    this.cssone= 'mydisble';
    this.csstwo= 'myenable';
    this.cssthree= 'mydisble';
    this.loadServ.create(this.loaddata,15);
    this.modlelist = [];
    this.selectedrow = this.GrKala_Service.getGrKalaEmpty();
 
    this.grmaskOption = this.GrKala_Service.getGrmaskOptionEmpty();
 
    
    this.items = [
 
      { text: 'جدید', id: 'add' },
      { text: 'افزودن شاخه جدید', id: 'addzero' },
      { text: 'حذف', id: 'delete' },

    ];
  }

  itemClick(e) {
    if (!e.itemData.items) {
      let a = e.itemData.id;
      if (a == 'add')
        this.onClickAddtree(false);
   
      else if (a == 'addzero') {

        this.onClickAddtree(true);

      }
     
    }



  }
 


  ngOnInit() {
   
    this.isdelete = "no";;
    this.load();
    this.loadcodelist();

  }
 
  loadcodelist() {
    this.loadServ.showloading(this.loaddata, 9);
    this.GrKala_Service.getallCode().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.codelist = data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });

  }
  
  load() {


 


    this.loadServ.showloading(this.loaddata,0);
    this.GrKala_Service.getall()
      .subscribe((res: GrKala[]) => {
        this.modlelist = [];
        this.modlelist = res;
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 6);
    this.GrKala_Service.readPattern()
      .subscribe((res: any) => {
        this.grmaskOption = res;
        this.patvaluchang();
        this.nprint = this.codemask;
        this.loadServ.hideloading(this.loaddata, 6);

      }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.alertify.error(error);
      });


  }
 
 
  reloadrows(): void {
    this.load();
    console.log("m:page refleshe done");
    //  this.load();
  }

 
  treeMenu(e) {
    //مهم . دیپ کپی برای اینکه وقتی دکمه جدید زدیم ادی ردیف های درخت منفی یک نشه
   this.selectedrow = JSON.parse(JSON.stringify( e.itemData));

    if (this.selectedrow.id == -1)
      this.isnew = true;
    else this.isnew = false;

    this.isinedit = false;
  // console.log(  e.itemData);

  }
  onClickAddtree(isnewmain:boolean) {

    if (this.loaddata.showed)
      return;


    //console.log()
    //روی هیچ کدوم کلیک نکرده بودیم
    let old = JSON.parse(JSON.stringify(this.selectedrow)) as GrKala;
   
   // this.selectedrow.id = -1
    this.selectedrow = this.GrKala_Service.getGrKalaEmpty();
    if (isnewmain) {

      
      this.selectedrow.id = -1
      this.selectedrow.level = 1;
      this.selectedrow.treeId = 1;
      this.selectedrow.pTreeId = 0;
      old =this.GrKala_Service.getGrKalaEmpty();
    }
    else {
      this.selectedrow.id = -1
      this.selectedrow.level = old.level+1;
      this.selectedrow.treeId = 1;
      this.selectedrow.pTreeId = old.treeId;
  
    }

    for (var i = 0; i < this.modlelist.length; i++) {
    
        if (this.modlelist[i].treeId >= this.selectedrow.treeId) {
          this.selectedrow.treeId = this.modlelist[i].treeId + 1;

      }
    }

    //جدید
    this.isnew = true;
    this.isinedit = false;
   // this.newGrKala.
    let selector =  old.level  ;
    if (this.selectedrow.level == 1) {
      selector = 0;
    }
    //اگه اگر درخواست از سطح 
    if (this.grmaskOption.levels.length <= selector) {

      this.alertify.alert('سطح درخواستی بیشتر از الگوی کدینگ است');
      return;

    }
      let  lev = this.grmaskOption.levels[selector];
      let codeint = lev.start;
      for (var i = 0; i < this.modlelist.length; i++) {
        if (this.modlelist[i].pTreeId == this.selectedrow.pTreeId) {
          codeint = +this.modlelist[i].code;
        }
      }
      codeint++;
    

        let codemaker = codeint.toString();

      while (codemaker.length < lev.count) {

        codemaker = '0' + codemaker;

      }

       this.codemask = lev.mask;
    
      this.selectedrow.code = codemaker;



    this.selectedrow.codeA = old.codeA + old.code ;
    this.grmaskOption.cntMax;
    this.selectedrow.codeC = '';
    this.selectedrow.name = '';
     //صفر میندازیم ته کد
    for (var i = this.selectedrow.level; i < this.grmaskOption.levels.length;i++) {

      for (var t = 0; t < this.grmaskOption.levels[i].count; t++) {
        this.selectedrow.codeC += '0';
      }
     

    }
    
 

  }
  patvaluchang() {

    console.log(this.grmaskOption.kind);

  //  this.grmaskOption.leg = e.value.replace(/[^a-zA-Z ]/g, "").length;
    if (this.grmaskOption.kind == 1) {
      this.grmaskOption.leg = this.grmaskOption.cntleg;
    }
    if (this.grmaskOption.kind == 2) {
      this.grmaskOption.leg = this.grmaskOption.cntPattern.replace(/[^A-Za-z0-9]/g, "").length;
    }
    if (this.grmaskOption.kind == 3) {
      this.grmaskOption.leg = this.grmaskOption.pattern.replace(/[^A-Za-z0-9]/g, "").length;
    }
  
   // this.pattern = this.pattern.replace('-', "_");
  }
  radiochanged(e) {
    this.cssone = 'mydisble';
    this.csstwo = 'mydisble';
    this.cssthree = 'mydisble';
    this.cssGroup = 'mydisble';

    if (e.value == 1)
      this.cssone = 'myenable';
    else if (e.value == 2)
      this.csstwo = 'myenable';
    else if (e.value == 3) {
      this.cssthree = 'myenable';
      this.cssGroup = 'myenable';
    }
     

    this.patvaluchang();
  }
  addthis(e) {

  

  }

  onFormSubmit(e) {

    console.log(JSON.stringify);
    if (this.selectedrow.id > 0) {

      this.loadServ.showloading(this.loaddata, 7);
      this.GrKala_Service.update(this.selectedrow).subscribe((res) => {
     
        this.loadServ.hideloading(this.loaddata, 7);

        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
           
        }
        else { 

          this.isnew = false;
          this.isinedit = false;
          this.loadcodelist();
          for (var r of this.modlelist) {
            if (r.id == res.data.id) {

              r._sumName = res.data._sumName;
              r.name = res.data.name;
              r.code = res.data.code;
              r.codeA = res.data.codeA;
              r.codeC = res.data.codeC;

            }
          }

        }



      }, error => {
        this.loadServ.hideloading(this.loaddata, 7);
        this.alertify.error(error);
      });

    }
    else {
      console.log(JSON.stringify(this.selectedrow));
      this.loadServ.showloading(this.loaddata, 8);
      this.GrKala_Service.add(this.selectedrow).subscribe((res: any) => {
        this.modlelist.push(res);
        this.alertify.success('رکورد ذخیره شد');
        this.loadServ.hideloading(this.loaddata, 8);
        this.loadcodelist();
        this.isnew = false;
        this.isinedit = false;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.alertify.error(error);
      });
    }




  }
  

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
     
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {



    console.log('delete id: '+this.selectedrow.id);

    this.loadServ.showloading(this.loaddata, 1);
    this.GrKala_Service.delete(this.selectedrow.id).subscribe(res => {
   
     
      this.loadServ.hideloading(this.loaddata, 1);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
        return;

      }
      else {
        this.alertify.success(res.mes);
        this.load();

      }

 

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }

  onSelectionChanged(e) {
    if (e.itemData == null)
      return;


    this.selectedrow = JSON.parse( JSON.stringify( e.itemData));


    if (this.grmaskOption.levelNum >= this.selectedrow.level) {
      this.codemask=  this.grmaskOption.levels[this.selectedrow.level - 1].mask;
    }
     
    this.isnew = false;
    this.isinedit = false;

    
  }





 
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.load();

  }
 

patternsave()
{




 

  this.loadServ.showloading(this.loaddata, 5);
  this.GrKala_Service.updatePattern(this.grmaskOption)
    .subscribe(data => {
      this.alertify.message(data.value);
      //this.pattern = data.data;
      if (data.id == 1) this.load();

      this.loadServ.hideloading(this.loaddata, 5);

    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
    });


}
  validationCallback(e) {


    let v = this.selectedrow.codeA + e.value + this.selectedrow.codeC ;


    let c = this.selectedrow.codeA + this.selectedrow.code + this.selectedrow.codeC;


    if (!this.isnew) {
      for (var val of this.modlelist) {
        if (this.selectedrow.id == val.id) {

          if ((val.codeA + val.code + val.codeC) == v)
          return true;
        }
      }


    }
     

    for (var i of this.codelist) {
      if (v == i) return false;
    }

    return true


  }
  editclick(e) {
    this.isinedit = true;


  }
  cancell() {

    this.isinedit = false;

    this.backcanceled();
  
  }

  backcanceled() {

    for (var r of this.modlelist) {
      if (r.id == this.selectedrow.id) {
        this.selectedrow = JSON.parse(JSON.stringify(r));
        break;
      }

    }

  }

}


