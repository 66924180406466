import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxListComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import { Ghar, GharRow, HItem, HItemG, Oprt, PerM } from '../../../../_models/app99/app99model';
import { Localst } from '../../../../_services/localst.service';
 
  

@Component({
  selector: 'app99-Ghar-edit',
  templateUrl: './Ghar-edit.component.html',
  styleUrls: ['./Ghar-edit.component.css']
})
export class GharEditComponent implements OnInit {

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild("dxliststr", { static: false }) dxliststr: DxListComponent;


  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: Ghar;
  myModel_old: Ghar;
  focusedRowKey: number;
 
   perMlist: any[]=[];
   HItemlist: HItem[]=[];
   HItemGlist: HItemG[]=[];
  visitorMlist: any[]=[];


  oprtList : Oprt[]=[];

  gridsourse: GharRow[];

  selectedrow: GharRow;
 Gharcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  selectedtab: number;

  selectedoprKeys: number[];
 
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
 
    private localst: Localst,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
    this.selectedtab = 0;
    this.focusedRowKey = -1;
    this.myModel = this.app99Service.Ghar_getGharEmpty();
    this.Gharcodelist = [];
    this.gridsourse = [];

    this.selectedrow = this.app99Service.GharRow_getGharRowEmpty();
  }

  ngOnInit() {
 
    this.loadother();
    
    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.Ghar_getallCods().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.Gharcodelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.loadServ.showloading(this.loaddata, 7);
    this.app99Service.HItemG_getall().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.HItemGlist = res.data;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


    this.loadServ.showloading(this.loaddata, 8);
    this.app99Service.Oprt_getall().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.oprtList = res;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });



    this.isnew = true;
 
    if (this.editId == -1) {
 
 
      this.myModel = this.app99Service.Ghar_getGharEmpty();
      try {
        this.myModel.dateA_S = this.localst.dateEmruz.dateString;
        this.myModel.dateB_S = this.localst.dateEmruz.dateString;
        this.myModel.dateC_S = this.localst.dateEmruz.dateString;

      }
      catch {

      }
   
      this.setgridbyKind(0);

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.Ghar_getbyid(this.editId).subscribe(
        (data : Ghar) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
          if (this.myModel.rowsAsli.length>0)
            this.focusedRowKey = this.myModel.rowsAsli[0].id;


       
          this.selectedoprKeys = this.myModel.gharOprts.map(x => x.oprtId);

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
          this.setgridbyKind(0);
          this.calcsum();

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }
  onClickReflesh() {

    this.loadother();


  }

  setgridbyKind(k ) {
    if (k == 0) {
      //دیپ نباشه
      this.gridsourse = this.myModel.rowsAsli;
    }
    //اضافت
    if (k == 1) {
   
      this.gridsourse = this.myModel.rowsAdd;
    }
    //کسورات
    if (k == 2) {

      this.gridsourse = this.myModel.rowsRem;
    }

    if (this.gridsourse == null)
      this.gridsourse = [];

   // this.dataGrid.instance.addRow();
   // let d = this.app99Service.GharRow_getGharRowEmpty();
   // d.id = -1;
   //d.costFi = 0;
   //d.cost = 0;
   //d.costAll = 0;
   //d.costAllFi = 0;
   //d.cnt = 0;
   // this.gridsourse.push(d);
   // this.focusedRowKey = -1;
  }

  addthis = e => {
    
    
  }

  loadother() {

    this.loadServ.showloading(this.loaddata, 4);
    this.app99Service.PerM_getallForList(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.perMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


    this.loadServ.showloading(this.loaddata, 5);
    this.app99Service.HItem_getall().subscribe(
      (data: HItem[]) => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.HItemlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 5);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


    this.loadServ.showloading(this.loaddata, 6);
    this.app99Service.PerM_getallForList(1).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 6);
        this.visitorMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 6);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );

  }

  
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

    let m = [];
    if (this.myModel.rowsAsli.length == 0) {
      m.push('هیچ رکوردی وارد نشده است');
      this.alertify.showListString(m);

      return;

    }
 //ftguhr6765658

    if (!this.firstclreate) {


      var keys = this.dxliststr.selectedItemKeys;


      this.myModel.gharOprts = [];

      for (var r of keys) {
        let l = this.app99Service.Ghar_getEmptyGharOprtEmpty();
        l.oprtId = r.id;
        l.oprtN = r.name;
        this.myModel.gharOprts.push(l);

      }

    }
    else {

     // this.myModel.gharOprts = this.myModel.gharOprts;

    }


   // console.log(JSON.stringify(this.myModel));
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.Ghar_add(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata,4);


        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);

        }

 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
      
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.Ghar_update(this.myModel).subscribe(res => {
        this.loadServ.hideloading(this.loaddata, 5);


        if (res.errors.length > 0) {

          this.alertify.showListString(res.errors);
        }
        else {
          this.alertify.success(res.mes);
          this.OnMySaveEvent.emit(true);

        }
       
     
 



      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);

       });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    //if (this.editId != -1)
    //if (this.myModel_old.cMelli != null)
    //  if (this.myModel_old.cMelli == v)
    //    return true;

    for (var val of this.Gharcodelist) {
      if (v == val) return false;
    }

    return true


  }
 
 

  validateNumber(e) {

    

  }

  onSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }

  hItemchanged(e, cellInfo) {


    var newValue = e.value;

    let s = null;
    for (var r of this.HItemlist) {
      if (r.id == newValue) {
        s = r;
        break;
      }
     
    }
    if (s != null) {
    //  cellInfo.setValue(newValue);
      cellInfo.data.hItemId = newValue;
      cellInfo.data.hItemN = s.name;
      cellInfo.data.cnt = 1;
      cellInfo.data.cost = s.costFi;
      cellInfo.data.costAll = s.costFi;
      cellInfo.data.costFi = s.costFi;
      cellInfo.data.costAllFi = s.costFi;
    }





    this.calcsum();
  }
  cntchanged(e, cellInfo) {

    var indexrow = this.dataGrid.focusedRowIndex;
    var indexcolum = this.dataGrid.focusedColumnIndex;
   // var previousValue = e.previousValue;
    var newValue = e.value;
 
    cellInfo.setValue(newValue);
  
    let fi = this.dataGrid.instance.cellValue(indexrow, 2);
    let fieta = this.dataGrid.instance.cellValue(indexrow, 4);


    this.dataGrid.instance.cellValue(indexrow, 3, fi * newValue);
    this.dataGrid.instance.cellValue(indexrow, 5, fieta * newValue);
    this.calcsum();
  }
  costchanged(e, cellInfo) {

    var indexrow = this.dataGrid.focusedRowIndex;
    var indexcolum = this.dataGrid.focusedColumnIndex;
    var newValue = e.value;

    cellInfo.setValue(newValue);

    if (e.value == 0)
      return;

    cellInfo.data.cost = e.value;
    cellInfo.data.costAll = e.value * cellInfo.data.cnt;

    //this.dataGrid.instance.cellValue(indexrow, 3, cnt * newValue);

    this.calcsum();
  }
  onFocusedRowChanged(e) {
    //console.log('onFocusedRowChanged');
    // return;
    if (e.row == null) {


      return;
    }
    let f = e.row.data;
    for (var _i = 0; _i < this.gridsourse.length; _i++) {


      if (this.gridsourse[_i].id == f.id) {

 
        this.selectedrow = this.gridsourse[_i];
      }



    }




    //console.log('selectedrow : ' + this.selectedrow.id);
    //this.focusedRowKey = this.selectedrow.id;
    //console.log(e.rowIndex);


    //this.pushnewrow(true);

    //console.log('now index' + e.row.rowIndex);
    //console.log('now index hesabid' + e.row.data.hesabId);
    //if (e.row.data.hesabId == null) {
    //  this.dataGrid.instance.editRow(e.row.rowIndex);
 

    //}
    //// else this.dataGrid.instance.cancelEditData();

    //this.tafg_reflesh(this.selectedrow.hesabId);

  }
  ononInitNewRow(e) {



  }
  calcsum( ) {


    this.dataGrid.instance.saveEditData();
    let sum1 = 0;
    let sum2 = 0;



    var rd = this.dataGrid.instance.getKeyByRowIndex(this.dataGrid.focusedRowIndex);


    for (var r of this.myModel.rowsAsli) {

        sum1 += r.costAll;
        sum2 += r.costAllFi;
 
    }
    for (var r of this.myModel.rowsAdd) {
      sum1 += r.costAll;
      sum2 += r.costAllFi;
    }
    for (var r of this.myModel.rowsRem) {
      sum1 -= r.costAll;
      sum2 -= r.costAllFi;
    }

    this.myModel.costAll = sum1;
    this.myModel.costTakh = sum2- sum1;


  }
  addrow() {

    let nid = 0;
    for (var d of this.gridsourse) {
      if (d.id < nid)
        nid = d.id
    }
    nid--;
    let ne = this.app99Service.GharRow_getGharRowEmpty();
    ne.id = nid;
    ne.costFi = 0;
    ne.cost = 0;
    ne.costAll = 0;
    ne.costAllFi = 0;
    ne.cnt = 0;
    this.gridsourse.push(ne);
  }
  removerow() {

    let a = this.dataGrid.focusedRowKey;

    if (this.selectedtab == 0) {
      for (var i = 0; i < this.myModel.rowsAsli.length; i++) {
        if (this.myModel.rowsAsli[i].id == a) {
          this.myModel.rowsAsli.splice(i, 1);
        }
      }
    }
   else if (this.selectedtab == 1) {
      for (var i = 0; i < this.myModel.rowsAsli.length; i++) {
        if (this.myModel.rowsAsli[i].id == a) {
          this.myModel.rowsAsli.splice(i, 1);
        }
      }
    }
   else if (this.selectedtab == 2) {
      for (var i = 0; i < this.myModel.rowsAsli.length; i++) {
        if (this.myModel.rowsAsli[i].id == a) {
          this.myModel.rowsAsli.splice(i, 1);
        }
      }
    }

    this.calcsum();
  }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
   
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: this.addrow.bind(this)
        }
      }
  
    );
  //    , {
  //location: 'before',
  //  widget: 'dxButton',
  //    options: {
  //  icon: 'remove',
  //    onClick: this.removerow.bind(this)
  //}

  }
  selectedtabchang(e) {
    this.selectedtab = +e;


    this.setgridbyKind(this.selectedtab);

   // console.log(e);
  }
  ttttt() {

    this.calcsum();

  }

  ononRowRemoved() {
    this.calcsum();
  }

  firstload: boolean =false;
  HItemGlistchanged(e , data) {

    if (e.value == null)
      return;



    if (this.firstload == false) {


       

      this.firstload = true;

      if (this.editId > 0)
        return;

    }


  

    this.loadServ.showloading(this.loaddata, 7);
    this.app99Service.GharRow_getByHItemGId(e.value).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 7);

      this.myModel.rowsAsli = [];
      this.myModel.rowsAsli = res.data;
 
      this.setgridbyKind(this.selectedtab);

      this.calcsum();

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


  }
  firstclreate = true;
  dropopened(e) {
    //ryt345y5y45y45u
    e.component._popup.option('width', 500);
    if (this.firstclreate) {

     // this.dxliststr.selectedItemKeys = this.selectedoprKeys;

      for (var i = 0; i < this.oprtList.length ; i++) {

        for (var g of this.myModel.gharOprts) {
          if (this.oprtList[i].id == g.oprtId) {
            this.dxliststr.instance.selectItem(i);

          }
         
        }
      


      }


   

   
    //  this.dxliststr.instance.selectAll();
      this.firstclreate = false;

    }
  }
}
