import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 

import { LoadingService } from '../../../../_services/loading.service';
import { PdHes } from '../../../../_models/app4/paye/PdHes';
import { PdHesService } from '../../../../_services/app4/paye/PdHes.service';
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
 
@Component({
  selector: 'app4-PdHes-edit',
  templateUrl: './PdHes-edit.component.html',
  styleUrls: ['./PdHes-edit.component.css']
})
export class PdHesEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PdHes;
  myModel_old: PdHes;
 
  pdMoveKindList: any[]=[];
 
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
  n: number;

  hesablist: Hesab[] = [];

  tafsiliForGroupRead: TafsiliForGroupRead;

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private hesabService: HesabService,
    private PdHesService: PdHesService,
    private tafsiliService: TafsiliService,
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
    this.validationCallback = this.validationCallback.bind(this);
 
    
    this.myModel = this.PdHesService.getPdHesEmpty();
    this.tafsiliForGroupRead = this.tafsiliService.getTafsiliGroupEmpty();
 
  }

  ngOnInit() {
    this.isnew = true;
    this.refreshbottum();
 
    this.myinit();
   




  
  }

  pdMoveKindListloaded: boolean = false;
  refreshbottum() {

    //this.subscribe_table = this.com_table.Receive().subscribe(async res => {
    //  await this.SaveTableAsync(res.data);
    //  this.ReadTableAsync();
    //});

    this.loadServ.showloading(this.loaddata, 5);
    this.PdHesService.getkindMovePatternList().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 5);

      this.pdMoveKindList = res.data;
      this.pdMoveKindListloaded = true;
    }, error => {
      this.loadServ.hideloading(this.loaddata, 5);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


    this.loadServ.showloading(this.loaddata, 4);
    this.hesabService.getHesabs_treenum(3).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 4);

      this.hesablist = res;

    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




  }

  myinit() {

    if (this.editId == -1) {
      this.myModel = this.PdHesService.getPdHesEmpty();
      this.isnew = true;
    }
    else {


      this.isnew = false;

      this.loadServ.showloading(this.loaddata, 3);
      this.PdHesService.getbyid(this.editId).subscribe(
        (res) => {

          this.loadServ.hideloading(this.loaddata, 3);
          this.myModel = res.data;
          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));
        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata, 3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }



  }

  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

 
 

   
 
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.PdHesService.add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.PdHesService.update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

  
    return true


  }
 
 

  validateNumber(e) {

    

  }

  gridBox_displayExpr(item) {
    return item && item.codeSum + '-' + item.name;
  }
  gridBox_displayExprTaf(item) {
    return item && item.code + '-' + item.name;
  }
  adjustWidth(e, w) {

    e.component._popup.option('width', w);
    //if (this.firsttimeopen) {


    //  this.firsttimeopen = false;

    //}
  }
 
patternrows: any[] = [];
patternchanged(e) {

  this.patternrows = [];
    if (e.value == null)
      return;
    
    var h = e.component.option('selectedItem');

    this.patternrows = h.rows;


  }

 


  mochangedA(e) {

    if (e.value == null)
      return;
    //hgfgfh545555
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

    //this.myModel.hesabAC = h.codeSum;
    //this.myModel.hesabAN = h.name;

    ////gfu68ui76i67
    //if (e.previousValue != null) {
    //  this.myModel.taf4Id = null;
    //  this.myModel.taf4C = null;
    //  this.myModel.taf4N = null;
   
    //}


    this.tafsiliService.getTafsilisGroup(h.id).subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 6);

      this.tafsiliForGroupRead = res;
      this.tafsiliForGroupRead.taf4List

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


 
  }
  t4changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    if (h == null)
      return;

    //this.myModel.taf4C = h.code;
    //this.myModel.taf4N = h.name;
 
  }




  mochangedB(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;

    if (h == null)
      return;

    //this.myModel.hesabBC = h.codeSum;
    //this.myModel.hesabBN = h.name;


  }



  mochangedC(e) {

    if (e.value == null)
      return;

    var h = e.component.option('selectedItem') as Hesab;
 
    if (h == null)
      return;

    //this.myModel.hesabCC = h.codeSum;
    //this.myModel.hesabCN = h.name;

 

  }
}
