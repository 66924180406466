import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { environment } from '../../../../environments/environment';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnEnd } from '../../../_models/app2/end/AnEnd';
 
 
let EmptyAnEnd: AnEnd = {
  id: -1,
  code: 0,
  dateA: 0,
  dateA_S: null,
  anbarId: null,
  anbarN: null,
  dis: null,
  rows: [],
  prc: 0,
  years: null,
  uSa: null,
  uSaF: null,
  uSaE: null,
  uSaEF: null,
  dateELog: null ,
  dateLog: null,
  ctn: 0

};
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnEndService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(years: number): Observable<AnEnd[]> {
    let apiadress = '/api/App2/End/AnEnd/getall?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<AnEnd[]>(this.origin + apiadress, { 'headers': headers });
  }

 
 

 
  getbyid(id): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnEnd/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
    }
 
  add(item: AnEnd) : Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnEnd/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getAnEndEmpty(): AnEnd {

 
    return JSON.parse(JSON.stringify(EmptyAnEnd));

  }
 
 

  delete(AnEndId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnEnd/delete?id=' + AnEndId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

  createEftetah(years:number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnEnd/createEftetah?years=' + years.toString();
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
}
