import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { Morur, MorurOption } from '../../../_models/app1/rep/Morur';
import { Tadilmahilat, TadilmahilatOption } from '../../../_models/app1/rep/Tadilmahilat';
import { Localst } from '../../localst.service';
 
 
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};



 
 
@Injectable({
  providedIn: 'root'
})
export class TadilmahilatService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  EmptyTadilmahiat: Tadilmahilat = {
     
    id: -1,
    hesabId: null,
    groupN:null,
    kolC:null,
    kolN:null,
    hesabCode: null,
    hesabN: null,
    l4Id: null,
    l4Code: null,
    l5Id: null,
    l5Code: null,
    l6Id: null,
    l6Code: null,
    l4N:null,
    l5N:null,
    l6N:null,
    mandehBed: null,
    mandehBes: null,
    mahiatId: null,
    mahiatN: null,
    tadilId: null,
    tadilC: null,
    tadilN: null,
    legpx: null,
    correct : true
  };
  EmptyTadilmahiatOption: TadilmahilatOption = {

    id: -1,
    date: null,
    noeHesab: null,
    mahiat: null,
    year: null,

    rows: [],
    rowsErrors: []


  };
  constructor(private http: HttpClient, private localstService: Localst) { }



 
  getTadilmahilat(option: TadilmahilatOption): Observable<TadilmahilatOption> {
    let apiadress = '/api/App1/Opr/Sabt/report_Tadilmahilat';
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<TadilmahilatOption>(this.origin + apiadress, option, { 'headers': headers } );

  }

  AutoSanadTadilmahilat(data: TadilmahilatOption): Observable<TadilmahilatOption> {
    let apiadress = '/api/App1/Opr/Sanad/AutoSanadTadilmahilat';
    console.log('AutoSanadTadilmahilat');
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.post<TadilmahilatOption>(this.origin + apiadress, data, { 'headers': headers });

  }
  getemptyTadilmahilat(): Tadilmahilat {

    return JSON.parse(JSON.stringify(this.EmptyTadilmahiat));
  }
  getemptyTadilmahilatOption(): TadilmahilatOption {

    return JSON.parse(JSON.stringify(this.EmptyTadilmahiatOption));
  }
  getprint(moror: Morur[]): string {

    let row =
      `
    <td>Jill</td>
    <td>Smith</td>
    <td>50</td>
`
      
    if (moror != null) {


      for (var _i = 0; _i < moror.length; _i++) {

        row = row + '<tr><td>' + moror[_i].id + '</td>' +
          '<td>' + moror[_i].name + '</td>' +
          '<td>' + moror[_i].code + '</td></tr>';

   
    
      }

    }
  

    return `

<style>

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 20mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 1cm;
        border: 5px red solid;
        height: 257mm;
        outline: 2cm #FFEAEA solid;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }




</style>



  <div> 
<div class="book">
  <div class="page">
    <div class="subpage"><table style="width:100%">
  <tr>
    <th>لببلا</th>
    <th>Lastname</th>
    <th>Age</th>
  </tr>
  `+

      row + row + row + row


      +`
  <tr>
    <td>Eve</td>
    <td>Jackson</td>
    <td>94</td>
  </tr>
</table></div>
  </div>
  <div class="page">
    <div class="subpage">Page 2/2</div>
  </div>
</div>



</div>




`;






  }






}
