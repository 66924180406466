import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
 
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { AlertifyService } from '../../_services/alertify.service';

import { ArchService } from '../../_services/arch.service';
import { PhotoArch } from '../../_models/photoArch';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { SafeUrl } from '@angular/platform-browser';
import { WindowRefService } from '../../_services/window.service';
import { Arch } from '../../_models/arch';

@Component({
  selector: 'app-archphoto-editor',
  templateUrl: './archphoto-editor.component.html',
  styleUrls: ['./archphoto-editor.component.css']
})
export class ArchPhotoEditorComponent implements OnInit {
  @Input() arch: Arch;
  photos: PhotoArch[];
  imageData: any;
  image: any;
  @Output() getMemberPhotoChange = new EventEmitter<string>();
  uploader: FileUploader;
  hasBaseDropZoneOver = false;

  private nativeWindow: Window;
  imageBlobUrl: string;
  uploaapi: string;
  sanitizedImageBlobUrl: SafeUrl;

  baseUrl = environment.apiUrl;
  currentMain: PhotoArch;
  // constructor() {}
  constructor(private authService: AuthService, private userService: UserService,
    private windowRefService: WindowRefService,private archService: ArchService,  private alertify: AlertifyService) { }

  ngOnInit() {
    
  //  this.archService.GetArchPhotosStream().subscribe(c => this.imageData = c);

  //  const reader = new FileReader();
  //  reader.onload = (e) => this.image = e.target.result;
   // reader.readAsDataURL(new Blob([this.imageData]));


    this.archService.getArchPhotos().subscribe(c => this.photos = c);
   // console.log(this.photos.length);
    this.initializeUploader();


  }
  imageToShow: any;
   
 





  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    this.uploaapi= this.baseUrl + 'ArchPhoto/AddPhotoForArch?arch=' + '12' + '&uname=ali' 
    //this.uploaapi = this.baseUrl + 'archph/' + this.authService.decodedToken.nameid + '/photos',
    console.log(this.uploaapi)


    this.uploader = new FileUploader({
      
      url: this.uploaapi,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });
  

    this.uploader.onAfterAddingFile = (File) => { File.withCredentials = false }


    this.uploader.onSuccessItem = (item, response, status, headers) => {
      console.log('onsucces!');
    
        //if (response) {
        //  const res: PhotoArch = JSON.parse(response);
        //  const photo = {
        //    id: res.id,
        //    url: res.url,
        //    dateAdded: res.dateAdded,
        //    description: res.description,
        //    isMain: res.isMain
        //  };
        //  this.photos.push(photo);
        //  if (photo.isMain) {

        //    this.authService.changeMemberPhoto(photo.url);
        //    this.authService.currentUser.photoUrl = photo.url;
        //    localStorage.setItem('user', JSON.stringify(this.authService.currentUser));



        //  }
        //}
     
      };
  

   

    //deletePhoto(id: number) {
    //  this.alertify.confirm('Are you sure you want to delete this photo?', () => {
    //    this.userService.deletePhoto(this.authService.decodedToken.nameid, id).subscribe(() => {
    //      this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
    //      this.alertify.success('Photo has been deleted');
    //    }, error => {
    //      this.alertify.error('Failed to delete the photo');
    //    });
    //  });
    //}
   
  }
  

  deletePhoto(id: number) {
    this.alertify.confirm('Are you sure you want to delete this photo?', () => {
      this.userService.deletePhoto(this.authService.decodedToken.nameid, id).subscribe(() => {
        this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
        this.alertify.success('Photo has been deleted');
      }, error => {
        this.alertify.error('Failed to delete the photo');
      });
    });
  }
}
