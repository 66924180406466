import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Hesab } from '../../../../_models/app1/paye/Hesab';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName, mytask } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxListComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxComponent,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'; 
import { FocusOrigin } from '@angular/cdk/a11y';
import { TafDasteService } from '../../../../_services/app1/paye/TafDaste.service';
import { TafDaste } from '../../../../_models/app1/paye/TafDaste';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { mymodule } from '../../../../../../my_modules/mymodule';
import { Console } from 'console';
import { element } from 'protractor';
import { LoadingService } from '../../../../_services/loading.service';
import { parse } from 'url';




@Component({
  selector: 'app-Hesab-edit',
  templateUrl: './Hesab-edit.component.html',
  styleUrls: ['./Hesab-edit.component.css']
})

export class HesabEditComponent implements OnInit {

  loadbar: mymodule.myloadprogses;
 
  
  @ViewChild("childcodeinput", { static: false }) childcodeinput: DxTextBoxComponent;
  @ViewChild("childnameinput", { static: false }) childnameinput: DxTextBoxComponent;
  @ViewChild("childnameinput", { static: false }) mahiyat: DxSelectBoxComponent;

  elementOrigin = this.formatOrigin(null);
  subtreeOrigin = this.formatOrigin(null);

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Output() evHesabChanged = new EventEmitter();
 
  @Input() myHesab: Hesab;
  @Input() listcodsum: string[];

  validcodmessage: string;
  myHesab_old: Hesab;

  mTitle; string;

  enc: string;
  sho: false;

  frezzfulledit: boolean;


  somest: string;
  someint: number;

  kindhesab: IdName[] = [
    { id: 1, name: "دائمی" },
    { id: 2, name: "موقت" },
    { id: 3, name: "انتظامی" },
  ];
  mahiyatdb: IdName[] = [

    { id: 1, name: "بدهکار" },
    { id: 2, name: "بستانکار" },
    { id: 3, name: "بدهکار بستانکار" },
  ];
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  sesathe: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
 
  tasks: mytask[] = [
  { id: 1, text: "Prepare 2016 Financial" },
    { id: 2, text: "Prepare 2016 Marketing Plan" },

    { id: 3, text: "بیایباn" },
];

  vvv: any;
  listDaste4: TafDaste[];
  listDaste5: TafDaste[];
  listDaste6: TafDaste[];
  listDaste4selected: number[];
  listDaste5selected: number[];
  listDaste6selected: number[];

  listDaste4changed: any[];
  listDaste5changed: any[];
  listDaste6changed: any[];
 

 


  checked4: boolean;
  checked5: boolean;
  checked6: boolean;

  //برای لغو کد تکراری
  superedit: boolean;


  @Input() isenable: boolean;
  maindivv: any;
  isshowfooter: boolean;


  listmoteghbal: Hesab[] = [];
  viewinited: boolean;
  loaddata: loadingCl;



  constructor(
    private userService: UserService,
    private alertify: AlertifyService,
    private  tafdasteService: TafDasteService,
    private tafsiliService: TafsiliService,
    private router: Router,
    private HesabService: HesabService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private loadServ: LoadingService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata,15);
    this.loadbar = new mymodule.myloadprogses(1);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);  
    this.validationCallback = this.validationCallback.bind(this);  
   // this.myHesab = this.HesabService.getHesabEmpty();
    this.listDaste4selected = [];
    this.listDaste5selected = [];
    this.listDaste6selected = [];
    this.isenable = false;
    this.listmoteghbal = [];

    this.viewinited = false;
    this.enc = 'mydisable';
    this.frezzfulledit = true;
    this.superedit = false;
  }
 

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    //console.log(event.ctrlKey);
    //console.log(event.altKey);
    //console.log(event.key);
    if ( event.altKey &&  event.keyCode == 76 ) {

      this.frezzfulledit = !this.frezzfulledit;

      if (!this.frezzfulledit) {
        this.superedit = true;
      }
      else
        this.superedit = false;


      console.log(this.frezzfulledit);

    }
  }

  ngOnInit() {

    this.validcodmessage = 'کد تکراری است';
   

 

    let md = document.getElementsByClassName('maindiv') as HTMLCollectionOf<HTMLElement>;
    if (md.length != 0) {
      //  shand[0].style.transform = "translate(-50%, -100%) rotate(" + 3 * 6 + "deg)";
     // this.maindivv[0].setAttribute("style", " opacity: 0.4;   pointer - events: none;");
      this.maindivv = md[0];
    }



    this.enc = 'mydisable';
    //??????????
   // this.maindivv.setAttribute("style", " opacity: 0.4;   pointer-events: none;");


    this.loadServ.showloading(this.loaddata,0);

    this.isshowfooter = false;
    this.tafdasteService.getTafDastes()
      .subscribe((res: TafDaste[]) => {

        this.loadServ.hideloading(this.loaddata,0);
        this.listDaste4 = res;
        this.listDaste5 = JSON.parse(JSON.stringify(this.listDaste4));
        this.listDaste6 = JSON.parse(JSON.stringify(this.listDaste4));

     

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
          console.log('error2: ' + error);
        this.alertify.error(error);
      }).add(() => {

      });


  }
  doSomething() {
    console.log('  doSomething');

  }
  ngOnChanges(c) {

    if (!this.viewinited) {

      return;
    }

    console.log('chang');
    this.ngOnChangesMy();
    if (this.isenable) {

      this.enc = 'myenable';
      //  this.maindivv.setAttribute("style", " opacity: 1;   pointer-events: auto;");
      this.childcodeinput.instance.focus();
    }

    else {
      this.enc = 'mydisable';
    //  this.maindivv.setAttribute("style", " opacity: 0.52;   pointer-events: none;");

    }
     

    
 
  }

  ngAfterViewInit() {
    this.viewinited = true;
    console.log('AfterViewInit');
  
}
  ddd() {

    console.log('fffffffddddd');


  }
  ngOnChangesMy() {

    this.frezzfulledit = true;

    this.reloadlistmoteghbal();

    console.log('input modle changed to id hesab :' + this.myHesab.id);
    //this.myHesab.level4 = true;
    //this.checked4 = true;
    console.log(this.myHesab.code);

    if (this.myHesab.treeNum == 3) {
      this.isshowfooter = true;

    }
    else this.isshowfooter = false;


    if (this.myHesab.id == -2) {
      this.mTitle = '---'
      this.frezzfulledit = false;
    
    }
    if (this.myHesab.id > -1) {
      this.mTitle = 'ویرایش';
      this.isnew = false;
      //ببرسی امکان ویرایش کامل حساب
      this.loadServ.showloading(this.loaddata,1);
        this.HesabService.allowdelete(this.myHesab.id)
          .subscribe((res:  boolean) => {

            console.log(res);
            this.loadServ.hideloading(this.loaddata,1);

            if (res == true)
              this.frezzfulledit = false;
            else
              this.frezzfulledit = true;


          }, error => {
              this.loadServ.hideloading(this.loaddata,1);
            console.log('error: ' + error);
           // this.alertify.error(error);
          }).add(() => {

          });



    }
    if (this.myHesab.id == -1) {
      console.log('in new mode');
      this.mTitle = 'جدید';
      this.frezzfulledit = false;
      this.isnew = true;
   

     // this.isenable = true;
    }
    //  console.log('treeNum '+this.myHesab.treeNum);
    if (this.myHesab.treeNum == 0) {
      this.mTitle += ' حسابها ';
    }
    else if (this.myHesab.treeNum == 1) {
      this.mTitle += ' گروه ';
    }
    else if (this.myHesab.treeNum == 2) {
      this.mTitle += ' کل ';
    }
    else if (this.myHesab.treeNum == 4) {
      this.mTitle += ' معین ';
    }

    this.listDaste4selected = this.listDaste4selected.filter(obj => obj === -1);
    this.listDaste5selected = this.listDaste5selected.filter(obj => obj === -1);
    this.listDaste6selected = this.listDaste6selected.filter(obj => obj === -1);

   
    console.log('myHesab.id : ' + this.myHesab.id);
    if (this.myHesab.id > -1) {
      this.loadServ.showloading(this.loaddata,2);
      this.HesabService.getHesab(this.myHesab.id)
        .subscribe((res: Hesab) => {

          this.loadServ.hideloading(this.loaddata,2);

          console.log('reload model by id:' + this.myHesab.id);
          this.myHesab = JSON.parse(JSON.stringify(res)); 
          this.myHesab_old = JSON.parse(JSON.stringify(res));
          //this.listDaste4selected.push(6);
          if (this.myHesab.taf4Daste_IdName != null) {

            for (let num of this.myHesab.taf4Daste_IdName) {
              this.listDaste4selected.push(num.id);
              // console.log(num.id);
            }

          }
          if (this.myHesab.taf5Daste_IdName != null) {

            for (let num of this.myHesab.taf5Daste_IdName) {
              this.listDaste5selected.push(num.id);
              // console.log(num.id);
            }
            //  console.log("ppp6 " + JSON.stringify(this.listDaste5selected));
          }
          if (this.myHesab.taf6Daste_IdName != null) {

            for (let num of this.myHesab.taf6Daste_IdName) {
              this.listDaste6selected.push(num.id);
              // console.log(num.id);
            }


          }
          //  console.log(this.loadbar.alloaded);
          this.loadbar.set_Loaded(0);
          this.loadbar.readprogress();
          //  console.log(this.loadbar.alloaded);

          // this.ProgressValue = this.loadbar.readprogress();


        }, error => {
            this.loadServ.hideloading(this.loaddata,2);
            console.log('error: ' + error);
          this.alertify.error(error);
        }).add(() => {
     
        });

    }

    


  }
  test(e) {

    this.childcodeinput.instance.focus();

  }
  addthis () {

    if (this.loaddata.showed)
      return;


    this.myHesab.codeSum = this.myHesab.codeP + this.myHesab.code;
   // console.log("The button save was clicked");






  }
  cancelthis (){
 
   // return;
    this.evHesabChanged.emit();
    let v = this.HesabService.getHesabEmpty();
    v.id = -2;
    this.myHesab = v;
    this.isenable = false;
    if (this.maindivv != null) {
      this.enc = 'mydisable';
     // this.maindivv.setAttribute("style", " opacity: 0.4;   pointer-events: none;");

    }
    

  }
  dooo = e => {

    console.log("dooo clicked");
 
   
  }
  IdNameTemp: IdName = { id: -1, name: '' };


  onFormSubmit = function (e) {

    console.log("m:submitform" + this.IdNameTemp.id);

 
    

   
    console.log("mm:" + JSON.stringify(this.myHesab));
 

    if (this.myHesab.id == -1 && this.isnew) {
      this.loadServ.showloading(this.loaddata,3);
      this.HesabService.addHesab(this.myHesab).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,3);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        //this.router.navigate(['/app1/paye/Hesab']);
        var tempempty = this.HesabService.getHesabEmpty();
        tempempty.id = -2;
        this.myHesab = tempempty;
        this.ngOnChangesMy();
        this.evHesabChanged.emit();
      }, error => {
          this.loadServ.hideloading(this.loaddata,3);
        this.alertify.error(error);
        console.log("myerror:" + error)
      }).add(() => {
        //Called when operation is complete (both success and error)
       
        console.log('complete emit newHeasab_madule');
      });

    }
    else if (this.myHesab.id > -1 && this.isnew == false) {
     // console.log(JSON.stringify(this.myHesab));
      this.loadServ.showloading(this.loaddata, 4);

  

      let obforupdate = JSON.parse(JSON.stringify(this.myHesab));
      obforupdate.taf4replace = -1;
      obforupdate.taf5replace = -1;
      obforupdate.taf6replace = -1;


      this.HesabService.updateHesab(obforupdate).subscribe(next => {
        this.alertify.success('مشخصات با موفقیت بروز رسانی شد');
        console.log("m:saved");
        this.loadServ.hideloading(this.loaddata,4);
        // this.router.navigate(['/app1/paye/Hesab']);
        this.evHesabChanged.emit(); 
      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      }).add(() => {
        //Called when operation is complete (both success and error)

        console.log('complete emit newHeasab_madule');
      });

    }
    else if (this.myHesab.id == -2) {


      //donothing

    }

 

   // this.evHesabChanged.emit();


   // e.preventDefault();
  }

   
  values = '';

  
  onValueChanged(e) {
    //const previousValue = e.previousValue;
    //const newValue = e.value;

    //this.values = newValue;
            
    //this.listDaste4selected

  }

  additem: any;
  removeitem: any;

  idnametemp: IdName = { id:-1 , name:''};

  onSelectionChanged(e) {

   
    if (this.loadbar.alloaded == false) return;

    let adc = -1;
    let rec = -1;
    this.additem =  e.addedItems;
    this.removeitem = e.removedItems;
    if (this.additem[0]) {

      adc = this.additem[0].id;
      console.log('add id:  ' + adc);

    }
     
    if (this.removeitem[0]) {

      rec = this.removeitem[0].id
      console.log('remopve:' + rec);
    }
  

    if (adc != -1) {
      if (this.listDaste4selected.indexOf(adc) < 0)
      this.listDaste4selected.push(adc);
     
    }
 

    if (rec != -1)
      this.listDaste4selected = this.listDaste4selected.filter(obj => obj !== rec)

    this.idnametemp.id = 1;
    this.idnametemp.name = 'test';

  //  console.log("midname:" + JSON.stringify(this.idnametemp));
    let c = 0;
    this.myHesab.taf4Daste_IdName = [];
    for (let entry of this.listDaste4selected) {
      c++;
      this.idnametemp.id = entry;
      this.idnametemp.name = 'daste';

     // console.log('ooo:' + JSON.stringify(this.idnametemp));

      this.myHesab.taf4Daste_IdName.push(JSON.parse(JSON.stringify(this.idnametemp)));
    }
  
    if (c > 0) {
      this.myHesab.level4 = true;
    }
    else  {
      this.myHesab.level4 = false;
    }

    //console.log("m:" + JSON.stringify(this.myHesab));

  }


  additem5: any;
  removeitem5: any;

  idnametemp5: IdName = { id: -1, name: '' };

  onSelectionChanged5(e) {


    if (this.loadbar.alloaded == false) return;

    let adc = -1;
    let rec = -1;
    this.additem5 = e.addedItems;
    this.removeitem5 = e.removedItems;
    if (this.additem5[0]) {

      adc = this.additem5[0].id;
      console.log('add id:  ' + adc);

    }

    if (this.removeitem5[0]) {

      rec = this.removeitem5[0].id
      console.log('remopve:' + rec);
    }


    if (adc != -1) {
      if (this.listDaste5selected.indexOf(adc) < 0)
        this.listDaste5selected.push(adc);

    }


    if (rec != -1)
      this.listDaste5selected = this.listDaste5selected.filter(obj => obj !== rec)

    this.idnametemp5.id = 1;
    this.idnametemp5.name = 'test';

    //  console.log("midname:" + JSON.stringify(this.idnametemp));
    this.myHesab.taf5Daste_IdName = [];
    let c = 0;
    for (let entry of this.listDaste5selected) {
      c++;
      this.idnametemp5.id = entry;
      this.idnametemp5.name = 'daste';

    //  console.log('ooo:' + JSON.stringify(this.idnametemp5));

      this.myHesab.taf5Daste_IdName.push(JSON.parse(JSON.stringify(this.idnametemp5)));
    }


    if (c > 0) {
      this.myHesab.level5 = true;
    }
    else {
      this.myHesab.level5 = false;
    }
  //  console.log("m:" + JSON.stringify(this.myHesab));

  }



  additem6: any;
  removeitem6: any;

  idnametemp6: IdName = { id: -1, name: '' };

  onSelectionChanged6(e) {


    if (this.loadbar.alloaded == false) return;

    let adc = -1;
    let rec = -1;
    this.additem6 = e.addedItems;
    this.removeitem6 = e.removedItems;
    if (this.additem6[0]) {

      adc = this.additem6[0].id;
      console.log('add id:  ' + adc);

    }

    if (this.removeitem6[0]) {

      rec = this.removeitem6[0].id
      console.log('remopve:' + rec);
    }


    if (adc != -1) {
      if (this.listDaste6selected.indexOf(adc) < 0)
        this.listDaste6selected.push(adc);

    }


    if (rec != -1)
      this.listDaste6selected = this.listDaste6selected.filter(obj => obj !== rec)

    this.idnametemp6.id = 1;
    this.idnametemp6.name = 'test';

    //  console.log("midname:" + JSON.stringify(this.idnametemp));
    this.myHesab.taf6Daste_IdName = [];
    let c = 0;
    for (let entry of this.listDaste6selected) {
      c++;
      this.idnametemp6.id = entry;
      this.idnametemp6.name = 'daste';

    //  console.log('ooo:' + JSON.stringify(this.idnametemp6));

      this.myHesab.taf6Daste_IdName.push(JSON.parse(JSON.stringify(this.idnametemp6)));
    }
    if (c > 0) {
      this.myHesab.level6 = true;
    }
    else {
      this.myHesab.level6 = false;
    }
   // console.log("m6 :" + JSON.stringify(this.myHesab));

  }


   

  reloadlistmoteghbal() {

    this.loadServ.showloading(this.loaddata,5);
    this.HesabService.getHesabs_treenum(3)
      .subscribe((res: Hesab[]) => {
        this.listmoteghbal = res;

        for (var r = 0; r < this.listmoteghbal.length; r++) {

          this.listmoteghbal[r].name = this.listmoteghbal[r].codeSum + '  -  ' + this.listmoteghbal[r].name   ;
        }

        this.loadServ.hideloading(this.loaddata,5);
      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
          console.log('error3: ' + error);
      //  this.alertify.error(error);
      }).add(() => {

      });

  }



   RemoveElementFromArray(element: any) {
     this.listDaste4changed.forEach((value, index) => {
       if (value == element) this.listDaste4changed.splice(index, 1);
     });



   }

  



  isvalisss: boolean;
  dffd: any;
  onKey(e) {
   
   

  }

  fdgdf: any;
  validationCallback(e) {


    //console.log(JSON.stringify(e));
    if (! /^[Z0-9]+$/.test(e.value)) {
      this.validcodmessage = 'تنها مقدار عددی قابل قبول است';
      // e.message = 'jjj';
      return false;
    }

    //اگر خواستیم فیلتر را نادیده بگیریم
    if (this.superedit) {

      return true;
    }
 


    if (!this.isenable) return true;
    var val = e.value;
      var v  =this.myHesab.codeP + val

    if (this.myHesab_old != null)
    if (this.isnew == false && v == this.myHesab_old.codeSum) {
      return true;
    }

   // console.log('jcode :  ' +v);

    for (let entry of this.listcodsum) {

   //   console.log('jcode :  ' + v + '  k  ' + entry);
      if (v == entry) {
        this.validcodmessage = 'کد حساب تکراری است';
         return false
      }
     
    }


    return true;

}
 
  onfocusout() {


    this.childnameinput.instance.focus();


  }
  checkComparison() {
    return true;
  }
  validateNumber(e) {

    

  }


  formatOrigin(origin: FocusOrigin): string {
    return origin ? origin + ' focused' : 'blurred';
  }


  fo_namehesab() {




  }

 

}
export interface Point {

}
