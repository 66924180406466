import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
 
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
 
import { exportDataGrid } from 'devextreme/excel_exporter';

import { LoadingService } from '../../../_services/loading.service';
import { AlertifyService } from '../../../_services/alertify.service';

import { Localst } from '../../../_services/localst.service';

import { ApiRet } from '../../../_models/General/ApiRet';

import { AnBastan } from '../../../_models/app2/glob/AnBastan';
import { AnBastanService } from '../../../_services/app2/glob/AnBastan.service';
import { AnDeedService } from '../../../_services/app2/opr/AnDeed.service';
 
 

@Component({
  selector: 'app-AnBastan-list',
  templateUrl: './AnBastan-list.component.html',
  styleUrls: ['./AnBastan-list.component.css']
})
 

export class AnBastanlistComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;
 
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  @Output() onOpenSanadList: EventEmitter<any> = new EventEmitter<any>();

  selectedrow: any;
  model: AnBastan[];
  mastext: '-';
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
  anbardateslast: any[] = [];
  loaddata: loadingCl;

  constructor(
  
    private loadServ: LoadingService,
    private alertify: AlertifyService,

    private anBastanService: AnBastanService,
    private anDeedService: AnDeedService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata,8);
    this.model = [];
  }

  ngOnInit() {


    this.loadgrid();


  }
  ngAfterViewInit() {

   
  }


  loadgrid() {

    this.loadServ.showloading(this.loaddata, 2);
    this.anBastanService.getallByKind(300, this.localst.yearnow_get())
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 2);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }

        this.model = res.data;
        this.loadalldate();
        //  this.model[0]._butVaz = -1;

      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


  }
 


  loadalldate() {
    this.loadServ.showloading(this.loaddata, 0);
    this.anDeedService.getMaxDateOfDeeds(this.localst.yearnow_get()).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.anbardateslast = res.data;

      for (var t of this.model) {

        for (var uuu of this.anbardateslast) {
          //     public int anbarid { get; set; }
          //public int Year { get; set; }
          //public int Date { get; set; }
          //public string Date_S { get; set; }
          if (uuu.anbarid == t.anbarId) {

            t.datelast = uuu.date_S;
          }


        }



      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });
  }


  refreshbottum() {

    this.loadgrid();

  }

   
  onCellPrepared(e) {

    if (e.data == undefined)
      return;


   



  }
  temp_dateAchanged(e, cellInfo) {

    if (e.value == undefined || e.value == null || e.value == '') {

      //فقط اگر در دیتا بیس ذخیره بود دکمه نمایش داده شود
      if (cellInfo.data.id>0)
      cellInfo.data._butVaz = -1;
 
      return;
    }
    

 ///tgyutyu444

    let sd = e.value;
    sd = sd.replace('/', '').replace('/', '');

    if (sd.length < 8)
      return;

 
    cellInfo.data.dateA_S = sd.substring(0, 4) + '/' + sd.substring(4, 6) + '/' + sd.substring(6, 8);
    cellInfo.data.dateA = +sd;

    if (cellInfo.data.dateA > cellInfo.data._dbDate)
      cellInfo.data._butVaz = 1
    else if (cellInfo.data.dateA < cellInfo.data._dbDate)
      cellInfo.data._butVaz = -1
    else
      cellInfo.data._butVaz = 0;
    



  }
  onSaverow(data) {
    if (data == null)
      return;

    this.loadServ.showloading(this.loaddata, 1);
    this.anBastanService.update(data.data as AnBastan)
      .subscribe((res: ApiRet) => {
        this.loadServ.hideloading(this.loaddata, 1);

        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        else {
          this.alertify.success(res.mes);
          this.loadgrid();
        }
          


      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });


  }

}


