import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { environment } from '../../../../environments/environment';
 
import { Localst } from '../../localst.service';
 
import { ApiRet } from '../../../_models/General/ApiRet';
import { AnCount } from '../../../_models/app2/end/AnCount';
 
 
let EmptyAnCount: AnCount = {

  id: -1,
  anGarId: null,
  anGar: null,
  vaz: 0,
  vazN: 'ثبت نشده',
  anDeedInId :null,
  anDeedInVaz: 'صادر نشده',
  anDeedIn: null,
    anDeedOutId :null,
  anDeedOutVaz: 'صادر نشده',
  anDeedOut : null,
  anCountRows:[],
  numDone: 0,
  uSa: null, 
  uSaF: null, 
  dateLog: null

};
 

const origin = location.origin ;
@Injectable({
  providedIn: 'root'
})
export class AnCountService {
  baseUrl = environment.apiUrl;
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<AnCount[]> {
    let apiadress = '/api/App2/End/AnCount/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<AnCount[]>(this.origin + apiadress, { 'headers': headers });
  }

  getalltiny(): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnCount/getalltiny';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

 
  getbyid(id): Observable<AnCount> {
    let apiadress = '/api/App2/End/AnCount/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<AnCount>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: AnCount) : Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnCount/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }



  getAnCountEmpty(): AnCount {

 
    return JSON.parse(JSON.stringify(EmptyAnCount));

  }
 

  update(item: AnCount): Observable<ApiRet>  {
    let apiadress = '/api/App2/End/AnCount/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post<ApiRet>(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(AnCountId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnCount/delete?id=' + AnCountId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 
  CreateRow(AnGarId:number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnCount/CreateRow?AnGarId=' + AnGarId.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });

  }
  CreateAnDeed(anCountId: number, inOutKey: number): Observable<ApiRet> {
    let apiadress = '/api/App2/End/AnCount/CreateAnDeed?anCountId=' + anCountId.toString() + '&inOutKey=' + inOutKey.toString();
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }



}
