import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Tafsili } from '../../../_models/app1/paye/Tafsili';
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
import { TafsiliForGroupRead } from '../../../_models/app1/paye/tafsiliForGroupRead';
import { Localst } from '../../localst.service';
import { TafsiliForSmall } from '../../../_models/app1/paye/tafsiliForSmall';
import { ApiRet } from '../../../_models/General/ApiRet';
 
//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};
let EmptyTafsili: Tafsili = {

  id: null,

  codeint: null,


  code: null,


  name: null,
  nameE: null,
  tafDaste: null,
  tafDasteId: null,


  dasteName: null,

  tabaghe: null,

  arzId: null,

  arzN: null,
  visib: true,
  dis: null,

  radif: null,
  show: true
};

let EmptyTafsiliForGroupRead: TafsiliForGroupRead = {

  hesabId: null,

  taf4List: [],
  taf5List: [],
  taf6List: [],

  daste4: null,
  daste5: null,
  daste6: null,
  have4: false,
  have5: false,
  have6: false
};


let HeaderTafsili: any = {

  id: 'id',
  code: 'کد',
  name: 'نام',
  nameE: 'نام لاتین',
  dasteId: 'dasteId',
  dasteName: 'نام گروه',
  tabaghe: 'طبقه',
  arzId: 'arzId',
  arzN: 'ارز',
  isActive: 'فعال',
  show: false,
  dis: 'توضیحات',

};
 
@Injectable({
  providedIn: 'root'
})
export class TafsiliService {
  baseUrl = environment.apiUrl;
  origin = location.origin ;
  constructor(private http: HttpClient, private localstService: Localst) { }

  getTafsilis(): Observable<Tafsili[]> {
    let apiadress = '/api/App1/Paye/Tafsili/getallnopage';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Tafsili[]>(this.origin + apiadress, { 'headers': headers });
  }
 
  getTafsilis_P(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Tafsili[]>> {
    let apiadress = '/api/App1/Paye/Tafsili/getall';
    var headers = this.localstService.header_get(apiadress,null);


    const paginatedResult: PaginatedResult<Tafsili[]> = new PaginatedResult<Tafsili[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (userParams != null) {
      params = params.append('Name', userParams.Name);
      params = params.append('Code', userParams.Code);
 
    }
   // console.log('App1/Paye/Tafsili/getall' + userParams.NameFarsi);
    return this.http.get<Tafsili[]>(this.origin + apiadress, { 'headers': headers , observe: 'response', params })
      .pipe(
        //اطلاعات کلاس پیجیشن را در میاره
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'))
          }
          return paginatedResult;
        })
    );
   
    //for (var val of getlist) {

    //  console.log(val); // prints values: 10, 20, 30, 40
    //}
   // console.log(getlist[0].Onvan);
 

  }

  getTafsilisGroup(hesabid): Observable<TafsiliForGroupRead> {
    let apiadress = '/api/App1/Paye/Tafsili/getTafsiliGroup?hesabid=' + hesabid;
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<TafsiliForGroupRead>(this.origin + apiadress, { 'headers': headers });
  }


  getTafsilisGroupList(): Observable<TafsiliForGroupRead[]> {
    let apiadress = '/api/App1/Paye/Tafsili/getTafsiliGroupList';
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.get<TafsiliForGroupRead[]>(this.origin + apiadress, { 'headers': headers });
  }



  getTafsiliJustGrupedByDaste(filter:boolean, visId: any, visTbl: any): Observable<ApiRet> {

    let apiadress = '/api/App1/Paye/Tafsili/TafsiliJustGrupedByDaste' + '?filter=' + filter + '&visId=' + visId + '&visTbl=' + visTbl;
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }


  getTafsiliJustGrupedByDaste_cash: string;
  getTafsiliJustGrupedByDaste_data: any;
  getTafsiliJustGrupedByDaste_cashOn(filter: boolean, visId: any, visTbl: any): Observable<ApiRet> {

    let apiadress = '/api/App1/Paye/Tafsili/TafsiliJustGrupedByDaste' + '?filter=' + filter + '&visId=' + visId + '&visTbl=' + visTbl + '&cache=' + this.getTafsiliJustGrupedByDaste_cash;;
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
  getTafsiliJustGrupedByDaste__cashGet(): any {
    return this.getTafsiliJustGrupedByDaste_cash;
  }
  getTafsiliJustGrupedByDaste__cashSet(data) {
    this.getTafsiliJustGrupedByDaste_cash = data;
  }

  getTafsiliJustGrupedByDaste__DataGet():any {
   return this.getTafsiliJustGrupedByDaste_data;
  }
  getTafsiliJustGrupedByDaste__DataSet(data): any {
     this.getTafsiliJustGrupedByDaste_data = data;
  }

  getTafsili(id): Observable<Tafsili> {
    let apiadress = '/api/App1/Paye/Tafsili/' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

     
    return this.http.get<Tafsili>(this.origin + apiadress, { 'headers': headers }).pipe( );
  

  }


  getNextCode(dasteid): Observable< number > {
    let apiadress = '/api/App1/Paye/Tafsili/NextTafsilicode?tafdasteId=' + dasteid.toString()
    var headers = this.localstService.header_get(apiadress,null);


    console.log(JSON.stringify(headers));

   return this.http.get<number>(this.origin + apiadress, { 'headers': headers }).pipe( );
  }
  addTafsili(item: Tafsili) {
    let apiadress = '/api/App1/Paye/Tafsili/addTafsili';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item ,{ 'headers': headers } );
  }

  getTafsiliGroupEmpty(): TafsiliForGroupRead {

    return EmptyTafsiliForGroupRead;


  }

  getTafsiliEmpty(): Tafsili {


    return   JSON.parse(JSON.stringify(EmptyTafsili));



  }

  getTafsiliHeader(): Tafsili {

    return HeaderTafsili;


  }
  updateTafsili(item: Tafsili) {

    let apiadress = '/api/App1/Paye/Tafsili/updateTafsili';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }

 
  deleteTafsili(TafsiliId: number): Observable<number> {
    let apiadress = '/api/App1/Paye/Tafsili/deletebyid?id=' + TafsiliId.toString();
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.delete<number>(this.origin + apiadress, { 'headers': headers });
 
  }
 
  countCode(item: string) {
    let apiadress = '/api/App1/Paye/Tafsili/countTafsilicode?code=' + item;
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.get(this.origin + apiadress, { 'headers': headers });
  }
  TafsiliListCode(): Observable<string[]> {

    let apiadress = '/api/App1/Paye/Tafsili/Tafsilicodelist';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
 
  }
  GetTafsiliForSmall(): Observable<TafsiliForSmall[]> {

    let apiadress = '/api/App1/Paye/Tafsili/GetTafsiliForSmall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<TafsiliForSmall[]>(this.origin + apiadress, { 'headers': headers });

  }
  GetTafsiliForSmallByDaste(dasteId: number, filter: boolean, visId : any, visTbl:any): Observable<TafsiliForSmall[]> {

    let apiadress = '/api/App1/Paye/Tafsili/GetTafsiliForSmallByDaste?dasteId=' + dasteId.toString() + '&filter=' + filter +'&visId=' + visId + '&visTbl=' + visTbl;
    var headers = this.localstService.header_get(apiadress,null);
    return this.http.get<TafsiliForSmall[]>(this.origin + apiadress, { 'headers': headers });
  }
  GetTafsiliForSmallBy2Dastes(dasteId1: number, dasteId2: number, filter: boolean, visId: any, visTbl: any): Observable<TafsiliForSmall[]> {

    let apiadress = '/api/App1/Paye/Tafsili/GetTafsiliForSmallBy2Dastes?dasteId1=' + dasteId1.toString() + '&dasteId2=' + dasteId2.toString() + '&filter=' + filter + '&visId=' + visId + '&visTbl=' + visTbl;;
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.get<TafsiliForSmall[]>(this.origin + apiadress, { 'headers': headers });
  }
 
  GetTafsiliForSmallByAnFormCode(anFormCode: number, filter: boolean, visId: any, visTbl: any): Observable<TafsiliForSmall[]> {

    let apiadress = '/api/App1/Paye/Tafsili/GetTafsiliForSmallByAnFormCode?anFormCode=' + anFormCode.toString()  + '&filter=' + filter + '&visId=' + visId + '&visTbl=' + visTbl;;
    var headers = this.localstService.header_get(apiadress, null);

    return this.http.get<TafsiliForSmall[]>(this.origin + apiadress, { 'headers': headers });
  }
  //این تابع کد ملی را با نام مرج نمیکند
  GetTafsiliForSmallByDastes(op): Observable<TafsiliForSmall[]> {

  

    let apiadress = '/api/App1/Paye/Tafsili/GetTafsiliForSmallByDastes';
    var headers = this.localstService.header_get(apiadress, null);
    return this.http.post<TafsiliForSmall[]>(this.origin + apiadress,op, { 'headers': headers });
  }
  countCode2(item: string) {
    console.log('m:service countCode');
    return null;
  }
}
