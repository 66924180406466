import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxDataGridComponent, DxSelectBoxComponent, DxTreeListComponent } from 'devextreme-angular';
 
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
import { IdName } from '../../../../_models/General/IdName';
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { parse } from 'querystring';
import { LoadingService } from '../../../../_services/loading.service';
 
import { ReportApp1Service } from '../../../../_services/app1/rep/ReportApp1.service';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { Tafsili } from '../../../../_models/app1/paye/tafsili';

import { RankingRepService } from '../../../../_services/app1/rep/RankingRep.service';
 
import { Hesab } from '../../../../_models/app1/paye/hesab';
import { TafsiliForGroupRead } from '../../../../_models/app1/paye/tafsiliForGroupRead';
import DataSource from "devextreme/data/data_source";
import { TafsiliGbydaste } from '../../../../_models/app1/paye/tafsiliGbydaste';
 
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { RankingOp, Rankingtree, RankingtreeBox } from '../../../../_models/app1/rep/RankingRep';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-Ranking-list',
  templateUrl: './Ranking-list.component.html',
  styleUrls: ['./Ranking-list.component.css']
})
  

export class RankingListComponent implements OnInit {

  openprint: boolean;
  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;
 
  model: RankingtreeBox;
  modelRiz: RankingtreeBox;
  modeltemp: RankingOp;
  modelop: RankingOp[];

 
  editId: number = -1;

  @ViewChild("moientempedit", { static: false }) moientempedit: DxSelectBoxComponent;
  @ViewChild("taf4tempedit", { static: false }) taf4tempedit: DxSelectBoxComponent;
  @ViewChild("taf5tempedit", { static: false }) taf5tempedit: DxSelectBoxComponent;
  @ViewChild("taf6tempedit", { static: false }) taf6tempedit: DxSelectBoxComponent;

  @ViewChild("dataTree", { static: false }) dataTree: DxTreeListComponent;
 
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() OnMyprint = new EventEmitter<any>();
  //حالت امکان صدور ثبت
  @Input() InActionMode: boolean;
  devDataTaf4: any = {};
  devDataTaf5: any = {};
  devDataTaf6: any = {};
 

  TafG: TafsiliForGroupRead;
  //TafGbala: TafsiliForGroupRead;
  TafGListall: TafsiliForGroupRead[] = [];
  TafGListallCount = 0;
  tafjustdaste: TafsiliGbydaste[] = [];
  hesablist: Hesab[] = [];
  taflevel: any[] = [{ id: 4, name: 'سطح چهارم' },{ id: 5, name: 'سطح پنجم' },{ id: 6, name: 'سطح ششم' }];


  valdata: DialogData;
 
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private MorurService: MorurService,
    private reportapp1Service: ReportApp1Service,
    private tafsiliServicee: TafsiliService,
    private rankingRepService: RankingRepService,
    private route: ActivatedRoute,
    private TafsiliService: TafsiliService,
    private hesabService: HesabService,
    private localst: Localst,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 20);


    this.openprint = false;
  
 
    //if (this.model == null)
    //{

    //  this.model = reportapp1Service.GetRankingRepEmpty();
    //  let y = this.localst.yearnow_Class_get();
    //  this.model.years = y.codeSal;
    //  this.model.dateS_S = y.codeSal.toString() +'0101';
    //  this.model.dateE_S = y.codeSal.toString() +'1230';
    //  this.model.taf4Id = -1;
 

    //  this.model.rows = [];
       
 

    // }

 

  }

  ngOnInit() {

    this.model = this.rankingRepService.getRankingtreeBoxEmpty();
    this.modelRiz = this.rankingRepService.getRankingtreeBoxEmpty();
   
    this.modelop = [];
    this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
    this.modeltemp = this.rankingRepService.getRankingOpEmpty();
    this.refleshhesabvatafsili();
    this.loadops();
    this.loadtree();

    this.loadServ.showloading(this.loaddata, 3);
    this.tafsiliServicee.getTafsilis().subscribe((data) => {

      this.loadServ.hideloading(this.loaddata, 3);
  

      //  console.log(JSON.stringify(this.model));

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  ngOnChanges(changes: any) {


    console.log('chanmge: ' + JSON.stringify(changes));
     

    this.loadRanking();
  }
  

  async refleshhesabvatafsili() {



    this.loadServ.showloading(this.loaddata, 17);

    //let a = null;
    //if (this.kindpage == 101 || this.kindpage == 103 || this.kindpage == 104 || this.kindpage == 106 || this.kindpage == 110
    //  || this.kindpage == -101 || this.kindpage == -103 || this.kindpage == -104 || this.kindpage == -106 || this.kindpage == -110
    //  || this.kindpage == -111 || this.kindpage == -21)
    //  a = await this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1001, -1002).toPromise();
    //else
    //  a = await this.TafsiliService.GetTafsiliForSmallBy2Dastes(-1003, -1004).toPromise();

    //this.tafMolist = new DataSource({
    //  store: a,
    //  paginate: true,
    //  pageSize: 50
    //});




    this.tafjustdaste = (await this.TafsiliService.getTafsiliJustGrupedByDaste(false, this.editId, 'none').toPromise()).data;


    ////اطلاعات حسابداری


    this.hesablist = await this.hesabService.getHesabs_treenum(3).toPromise();



    this.TafGListall = await this.TafsiliService.getTafsilisGroupList().toPromise();
    this.TafGListallCount = this.TafGListall.length;
   // this.tafg_reflesh(this.selectedrow.hesabId);
 

    //پاک کردن کش تمام حساب های tafglist
    for (var r = 0; r < this.TafGListall.length; r++) {
      if (this.TafGListall[r].taf4List.length > 0) {
        this.TafGListall[r].taf4List = [];
        this.TafGListall[r].taf5List = [];
        this.TafGListall[r].taf6List = [];
        this.tafg_reflesh(this.TafGListall[r].hesabId);
      }
    }



    this.loadServ.hideloading(this.loaddata, 17);



  }


  
  onRowDblClick(e) {

    if (e.key == null)
      return;

  
  

  }
 
 

  adjustWidth(e,w) {

    e.component._popup.option('width', w);
   
   
    }
 
  mochanged(e) {

    if (e.value == null)
      return;
    //hgfgfdsfgh543
    var h = e.component.option('selectedItem') as Hesab;
    //????????
    if (h == null)
      return;

    this.tafg_reflesh(e.value);


    this.modeltemp.hesabC = h.codeSum;
    this.modeltemp.hesabN = h.name;



    this.devDataTaf4 = new DataSource({
      store: this.TafG.taf4List,
      paginate: true,
      pageSize: 50
    });
    this.devDataTaf5 = new DataSource({
      store: this.TafG.taf5List,
      paginate: true,
      pageSize: 50
    });
    this.devDataTaf6 = new DataSource({
      store: this.TafG.taf6List,
      paginate: true,
      pageSize: 50
    });

  }

 

  t4changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    this.modeltemp.taf4C = h.code;
    this.modeltemp.taf4N = h.name;
 
  }
  t5changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    this.modeltemp.taf5C = h.code;
    this.modeltemp.taf5N = h.name;
 
  }
  t6changed(e) {
    if (e.value == null)
      return;
    var h = e.component.option('selectedItem');
    this.modeltemp.taf6C = h.code;
    this.modeltemp.taf6N = h.name;

 

  }


  ///???????????
  tafg_reflesh(hesabid: number) {

    if (hesabid == null) {
      //hhjhjg776g

      this.TafG = this.TafsiliService.getTafsiliGroupEmpty();

       this.devDataTaf4 = [];
      this.devDataTaf5 = [];
      this.devDataTaf6 = [];
      //this.TafG = this.TafsiliService.getTafsiliGroupEmpty();
      //this.devDataTaf4 = new DataSource({
      //  store: this.TafG.taf4List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf5 = new DataSource({
      //  store: this.TafG.taf5List,
      //  paginate: true,
      //  pageSize: 50
      //});
      //this.devDataTaf6 = new DataSource({
      //  store: this.TafG.taf6List,
      //  paginate: true,
      //  pageSize: 50
      //});
      return;


    }
    //?????????????????????????????? چرا
    //if (this.TafG.hesabId == null) {
    //  return;
    //}
    //if (this.TafG.hesabId == hesabid) {
    //  return;
    //}

    this.TafG = null;

    //tguyrtu555

    //خواندن تفصیلی ها
    for (var _i = 0; _i < this.TafGListallCount; _i++) {

      //پیدا کردن حسابی که انتخاب کردیم
      if (this.TafGListall[_i].hesabId == hesabid) {
        this.TafGListall[_i].taf4List = [];
        this.TafGListall[_i].taf5List = [];
        this.TafGListall[_i].taf6List = [];


        //خواندن لیست تمام تفصیلی های موجود درسته ان حساب

        for (var j = 0; j < this.tafjustdaste.length; j++) {
          //خواندن تفصیلی 4
          // if (this.TafGListall[_i].taf4List.length == 0)
          for (var t4 = 0; t4 < this.TafGListall[_i].daste4.length; t4++) {

            if (this.TafGListall[_i].daste4[t4] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf4List = this.TafGListall[_i].taf4List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی   5
          // if (this.TafGListall[_i].taf5List.length == 0)
          for (var t5 = 0; t5 < this.TafGListall[_i].daste5.length; t5++) {

            if (this.TafGListall[_i].daste5[t5] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf5List = this.TafGListall[_i].taf5List.concat(this.tafjustdaste[j].rows);
            }

          }
          //خواندن تفصیلی 6
          // if (this.TafGListall[_i].taf6List.length == 0)
          for (var t6 = 0; t6 < this.TafGListall[_i].daste6.length; t6++) {

            if (this.TafGListall[_i].daste6[t6] == this.tafjustdaste[j].dasteId) {
              this.TafGListall[_i].taf6List = this.TafGListall[_i].taf6List.concat(this.tafjustdaste[j].rows);
            }

          }


        }




        this.TafG = this.TafGListall[_i];
        //this.devDataTaf4 = new DataSource({
        //  store: this.TafG.taf4List,
        //  paginate: true,
        //  pageSize: 50
        //});
        //this.devDataTaf5 = new DataSource({
        //  store: this.TafG.taf5List,
        //  paginate: true,
        //  pageSize: 50
        //});
        //this.devDataTaf6 = new DataSource({
        //  store: this.TafG.taf6List,
        //  paginate: true,
        //  pageSize: 50
        //});

    

        return;
      }

    }









  }

 
  loadRanking() {

    if (this.model == null)
      return;
 
 ////   console.log(JSON.stringify(this.model));
 //   this.loadServ.showloading(this.loaddata, 0);
 
 //   this.rankingRepService.getall(this.model).subscribe((data) => {

 //     this.loadServ.hideloading(this.loaddata, 0);
 //     this.model = data;

 //   //  console.log(JSON.stringify(this.model));

 //   }, error => {
 //       this.loadServ.hideloading(this.loaddata, 0);
 //     this.alertify.error(error);
    
 //   });

  } 

   
 
 
  RowPrepared(e) {

    //if (e.key < 0) {
    //  /*  e.rowElement.style.border = '10px solid';*/
    //  e.rowElement.style.backgroundColor = '#E1E1F0';
    //}

    //e.rowElement.css('background', 'green');
  }

  onClickExportexcel() {



    this.loadServ.showloading(this.loaddata, 3);
    this.rankingRepService.AnRankingAsExcel().subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }
  onClickRizExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' جزئیات ');


  }
  onClickprint() {
    //yhkigoiyuo5
    let data =  {isRiz:false , taftext:'ttt', option: this.modelop, model: this.model };
    
    this.OnMyprint.emit(JSON.parse(JSON.stringify(data)));

  }
  onClickprintRiz(){


    let data = { isRiz: true, taftext: 'tttriz', option: this.modelop, model: this.modelRiz };

    this.OnMyprint.emit(JSON.parse(JSON.stringify(data)));

  }



  cnt: number = -1;
  onClickAddf() {


     


    this.cnt--;
    this.modeltemp.id = this.cnt;

    let rowop = JSON.parse(JSON.stringify(this.modeltemp));



    this.modelop.push(rowop);

    this.modeltemp = this.rankingRepService.getRankingOpEmpty();
  }


  onClickSearch() {






    this.loadServ.showloading(this.loaddata, 6);
    this.rankingRepService.CreateAndSave(this.modelop).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 6);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {

        this.loadtree();
      }
     

    //  console.log(JSON.stringify(this.model));

    }, error => {
        this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);

    });


  }


  onClickexportToClient() {

    this.loadServ.showloading(this.loaddata, 6);
    this.rankingRepService.exportToClient().subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 6);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {

        this.alertify.success(res.mes);
      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 6);
      this.alertify.error(error);

    });
  }

  loadtree() {



    this.loadServ.showloading(this.loaddata, 7);
    this.rankingRepService.getReport().subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 7);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.model = res.data;

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 7);
      this.alertify.error(error);

    });



  }
 
  loadops() {



    this.loadServ.showloading(this.loaddata, 8);
    this.rankingRepService.getall_Op().subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 8);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.modelop = res.data;

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 8);
      this.alertify.error(error);

    });



  }

  onDeleterow(info) {




    var a =[];

    //fdger444
    let index = -1;
    

 
    for (var r of this.modelop) {
    
      if (r.id != info.data.id) {
        a.push(r);
        
      }
    }
 
    this.modelop = a;

  }




  rizsopen = false;
  closeclick() {
    this.rizsopen = false;
  }

  RowDblClick(e) {

    
    this.openRiz(e.data.id);
  }
  onClickRiz() {

    let key = this.dataTree.focusedRowKey;
    //fgh656u5
    if (key == null || key == -1) {
      this.alertify.warning('هیچ ردیفی انتخاب نشده');
      return;
    }

    this.openRiz(key);
  }
  openRiz(key) {

    this.rizsopen = !this.rizsopen;

 

    if (key == null)
      return;


    let postdata = null;
    //اگر مادر بود
    if (key > 0) {

      for (var r of this.model.rows) {
        if (r.id == key) {

            postdata ={ opId: 0, tafId: r.tafId };

        }
      }

    }
    else {
      for (var r of this.model.rows) {
        for (var u of r.sub) {
          if (u.id == key) {
            postdata= { opId: u.rankingOpId, tafId: u.tafId };
          }
        }
      }
      // postdata.push({ opId: datarow.rankingOpId, tafId: datarow.tafId });
    }

    this.loadServ.showloading(this.loaddata, 9);
    this.rankingRepService.getRiz(postdata).subscribe((res) => {

      this.loadServ.hideloading(this.loaddata, 9);

      if (res.errors.length > 0) {
        this.alertify.showListString(res.errors);
      }
      else {
        this.modelRiz = res.data;

      }

    }, error => {
      this.loadServ.hideloading(this.loaddata, 9);
      this.alertify.error(error);

    });

  }
  onClickdoedit() {


    let rowop = JSON.parse(JSON.stringify(this.modeltemp));

    for (var i = 0; i < this.modelop.length; i++) {
      if (this.modelop[i].id == rowop.id) {
        this.modelop[i] = rowop;
        break;
      }

    }

    this.modeltemp = this.rankingRepService.getRankingOpEmpty();


  }

  onEditrow(e) {


    this.modeltemp = JSON.parse(JSON.stringify(e.data)); 

   
  }
  onClickcancel() {
    this.modeltemp = this.rankingRepService.getRankingOpEmpty();
  }
 
} 


