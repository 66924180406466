import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
 
import { environment } from '../../../../environments/environment';
import { PaginatedResult } from '../../../_models/pagination';
 
import { Localst } from '../../localst.service';
import { Anbar } from '../../../_models/app2/paye/anbar';
import { AnbarKala } from '../../../_models/app2/paye/AnbarKala';
import { ApiRet } from '../../../_models/General/ApiRet';
 
 
let EmptyAnbar: Anbar = {

  id: null,
 
  code:null,
  name: null,
  anbardar: null,
  tell: null,
  adress: null,
  visib: true,
  isManfi: false,
  isKalaDupl:false

};
let EmptyAnbarKala: AnbarKala = {

  id: null,


  anbarId: null,

  kalaId: null


};

 
@Injectable({
  providedIn: 'root'
})
export class AnbarService {
/*  baseUrl = environment.apiUrl;*/
  origin = location.origin;

  constructor(private http: HttpClient, private localstService: Localst) { }

  getall(): Observable<Anbar[]> {
    let apiadress = '/api/App2/Paye/Anbar/getall';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<Anbar[]>(this.origin + apiadress, { 'headers': headers });
  }

  getalltiny(): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Anbar/getalltiny';
    var headers = this.localstService.header_get(apiadress, null);


    return this.http.get<ApiRet>(this.origin + apiadress, { 'headers': headers });
  }
 

 
  getbyid(id): Observable<Anbar> {
    let apiadress = '/api/App2/Paye/Anbar/getbyid?id=' + id.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    let v = this.http.get<Anbar>(this.origin + apiadress, { 'headers': headers }).pipe();
  
    return v;
    // return this.http.get<User>(origin + 'users/' + id, httpOptions);
  }
 
  add(item: Anbar) {
    let apiadress = '/api/App2/Paye/Anbar/add';
    var headers = this.localstService.header_get(apiadress,null);

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }



  getAnbarEmpty(): Anbar {

 
    return JSON.parse(JSON.stringify(EmptyAnbar));

  }
  getAnbarEmptyKala(): AnbarKala {


    return JSON.parse(JSON.stringify(EmptyAnbarKala));

  }

  update(item: Anbar) {
    let apiadress = '/api/App2/Paye/Anbar/update';
    var headers = this.localstService.header_get(apiadress,null);

 

    return this.http.post(this.origin + apiadress, item, { 'headers': headers });
  }


  delete(AnbarId: number): Observable<ApiRet> {
    let apiadress = '/api/App2/Paye/Anbar/delete?id=' + AnbarId.toString();
    var headers = this.localstService.header_get(apiadress,null);

 
    return this.http.delete<ApiRet>(this.origin + apiadress, { 'headers': headers }).pipe();
  }
  getallCode(): Observable<string[]> {
    let apiadress = '/api/App2/Paye/Anbar/getallCods';
    var headers = this.localstService.header_get(apiadress,null);


    return this.http.get<string[]>(this.origin + apiadress, { 'headers': headers });
  }
}
