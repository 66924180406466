import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../../../node_modules/@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
import { Tadilmahilat, TadilmahilatOption } from '../../../../_models/app1/rep/Tadilmahilat';
import { TadilmahilatService } from '../../../../_services/app1/rep/Tadilmahilat.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { DxDataGridComponent } from 'devextreme-angular';
 
import { MorurService } from '../../../../_services/app1/rep/Morur.service';
import { ExcelService } from '../../../../_services/excel.service';
import { Localst } from '../../../../_services/localst.service';
import { IdName } from '../../../../_models/General/IdName';
import { DialogData, DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { parse } from 'querystring';
import { LoadingService } from '../../../../_services/loading.service';
import { Sabt } from '../../../../_models/app1/opr/sabt';
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';
 



@Component({
  selector: 'app-Tadilmahilat-list',
  templateUrl: './Tadilmahilat-list.component.html',
  styleUrls: ['./Tadilmahilat-list.component.css']
})
  

export class TadilmahilatListComponent implements OnInit {

  openprint: boolean;
  @ViewChild("dxgrid", { static: false }) dxgrid: DxDataGridComponent;
  openedit: boolean;
  openeditId: number;
  tadilop: TadilmahilatOption;

  tadilopAfterAutoSanad: TadilmahilatOption;
  @Output() OnMyLoadSanadEvent = new EventEmitter<any>();
  @Output() OnMyprint = new EventEmitter<any>();
  //حالت امکان صدور ثبت
  @Input() InActionMode: boolean;
  salemaliMin: number;
  salemaliMax: number;

  bedsum: number;
  bessum: number;
  bedsumman: number;
  bessumman: number;
  selectedrow: Sabt;
 
  valdata: DialogData;
 
 
  user: User = JSON.parse(localStorage.getItem('user'));
 
  shakhsParams: any = {};
  focusedRowKey: number;
  bls1: string;
  bls2: string;
  bls3: string;
  bls4: string;
  bls5: string;
  bls6: string;
 
  selectedlevelold: number;
  selectedlevelnew: number;
  selectedlevelDBClicknew: number;
  //animal: string;
  //name: string;
  isdelete: string;
  kindhesab: IdName[] = [
    { id: -1, name: "(همه)" },
    { id: 1, name: "دائمی" },
    { id: 2, name: "موقت" },
    { id: 3, name: "انتظامی" },
  ];
  mahiyatdb: IdName[] = [
    { id: -1, name: "(همه)" },
    { id: 1, name: "بدهکار" },
    { id: 2, name: "بستانکار" },
    { id: 3, name: "بدهکار بستانکار" },
  ];

  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private TadilmahilatService: TadilmahilatService,
    private sabtService: SabtService,
    private route: ActivatedRoute,
    private localst: Localst,
    private loadServ: LoadingService,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.loadServ.create(this.loaddata, 5);

    this.selectedrow = sabtService.getSabtEmpty();
 
    this.openprint = false;
    this.bls1 = 'btn btn-light btndef';
    this.bls2 = 'btn btn-light btndef';
    this.bls3 = 'btn btn-light btndef';
    this.bls4 = 'btn btn-light btndef';
    this.bls5 = 'btn btn-light btndef';
    this.bls6 = 'btn btn-light btndef';
    this.selectedlevelold = -2;
    this.selectedlevelnew = -1;
    this.selectedlevelDBClicknew = -1;
    this.focusedRowKey = -1;
    this.selectchanginuse = false;
    if (this.tadilop == null)
    {

      this.tadilop = TadilmahilatService.getemptyTadilmahilatOption();
      let y = this.localst.yearnow_Class_get();
      this.tadilop.year = y.codeSal;
      this.tadilop.date = y.codeSal.toString() +'1229';
      this.tadilop.mahiat = -1;
      this.tadilop.noeHesab = -1;
      this.tadilop.rows = [];


      this.tadilopAfterAutoSanad = JSON.parse(JSON.stringify(this.tadilop));

     }

 

  }

  ngOnInit() {
    this.resetFilters();
    this.isdelete = "no";
    this.salemaliMin= 1390;
    this.salemaliMax= 1444;

  }
  ngOnChanges(changes: any) {


    console.log('chanmge: ' + JSON.stringify(changes));
     

    this.loadTadilmahilat();
  }
  
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = ''; 
    this.shakhsParams.Codmelli = '';
 
  }
 

 


  onFocusedRowChanged(e) {
    console.log('onFocusedRowChanged');
 
    if (e.row == null) {


      return;
    }

    this.selectedrow = e.row.data as Sabt;
     
    console.log(JSON.stringify(this.selectedrow));
    //for (var _i = 0; _i < this.mod.sabts.length; _i++) {


    //  if (this.mymodle.sabts[_i].id == f.id) {

 

    //    this.selectedrow = this.mymodle.sabts[_i];
    //  }

 

  }

  onRowDblClick(e) {

    if (e.key == null)
      return;

    let sabtid = e.key;
    console.log('jjj: ' +sabtid);
    if (this.tadilop.rows == null)
      return;

    this.gotoghardesh(sabtid);

  }
  onClickedickNew() {

  

 

  }


  onClickDelete() {

 

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

       // this.dodelete();

      }

    });
  }






  onClickEdit() {

    //if (this.getselectedrow() == null) {



    //  return;
    //}



    //this.openeditId = this.getselectedrow().id;
    //this.openedit = true;



  }
  onClickNew() {

    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

  

  }

  onClicklevel(level: number) {

    this.bls1 = this.bls1.replace(' btnbk', '');
    this.bls2 = this.bls2.replace(' btnbk', '');
    this.bls3 = this.bls3.replace(' btnbk', '');
    this.bls4 = this.bls4.replace(' btnbk', '');
    this.bls5 = this.bls5.replace(' btnbk', '');
    this.bls6 = this.bls6.replace(' btnbk', '');


  
    if (level == 1)
      this.bls1 += ' btnbk';
   else  if (level == 2)
      this.bls2 += ' btnbk';
    else  if (level == 3)
      this.bls3 += ' btnbk';
    else if (level == 4)
      this.bls4 += ' btnbk';
    else if (level == 5)
      this.bls5 += ' btnbk';
    else if (level == 6)
      this.bls6 += ' btnbk';


 
    //this.loadTadilmahilatSub(

    //  this.selectedrow.hesOrTafId,


    //);

    if (level != this.selectedlevelnew) {
      this.selectedlevelold = this.selectedlevelnew;
    }
    this.selectedlevelnew = level;
    console.log('old ' + this.selectedlevelold + 'new' + this.selectedlevelnew);



    if (this.getselectedrow() == null) {

      this.tadilop.rows = null;

    }
    else  {
      //this.loadTadilmahilatSub(
      //  this.selectedlevelold,
      //  this.getselectedrow().hesOrTafId,
      //  this.selectedlevelnew
      //);
    }

  }


 
  loadTadilmahilat() {

    if (this.tadilop == null)
      return;
 
 //   console.log(JSON.stringify(this.tadilop));
    this.loadServ.showloading(this.loaddata, 0);
    this.TadilmahilatService.getTadilmahilat(this.tadilop).subscribe((data) => {

      this.loadServ.hideloading(this.loaddata, 0);
      this.tadilop = data;
      this.calcsum();
    //  console.log(JSON.stringify(this.tadilop));

    }, error => {
        this.loadServ.hideloading(this.loaddata, 0);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  } 

   
  mtemp: Tadilmahilat;
  getselectedrow() : Tadilmahilat {
 

    var r = this.dxgrid.focusedRowKey;
    if (r == null) return null;
    if (this.tadilop.rows == null) return null;

   // console.log(this.focusedRowKey)
    for (var _i = 0; _i < this.tadilop.rows.length; _i++) {

      //if (this.focusedRowKey == this.modlelist[_i].id) {
         

      //  return this.modlelist[_i];
      //}
 
    }

    return null;


  }
  test() {

    var para = document.createElement('p');
    var title = document.createElement('title');

    // define some window attributes
    var features = 'width=1200, height=900, status=1, menubar=1, location=0, left=100, top=100';
    var winName = 'print';

    // populate the html elements
    para.textContent = '<p>fghjfgjghkjgh</p>';
    title.textContent = 'New Window Title';
     


    var winRef = window.open('', winName, features);


  
   // winRef.document.write(this.TadilmahilatService.getprint(this.modlelist));








  }

  onClickghardesh() {

    var item = this.dxgrid.selectedRowKeys;

    if (item == null)
      return;



    for (var i = 0; i < item.length; i++) {

      this.gotoghardesh(i);
    }


 


  }

  gotoghardesh(rowidkey : number) {


    var option = { "HPSanadId": -1, "HPSabtId": -1, "HPOpenOnInit": false };
    for (var i = 0; i < this.tadilop.rows.length; i++) {

      if (this.tadilop.rows[i].id == rowidkey) {

        //console.log(JSON.stringify(this.modlelist[i]));
       // var option = { "HPSanadId": this.modlelist[i].sanadId, "HPSabtId": this.modlelist[i].id, "HPOpenOnInit": true };
        //sanadid = this.modlelist[i].sanadId;

        break;
      }

    }


    if (option.HPSanadId != -1)
      this.OnMyLoadSanadEvent.emit(option);



  }
  onClickReflesh() {

    this.loadTadilmahilat();
  }

  onClickExportexcel() {



    this.excelService.exportAsExcelFileDevextream(this.dxgrid.instance, ' ریز گردش ');

  }
 
  onClickprint() {


    var t = this.tadilop;
 

  //  var mdata = { orgrows: t, orgheader: this.tadilop }

    this.OnMyprint.emit(JSON.parse(JSON.stringify(this.tadilop)));

  }

  onClickDoAutoSanad() {

    let mess = 'از صدور سند تعدیل ماهیت اخر دوره اطمینان دارید؟';
    
    this.valdata = { title: mess, rows: [' '], valid: false, kind: 'yesno', selectbutton: 'none', datastringShow: true, datastring: this.localst.yearnow_Class_get().dateE.toString(),styleMode:0}


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1500';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {
     // console.log('m:The dialog was closed');
    

      if (result != null) {

        let dialogdata = result;
        //console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

         // console.log(JSON.stringify(dilogconfig));
          this.createTadilSanad(dilogconfig.data);

        }
      }
    });


  }
 
  tadilopTemp: TadilmahilatOption;
  createTadilSanad(data :any) {
    console.log('createTadilSanad');

    var keys = this.dxgrid.selectedRowKeys;
    if (keys == null)
      return;


  //  console.log(JSON.stringify(keys));

   this.tadilopTemp = (JSON.parse(JSON.stringify(this.tadilop)));
    this.tadilopTemp.rows = [];
    this.tadilopTemp.rowsErrors = []; 
    this.tadilopTemp.date = data.datastring;
    //انتخاب سلکت شده ها
 
    for (var k = 0; k < keys.length; k++) {

      for (var r = 0; r < this.tadilop.rows.length; r++) {

        if (this.tadilop.rows[r].id == keys[k]) {

          if (this.tadilop.rows[r].correct == false) {
            this.alertify.message('تنها حساب های مجوز دار قابلیت انتخاب را دارند');
            console.log('تنها حساب های مجوز دار قابلیت انتخاب را دارند');
            return;
          }
          this.tadilopTemp.rows.push(this.tadilop.rows[r]);

        }


      }
      
 }

   

   // console.log(JSON.stringify(this.tadilopTemp));

    if (this.tadilopTemp.rows.length == 0) {

      this.alertify.message('هیچ ردیفی انتخاب نشده');
      return;
    }
     


    this.loadServ.showloading(this.loaddata, 1);
      this.TadilmahilatService.AutoSanadTadilmahilat(this.tadilopTemp).subscribe((data) => {

        this.loadServ.hideloading(this.loaddata, 1);
      //Tadilmahilat class
    //  this.tadilop = data;
      this.tadilopAfterAutoSanad = data;

        if (this.tadilopAfterAutoSanad.rowsErrors.length > 0) {

          var row: string[] = [];

          for (var r = 0; r < this.tadilopAfterAutoSanad.rowsErrors.length; r++) {
            let v = this.tadilopAfterAutoSanad.rowsErrors[r].hesabCode;

            if (this.tadilopAfterAutoSanad.rowsErrors[r].l4Code != null)
              v += " " + this.tadilopAfterAutoSanad.rowsErrors[r].l4Code;

            if (this.tadilopAfterAutoSanad.rowsErrors[r].l5Code != null)
              v += " " + this.tadilopAfterAutoSanad.rowsErrors[r].l5Code;

            if (this.tadilopAfterAutoSanad.rowsErrors[r].l6Code != null)
              v += " " + this.tadilopAfterAutoSanad.rowsErrors[r].l6Code;

            row.push(v);
          }



          this.errortadilhesabshow(row);

        }
        else {
          this.alertify.success(' سند تعدیل ماهیت اخر دوره با موفقیت صادر گردید');
        }
     // console.log(JSON.stringify(this.tadilopAfterAutoSanad));
        this.loadTadilmahilat();
      }, error => {
          this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });


  }


  errortadilhesabshow(row : string[]) {

    let mess = 'سطوح حساب تعدیل ماهیت اخر دوره در موارد زیر همخوانی ندارد';
    this.valdata = { title: mess, rows: row, valid: false, kind: 'alert', selectbutton: 'none', datastringShow: false, datastring: null, styleMode: 0 }


    // this.valdata.rows = verror;

    const dilogconfig = new MatDialogConfig();
    dilogconfig.width = '1500';
    dilogconfig.data = this.valdata;


    let dialogRef = this.dialog.open(DialogvalidComponent, dilogconfig);


    dialogRef.afterClosed().subscribe(result => {
      // console.log('m:The dialog was closed');


      if (result != null) {

        let dialogdata = result;
        //console.log(JSON.stringify(dialogdata));
        if (dialogdata.selectbutton == 'yesdone') {

   

        }
      }
    });


  }

  selectchanginuse: boolean;
  onSelectionChanged(e) {

    //if (this.selectchanginuse == true)
    //  return;

    //this.selectchanginuse = true;

    //var keys = this.dxgrid.selectedRowKeys;
    //var keysAccept : Number[] = [];
    //if (keys == null)
    //  return;


    ////اگر کلیدی داشتیم که مجوز بستن نداشت ان را از حالت انتخاب در میس او ریم
    //for (var k = 0; k < keys.length; k++) {

    //  for (var r = 0; r < this.tadilop.rows.length; r++) {

    //    if (this.tadilop.rows[r].id == keys[k]) {

    //      if (this.tadilop.rows[r].correct == true) {
    //        keysAccept.push(keys[k]);
           
    //      }

    //    }


    //  }

    //}
    //console.log(JSON.stringify(keysAccept));
    //this.dxgrid.instance.clearSelection();
    //this.dxgrid.instance.selectRows(keysAccept, true);

    //this.selectchanginuse = false;


  }
  datestartchang(e) {



  }
  onFocusInDateS(e) {

  }

  calcsum() {
    this.bedsum = 0;
    this.bessum = 0;
    this.bedsumman = 0;
    this.bessumman = 0;
    for (var r = 0; r < this.tadilop.rows.length; r++) {

      this.bedsum += this.tadilop.rows[r].mandehBed;
      this.bessum += this.tadilop.rows[r].mandehBes;


    }

    this.bedsumman = this.bedsum - this.bessum;
    if (this.bedsumman < 0) {
      this.bessumman = this.bedsumman * -1;
      this.bedsumman = 0;
    }

 
  }
} 


