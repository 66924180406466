import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { SanadService } from '../../../../_services/app1/opr/Sanad.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { Sanad } from '../../../../_models/app1/opr/Sanad';
import { Localst } from '../../../../_services/localst.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { DialogData,DialogvalidComponent } from '../../../../dialogvalid/dialogvalid.component';
import { ExcelService } from '../../../../_services/excel.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
 
 
 
 
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../../../../_services/loading.service';

import { Sabt, SearchSanad } from '../../../../_models/app1/opr/sabt';
import { SabtService } from '../../../../_services/app1/opr/Sabt.service';
import { TafsiliForSmall } from '../../../../_models/app1/paye/tafsiliForSmall';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { hesabsmall } from '../../../../_models/app1/paye/hesabsmall';
import { HesabService } from '../../../../_services/app1/paye/Hesab.service';
import { SaleMaliService } from '../../../../_services/General/SaleMali.service';
import { SaleMali } from '../../../../_models/General/saleMali';
import DataSource from "devextreme/data/data_source";
 

@Component({
  selector: 'app-Sanad-search',
  templateUrl: './Sanad-search.component.html',
  styleUrls: ['./Sanad-search.component.css']
})
 

export class SanadsearchComponent implements OnInit {
 
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();
  @Input() hyperLinkOption: any;

  openeprint: boolean;
 openeDuplcate : boolean;
 openedit: boolean;
  openeditId: number;
 

  focusedSabtId: number;
  
  tafsililist: TafsiliForSmall[];
  devDataTafall: any = {};
  hesabsmallist: hesabsmall[];


  valdata: DialogData;
  selectedrow: any;
  defaultpagation: Pagination = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
   };

  model: SearchSanad;
 
  salemaliMax: number;
  salemaliMin: number;
  salemalilist: SaleMali[];
  shakhsParams: any = {};
  pagination: Pagination;
  header: any;

  //animal: string;
  //name: string;
  isdelete: string;
  selectedrowkeys: number[];
  selectedrowkeysIgnored: number[];
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;
 
  loaddata: loadingCl;
  printnum: number;
  duplcateOption: any;
  constructor(private userService: UserService,
    private SanadService: SanadService,
    private cd: ChangeDetectorRef,
 
    private loadServ: LoadingService,
    private alertify: AlertifyService,
    private saleMaliService: SaleMaliService,
    private SabtService: SabtService,
    private tafsiliService: TafsiliService,
    private hesabService: HesabService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
    this.tafsililist = [];
    this.hesabsmallist = [];

    this.openedit= false;
    this.openeditId = -1;
    this.focusedSabtId = -1;
    this.salemaliMax = 0;
    this.salemaliMin = 9999;
    this.loadServ.create(this.loaddata,8);



    this.model = SabtService.getSearchSanadEmpty();

  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode);
 
    //c=67
    if (!this.openedit) {
      if (event.keyCode == 13 ) {

        this.onClickReflesh();
      }
      if (event.keyCode == 32) {

      //  this.onClickEdit();
      }
    }



  }
  ngOnInit() {


    this.loadServ.showloading(this.loaddata, 2);
    this.tafsiliService.GetTafsiliForSmall()
      .subscribe((res: TafsiliForSmall[]) => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.tafsililist = res;

        this.devDataTafall = new DataSource({
          store: this.tafsililist,
          paginate: true,
          pageSize: 50
        });


      }, error => {
        this.loadServ.hideloading(this.loaddata, 2);
        this.alertify.error(error);
      });


    this.loadServ.showloading(this.loaddata, 3);
    this.hesabService.gethesabsmall(3)
      .subscribe((res: hesabsmall[]) => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.hesabsmallist = res;
      }, error => {
        this.loadServ.hideloading(this.loaddata, 3);
        this.alertify.error(error);
      });



    this.loadServ.showloading(this.loaddata, 0);
    //خواندن سال مالی
    this.saleMaliService.getSaleMalis().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 0);
      this.salemalilist = data;

      //  console.log(JSON.stringify(this.salemalilist));

      if (this.salemalilist == null)
        this.salemalilist = [];

      for (var i = 0; i < this.salemalilist.length; i++) {

        //  console.log(this.salemalilist[i].codeSal);
        if (this.salemalilist[i].codeSal > this.salemaliMax) {
          this.salemaliMax = this.salemalilist[i].codeSal;


        }
        if (this.salemalilist[i].codeSal < this.salemaliMin) {

          this.salemaliMin = this.salemalilist[i].codeSal;
       

        }

      }

      var yearc = this.localst.yearnow_Class_get();
      this.model.yearS = yearc.codeSal;
      this.model.yearE = yearc.codeSal;
      this.model.dateS = yearc.dateS.toString();
      this.model.dateE = yearc.dateE.toString();


    }, error => {
      this.loadServ.hideloading(this.loaddata, 0);
      console.log("myerror:" + error)
    });



  }
  ngAfterViewInit() {


  }
  onClicksearch() {

  }
  onClicksearchall() {
  
  }
  searchSabts() {

   
    this.loadServ.showloading(this.loaddata,0);
  
    this.SabtService.SearchSabtssanad(this.model)
      .subscribe((res: SearchSanad) => {
        this.loadServ.hideloading(this.loaddata,0);
        this.model = res;
      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

  }

 
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';
 
  }
 


  reloadrows(): void {
 
  }

 


 

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;


    if (this.selectedrow != null)
      this.printnum = this.selectedrow.cShomare;
  
 

  }




  onClickExportexcel() {

    this.excelService.exportAsExcelFileDevextream(this.gridContainer.instance,' لیست اسناد ');

   // this.excelService.exportAsExcelFile(this.modelsearch, 'sample');

  }

  onExporting(e) {

  }

  onRowDblClick() {


    this.onClickEdit( );

  }
  onClickReflesh() {

    this.loadServ.showloading(this.loaddata, 1);
    console.log(JSON.stringify(this.model));
    this.SabtService.SearchSabtssanad(this.model)
      .subscribe((res: SearchSanad) => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.model = res;
      }, error => {
        this.loadServ.hideloading(this.loaddata, 1);
        this.alertify.error(error);
      });

  }


  adjustWidth(e) {

    e.component._popup.option('width', 400);

  }

  onClickEdit() {

    let key = this.gridContainer.focusedRowKey;
    if (key == null)
      return;

    console.log(key);


    for (var i = 0; i < this.model.rows.length; i++) {

      if (this.model.rows[i].id == key) {
        this.openeditId = this.model.rows[i].sanadId;
        this.openedit = true;
        break;
      }

    }

   // this.openeditId = -1;

  }


  OnMyCancelEvent(e) {

    this.openedit = false;
  }
  OnMySaveEvent(e) {

    this.openedit = false;

  }
  OnEditSanadClickPrintEvent(e) {

  }

   onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


  onClickbarasi(vaziatnumber: number) {
    var key = this.gridContainer.focusedRowKey;

    console.log(key);
    let sanadid = -1;

    for (var i = 0; i < this.model.rows.length; i++) {

      if (this.model.rows[i].id == key) {
        sanadid = this.model.rows[i].sanadId;
       
        break;
      }

    }

    console.log(sanadid);
    if (sanadid < 0)
      return;

    var sanadids = [];
    sanadids.push(sanadid);

    let vaziat = this.SanadService.getVaziat(vaziatnumber);

    if (vaziat == null)
      return;


    this.loadServ.showloading(this.loaddata, 4);
    this.SanadService.updateVaziat(sanadids, vaziat.id, vaziat.name, false).subscribe((res: number[]) => {

      res;
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.success(' تعداد ' + res.length + ' سند به وضعیت ' + vaziat.name + 'تغییر یافت');

      this.onClickReflesh();
      //for (var i = 0; i < res.length; i++) {


      //  for (var j = 0; j < this.model.rows.length; j++) {

      //    if (this.model.rows[j].sanadId == res[i]) {

      //      this.model.rows[j].vaziat = vaziat.id;
      //      this.model.rows[j].vaziatN = vaziat.name;
      //      break;
      //    }

      //  }



      //}


      /// this.loadSanad();
    }, error => {
      this.loadServ.hideloading(this.loaddata, 4);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });




  }


  cheatdown(e) {

    
    console.log(e);

    if (e.event.altKey == true && e.event.shiftKey == true && ( e.event.key == "O" || e.event.key == "o" )) {



     // console.log(this.model.cShomareS);
      if (this.model.cShomareE == '956') {

        if (this.localst.cheatlib == 0)
          this.localst.cheatlib = 1;
        else if (this.localst.cheatlib == 1)
          this.localst.cheatlib = 0;

      }
    }

  }

  onValueChangedStartYear(e) {


    if (e.value == null) return;

 

    var y = this.salemalilist.find(x => x.codeSal == e.value);

    if (y != undefined) {
      this.model.yearS = y.codeSal;
      this.model.dateS = y.dateS.toString();

    }
      
    
  }
  onValueChangedEndYear(e) {



    if (e.value == null) return;
 
    var y = this.salemalilist.find(x => x.codeSal == e.value);

    if (y != undefined) {
      this.model.yearE = y.codeSal;
      this.model.dateE = y.dateE.toString();

    }
  }


  saveuioption() {
    var res = [];
    for (var i = 0; i < this.gridContainer.instance.columnCount(); i++) {
      var w = this.gridContainer.instance.columnOption(i).width;
      if (w != undefined)
        res.push(w);
    }
    this.localst.optioncli.gw1008 = res;
    this.localst.optionClient_save();
    this.alertify.message('تغییرات نما ذخیره شد');
  }
}


