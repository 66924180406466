import { Component, OnInit } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { MatDialog } from '@angular/material';
import { DeldialogComponent } from '../../../../dialog/deldialog.component';
import { ExcelService } from '../../../../_services/excel.service';
import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { LoadingService } from '../../../../_services/loading.service';
import { Kala, KalaForList } from '../../../../_models/app2/paye/Kala';
import { KalaService } from '../../../../_services/app2/paye/Kala.service';
import * as FileSaver from 'file-saver';
import { Localst } from '../../../../_services/localst.service';
 
 
export interface DialogData {
  animal: string;
  name: string;
} 

@Component({
  selector: 'app2-Kala-list',
  templateUrl: './Kala-list.component.html',
  styleUrls: ['./Kala-list.component.css']
})


export class KalaListComponent implements OnInit {

  openedit: boolean;
  openeditId: number;

  openMerge: boolean;

  defaultpagation: Pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  };

  modlelist: KalaForList[];
 
  user: User = JSON.parse(localStorage.getItem('user'));
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  shakhsParams: any = {};
  pagination: Pagination;
 
  selectedrow: any;
 
  isdelete: string;
  @ViewChild("gridContainer", { static: false }) gridContainer: DxDataGridComponent;

  loaddata: loadingCl;
  constructor(private userService: UserService, private loadServ: LoadingService, private alertify: AlertifyService, private KalaService: KalaService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router,
    private localst: Localst,
    public dialog: MatDialog) {
    this.loaddata = loadServ.getEmpty();
  
    //hgjy67777
    this.loadServ.create(this.loaddata,8);
    this.pagination = this.defaultpagation;
    this.openMerge = false;
  }


 
  userType: number = 0;

  ngOnInit() {
    this.resetFilters();
    this.isdelete = "no";;
    this.loadKala();

    this.loadServ.showloading(this.loaddata, 1);
    this.userService.GetUserForAcc().subscribe(res => {
      this.loadServ.hideloading(this.loaddata, 1);

      this.userType = res.value;
      if (res.errors.length > 0) {
       // this.alertify.showListString(res.errors);
      }
      else {
       // this.users = res.data;
      }


    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });


   }
  onClicksearch() {
    
    this.loadKala();
    console.log('m:searching...filter');
  }
  onClicksearchall() {
    this.resetFilters();
    this.loadKala();
    console.log('m:searching... all');
  }
  rrr() {
    this.gridContainer.instance.refresh();
    this.gridContainer.instance.getDataSource().reload();
    console.log('refleshrrr: ' + this.modlelist.length.toString());
  }
  rrr2() {

    this.gridContainer.instance.getDataSource().reload();
    console.log('refleshrrr: ' + this.modlelist.length.toString());
  }
  loadKala() {


    this.loadServ.showloading(this.loaddata,0);
    this.KalaService.getallForlist()
      .subscribe((res: KalaForList[]) => {
        this.modlelist = res;
        console.log('reflesh: ' + this.modlelist.length.toString());
        this.loadServ.hideloading(this.loaddata,0);

      }, error => {
          this.loadServ.hideloading(this.loaddata,0);
        this.alertify.error(error);
      });

    //this.KalaService.getKalas_P(this.pagination.currentPage, this.pagination.itemsPerPage, this.shakhsParams)
    //  .subscribe((res: PaginatedResult<Kala[]>) => {
    //    this.modlelist = res.result;
    //    this.pagination = res.pagination;
    //  }, error => {
    //    this.alertify.error(error);
    //  });

  }
  resetFilters() {
    this.shakhsParams.NameFarsi = '';
    this.shakhsParams.FamilyFarsi = '';
    this.shakhsParams.Code = '';
    this.shakhsParams.Codmelli = '';

  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //در کلا پیج خودمان شماره صفحه کلیک شده را قرار میدهیم
    console.log(this.pagination.currentPage);
    this.loadKala();
  }


  reloadrows(): void {
    this.loadKala();
    console.log("m:page refleshe done");
    //  this.loadKala();
  }

  onFocusedRowChanged(e) {
    this.selectedrow = e.row.data;

    console.log(JSON.stringify(this.selectedrow));

  }
  onClickedickNew() {

    console.log(JSON.stringify(this.selectedrow));


    if (this.selectedrow.id != null)
      this.router.navigate(['/app1/paye/TafDaste/Edit', this.selectedrow.id]);

  }


  onClickDelete() {

    if (this.loaddata.showed)
      return;

    console.log("The delete clicked " + this.selectedrow.id);
    this.openDialog();
    //  this.dodelete();

  }

  openDialog(): void {


    let dialogRef = this.dialog.open(DeldialogComponent, {
      width: '250px',
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('m:The dialog was closed');
      let isdelete = result;
      console.log('m:' + isdelete);
      if (isdelete == 'yes') {

        this.dodelete();

      }

    }, error => {
      this.alertify.error(error);
    });
  }


  dodelete() {



     

    this.loadServ.showloading(this.loaddata,1);
    this.KalaService.delete(this.selectedrow.id).subscribe(res => {

      if (res.errors.length > 0) {
        this.alertify.error(res.errors[0]);
      }
      else {
        this.alertify.success(res.mes);
      }
 
 

      this.loadServ.hideloading(this.loaddata,1);
      this.loadKala();

    }, error => {
        this.loadServ.hideloading(this.loaddata,1);
      this.alertify.error(error);

    });




  }







  onClickEdit() {

    if (this.loaddata.showed)
      return;
    if (this.selectedrow.id == null) {



      return;
    }



    this.openeditId = this.selectedrow.id;
    this.openedit = true;



  }
  onClickNew() {

    if (this.loaddata.showed)
      return;
    console.log('newopen');
    this.openeditId = -1;

    this.openedit = true;


  }
  OnMyCancelEvent(e) {
    console.log('cancel form');
    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }

  }

  OnMySaveEvent(e) {

    console.log(e);
    if (e) {
      this.openeditId = -1;
      this.openedit = false;

    }
    this.loadKala();

  }
  onClickExportexcel() {

   

    this.loadServ.showloading(this.loaddata, 3);
    this.KalaService.getAsExcel().subscribe((buffer) => {
      this.loadServ.hideloading(this.loaddata, 3);

      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');

    }, error => {
      this.loadServ.hideloading(this.loaddata, 3);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });





  }
  onRowDblClick(e) {

    if (e.key == null) return;
    this.openeditId = e.key;
    this.openedit = true;

  }

  onClickMerge() {

    this.openMerge = !this.openMerge;


  }

  OnKalaMergeSaveEvent(e) {
    if (e) {

      this.loadKala();
      this.openMerge = false;

    }

  }


  OnKalaMergeCancelEvent() {

    this.openMerge = false;

  }
}
