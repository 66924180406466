import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxDataGridComponent, DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { App99Service } from '../../../../_services/app99/App99.service';
import { BankM, ChekM, PayM, HItem, KindHaz, PerM } from '../../../../_models/app99/app99model';
import { Localst } from '../../../../_services/localst.service';
 
let comp = 'PayM';

@Component({
  selector: 'app99-PayM-edit',
  templateUrl: './PayM-edit.component.html',
  styleUrls: ['./PayM-edit.component.css']
})
export class PayMEditComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
  pagination: Pagination;
  myModel: PayM;
  myModel_old: PayM;
  focusedRowKey: number;
 
   perMlist: any[]=[];
   HItemlist: HItem[]=[];
   HItemlistFilt: any[]=[];
 
  ParKindlist: any[]=[];
  BankMlist: BankM[]=[];
  perMForhaveGhars: any[]=[];
  gharsfiltered: any[] = [];

  sGhartinylist: any[] = [];


  chekm: ChekM;
 PayMcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
  selectedtab: number;
  gridcost: number;
  mandehperm: number =0;
 

 
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor(private userService: UserService, private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private app99Service: App99Service,
 
    private localst: Localst,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,15);
    this.validationCallback = this.validationCallback.bind(this);
    this.selectedtab = 0;
    this.focusedRowKey = -1;
    this.myModel = this.app99Service.PayM_getPayMEmpty();
    this.myModel.chekm  = this.app99Service.ChekM_getChekMEmpty();
    this.myModel.chekmId = -1;

    this.PayMcodelist = [];
    this.gridcost = 0;
    this.ParKindlist = this.app99Service.DarMRow_getParKindList();
    this.ParKindlist.splice(-1, 1);
 
  }

  ngOnInit() {
 
    this.loadother();
    




    this.loadServ.showloading(this.loaddata, 1);
    this.app99Service.PayM_getallCods().subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.PayMcodelist = data;
 
    }, error => {
      this.loadServ.hideloading(this.loaddata, 1);
      this.alertify.error(error);
      console.log("myerror:" + error);
    });




    this.isnew = true;
 
    if (this.editId == -1) {
 
  
      try {
        this.myModel.dateA_S = this.localst.dateEmruz.dateString;
        this.myModel.chekm.dateSabt_S = this.localst.dateEmruz.dateString;
    
  

      }
      catch {

      }
   
 

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;


      this.loadServ.showloading(this.loaddata, 3);
      this.app99Service.PayM_getbyid(this.editId).subscribe(
        (data : PayM) => {

          this.loadServ.hideloading(this.loaddata,3);
          this.myModel = data;
         

          this.myModel_old = JSON.parse(JSON.stringify(this.myModel));

  

        }
        ,
        error => {
          this.loadServ.hideloading(this.loaddata,3);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
 
    console.log(this.editId.toString());
  
  }

 
  addthis = e => {
 
    
  }

  loadother() {

    this.loadServ.showloading(this.loaddata, 4);
    this.app99Service.PerM_getallForList(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 4);
        this.perMlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 4);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


    this.loadServ.showloading(this.loaddata, 5);
    this.app99Service.HItem_getall().subscribe(
      (data: HItem[]) => {
        this.loadServ.hideloading(this.loaddata, 5);
        this.HItemlist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 5);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );


  

    this.loadServ.showloading(this.loaddata, 7);
    this.app99Service.BankM_getall(comp).subscribe(
      (res) => {
        this.loadServ.hideloading(this.loaddata, 7);
        if (res.errors.length > 0) {
          this.alertify.showListString(res.errors);
          return;
        }
        this.BankMlist = res.data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 7);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );



    this.loadServ.showloading(this.loaddata, 8);
    this.app99Service.PerM_getallForhaveGhars(0).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 8);
        this.perMForhaveGhars = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 8);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );

    this.loadServ.showloading(this.loaddata, 9);
    this.app99Service.Ghar_GetAllAsGhartiny(-1,-1).subscribe(
      (data: any[]) => {
        this.loadServ.hideloading(this.loaddata, 9);
        this.sGhartinylist = data;
      },
      error => {
        this.loadServ.hideloading(this.loaddata, 9);
        console.log("myerror:" + error);
        this.alertify.error(error);
      }


    );
  }

  
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;
  

    if (this.myModel.id == null) {


      this.myModel.id =-1

    }

    let m = [];
 
      
 

  
    //  m.push('مبلغ فاکتور تسویه نشده است');

    if (this.myModel.costAll == 0)
      m.push('مبلغ فاکتور صفر است');


 
 //grfhdhhdfdhjrtrt
 

    if (m.length > 0) {

      this.alertify.showListString(m);
      return;
    }
    

   // console.log(JSON.stringify(this.myModel));

    console.log(this.myModel);
    if (this.myModel.id == -1) {
      this.loadServ.showloading(this.loaddata,4);
      this.app99Service.PayM_add(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,4);
        this.alertify.success('مشخصات با موفقت ذخیره شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,4);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }
    else {
 
      this.loadServ.showloading(this.loaddata,5);
      this.app99Service.PayM_update(this.myModel).subscribe(next => {
        this.loadServ.hideloading(this.loaddata,5);
        this.alertify.success('مشخصات با بروز رسانی شد');
        console.log("m:saved");
        this.OnMySaveEvent.emit(true);
 

      }, error => {
          this.loadServ.hideloading(this.loaddata,5);
        this.alertify.error(error);
        console.log("myerror:" + error)
      });

    }


    e.preventDefault();
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
  validationCallback(e) {

    let v = e.value;

    //if (this.editId != -1)
    //if (this.myModel_old.cMelli != null)
    //  if (this.myModel_old.cMelli == v)
    //    return true;

    for (var val of this.PayMcodelist) {
      if (v == val) return false;
    }

    return true


  }
 
 

  validateNumber(e) {

    

  }

  onSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }
  ghar1tempchanged(e, cellInfo) {


  


    
      for (var r of this.perMForhaveGhars) {
        if (r.id == e.value) {




          cellInfo.data.permId = r.id;
          cellInfo.data.permN = r.name;

   
   
          //Id = x.Id,
          //  Name = x.Name,
          //  CMelli = x.CMelli,
          //  Rate = x.Rate
          break;
        }
      

    }
   // cellInfo.data.setValue(e.value);
    
  }
  ghar2tempchanged(e, cellInfo) {

    let gid = -1;
    for (var r of this.sGhartinylist) {
      if (r.id == e.value) {

        gid = r.id;
        cellInfo.data.garId = r.id;
        cellInfo.data.garDis = r.dis;

        break;
      }



    }
    let sid = -1;
    if (!this.isnew) {
      sid= this.myModel_old.sId
    }
 
    this.HItemlistFilt = [];
    if (gid != -1) {
      this.loadServ.showloading(this.loaddata, 2);
      this.app99Service.GharRow_getAllowitem(gid, sid).subscribe(
        (data: any[]) => {
          this.loadServ.hideloading(this.loaddata, 2);
          this.HItemlistFilt = data;
        },
        error => {
          this.loadServ.hideloading(this.loaddata, 2);
          console.log("myerror:" + error);
          this.alertify.error(error);
        }


      );
    }
   
  }

  hItemchanged(e, cellInfo) {
 
 
    var newValue = e.value;

   
 

    for (var r of this.HItemlist) {
      if (r.id == newValue) {
        let s= r;

        cellInfo.setValue(e.value);
        //cellInfo.data.hItemId = s.id;
        cellInfo.data.hItemN = s.name;
        cellInfo.data.cnt = 1;
        cellInfo.data.cost = 0;
        cellInfo.data.costAll = 0;
       // cellInfo.data.cost = s.costFi;
      //  cellInfo.data.costAll = s.costFi;
        break;
      }
     
    }

    for (var k of this.HItemlistFilt) {
      if (k.id == newValue) {
        cellInfo.data.cntAllow = k.cntAllow;

      }


    }

 

 
  }
  cntchanged(e, cellInfo) {

  
    var newValue = e.value;
 
    cellInfo.setValue(newValue);

    cellInfo.data.costAll = cellInfo.data.cost * newValue;

 
  }
  costchanged(e, cellInfo) {

 
    var newValue = e.value;

    cellInfo.setValue(newValue);

    cellInfo.data.costAll = cellInfo.data.cnt * newValue;
    
  }

  costAllchanged(e, cellInfo) {


    var newValue = e.value;

    cellInfo.setValue(newValue);

    if (cellInfo.data.cnt > 0) {

      cellInfo.data.cost = newValue / cellInfo.data.cnt;
    }
 
  }
 
 
  selectedtabchang(e) {
    this.selectedtab = +e;
    
 
  }
  parKindchanged(e) {

    if(e.value !=2)
    this.myModel.mos = '';


  }
  
  permchanged(e) {


    if (e.value == null)
      return
     
      // this.loadServ.showloading(this.loaddata, 10);
    this.app99Service.AllM_mandehPerm(e.value).subscribe((data: any) => {
      // this.loadServ.hideloading(this.loaddata, 10);
      this.mandehperm = data.value;
 
 
    }, error => {
      //this.loadServ.hideloading(this.loaddata, 10);
      this.alertify.error(error);
      console.log("myerror:" + error)
    });

  }
  onClickReflesh() {

    this.loadother();


  }
}
