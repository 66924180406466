import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
 
import { map } from 'rxjs/operators';
import { IdName } from '../_models/General/IdName';

//const httpOptions = {
//  headers: new HttpHeaders({
//    'Authorization': 'Bearer ' + localStorage.getItem('token')
//  })
//};

@Injectable({
  providedIn: 'root'
})
export class MyService {
 // baseUrl = environment.apiUrl;
  baseUrl = location.origin + '/api/';
  constructor(private http: HttpClient) {}

  getUsersAll(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'my/usergetall');
  }


}
