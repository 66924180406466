import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../_models/user';
 
import { ArchService } from '../../_services/arch.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Pagination, PaginatedResult } from '../../_models/pagination';

import { arch } from 'process';
import { Arch } from '../../_models/arch';


@Component({
  selector: 'app-arch-row',
  templateUrl: './arch-row.component.html',
  styleUrls: ['./arch-row.component.css']
})
export class ArchRowComponent implements OnInit {
  @Input() myvs: Arch = this.archService.getArchEmpty()
  user: User = JSON.parse(localStorage.getItem('user'));
 
  focusedRowIndex = 0;
  selectedrowId: number;
  autoNavigateToFocusedRow = true;
  constructor(private archService: ArchService, private alertify: AlertifyService,
    private route: ActivatedRoute) {
   
  }
  OnInput(value) {
    this.myvs = value;
  }
  ngOnInit() {

   // this.myvs = this.archService.getArchEmpty();
   
    console.log(this.myvs.Id);
    };
   
}



