import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { User } from '../../../../_models/user';
import { UserService } from '../../../../_services/user.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, PaginatedResult } from '../../../../_models/pagination';
 
import { FormGroup, FormBuilder } from '@angular/forms';
import notify from 'devextreme/ui/notify';
 
import { IdName } from '../../../../_models/General/IdName';
//import DevExpress from 'devextreme/bundles/dx.all';
import { log } from 'util';
import { DxSelectBoxComponent, DxSelectBoxModule ,DxTextBoxModule} from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import Tooltip from "devextreme/ui/tooltip"
import { async } from '@angular/core/testing';
//import DevExpress from 'devextreme/bundles/dx.all';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TafsiliService } from '../../../../_services/app1/paye/Tafsili.service';
import { OstanKol } from '../../../../_models/app1/paye/ostanKol';
import { OstanService } from '../../../../_services/app1/paye/Ostan.service';
 
import { Ostan } from '../../../../_models/app1/paye/Ostan';
import { LoadingService } from '../../../../_services/loading.service';
import { DirSellService } from '../../../../_services/app3/opr/DirSell.service';
import { ExcelService } from '../../../../_services/excel.service';
import { DirSellExcelImportDto } from '../../../../_models/app3/opr/DirSell';
 

@Component({
  selector: 'app3-DirSell-xls',
  templateUrl: './DirSell-xls.component.html',
  styleUrls: ['./DirSell-xls.component.css']
})
export class DirSellXlsComponent implements OnInit {

 

  baseUrl = environment.apiUrl;
  @Input() isnew: boolean;
  @Input() editId: number;
  @Output() OnMySaveEvent = new EventEmitter<boolean>();
  @Output() OnMyCancelEvent = new EventEmitter<boolean>();
 
  myModel: DirSellExcelImportDto;

  @ViewChild("myInputexcel", { static: false }) myInputexcel: ElementRef;


 
  errors: string;
 

 DirSellcodelist: string[];
 
  rules: any;
  sho: false;
  somest: string;
  someint: number;
 
 
  private sub: any;
  test: string;
    n: number;
 
 
  loaddata: loadingCl;
  constructor( private alertify: AlertifyService, private loadServ: LoadingService,
    private router: Router,
    private dirSellService: DirSellService,
    private excelService: ExcelService,
 
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ostanService: OstanService,
    private httpClient: HttpClient) {
    this.loaddata = loadServ.getEmpty();
    loadServ.create(this.loaddata,8);
 
    
    this.myModel = this.dirSellService.getDirSellExcelImportEmpty();
    this.DirSellcodelist = [];
 
  }

  ngOnInit() {
 
 
 

    this.isnew = true;
 
    if (this.editId == -1) {
 
 
 

      this.isnew = true;

 
      
    }
    else {
   
 
      this.isnew = false;

 
    }
 
  }


  addthis = e => {
 
    
  }
  cancelthis(e) {
    
    this.OnMyCancelEvent.emit(true);

  }


  onFormSubmit(e) {
    if (this.loaddata.showed)
      return;

    //if (this.myModel.datatable.length < 3) {
    //  this.alertify.warning('اکسل خالی است');
    //  return;
    //}


    this.loadServ.showloading(this.loaddata, 11);
    this.dirSellService.AddExcelImport(this.myModel).subscribe(data => {
      this.loadServ.hideloading(this.loaddata, 11);

      //console.log(data.vgf);

      // console.log(JSON.stringify(data));

      if (data.errors.length > 0) {
        this.alertify.showListString(data.errors);

        for (var r of data.errors) {
          this.errors += r + '\n';
        }



      }

      else {
        this.alertify.success(data.mes);
        this.OnMySaveEvent.emit(true);


      }

     
    }, error => {
      this.loadServ.hideloading(this.loaddata, 11);
      this.alertify.error(error);
 
    });
    this.myInputexcel.nativeElement.value = "";

 
  }


 


  values = '';

  
  onValueChanged(e) {
    const previousValue = e.previousValue;
    const newValue = e.value;

    this.values = newValue;

  }

 
  isvalisss: boolean;
  dffd: any;
 
 
 

  validateNumber(e) {

    

  }



  readExcel(event) {
    //گرفتن دیتاا
    this.excelService.importExcelFile(event).then(data => {
      //console.log(JSON.stringify(data));





      //this.loadServ.showloading(this.loaddata, 11);
      //this.dirSellService.AddExcelImport(data).subscribe((data: any) => {
      //  this.loadServ.hideloading(this.loaddata, 11);

      //  //console.log(data.vgf);

      //  // console.log(JSON.stringify(data));

      //  if (data.errors.length > 0) {
      //    this.alertify.showListString(data.errors);

      //  }

      //  else {

      //  }


      //}, error => {
      //  this.loadServ.hideloading(this.loaddata, 11);
      //  this.alertify.error(error);

      //});
      //this.myInputexcel.nativeElement.value = "";









      this.myModel.datatable = data;
      if (data.length < 2)
        return;



    });



  }

}
